/*
 * Application global variables.
 */

// Angular Material custom theme
// See https://material.angular.io/guide/theming for more details.
//
// You can also read https://medium.com/@tomastrajan/the-complete-guide-to-angular-material-themes-4d165a9d24d1
// for more insight about Angular Material theming.

@import "~@angular/material/theming";

//
// Global variables for Material customization.
//

// Toolbar

$mat-toolbar-height-desktop: get($header-config, default, height, desktop) !default;
$mat-toolbar-height-tablet-and-mobile: get($header-config, default, height, tablet-and-mobile) !default;

$mat-toolbar-desktop-density-config: (height: (default: $mat-toolbar-height-desktop,
    maximum: $mat-toolbar-height-desktop,
    minimum: $mat-toolbar-height-tablet-and-mobile,
  ),
) !default;

$mat-toolbar-mobile-density-config: (height: (default: $mat-toolbar-height-tablet-and-mobile,
    maximum: $mat-toolbar-height-tablet-and-mobile,
    minimum: $mat-toolbar-height-tablet-and-mobile,
  ),
) !default;

// Typography

$custom-typography: mat-typography-config($font-family: $font-family-sans-serif,
  $title: mat-typography-level(24px, 32px, bold),
);

@include mat-core($custom-typography);

// Global

$mat-primary: (300: $primary-lighten,
  500: $primary,
  700:$primary-darken,
  contrast: (300: $gray-300,
    500: $primary-inverse,
    700: $gray-700,
  ),
);

$mat-accent: (100: lighten($success, 8%),
  500: $success,
  700: darken($success, 8%),
  contrast: (100: $gray-300,
    500: $success-inverse,
    700: $gray-700,
  ),
);

$mat-warn: (100: lighten($danger, 8%),
  500: $danger,
  700: darken($danger, 8%),
  contrast: (100: $gray-300,
    500: $danger-inverse,
    700: $gray-700,
  ),
);

$mat-primary-palette: mat-palette($mat-primary, 500, 100, 700);
$mat-accent-palette: mat-palette($mat-accent, 500, 100, 700);
$mat-warn-palette: mat-palette($mat-warn, 500, 100, 700);

$material-light-theme: ( // light-theme
  primary: $mat-primary-palette,
  accent: $mat-accent-palette,
  warn: $mat-warn-palette,
  foreground: ( // text-color
    base: $body-color,
    divider: $gray-600,
    dividers: $gray-600,
    disabled: $gray-600,
    disabled-button: rgba($black, 0.26),
    disabled-text: $gray-600,
    hint-text: $black,
    secondary-text: $black,
    icon: rgba($black, 0.54),
    icons: rgba($black, 0.54),
    text: rgba($black, 0.87),
    slider-min: rgba($black, 0.87),
    slider-off: rgba($black, 0.26),
    slider-off-active: rgba($black, 0.38)),
  background: ( // background-color
    status-bar: $success,
    app-bar: $white,
    background: $body-bg,
    hover: rgba($black, 0.04),
    card: $card-bg,
    dialog: $item-bg,
    disabled-button: rgba($gray-600, 0.12),
    raised-button: $white,
    focused-button: $white,
    selected-button: map-get($mat-grey, 300),
    selected-disabled-button: map-get($mat-grey, 400),
    disabled-button-toggle: map-get($mat-grey, 200),
    unselected-chip: map-get($mat-grey, 300),
    disabled-list-option: map-get($mat-grey, 200),
    tooltip: $canvas-body-bg));

$app-light-theme: mat-light-theme($material-light-theme);

// Material design icons font path
$material-design-icons-font-directory-path: "~material-design-icons-iconfont/dist/fonts/";
