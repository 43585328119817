//
// Apexcharts
//

// Text
.apexcharts-text,
.apexcharts-title-text,
.apexcharts-legend-text {
  font-family: $font-family-base !important;
}

.apexcharts-title-text {
  font-weight: $font-weight-normal;
}

.apexcharts-pie-label {
  font-weight: $font-weight-normal;
  font-size: $font-size-sm;
}

// Dropdown
.apexcharts-toolbar {
  text-align: left !important;
}

.apexcharts-menu {
  // don't have themed
  background: var(--white);

  // if has themed
  @include themed() {
    background: theme-color($color-white);
  }

  border: 0 !important;
  padding: 0.5rem 0 !important;
  box-shadow: $dropdown-box-shadow;
  border-radius: $border-radius !important;
  overflow: hidden;
  min-width: 10rem !important;

  .apexcharts-menu-item {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;

    &:hover {
      background-color: $hover-bg !important;
    }
  }
}

// Tooltips
.apexcharts-tooltip {
  &.apexcharts-theme-light {
    @include border-radius($border-radius);
    box-shadow: $dropdown-box-shadow;
    border: 0 !important;

    @include themed() {
      color: theme-color(color-canvas-body-text) !important;
      background: theme-color(color-canvas-body-bg) !important;
    }

    .apexcharts-tooltip-title {
      @include themed() {
        background-color: theme-color(color-canvas-header-bg) !important;
      }

      border: 0 !important;
    }
  }

  .apexcharts-tooltip-title {
    padding: 0.5rem 1rem;
  }
}

.apexcharts-xaxistooltip {
  &.apexcharts-theme-light {
    @include border-radius($border-radius !important);
    box-shadow: $dropdown-box-shadow !important;
    border: 0 !important;
    // don't have themed
    color: var(--white-inverse);
    background: var(--white);

    // if has themed
    @include themed() {
      color: theme-color($color-white-inverse);
      background: theme-color($color-white);
    }

    &:before {
      border-bottom: 0;
    }

    &:after {
      // don't have themed
      border-bottom-color: var(--white);

      // if has themed
      @include themed() {
        border-bottom-color: theme-color($color-white);
      }
    }
  }
}

// Border radius integration
.card-rounded-bottom {
  .apexcharts-canvas {
    svg {
      border-bottom-left-radius: $card-border-radius;
      border-bottom-right-radius: $card-border-radius;
    }
  }
}

// Border radius options
.rounded {
  .apexcharts-canvas {
    svg {
      border-radius: $border-radius !important;
    }
  }
}

.rounded-sm {
  .apexcharts-canvas {
    svg {
      border-radius: $border-radius-sm !important;
    }
  }
}

.rounded-lg {
  .apexcharts-canvas {
    svg {
      border-radius: $border-radius-lg !important;
    }
  }
}

.rounded-xl {
  .apexcharts-canvas {
    svg {
      border-radius: $border-radius-xl !important;
    }
  }
}

.rounded-bottom-xl {
  .apexcharts-canvas {
    svg {
      border-bottom-left-radius: $border-radius-xl !important;
      border-bottom-right-radius: $border-radius-xl !important;
    }
  }
}
