//
// Tooltip
//

.mat-tooltip {
  box-shadow: $tooltip-box-shadow;

  @include themed() {
    color: theme-color(color-canvas-body-text) !important;
    background-color: theme-color(color-canvas-body-bg) !important;
  }
}
