//
// Radio
//


.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color: var(--primary) !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--primary) !important;
}
