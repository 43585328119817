//
// Card
//

// Base
.card {
  border: 0;
  box-shadow: $box-shadow-xs;

  @include themed() {
    color: theme-color($color-gray-900);
    background-color: theme-color($color-card-bg);
  }

  // Header
  >.card-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 $card-px;
    min-height: $card-header-height;
    background-color: transparent;

    @include themed() {
      color: theme-color($color-gray-900);
      border-bottom: 1px solid theme-color($color-gray-200);
    }

    // Title
    .card-title {
      display: flex;
      align-items: center;
      font-weight: $font-weight-bolder;
      margin: $card-header-py;
      margin-left: 0;

      &.flex-column {
        align-items: flex-start;
        justify-content: center;
      }

      .card-icon {
        margin-right: 0.75rem;
        line-height: 0;

        i {
          font-size: 1.25rem;
          color: $gray-600;
          line-height: 0;

          &:after,
          &:before {
            line-height: 0;
          }
        }

        .svg-icon {
          @include svg-icon-size(24px);
          @include svg-icon-color($gray-600);
        }
      }

      &,
      .card-label {
        font-size: 1.275rem;

        @include themed() {
          color: theme-color($color-card-text);
        }
      }

      .card-label {
        margin: 0 0.75rem 0 0;
        flex-wrap: wrap;
      }

      // Description
      small {
        color: $text-muted;
        font-size: 1rem;
      }

      // Headings
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 0;
      }
    }

    // Toolbar
    .card-toolbar {
      display: flex;
      align-items: center;
      margin: $card-header-py 0;
      flex-wrap: wrap;
    }
  }

  // Body
  >.card-body {
    padding: $card-py $card-px;

    @include themed() {
      color: theme-color($color-gray-900);
    }
  }

  // Footer
  >.card-footer {
    padding: $card-py $card-px;
    background-color: transparent;

    @include themed() {
      border-top: 1px solid theme-color($color-gray-200);
    }
  }

  // Dark style
  &.card-dark {
    color: $card-bg;
    background-color: $card-color;

    // Header
    >.card-header {
      color: $gray-100;
      border-bottom: 1px solid $gray-800;
      background-color: $toolbar-color;

      .card-title {

        &,
        .card-label {
          color: $card-bg;
        }
      }
    }

    // Body
    >.card-body {
      color: $gray-100;
    }

    // Footer
    >.card-footer {
      border-top: 1px solid $gray-800;
    }
  }

  // Scroll
  .card-scroll {
    position: relative;
    overflow: auto;
  }

  // Reset padding x
  &.card-px-0 {

    .card-header,
    .card-body,
    .card-footer {
      padding-left: 0;
      padding-right: 0;
    }
  }

  // Dashed style
  &.card-dashed {
    box-shadow: none;

    @include themed() {
      border: 1px dashed theme-color($color-gray-200) !important;
    }

    >.card-header {
      @include themed() {
        border-bottom: 1px dashed theme-color($color-gray-200) !important;
      }
    }

    >.card-footer {
      @include themed() {
        border-top: 1px dashed theme-color($color-gray-200) !important;
      }
    }
  }

  // Bordered style
  &.card-bordered {
    box-shadow: none;

    @include themed() {
      border: 1px solid theme-color($color-gray-200) !important;
    }
  }

  // Flush borders
  &.card-flush {
    >.card-header {
      border-bottom: 0;
    }

    >.card-footer {
      border-top: 0;
    }
  }

  // Outline style
  &.card-outline {
    box-shadow: none;

    @include themed() {
      border: 1px solid theme-color($color-gray-200) !important;
    }
  }

  // Transparent style
  &.card-transparent {
    box-shadow: none;
    background-color: transparent;
  }

  &.card-shadow {
    box-shadow: $card-box-shadow;
    border: 0;
  }
}

// Responsive stretch heights
.card {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      // Stretch
      &.card#{$infix}-stretch {
        height: calc(100% - var(--bs-gutter-y));
      }

      // Stretch 75
      &.card#{$infix}-stretch-75 {
        height: calc(75% - var(--bs-gutter-y));
      }

      // Stretch 50
      &.card#{$infix}-stretch-50 {
        height: calc(50% - var(--bs-gutter-y));
      }

      // Stretch 33
      &.card#{$infix}-stretch-33 {
        height: calc(33.333% - var(--bs-gutter-y));
      }

      // Stretch 25
      &.card#{$infix}-stretch-25 {
        height: calc(25% - var(--bs-gutter-y));
      }

      // Header stretch
      .card-header#{$infix}-stretch {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        align-items: stretch;

        .card-toolbar {
          margin: 0;
          align-items: stretch;
        }
      }
    }
  }
}

// Utilities
.card-p {
  padding: $card-py $card-px !important;
}

.card-m {
  margin: $card-py $card-px;
}

.card-px {
  padding-left: $card-px !important;
  padding-right: $card-px !important;
}

.card-py {
  padding-top: $card-py !important;
  padding-bottom: $card-py !important;
}

.card-rounded {
  border-radius: $card-border-radius;
}

.card-rounded-top {
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
}

.card-rounded-bottom {
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}

// Mobile mode
@include media-breakpoint-down(md) {
  .card {

    // Header
    >.card-header:not(.flex-nowrap) {
      padding-top: $card-header-py;
      padding-bottom: $card-header-py;
    }
  }
}
