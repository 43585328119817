//
// Border
//

.border {
  @include themed() {
    border-color: theme-color($color-gray-200) !important;
  }
}

.border-top {
  @include themed() {
    border-top: 1px solid theme-color($color-gray-200) !important;
  }
}

.border-bottom {
  @include themed() {
    border-bottom: 1px solid theme-color($color-gray-200) !important;
  }
}

.border-end {
  @include themed() {
    border-right: 1px solid theme-color($color-gray-200) !important;
  }
}

.border-start {
  @include themed() {
    border-left: 1px solid theme-color($color-gray-200) !important;
  }
}

.border-active:not(.active):not(:active):not(:hover):not(:focus) {
  border-color: transparent !important;
}

.border-hover:not(:hover):not(:focus):not(.active):not(:active) {
  cursor: pointer;
  border-color: transparent !important;
}

// Border color
@each $color, $value in $theme-text-colors {
  .border-#{$color} {
    border-color: $value !important;
  }

  // if has themed
  .border-#{$color} {
    @include themed() {
      border-color: var(--#{$color}) !important;
    }
  }
}

// Gray border colors
@each $color, $value in $grays {
  // Initial
  .border-gray-#{$color} {
    border-color: $value !important;
  }
}

// Hover border colors
@each $color, $value in $theme-text-colors {
  .border-hover-#{$color}:hover {
    border-color: $value !important;
  }

  .border-active-#{$color}.active {
    border-color: $value !important;
  }
}

// Dashed style
.border-dashed {
  border-style: dashed !important;
  border-color: $border-dashed-color;
}

.border-top-dashed {
  border-top-style: dashed !important;
}

.border-bottom-dashed {
  border-bottom-style: dashed !important;
}

.border-start-dashed {
  border-left-style: dashed !important;
}

.border-end-dashed {
  border-right-style: dashed !important;
}

// Dotted style
.border-dotted {
  border-style: dotted !important;
}

.border-top-dotted {
  border-top-style: dotted !important;
}

.border-bottom-dotted {
  border-bottom-style: dotted !important;
}

.border-start-dotted {
  border-left-style: dotted !important;
}

.border-end-dotted {
  border-right-style: dotted !important;
}

// Border radiuses
.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

// Border transparent
.border-transparent {
  border-color: transparent !important;
}

.rounded-sm {
  @include border-radius($border-radius-sm);
}

.rounded {
  @include border-radius($border-radius);
}

.rounded-lg {
  @include border-radius($border-radius-lg);
}

.rounded-xl {
  @include border-radius($border-radius-xl);
}

.rounded-xxl {
  @include border-radius($border-radius-xxl);
}

.rounded-circle {
  @include border-radius($border-radius-full);
}
