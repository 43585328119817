//
// Form Select
//

.form-select {
  box-shadow: none !important;
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2211%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M8.99%2010.1a.817.817%200%2001-.566-.235l-8-8A.8.8%200%20011.555.733L8.99%208.168%2016.424.733a.8.8%200%20111.131%201.132l-8%208a.817.817%200%2001-.565.234z%22%20fill%3D%22%23383D48%22%2F%3E%3C%2Fsvg%3E) !important;
  background-position: right 1rem center !important;
  background-size: inherit !important;
  font-weight: $input-font-weight;
  border: none;

  // Solid style
  &.form-select-solid {
    @include placeholder($input-solid-placeholder-color);

    @include themed() {
      color: theme-color(color-item-text);
      background-color: theme-color(color-item-bg);
      border-color: theme-color(color-item-bg);
    }

    transition: $transition-input;

    .dropdown.show>&,
    &:active,
    &.active,
    &:focus,
    &.focus {
      @include themed() {
        color: theme-color(color-tabbar-text);
        background-color: theme-color($color-focus-bg) !important;
        border-color: theme-color($color-focus-bg) !important;
      }

      transition: $transition-input;
    }
  }

  // White style
  &.form-select-white {
    @include placeholder($input-placeholder-color);
    background-color: $white;
    border-color: $white;
    color: $input-color;

    .dropdown.show>&,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $white;
      border-color: $white !important;
      color: $input-color;
    }
  }
}
