//
// Text
//

// Text colors
@each $name,
$color in $theme-text-colors {

  // Base color
  // don't have themed
  .text-#{$name} {
    color: var(--#{$name});
  }

  // if has themed
  .text-#{$name} {
    @include themed() {
      color: theme-color(color-#{$name});
    }
  }

  // Inverse color
  .text-inverse-#{$name} {
    color: theme-inverse-color($name);
  }

  @if (theme-light-color($name)) {

    // Light colors
    .text-light-#{$name} {
      color: theme-light-color($name);
    }
  }

  @if (theme-lighten-color($name)) {

    // Lighten colors
    .text-lighten-#{$name} {
      color: theme-lighten-color($name) !important;
    }
  }

  // Hover
  .text-hover-#{$name} {
    transition: $transition-link;

    i {
      transition: $transition-link;
    }

    &:hover {
      transition: $transition-link;

      @include themed() {
        color: theme-color(color-#{$name}) !important;
      }

      i {
        transition: $transition-link;
        color: $color !important;
      }

      .svg-icon {
        @include svg-icon-color($color);
      }
    }
  }

  // Hover inverse
  .text-hover-inverse-#{$name} {
    transition: $transition-link;

    i {
      transition: $transition-link;
    }

    &:hover {
      transition: $transition-link;
      color: theme-inverse-color($name) !important;

      i {
        transition: $transition-link;
        color: theme-inverse-color($name) !important;
      }

      .svg-icon {
        @include svg-icon-color(theme-inverse-color($name));
      }
    }
  }

  // Active
  .text-active-#{$name} {
    transition: $transition-link;

    i {
      transition: $transition-link;
    }

    &.active {
      transition: $transition-link;
      color: $color !important;

      i {
        transition: $transition-link;
        color: $color !important;
      }

      .svg-icon {
        @include svg-icon-color($color);
      }
    }
  }

  // Active inverse
  .text-active-inverse-#{$name} {
    transition: $transition-link;

    i {
      transition: $transition-link;
    }

    &.active {
      transition: $transition-link;
      color: theme-inverse-color($name) !important;

      i {
        transition: $transition-link;
        color: theme-inverse-color($name) !important;
      }

      .svg-icon {
        @include svg-icon-color(theme-inverse-color($name));
      }
    }
  }
}

// Text transparent
.fw-boldest {
  font-weight: $font-weight-boldest !important;
}

// Text transparent
.text-transparent {
  color: transparent;
}

// Color white
.color-white {
  color: white !important;
}

// Color black
.color-black {
  color: black !important;
}

// Cursor pointer
.cursor-pointer {
  cursor: pointer;
}

// Cursor default
.cursor-default {
  cursor: default;
}

// Cursor move
.cursor-move {
  cursor: move;
}

.text-primary-inverse {
  color: var(--inverse-primary)
}

// Icons
i {
  line-height: 1;
  font-size: 1rem;

  &.icon-color {
    color: $text-muted;
  }
}

// Link transition
a {
  transition: $transition-link;

  &:hover {
    transition: $transition-link;
  }
}
