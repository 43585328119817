//
// Form Datepicker
//

.form-datepicker {
  box-shadow: none !important;
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M5.25%209.75a.75.75%200%20000%201.5h.008a.75.75%200%20000-1.5H5.25zM8.25%209.75a.75.75%200%20000%201.5h4.5a.75.75%200%20000-1.5h-4.5zM5.25%2012.75a.75.75%200%20000%201.5h.008a.75.75%200%20000-1.5H5.25zM8.25%2012.75a.75.75%200%20000%201.5h4.5a.75.75%200%20000-1.5h-4.5z%22%20fill%3D%22%23383D48%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M6%20.75a.75.75%200%2000-1.5%200v.75H2.25A2.25%202.25%200%20000%203.75v12A2.25%202.25%200%20002.25%2018h13.5A2.25%202.25%200%200018%2015.75v-12a2.25%202.25%200%2000-2.25-2.25H13.5V.75a.75.75%200%2000-1.5%200v.75H6V.75zM2.25%203H4.5v.75a.75.75%200%20001.5%200V3h6v.75a.75.75%200%20001.5%200V3h2.25a.75.75%200%2001.75.75V6h-15V3.75A.75.75%200%20012.25%203zM1.5%207.5h15v8.25a.75.75%200%2001-.75.75H2.25a.75.75%200%2001-.75-.75V7.5z%22%20fill%3D%22%23383D48%22%2F%3E%3C%2Fsvg%3E) !important;
  background-position: right 1rem center !important;
  // background-size: $input-bg-size $input-bg-size !important;
  background-repeat: no-repeat !important;
  padding: $input-btn-padding-y $input-btn-padding-x;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  border-top-right-radius: $input-border-radius;
  border-bottom-right-radius: $input-border-radius;

  // Solid style
  &.form-datepicker-solid {
    @include placeholder($input-solid-placeholder-color);

    @include themed() {
      color: theme-color(color-item-text);
      background-color: theme-color(color-item-bg);
      border-color: theme-color(color-item-bg);
    }

    transition: $transition-input;

    .dropdown.show>&,
    &:active,
    &.active,
    &:focus,
    &.focus {
      @include themed() {
        color: theme-color(color-tabbar-text);
        background-color: theme-color($color-focus-bg) !important;
        border-color: theme-color($color-focus-bg) !important;
      }

      transition: $transition-input;
    }
  }

  // White style
  &.form-datepicker-white {
    @include placeholder($input-placeholder-color);
    background-color: $white;
    border-color: $white;
    color: $input-color;

    .dropdown.show>&,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $white;
      border-color: $white !important;
      color: $input-color;
    }
  }
}
