//
// Drawer
//

.mat-dialog-container {
  background-color: $white !important;

  .mat-dialog-content {
    max-height: 80vh;
  }
}

// mat-dialog-container {
//   padding: 0 !important;

//   .modal {
//     display: block;
//     position: relative;

//     .modal-dialog {
//       margin: 0;
//     }
//   }

//   mat-dialog-content {
//     margin: 0 !important;
//     padding: 0 !important;
//   }
// }
