//
// Theme Light Colors
//

// Bootstrap gray colors map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
) !default; // Custom variable

// Theme colors
$theme-colors: (
  "white": $white,
  // custom color type
  "light": $light,
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "dark": $dark,
  "black": $black,
  "muted": $text-muted,
  "blue": $blue,
  // bootstrap color type
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "gray": $gray,
  "body-bg": $body-bg,
  "body-text": $body-color,
  "toolbar-bg": $toolbar-bg,
  "toolbar-text": $toolbar-color,
  "focus-bg": $focus-bg,
  "tabbar-text": $focus-color,
  "card-bg": $card-bg,
  "card-text": $card-color,
  "item-bg": $item-bg,
  "item-text": $item-color,
) !default;

// Hover colors
$theme-active-colors: (
  "white": $white-active,
  "primary": $primary-active,
  "secondary": $secondary-active,
  "light": $light-active,
  "success": $success-active,
  "info": $info-active,
  "warning": $warning-active,
  "danger": $danger-active,
  "dark": $dark-active,
  "black": $black-active,
  "blue": $blue-active,
  "indigo": $indigo-active,
  "purple": $purple-active,
  "pink": $pink-active,
  "red": $red-active,
  "orange": $orange-active,
  "yellow": $yellow-active,
  "green": $green-active,
  "teal": $teal-active,
  "cyan": $cyan-active,
  "gray": $gray-active,
) !default; // Custom variable

// Light colors
$theme-light-colors: (
  "primary": $primary-light,
  "success": $success-light,
  "info": $info-light,
  "warning": $warning-light,
  "danger": $danger-light,
  "dark": $dark-light,
  "blue": $blue-light,
  "indigo": $indigo-light,
  "purple": $purple-light,
  "pink": $pink-light,
  "red": $red-light,
  "orange": $orange-light,
  "yellow": $yellow-light,
  "green": $green-light,
  "teal": $teal-light,
  "cyan": $cyan-light,
  "gray": $gray-light,
) !default; // Custom variable

// Lighten colors
$theme-lighten-colors: (
  "primary": $primary-lighten,
  "success": $success-lighten,
  "info": $info-lighten,
  "warning": $warning-lighten,
  "danger": $danger-lighten,
  "blue": $blue-lighten,
  "indigo": $indigo-lighten,
  "purple": $purple-lighten,
  "pink": $pink-lighten,
  "red": $red-lighten,
  "orange": $orange-lighten,
  "yellow": $yellow-lighten,
  "green": $green-lighten,
  "teal": $teal-lighten,
  "cyan": $cyan-lighten,
  "gray": $gray-lighten,
) !default; // Custom variable

// Text colors
$theme-text-colors: (
  "white": $white,
  "primary": $primary,
  "secondary": $secondary,
  "light": $light,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "dark": $dark,
  "black": $black,
  "muted": $text-muted,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "gray": $gray,
) !default; // Custom variable

// Inverse colors
$theme-inverse-colors: (
  "white": $white-inverse,
  "primary": $primary-inverse,
  "secondary": $secondary-inverse,
  "light": $light-inverse,
  "success": $success-inverse,
  "info": $info-inverse,
  "warning": $warning-inverse,
  "danger": $danger-inverse,
  "dark": $dark-inverse,
  "black": $black-inverse,
  "blue": $blue-inverse,
  "indigo": $indigo-inverse,
  "purple": $purple-inverse,
  "pink": $pink-inverse,
  "red": $red-inverse,
  "orange": $orange-inverse,
  "yellow": $yellow-inverse,
  "green": $green-inverse,
  "teal": $teal-inverse,
  "cyan": $cyan-inverse,
  "gray": $gray-inverse,
) !default; // Custom variable
