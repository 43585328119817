//
// Toolbar
//

mat-toolbar,
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar .h1,
.mat-toolbar h2,
.mat-toolbar .h2,
.mat-toolbar h3,
.mat-toolbar .h3,
.mat-toolbar h4,
.mat-toolbar .h4,
.mat-toolbar h5,
.mat-toolbar .h5,
.mat-toolbar h6,
.mat-toolbar .h6 {
  font: inherit;
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .mat-toolbar {
    height: get($header-config, default, height, tablet-and-mobile);
  }
}
