//
// Animation
//

// Base
.animation {
  animation-duration: 1s;
  animation-fill-mode: both;
}

// Slide In Down
@keyframes animationSlideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animation-slide-in-down {
  animation-name: animationSlideInDown;
}

// Slide In Up
@keyframes animationSlideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animation-slide-in-up {
  animation-name: animationSlideInUp;
}

// Fade In
@keyframes animationFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animation-fade-in {
  animation-name: animationFadeIn;
}

// Fade Out
@keyframes animationFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.animation-fade-out {
  animation-name: animationFadeOut;
}

// Blink
.animation-blink {
  animation: animationBlink 1s steps(5, start) infinite;
}

@keyframes animationBlink {
  to {
    visibility: hidden;
  }
}

// Shake
.animation-shake {
  position: relative;
  animation: shake 0.2s;
}

@keyframes shake {
  0%,
  100% {
    left: 0px;
  }

  20%,
  80% {
    left: -15px;
  }

  40%,
  60% {
    left: 15px;
  }
}
