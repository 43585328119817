//
// Badge
//

.badge {
  // Fixed size
  &.badge-circle,
  &.badge-square {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: $badge-size;
    min-width: $badge-size;
    padding: 0 0.1rem;
  }

  // Circle
  &.badge-circle {
    border-radius: 50%;
    padding: 0;
    min-width: unset;
    width: $badge-size;
  }
}

@each $name, $value in $theme-colors {
  // don't have themed
  .badge-#{$name} {
    color: var(--inverse-#{$name});
    background-color: var(--#{$name});
  }

  // if has themed
  .badge-#{$name} {
    @include themed() {
      color: theme-color(color-#{$name}-inverse) !important;
      background-color: theme-color(color-#{$name}) !important;
    }
  }

  .badge-light-#{$name} {
    color: $value;
    background-color: theme-light-color($name);
  }

  .badge-lighten-#{$name} {
    color: $value;
    background-color: theme-lighten-color($name);
  }
}
