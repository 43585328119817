//
// Avatar
//

// Base
.image-input {
  position: relative;
  display: inline-block;
  @include border-radius($border-radius);
  background-repeat: no-repeat;
  background-size: cover;

  // Empty state
  &:not(.image-input-empty) {
    background-image: none !important;
  }

  // Wrapper
  .image-input-wrapper {
    width: 120px;
    height: 120px;
    @include border-radius($border-radius);
    background-repeat: no-repeat;
    background-size: cover;
  }

  // Change Button
  [data-image-input-action="change"] {
    cursor: pointer;
    position: absolute;
    right: -10px;
    top: -10px;

    input {
      width: 0 !important;
      height: 0 !important;
      overflow: hidden;
      opacity: 0;
    }
  }

  // Cancel & Remove Buttons
  [data-image-input-action="cancel"],
  [data-image-input-action="remove"] {
    position: absolute;
    right: -10px;
    bottom: -5px;
  }

  [data-image-input-action="cancel"] {
    display: none;
  }

  // Input Changed State
  &.image-input-changed {
    [data-image-input-action="cancel"] {
      display: flex;
    }

    [data-image-input-action="remove"] {
      display: none;
    }
  }

  // Input Empty State
  &.image-input-empty {
    [data-image-input-action="remove"],
    [data-image-input-action="cancel"] {
      display: none;
    }
  }

  // Circle style
  &.image-input-circle {
    border-radius: 50%;

    // Wrapper
    .image-input-wrapper {
      border-radius: 50%;
    }

    // Change Control
    [data-action="change"] {
      right: 5px;
      top: 5px;
    }

    // Cancel & Remove Buttons
    [data-action="cancel"],
    [data-action="remove"] {
      right: 5px;
      bottom: 5px;
    }
  }

  // Bordered style
  &.image-input-outline {
    .image-input-wrapper {
      border: 3px solid $white;
      box-shadow: $box-shadow;
    }
  }
}
