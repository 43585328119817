//
// symbol
//

// Base
.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  @include border-radius($border-radius);

  // Label
  .symbol-label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    // don't have themed
    color: var(--gray-800);
    background-color: var(--gray-200);

    // if has themed
    @include themed() {
      color: theme-color(color-gray-800);
      background-color: theme-color(color-gray-200);
    }

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @include border-radius($border-radius);
  }

  // Badge
  .badge {
    position: absolute;

    // don't have themed
    border: 2px solid var(--gray-200);

    // if has themed
    @include themed() {
      border: 2px solid theme-color(color-gray-200);
    }

    top: 0;
    left: 100%;
    transform: translateX(-50%) translateY(-50%);
  }

  // Image
  >img {
    width: 100%;
    flex-shrink: 0;
    display: inline-block;
    @include border-radius($border-radius);
  }

  // Square
  &.symbol-square {

    &,
    >img,
    .symbol-label {
      border-radius: 0 !important;
    }
  }

  // Circle
  &.symbol-circle {

    &,
    >img,
    .symbol-label {
      border-radius: 50%;
    }
  }

  // Sizes
  @include symbol-size(get($symbol-sizes, default));

  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      @each $name,
      $value in $symbol-sizes {
        @if ($name !="default") {
          &.symbol#{$infix}-#{$name} {
            @include symbol-size($value);
          }
        }
      }
    }
  }
}

// Group
.symbol-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 10px;

  .symbol {
    position: relative;
    z-index: 0;
    margin-left: -20px;

    // don't have themed
    border: 2px solid var(--gray-200);

    // if has themed
    @include themed() {
      border: 2px solid theme-color(color-gray-200);
    }

    transition: all 0.3s ease;

    &:hover {
      transition: all 0.3s ease;
      z-index: 1;
    }
  }

  &.symbol-hover {
    .symbol {
      cursor: pointer;
    }
  }
}
