//
// Root CSS Variables
//

:root {

  --#{$primary}: var(--primary);

  // Theme colors
  @each $name,
  $value in $theme-colors {
    --#{$name}: #{$value};

    @if (theme-light-color($name)) {
      --light-#{$name}: #{theme-light-color($name)};
      --active-#{$name}: #{theme-active-color($name)};
      --inverse-#{$name}: #{theme-inverse-color($name)};
    }
  }

  // Gray colors
  @each $name,
  $value in $grays {
    --gray-#{$name}: #{$value};
  }

  // Breakpoints
  @each $breakpoint,
  $value in $grid-breakpoints {
    --#{$breakpoint}: #{$value};
  }
}

// @media (prefers-color-scheme: dark) {
//   body {
//     // Theme colors
//     @each $name, $value in $theme-dark-colors {
//       --#{$name}: #{$value};

//       @if (theme-light-color($name)) {
//         --light-#{$name}: #{theme-light-color($name)};
//         --active-#{$name}: #{theme-active-dark-color($name)};
//         --inverse-#{$name}: #{theme-inverse-dark-color($name)};
//       }
//     }

//     // Gray colors
//     @each $name, $value in $dark-grays {
//       --gray-#{$name}: #{$value};
//     }
//   }
// }
