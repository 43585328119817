//
// Color themes
//

// Basic custom colors
$color-body-bg: "color-body-bg";
$color-body-text: "color-body-text";

$color-canvas-header-bg: "color-body-header-bg";
$color-canvas-header-text: "color-body-header-text";

$color-canvas-body-bg: "color-body-body-bg";
$color-canvas-body-text: "color-body-body-text";

$color-toolbar-bg: "color-toolbar-bg";
$color-toolbar-text: "color-toolbar-text";

$color-focus-bg: "color-focus-bg";
$color-tabbar-text: "color-tabbar-text";

$color-card-bg: "color-card-bg";
$color-card-text: "color-card-text";

$color-item-bg: "color-item-bg";
$color-item-text: "color-item-text";

// Bootstrap theme colors
// Dark colors
$color-dark: "color-dark";
$color-dark-active: "color-dark-active";
$color-dark-inverse: "color-dark-inverse";
$color-darken: "color-darken";
$color-dark-light: "color-dark-light";

// Light colors
$color-light: "color-light";
$color-light-active: "color-light-active";
$color-light-inverse: "color-light-inverse";
$color-lighten: "color-lighten";

// Black colors
$color-black: "color-black";
$color-black-active: "color-black-active";
$color-black-inverse: "color-black-inverse";

// White colors
$color-white: "color-white";
$color-white-active: "color-white-active";
$color-white-inverse: "color-white-inverse";

// Primary colors
$color-primary: "color-primary";
$color-primary-active: "color-primary-active";
$color-primary-inverse: "color-primary-inverse";
$color-primary-lighten: "color-primary-lighten";
$color-primary-darken: "color-primary-darken";

// Secondary colors
$color-secondary: "color-secondary";
$color-secondary-active: "color-secondary-active";
$color-secondary-inverse: "color-secondary-inverse";

// Success colors
$color-success: "color-success";
$color-success-active: "color-success-active";
$color-success-inverse: "color-success-inverse";

// Info colors
$color-info: "color-info";
$color-info-active: "color-info-active";
$color-info-inverse: "color-info-inverse";

// Warning colors
$color-warning: "color-warning";
$color-warning-active: "color-warning-active";
$color-warning-inverse: "color-warning-inverse";

// Danger colors
$color-danger: "color-danger";
$color-danger-active: "color-danger-active";
$color-danger-inverse: "color-danger-inverse";

// Blue colors
$color-blue: "color-blue";
$color-blue-active: "color-blue-active";
$color-blue-inverse: "color-blue-inverse";

// Indigo colors
$color-indigo: "color-indigo";
$color-indigo-active: "color-indigo-active";
$color-indigo-inverse: "color-indigo-inverse";

// Purple colors
$color-purple: "color-purple";
$color-purple-active: "color-purple-active";
$color-purple-inverse: "color-purple-inverse";

// Pink colors
$color-pink: "color-pink";
$color-pink-active: "color-pink-active";
$color-pink-inverse: "color-pink-inverse";

// Red colors
$color-red: "color-red";
$color-red-active: "color-red-active";
$color-red-inverse: "color-red-inverse";

// Orange colors
$color-orange: "color-orange";
$color-orange-active: "color-orange-active";
$color-orange-inverse: "color-orange-inverse";

// Yellow colors
$color-yellow: "color-yellow";
$color-yellow-active: "color-yellow-active";
$color-yellow-inverse: "color-yellow-inverse";

// Green colors
$color-green: "color-green";
$color-green-active: "color-green-active";
$color-green-inverse: "color-green-inverse";

// Teal colors
$color-teal: "color-teal";
$color-teal-active: "color-teal-active";
$color-teal-inverse: "color-teal-inverse";

// Cyan colors
$color-cyan: "color-cyan";
$color-cyan-active: "color-cyan-active";
$color-cyan-inverse: "color-cyan-inverse";

// Gray colors
$color-gray: "color-gray";
$color-gray-active: "color-gray-active";
$color-gray-inverse: "color-gray-inverse";

// Muted colors
$color-muted: "color-muted";

// Bootstrap gray colors
$color-gray-100: "color-gray-100";
$color-gray-200: "color-gray-200";
$color-gray-300: "color-gray-300";
$color-gray-400: "color-gray-400";
$color-gray-500: "color-gray-500";
$color-gray-600: "color-gray-600";
$color-gray-700: "color-gray-700";
$color-gray-800: "color-gray-800";
$color-gray-900: "color-gray-900";

// Themes
$themes: (
  //
  // Light Colors
  //
  light:
    (
      // Basic custom colors
      color-body-bg: $body-bg,
      color-body-text: $body-color,
      color-canvas-header-bg: $canvas-header-bg,
      color-canvas-header-text: $canvas-header-color,
      color-canvas-body-bg: $canvas-body-bg,
      color-canvas-body-text: $canvas-body-color,
      color-toolbar-bg: $toolbar-bg,
      color-toolbar-text: $toolbar-color,
      color-focus-bg: $focus-bg,
      color-tabbar-text: $focus-color,
      color-card-bg: $card-bg,
      color-card-text: $card-color,
      color-item-bg: $item-bg,
      color-item-text: $item-color,
      // Bootstrap theme colors
      // Dark colors
      color-dark: $dark,
      color-dark-active: $dark-active,
      color-dark-inverse: $dark-inverse,
      color-darken: $darken,
      color-dark-light: $dark-light,
      // Light colors
      color-light: $light,
      color-light-active: $light-active,
      color-light-inverse: $light-inverse,
      color-lighten: $lighten,
      // Black colors
      color-black: $black,
      color-black-active: $black-active,
      color-black-inverse: $black-inverse,
      // White colors
      color-white: $white,
      color-white-active: $white-active,
      color-white-inverse: $white-inverse,
      // Primary colors
      color-primary: $primary,
      color-primary-active: $primary-active,
      color-primary-inverse: $primary-inverse,
      color-primary-lighten: $primary-lighten,
      // Secondary colors
      color-secondary: $secondary,
      color-secondary-active: $secondary-active,
      color-secondary-inverse: $secondary-inverse,
      // Success colors
      color-success: $success,
      color-success-active: $success-active,
      color-success-inverse: $success-inverse,
      // Info colors
      color-info: $info,
      color-info-active: $info-active,
      color-info-inverse: $info-inverse,
      // Warning colors
      color-warning: $warning,
      color-warning-active: $warning-active,
      color-warning-inverse: $warning-inverse,
      // Danger colors
      color-danger: $danger,
      color-danger-active: $danger-active,
      color-danger-inverse: $danger-inverse,
      // Blue colors
      color-blue: $blue,
      color-blue-active: $blue-active,
      color-blue-inverse: $blue-inverse,
      // Indigo colors
      color-indigo: $indigo,
      color-indigo-active: $indigo-active,
      color-indigo-inverse: $indigo-inverse,
      // Purple colors
      color-purple: $purple,
      color-purple-active: $purple-active,
      color-purple-inverse: $purple-inverse,
      // Pink colors
      color-pink: $pink,
      color-pink-active: $pink-active,
      color-pink-inverse: $pink-inverse,
      // Red colors
      color-red: $red,
      color-red-active: $red-active,
      color-red-inverse: $red-inverse,
      // Orange colors
      color-orange: $orange,
      color-orange-active: $orange-active,
      color-orange-inverse: $orange-inverse,
      // Yellow colors
      color-yellow: $yellow,
      color-yellow-active: $yellow-active,
      color-yellow-inverse: $yellow-inverse,
      // Green colors
      color-green: $green,
      color-green-active: $green-active,
      color-green-inverse: $green-inverse,
      // Teal colors
      color-teal: $teal,
      color-teal-active: $teal-active,
      color-teal-inverse: $teal-inverse,
      // Cyan colors
      color-cyan: $cyan,
      color-cyan-active: $cyan-active,
      color-cyan-inverse: $cyan-inverse,
      // Gray colors
      color-gray: $gray,
      color-gray-active: $gray-active,
      color-gray-inverse: $gray-inverse,
      // Muted colors
      color-muted: $gray-500,
      // Bootstrap gray colors
      color-gray-100: $gray-100,
      color-gray-200: $gray-200,
      color-gray-300: $gray-300,
      color-gray-400: $gray-400,
      color-gray-500: $gray-500,
      color-gray-600: $gray-600,
      color-gray-700: $gray-700,
      color-gray-800: $gray-800,
      color-gray-900: $gray-900
    ),
  //
  // Dark Colors
  //
  dark:
    (
      // Basic custom colors
      color-body-bg: $body-color,
      color-body-text: $body-bg,
      color-canvas-header-bg: $canvas-header-color,
      color-canvas-header-text: $canvas-header-bg,
      color-canvas-body-bg: $canvas-body-color,
      color-canvas-body-text: $canvas-body-bg,
      color-toolbar-bg: $toolbar-color,
      color-toolbar-text: $toolbar-bg,
      color-focus-bg: $focus-color,
      color-tabbar-text: $focus-bg,
      color-card-bg: $card-color,
      color-card-text: $card-bg,
      color-item-bg: $item-color,
      color-item-text: $item-bg,
      // Bootstrap theme colors
      // Dark colors
      color-dark: $light,
      color-dark-active: $light-active,
      color-dark-inverse: $light-inverse,
      color-darken: $lighten,
      color-dark-light: $lighten,
      // Light colors
      color-light: $dark,
      color-light-active: $dark-active,
      color-light-inverse: $dark-inverse,
      color-lighten: $darken,
      // Black colors
      color-black: $white,
      color-black-active: $white-active,
      color-black-inverse: $white-inverse,
      // White colors
      color-white: $black,
      color-white-active: $black-active,
      color-white-inverse: $black-inverse,
      // Primary colors
      color-primary: $primary,
      color-primary-active: $primary-active,
      color-primary-inverse: $primary-inverse,
      color-primary-lighten: $primary-darken,
      // Secondary colors
      color-secondary: $secondary,
      color-secondary-active: $secondary-active,
      color-secondary-inverse: $secondary-inverse,
      // Success colors
      color-success: $success,
      color-success-active: $success-active,
      color-success-inverse: $success-inverse,
      // Info colors
      color-info: $info,
      color-info-active: $info-active,
      // Warning colors
      color-warning: $warning,
      color-warning-active: $warning-active,
      // Danger colors
      color-danger: $danger,
      color-danger-active: $danger-active,
      // Blue colors
      color-blue: $blue,
      color-blue-active: $blue-active,
      // Indigo colors
      color-indigo: $indigo,
      color-indigo-active: $indigo-active,
      // Purple colors
      color-purple: $purple,
      color-purple-active: $purple-active,
      // Pink colors
      color-pink: $pink,
      color-pink-active: $pink-active,
      // Red colors
      color-red: $red,
      color-red-active: $red-active,
      // Orange colors
      color-orange: $orange,
      color-orange-active: $orange-active,
      // Yellow colors
      color-yellow: $yellow,
      color-yellow-active: $yellow-active,
      // Teal colors
      color-teal: $teal,
      color-teal-active: $teal-active,
      // Cyan colors
      color-cyan: $cyan,
      color-cyan-active: $cyan-active,
      // Gray colors
      color-gray: $gray,
      color-gray-active: $gray-active,
      // Muted colors
      color-muted: $gray-500,
      // Bootstrap gray colors
      color-gray-100: $gray-900,
      color-gray-200: $gray-800,
      color-gray-300: $gray-700,
      color-gray-400: $gray-600,
      color-gray-500: $gray-500,
      color-gray-600: $gray-400,
      color-gray-700: $gray-300,
      color-gray-800: $gray-200,
      color-gray-900: $gray-100
    )
);
