//
// Form Control
//

// Form control
.form-control {
  box-shadow: none !important;

  // Dropdown shown state
  .dropdown.show > & {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
  }

  // Readonly state
  &[readonly] {
    background-color: $input-readonly-bg;
  }

  // Disabled state
  &[disabled] {
    color: $gray-600 !important;
  }

  // Solid style
  &.form-control-solid {
    border: none !important;

    @include themed() {
      color: theme-color(color-item-text);
      background-color: theme-color(color-item-bg);
    }

    @include placeholder($input-solid-placeholder-color);
    transition: $transition-input;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      @include themed() {
        color: theme-color(color-tabbar-text);
        background-color: theme-color($color-focus-bg) !important;
      }

      border-color: $primary !important;
      transition: $transition-input;
    }
  }

  // White style
  &.form-control-white {
    background-color: $white;
    border-color: $white;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $white;
      border-color: $white;
    }
  }

  // Flush
  &.form-control-flush {
    @include input-reset();
  }
}

// Placeholder colors
.placeholder-gray-500 {
  @include placeholder($gray-500);
}

.placeholder-white {
  @include placeholder($white);
}

// Textarea reset resize
.resize-none {
  resize: none;
}
