//
// Separator
//

.separator {
  display: block;
  height: 0;

  // don't have themed
  border-bottom: 1px solid var(--gray-200);

  // if has themed
  @include themed() {
    border-bottom: 1px solid theme-color(color-gray-200);
  }

  &.separator-dotted {
    border-bottom-style: dotted;

    // don't have themed
    border-bottom-color: var(--gray-300);

    // if has themed
    @include themed() {
      border-bottom-color: theme-color(color-gray-300);
    }
  }

  &.separator-dashed {
    border-bottom-style: dashed;

    // don't have themed
    border-bottom-color: var(--gray-300);

    // if has themed
    @include themed() {
      border-bottom-color: theme-color(color-gray-300);
    }
  }
}
