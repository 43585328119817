//
// Timeline Label
//
$label-width: 50px;

.timeline-label {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: $label-width + 13px;
    width: 3px;
    top: 0;
    bottom: 0;
    background-color: $gray-200;
    // don't have themed
    background-color: var(--gray-200);

    // if has themed
    @include themed() {
      background-color: theme-color($color-gray-200);
    }
  }

  //
  .timeline-item {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-bottom: 1.7rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .timeline-label {
    width: $label-width;
    flex-shrink: 0;
    position: relative;
    // don't have themed
    color: var(--gray-800);

    // if has themed
    @include themed() {
      color: theme-color($color-gray-800);
    }
  }

  .timeline-badge {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    margin-top: 1px;
    margin-left: 5px;
    padding: 3px !important;
    background: var(--white);
    border: 6px solid var(--white) !important;

    span {
      display: block;
      border-radius: 100%;
      width: 6px;
      height: 6px;
      // don't have themed
      background-color: var(--gray-200);

      // if has themed
      @include themed() {
        background-color: theme-color($color-gray-200);
      }
    }
  }

  .timeline-content {
    flex-grow: 1;
  }
}
