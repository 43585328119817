//
// Overlay
//

.mat-select-panel .mat-option {
  height: auto !important;
  min-height: 3em !important;
  white-space: normal !important;
  line-height: 2em !important;
}
