//
// Buttons
//

.btn-primary {
  color: var(--inverse-primary) !important;
  background-color: var(--primary) !important;
}

.btn-link {
  color: var(--primary) !important;
}
