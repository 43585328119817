//
// Theme Base Colors
//

// Bootstrap base colors
$white: #ffffff !default;
$gray-100: #f5f8fa !default;
$gray-200: #eff2f5 !default;
$gray-300: #e4e6ef !default;
$gray-400: #b5b5c3 !default;
$gray-500: #a1a5b7 !default;
$gray-600: #7e8299 !default;
$gray-700: #5e6278 !default;
$gray-800: #3f4254 !default;
$gray-900: #181c32 !default;
$black: #000000 !default;
$text-muted: $gray-500 !default;

// Basic custom colors
// Primary
// Use _override.scss for current branding in this file. ed6221-senkron 216FED-lapis 43ad9b-senyonet

$success: #1BC5BC !default;
$info: #5F5CF1 !default;
$warning: #FFA800 !default;
$danger: #F65464 !default;

// Bootstrap custom colors
$blue: #3699FF !default;
$indigo: #6610f2 !default;
$purple: #5F5CF1 !default;
$pink: #d63384 !default;
$red: #F65464 !default;
$orange: #fd7e14 !default;
$yellow: #FFA800 !default;
$green: #1BC5BC !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

// Body
//
// Settings for the `<body>` element.
$body-bg: #f5f8fa !default;
$body-color: #151521 !default;
$body-text-align: null !default;

$canvas-header-bg: #f5f8fa !default;
$canvas-header-color: #323248 !default;

$canvas-body-bg: #ffffff !default;
$canvas-body-color: #2b2b40 !default;

$toolbar-bg: #ffffff !default;
$toolbar-color: #1a1a27 !default;

$card-bg: #ffffff !default;
$card-color: #1e1e2d !default;

$item-bg: #e4e6ef !default;
$item-color: #1b1b29 !default;

$focus-bg: #eef3f7 !default;
$focus-color: #171723 !default;
