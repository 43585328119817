//
// toolbar
//

// Desktop mode
@include media-breakpoint-up(lg) {
  .toolbar {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    transition: left 0.3s ease, right 0.3s ease;
    box-shadow: get($toolbar-config, fixed, box-shadow);

    @include themed() {
      color: theme-color($color-toolbar-text);
      background: theme-color($color-toolbar-bg);
    }

    // Fixed toolbar
    .toolbar-fixed & {
      height: get($toolbar-config, fixed, height, desktop);
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 2;
      box-shadow: get($toolbar-config, fixed, box-shadow);
    }

    // toolbar enabled & toolbar not fixed modes
    .toolbar-enabled:not(.toolbar-fixed) & {
      margin-bottom: get($content-spacing, desktop);
    }

    // Fixed header & fixed toolbar modes
    .header-fixed.toolbar-fixed & {
      top: get($header-config, fixed, height, desktop);

      @include themed() {
        border-top: 1px solid theme-color($color-gray-100);
      }
    }

    // Aside default enabled, aside fixed & header sticky modes
    .aside-enabled.aside-fixed.toolbar-fixed & {
      left: get($aside-config, width) !important;
      transition: left get($aside-config, transition-speed) ease;
    }

    // Aside default enabled, aside fixed, header sticky & aside minimize modes
    .aside-enabled.aside-fixed.toolbar-fixed[data-aside-minimize="on"] & {
      left: get($aside-config, minimized-width) !important;
      transition: left get($aside-config, transition-speed) ease;
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .toolbar {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    transition: left 0.3s ease, right 0.3s ease;

    @include themed() {
      color: theme-color($color-toolbar-text);
      background: theme-color($color-toolbar-bg);
    }

    // Fixed toolbar mode
    .toolbar-tablet-and-mobile-fixed & {
      height: get($toolbar-config, fixed, height, tablet-and-mobile);
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: get($toolbar-config, fixed, z-index);
      box-shadow: get($toolbar-config, fixed, box-shadow);
    }

    // Toolbar enabled & toolbar not fixed modes
    .toolbar-enabled:not(.toolbar-tablet-and-mobile-fixed) & {
      margin: 0 0 get($content-spacing, tablet-and-mobile);
    }

    // Fixed header & fixed toolbar modes
    .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed & {
      top: get($header-config, default, height, tablet-and-mobile);
    }
  }

  // Page title
  .page-title {
    margin: get($content-spacing, tablet-and-mobile) !important;
  }
}
