//
// SVG Symbol
//

.svg-symbol {
  line-height: 1;

  svg {
    width: 100%;
    height: 100%;
    @include themed() {
      fill: theme-color(color-black);
    }
  }

  // Theme colors
  @each $name, $color in $theme-text-colors {
    &.svg-symbol-#{$name} {
      svg {
        fill: var(--#{$name});
      }
    }
  }
}
