//
// To make future updates easier consider the global variables from _override.scss for current branding in this file.
//

// Primary
$primary: #e10e8d;
$primary-active: #a90768;
$primary-light: #fff1fe;
$primary-lighten: rgb(255, 241, 254,.5);
$primary-darken: #361a2b;
$primary-inverse: #ffffff;


.menu-state-title-primary .menu-item.hover > .menu-link:not(.disabled):not(.active), .menu-state-title-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  color: var(--primary) !important;
}

.menu-state-title-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title, .menu-state-title-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: var(--primary) !important;
}

.btn-check:checked + .btn.btn-active-light-primary, .btn-check:active + .btn.btn-active-light-primary, .btn.btn-active-light-primary:focus:not(.btn-active), .btn.btn-active-light-primary:hover:not(.btn-active), .btn.btn-active-light-primary:active:not(.btn-active), .btn.btn-active-light-primary.active, .btn.btn-active-light-primary.show, .btn.btn-active-light-primary .show, .btn.btn-active-light-primary[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-primary {
  background-color: rgba( var(--primary-rgb), 0.1) !important;
}

.aside-dark .menu .menu-item .menu-link.active .menu-title {
  color: var(--primary) !important;
}

.aside-dark .menu .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  fill: var(--primary) !important
}

.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  fill: var(--primary) !important
}

.bg-primary {
  background-color: var(--primary-rgb) !important;
}

.bg-hover-primary:hover {
  background-color: var(--primary) !important;
}

.bg-light-primary {
  background-color: rgba( var(--primary-rgb), 0.1) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--primary) !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--primary) !important;
}

.mat-calendar-body-in-range::before {
  background-color: rgba( var(--primary-rgb), 0.1) !important;
}

.mat-calendar-body-selected {
  color: var(--inverse-primary) !important;
  background-color: var(--primary) !important;
}


.mat-datepicker-toggle-active {
  color: var(--primary) !important;
}
