//
// Popover
//

@use 'sass:math';

// Base
.popover {
  border: none;

  .popover-header {
    font-size: $popover-header-font-size;
    font-weight: $popover-header-font-weight;

    @include themed() {
      color: theme-color(color-canvas-body-text) !important;
      background-color: theme-color(color-canvas-body-bg) !important;
      border-bottom: 1px solid theme-color(color-canvas-header-bg) !important;
    }
  }

  .popover-body {
    @include themed() {
      color: theme-color(color-canvas-body-text) !important;
      background-color: theme-color(color-canvas-body-bg) !important;
    }
  }

  /*rtl:raw:
    left: 0 !important;
    right: auto !important;
    */

  &.bs-popover-right {
    margin-right: auto#{"/*rtl:ignore*/"};
    margin-left: $popover-arrow-height#{"/*rtl:ignore*/"};

    >.arrow {
      right: auto#{"/*rtl:ignore*/"};
      left: subtract(-$popover-arrow-height, $popover-border-width) #{"/*rtl:ignore*/"};

      &::before {
        right: auto#{"/*rtl:ignore*/"};
        left: 0#{"/*rtl:ignore*/"};
        border-width: divide($popover-arrow-width, 2) $popover-arrow-height divide($popover-arrow-width, 2) 0#{"/*rtl:ignore*/"};
        border-right-color: $popover-arrow-outer-color#{"/*rtl:ignore*/"};
      }

      &::after {
        right: auto#{"/*rtl:ignore*/"};
        left: $popover-border-width#{"/*rtl:ignore*/"};
        border-width: divide($popover-arrow-width, 2) $popover-arrow-height divide($popover-arrow-width, 2) 0#{"/*rtl:ignore*/"};
        border-right-color: $popover-arrow-color#{"/*rtl:ignore*/"};
      }
    }
  }

  &.bs-popover-left {
    margin-left: auto#{"/*rtl:ignore*/"};
    margin-right: $popover-arrow-height#{"/*rtl:ignore*/"};

    >.arrow {
      left: auto#{"/*rtl:ignore*/"};
      right: subtract(-$popover-arrow-height, $popover-border-width) #{"/*rtl:ignore*/"};

      &::before {
        left: auto#{"/*rtl:ignore*/"};
        right: 0#{"/*rtl:ignore*/"};
        border-width: divide($popover-arrow-width, 2) 0 divide($popover-arrow-width, 2) $popover-arrow-height#{"/*rtl:ignore*/"};
        border-left-color: $popover-arrow-outer-color#{"/*rtl:ignore*/"};
      }

      &::after {
        left: auto#{"/*rtl:ignore*/"};
        right: $popover-border-width#{"/*rtl:ignore*/"};
        border-width: divide($popover-arrow-width, 2) 0 divide($popover-arrow-width, 2) $popover-arrow-height#{"/*rtl:ignore*/"};
        border-left-color: $popover-arrow-color#{"/*rtl:ignore*/"};
      }
    }
  }
}

// Dark skin
.popover-dark {
  @include popover-theme($gray-900, $gray-800, $gray-900, $gray-200, $gray-400, $gray-100, $gray-900);
  // $bg-color, $border-color, $header-bg-color, $header-color, $body-color, $arrow-outer-color, $arrow-color
}
