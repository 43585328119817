//
// SVG Icon
//

.svg-icon {
  line-height: 1;

  @include svg-icon-size(get($font-sizes, 5));
  @include svg-icon-color($text-muted);

  // Theme colors
  @each $name, $color in $theme-text-colors {
    &.svg-icon-#{$name} {
      svg {
        [fill]:not(.permanent):not(g) {
          transition: fill 0.3s ease;
          fill: var(--#{$name}) !important;
        }

        &:hover {
          [fill]:not(.permanent):not(g) {
            transition: fill 0.3s ease;
          }
        }
      }
    }

    &.svg-icon-#{$name} {
      @include themed() {
        @include svg-icon-color(theme-color(color-#{$name}));
      }
    }
  }

  // Responsive icon sizes
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      // Sizes
      @each $name, $value in $font-sizes {
        &.svg-icon#{$infix}-#{$name} {
          @include svg-icon-size($value, true);
        }
      }
    }
  }
}

.svg-icon-100 {
  svg {
    height: 100% !important;
    width: 100% !important;
  }
}

.svg-icon-transparent {
  svg {
    [fill]:not(.permanent):not(g) {
      transition: fill 0.3s ease;
      fill: transparent !important;
    }
    height: 20rem !important;
    width: 20rem !important;
  }
}
