//
// Drawer
//

// Drawer
.drawer {
  display: flex !important;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;

  @include themed() {
    color: theme-color($color-card-text);
    background-color: theme-color($color-card-bg);
  }

  &.drawer-dark {
    background-color: $card-color;

    mat-label {
      color: $card-bg;
    }
  }

  transition: transform get($drawer, transition-speed) ease-in-out;

  &.drawer-start {
    left: 0;
    transform: translateX(-100%);
  }

  &.drawer-end {
    right: 0;
    transform: translateX(100%);
  }

  &.drawer-on {
    transform: none;
    box-shadow: get($drawer, box-shadow);
    transition: transform get($drawer, transition-speed) ease-in-out;
  }

  &.animations-enabled {
    transition-property: transform, width;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
  }
}

// Drawer Overlay
.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: get($drawer, z-index) - 1;
  background-color: get($drawer, overlay-bg-color);
  animation: animation-drawer-fade-in get($drawer, overlay-animation-speed) ease-in-out 1;
}

// Initial state
[data-drawer="true"] {
  display: none;
}

// Initial state
body[data-drawer="on"] {
  overflow: hidden;

  .drawer {
    z-index: get($drawer, z-index);
  }
}

body[data-drawer="swipe"] {
  .drawer {
    z-index: 2;
  }
}

// Desktop mode
@include media-breakpoint-up(lg) {
  body[data-drawer="swipe"] {
    &[data-aside-minimize="on"] {
      &.aside-enabled .drawer-start {
        left: get($aside-config, minimized-width);
        transition: 0.3s ease;
      }

      &.toolbar-fixed {
        &.aside-enabled {
          .drawer-start {
            top: calc(var(--header-height) + var(--toolbar-height)) !important;
            transition: 0.3s ease;
          }
        }
      }
    }

    &.aside-enabled {
      .drawer-start {
        left: get($aside-config, width);
        transition: 0.3s ease;
      }
    }

    &.toolbar-fixed {
      &.aside-enabled {
        .drawer-start {
          top: calc(var(--header-height) + var(--toolbar-height)) !important;
          transition: 0.3s ease;
        }
      }
    }
  }
}

// Animation
@keyframes animation-drawer-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
