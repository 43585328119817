@charset "UTF-8";
/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */
/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: local("Poppins Light"), local("Poppins-Light"), url(pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: local("Poppins Light"), local("Poppins-Light"), url(pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: local("Poppins Light"), local("Poppins-Light"), url(pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins Regular"), local("Poppins-Regular"), url(pxiEyp8kv8JHgFVrJJbecmNE.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins Regular"), local("Poppins-Regular"), url(pxiEyp8kv8JHgFVrJJnecmNE.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins Regular"), local("Poppins-Regular"), url(pxiEyp8kv8JHgFVrJJfecg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Medium"), local("Poppins-Medium"), url(pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Medium"), local("Poppins-Medium"), url(pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Medium"), local("Poppins-Medium"), url(pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url(pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url(pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url(pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local("Poppins Bold"), local("Poppins-Bold"), url(pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local("Poppins Bold"), local("Poppins-Bold"), url(pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local("Poppins Bold"), local("Poppins-Bold"), url(pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.menu-state-title-primary .menu-item.hover > .menu-link:not(.disabled):not(.active), .menu-state-title-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  color: var(--primary) !important;
}

.menu-state-title-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title, .menu-state-title-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: var(--primary) !important;
}

.btn-check:checked + .btn.btn-active-light-primary, .btn-check:active + .btn.btn-active-light-primary, .btn.btn-active-light-primary:focus:not(.btn-active), .btn.btn-active-light-primary:hover:not(.btn-active), .btn.btn-active-light-primary:active:not(.btn-active), .btn.btn-active-light-primary.active, .btn.btn-active-light-primary.show, .btn.btn-active-light-primary .show, .btn.btn-active-light-primary[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-primary {
  background-color: rgba(var(--primary-rgb), 0.1) !important;
}

.aside-dark .menu .menu-item .menu-link.active .menu-title {
  color: var(--primary) !important;
}

.aside-dark .menu .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  fill: var(--primary) !important;
}

.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  fill: var(--primary) !important;
}

.bg-primary {
  background-color: var(--primary-rgb) !important;
}

.bg-hover-primary:hover {
  background-color: var(--primary) !important;
}

.bg-light-primary {
  background-color: rgba(var(--primary-rgb), 0.1) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--primary) !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--primary) !important;
}

.mat-calendar-body-in-range::before {
  background-color: rgba(var(--primary-rgb), 0.1) !important;
}

.mat-calendar-body-selected {
  color: var(--inverse-primary) !important;
  background-color: var(--primary) !important;
}

.mat-datepicker-toggle-active {
  color: var(--primary) !important;
}

/*
 * Application global variables.
 */
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Poppins, Helvetica, "sans-serif";
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1, .mat-typography .h1 {
  font: 400 24px/32px Poppins, Helvetica, "sans-serif";
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2, .mat-typography .h2 {
  font: bold 24px/32px Poppins, Helvetica, "sans-serif";
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3, .mat-typography .h3 {
  font: 400 16px/28px Poppins, Helvetica, "sans-serif";
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4, .mat-typography .h4 {
  font: 400 15px/24px Poppins, Helvetica, "sans-serif";
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5, .mat-typography .h5 {
  font: 400 calc(14px * 0.83)/20px Poppins, Helvetica, "sans-serif";
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6, .mat-typography .h6 {
  font: 400 calc(14px * 0.67)/20px Poppins, Helvetica, "sans-serif";
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Poppins, Helvetica, "sans-serif";
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Poppins, Helvetica, "sans-serif";
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Poppins, Helvetica, "sans-serif";
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Poppins, Helvetica, "sans-serif";
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Poppins, Helvetica, "sans-serif";
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Poppins, Helvetica, "sans-serif";
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Poppins, Helvetica, "sans-serif";
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Poppins, Helvetica, "sans-serif";
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Poppins, Helvetica, "sans-serif";
}

.mat-card {
  font-family: Poppins, Helvetica, "sans-serif";
}

.mat-card-title {
  font-size: 24px;
  font-weight: bold;
}

.mat-card-header .mat-card-title {
  font-size: 24px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Poppins, Helvetica, "sans-serif";
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Poppins, Helvetica, "sans-serif";
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Poppins, Helvetica, "sans-serif";
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: bold 24px/32px Poppins, Helvetica, "sans-serif";
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Poppins, Helvetica, "sans-serif";
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Poppins, Helvetica, "sans-serif";
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: 12px;
}

.mat-radio-button {
  font-family: Poppins, Helvetica, "sans-serif";
}

.mat-select {
  font-family: Poppins, Helvetica, "sans-serif";
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Poppins, Helvetica, "sans-serif";
}

.mat-slider-thumb-label-text {
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Poppins, Helvetica, "sans-serif";
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Poppins, Helvetica, "sans-serif";
}

.mat-tab-label, .mat-tab-link {
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar .h1,
.mat-toolbar h2,
.mat-toolbar .h2,
.mat-toolbar h3,
.mat-toolbar .h3,
.mat-toolbar h4,
.mat-toolbar .h4,
.mat-toolbar h5,
.mat-toolbar .h5,
.mat-toolbar h6,
.mat-toolbar .h6 {
  font: bold 24px/32px Poppins, Helvetica, "sans-serif";
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Poppins, Helvetica, "sans-serif";
}

.mat-list-option {
  font-family: Poppins, Helvetica, "sans-serif";
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Poppins, Helvetica, "sans-serif";
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Poppins, Helvetica, "sans-serif";
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.eot");
  /* For IE6-8 */
  src: local("☺"), url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff2") format("woff2"), url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.woff") format("woff"), url("~material-design-icons-iconfont/dist/fonts/MaterialIcons-Regular.ttf") format("truetype");
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  /* Respect document layout direction */
  direction: inherit;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}
.material-icons._10k:before {
  content: "\e951";
}
.material-icons._10mp:before {
  content: "\e952";
}
.material-icons._11mp:before {
  content: "\e953";
}
.material-icons._123:before {
  content: "\eb8d";
}
.material-icons._12mp:before {
  content: "\e954";
}
.material-icons._13mp:before {
  content: "\e955";
}
.material-icons._14mp:before {
  content: "\e956";
}
.material-icons._15mp:before {
  content: "\e957";
}
.material-icons._16mp:before {
  content: "\e958";
}
.material-icons._17mp:before {
  content: "\e959";
}
.material-icons._18_up_rating:before {
  content: "\f8fd";
}
.material-icons._18mp:before {
  content: "\e95a";
}
.material-icons._19mp:before {
  content: "\e95b";
}
.material-icons._1k:before {
  content: "\e95c";
}
.material-icons._1k_plus:before {
  content: "\e95d";
}
.material-icons._1x_mobiledata:before {
  content: "\efcd";
}
.material-icons._20mp:before {
  content: "\e95e";
}
.material-icons._21mp:before {
  content: "\e95f";
}
.material-icons._22mp:before {
  content: "\e960";
}
.material-icons._23mp:before {
  content: "\e961";
}
.material-icons._24mp:before {
  content: "\e962";
}
.material-icons._2k:before {
  content: "\e963";
}
.material-icons._2k_plus:before {
  content: "\e964";
}
.material-icons._2mp:before {
  content: "\e965";
}
.material-icons._30fps:before {
  content: "\efce";
}
.material-icons._30fps_select:before {
  content: "\efcf";
}
.material-icons._360:before {
  content: "\e577";
}
.material-icons._3d_rotation:before {
  content: "\e84d";
}
.material-icons._3g_mobiledata:before {
  content: "\efd0";
}
.material-icons._3k:before {
  content: "\e966";
}
.material-icons._3k_plus:before {
  content: "\e967";
}
.material-icons._3mp:before {
  content: "\e968";
}
.material-icons._3p:before {
  content: "\efd1";
}
.material-icons._4g_mobiledata:before {
  content: "\efd2";
}
.material-icons._4g_plus_mobiledata:before {
  content: "\efd3";
}
.material-icons._4k:before {
  content: "\e072";
}
.material-icons._4k_plus:before {
  content: "\e969";
}
.material-icons._4mp:before {
  content: "\e96a";
}
.material-icons._5g:before {
  content: "\ef38";
}
.material-icons._5k:before {
  content: "\e96b";
}
.material-icons._5k_plus:before {
  content: "\e96c";
}
.material-icons._5mp:before {
  content: "\e96d";
}
.material-icons._60fps:before {
  content: "\efd4";
}
.material-icons._60fps_select:before {
  content: "\efd5";
}
.material-icons._6_ft_apart:before {
  content: "\f21e";
}
.material-icons._6k:before {
  content: "\e96e";
}
.material-icons._6k_plus:before {
  content: "\e96f";
}
.material-icons._6mp:before {
  content: "\e970";
}
.material-icons._7k:before {
  content: "\e971";
}
.material-icons._7k_plus:before {
  content: "\e972";
}
.material-icons._7mp:before {
  content: "\e973";
}
.material-icons._8k:before {
  content: "\e974";
}
.material-icons._8k_plus:before {
  content: "\e975";
}
.material-icons._8mp:before {
  content: "\e976";
}
.material-icons._9k:before {
  content: "\e977";
}
.material-icons._9k_plus:before {
  content: "\e978";
}
.material-icons._9mp:before {
  content: "\e979";
}
.material-icons.abc:before {
  content: "\eb94";
}
.material-icons.ac_unit:before {
  content: "\eb3b";
}
.material-icons.access_alarm:before {
  content: "\e190";
}
.material-icons.access_alarms:before {
  content: "\e191";
}
.material-icons.access_time:before {
  content: "\e192";
}
.material-icons.access_time_filled:before {
  content: "\efd6";
}
.material-icons.accessibility:before {
  content: "\e84e";
}
.material-icons.accessibility_new:before {
  content: "\e92c";
}
.material-icons.accessible:before {
  content: "\e914";
}
.material-icons.accessible_forward:before {
  content: "\e934";
}
.material-icons.account_balance:before {
  content: "\e84f";
}
.material-icons.account_balance_wallet:before {
  content: "\e850";
}
.material-icons.account_box:before {
  content: "\e851";
}
.material-icons.account_circle:before {
  content: "\e853";
}
.material-icons.account_tree:before {
  content: "\e97a";
}
.material-icons.ad_units:before {
  content: "\ef39";
}
.material-icons.adb:before {
  content: "\e60e";
}
.material-icons.add:before {
  content: "\e145";
}
.material-icons.add_a_photo:before {
  content: "\e439";
}
.material-icons.add_alarm:before {
  content: "\e193";
}
.material-icons.add_alert:before {
  content: "\e003";
}
.material-icons.add_box:before {
  content: "\e146";
}
.material-icons.add_business:before {
  content: "\e729";
}
.material-icons.add_call:before {
  content: "\e0e8";
}
.material-icons.add_card:before {
  content: "\eb86";
}
.material-icons.add_chart:before {
  content: "\e97b";
}
.material-icons.add_circle:before {
  content: "\e147";
}
.material-icons.add_circle_outline:before {
  content: "\e148";
}
.material-icons.add_comment:before {
  content: "\e266";
}
.material-icons.add_home:before {
  content: "\f8eb";
}
.material-icons.add_home_work:before {
  content: "\f8ed";
}
.material-icons.add_ic_call:before {
  content: "\e97c";
}
.material-icons.add_link:before {
  content: "\e178";
}
.material-icons.add_location:before {
  content: "\e567";
}
.material-icons.add_location_alt:before {
  content: "\ef3a";
}
.material-icons.add_moderator:before {
  content: "\e97d";
}
.material-icons.add_photo_alternate:before {
  content: "\e43e";
}
.material-icons.add_reaction:before {
  content: "\e1d3";
}
.material-icons.add_road:before {
  content: "\ef3b";
}
.material-icons.add_shopping_cart:before {
  content: "\e854";
}
.material-icons.add_task:before {
  content: "\f23a";
}
.material-icons.add_to_drive:before {
  content: "\e65c";
}
.material-icons.add_to_home_screen:before {
  content: "\e1fe";
}
.material-icons.add_to_photos:before {
  content: "\e39d";
}
.material-icons.add_to_queue:before {
  content: "\e05c";
}
.material-icons.addchart:before {
  content: "\ef3c";
}
.material-icons.adf_scanner:before {
  content: "\eada";
}
.material-icons.adjust:before {
  content: "\e39e";
}
.material-icons.admin_panel_settings:before {
  content: "\ef3d";
}
.material-icons.adobe:before {
  content: "\ea96";
}
.material-icons.ads_click:before {
  content: "\e762";
}
.material-icons.agriculture:before {
  content: "\ea79";
}
.material-icons.air:before {
  content: "\efd8";
}
.material-icons.airline_seat_flat:before {
  content: "\e630";
}
.material-icons.airline_seat_flat_angled:before {
  content: "\e631";
}
.material-icons.airline_seat_individual_suite:before {
  content: "\e632";
}
.material-icons.airline_seat_legroom_extra:before {
  content: "\e633";
}
.material-icons.airline_seat_legroom_normal:before {
  content: "\e634";
}
.material-icons.airline_seat_legroom_reduced:before {
  content: "\e635";
}
.material-icons.airline_seat_recline_extra:before {
  content: "\e636";
}
.material-icons.airline_seat_recline_normal:before {
  content: "\e637";
}
.material-icons.airline_stops:before {
  content: "\e7d0";
}
.material-icons.airlines:before {
  content: "\e7ca";
}
.material-icons.airplane_ticket:before {
  content: "\efd9";
}
.material-icons.airplanemode_active:before {
  content: "\e195";
}
.material-icons.airplanemode_inactive:before {
  content: "\e194";
}
.material-icons.airplanemode_off:before {
  content: "\e194";
}
.material-icons.airplanemode_on:before {
  content: "\e195";
}
.material-icons.airplay:before {
  content: "\e055";
}
.material-icons.airport_shuttle:before {
  content: "\eb3c";
}
.material-icons.alarm:before {
  content: "\e855";
}
.material-icons.alarm_add:before {
  content: "\e856";
}
.material-icons.alarm_off:before {
  content: "\e857";
}
.material-icons.alarm_on:before {
  content: "\e858";
}
.material-icons.album:before {
  content: "\e019";
}
.material-icons.align_horizontal_center:before {
  content: "\e00f";
}
.material-icons.align_horizontal_left:before {
  content: "\e00d";
}
.material-icons.align_horizontal_right:before {
  content: "\e010";
}
.material-icons.align_vertical_bottom:before {
  content: "\e015";
}
.material-icons.align_vertical_center:before {
  content: "\e011";
}
.material-icons.align_vertical_top:before {
  content: "\e00c";
}
.material-icons.all_inbox:before {
  content: "\e97f";
}
.material-icons.all_inclusive:before {
  content: "\eb3d";
}
.material-icons.all_out:before {
  content: "\e90b";
}
.material-icons.alt_route:before {
  content: "\f184";
}
.material-icons.alternate_email:before {
  content: "\e0e6";
}
.material-icons.amp_stories:before {
  content: "\ea13";
}
.material-icons.analytics:before {
  content: "\ef3e";
}
.material-icons.anchor:before {
  content: "\f1cd";
}
.material-icons.android:before {
  content: "\e859";
}
.material-icons.animation:before {
  content: "\e71c";
}
.material-icons.announcement:before {
  content: "\e85a";
}
.material-icons.aod:before {
  content: "\efda";
}
.material-icons.apartment:before {
  content: "\ea40";
}
.material-icons.api:before {
  content: "\f1b7";
}
.material-icons.app_blocking:before {
  content: "\ef3f";
}
.material-icons.app_registration:before {
  content: "\ef40";
}
.material-icons.app_settings_alt:before {
  content: "\ef41";
}
.material-icons.app_shortcut:before {
  content: "\eae4";
}
.material-icons.apple:before {
  content: "\ea80";
}
.material-icons.approval:before {
  content: "\e982";
}
.material-icons.apps:before {
  content: "\e5c3";
}
.material-icons.apps_outage:before {
  content: "\e7cc";
}
.material-icons.architecture:before {
  content: "\ea3b";
}
.material-icons.archive:before {
  content: "\e149";
}
.material-icons.area_chart:before {
  content: "\e770";
}
.material-icons.arrow_back:before {
  content: "\e5c4";
}
.material-icons.arrow_back_ios:before {
  content: "\e5e0";
}
.material-icons.arrow_back_ios_new:before {
  content: "\e2ea";
}
.material-icons.arrow_circle_down:before {
  content: "\f181";
}
.material-icons.arrow_circle_left:before {
  content: "\eaa7";
}
.material-icons.arrow_circle_right:before {
  content: "\eaaa";
}
.material-icons.arrow_circle_up:before {
  content: "\f182";
}
.material-icons.arrow_downward:before {
  content: "\e5db";
}
.material-icons.arrow_drop_down:before {
  content: "\e5c5";
}
.material-icons.arrow_drop_down_circle:before {
  content: "\e5c6";
}
.material-icons.arrow_drop_up:before {
  content: "\e5c7";
}
.material-icons.arrow_forward:before {
  content: "\e5c8";
}
.material-icons.arrow_forward_ios:before {
  content: "\e5e1";
}
.material-icons.arrow_left:before {
  content: "\e5de";
}
.material-icons.arrow_right:before {
  content: "\e5df";
}
.material-icons.arrow_right_alt:before {
  content: "\e941";
}
.material-icons.arrow_upward:before {
  content: "\e5d8";
}
.material-icons.art_track:before {
  content: "\e060";
}
.material-icons.article:before {
  content: "\ef42";
}
.material-icons.aspect_ratio:before {
  content: "\e85b";
}
.material-icons.assessment:before {
  content: "\e85c";
}
.material-icons.assignment:before {
  content: "\e85d";
}
.material-icons.assignment_ind:before {
  content: "\e85e";
}
.material-icons.assignment_late:before {
  content: "\e85f";
}
.material-icons.assignment_return:before {
  content: "\e860";
}
.material-icons.assignment_returned:before {
  content: "\e861";
}
.material-icons.assignment_turned_in:before {
  content: "\e862";
}
.material-icons.assistant:before {
  content: "\e39f";
}
.material-icons.assistant_direction:before {
  content: "\e988";
}
.material-icons.assistant_navigation:before {
  content: "\e989";
}
.material-icons.assistant_photo:before {
  content: "\e3a0";
}
.material-icons.assured_workload:before {
  content: "\eb6f";
}
.material-icons.atm:before {
  content: "\e573";
}
.material-icons.attach_email:before {
  content: "\ea5e";
}
.material-icons.attach_file:before {
  content: "\e226";
}
.material-icons.attach_money:before {
  content: "\e227";
}
.material-icons.attachment:before {
  content: "\e2bc";
}
.material-icons.attractions:before {
  content: "\ea52";
}
.material-icons.attribution:before {
  content: "\efdb";
}
.material-icons.audio_file:before {
  content: "\eb82";
}
.material-icons.audiotrack:before {
  content: "\e3a1";
}
.material-icons.auto_awesome:before {
  content: "\e65f";
}
.material-icons.auto_awesome_mosaic:before {
  content: "\e660";
}
.material-icons.auto_awesome_motion:before {
  content: "\e661";
}
.material-icons.auto_delete:before {
  content: "\ea4c";
}
.material-icons.auto_fix_high:before {
  content: "\e663";
}
.material-icons.auto_fix_normal:before {
  content: "\e664";
}
.material-icons.auto_fix_off:before {
  content: "\e665";
}
.material-icons.auto_graph:before {
  content: "\e4fb";
}
.material-icons.auto_mode:before {
  content: "\ec20";
}
.material-icons.auto_stories:before {
  content: "\e666";
}
.material-icons.autofps_select:before {
  content: "\efdc";
}
.material-icons.autorenew:before {
  content: "\e863";
}
.material-icons.av_timer:before {
  content: "\e01b";
}
.material-icons.baby_changing_station:before {
  content: "\f19b";
}
.material-icons.back_hand:before {
  content: "\e764";
}
.material-icons.backpack:before {
  content: "\f19c";
}
.material-icons.backspace:before {
  content: "\e14a";
}
.material-icons.backup:before {
  content: "\e864";
}
.material-icons.backup_table:before {
  content: "\ef43";
}
.material-icons.badge:before {
  content: "\ea67";
}
.material-icons.bakery_dining:before {
  content: "\ea53";
}
.material-icons.balance:before {
  content: "\eaf6";
}
.material-icons.balcony:before {
  content: "\e58f";
}
.material-icons.ballot:before {
  content: "\e172";
}
.material-icons.bar_chart:before {
  content: "\e26b";
}
.material-icons.batch_prediction:before {
  content: "\f0f5";
}
.material-icons.bathroom:before {
  content: "\efdd";
}
.material-icons.bathtub:before {
  content: "\ea41";
}
.material-icons.battery_0_bar:before {
  content: "\ebdc";
}
.material-icons.battery_1_bar:before {
  content: "\ebd9";
}
.material-icons.battery_2_bar:before {
  content: "\ebe0";
}
.material-icons.battery_3_bar:before {
  content: "\ebdd";
}
.material-icons.battery_4_bar:before {
  content: "\ebe2";
}
.material-icons.battery_5_bar:before {
  content: "\ebd4";
}
.material-icons.battery_6_bar:before {
  content: "\ebd2";
}
.material-icons.battery_alert:before {
  content: "\e19c";
}
.material-icons.battery_charging_full:before {
  content: "\e1a3";
}
.material-icons.battery_full:before {
  content: "\e1a4";
}
.material-icons.battery_saver:before {
  content: "\efde";
}
.material-icons.battery_std:before {
  content: "\e1a5";
}
.material-icons.battery_unknown:before {
  content: "\e1a6";
}
.material-icons.beach_access:before {
  content: "\eb3e";
}
.material-icons.bed:before {
  content: "\efdf";
}
.material-icons.bedroom_baby:before {
  content: "\efe0";
}
.material-icons.bedroom_child:before {
  content: "\efe1";
}
.material-icons.bedroom_parent:before {
  content: "\efe2";
}
.material-icons.bedtime:before {
  content: "\ef44";
}
.material-icons.bedtime_off:before {
  content: "\eb76";
}
.material-icons.beenhere:before {
  content: "\e52d";
}
.material-icons.bento:before {
  content: "\f1f4";
}
.material-icons.bike_scooter:before {
  content: "\ef45";
}
.material-icons.biotech:before {
  content: "\ea3a";
}
.material-icons.blender:before {
  content: "\efe3";
}
.material-icons.blinds:before {
  content: "\e286";
}
.material-icons.blinds_closed:before {
  content: "\ec1f";
}
.material-icons.block:before {
  content: "\e14b";
}
.material-icons.block_flipped:before {
  content: "\ef46";
}
.material-icons.bloodtype:before {
  content: "\efe4";
}
.material-icons.bluetooth:before {
  content: "\e1a7";
}
.material-icons.bluetooth_audio:before {
  content: "\e60f";
}
.material-icons.bluetooth_connected:before {
  content: "\e1a8";
}
.material-icons.bluetooth_disabled:before {
  content: "\e1a9";
}
.material-icons.bluetooth_drive:before {
  content: "\efe5";
}
.material-icons.bluetooth_searching:before {
  content: "\e1aa";
}
.material-icons.blur_circular:before {
  content: "\e3a2";
}
.material-icons.blur_linear:before {
  content: "\e3a3";
}
.material-icons.blur_off:before {
  content: "\e3a4";
}
.material-icons.blur_on:before {
  content: "\e3a5";
}
.material-icons.bolt:before {
  content: "\ea0b";
}
.material-icons.book:before {
  content: "\e865";
}
.material-icons.book_online:before {
  content: "\f217";
}
.material-icons.bookmark:before {
  content: "\e866";
}
.material-icons.bookmark_add:before {
  content: "\e598";
}
.material-icons.bookmark_added:before {
  content: "\e599";
}
.material-icons.bookmark_border:before {
  content: "\e867";
}
.material-icons.bookmark_outline:before {
  content: "\e867";
}
.material-icons.bookmark_remove:before {
  content: "\e59a";
}
.material-icons.bookmarks:before {
  content: "\e98b";
}
.material-icons.border_all:before {
  content: "\e228";
}
.material-icons.border_bottom:before {
  content: "\e229";
}
.material-icons.border_clear:before {
  content: "\e22a";
}
.material-icons.border_color:before {
  content: "\e22b";
}
.material-icons.border_horizontal:before {
  content: "\e22c";
}
.material-icons.border_inner:before {
  content: "\e22d";
}
.material-icons.border_left:before {
  content: "\e22e";
}
.material-icons.border_outer:before {
  content: "\e22f";
}
.material-icons.border_right:before {
  content: "\e230";
}
.material-icons.border_style:before {
  content: "\e231";
}
.material-icons.border_top:before {
  content: "\e232";
}
.material-icons.border_vertical:before {
  content: "\e233";
}
.material-icons.boy:before {
  content: "\eb67";
}
.material-icons.branding_watermark:before {
  content: "\e06b";
}
.material-icons.breakfast_dining:before {
  content: "\ea54";
}
.material-icons.brightness_1:before {
  content: "\e3a6";
}
.material-icons.brightness_2:before {
  content: "\e3a7";
}
.material-icons.brightness_3:before {
  content: "\e3a8";
}
.material-icons.brightness_4:before {
  content: "\e3a9";
}
.material-icons.brightness_5:before {
  content: "\e3aa";
}
.material-icons.brightness_6:before {
  content: "\e3ab";
}
.material-icons.brightness_7:before {
  content: "\e3ac";
}
.material-icons.brightness_auto:before {
  content: "\e1ab";
}
.material-icons.brightness_high:before {
  content: "\e1ac";
}
.material-icons.brightness_low:before {
  content: "\e1ad";
}
.material-icons.brightness_medium:before {
  content: "\e1ae";
}
.material-icons.broadcast_on_home:before {
  content: "\f8f8";
}
.material-icons.broadcast_on_personal:before {
  content: "\f8f9";
}
.material-icons.broken_image:before {
  content: "\e3ad";
}
.material-icons.browse_gallery:before {
  content: "\ebd1";
}
.material-icons.browser_not_supported:before {
  content: "\ef47";
}
.material-icons.browser_updated:before {
  content: "\e7cf";
}
.material-icons.brunch_dining:before {
  content: "\ea73";
}
.material-icons.brush:before {
  content: "\e3ae";
}
.material-icons.bubble_chart:before {
  content: "\e6dd";
}
.material-icons.bug_report:before {
  content: "\e868";
}
.material-icons.build:before {
  content: "\e869";
}
.material-icons.build_circle:before {
  content: "\ef48";
}
.material-icons.bungalow:before {
  content: "\e591";
}
.material-icons.burst_mode:before {
  content: "\e43c";
}
.material-icons.bus_alert:before {
  content: "\e98f";
}
.material-icons.business:before {
  content: "\e0af";
}
.material-icons.business_center:before {
  content: "\eb3f";
}
.material-icons.cabin:before {
  content: "\e589";
}
.material-icons.cable:before {
  content: "\efe6";
}
.material-icons.cached:before {
  content: "\e86a";
}
.material-icons.cake:before {
  content: "\e7e9";
}
.material-icons.calculate:before {
  content: "\ea5f";
}
.material-icons.calendar_month:before {
  content: "\ebcc";
}
.material-icons.calendar_today:before {
  content: "\e935";
}
.material-icons.calendar_view_day:before {
  content: "\e936";
}
.material-icons.calendar_view_month:before {
  content: "\efe7";
}
.material-icons.calendar_view_week:before {
  content: "\efe8";
}
.material-icons.call:before {
  content: "\e0b0";
}
.material-icons.call_end:before {
  content: "\e0b1";
}
.material-icons.call_made:before {
  content: "\e0b2";
}
.material-icons.call_merge:before {
  content: "\e0b3";
}
.material-icons.call_missed:before {
  content: "\e0b4";
}
.material-icons.call_missed_outgoing:before {
  content: "\e0e4";
}
.material-icons.call_received:before {
  content: "\e0b5";
}
.material-icons.call_split:before {
  content: "\e0b6";
}
.material-icons.call_to_action:before {
  content: "\e06c";
}
.material-icons.camera:before {
  content: "\e3af";
}
.material-icons.camera_alt:before {
  content: "\e3b0";
}
.material-icons.camera_enhance:before {
  content: "\e8fc";
}
.material-icons.camera_front:before {
  content: "\e3b1";
}
.material-icons.camera_indoor:before {
  content: "\efe9";
}
.material-icons.camera_outdoor:before {
  content: "\efea";
}
.material-icons.camera_rear:before {
  content: "\e3b2";
}
.material-icons.camera_roll:before {
  content: "\e3b3";
}
.material-icons.cameraswitch:before {
  content: "\efeb";
}
.material-icons.campaign:before {
  content: "\ef49";
}
.material-icons.cancel:before {
  content: "\e5c9";
}
.material-icons.cancel_presentation:before {
  content: "\e0e9";
}
.material-icons.cancel_schedule_send:before {
  content: "\ea39";
}
.material-icons.candlestick_chart:before {
  content: "\ead4";
}
.material-icons.car_crash:before {
  content: "\ebf2";
}
.material-icons.car_rental:before {
  content: "\ea55";
}
.material-icons.car_repair:before {
  content: "\ea56";
}
.material-icons.card_giftcard:before {
  content: "\e8f6";
}
.material-icons.card_membership:before {
  content: "\e8f7";
}
.material-icons.card_travel:before {
  content: "\e8f8";
}
.material-icons.carpenter:before {
  content: "\f1f8";
}
.material-icons.cases:before {
  content: "\e992";
}
.material-icons.casino:before {
  content: "\eb40";
}
.material-icons.cast:before {
  content: "\e307";
}
.material-icons.cast_connected:before {
  content: "\e308";
}
.material-icons.cast_for_education:before {
  content: "\efec";
}
.material-icons.castle:before {
  content: "\eab1";
}
.material-icons.catching_pokemon:before {
  content: "\e508";
}
.material-icons.category:before {
  content: "\e574";
}
.material-icons.celebration:before {
  content: "\ea65";
}
.material-icons.cell_tower:before {
  content: "\ebba";
}
.material-icons.cell_wifi:before {
  content: "\e0ec";
}
.material-icons.center_focus_strong:before {
  content: "\e3b4";
}
.material-icons.center_focus_weak:before {
  content: "\e3b5";
}
.material-icons.chair:before {
  content: "\efed";
}
.material-icons.chair_alt:before {
  content: "\efee";
}
.material-icons.chalet:before {
  content: "\e585";
}
.material-icons.change_circle:before {
  content: "\e2e7";
}
.material-icons.change_history:before {
  content: "\e86b";
}
.material-icons.charging_station:before {
  content: "\f19d";
}
.material-icons.chat:before {
  content: "\e0b7";
}
.material-icons.chat_bubble:before {
  content: "\e0ca";
}
.material-icons.chat_bubble_outline:before {
  content: "\e0cb";
}
.material-icons.check:before {
  content: "\e5ca";
}
.material-icons.check_box:before {
  content: "\e834";
}
.material-icons.check_box_outline_blank:before {
  content: "\e835";
}
.material-icons.check_circle:before {
  content: "\e86c";
}
.material-icons.check_circle_outline:before {
  content: "\e92d";
}
.material-icons.checklist:before {
  content: "\e6b1";
}
.material-icons.checklist_rtl:before {
  content: "\e6b3";
}
.material-icons.checkroom:before {
  content: "\f19e";
}
.material-icons.chevron_left:before {
  content: "\e5cb";
}
.material-icons.chevron_right:before {
  content: "\e5cc";
}
.material-icons.child_care:before {
  content: "\eb41";
}
.material-icons.child_friendly:before {
  content: "\eb42";
}
.material-icons.chrome_reader_mode:before {
  content: "\e86d";
}
.material-icons.church:before {
  content: "\eaae";
}
.material-icons.circle:before {
  content: "\ef4a";
}
.material-icons.circle_notifications:before {
  content: "\e994";
}
.material-icons.class:before {
  content: "\e86e";
}
.material-icons.clean_hands:before {
  content: "\f21f";
}
.material-icons.cleaning_services:before {
  content: "\f0ff";
}
.material-icons.clear:before {
  content: "\e14c";
}
.material-icons.clear_all:before {
  content: "\e0b8";
}
.material-icons.close:before {
  content: "\e5cd";
}
.material-icons.close_fullscreen:before {
  content: "\f1cf";
}
.material-icons.closed_caption:before {
  content: "\e01c";
}
.material-icons.closed_caption_disabled:before {
  content: "\f1dc";
}
.material-icons.closed_caption_off:before {
  content: "\e996";
}
.material-icons.cloud:before {
  content: "\e2bd";
}
.material-icons.cloud_circle:before {
  content: "\e2be";
}
.material-icons.cloud_done:before {
  content: "\e2bf";
}
.material-icons.cloud_download:before {
  content: "\e2c0";
}
.material-icons.cloud_off:before {
  content: "\e2c1";
}
.material-icons.cloud_queue:before {
  content: "\e2c2";
}
.material-icons.cloud_sync:before {
  content: "\eb5a";
}
.material-icons.cloud_upload:before {
  content: "\e2c3";
}
.material-icons.cloudy_snowing:before {
  content: "\e810";
}
.material-icons.co2:before {
  content: "\e7b0";
}
.material-icons.co_present:before {
  content: "\eaf0";
}
.material-icons.code:before {
  content: "\e86f";
}
.material-icons.code_off:before {
  content: "\e4f3";
}
.material-icons.coffee:before {
  content: "\efef";
}
.material-icons.coffee_maker:before {
  content: "\eff0";
}
.material-icons.collections:before {
  content: "\e3b6";
}
.material-icons.collections_bookmark:before {
  content: "\e431";
}
.material-icons.color_lens:before {
  content: "\e3b7";
}
.material-icons.colorize:before {
  content: "\e3b8";
}
.material-icons.comment:before {
  content: "\e0b9";
}
.material-icons.comment_bank:before {
  content: "\ea4e";
}
.material-icons.comments_disabled:before {
  content: "\e7a2";
}
.material-icons.commit:before {
  content: "\eaf5";
}
.material-icons.commute:before {
  content: "\e940";
}
.material-icons.compare:before {
  content: "\e3b9";
}
.material-icons.compare_arrows:before {
  content: "\e915";
}
.material-icons.compass_calibration:before {
  content: "\e57c";
}
.material-icons.compost:before {
  content: "\e761";
}
.material-icons.compress:before {
  content: "\e94d";
}
.material-icons.computer:before {
  content: "\e30a";
}
.material-icons.confirmation_num:before {
  content: "\e638";
}
.material-icons.confirmation_number:before {
  content: "\e638";
}
.material-icons.connect_without_contact:before {
  content: "\f223";
}
.material-icons.connected_tv:before {
  content: "\e998";
}
.material-icons.connecting_airports:before {
  content: "\e7c9";
}
.material-icons.construction:before {
  content: "\ea3c";
}
.material-icons.contact_mail:before {
  content: "\e0d0";
}
.material-icons.contact_page:before {
  content: "\f22e";
}
.material-icons.contact_phone:before {
  content: "\e0cf";
}
.material-icons.contact_support:before {
  content: "\e94c";
}
.material-icons.contactless:before {
  content: "\ea71";
}
.material-icons.contacts:before {
  content: "\e0ba";
}
.material-icons.content_copy:before {
  content: "\e14d";
}
.material-icons.content_cut:before {
  content: "\e14e";
}
.material-icons.content_paste:before {
  content: "\e14f";
}
.material-icons.content_paste_go:before {
  content: "\ea8e";
}
.material-icons.content_paste_off:before {
  content: "\e4f8";
}
.material-icons.content_paste_search:before {
  content: "\ea9b";
}
.material-icons.contrast:before {
  content: "\eb37";
}
.material-icons.control_camera:before {
  content: "\e074";
}
.material-icons.control_point:before {
  content: "\e3ba";
}
.material-icons.control_point_duplicate:before {
  content: "\e3bb";
}
.material-icons.cookie:before {
  content: "\eaac";
}
.material-icons.copy_all:before {
  content: "\e2ec";
}
.material-icons.copyright:before {
  content: "\e90c";
}
.material-icons.coronavirus:before {
  content: "\f221";
}
.material-icons.corporate_fare:before {
  content: "\f1d0";
}
.material-icons.cottage:before {
  content: "\e587";
}
.material-icons.countertops:before {
  content: "\f1f7";
}
.material-icons.create:before {
  content: "\e150";
}
.material-icons.create_new_folder:before {
  content: "\e2cc";
}
.material-icons.credit_card:before {
  content: "\e870";
}
.material-icons.credit_card_off:before {
  content: "\e4f4";
}
.material-icons.credit_score:before {
  content: "\eff1";
}
.material-icons.crib:before {
  content: "\e588";
}
.material-icons.crisis_alert:before {
  content: "\ebe9";
}
.material-icons.crop:before {
  content: "\e3be";
}
.material-icons.crop_16_9:before {
  content: "\e3bc";
}
.material-icons.crop_3_2:before {
  content: "\e3bd";
}
.material-icons.crop_5_4:before {
  content: "\e3bf";
}
.material-icons.crop_7_5:before {
  content: "\e3c0";
}
.material-icons.crop_din:before {
  content: "\e3c1";
}
.material-icons.crop_free:before {
  content: "\e3c2";
}
.material-icons.crop_landscape:before {
  content: "\e3c3";
}
.material-icons.crop_original:before {
  content: "\e3c4";
}
.material-icons.crop_portrait:before {
  content: "\e3c5";
}
.material-icons.crop_rotate:before {
  content: "\e437";
}
.material-icons.crop_square:before {
  content: "\e3c6";
}
.material-icons.cruelty_free:before {
  content: "\e799";
}
.material-icons.css:before {
  content: "\eb93";
}
.material-icons.currency_bitcoin:before {
  content: "\ebc5";
}
.material-icons.currency_exchange:before {
  content: "\eb70";
}
.material-icons.currency_franc:before {
  content: "\eafa";
}
.material-icons.currency_lira:before {
  content: "\eaef";
}
.material-icons.currency_pound:before {
  content: "\eaf1";
}
.material-icons.currency_ruble:before {
  content: "\eaec";
}
.material-icons.currency_rupee:before {
  content: "\eaf7";
}
.material-icons.currency_yen:before {
  content: "\eafb";
}
.material-icons.currency_yuan:before {
  content: "\eaf9";
}
.material-icons.curtains:before {
  content: "\ec1e";
}
.material-icons.curtains_closed:before {
  content: "\ec1d";
}
.material-icons.cyclone:before {
  content: "\ebd5";
}
.material-icons.dangerous:before {
  content: "\e99a";
}
.material-icons.dark_mode:before {
  content: "\e51c";
}
.material-icons.dashboard:before {
  content: "\e871";
}
.material-icons.dashboard_customize:before {
  content: "\e99b";
}
.material-icons.data_array:before {
  content: "\ead1";
}
.material-icons.data_exploration:before {
  content: "\e76f";
}
.material-icons.data_object:before {
  content: "\ead3";
}
.material-icons.data_saver_off:before {
  content: "\eff2";
}
.material-icons.data_saver_on:before {
  content: "\eff3";
}
.material-icons.data_thresholding:before {
  content: "\eb9f";
}
.material-icons.data_usage:before {
  content: "\e1af";
}
.material-icons.dataset:before {
  content: "\f8ee";
}
.material-icons.dataset_linked:before {
  content: "\f8ef";
}
.material-icons.date_range:before {
  content: "\e916";
}
.material-icons.deblur:before {
  content: "\eb77";
}
.material-icons.deck:before {
  content: "\ea42";
}
.material-icons.dehaze:before {
  content: "\e3c7";
}
.material-icons.delete:before {
  content: "\e872";
}
.material-icons.delete_forever:before {
  content: "\e92b";
}
.material-icons.delete_outline:before {
  content: "\e92e";
}
.material-icons.delete_sweep:before {
  content: "\e16c";
}
.material-icons.delivery_dining:before {
  content: "\ea72";
}
.material-icons.density_large:before {
  content: "\eba9";
}
.material-icons.density_medium:before {
  content: "\eb9e";
}
.material-icons.density_small:before {
  content: "\eba8";
}
.material-icons.departure_board:before {
  content: "\e576";
}
.material-icons.description:before {
  content: "\e873";
}
.material-icons.deselect:before {
  content: "\ebb6";
}
.material-icons.design_services:before {
  content: "\f10a";
}
.material-icons.desk:before {
  content: "\f8f4";
}
.material-icons.desktop_access_disabled:before {
  content: "\e99d";
}
.material-icons.desktop_mac:before {
  content: "\e30b";
}
.material-icons.desktop_windows:before {
  content: "\e30c";
}
.material-icons.details:before {
  content: "\e3c8";
}
.material-icons.developer_board:before {
  content: "\e30d";
}
.material-icons.developer_board_off:before {
  content: "\e4ff";
}
.material-icons.developer_mode:before {
  content: "\e1b0";
}
.material-icons.device_hub:before {
  content: "\e335";
}
.material-icons.device_thermostat:before {
  content: "\e1ff";
}
.material-icons.device_unknown:before {
  content: "\e339";
}
.material-icons.devices:before {
  content: "\e1b1";
}
.material-icons.devices_fold:before {
  content: "\ebde";
}
.material-icons.devices_other:before {
  content: "\e337";
}
.material-icons.dialer_sip:before {
  content: "\e0bb";
}
.material-icons.dialpad:before {
  content: "\e0bc";
}
.material-icons.diamond:before {
  content: "\ead5";
}
.material-icons.difference:before {
  content: "\eb7d";
}
.material-icons.dining:before {
  content: "\eff4";
}
.material-icons.dinner_dining:before {
  content: "\ea57";
}
.material-icons.directions:before {
  content: "\e52e";
}
.material-icons.directions_bike:before {
  content: "\e52f";
}
.material-icons.directions_boat:before {
  content: "\e532";
}
.material-icons.directions_boat_filled:before {
  content: "\eff5";
}
.material-icons.directions_bus:before {
  content: "\e530";
}
.material-icons.directions_bus_filled:before {
  content: "\eff6";
}
.material-icons.directions_car:before {
  content: "\e531";
}
.material-icons.directions_car_filled:before {
  content: "\eff7";
}
.material-icons.directions_ferry:before {
  content: "\e532";
}
.material-icons.directions_off:before {
  content: "\f10f";
}
.material-icons.directions_railway:before {
  content: "\e534";
}
.material-icons.directions_railway_filled:before {
  content: "\eff8";
}
.material-icons.directions_run:before {
  content: "\e566";
}
.material-icons.directions_subway:before {
  content: "\e533";
}
.material-icons.directions_subway_filled:before {
  content: "\eff9";
}
.material-icons.directions_train:before {
  content: "\e534";
}
.material-icons.directions_transit:before {
  content: "\e535";
}
.material-icons.directions_transit_filled:before {
  content: "\effa";
}
.material-icons.directions_walk:before {
  content: "\e536";
}
.material-icons.dirty_lens:before {
  content: "\ef4b";
}
.material-icons.disabled_by_default:before {
  content: "\f230";
}
.material-icons.disabled_visible:before {
  content: "\e76e";
}
.material-icons.disc_full:before {
  content: "\e610";
}
.material-icons.discord:before {
  content: "\ea6c";
}
.material-icons.discount:before {
  content: "\ebc9";
}
.material-icons.display_settings:before {
  content: "\eb97";
}
.material-icons.dnd_forwardslash:before {
  content: "\e611";
}
.material-icons.dns:before {
  content: "\e875";
}
.material-icons.do_disturb:before {
  content: "\f08c";
}
.material-icons.do_disturb_alt:before {
  content: "\f08d";
}
.material-icons.do_disturb_off:before {
  content: "\f08e";
}
.material-icons.do_disturb_on:before {
  content: "\f08f";
}
.material-icons.do_not_disturb:before {
  content: "\e612";
}
.material-icons.do_not_disturb_alt:before {
  content: "\e611";
}
.material-icons.do_not_disturb_off:before {
  content: "\e643";
}
.material-icons.do_not_disturb_on:before {
  content: "\e644";
}
.material-icons.do_not_disturb_on_total_silence:before {
  content: "\effb";
}
.material-icons.do_not_step:before {
  content: "\f19f";
}
.material-icons.do_not_touch:before {
  content: "\f1b0";
}
.material-icons.dock:before {
  content: "\e30e";
}
.material-icons.document_scanner:before {
  content: "\e5fa";
}
.material-icons.domain:before {
  content: "\e7ee";
}
.material-icons.domain_add:before {
  content: "\eb62";
}
.material-icons.domain_disabled:before {
  content: "\e0ef";
}
.material-icons.domain_verification:before {
  content: "\ef4c";
}
.material-icons.done:before {
  content: "\e876";
}
.material-icons.done_all:before {
  content: "\e877";
}
.material-icons.done_outline:before {
  content: "\e92f";
}
.material-icons.donut_large:before {
  content: "\e917";
}
.material-icons.donut_small:before {
  content: "\e918";
}
.material-icons.door_back:before {
  content: "\effc";
}
.material-icons.door_front:before {
  content: "\effd";
}
.material-icons.door_sliding:before {
  content: "\effe";
}
.material-icons.doorbell:before {
  content: "\efff";
}
.material-icons.double_arrow:before {
  content: "\ea50";
}
.material-icons.downhill_skiing:before {
  content: "\e509";
}
.material-icons.download:before {
  content: "\f090";
}
.material-icons.download_done:before {
  content: "\f091";
}
.material-icons.download_for_offline:before {
  content: "\f000";
}
.material-icons.downloading:before {
  content: "\f001";
}
.material-icons.drafts:before {
  content: "\e151";
}
.material-icons.drag_handle:before {
  content: "\e25d";
}
.material-icons.drag_indicator:before {
  content: "\e945";
}
.material-icons.draw:before {
  content: "\e746";
}
.material-icons.drive_eta:before {
  content: "\e613";
}
.material-icons.drive_file_move:before {
  content: "\e675";
}
.material-icons.drive_file_move_outline:before {
  content: "\e9a1";
}
.material-icons.drive_file_move_rtl:before {
  content: "\e76d";
}
.material-icons.drive_file_rename_outline:before {
  content: "\e9a2";
}
.material-icons.drive_folder_upload:before {
  content: "\e9a3";
}
.material-icons.dry:before {
  content: "\f1b3";
}
.material-icons.dry_cleaning:before {
  content: "\ea58";
}
.material-icons.duo:before {
  content: "\e9a5";
}
.material-icons.dvr:before {
  content: "\e1b2";
}
.material-icons.dynamic_feed:before {
  content: "\ea14";
}
.material-icons.dynamic_form:before {
  content: "\f1bf";
}
.material-icons.e_mobiledata:before {
  content: "\f002";
}
.material-icons.earbuds:before {
  content: "\f003";
}
.material-icons.earbuds_battery:before {
  content: "\f004";
}
.material-icons.east:before {
  content: "\f1df";
}
.material-icons.eco:before {
  content: "\ea35";
}
.material-icons.edgesensor_high:before {
  content: "\f005";
}
.material-icons.edgesensor_low:before {
  content: "\f006";
}
.material-icons.edit:before {
  content: "\e3c9";
}
.material-icons.edit_attributes:before {
  content: "\e578";
}
.material-icons.edit_calendar:before {
  content: "\e742";
}
.material-icons.edit_location:before {
  content: "\e568";
}
.material-icons.edit_location_alt:before {
  content: "\e1c5";
}
.material-icons.edit_note:before {
  content: "\e745";
}
.material-icons.edit_notifications:before {
  content: "\e525";
}
.material-icons.edit_off:before {
  content: "\e950";
}
.material-icons.edit_road:before {
  content: "\ef4d";
}
.material-icons.egg:before {
  content: "\eacc";
}
.material-icons.egg_alt:before {
  content: "\eac8";
}
.material-icons.eject:before {
  content: "\e8fb";
}
.material-icons.elderly:before {
  content: "\f21a";
}
.material-icons.elderly_woman:before {
  content: "\eb69";
}
.material-icons.electric_bike:before {
  content: "\eb1b";
}
.material-icons.electric_bolt:before {
  content: "\ec1c";
}
.material-icons.electric_car:before {
  content: "\eb1c";
}
.material-icons.electric_meter:before {
  content: "\ec1b";
}
.material-icons.electric_moped:before {
  content: "\eb1d";
}
.material-icons.electric_rickshaw:before {
  content: "\eb1e";
}
.material-icons.electric_scooter:before {
  content: "\eb1f";
}
.material-icons.electrical_services:before {
  content: "\f102";
}
.material-icons.elevator:before {
  content: "\f1a0";
}
.material-icons.email:before {
  content: "\e0be";
}
.material-icons.emergency:before {
  content: "\e1eb";
}
.material-icons.emergency_recording:before {
  content: "\ebf4";
}
.material-icons.emergency_share:before {
  content: "\ebf6";
}
.material-icons.emoji_emotions:before {
  content: "\ea22";
}
.material-icons.emoji_events:before {
  content: "\ea23";
}
.material-icons.emoji_flags:before {
  content: "\ea1a";
}
.material-icons.emoji_food_beverage:before {
  content: "\ea1b";
}
.material-icons.emoji_nature:before {
  content: "\ea1c";
}
.material-icons.emoji_objects:before {
  content: "\ea24";
}
.material-icons.emoji_people:before {
  content: "\ea1d";
}
.material-icons.emoji_symbols:before {
  content: "\ea1e";
}
.material-icons.emoji_transportation:before {
  content: "\ea1f";
}
.material-icons.energy_savings_leaf:before {
  content: "\ec1a";
}
.material-icons.engineering:before {
  content: "\ea3d";
}
.material-icons.enhance_photo_translate:before {
  content: "\e8fc";
}
.material-icons.enhanced_encryption:before {
  content: "\e63f";
}
.material-icons.equalizer:before {
  content: "\e01d";
}
.material-icons.error:before {
  content: "\e000";
}
.material-icons.error_outline:before {
  content: "\e001";
}
.material-icons.escalator:before {
  content: "\f1a1";
}
.material-icons.escalator_warning:before {
  content: "\f1ac";
}
.material-icons.euro:before {
  content: "\ea15";
}
.material-icons.euro_symbol:before {
  content: "\e926";
}
.material-icons.ev_station:before {
  content: "\e56d";
}
.material-icons.event:before {
  content: "\e878";
}
.material-icons.event_available:before {
  content: "\e614";
}
.material-icons.event_busy:before {
  content: "\e615";
}
.material-icons.event_note:before {
  content: "\e616";
}
.material-icons.event_repeat:before {
  content: "\eb7b";
}
.material-icons.event_seat:before {
  content: "\e903";
}
.material-icons.exit_to_app:before {
  content: "\e879";
}
.material-icons.expand:before {
  content: "\e94f";
}
.material-icons.expand_circle_down:before {
  content: "\e7cd";
}
.material-icons.expand_less:before {
  content: "\e5ce";
}
.material-icons.expand_more:before {
  content: "\e5cf";
}
.material-icons.explicit:before {
  content: "\e01e";
}
.material-icons.explore:before {
  content: "\e87a";
}
.material-icons.explore_off:before {
  content: "\e9a8";
}
.material-icons.exposure:before {
  content: "\e3ca";
}
.material-icons.exposure_minus_1:before {
  content: "\e3cb";
}
.material-icons.exposure_minus_2:before {
  content: "\e3cc";
}
.material-icons.exposure_neg_1:before {
  content: "\e3cb";
}
.material-icons.exposure_neg_2:before {
  content: "\e3cc";
}
.material-icons.exposure_plus_1:before {
  content: "\e3cd";
}
.material-icons.exposure_plus_2:before {
  content: "\e3ce";
}
.material-icons.exposure_zero:before {
  content: "\e3cf";
}
.material-icons.extension:before {
  content: "\e87b";
}
.material-icons.extension_off:before {
  content: "\e4f5";
}
.material-icons.face:before {
  content: "\e87c";
}
.material-icons.face_retouching_natural:before {
  content: "\ef4e";
}
.material-icons.face_retouching_off:before {
  content: "\f007";
}
.material-icons.facebook:before {
  content: "\f234";
}
.material-icons.fact_check:before {
  content: "\f0c5";
}
.material-icons.factory:before {
  content: "\ebbc";
}
.material-icons.family_restroom:before {
  content: "\f1a2";
}
.material-icons.fast_forward:before {
  content: "\e01f";
}
.material-icons.fast_rewind:before {
  content: "\e020";
}
.material-icons.fastfood:before {
  content: "\e57a";
}
.material-icons.favorite:before {
  content: "\e87d";
}
.material-icons.favorite_border:before {
  content: "\e87e";
}
.material-icons.favorite_outline:before {
  content: "\e87e";
}
.material-icons.fax:before {
  content: "\ead8";
}
.material-icons.featured_play_list:before {
  content: "\e06d";
}
.material-icons.featured_video:before {
  content: "\e06e";
}
.material-icons.feed:before {
  content: "\f009";
}
.material-icons.feedback:before {
  content: "\e87f";
}
.material-icons.female:before {
  content: "\e590";
}
.material-icons.fence:before {
  content: "\f1f6";
}
.material-icons.festival:before {
  content: "\ea68";
}
.material-icons.fiber_dvr:before {
  content: "\e05d";
}
.material-icons.fiber_manual_record:before {
  content: "\e061";
}
.material-icons.fiber_new:before {
  content: "\e05e";
}
.material-icons.fiber_pin:before {
  content: "\e06a";
}
.material-icons.fiber_smart_record:before {
  content: "\e062";
}
.material-icons.file_copy:before {
  content: "\e173";
}
.material-icons.file_download:before {
  content: "\e2c4";
}
.material-icons.file_download_done:before {
  content: "\e9aa";
}
.material-icons.file_download_off:before {
  content: "\e4fe";
}
.material-icons.file_open:before {
  content: "\eaf3";
}
.material-icons.file_present:before {
  content: "\ea0e";
}
.material-icons.file_upload:before {
  content: "\e2c6";
}
.material-icons.filter:before {
  content: "\e3d3";
}
.material-icons.filter_1:before {
  content: "\e3d0";
}
.material-icons.filter_2:before {
  content: "\e3d1";
}
.material-icons.filter_3:before {
  content: "\e3d2";
}
.material-icons.filter_4:before {
  content: "\e3d4";
}
.material-icons.filter_5:before {
  content: "\e3d5";
}
.material-icons.filter_6:before {
  content: "\e3d6";
}
.material-icons.filter_7:before {
  content: "\e3d7";
}
.material-icons.filter_8:before {
  content: "\e3d8";
}
.material-icons.filter_9:before {
  content: "\e3d9";
}
.material-icons.filter_9_plus:before {
  content: "\e3da";
}
.material-icons.filter_alt:before {
  content: "\ef4f";
}
.material-icons.filter_alt_off:before {
  content: "\eb32";
}
.material-icons.filter_b_and_w:before {
  content: "\e3db";
}
.material-icons.filter_center_focus:before {
  content: "\e3dc";
}
.material-icons.filter_drama:before {
  content: "\e3dd";
}
.material-icons.filter_frames:before {
  content: "\e3de";
}
.material-icons.filter_hdr:before {
  content: "\e3df";
}
.material-icons.filter_list:before {
  content: "\e152";
}
.material-icons.filter_list_alt:before {
  content: "\e94e";
}
.material-icons.filter_list_off:before {
  content: "\eb57";
}
.material-icons.filter_none:before {
  content: "\e3e0";
}
.material-icons.filter_tilt_shift:before {
  content: "\e3e2";
}
.material-icons.filter_vintage:before {
  content: "\e3e3";
}
.material-icons.find_in_page:before {
  content: "\e880";
}
.material-icons.find_replace:before {
  content: "\e881";
}
.material-icons.fingerprint:before {
  content: "\e90d";
}
.material-icons.fire_extinguisher:before {
  content: "\f1d8";
}
.material-icons.fire_hydrant:before {
  content: "\f1a3";
}
.material-icons.fire_hydrant_alt:before {
  content: "\f8f1";
}
.material-icons.fire_truck:before {
  content: "\f8f2";
}
.material-icons.fireplace:before {
  content: "\ea43";
}
.material-icons.first_page:before {
  content: "\e5dc";
}
.material-icons.fit_screen:before {
  content: "\ea10";
}
.material-icons.fitbit:before {
  content: "\e82b";
}
.material-icons.fitness_center:before {
  content: "\eb43";
}
.material-icons.flag:before {
  content: "\e153";
}
.material-icons.flag_circle:before {
  content: "\eaf8";
}
.material-icons.flaky:before {
  content: "\ef50";
}
.material-icons.flare:before {
  content: "\e3e4";
}
.material-icons.flash_auto:before {
  content: "\e3e5";
}
.material-icons.flash_off:before {
  content: "\e3e6";
}
.material-icons.flash_on:before {
  content: "\e3e7";
}
.material-icons.flashlight_off:before {
  content: "\f00a";
}
.material-icons.flashlight_on:before {
  content: "\f00b";
}
.material-icons.flatware:before {
  content: "\f00c";
}
.material-icons.flight:before {
  content: "\e539";
}
.material-icons.flight_class:before {
  content: "\e7cb";
}
.material-icons.flight_land:before {
  content: "\e904";
}
.material-icons.flight_takeoff:before {
  content: "\e905";
}
.material-icons.flip:before {
  content: "\e3e8";
}
.material-icons.flip_camera_android:before {
  content: "\ea37";
}
.material-icons.flip_camera_ios:before {
  content: "\ea38";
}
.material-icons.flip_to_back:before {
  content: "\e882";
}
.material-icons.flip_to_front:before {
  content: "\e883";
}
.material-icons.flood:before {
  content: "\ebe6";
}
.material-icons.flourescent:before {
  content: "\f00d";
}
.material-icons.flutter_dash:before {
  content: "\e00b";
}
.material-icons.fmd_bad:before {
  content: "\f00e";
}
.material-icons.fmd_good:before {
  content: "\f00f";
}
.material-icons.foggy:before {
  content: "\e818";
}
.material-icons.folder:before {
  content: "\e2c7";
}
.material-icons.folder_copy:before {
  content: "\ebbd";
}
.material-icons.folder_delete:before {
  content: "\eb34";
}
.material-icons.folder_off:before {
  content: "\eb83";
}
.material-icons.folder_open:before {
  content: "\e2c8";
}
.material-icons.folder_shared:before {
  content: "\e2c9";
}
.material-icons.folder_special:before {
  content: "\e617";
}
.material-icons.folder_zip:before {
  content: "\eb2c";
}
.material-icons.follow_the_signs:before {
  content: "\f222";
}
.material-icons.font_download:before {
  content: "\e167";
}
.material-icons.font_download_off:before {
  content: "\e4f9";
}
.material-icons.food_bank:before {
  content: "\f1f2";
}
.material-icons.forest:before {
  content: "\ea99";
}
.material-icons.fork_left:before {
  content: "\eba0";
}
.material-icons.fork_right:before {
  content: "\ebac";
}
.material-icons.format_align_center:before {
  content: "\e234";
}
.material-icons.format_align_justify:before {
  content: "\e235";
}
.material-icons.format_align_left:before {
  content: "\e236";
}
.material-icons.format_align_right:before {
  content: "\e237";
}
.material-icons.format_bold:before {
  content: "\e238";
}
.material-icons.format_clear:before {
  content: "\e239";
}
.material-icons.format_color_fill:before {
  content: "\e23a";
}
.material-icons.format_color_reset:before {
  content: "\e23b";
}
.material-icons.format_color_text:before {
  content: "\e23c";
}
.material-icons.format_indent_decrease:before {
  content: "\e23d";
}
.material-icons.format_indent_increase:before {
  content: "\e23e";
}
.material-icons.format_italic:before {
  content: "\e23f";
}
.material-icons.format_line_spacing:before {
  content: "\e240";
}
.material-icons.format_list_bulleted:before {
  content: "\e241";
}
.material-icons.format_list_numbered:before {
  content: "\e242";
}
.material-icons.format_list_numbered_rtl:before {
  content: "\e267";
}
.material-icons.format_overline:before {
  content: "\eb65";
}
.material-icons.format_paint:before {
  content: "\e243";
}
.material-icons.format_quote:before {
  content: "\e244";
}
.material-icons.format_shapes:before {
  content: "\e25e";
}
.material-icons.format_size:before {
  content: "\e245";
}
.material-icons.format_strikethrough:before {
  content: "\e246";
}
.material-icons.format_textdirection_l_to_r:before {
  content: "\e247";
}
.material-icons.format_textdirection_r_to_l:before {
  content: "\e248";
}
.material-icons.format_underline:before {
  content: "\e249";
}
.material-icons.format_underlined:before {
  content: "\e249";
}
.material-icons.fort:before {
  content: "\eaad";
}
.material-icons.forum:before {
  content: "\e0bf";
}
.material-icons.forward:before {
  content: "\e154";
}
.material-icons.forward_10:before {
  content: "\e056";
}
.material-icons.forward_30:before {
  content: "\e057";
}
.material-icons.forward_5:before {
  content: "\e058";
}
.material-icons.forward_to_inbox:before {
  content: "\f187";
}
.material-icons.foundation:before {
  content: "\f200";
}
.material-icons.free_breakfast:before {
  content: "\eb44";
}
.material-icons.free_cancellation:before {
  content: "\e748";
}
.material-icons.front_hand:before {
  content: "\e769";
}
.material-icons.fullscreen:before {
  content: "\e5d0";
}
.material-icons.fullscreen_exit:before {
  content: "\e5d1";
}
.material-icons.functions:before {
  content: "\e24a";
}
.material-icons.g_mobiledata:before {
  content: "\f010";
}
.material-icons.g_translate:before {
  content: "\e927";
}
.material-icons.gamepad:before {
  content: "\e30f";
}
.material-icons.games:before {
  content: "\e021";
}
.material-icons.garage:before {
  content: "\f011";
}
.material-icons.gas_meter:before {
  content: "\ec19";
}
.material-icons.gavel:before {
  content: "\e90e";
}
.material-icons.generating_tokens:before {
  content: "\e749";
}
.material-icons.gesture:before {
  content: "\e155";
}
.material-icons.get_app:before {
  content: "\e884";
}
.material-icons.gif:before {
  content: "\e908";
}
.material-icons.gif_box:before {
  content: "\e7a3";
}
.material-icons.girl:before {
  content: "\eb68";
}
.material-icons.gite:before {
  content: "\e58b";
}
.material-icons.goat:before {
  content: "\ebff";
}
.material-icons.golf_course:before {
  content: "\eb45";
}
.material-icons.gpp_bad:before {
  content: "\f012";
}
.material-icons.gpp_good:before {
  content: "\f013";
}
.material-icons.gpp_maybe:before {
  content: "\f014";
}
.material-icons.gps_fixed:before {
  content: "\e1b3";
}
.material-icons.gps_not_fixed:before {
  content: "\e1b4";
}
.material-icons.gps_off:before {
  content: "\e1b5";
}
.material-icons.grade:before {
  content: "\e885";
}
.material-icons.gradient:before {
  content: "\e3e9";
}
.material-icons.grading:before {
  content: "\ea4f";
}
.material-icons.grain:before {
  content: "\e3ea";
}
.material-icons.graphic_eq:before {
  content: "\e1b8";
}
.material-icons.grass:before {
  content: "\f205";
}
.material-icons.grid_3x3:before {
  content: "\f015";
}
.material-icons.grid_4x4:before {
  content: "\f016";
}
.material-icons.grid_goldenratio:before {
  content: "\f017";
}
.material-icons.grid_off:before {
  content: "\e3eb";
}
.material-icons.grid_on:before {
  content: "\e3ec";
}
.material-icons.grid_view:before {
  content: "\e9b0";
}
.material-icons.group:before {
  content: "\e7ef";
}
.material-icons.group_add:before {
  content: "\e7f0";
}
.material-icons.group_off:before {
  content: "\e747";
}
.material-icons.group_remove:before {
  content: "\e7ad";
}
.material-icons.group_work:before {
  content: "\e886";
}
.material-icons.groups:before {
  content: "\f233";
}
.material-icons.h_mobiledata:before {
  content: "\f018";
}
.material-icons.h_plus_mobiledata:before {
  content: "\f019";
}
.material-icons.hail:before {
  content: "\e9b1";
}
.material-icons.handshake:before {
  content: "\ebcb";
}
.material-icons.handyman:before {
  content: "\f10b";
}
.material-icons.hardware:before {
  content: "\ea59";
}
.material-icons.hd:before {
  content: "\e052";
}
.material-icons.hdr_auto:before {
  content: "\f01a";
}
.material-icons.hdr_auto_select:before {
  content: "\f01b";
}
.material-icons.hdr_enhanced_select:before {
  content: "\ef51";
}
.material-icons.hdr_off:before {
  content: "\e3ed";
}
.material-icons.hdr_off_select:before {
  content: "\f01c";
}
.material-icons.hdr_on:before {
  content: "\e3ee";
}
.material-icons.hdr_on_select:before {
  content: "\f01d";
}
.material-icons.hdr_plus:before {
  content: "\f01e";
}
.material-icons.hdr_strong:before {
  content: "\e3f1";
}
.material-icons.hdr_weak:before {
  content: "\e3f2";
}
.material-icons.headphones:before {
  content: "\f01f";
}
.material-icons.headphones_battery:before {
  content: "\f020";
}
.material-icons.headset:before {
  content: "\e310";
}
.material-icons.headset_mic:before {
  content: "\e311";
}
.material-icons.headset_off:before {
  content: "\e33a";
}
.material-icons.healing:before {
  content: "\e3f3";
}
.material-icons.health_and_safety:before {
  content: "\e1d5";
}
.material-icons.hearing:before {
  content: "\e023";
}
.material-icons.hearing_disabled:before {
  content: "\f104";
}
.material-icons.heart_broken:before {
  content: "\eac2";
}
.material-icons.heat_pump:before {
  content: "\ec18";
}
.material-icons.height:before {
  content: "\ea16";
}
.material-icons.help:before {
  content: "\e887";
}
.material-icons.help_center:before {
  content: "\f1c0";
}
.material-icons.help_outline:before {
  content: "\e8fd";
}
.material-icons.hevc:before {
  content: "\f021";
}
.material-icons.hexagon:before {
  content: "\eb39";
}
.material-icons.hide_image:before {
  content: "\f022";
}
.material-icons.hide_source:before {
  content: "\f023";
}
.material-icons.high_quality:before {
  content: "\e024";
}
.material-icons.highlight:before {
  content: "\e25f";
}
.material-icons.highlight_alt:before {
  content: "\ef52";
}
.material-icons.highlight_off:before {
  content: "\e888";
}
.material-icons.highlight_remove:before {
  content: "\e888";
}
.material-icons.hiking:before {
  content: "\e50a";
}
.material-icons.history:before {
  content: "\e889";
}
.material-icons.history_edu:before {
  content: "\ea3e";
}
.material-icons.history_toggle_off:before {
  content: "\f17d";
}
.material-icons.hive:before {
  content: "\eaa6";
}
.material-icons.hls:before {
  content: "\eb8a";
}
.material-icons.hls_off:before {
  content: "\eb8c";
}
.material-icons.holiday_village:before {
  content: "\e58a";
}
.material-icons.home:before {
  content: "\e88a";
}
.material-icons.home_filled:before {
  content: "\e9b2";
}
.material-icons.home_max:before {
  content: "\f024";
}
.material-icons.home_mini:before {
  content: "\f025";
}
.material-icons.home_repair_service:before {
  content: "\f100";
}
.material-icons.home_work:before {
  content: "\ea09";
}
.material-icons.horizontal_distribute:before {
  content: "\e014";
}
.material-icons.horizontal_rule:before {
  content: "\f108";
}
.material-icons.horizontal_split:before {
  content: "\e947";
}
.material-icons.hot_tub:before {
  content: "\eb46";
}
.material-icons.hotel:before {
  content: "\e53a";
}
.material-icons.hotel_class:before {
  content: "\e743";
}
.material-icons.hourglass_bottom:before {
  content: "\ea5c";
}
.material-icons.hourglass_disabled:before {
  content: "\ef53";
}
.material-icons.hourglass_empty:before {
  content: "\e88b";
}
.material-icons.hourglass_full:before {
  content: "\e88c";
}
.material-icons.hourglass_top:before {
  content: "\ea5b";
}
.material-icons.house:before {
  content: "\ea44";
}
.material-icons.house_siding:before {
  content: "\f202";
}
.material-icons.houseboat:before {
  content: "\e584";
}
.material-icons.how_to_reg:before {
  content: "\e174";
}
.material-icons.how_to_vote:before {
  content: "\e175";
}
.material-icons.html:before {
  content: "\eb7e";
}
.material-icons.http:before {
  content: "\e902";
}
.material-icons.https:before {
  content: "\e88d";
}
.material-icons.hub:before {
  content: "\e9f4";
}
.material-icons.hvac:before {
  content: "\f10e";
}
.material-icons.ice_skating:before {
  content: "\e50b";
}
.material-icons.icecream:before {
  content: "\ea69";
}
.material-icons.image:before {
  content: "\e3f4";
}
.material-icons.image_aspect_ratio:before {
  content: "\e3f5";
}
.material-icons.image_not_supported:before {
  content: "\f116";
}
.material-icons.image_search:before {
  content: "\e43f";
}
.material-icons.imagesearch_roller:before {
  content: "\e9b4";
}
.material-icons.import_contacts:before {
  content: "\e0e0";
}
.material-icons.import_export:before {
  content: "\e0c3";
}
.material-icons.important_devices:before {
  content: "\e912";
}
.material-icons.inbox:before {
  content: "\e156";
}
.material-icons.incomplete_circle:before {
  content: "\e79b";
}
.material-icons.indeterminate_check_box:before {
  content: "\e909";
}
.material-icons.info:before {
  content: "\e88e";
}
.material-icons.info_outline:before {
  content: "\e88f";
}
.material-icons.input:before {
  content: "\e890";
}
.material-icons.insert_chart:before {
  content: "\e24b";
}
.material-icons.insert_chart_outlined:before {
  content: "\e26a";
}
.material-icons.insert_comment:before {
  content: "\e24c";
}
.material-icons.insert_drive_file:before {
  content: "\e24d";
}
.material-icons.insert_emoticon:before {
  content: "\e24e";
}
.material-icons.insert_invitation:before {
  content: "\e24f";
}
.material-icons.insert_link:before {
  content: "\e250";
}
.material-icons.insert_page_break:before {
  content: "\eaca";
}
.material-icons.insert_photo:before {
  content: "\e251";
}
.material-icons.insights:before {
  content: "\f092";
}
.material-icons.install_desktop:before {
  content: "\eb71";
}
.material-icons.install_mobile:before {
  content: "\eb72";
}
.material-icons.integration_instructions:before {
  content: "\ef54";
}
.material-icons.interests:before {
  content: "\e7c8";
}
.material-icons.interpreter_mode:before {
  content: "\e83b";
}
.material-icons.inventory:before {
  content: "\e179";
}
.material-icons.inventory_2:before {
  content: "\e1a1";
}
.material-icons.invert_colors:before {
  content: "\e891";
}
.material-icons.invert_colors_off:before {
  content: "\e0c4";
}
.material-icons.invert_colors_on:before {
  content: "\e891";
}
.material-icons.ios_share:before {
  content: "\e6b8";
}
.material-icons.iron:before {
  content: "\e583";
}
.material-icons.iso:before {
  content: "\e3f6";
}
.material-icons.javascript:before {
  content: "\eb7c";
}
.material-icons.join_full:before {
  content: "\eaeb";
}
.material-icons.join_inner:before {
  content: "\eaf4";
}
.material-icons.join_left:before {
  content: "\eaf2";
}
.material-icons.join_right:before {
  content: "\eaea";
}
.material-icons.kayaking:before {
  content: "\e50c";
}
.material-icons.kebab_dining:before {
  content: "\e842";
}
.material-icons.key:before {
  content: "\e73c";
}
.material-icons.key_off:before {
  content: "\eb84";
}
.material-icons.keyboard:before {
  content: "\e312";
}
.material-icons.keyboard_alt:before {
  content: "\f028";
}
.material-icons.keyboard_arrow_down:before {
  content: "\e313";
}
.material-icons.keyboard_arrow_left:before {
  content: "\e314";
}
.material-icons.keyboard_arrow_right:before {
  content: "\e315";
}
.material-icons.keyboard_arrow_up:before {
  content: "\e316";
}
.material-icons.keyboard_backspace:before {
  content: "\e317";
}
.material-icons.keyboard_capslock:before {
  content: "\e318";
}
.material-icons.keyboard_command:before {
  content: "\eae0";
}
.material-icons.keyboard_command_key:before {
  content: "\eae7";
}
.material-icons.keyboard_control:before {
  content: "\e5d3";
}
.material-icons.keyboard_control_key:before {
  content: "\eae6";
}
.material-icons.keyboard_double_arrow_down:before {
  content: "\ead0";
}
.material-icons.keyboard_double_arrow_left:before {
  content: "\eac3";
}
.material-icons.keyboard_double_arrow_right:before {
  content: "\eac9";
}
.material-icons.keyboard_double_arrow_up:before {
  content: "\eacf";
}
.material-icons.keyboard_hide:before {
  content: "\e31a";
}
.material-icons.keyboard_option:before {
  content: "\eadf";
}
.material-icons.keyboard_option_key:before {
  content: "\eae8";
}
.material-icons.keyboard_return:before {
  content: "\e31b";
}
.material-icons.keyboard_tab:before {
  content: "\e31c";
}
.material-icons.keyboard_voice:before {
  content: "\e31d";
}
.material-icons.king_bed:before {
  content: "\ea45";
}
.material-icons.kitchen:before {
  content: "\eb47";
}
.material-icons.kitesurfing:before {
  content: "\e50d";
}
.material-icons.label:before {
  content: "\e892";
}
.material-icons.label_important:before {
  content: "\e937";
}
.material-icons.label_important_outline:before {
  content: "\e948";
}
.material-icons.label_off:before {
  content: "\e9b6";
}
.material-icons.label_outline:before {
  content: "\e893";
}
.material-icons.lan:before {
  content: "\eb2f";
}
.material-icons.landscape:before {
  content: "\e3f7";
}
.material-icons.landslide:before {
  content: "\ebd7";
}
.material-icons.language:before {
  content: "\e894";
}
.material-icons.laptop:before {
  content: "\e31e";
}
.material-icons.laptop_chromebook:before {
  content: "\e31f";
}
.material-icons.laptop_mac:before {
  content: "\e320";
}
.material-icons.laptop_windows:before {
  content: "\e321";
}
.material-icons.last_page:before {
  content: "\e5dd";
}
.material-icons.launch:before {
  content: "\e895";
}
.material-icons.layers:before {
  content: "\e53b";
}
.material-icons.layers_clear:before {
  content: "\e53c";
}
.material-icons.leaderboard:before {
  content: "\f20c";
}
.material-icons.leak_add:before {
  content: "\e3f8";
}
.material-icons.leak_remove:before {
  content: "\e3f9";
}
.material-icons.leave_bags_at_home:before {
  content: "\f21b";
}
.material-icons.legend_toggle:before {
  content: "\f11b";
}
.material-icons.lens:before {
  content: "\e3fa";
}
.material-icons.lens_blur:before {
  content: "\f029";
}
.material-icons.library_add:before {
  content: "\e02e";
}
.material-icons.library_add_check:before {
  content: "\e9b7";
}
.material-icons.library_books:before {
  content: "\e02f";
}
.material-icons.library_music:before {
  content: "\e030";
}
.material-icons.light:before {
  content: "\f02a";
}
.material-icons.light_mode:before {
  content: "\e518";
}
.material-icons.lightbulb:before {
  content: "\e0f0";
}
.material-icons.lightbulb_circle:before {
  content: "\ebfe";
}
.material-icons.lightbulb_outline:before {
  content: "\e90f";
}
.material-icons.line_axis:before {
  content: "\ea9a";
}
.material-icons.line_style:before {
  content: "\e919";
}
.material-icons.line_weight:before {
  content: "\e91a";
}
.material-icons.linear_scale:before {
  content: "\e260";
}
.material-icons.link:before {
  content: "\e157";
}
.material-icons.link_off:before {
  content: "\e16f";
}
.material-icons.linked_camera:before {
  content: "\e438";
}
.material-icons.liquor:before {
  content: "\ea60";
}
.material-icons.list:before {
  content: "\e896";
}
.material-icons.list_alt:before {
  content: "\e0ee";
}
.material-icons.live_help:before {
  content: "\e0c6";
}
.material-icons.live_tv:before {
  content: "\e639";
}
.material-icons.living:before {
  content: "\f02b";
}
.material-icons.local_activity:before {
  content: "\e53f";
}
.material-icons.local_airport:before {
  content: "\e53d";
}
.material-icons.local_atm:before {
  content: "\e53e";
}
.material-icons.local_attraction:before {
  content: "\e53f";
}
.material-icons.local_bar:before {
  content: "\e540";
}
.material-icons.local_cafe:before {
  content: "\e541";
}
.material-icons.local_car_wash:before {
  content: "\e542";
}
.material-icons.local_convenience_store:before {
  content: "\e543";
}
.material-icons.local_dining:before {
  content: "\e556";
}
.material-icons.local_drink:before {
  content: "\e544";
}
.material-icons.local_fire_department:before {
  content: "\ef55";
}
.material-icons.local_florist:before {
  content: "\e545";
}
.material-icons.local_gas_station:before {
  content: "\e546";
}
.material-icons.local_grocery_store:before {
  content: "\e547";
}
.material-icons.local_hospital:before {
  content: "\e548";
}
.material-icons.local_hotel:before {
  content: "\e549";
}
.material-icons.local_laundry_service:before {
  content: "\e54a";
}
.material-icons.local_library:before {
  content: "\e54b";
}
.material-icons.local_mall:before {
  content: "\e54c";
}
.material-icons.local_movies:before {
  content: "\e54d";
}
.material-icons.local_offer:before {
  content: "\e54e";
}
.material-icons.local_parking:before {
  content: "\e54f";
}
.material-icons.local_pharmacy:before {
  content: "\e550";
}
.material-icons.local_phone:before {
  content: "\e551";
}
.material-icons.local_pizza:before {
  content: "\e552";
}
.material-icons.local_play:before {
  content: "\e553";
}
.material-icons.local_police:before {
  content: "\ef56";
}
.material-icons.local_post_office:before {
  content: "\e554";
}
.material-icons.local_print_shop:before {
  content: "\e555";
}
.material-icons.local_printshop:before {
  content: "\e555";
}
.material-icons.local_restaurant:before {
  content: "\e556";
}
.material-icons.local_see:before {
  content: "\e557";
}
.material-icons.local_shipping:before {
  content: "\e558";
}
.material-icons.local_taxi:before {
  content: "\e559";
}
.material-icons.location_city:before {
  content: "\e7f1";
}
.material-icons.location_disabled:before {
  content: "\e1b6";
}
.material-icons.location_history:before {
  content: "\e55a";
}
.material-icons.location_off:before {
  content: "\e0c7";
}
.material-icons.location_on:before {
  content: "\e0c8";
}
.material-icons.location_pin:before {
  content: "\f1db";
}
.material-icons.location_searching:before {
  content: "\e1b7";
}
.material-icons.lock:before {
  content: "\e897";
}
.material-icons.lock_clock:before {
  content: "\ef57";
}
.material-icons.lock_open:before {
  content: "\e898";
}
.material-icons.lock_outline:before {
  content: "\e899";
}
.material-icons.lock_person:before {
  content: "\f8f3";
}
.material-icons.lock_reset:before {
  content: "\eade";
}
.material-icons.login:before {
  content: "\ea77";
}
.material-icons.logo_dev:before {
  content: "\ead6";
}
.material-icons.logout:before {
  content: "\e9ba";
}
.material-icons.looks:before {
  content: "\e3fc";
}
.material-icons.looks_3:before {
  content: "\e3fb";
}
.material-icons.looks_4:before {
  content: "\e3fd";
}
.material-icons.looks_5:before {
  content: "\e3fe";
}
.material-icons.looks_6:before {
  content: "\e3ff";
}
.material-icons.looks_one:before {
  content: "\e400";
}
.material-icons.looks_two:before {
  content: "\e401";
}
.material-icons.loop:before {
  content: "\e028";
}
.material-icons.loupe:before {
  content: "\e402";
}
.material-icons.low_priority:before {
  content: "\e16d";
}
.material-icons.loyalty:before {
  content: "\e89a";
}
.material-icons.lte_mobiledata:before {
  content: "\f02c";
}
.material-icons.lte_plus_mobiledata:before {
  content: "\f02d";
}
.material-icons.luggage:before {
  content: "\f235";
}
.material-icons.lunch_dining:before {
  content: "\ea61";
}
.material-icons.lyrics:before {
  content: "\ec0b";
}
.material-icons.mail:before {
  content: "\e158";
}
.material-icons.mail_lock:before {
  content: "\ec0a";
}
.material-icons.mail_outline:before {
  content: "\e0e1";
}
.material-icons.male:before {
  content: "\e58e";
}
.material-icons.man:before {
  content: "\e4eb";
}
.material-icons.manage_accounts:before {
  content: "\f02e";
}
.material-icons.manage_history:before {
  content: "\ebe7";
}
.material-icons.manage_search:before {
  content: "\f02f";
}
.material-icons.map:before {
  content: "\e55b";
}
.material-icons.maps_home_work:before {
  content: "\f030";
}
.material-icons.maps_ugc:before {
  content: "\ef58";
}
.material-icons.margin:before {
  content: "\e9bb";
}
.material-icons.mark_as_unread:before {
  content: "\e9bc";
}
.material-icons.mark_chat_read:before {
  content: "\f18b";
}
.material-icons.mark_chat_unread:before {
  content: "\f189";
}
.material-icons.mark_email_read:before {
  content: "\f18c";
}
.material-icons.mark_email_unread:before {
  content: "\f18a";
}
.material-icons.mark_unread_chat_alt:before {
  content: "\eb9d";
}
.material-icons.markunread:before {
  content: "\e159";
}
.material-icons.markunread_mailbox:before {
  content: "\e89b";
}
.material-icons.masks:before {
  content: "\f218";
}
.material-icons.maximize:before {
  content: "\e930";
}
.material-icons.media_bluetooth_off:before {
  content: "\f031";
}
.material-icons.media_bluetooth_on:before {
  content: "\f032";
}
.material-icons.mediation:before {
  content: "\efa7";
}
.material-icons.medical_information:before {
  content: "\ebed";
}
.material-icons.medical_services:before {
  content: "\f109";
}
.material-icons.medication:before {
  content: "\f033";
}
.material-icons.medication_liquid:before {
  content: "\ea87";
}
.material-icons.meeting_room:before {
  content: "\eb4f";
}
.material-icons.memory:before {
  content: "\e322";
}
.material-icons.menu:before {
  content: "\e5d2";
}
.material-icons.menu_book:before {
  content: "\ea19";
}
.material-icons.menu_open:before {
  content: "\e9bd";
}
.material-icons.merge:before {
  content: "\eb98";
}
.material-icons.merge_type:before {
  content: "\e252";
}
.material-icons.message:before {
  content: "\e0c9";
}
.material-icons.messenger:before {
  content: "\e0ca";
}
.material-icons.messenger_outline:before {
  content: "\e0cb";
}
.material-icons.mic:before {
  content: "\e029";
}
.material-icons.mic_external_off:before {
  content: "\ef59";
}
.material-icons.mic_external_on:before {
  content: "\ef5a";
}
.material-icons.mic_none:before {
  content: "\e02a";
}
.material-icons.mic_off:before {
  content: "\e02b";
}
.material-icons.microwave:before {
  content: "\f204";
}
.material-icons.military_tech:before {
  content: "\ea3f";
}
.material-icons.minimize:before {
  content: "\e931";
}
.material-icons.minor_crash:before {
  content: "\ebf1";
}
.material-icons.miscellaneous_services:before {
  content: "\f10c";
}
.material-icons.missed_video_call:before {
  content: "\e073";
}
.material-icons.mms:before {
  content: "\e618";
}
.material-icons.mobile_friendly:before {
  content: "\e200";
}
.material-icons.mobile_off:before {
  content: "\e201";
}
.material-icons.mobile_screen_share:before {
  content: "\e0e7";
}
.material-icons.mobiledata_off:before {
  content: "\f034";
}
.material-icons.mode:before {
  content: "\f097";
}
.material-icons.mode_comment:before {
  content: "\e253";
}
.material-icons.mode_edit:before {
  content: "\e254";
}
.material-icons.mode_edit_outline:before {
  content: "\f035";
}
.material-icons.mode_fan_off:before {
  content: "\ec17";
}
.material-icons.mode_night:before {
  content: "\f036";
}
.material-icons.mode_of_travel:before {
  content: "\e7ce";
}
.material-icons.mode_standby:before {
  content: "\f037";
}
.material-icons.model_training:before {
  content: "\f0cf";
}
.material-icons.monetization_on:before {
  content: "\e263";
}
.material-icons.money:before {
  content: "\e57d";
}
.material-icons.money_off:before {
  content: "\e25c";
}
.material-icons.money_off_csred:before {
  content: "\f038";
}
.material-icons.monitor:before {
  content: "\ef5b";
}
.material-icons.monitor_heart:before {
  content: "\eaa2";
}
.material-icons.monitor_weight:before {
  content: "\f039";
}
.material-icons.monochrome_photos:before {
  content: "\e403";
}
.material-icons.mood:before {
  content: "\e7f2";
}
.material-icons.mood_bad:before {
  content: "\e7f3";
}
.material-icons.moped:before {
  content: "\eb28";
}
.material-icons.more:before {
  content: "\e619";
}
.material-icons.more_horiz:before {
  content: "\e5d3";
}
.material-icons.more_time:before {
  content: "\ea5d";
}
.material-icons.more_vert:before {
  content: "\e5d4";
}
.material-icons.mosque:before {
  content: "\eab2";
}
.material-icons.motion_photos_auto:before {
  content: "\f03a";
}
.material-icons.motion_photos_off:before {
  content: "\e9c0";
}
.material-icons.motion_photos_on:before {
  content: "\e9c1";
}
.material-icons.motion_photos_pause:before {
  content: "\f227";
}
.material-icons.motion_photos_paused:before {
  content: "\e9c2";
}
.material-icons.motorcycle:before {
  content: "\e91b";
}
.material-icons.mouse:before {
  content: "\e323";
}
.material-icons.move_down:before {
  content: "\eb61";
}
.material-icons.move_to_inbox:before {
  content: "\e168";
}
.material-icons.move_up:before {
  content: "\eb64";
}
.material-icons.movie:before {
  content: "\e02c";
}
.material-icons.movie_creation:before {
  content: "\e404";
}
.material-icons.movie_filter:before {
  content: "\e43a";
}
.material-icons.moving:before {
  content: "\e501";
}
.material-icons.mp:before {
  content: "\e9c3";
}
.material-icons.multiline_chart:before {
  content: "\e6df";
}
.material-icons.multiple_stop:before {
  content: "\f1b9";
}
.material-icons.multitrack_audio:before {
  content: "\e1b8";
}
.material-icons.museum:before {
  content: "\ea36";
}
.material-icons.music_note:before {
  content: "\e405";
}
.material-icons.music_off:before {
  content: "\e440";
}
.material-icons.music_video:before {
  content: "\e063";
}
.material-icons.my_library_add:before {
  content: "\e02e";
}
.material-icons.my_library_books:before {
  content: "\e02f";
}
.material-icons.my_library_music:before {
  content: "\e030";
}
.material-icons.my_location:before {
  content: "\e55c";
}
.material-icons.nat:before {
  content: "\ef5c";
}
.material-icons.nature:before {
  content: "\e406";
}
.material-icons.nature_people:before {
  content: "\e407";
}
.material-icons.navigate_before:before {
  content: "\e408";
}
.material-icons.navigate_next:before {
  content: "\e409";
}
.material-icons.navigation:before {
  content: "\e55d";
}
.material-icons.near_me:before {
  content: "\e569";
}
.material-icons.near_me_disabled:before {
  content: "\f1ef";
}
.material-icons.nearby_error:before {
  content: "\f03b";
}
.material-icons.nearby_off:before {
  content: "\f03c";
}
.material-icons.nest_cam_wired_stand:before {
  content: "\ec16";
}
.material-icons.network_cell:before {
  content: "\e1b9";
}
.material-icons.network_check:before {
  content: "\e640";
}
.material-icons.network_locked:before {
  content: "\e61a";
}
.material-icons.network_ping:before {
  content: "\ebca";
}
.material-icons.network_wifi:before {
  content: "\e1ba";
}
.material-icons.network_wifi_1_bar:before {
  content: "\ebe4";
}
.material-icons.network_wifi_2_bar:before {
  content: "\ebd6";
}
.material-icons.network_wifi_3_bar:before {
  content: "\ebe1";
}
.material-icons.new_label:before {
  content: "\e609";
}
.material-icons.new_releases:before {
  content: "\e031";
}
.material-icons.newspaper:before {
  content: "\eb81";
}
.material-icons.next_plan:before {
  content: "\ef5d";
}
.material-icons.next_week:before {
  content: "\e16a";
}
.material-icons.nfc:before {
  content: "\e1bb";
}
.material-icons.night_shelter:before {
  content: "\f1f1";
}
.material-icons.nightlife:before {
  content: "\ea62";
}
.material-icons.nightlight:before {
  content: "\f03d";
}
.material-icons.nightlight_round:before {
  content: "\ef5e";
}
.material-icons.nights_stay:before {
  content: "\ea46";
}
.material-icons.no_accounts:before {
  content: "\f03e";
}
.material-icons.no_adult_content:before {
  content: "\f8fe";
}
.material-icons.no_backpack:before {
  content: "\f237";
}
.material-icons.no_cell:before {
  content: "\f1a4";
}
.material-icons.no_crash:before {
  content: "\ebf0";
}
.material-icons.no_drinks:before {
  content: "\f1a5";
}
.material-icons.no_encryption:before {
  content: "\e641";
}
.material-icons.no_encryption_gmailerrorred:before {
  content: "\f03f";
}
.material-icons.no_flash:before {
  content: "\f1a6";
}
.material-icons.no_food:before {
  content: "\f1a7";
}
.material-icons.no_luggage:before {
  content: "\f23b";
}
.material-icons.no_meals:before {
  content: "\f1d6";
}
.material-icons.no_meals_ouline:before {
  content: "\f229";
}
.material-icons.no_meeting_room:before {
  content: "\eb4e";
}
.material-icons.no_photography:before {
  content: "\f1a8";
}
.material-icons.no_sim:before {
  content: "\e0cc";
}
.material-icons.no_stroller:before {
  content: "\f1af";
}
.material-icons.no_transfer:before {
  content: "\f1d5";
}
.material-icons.noise_aware:before {
  content: "\ebec";
}
.material-icons.noise_control_off:before {
  content: "\ebf3";
}
.material-icons.nordic_walking:before {
  content: "\e50e";
}
.material-icons.north:before {
  content: "\f1e0";
}
.material-icons.north_east:before {
  content: "\f1e1";
}
.material-icons.north_west:before {
  content: "\f1e2";
}
.material-icons.not_accessible:before {
  content: "\f0fe";
}
.material-icons.not_interested:before {
  content: "\e033";
}
.material-icons.not_listed_location:before {
  content: "\e575";
}
.material-icons.not_started:before {
  content: "\f0d1";
}
.material-icons.note:before {
  content: "\e06f";
}
.material-icons.note_add:before {
  content: "\e89c";
}
.material-icons.note_alt:before {
  content: "\f040";
}
.material-icons.notes:before {
  content: "\e26c";
}
.material-icons.notification_add:before {
  content: "\e399";
}
.material-icons.notification_important:before {
  content: "\e004";
}
.material-icons.notifications:before {
  content: "\e7f4";
}
.material-icons.notifications_active:before {
  content: "\e7f7";
}
.material-icons.notifications_none:before {
  content: "\e7f5";
}
.material-icons.notifications_off:before {
  content: "\e7f6";
}
.material-icons.notifications_on:before {
  content: "\e7f7";
}
.material-icons.notifications_paused:before {
  content: "\e7f8";
}
.material-icons.now_wallpaper:before {
  content: "\e1bc";
}
.material-icons.now_widgets:before {
  content: "\e1bd";
}
.material-icons.numbers:before {
  content: "\eac7";
}
.material-icons.offline_bolt:before {
  content: "\e932";
}
.material-icons.offline_pin:before {
  content: "\e90a";
}
.material-icons.offline_share:before {
  content: "\e9c5";
}
.material-icons.oil_barrel:before {
  content: "\ec15";
}
.material-icons.on_device_training:before {
  content: "\ebfd";
}
.material-icons.ondemand_video:before {
  content: "\e63a";
}
.material-icons.online_prediction:before {
  content: "\f0eb";
}
.material-icons.opacity:before {
  content: "\e91c";
}
.material-icons.open_in_browser:before {
  content: "\e89d";
}
.material-icons.open_in_full:before {
  content: "\f1ce";
}
.material-icons.open_in_new:before {
  content: "\e89e";
}
.material-icons.open_in_new_off:before {
  content: "\e4f6";
}
.material-icons.open_with:before {
  content: "\e89f";
}
.material-icons.other_houses:before {
  content: "\e58c";
}
.material-icons.outbond:before {
  content: "\f228";
}
.material-icons.outbound:before {
  content: "\e1ca";
}
.material-icons.outbox:before {
  content: "\ef5f";
}
.material-icons.outdoor_grill:before {
  content: "\ea47";
}
.material-icons.outgoing_mail:before {
  content: "\f0d2";
}
.material-icons.outlet:before {
  content: "\f1d4";
}
.material-icons.outlined_flag:before {
  content: "\e16e";
}
.material-icons.output:before {
  content: "\ebbe";
}
.material-icons.padding:before {
  content: "\e9c8";
}
.material-icons.pages:before {
  content: "\e7f9";
}
.material-icons.pageview:before {
  content: "\e8a0";
}
.material-icons.paid:before {
  content: "\f041";
}
.material-icons.palette:before {
  content: "\e40a";
}
.material-icons.pan_tool:before {
  content: "\e925";
}
.material-icons.pan_tool_alt:before {
  content: "\ebb9";
}
.material-icons.panorama:before {
  content: "\e40b";
}
.material-icons.panorama_fish_eye:before {
  content: "\e40c";
}
.material-icons.panorama_fisheye:before {
  content: "\e40c";
}
.material-icons.panorama_horizontal:before {
  content: "\e40d";
}
.material-icons.panorama_horizontal_select:before {
  content: "\ef60";
}
.material-icons.panorama_photosphere:before {
  content: "\e9c9";
}
.material-icons.panorama_photosphere_select:before {
  content: "\e9ca";
}
.material-icons.panorama_vertical:before {
  content: "\e40e";
}
.material-icons.panorama_vertical_select:before {
  content: "\ef61";
}
.material-icons.panorama_wide_angle:before {
  content: "\e40f";
}
.material-icons.panorama_wide_angle_select:before {
  content: "\ef62";
}
.material-icons.paragliding:before {
  content: "\e50f";
}
.material-icons.park:before {
  content: "\ea63";
}
.material-icons.party_mode:before {
  content: "\e7fa";
}
.material-icons.password:before {
  content: "\f042";
}
.material-icons.pattern:before {
  content: "\f043";
}
.material-icons.pause:before {
  content: "\e034";
}
.material-icons.pause_circle:before {
  content: "\e1a2";
}
.material-icons.pause_circle_filled:before {
  content: "\e035";
}
.material-icons.pause_circle_outline:before {
  content: "\e036";
}
.material-icons.pause_presentation:before {
  content: "\e0ea";
}
.material-icons.payment:before {
  content: "\e8a1";
}
.material-icons.payments:before {
  content: "\ef63";
}
.material-icons.paypal:before {
  content: "\ea8d";
}
.material-icons.pedal_bike:before {
  content: "\eb29";
}
.material-icons.pending:before {
  content: "\ef64";
}
.material-icons.pending_actions:before {
  content: "\f1bb";
}
.material-icons.pentagon:before {
  content: "\eb50";
}
.material-icons.people:before {
  content: "\e7fb";
}
.material-icons.people_alt:before {
  content: "\ea21";
}
.material-icons.people_outline:before {
  content: "\e7fc";
}
.material-icons.percent:before {
  content: "\eb58";
}
.material-icons.perm_camera_mic:before {
  content: "\e8a2";
}
.material-icons.perm_contact_cal:before {
  content: "\e8a3";
}
.material-icons.perm_contact_calendar:before {
  content: "\e8a3";
}
.material-icons.perm_data_setting:before {
  content: "\e8a4";
}
.material-icons.perm_device_info:before {
  content: "\e8a5";
}
.material-icons.perm_device_information:before {
  content: "\e8a5";
}
.material-icons.perm_identity:before {
  content: "\e8a6";
}
.material-icons.perm_media:before {
  content: "\e8a7";
}
.material-icons.perm_phone_msg:before {
  content: "\e8a8";
}
.material-icons.perm_scan_wifi:before {
  content: "\e8a9";
}
.material-icons.person:before {
  content: "\e7fd";
}
.material-icons.person_add:before {
  content: "\e7fe";
}
.material-icons.person_add_alt:before {
  content: "\ea4d";
}
.material-icons.person_add_alt_1:before {
  content: "\ef65";
}
.material-icons.person_add_disabled:before {
  content: "\e9cb";
}
.material-icons.person_off:before {
  content: "\e510";
}
.material-icons.person_outline:before {
  content: "\e7ff";
}
.material-icons.person_pin:before {
  content: "\e55a";
}
.material-icons.person_pin_circle:before {
  content: "\e56a";
}
.material-icons.person_remove:before {
  content: "\ef66";
}
.material-icons.person_remove_alt_1:before {
  content: "\ef67";
}
.material-icons.person_search:before {
  content: "\f106";
}
.material-icons.personal_injury:before {
  content: "\e6da";
}
.material-icons.personal_video:before {
  content: "\e63b";
}
.material-icons.pest_control:before {
  content: "\f0fa";
}
.material-icons.pest_control_rodent:before {
  content: "\f0fd";
}
.material-icons.pets:before {
  content: "\e91d";
}
.material-icons.phishing:before {
  content: "\ead7";
}
.material-icons.phone:before {
  content: "\e0cd";
}
.material-icons.phone_android:before {
  content: "\e324";
}
.material-icons.phone_bluetooth_speaker:before {
  content: "\e61b";
}
.material-icons.phone_callback:before {
  content: "\e649";
}
.material-icons.phone_disabled:before {
  content: "\e9cc";
}
.material-icons.phone_enabled:before {
  content: "\e9cd";
}
.material-icons.phone_forwarded:before {
  content: "\e61c";
}
.material-icons.phone_in_talk:before {
  content: "\e61d";
}
.material-icons.phone_iphone:before {
  content: "\e325";
}
.material-icons.phone_locked:before {
  content: "\e61e";
}
.material-icons.phone_missed:before {
  content: "\e61f";
}
.material-icons.phone_paused:before {
  content: "\e620";
}
.material-icons.phonelink:before {
  content: "\e326";
}
.material-icons.phonelink_erase:before {
  content: "\e0db";
}
.material-icons.phonelink_lock:before {
  content: "\e0dc";
}
.material-icons.phonelink_off:before {
  content: "\e327";
}
.material-icons.phonelink_ring:before {
  content: "\e0dd";
}
.material-icons.phonelink_setup:before {
  content: "\e0de";
}
.material-icons.photo:before {
  content: "\e410";
}
.material-icons.photo_album:before {
  content: "\e411";
}
.material-icons.photo_camera:before {
  content: "\e412";
}
.material-icons.photo_camera_back:before {
  content: "\ef68";
}
.material-icons.photo_camera_front:before {
  content: "\ef69";
}
.material-icons.photo_filter:before {
  content: "\e43b";
}
.material-icons.photo_library:before {
  content: "\e413";
}
.material-icons.photo_size_select_actual:before {
  content: "\e432";
}
.material-icons.photo_size_select_large:before {
  content: "\e433";
}
.material-icons.photo_size_select_small:before {
  content: "\e434";
}
.material-icons.php:before {
  content: "\eb8f";
}
.material-icons.piano:before {
  content: "\e521";
}
.material-icons.piano_off:before {
  content: "\e520";
}
.material-icons.picture_as_pdf:before {
  content: "\e415";
}
.material-icons.picture_in_picture:before {
  content: "\e8aa";
}
.material-icons.picture_in_picture_alt:before {
  content: "\e911";
}
.material-icons.pie_chart:before {
  content: "\e6c4";
}
.material-icons.pie_chart_outline:before {
  content: "\f044";
}
.material-icons.pie_chart_outlined:before {
  content: "\e6c5";
}
.material-icons.pin:before {
  content: "\f045";
}
.material-icons.pin_drop:before {
  content: "\e55e";
}
.material-icons.pin_end:before {
  content: "\e767";
}
.material-icons.pin_invoke:before {
  content: "\e763";
}
.material-icons.pinch:before {
  content: "\eb38";
}
.material-icons.pivot_table_chart:before {
  content: "\e9ce";
}
.material-icons.pix:before {
  content: "\eaa3";
}
.material-icons.place:before {
  content: "\e55f";
}
.material-icons.plagiarism:before {
  content: "\ea5a";
}
.material-icons.play_arrow:before {
  content: "\e037";
}
.material-icons.play_circle:before {
  content: "\e1c4";
}
.material-icons.play_circle_fill:before {
  content: "\e038";
}
.material-icons.play_circle_filled:before {
  content: "\e038";
}
.material-icons.play_circle_outline:before {
  content: "\e039";
}
.material-icons.play_disabled:before {
  content: "\ef6a";
}
.material-icons.play_for_work:before {
  content: "\e906";
}
.material-icons.play_lesson:before {
  content: "\f047";
}
.material-icons.playlist_add:before {
  content: "\e03b";
}
.material-icons.playlist_add_check:before {
  content: "\e065";
}
.material-icons.playlist_add_check_circle:before {
  content: "\e7e6";
}
.material-icons.playlist_add_circle:before {
  content: "\e7e5";
}
.material-icons.playlist_play:before {
  content: "\e05f";
}
.material-icons.playlist_remove:before {
  content: "\eb80";
}
.material-icons.plumbing:before {
  content: "\f107";
}
.material-icons.plus_one:before {
  content: "\e800";
}
.material-icons.podcasts:before {
  content: "\f048";
}
.material-icons.point_of_sale:before {
  content: "\f17e";
}
.material-icons.policy:before {
  content: "\ea17";
}
.material-icons.poll:before {
  content: "\e801";
}
.material-icons.polyline:before {
  content: "\ebbb";
}
.material-icons.polymer:before {
  content: "\e8ab";
}
.material-icons.pool:before {
  content: "\eb48";
}
.material-icons.portable_wifi_off:before {
  content: "\e0ce";
}
.material-icons.portrait:before {
  content: "\e416";
}
.material-icons.post_add:before {
  content: "\ea20";
}
.material-icons.power:before {
  content: "\e63c";
}
.material-icons.power_input:before {
  content: "\e336";
}
.material-icons.power_off:before {
  content: "\e646";
}
.material-icons.power_settings_new:before {
  content: "\e8ac";
}
.material-icons.precision_manufacturing:before {
  content: "\f049";
}
.material-icons.pregnant_woman:before {
  content: "\e91e";
}
.material-icons.present_to_all:before {
  content: "\e0df";
}
.material-icons.preview:before {
  content: "\f1c5";
}
.material-icons.price_change:before {
  content: "\f04a";
}
.material-icons.price_check:before {
  content: "\f04b";
}
.material-icons.print:before {
  content: "\e8ad";
}
.material-icons.print_disabled:before {
  content: "\e9cf";
}
.material-icons.priority_high:before {
  content: "\e645";
}
.material-icons.privacy_tip:before {
  content: "\f0dc";
}
.material-icons.private_connectivity:before {
  content: "\e744";
}
.material-icons.production_quantity_limits:before {
  content: "\e1d1";
}
.material-icons.propane:before {
  content: "\ec14";
}
.material-icons.propane_tank:before {
  content: "\ec13";
}
.material-icons.psychology:before {
  content: "\ea4a";
}
.material-icons.psychology_alt:before {
  content: "\f8ea";
}
.material-icons.public:before {
  content: "\e80b";
}
.material-icons.public_off:before {
  content: "\f1ca";
}
.material-icons.publish:before {
  content: "\e255";
}
.material-icons.published_with_changes:before {
  content: "\f232";
}
.material-icons.punch_clock:before {
  content: "\eaa8";
}
.material-icons.push_pin:before {
  content: "\f10d";
}
.material-icons.qr_code:before {
  content: "\ef6b";
}
.material-icons.qr_code_2:before {
  content: "\e00a";
}
.material-icons.qr_code_scanner:before {
  content: "\f206";
}
.material-icons.query_builder:before {
  content: "\e8ae";
}
.material-icons.query_stats:before {
  content: "\e4fc";
}
.material-icons.question_answer:before {
  content: "\e8af";
}
.material-icons.question_mark:before {
  content: "\eb8b";
}
.material-icons.queue:before {
  content: "\e03c";
}
.material-icons.queue_music:before {
  content: "\e03d";
}
.material-icons.queue_play_next:before {
  content: "\e066";
}
.material-icons.quick_contacts_dialer:before {
  content: "\e0cf";
}
.material-icons.quick_contacts_mail:before {
  content: "\e0d0";
}
.material-icons.quickreply:before {
  content: "\ef6c";
}
.material-icons.quiz:before {
  content: "\f04c";
}
.material-icons.quora:before {
  content: "\ea98";
}
.material-icons.r_mobiledata:before {
  content: "\f04d";
}
.material-icons.radar:before {
  content: "\f04e";
}
.material-icons.radio:before {
  content: "\e03e";
}
.material-icons.radio_button_checked:before {
  content: "\e837";
}
.material-icons.radio_button_off:before {
  content: "\e836";
}
.material-icons.radio_button_on:before {
  content: "\e837";
}
.material-icons.radio_button_unchecked:before {
  content: "\e836";
}
.material-icons.railway_alert:before {
  content: "\e9d1";
}
.material-icons.ramen_dining:before {
  content: "\ea64";
}
.material-icons.ramp_left:before {
  content: "\eb9c";
}
.material-icons.ramp_right:before {
  content: "\eb96";
}
.material-icons.rate_review:before {
  content: "\e560";
}
.material-icons.raw_off:before {
  content: "\f04f";
}
.material-icons.raw_on:before {
  content: "\f050";
}
.material-icons.read_more:before {
  content: "\ef6d";
}
.material-icons.real_estate_agent:before {
  content: "\e73a";
}
.material-icons.receipt:before {
  content: "\e8b0";
}
.material-icons.receipt_long:before {
  content: "\ef6e";
}
.material-icons.recent_actors:before {
  content: "\e03f";
}
.material-icons.recommend:before {
  content: "\e9d2";
}
.material-icons.record_voice_over:before {
  content: "\e91f";
}
.material-icons.rectangle:before {
  content: "\eb54";
}
.material-icons.recycling:before {
  content: "\e760";
}
.material-icons.reddit:before {
  content: "\eaa0";
}
.material-icons.redeem:before {
  content: "\e8b1";
}
.material-icons.redo:before {
  content: "\e15a";
}
.material-icons.reduce_capacity:before {
  content: "\f21c";
}
.material-icons.refresh:before {
  content: "\e5d5";
}
.material-icons.remember_me:before {
  content: "\f051";
}
.material-icons.remove:before {
  content: "\e15b";
}
.material-icons.remove_circle:before {
  content: "\e15c";
}
.material-icons.remove_circle_outline:before {
  content: "\e15d";
}
.material-icons.remove_done:before {
  content: "\e9d3";
}
.material-icons.remove_from_queue:before {
  content: "\e067";
}
.material-icons.remove_moderator:before {
  content: "\e9d4";
}
.material-icons.remove_red_eye:before {
  content: "\e417";
}
.material-icons.remove_road:before {
  content: "\ebfc";
}
.material-icons.remove_shopping_cart:before {
  content: "\e928";
}
.material-icons.reorder:before {
  content: "\e8fe";
}
.material-icons.repartition:before {
  content: "\f8e8";
}
.material-icons.repeat:before {
  content: "\e040";
}
.material-icons.repeat_on:before {
  content: "\e9d6";
}
.material-icons.repeat_one:before {
  content: "\e041";
}
.material-icons.repeat_one_on:before {
  content: "\e9d7";
}
.material-icons.replay:before {
  content: "\e042";
}
.material-icons.replay_10:before {
  content: "\e059";
}
.material-icons.replay_30:before {
  content: "\e05a";
}
.material-icons.replay_5:before {
  content: "\e05b";
}
.material-icons.replay_circle_filled:before {
  content: "\e9d8";
}
.material-icons.reply:before {
  content: "\e15e";
}
.material-icons.reply_all:before {
  content: "\e15f";
}
.material-icons.report:before {
  content: "\e160";
}
.material-icons.report_gmailerrorred:before {
  content: "\f052";
}
.material-icons.report_off:before {
  content: "\e170";
}
.material-icons.report_problem:before {
  content: "\e8b2";
}
.material-icons.request_page:before {
  content: "\f22c";
}
.material-icons.request_quote:before {
  content: "\f1b6";
}
.material-icons.reset_tv:before {
  content: "\e9d9";
}
.material-icons.restart_alt:before {
  content: "\f053";
}
.material-icons.restaurant:before {
  content: "\e56c";
}
.material-icons.restaurant_menu:before {
  content: "\e561";
}
.material-icons.restore:before {
  content: "\e8b3";
}
.material-icons.restore_from_trash:before {
  content: "\e938";
}
.material-icons.restore_page:before {
  content: "\e929";
}
.material-icons.reviews:before {
  content: "\f054";
}
.material-icons.rice_bowl:before {
  content: "\f1f5";
}
.material-icons.ring_volume:before {
  content: "\e0d1";
}
.material-icons.rocket:before {
  content: "\eba5";
}
.material-icons.rocket_launch:before {
  content: "\eb9b";
}
.material-icons.roller_shades:before {
  content: "\ec12";
}
.material-icons.roller_shades_closed:before {
  content: "\ec11";
}
.material-icons.roller_skating:before {
  content: "\ebcd";
}
.material-icons.roofing:before {
  content: "\f201";
}
.material-icons.room:before {
  content: "\e8b4";
}
.material-icons.room_preferences:before {
  content: "\f1b8";
}
.material-icons.room_service:before {
  content: "\eb49";
}
.material-icons.rotate_90_degrees_ccw:before {
  content: "\e418";
}
.material-icons.rotate_90_degrees_cw:before {
  content: "\eaab";
}
.material-icons.rotate_left:before {
  content: "\e419";
}
.material-icons.rotate_right:before {
  content: "\e41a";
}
.material-icons.roundabout_left:before {
  content: "\eb99";
}
.material-icons.roundabout_right:before {
  content: "\eba3";
}
.material-icons.rounded_corner:before {
  content: "\e920";
}
.material-icons.route:before {
  content: "\eacd";
}
.material-icons.router:before {
  content: "\e328";
}
.material-icons.rowing:before {
  content: "\e921";
}
.material-icons.rss_feed:before {
  content: "\e0e5";
}
.material-icons.rsvp:before {
  content: "\f055";
}
.material-icons.rtt:before {
  content: "\e9ad";
}
.material-icons.rule:before {
  content: "\f1c2";
}
.material-icons.rule_folder:before {
  content: "\f1c9";
}
.material-icons.run_circle:before {
  content: "\ef6f";
}
.material-icons.running_with_errors:before {
  content: "\e51d";
}
.material-icons.rv_hookup:before {
  content: "\e642";
}
.material-icons.safety_check:before {
  content: "\ebef";
}
.material-icons.safety_divider:before {
  content: "\e1cc";
}
.material-icons.sailing:before {
  content: "\e502";
}
.material-icons.sanitizer:before {
  content: "\f21d";
}
.material-icons.satellite:before {
  content: "\e562";
}
.material-icons.satellite_alt:before {
  content: "\eb3a";
}
.material-icons.save:before {
  content: "\e161";
}
.material-icons.save_alt:before {
  content: "\e171";
}
.material-icons.save_as:before {
  content: "\eb60";
}
.material-icons.saved_search:before {
  content: "\ea11";
}
.material-icons.savings:before {
  content: "\e2eb";
}
.material-icons.scale:before {
  content: "\eb5f";
}
.material-icons.scanner:before {
  content: "\e329";
}
.material-icons.scatter_plot:before {
  content: "\e268";
}
.material-icons.schedule:before {
  content: "\e8b5";
}
.material-icons.schedule_send:before {
  content: "\ea0a";
}
.material-icons.schema:before {
  content: "\e4fd";
}
.material-icons.school:before {
  content: "\e80c";
}
.material-icons.science:before {
  content: "\ea4b";
}
.material-icons.score:before {
  content: "\e269";
}
.material-icons.scoreboard:before {
  content: "\ebd0";
}
.material-icons.screen_lock_landscape:before {
  content: "\e1be";
}
.material-icons.screen_lock_portrait:before {
  content: "\e1bf";
}
.material-icons.screen_lock_rotation:before {
  content: "\e1c0";
}
.material-icons.screen_rotation:before {
  content: "\e1c1";
}
.material-icons.screen_rotation_alt:before {
  content: "\ebee";
}
.material-icons.screen_search_desktop:before {
  content: "\ef70";
}
.material-icons.screen_share:before {
  content: "\e0e2";
}
.material-icons.screenshot:before {
  content: "\f056";
}
.material-icons.screenshot_monitor:before {
  content: "\ec08";
}
.material-icons.scuba_diving:before {
  content: "\ebce";
}
.material-icons.sd:before {
  content: "\e9dd";
}
.material-icons.sd_card:before {
  content: "\e623";
}
.material-icons.sd_card_alert:before {
  content: "\f057";
}
.material-icons.sd_storage:before {
  content: "\e1c2";
}
.material-icons.search:before {
  content: "\e8b6";
}
.material-icons.search_off:before {
  content: "\ea76";
}
.material-icons.security:before {
  content: "\e32a";
}
.material-icons.security_update:before {
  content: "\f058";
}
.material-icons.security_update_good:before {
  content: "\f059";
}
.material-icons.security_update_warning:before {
  content: "\f05a";
}
.material-icons.segment:before {
  content: "\e94b";
}
.material-icons.select_all:before {
  content: "\e162";
}
.material-icons.self_improvement:before {
  content: "\ea78";
}
.material-icons.sell:before {
  content: "\f05b";
}
.material-icons.send:before {
  content: "\e163";
}
.material-icons.send_and_archive:before {
  content: "\ea0c";
}
.material-icons.send_time_extension:before {
  content: "\eadb";
}
.material-icons.send_to_mobile:before {
  content: "\f05c";
}
.material-icons.sensor_door:before {
  content: "\f1b5";
}
.material-icons.sensor_occupied:before {
  content: "\ec10";
}
.material-icons.sensor_window:before {
  content: "\f1b4";
}
.material-icons.sensors:before {
  content: "\e51e";
}
.material-icons.sensors_off:before {
  content: "\e51f";
}
.material-icons.sentiment_dissatisfied:before {
  content: "\e811";
}
.material-icons.sentiment_neutral:before {
  content: "\e812";
}
.material-icons.sentiment_satisfied:before {
  content: "\e813";
}
.material-icons.sentiment_satisfied_alt:before {
  content: "\e0ed";
}
.material-icons.sentiment_very_dissatisfied:before {
  content: "\e814";
}
.material-icons.sentiment_very_satisfied:before {
  content: "\e815";
}
.material-icons.set_meal:before {
  content: "\f1ea";
}
.material-icons.settings:before {
  content: "\e8b8";
}
.material-icons.settings_accessibility:before {
  content: "\f05d";
}
.material-icons.settings_applications:before {
  content: "\e8b9";
}
.material-icons.settings_backup_restore:before {
  content: "\e8ba";
}
.material-icons.settings_bluetooth:before {
  content: "\e8bb";
}
.material-icons.settings_brightness:before {
  content: "\e8bd";
}
.material-icons.settings_cell:before {
  content: "\e8bc";
}
.material-icons.settings_display:before {
  content: "\e8bd";
}
.material-icons.settings_ethernet:before {
  content: "\e8be";
}
.material-icons.settings_input_antenna:before {
  content: "\e8bf";
}
.material-icons.settings_input_component:before {
  content: "\e8c0";
}
.material-icons.settings_input_composite:before {
  content: "\e8c1";
}
.material-icons.settings_input_hdmi:before {
  content: "\e8c2";
}
.material-icons.settings_input_svideo:before {
  content: "\e8c3";
}
.material-icons.settings_overscan:before {
  content: "\e8c4";
}
.material-icons.settings_phone:before {
  content: "\e8c5";
}
.material-icons.settings_power:before {
  content: "\e8c6";
}
.material-icons.settings_remote:before {
  content: "\e8c7";
}
.material-icons.settings_suggest:before {
  content: "\f05e";
}
.material-icons.settings_system_daydream:before {
  content: "\e1c3";
}
.material-icons.settings_voice:before {
  content: "\e8c8";
}
.material-icons.severe_cold:before {
  content: "\ebd3";
}
.material-icons.share:before {
  content: "\e80d";
}
.material-icons.share_arrival_time:before {
  content: "\e524";
}
.material-icons.share_location:before {
  content: "\f05f";
}
.material-icons.shield:before {
  content: "\e9e0";
}
.material-icons.shield_moon:before {
  content: "\eaa9";
}
.material-icons.shop:before {
  content: "\e8c9";
}
.material-icons.shop_2:before {
  content: "\e19e";
}
.material-icons.shop_two:before {
  content: "\e8ca";
}
.material-icons.shopify:before {
  content: "\ea9d";
}
.material-icons.shopping_bag:before {
  content: "\f1cc";
}
.material-icons.shopping_basket:before {
  content: "\e8cb";
}
.material-icons.shopping_cart:before {
  content: "\e8cc";
}
.material-icons.shopping_cart_checkout:before {
  content: "\eb88";
}
.material-icons.short_text:before {
  content: "\e261";
}
.material-icons.shortcut:before {
  content: "\f060";
}
.material-icons.show_chart:before {
  content: "\e6e1";
}
.material-icons.shower:before {
  content: "\f061";
}
.material-icons.shuffle:before {
  content: "\e043";
}
.material-icons.shuffle_on:before {
  content: "\e9e1";
}
.material-icons.shutter_speed:before {
  content: "\e43d";
}
.material-icons.sick:before {
  content: "\f220";
}
.material-icons.sign_language:before {
  content: "\ebe5";
}
.material-icons.signal_cellular_0_bar:before {
  content: "\f0a8";
}
.material-icons.signal_cellular_4_bar:before {
  content: "\e1c8";
}
.material-icons.signal_cellular_alt:before {
  content: "\e202";
}
.material-icons.signal_cellular_alt_1_bar:before {
  content: "\ebdf";
}
.material-icons.signal_cellular_alt_2_bar:before {
  content: "\ebe3";
}
.material-icons.signal_cellular_connected_no_internet_0_bar:before {
  content: "\f0ac";
}
.material-icons.signal_cellular_connected_no_internet_4_bar:before {
  content: "\e1cd";
}
.material-icons.signal_cellular_no_sim:before {
  content: "\e1ce";
}
.material-icons.signal_cellular_nodata:before {
  content: "\f062";
}
.material-icons.signal_cellular_null:before {
  content: "\e1cf";
}
.material-icons.signal_cellular_off:before {
  content: "\e1d0";
}
.material-icons.signal_wifi_0_bar:before {
  content: "\f0b0";
}
.material-icons.signal_wifi_4_bar:before {
  content: "\e1d8";
}
.material-icons.signal_wifi_4_bar_lock:before {
  content: "\e1d9";
}
.material-icons.signal_wifi_bad:before {
  content: "\f063";
}
.material-icons.signal_wifi_connected_no_internet_4:before {
  content: "\f064";
}
.material-icons.signal_wifi_off:before {
  content: "\e1da";
}
.material-icons.signal_wifi_statusbar_4_bar:before {
  content: "\f065";
}
.material-icons.signal_wifi_statusbar_connected_no_internet_4:before {
  content: "\f066";
}
.material-icons.signal_wifi_statusbar_null:before {
  content: "\f067";
}
.material-icons.signpost:before {
  content: "\eb91";
}
.material-icons.sim_card:before {
  content: "\e32b";
}
.material-icons.sim_card_alert:before {
  content: "\e624";
}
.material-icons.sim_card_download:before {
  content: "\f068";
}
.material-icons.single_bed:before {
  content: "\ea48";
}
.material-icons.sip:before {
  content: "\f069";
}
.material-icons.skateboarding:before {
  content: "\e511";
}
.material-icons.skip_next:before {
  content: "\e044";
}
.material-icons.skip_previous:before {
  content: "\e045";
}
.material-icons.sledding:before {
  content: "\e512";
}
.material-icons.slideshow:before {
  content: "\e41b";
}
.material-icons.slow_motion_video:before {
  content: "\e068";
}
.material-icons.smart_button:before {
  content: "\f1c1";
}
.material-icons.smart_display:before {
  content: "\f06a";
}
.material-icons.smart_screen:before {
  content: "\f06b";
}
.material-icons.smart_toy:before {
  content: "\f06c";
}
.material-icons.smartphone:before {
  content: "\e32c";
}
.material-icons.smoke_free:before {
  content: "\eb4a";
}
.material-icons.smoking_rooms:before {
  content: "\eb4b";
}
.material-icons.sms:before {
  content: "\e625";
}
.material-icons.sms_failed:before {
  content: "\e626";
}
.material-icons.snapchat:before {
  content: "\ea6e";
}
.material-icons.snippet_folder:before {
  content: "\f1c7";
}
.material-icons.snooze:before {
  content: "\e046";
}
.material-icons.snowboarding:before {
  content: "\e513";
}
.material-icons.snowing:before {
  content: "\e80f";
}
.material-icons.snowmobile:before {
  content: "\e503";
}
.material-icons.snowshoeing:before {
  content: "\e514";
}
.material-icons.soap:before {
  content: "\f1b2";
}
.material-icons.social_distance:before {
  content: "\e1cb";
}
.material-icons.solar_power:before {
  content: "\ec0f";
}
.material-icons.sort:before {
  content: "\e164";
}
.material-icons.sort_by_alpha:before {
  content: "\e053";
}
.material-icons.sos:before {
  content: "\ebf7";
}
.material-icons.soup_kitchen:before {
  content: "\e7d3";
}
.material-icons.source:before {
  content: "\f1c4";
}
.material-icons.south:before {
  content: "\f1e3";
}
.material-icons.south_america:before {
  content: "\e7e4";
}
.material-icons.south_east:before {
  content: "\f1e4";
}
.material-icons.south_west:before {
  content: "\f1e5";
}
.material-icons.spa:before {
  content: "\eb4c";
}
.material-icons.space_bar:before {
  content: "\e256";
}
.material-icons.space_dashboard:before {
  content: "\e66b";
}
.material-icons.spatial_audio:before {
  content: "\ebeb";
}
.material-icons.spatial_audio_off:before {
  content: "\ebe8";
}
.material-icons.spatial_tracking:before {
  content: "\ebea";
}
.material-icons.speaker:before {
  content: "\e32d";
}
.material-icons.speaker_group:before {
  content: "\e32e";
}
.material-icons.speaker_notes:before {
  content: "\e8cd";
}
.material-icons.speaker_notes_off:before {
  content: "\e92a";
}
.material-icons.speaker_phone:before {
  content: "\e0d2";
}
.material-icons.speed:before {
  content: "\e9e4";
}
.material-icons.spellcheck:before {
  content: "\e8ce";
}
.material-icons.splitscreen:before {
  content: "\f06d";
}
.material-icons.spoke:before {
  content: "\e9a7";
}
.material-icons.sports:before {
  content: "\ea30";
}
.material-icons.sports_bar:before {
  content: "\f1f3";
}
.material-icons.sports_baseball:before {
  content: "\ea51";
}
.material-icons.sports_basketball:before {
  content: "\ea26";
}
.material-icons.sports_cricket:before {
  content: "\ea27";
}
.material-icons.sports_esports:before {
  content: "\ea28";
}
.material-icons.sports_football:before {
  content: "\ea29";
}
.material-icons.sports_golf:before {
  content: "\ea2a";
}
.material-icons.sports_gymnastics:before {
  content: "\ebc4";
}
.material-icons.sports_handball:before {
  content: "\ea33";
}
.material-icons.sports_hockey:before {
  content: "\ea2b";
}
.material-icons.sports_kabaddi:before {
  content: "\ea34";
}
.material-icons.sports_martial_arts:before {
  content: "\eae9";
}
.material-icons.sports_mma:before {
  content: "\ea2c";
}
.material-icons.sports_motorsports:before {
  content: "\ea2d";
}
.material-icons.sports_rugby:before {
  content: "\ea2e";
}
.material-icons.sports_score:before {
  content: "\f06e";
}
.material-icons.sports_soccer:before {
  content: "\ea2f";
}
.material-icons.sports_tennis:before {
  content: "\ea32";
}
.material-icons.sports_volleyball:before {
  content: "\ea31";
}
.material-icons.square:before {
  content: "\eb36";
}
.material-icons.square_foot:before {
  content: "\ea49";
}
.material-icons.ssid_chart:before {
  content: "\eb66";
}
.material-icons.stacked_bar_chart:before {
  content: "\e9e6";
}
.material-icons.stacked_line_chart:before {
  content: "\f22b";
}
.material-icons.stadium:before {
  content: "\eb90";
}
.material-icons.stairs:before {
  content: "\f1a9";
}
.material-icons.star:before {
  content: "\e838";
}
.material-icons.star_border:before {
  content: "\e83a";
}
.material-icons.star_border_purple500:before {
  content: "\f099";
}
.material-icons.star_half:before {
  content: "\e839";
}
.material-icons.star_outline:before {
  content: "\f06f";
}
.material-icons.star_purple500:before {
  content: "\f09a";
}
.material-icons.star_rate:before {
  content: "\f0ec";
}
.material-icons.stars:before {
  content: "\e8d0";
}
.material-icons.start:before {
  content: "\e089";
}
.material-icons.stay_current_landscape:before {
  content: "\e0d3";
}
.material-icons.stay_current_portrait:before {
  content: "\e0d4";
}
.material-icons.stay_primary_landscape:before {
  content: "\e0d5";
}
.material-icons.stay_primary_portrait:before {
  content: "\e0d6";
}
.material-icons.sticky_note_2:before {
  content: "\f1fc";
}
.material-icons.stop:before {
  content: "\e047";
}
.material-icons.stop_circle:before {
  content: "\ef71";
}
.material-icons.stop_screen_share:before {
  content: "\e0e3";
}
.material-icons.storage:before {
  content: "\e1db";
}
.material-icons.store:before {
  content: "\e8d1";
}
.material-icons.store_mall_directory:before {
  content: "\e563";
}
.material-icons.storefront:before {
  content: "\ea12";
}
.material-icons.storm:before {
  content: "\f070";
}
.material-icons.straight:before {
  content: "\eb95";
}
.material-icons.straighten:before {
  content: "\e41c";
}
.material-icons.stream:before {
  content: "\e9e9";
}
.material-icons.streetview:before {
  content: "\e56e";
}
.material-icons.strikethrough_s:before {
  content: "\e257";
}
.material-icons.stroller:before {
  content: "\f1ae";
}
.material-icons.style:before {
  content: "\e41d";
}
.material-icons.subdirectory_arrow_left:before {
  content: "\e5d9";
}
.material-icons.subdirectory_arrow_right:before {
  content: "\e5da";
}
.material-icons.subject:before {
  content: "\e8d2";
}
.material-icons.subscript:before {
  content: "\f111";
}
.material-icons.subscriptions:before {
  content: "\e064";
}
.material-icons.subtitles:before {
  content: "\e048";
}
.material-icons.subtitles_off:before {
  content: "\ef72";
}
.material-icons.subway:before {
  content: "\e56f";
}
.material-icons.summarize:before {
  content: "\f071";
}
.material-icons.sunny:before {
  content: "\e81a";
}
.material-icons.sunny_snowing:before {
  content: "\e819";
}
.material-icons.superscript:before {
  content: "\f112";
}
.material-icons.supervised_user_circle:before {
  content: "\e939";
}
.material-icons.supervisor_account:before {
  content: "\e8d3";
}
.material-icons.support:before {
  content: "\ef73";
}
.material-icons.support_agent:before {
  content: "\f0e2";
}
.material-icons.surfing:before {
  content: "\e515";
}
.material-icons.surround_sound:before {
  content: "\e049";
}
.material-icons.swap_calls:before {
  content: "\e0d7";
}
.material-icons.swap_horiz:before {
  content: "\e8d4";
}
.material-icons.swap_horizontal_circle:before {
  content: "\e933";
}
.material-icons.swap_vert:before {
  content: "\e8d5";
}
.material-icons.swap_vert_circle:before {
  content: "\e8d6";
}
.material-icons.swap_vertical_circle:before {
  content: "\e8d6";
}
.material-icons.swipe:before {
  content: "\e9ec";
}
.material-icons.swipe_down:before {
  content: "\eb53";
}
.material-icons.swipe_down_alt:before {
  content: "\eb30";
}
.material-icons.swipe_left:before {
  content: "\eb59";
}
.material-icons.swipe_left_alt:before {
  content: "\eb33";
}
.material-icons.swipe_right:before {
  content: "\eb52";
}
.material-icons.swipe_right_alt:before {
  content: "\eb56";
}
.material-icons.swipe_up:before {
  content: "\eb2e";
}
.material-icons.swipe_up_alt:before {
  content: "\eb35";
}
.material-icons.swipe_vertical:before {
  content: "\eb51";
}
.material-icons.switch_access_shortcut:before {
  content: "\e7e1";
}
.material-icons.switch_access_shortcut_add:before {
  content: "\e7e2";
}
.material-icons.switch_account:before {
  content: "\e9ed";
}
.material-icons.switch_camera:before {
  content: "\e41e";
}
.material-icons.switch_left:before {
  content: "\f1d1";
}
.material-icons.switch_right:before {
  content: "\f1d2";
}
.material-icons.switch_video:before {
  content: "\e41f";
}
.material-icons.synagogue:before {
  content: "\eab0";
}
.material-icons.sync:before {
  content: "\e627";
}
.material-icons.sync_alt:before {
  content: "\ea18";
}
.material-icons.sync_disabled:before {
  content: "\e628";
}
.material-icons.sync_lock:before {
  content: "\eaee";
}
.material-icons.sync_problem:before {
  content: "\e629";
}
.material-icons.system_security_update:before {
  content: "\f072";
}
.material-icons.system_security_update_good:before {
  content: "\f073";
}
.material-icons.system_security_update_warning:before {
  content: "\f074";
}
.material-icons.system_update:before {
  content: "\e62a";
}
.material-icons.system_update_alt:before {
  content: "\e8d7";
}
.material-icons.system_update_tv:before {
  content: "\e8d7";
}
.material-icons.tab:before {
  content: "\e8d8";
}
.material-icons.tab_unselected:before {
  content: "\e8d9";
}
.material-icons.table_bar:before {
  content: "\ead2";
}
.material-icons.table_chart:before {
  content: "\e265";
}
.material-icons.table_restaurant:before {
  content: "\eac6";
}
.material-icons.table_rows:before {
  content: "\f101";
}
.material-icons.table_view:before {
  content: "\f1be";
}
.material-icons.tablet:before {
  content: "\e32f";
}
.material-icons.tablet_android:before {
  content: "\e330";
}
.material-icons.tablet_mac:before {
  content: "\e331";
}
.material-icons.tag:before {
  content: "\e9ef";
}
.material-icons.tag_faces:before {
  content: "\e420";
}
.material-icons.takeout_dining:before {
  content: "\ea74";
}
.material-icons.tap_and_play:before {
  content: "\e62b";
}
.material-icons.tapas:before {
  content: "\f1e9";
}
.material-icons.task:before {
  content: "\f075";
}
.material-icons.task_alt:before {
  content: "\e2e6";
}
.material-icons.taxi_alert:before {
  content: "\ef74";
}
.material-icons.telegram:before {
  content: "\ea6b";
}
.material-icons.temple_buddhist:before {
  content: "\eab3";
}
.material-icons.temple_hindu:before {
  content: "\eaaf";
}
.material-icons.terminal:before {
  content: "\eb8e";
}
.material-icons.terrain:before {
  content: "\e564";
}
.material-icons.text_decrease:before {
  content: "\eadd";
}
.material-icons.text_fields:before {
  content: "\e262";
}
.material-icons.text_format:before {
  content: "\e165";
}
.material-icons.text_increase:before {
  content: "\eae2";
}
.material-icons.text_rotate_up:before {
  content: "\e93a";
}
.material-icons.text_rotate_vertical:before {
  content: "\e93b";
}
.material-icons.text_rotation_angledown:before {
  content: "\e93c";
}
.material-icons.text_rotation_angleup:before {
  content: "\e93d";
}
.material-icons.text_rotation_down:before {
  content: "\e93e";
}
.material-icons.text_rotation_none:before {
  content: "\e93f";
}
.material-icons.text_snippet:before {
  content: "\f1c6";
}
.material-icons.textsms:before {
  content: "\e0d8";
}
.material-icons.texture:before {
  content: "\e421";
}
.material-icons.theater_comedy:before {
  content: "\ea66";
}
.material-icons.theaters:before {
  content: "\e8da";
}
.material-icons.thermostat:before {
  content: "\f076";
}
.material-icons.thermostat_auto:before {
  content: "\f077";
}
.material-icons.thumb_down:before {
  content: "\e8db";
}
.material-icons.thumb_down_alt:before {
  content: "\e816";
}
.material-icons.thumb_down_off_alt:before {
  content: "\e9f2";
}
.material-icons.thumb_up:before {
  content: "\e8dc";
}
.material-icons.thumb_up_alt:before {
  content: "\e817";
}
.material-icons.thumb_up_off_alt:before {
  content: "\e9f3";
}
.material-icons.thumbs_up_down:before {
  content: "\e8dd";
}
.material-icons.thunderstorm:before {
  content: "\ebdb";
}
.material-icons.tiktok:before {
  content: "\ea7e";
}
.material-icons.time_to_leave:before {
  content: "\e62c";
}
.material-icons.timelapse:before {
  content: "\e422";
}
.material-icons.timeline:before {
  content: "\e922";
}
.material-icons.timer:before {
  content: "\e425";
}
.material-icons.timer_10:before {
  content: "\e423";
}
.material-icons.timer_10_select:before {
  content: "\f07a";
}
.material-icons.timer_3:before {
  content: "\e424";
}
.material-icons.timer_3_select:before {
  content: "\f07b";
}
.material-icons.timer_off:before {
  content: "\e426";
}
.material-icons.tips_and_updates:before {
  content: "\e79a";
}
.material-icons.tire_repair:before {
  content: "\ebc8";
}
.material-icons.title:before {
  content: "\e264";
}
.material-icons.toc:before {
  content: "\e8de";
}
.material-icons.today:before {
  content: "\e8df";
}
.material-icons.toggle_off:before {
  content: "\e9f5";
}
.material-icons.toggle_on:before {
  content: "\e9f6";
}
.material-icons.token:before {
  content: "\ea25";
}
.material-icons.toll:before {
  content: "\e8e0";
}
.material-icons.tonality:before {
  content: "\e427";
}
.material-icons.topic:before {
  content: "\f1c8";
}
.material-icons.tornado:before {
  content: "\e199";
}
.material-icons.touch_app:before {
  content: "\e913";
}
.material-icons.tour:before {
  content: "\ef75";
}
.material-icons.toys:before {
  content: "\e332";
}
.material-icons.track_changes:before {
  content: "\e8e1";
}
.material-icons.traffic:before {
  content: "\e565";
}
.material-icons.train:before {
  content: "\e570";
}
.material-icons.tram:before {
  content: "\e571";
}
.material-icons.transcribe:before {
  content: "\f8ec";
}
.material-icons.transfer_within_a_station:before {
  content: "\e572";
}
.material-icons.transform:before {
  content: "\e428";
}
.material-icons.transgender:before {
  content: "\e58d";
}
.material-icons.transit_enterexit:before {
  content: "\e579";
}
.material-icons.translate:before {
  content: "\e8e2";
}
.material-icons.travel_explore:before {
  content: "\e2db";
}
.material-icons.trending_down:before {
  content: "\e8e3";
}
.material-icons.trending_flat:before {
  content: "\e8e4";
}
.material-icons.trending_neutral:before {
  content: "\e8e4";
}
.material-icons.trending_up:before {
  content: "\e8e5";
}
.material-icons.trip_origin:before {
  content: "\e57b";
}
.material-icons.troubleshoot:before {
  content: "\e1d2";
}
.material-icons.try:before {
  content: "\f07c";
}
.material-icons.tsunami:before {
  content: "\ebd8";
}
.material-icons.tty:before {
  content: "\f1aa";
}
.material-icons.tune:before {
  content: "\e429";
}
.material-icons.tungsten:before {
  content: "\f07d";
}
.material-icons.turn_left:before {
  content: "\eba6";
}
.material-icons.turn_right:before {
  content: "\ebab";
}
.material-icons.turn_sharp_left:before {
  content: "\eba7";
}
.material-icons.turn_sharp_right:before {
  content: "\ebaa";
}
.material-icons.turn_slight_left:before {
  content: "\eba4";
}
.material-icons.turn_slight_right:before {
  content: "\eb9a";
}
.material-icons.turned_in:before {
  content: "\e8e6";
}
.material-icons.turned_in_not:before {
  content: "\e8e7";
}
.material-icons.tv:before {
  content: "\e333";
}
.material-icons.tv_off:before {
  content: "\e647";
}
.material-icons.two_wheeler:before {
  content: "\e9f9";
}
.material-icons.type_specimen:before {
  content: "\f8f0";
}
.material-icons.u_turn_left:before {
  content: "\eba1";
}
.material-icons.u_turn_right:before {
  content: "\eba2";
}
.material-icons.umbrella:before {
  content: "\f1ad";
}
.material-icons.unarchive:before {
  content: "\e169";
}
.material-icons.undo:before {
  content: "\e166";
}
.material-icons.unfold_less:before {
  content: "\e5d6";
}
.material-icons.unfold_more:before {
  content: "\e5d7";
}
.material-icons.unpublished:before {
  content: "\f236";
}
.material-icons.unsubscribe:before {
  content: "\e0eb";
}
.material-icons.upcoming:before {
  content: "\f07e";
}
.material-icons.update:before {
  content: "\e923";
}
.material-icons.update_disabled:before {
  content: "\e075";
}
.material-icons.upgrade:before {
  content: "\f0fb";
}
.material-icons.upload:before {
  content: "\f09b";
}
.material-icons.upload_file:before {
  content: "\e9fc";
}
.material-icons.usb:before {
  content: "\e1e0";
}
.material-icons.usb_off:before {
  content: "\e4fa";
}
.material-icons.vaccines:before {
  content: "\e138";
}
.material-icons.vape_free:before {
  content: "\ebc6";
}
.material-icons.vaping_rooms:before {
  content: "\ebcf";
}
.material-icons.verified:before {
  content: "\ef76";
}
.material-icons.verified_user:before {
  content: "\e8e8";
}
.material-icons.vertical_align_bottom:before {
  content: "\e258";
}
.material-icons.vertical_align_center:before {
  content: "\e259";
}
.material-icons.vertical_align_top:before {
  content: "\e25a";
}
.material-icons.vertical_distribute:before {
  content: "\e076";
}
.material-icons.vertical_shades:before {
  content: "\ec0e";
}
.material-icons.vertical_shades_closed:before {
  content: "\ec0d";
}
.material-icons.vertical_split:before {
  content: "\e949";
}
.material-icons.vibration:before {
  content: "\e62d";
}
.material-icons.video_call:before {
  content: "\e070";
}
.material-icons.video_camera_back:before {
  content: "\f07f";
}
.material-icons.video_camera_front:before {
  content: "\f080";
}
.material-icons.video_collection:before {
  content: "\e04a";
}
.material-icons.video_file:before {
  content: "\eb87";
}
.material-icons.video_label:before {
  content: "\e071";
}
.material-icons.video_library:before {
  content: "\e04a";
}
.material-icons.video_settings:before {
  content: "\ea75";
}
.material-icons.video_stable:before {
  content: "\f081";
}
.material-icons.videocam:before {
  content: "\e04b";
}
.material-icons.videocam_off:before {
  content: "\e04c";
}
.material-icons.videogame_asset:before {
  content: "\e338";
}
.material-icons.videogame_asset_off:before {
  content: "\e500";
}
.material-icons.view_agenda:before {
  content: "\e8e9";
}
.material-icons.view_array:before {
  content: "\e8ea";
}
.material-icons.view_carousel:before {
  content: "\e8eb";
}
.material-icons.view_column:before {
  content: "\e8ec";
}
.material-icons.view_comfortable:before {
  content: "\e42a";
}
.material-icons.view_comfy:before {
  content: "\e42a";
}
.material-icons.view_comfy_alt:before {
  content: "\eb73";
}
.material-icons.view_compact:before {
  content: "\e42b";
}
.material-icons.view_compact_alt:before {
  content: "\eb74";
}
.material-icons.view_cozy:before {
  content: "\eb75";
}
.material-icons.view_day:before {
  content: "\e8ed";
}
.material-icons.view_headline:before {
  content: "\e8ee";
}
.material-icons.view_in_ar:before {
  content: "\e9fe";
}
.material-icons.view_kanban:before {
  content: "\eb7f";
}
.material-icons.view_list:before {
  content: "\e8ef";
}
.material-icons.view_module:before {
  content: "\e8f0";
}
.material-icons.view_quilt:before {
  content: "\e8f1";
}
.material-icons.view_sidebar:before {
  content: "\f114";
}
.material-icons.view_stream:before {
  content: "\e8f2";
}
.material-icons.view_timeline:before {
  content: "\eb85";
}
.material-icons.view_week:before {
  content: "\e8f3";
}
.material-icons.vignette:before {
  content: "\e435";
}
.material-icons.villa:before {
  content: "\e586";
}
.material-icons.visibility:before {
  content: "\e8f4";
}
.material-icons.visibility_off:before {
  content: "\e8f5";
}
.material-icons.voice_chat:before {
  content: "\e62e";
}
.material-icons.voice_over_off:before {
  content: "\e94a";
}
.material-icons.voicemail:before {
  content: "\e0d9";
}
.material-icons.volcano:before {
  content: "\ebda";
}
.material-icons.volume_down:before {
  content: "\e04d";
}
.material-icons.volume_down_alt:before {
  content: "\e79c";
}
.material-icons.volume_mute:before {
  content: "\e04e";
}
.material-icons.volume_off:before {
  content: "\e04f";
}
.material-icons.volume_up:before {
  content: "\e050";
}
.material-icons.volunteer_activism:before {
  content: "\ea70";
}
.material-icons.vpn_key:before {
  content: "\e0da";
}
.material-icons.vpn_key_off:before {
  content: "\eb7a";
}
.material-icons.vpn_lock:before {
  content: "\e62f";
}
.material-icons.vrpano:before {
  content: "\f082";
}
.material-icons.wallet:before {
  content: "\f8ff";
}
.material-icons.wallet_giftcard:before {
  content: "\e8f6";
}
.material-icons.wallet_membership:before {
  content: "\e8f7";
}
.material-icons.wallet_travel:before {
  content: "\e8f8";
}
.material-icons.wallpaper:before {
  content: "\e1bc";
}
.material-icons.warehouse:before {
  content: "\ebb8";
}
.material-icons.warning:before {
  content: "\e002";
}
.material-icons.warning_amber:before {
  content: "\f083";
}
.material-icons.wash:before {
  content: "\f1b1";
}
.material-icons.watch:before {
  content: "\e334";
}
.material-icons.watch_later:before {
  content: "\e924";
}
.material-icons.watch_off:before {
  content: "\eae3";
}
.material-icons.water:before {
  content: "\f084";
}
.material-icons.water_damage:before {
  content: "\f203";
}
.material-icons.water_drop:before {
  content: "\e798";
}
.material-icons.waterfall_chart:before {
  content: "\ea00";
}
.material-icons.waves:before {
  content: "\e176";
}
.material-icons.waving_hand:before {
  content: "\e766";
}
.material-icons.wb_auto:before {
  content: "\e42c";
}
.material-icons.wb_cloudy:before {
  content: "\e42d";
}
.material-icons.wb_incandescent:before {
  content: "\e42e";
}
.material-icons.wb_iridescent:before {
  content: "\e436";
}
.material-icons.wb_shade:before {
  content: "\ea01";
}
.material-icons.wb_sunny:before {
  content: "\e430";
}
.material-icons.wb_twighlight:before {
  content: "\ea02";
}
.material-icons.wb_twilight:before {
  content: "\e1c6";
}
.material-icons.wc:before {
  content: "\e63d";
}
.material-icons.web:before {
  content: "\e051";
}
.material-icons.web_asset:before {
  content: "\e069";
}
.material-icons.web_asset_off:before {
  content: "\e4f7";
}
.material-icons.web_stories:before {
  content: "\e595";
}
.material-icons.webhook:before {
  content: "\eb92";
}
.material-icons.wechat:before {
  content: "\ea81";
}
.material-icons.weekend:before {
  content: "\e16b";
}
.material-icons.west:before {
  content: "\f1e6";
}
.material-icons.whatsapp:before {
  content: "\ea9c";
}
.material-icons.whatshot:before {
  content: "\e80e";
}
.material-icons.wheelchair_pickup:before {
  content: "\f1ab";
}
.material-icons.where_to_vote:before {
  content: "\e177";
}
.material-icons.widgets:before {
  content: "\e1bd";
}
.material-icons.width_full:before {
  content: "\f8f5";
}
.material-icons.width_normal:before {
  content: "\f8f6";
}
.material-icons.width_wide:before {
  content: "\f8f7";
}
.material-icons.wifi:before {
  content: "\e63e";
}
.material-icons.wifi_1_bar:before {
  content: "\e4ca";
}
.material-icons.wifi_2_bar:before {
  content: "\e4d9";
}
.material-icons.wifi_calling:before {
  content: "\ef77";
}
.material-icons.wifi_calling_3:before {
  content: "\f085";
}
.material-icons.wifi_channel:before {
  content: "\eb6a";
}
.material-icons.wifi_find:before {
  content: "\eb31";
}
.material-icons.wifi_lock:before {
  content: "\e1e1";
}
.material-icons.wifi_off:before {
  content: "\e648";
}
.material-icons.wifi_password:before {
  content: "\eb6b";
}
.material-icons.wifi_protected_setup:before {
  content: "\f0fc";
}
.material-icons.wifi_tethering:before {
  content: "\e1e2";
}
.material-icons.wifi_tethering_error:before {
  content: "\ead9";
}
.material-icons.wifi_tethering_error_rounded:before {
  content: "\f086";
}
.material-icons.wifi_tethering_off:before {
  content: "\f087";
}
.material-icons.wind_power:before {
  content: "\ec0c";
}
.material-icons.window:before {
  content: "\f088";
}
.material-icons.wine_bar:before {
  content: "\f1e8";
}
.material-icons.woman:before {
  content: "\e13e";
}
.material-icons.woo_commerce:before {
  content: "\ea6d";
}
.material-icons.wordpress:before {
  content: "\ea9f";
}
.material-icons.work:before {
  content: "\e8f9";
}
.material-icons.work_history:before {
  content: "\ec09";
}
.material-icons.work_off:before {
  content: "\e942";
}
.material-icons.work_outline:before {
  content: "\e943";
}
.material-icons.workspace_premium:before {
  content: "\e7af";
}
.material-icons.workspaces:before {
  content: "\e1a0";
}
.material-icons.workspaces_filled:before {
  content: "\ea0d";
}
.material-icons.workspaces_outline:before {
  content: "\ea0f";
}
.material-icons.wrap_text:before {
  content: "\e25b";
}
.material-icons.wrong_location:before {
  content: "\ef78";
}
.material-icons.wysiwyg:before {
  content: "\f1c3";
}
.material-icons.yard:before {
  content: "\f089";
}
.material-icons.youtube_searched_for:before {
  content: "\e8fa";
}
.material-icons.zoom_in:before {
  content: "\e8ff";
}
.material-icons.zoom_in_map:before {
  content: "\eb2d";
}
.material-icons.zoom_out:before {
  content: "\e900";
}
.material-icons.zoom_out_map:before {
  content: "\e56b";
}

/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1, .mat-typography .h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2, .mat-typography .h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3, .mat-typography .h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4, .mat-typography .h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5, .mat-typography .h5 {
  font: 400 calc(14px * 0.83)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6, .mat-typography .h6 {
  font: 400 calc(14px * 0.67)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar .h1,
.mat-toolbar h2,
.mat-toolbar .h2,
.mat-toolbar h3,
.mat-toolbar .h3,
.mat-toolbar h4,
.mat-toolbar .h4,
.mat-toolbar h5,
.mat-toolbar .h5,
.mat-toolbar h6,
.mat-toolbar .h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-ripple-element {
  background-color: rgba(21, 21, 33, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: black;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e10e8d;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #1bc5bc;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f65464;
}

.mat-optgroup-label {
  color: black;
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: black;
}

.mat-pseudo-checkbox {
  color: black;
}
.mat-pseudo-checkbox::after {
  color: #f5f8fa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #e10e8d;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #1bc5bc;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f65464;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #f5f8fa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-content {
  color: white;
  background: #e10e8d;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #1bc5bc;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f65464;
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #b5b8b9;
  color: #7e8299;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #e4e6ef;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #e10e8d;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #1bc5bc;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f65464;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #e10e8d;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #1bc5bc;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f65464;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: #151521;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: #7e8299;
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #e10e8d;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #1bc5bc;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f65464;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(126, 130, 153, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: black;
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: white;
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #7e8299;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #7e8299;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #7e8299;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: black;
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #7e8299;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: black;
}

.mat-checkbox-frame {
  border-color: black;
}

.mat-checkbox-checkmark {
  fill: #f5f8fa;
}

.mat-checkbox-checkmark-path {
  stroke: #f5f8fa !important;
}

.mat-checkbox-mixedmark {
  background-color: #f5f8fa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #e10e8d;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #1bc5bc;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f65464;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: black;
}

.mat-checkbox .mat-ripple-element {
  background-color: #151521;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #e10e8d;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #1bc5bc;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f65464;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: #151521;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #e10e8d;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f65464;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #1bc5bc;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: #7e8299;
}

.mat-header-cell {
  color: black;
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: black;
}

.mat-calendar-table-header-divider::after {
  background: #7e8299;
}

.mat-calendar-body-label {
  color: black;
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #7e8299;
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: #7e8299;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.04);
}

.mat-calendar-body-in-preview {
  color: #7e8299;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: black;
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.8);
}

.mat-calendar-body-in-range::before {
  background: rgba(225, 14, 141, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(225, 14, 141, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(225, 14, 141, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #e10e8d;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(225, 14, 141, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(27, 197, 188, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(27, 197, 188, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(27, 197, 188, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #1bc5bc;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(27, 197, 188, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(246, 84, 100, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(246, 84, 100, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(246, 84, 100, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f65464;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(246, 84, 100, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #e10e8d;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #1bc5bc;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f65464;
}

.mat-date-range-input-inner[disabled] {
  color: #7e8299;
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #e4e6ef;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: #7e8299;
}

.mat-divider-vertical {
  border-right-color: #7e8299;
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: #7e8299;
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: black;
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #e10e8d;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #1bc5bc;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f65464;
}

.mat-focused .mat-form-field-required-marker {
  color: #1bc5bc;
}

.mat-form-field-ripple {
  background-color: rgba(126, 130, 153, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #e10e8d;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #1bc5bc;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f65464;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #e10e8d;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #1bc5bc;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f65464;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f65464;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f65464;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f65464;
}

.mat-error {
  color: #f65464;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: black;
}
.mat-form-field-appearance-legacy .mat-hint {
  color: black;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(126, 130, 153, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(126, 130, 153, 0.42) 0%, rgba(126, 130, 153, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(126, 130, 153, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(126, 130, 153, 0.42) 0%, rgba(126, 130, 153, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(21, 21, 33, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(21, 21, 33, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(126, 130, 153, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: #7e8299;
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(126, 130, 153, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(126, 130, 153, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #e10e8d;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #1bc5bc;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f65464;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f65464;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: #7e8299;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(126, 130, 153, 0.06);
}

.mat-icon.mat-primary {
  color: #e10e8d;
}
.mat-icon.mat-accent {
  color: #1bc5bc;
}
.mat-icon.mat-warn {
  color: #f65464;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: black;
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: #7e8299;
}

.mat-input-element {
  caret-color: #e10e8d;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #1bc5bc;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f65464;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f65464;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: black;
}

.mat-list-item-disabled {
  background-color: #eeeeee;
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after {
  color: #7e8299;
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: black;
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: #7e8299;
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-fill::after {
  background-color: #e10e8d;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #27e1d8;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #27e1d8;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #1bc5bc;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f87b87;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f87b87;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f65464;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #e10e8d;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #1bc5bc;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f65464;
}

.mat-radio-outer-circle {
  border-color: black;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e10e8d;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #e10e8d;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1bc5bc;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #1bc5bc;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f65464;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f65464;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: #7e8299;
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: #7e8299;
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: #7e8299;
}
.mat-radio-button .mat-ripple-element {
  background-color: #151521;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: #7e8299;
}

.mat-select-arrow {
  color: black;
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #e10e8d;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #1bc5bc;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f65464;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f65464;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: #7e8299;
}

.mat-drawer-container {
  background-color: #f5f8fa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: #e4e6ef;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: #e4e6ef;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px #7e8299;
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px #7e8299;
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px #7e8299;
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px #7e8299;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #1bc5bc;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(27, 197, 188, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #1bc5bc;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #e10e8d;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(225, 14, 141, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #e10e8d;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f65464;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(246, 84, 100, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f65464;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #151521;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: #7e8299;
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #e10e8d;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-primary .mat-slider-focus-ring {
  background-color: rgba(225, 14, 141, 0.2);
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #1bc5bc;
}
.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-accent .mat-slider-focus-ring {
  background-color: rgba(27, 197, 188, 0.2);
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #f65464;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-warn .mat-slider-focus-ring {
  background-color: rgba(246, 84, 100, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(21, 21, 33, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(21, 21, 33, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(21, 21, 33, 0.7), rgba(21, 21, 33, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(21, 21, 33, 0.7), rgba(21, 21, 33, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(21, 21, 33, 0.7), rgba(21, 21, 33, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: black;
}
.mat-step-header .mat-step-icon {
  background-color: black;
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #e10e8d;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f65464;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f65464;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: #7e8299;
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: #7e8299;
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: black;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid #7e8299;
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid #7e8299;
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: #7e8299;
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #7e8299;
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #e10e8d;
}
.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(39, 225, 216, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #1bc5bc;
}
.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 123, 135, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f65464;
}
.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #e10e8d;
}
.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(39, 225, 216, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #1bc5bc;
}
.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 123, 135, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #f65464;
}
.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #e10e8d;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #1bc5bc;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f65464;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(255, 255, 255, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #1bc5bc;
}

/*!
 * Bootstrap  v5.3.3 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #3699FF;
  --bs-indigo: #6610f2;
  --bs-purple: #5F5CF1;
  --bs-pink: #d63384;
  --bs-red: #F65464;
  --bs-orange: #fd7e14;
  --bs-yellow: #FFA800;
  --bs-green: #1BC5BC;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000000;
  --bs-white: #ffffff;
  --bs-gray: #7e8299;
  --bs-gray-dark: #3f4254;
  --bs-gray-100: #f5f8fa;
  --bs-gray-200: #eff2f5;
  --bs-gray-300: #e4e6ef;
  --bs-gray-400: #b5b5c3;
  --bs-gray-500: #a1a5b7;
  --bs-gray-600: #7e8299;
  --bs-gray-700: #5e6278;
  --bs-gray-800: #3f4254;
  --bs-gray-900: #181c32;
  --bs-white: #ffffff;
  --bs-light: #eff2f5;
  --bs-primary: #e10e8d;
  --bs-secondary: #e4e6ef;
  --bs-success: #1BC5BC;
  --bs-info: #5F5CF1;
  --bs-warning: #FFA800;
  --bs-danger: #F65464;
  --bs-dark: #3f4254;
  --bs-black: #000000;
  --bs-muted: #a1a5b7;
  --bs-blue: #3699FF;
  --bs-indigo: #6610f2;
  --bs-purple: #5F5CF1;
  --bs-pink: #d63384;
  --bs-red: #F65464;
  --bs-orange: #fd7e14;
  --bs-yellow: #FFA800;
  --bs-green: #1BC5BC;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-gray: #7e8299;
  --bs-body-bg: #f5f8fa;
  --bs-body-text: #151521;
  --bs-toolbar-bg: #ffffff;
  --bs-toolbar-text: #1a1a27;
  --bs-focus-bg: #eef3f7;
  --bs-tabbar-text: #171723;
  --bs-card-bg: #ffffff;
  --bs-card-text: #1e1e2d;
  --bs-item-bg: #e4e6ef;
  --bs-item-text: #1b1b29;
  --bs-white-rgb: 255, 255, 255;
  --bs-light-rgb: 239, 242, 245;
  --bs-primary-rgb: 225, 14, 141;
  --bs-secondary-rgb: 228, 230, 239;
  --bs-success-rgb: 27, 197, 188;
  --bs-info-rgb: 95, 92, 241;
  --bs-warning-rgb: 255, 168, 0;
  --bs-danger-rgb: 246, 84, 100;
  --bs-dark-rgb: 63, 66, 84;
  --bs-black-rgb: 0, 0, 0;
  --bs-muted-rgb: 161, 165, 183;
  --bs-blue-rgb: 54, 153, 255;
  --bs-indigo-rgb: 102, 16, 242;
  --bs-purple-rgb: 95, 92, 241;
  --bs-pink-rgb: 214, 51, 132;
  --bs-red-rgb: 246, 84, 100;
  --bs-orange-rgb: 253, 126, 20;
  --bs-yellow-rgb: 255, 168, 0;
  --bs-green-rgb: 27, 197, 188;
  --bs-teal-rgb: 32, 201, 151;
  --bs-cyan-rgb: 13, 202, 240;
  --bs-gray-rgb: 126, 130, 153;
  --bs-body-bg-rgb: 245, 248, 250;
  --bs-body-text-rgb: 21, 21, 33;
  --bs-toolbar-bg-rgb: 255, 255, 255;
  --bs-toolbar-text-rgb: 26, 26, 39;
  --bs-focus-bg-rgb: 238, 243, 247;
  --bs-tabbar-text-rgb: 23, 23, 35;
  --bs-card-bg-rgb: 255, 255, 255;
  --bs-card-text-rgb: 30, 30, 45;
  --bs-item-bg-rgb: 228, 230, 239;
  --bs-item-text-rgb: 27, 27, 41;
  --bs-primary-text-emphasis: #5a0638;
  --bs-secondary-text-emphasis: #5b5c60;
  --bs-success-text-emphasis: #0b4f4b;
  --bs-info-text-emphasis: #262560;
  --bs-warning-text-emphasis: #664300;
  --bs-danger-text-emphasis: #622228;
  --bs-light-text-emphasis: #5e6278;
  --bs-dark-text-emphasis: #5e6278;
  --bs-primary-bg-subtle: #f9cfe8;
  --bs-secondary-bg-subtle: #fafafc;
  --bs-success-bg-subtle: #d1f3f2;
  --bs-info-bg-subtle: #dfdefc;
  --bs-warning-bg-subtle: #ffeecc;
  --bs-danger-bg-subtle: #fddde0;
  --bs-light-bg-subtle: #fafcfd;
  --bs-dark-bg-subtle: #b5b5c3;
  --bs-primary-border-subtle: #f39fd1;
  --bs-secondary-border-subtle: #f4f5f9;
  --bs-success-border-subtle: #a4e8e4;
  --bs-info-border-subtle: #bfbef9;
  --bs-warning-border-subtle: #ffdc99;
  --bs-danger-border-subtle: #fbbbc1;
  --bs-light-border-subtle: #eff2f5;
  --bs-dark-border-subtle: #a1a5b7;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: Poppins, Helvetica, "sans-serif";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size:1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #151521;
  --bs-body-color-rgb: 21, 21, 33;
  --bs-body-bg: #f5f8fa;
  --bs-body-bg-rgb: 245, 248, 250;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(21, 21, 33, 0.75);
  --bs-secondary-color-rgb: 21, 21, 33;
  --bs-secondary-bg: #eff2f5;
  --bs-secondary-bg-rgb: 239, 242, 245;
  --bs-tertiary-color: rgba(21, 21, 33, 0.5);
  --bs-tertiary-color-rgb: 21, 21, 33;
  --bs-tertiary-bg: #f5f8fa;
  --bs-tertiary-bg-rgb: 245, 248, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #e10e8d;
  --bs-link-color-rgb: 225, 14, 141;
  --bs-link-decoration: none;
  --bs-link-hover-color: #a90768;
  --bs-link-hover-color-rgb: 169, 7, 104;
  --bs-link-hover-decoration: underline;
  --bs-code-color: #e10e8d;
  --bs-highlight-color: #151521;
  --bs-highlight-bg: #ffeecc;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #eff2f5;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.475rem;
  --bs-border-radius-sm: 0.275rem;
  --bs-border-radius-lg: 0.775rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.05);
  --bs-box-shadow-sm: 0 0.4rem 1.25rem 0.4rem rgba(0, 0, 0, 0.035);
  --bs-box-shadow-lg: 0 1rem 2rem 1rem rgba(0, 0, 0, 0.1);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(225, 14, 141, 0.25);
  --bs-form-valid-color: #1BC5BC;
  --bs-form-valid-border-color: #1BC5BC;
  --bs-form-invalid-color: #F65464;
  --bs-form-invalid-border-color: #F65464;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #e4e6ef;
  --bs-body-color-rgb: 228, 230, 239;
  --bs-body-bg: #181c32;
  --bs-body-bg-rgb: 24, 28, 50;
  --bs-emphasis-color: #ffffff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(228, 230, 239, 0.75);
  --bs-secondary-color-rgb: 228, 230, 239;
  --bs-secondary-bg: #3f4254;
  --bs-secondary-bg-rgb: 63, 66, 84;
  --bs-tertiary-color: rgba(228, 230, 239, 0.5);
  --bs-tertiary-color-rgb: 228, 230, 239;
  --bs-tertiary-bg: #2c2f43;
  --bs-tertiary-bg-rgb: 44, 47, 67;
  --bs-primary-text-emphasis: #ed6ebb;
  --bs-secondary-text-emphasis: #eff0f5;
  --bs-success-text-emphasis: #76dcd7;
  --bs-info-text-emphasis: #9f9df7;
  --bs-warning-text-emphasis: #ffcb66;
  --bs-danger-text-emphasis: #fa98a2;
  --bs-light-text-emphasis: #f5f8fa;
  --bs-dark-text-emphasis: #e4e6ef;
  --bs-primary-bg-subtle: #2d031c;
  --bs-secondary-bg-subtle: #2e2e30;
  --bs-success-bg-subtle: #052726;
  --bs-info-bg-subtle: #131230;
  --bs-warning-bg-subtle: #332200;
  --bs-danger-bg-subtle: #311114;
  --bs-light-bg-subtle: #3f4254;
  --bs-dark-bg-subtle: #20212a;
  --bs-primary-border-subtle: #870855;
  --bs-secondary-border-subtle: #898a8f;
  --bs-success-border-subtle: #107671;
  --bs-info-border-subtle: #393791;
  --bs-warning-border-subtle: #996500;
  --bs-danger-border-subtle: #94323c;
  --bs-light-border-subtle: #5e6278;
  --bs-dark-border-subtle: #3f4254;
  --bs-heading-color: inherit;
  --bs-link-color: #ed6ebb;
  --bs-link-hover-color: #f18bc9;
  --bs-link-color-rgb: 237, 110, 187;
  --bs-link-hover-color-rgb: 241, 139, 201;
  --bs-code-color: #ed6ebb;
  --bs-highlight-color: #e4e6ef;
  --bs-highlight-bg: #664300;
  --bs-border-color: #5e6278;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #76dcd7;
  --bs-form-valid-border-color: #76dcd7;
  --bs-form-invalid-color: #fa98a2;
  --bs-form-invalid-border-color: #fa98a2;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 1.75rem;
  }
}

h2, .h2 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.5rem;
  }
}

h3, .h3 {
  font-size: calc(1.26rem + 0.12vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.35rem;
  }
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1.15rem;
}

h6, .h6 {
  font-size: 1.075rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 600;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.275rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #7e8299;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: var(--bs-box-shadow-sm);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.75rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.75rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.25rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.25rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 1.75rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 1.75rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 2rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 2rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 2.25rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 2.25rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 2.5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 2.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-9,
.gx-sm-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-sm-9,
.gy-sm-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 2.5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 2rem;
  }

  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 2rem;
  }

  .g-md-9,
.gx-md-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-md-9,
.gy-md-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 2.5rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 2.5rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 2.5rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: #151521;
  --bs-table-bg: transparent;
  --bs-table-border-color: #eff2f5;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #151521;
  --bs-table-striped-bg: rgba(245, 248, 250, 0.75);
  --bs-table-active-color: #151521;
  --bs-table-active-bg: #f5f8fa;
  --bs-table-hover-color: #151521;
  --bs-table-hover-bg: #f5f8fa;
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(1px * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000000;
  --bs-table-bg: #f9cfe8;
  --bs-table-border-color: #e0bad1;
  --bs-table-striped-bg: #edc5dc;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e0bad1;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e6bfd7;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000000;
  --bs-table-bg: #fafafc;
  --bs-table-border-color: #e1e1e3;
  --bs-table-striped-bg: #eeeeef;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e1e1e3;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e7e7e9;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000000;
  --bs-table-bg: #d1f3f2;
  --bs-table-border-color: #bcdbda;
  --bs-table-striped-bg: #c7e7e6;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #bcdbda;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #c1e1e0;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000000;
  --bs-table-bg: #dfdefc;
  --bs-table-border-color: #c9c8e3;
  --bs-table-striped-bg: #d4d3ef;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #c9c8e3;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #cecde9;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000000;
  --bs-table-bg: #ffeecc;
  --bs-table-border-color: #e6d6b8;
  --bs-table-striped-bg: #f2e2c2;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e6d6b8;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #ecdcbd;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000000;
  --bs-table-bg: #fddde0;
  --bs-table-border-color: #e4c7ca;
  --bs-table-striped-bg: #f0d2d5;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e4c7ca;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #eacccf;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000000;
  --bs-table-bg: #eff2f5;
  --bs-table-border-color: #d7dadd;
  --bs-table-striped-bg: #e3e6e9;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #d7dadd;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #dde0e3;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #ffffff;
  --bs-table-bg: #3f4254;
  --bs-table-border-color: #525565;
  --bs-table-striped-bg: #494b5d;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #525565;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #4d5061;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  font-size: 1.05rem;
  font-weight: 500;
}

.col-form-label {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.825rem + 1px);
  padding-bottom: calc(0.825rem + 1px);
  font-size: 1.15rem;
}

.col-form-label-sm {
  padding-top: calc(0.55rem + 1px);
  padding-bottom: calc(0.55rem + 1px);
  font-size: 0.925rem;
}

.form-text {
  margin-top: 0.5rem;
  font-size: 0.925rem;
  color: #a1a5b7;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #151521;
  appearance: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  border-radius: 0.475rem;
  box-shadow: var(--bs-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #151521;
  background-color: #ffffff;
  border-color: #b5b5c3;
  outline: 0;
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(225, 14, 141, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: #a1a5b7;
  opacity: 1;
}
.form-control:disabled {
  background-color: #eff2f5;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.75rem 1rem;
  margin: -0.75rem -1rem;
  margin-inline-end: 1rem;
  color: #151521;
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #151521;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 1.1rem + calc(1px * 2));
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  border-radius: 0.475rem;
}
.form-control-sm::file-selector-button {
  padding: 0.55rem 0.75rem;
  margin: -0.55rem -0.75rem;
  margin-inline-end: 0.75rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.65rem + calc(1px * 2));
  padding: 0.825rem 1.5rem;
  font-size: 1.15rem;
  border-radius: 0.475rem;
}
.form-control-lg::file-selector-button {
  padding: 0.825rem 1.5rem;
  margin: -0.825rem -1.5rem;
  margin-inline-end: 1.5rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.5rem + calc(1px * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 1.1rem + calc(1px * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.65rem + calc(1px * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 1.5rem + calc(1px * 2));
  padding: 0.75rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.475rem;
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 0.475rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 1.1rem + calc(1px * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1.65rem + calc(1px * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237e8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.75rem 3rem 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #151521;
  appearance: none;
  background-color: #ffffff;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid #e4e6ef;
  border-radius: 0.475rem;
  box-shadow: var(--bs-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #b5b5c3;
  outline: 0;
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(225, 14, 141, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #eff2f5;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #151521;
}

.form-select-sm {
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
  padding-left: 0.75rem;
  font-size: 0.925rem;
  border-radius: 0.475rem;
}

.form-select-lg {
  padding-top: 0.825rem;
  padding-bottom: 0.825rem;
  padding-left: 1.5rem;
  font-size: 1.15rem;
  border-radius: 0.475rem;
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23e4e6ef' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 2.35rem;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -2.35rem;
}

.form-check-reverse {
  padding-right: 2.35rem;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -2.35rem;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: #f5f8fa;
  flex-shrink: 0;
  width: 1.85rem;
  height: 1.85rem;
  margin-top: -0.175rem;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  print-color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}
.form-check-input[type=checkbox] {
  border-radius: 0.45em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #b5b5c3;
  outline: 0;
  box-shadow: none;
}
.form-check-input:checked {
  background-color: #e10e8d;
  border-color: #e10e8d;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 11' width='13' height='11' fill='none'%3e%3cpath d='M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #e10e8d;
  border-color: #e10e8d;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 3.75rem;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 3.25rem;
  margin-left: -3.75rem;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 3.25rem;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23b5b5c3'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 3.75rem;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -3.75rem;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.6;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f5f8fa, 0 0 0 0.25rem rgba(225, 14, 141, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f5f8fa, 0 0 0 0.25rem rgba(225, 14, 141, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #e10e8d;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #f6b7dd;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--bs-box-shadow-inset);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #e10e8d;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #f6b7dd;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--bs-box-shadow-inset);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(1px * 2));
  min-height: calc(3.5rem + calc(1px * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 1rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.5rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #ffffff;
  border-radius: 0.475rem;
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #7e8299;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: #eff2f5;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #151521;
  text-align: center;
  white-space: nowrap;
  background-color: #f5f8fa;
  border: 1px solid #e4e6ef;
  border-radius: 0.475rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.825rem 1.5rem;
  font-size: 1.15rem;
  border-radius: 0.475rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  border-radius: 0.475rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(1px * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.925rem;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.925rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: 3px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231BC5BC' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231BC5BC' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 5.5rem;
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 1.5rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.925rem;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.925rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: 3px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F65464'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F65464' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F65464'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F65464' stroke='none'/%3e%3c/svg%3e");
  padding-right: 5.5rem;
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 1.5rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.75rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size:1.1rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.475rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.6;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  text-decoration: none;
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
  box-shadow: var(--bs-btn-active-shadow);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
  box-shadow: none;
}

.btn-white {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #ffffff;
}

.btn-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #eff2f5;
  --bs-btn-border-color: #eff2f5;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #cbced0;
  --bs-btn-hover-border-color: #bfc2c4;
  --bs-btn-focus-shadow-rgb: 203, 206, 208;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #bfc2c4;
  --bs-btn-active-border-color: #b3b6b8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #eff2f5;
  --bs-btn-disabled-border-color: #eff2f5;
}

.btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #e10e8d;
  --bs-btn-border-color: #e10e8d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #bf0c78;
  --bs-btn-hover-border-color: #b40b71;
  --bs-btn-focus-shadow-rgb: 230, 50, 158;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #b40b71;
  --bs-btn-active-border-color: #a90b6a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #e10e8d;
  --bs-btn-disabled-border-color: #e10e8d;
}

.btn-secondary {
  --bs-btn-color: #000000;
  --bs-btn-bg: #e4e6ef;
  --bs-btn-border-color: #e4e6ef;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e8eaf1;
  --bs-btn-hover-border-color: #e7e9f1;
  --bs-btn-focus-shadow-rgb: 194, 196, 203;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e9ebf2;
  --bs-btn-active-border-color: #e7e9f1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #e4e6ef;
  --bs-btn-disabled-border-color: #e4e6ef;
}

.btn-success {
  --bs-btn-color: #000000;
  --bs-btn-bg: #1BC5BC;
  --bs-btn-border-color: #1BC5BC;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #3dcec6;
  --bs-btn-hover-border-color: #32cbc3;
  --bs-btn-focus-shadow-rgb: 23, 167, 160;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #49d1c9;
  --bs-btn-active-border-color: #32cbc3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #1BC5BC;
  --bs-btn-disabled-border-color: #1BC5BC;
}

.btn-info {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #5F5CF1;
  --bs-btn-border-color: #5F5CF1;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #514ecd;
  --bs-btn-hover-border-color: #4c4ac1;
  --bs-btn-focus-shadow-rgb: 119, 116, 243;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4c4ac1;
  --bs-btn-active-border-color: #4745b5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #5F5CF1;
  --bs-btn-disabled-border-color: #5F5CF1;
}

.btn-warning {
  --bs-btn-color: #000000;
  --bs-btn-bg: #FFA800;
  --bs-btn-border-color: #FFA800;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffb526;
  --bs-btn-hover-border-color: #ffb11a;
  --bs-btn-focus-shadow-rgb: 217, 143, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffb933;
  --bs-btn-active-border-color: #ffb11a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #FFA800;
  --bs-btn-disabled-border-color: #FFA800;
}

.btn-danger {
  --bs-btn-color: #000000;
  --bs-btn-bg: #F65464;
  --bs-btn-border-color: #F65464;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f76e7b;
  --bs-btn-hover-border-color: #f76574;
  --bs-btn-focus-shadow-rgb: 209, 71, 85;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f87683;
  --bs-btn-active-border-color: #f76574;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #F65464;
  --bs-btn-disabled-border-color: #F65464;
}

.btn-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #3f4254;
  --bs-btn-border-color: #3f4254;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #5c5e6e;
  --bs-btn-hover-border-color: #525565;
  --bs-btn-focus-shadow-rgb: 92, 94, 110;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #656876;
  --bs-btn-active-border-color: #525565;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #3f4254;
  --bs-btn-disabled-border-color: #3f4254;
}

.btn-black {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #000000;
  --bs-btn-disabled-border-color: #000000;
}

.btn-muted {
  --bs-btn-color: #000000;
  --bs-btn-bg: #a1a5b7;
  --bs-btn-border-color: #a1a5b7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #afb3c2;
  --bs-btn-hover-border-color: #aaaebe;
  --bs-btn-focus-shadow-rgb: 137, 140, 156;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #b4b7c5;
  --bs-btn-active-border-color: #aaaebe;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #a1a5b7;
  --bs-btn-disabled-border-color: #a1a5b7;
}

.btn-blue {
  --bs-btn-color: #000000;
  --bs-btn-bg: #3699FF;
  --bs-btn-border-color: #3699FF;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #54a8ff;
  --bs-btn-hover-border-color: #4aa3ff;
  --bs-btn-focus-shadow-rgb: 46, 130, 217;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #5eadff;
  --bs-btn-active-border-color: #4aa3ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #3699FF;
  --bs-btn-disabled-border-color: #3699FF;
}

.btn-indigo {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #6610f2;
  --bs-btn-border-color: #6610f2;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #570ece;
  --bs-btn-hover-border-color: #520dc2;
  --bs-btn-focus-shadow-rgb: 125, 52, 244;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #520dc2;
  --bs-btn-active-border-color: #4d0cb6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #6610f2;
  --bs-btn-disabled-border-color: #6610f2;
}

.btn-purple {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #5F5CF1;
  --bs-btn-border-color: #5F5CF1;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #514ecd;
  --bs-btn-hover-border-color: #4c4ac1;
  --bs-btn-focus-shadow-rgb: 119, 116, 243;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4c4ac1;
  --bs-btn-active-border-color: #4745b5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #5F5CF1;
  --bs-btn-disabled-border-color: #5F5CF1;
}

.btn-pink {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #d63384;
  --bs-btn-border-color: #d63384;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #b62b70;
  --bs-btn-hover-border-color: #ab296a;
  --bs-btn-focus-shadow-rgb: 220, 82, 150;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ab296a;
  --bs-btn-active-border-color: #a12663;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #d63384;
  --bs-btn-disabled-border-color: #d63384;
}

.btn-red {
  --bs-btn-color: #000000;
  --bs-btn-bg: #F65464;
  --bs-btn-border-color: #F65464;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f76e7b;
  --bs-btn-hover-border-color: #f76574;
  --bs-btn-focus-shadow-rgb: 209, 71, 85;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f87683;
  --bs-btn-active-border-color: #f76574;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #F65464;
  --bs-btn-disabled-border-color: #F65464;
}

.btn-orange {
  --bs-btn-color: #000000;
  --bs-btn-bg: #fd7e14;
  --bs-btn-border-color: #fd7e14;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #fd9137;
  --bs-btn-hover-border-color: #fd8b2c;
  --bs-btn-focus-shadow-rgb: 215, 107, 17;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #fd9843;
  --bs-btn-active-border-color: #fd8b2c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #fd7e14;
  --bs-btn-disabled-border-color: #fd7e14;
}

.btn-yellow {
  --bs-btn-color: #000000;
  --bs-btn-bg: #FFA800;
  --bs-btn-border-color: #FFA800;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffb526;
  --bs-btn-hover-border-color: #ffb11a;
  --bs-btn-focus-shadow-rgb: 217, 143, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffb933;
  --bs-btn-active-border-color: #ffb11a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #FFA800;
  --bs-btn-disabled-border-color: #FFA800;
}

.btn-green {
  --bs-btn-color: #000000;
  --bs-btn-bg: #1BC5BC;
  --bs-btn-border-color: #1BC5BC;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #3dcec6;
  --bs-btn-hover-border-color: #32cbc3;
  --bs-btn-focus-shadow-rgb: 23, 167, 160;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #49d1c9;
  --bs-btn-active-border-color: #32cbc3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #1BC5BC;
  --bs-btn-disabled-border-color: #1BC5BC;
}

.btn-teal {
  --bs-btn-color: #000000;
  --bs-btn-bg: #20c997;
  --bs-btn-border-color: #20c997;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #41d1a7;
  --bs-btn-hover-border-color: #36cea1;
  --bs-btn-focus-shadow-rgb: 27, 171, 128;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #4dd4ac;
  --bs-btn-active-border-color: #36cea1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #20c997;
  --bs-btn-disabled-border-color: #20c997;
}

.btn-cyan {
  --bs-btn-color: #000000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-gray {
  --bs-btn-color: #000000;
  --bs-btn-bg: #7e8299;
  --bs-btn-border-color: #7e8299;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #9195a8;
  --bs-btn-hover-border-color: #8b8fa3;
  --bs-btn-focus-shadow-rgb: 107, 111, 130;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #989bad;
  --bs-btn-active-border-color: #8b8fa3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #7e8299;
  --bs-btn-disabled-border-color: #7e8299;
}

.btn-body-bg {
  --bs-btn-color: #000000;
  --bs-btn-bg: #f5f8fa;
  --bs-btn-border-color: #f5f8fa;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f7f9fb;
  --bs-btn-hover-border-color: #f6f9fb;
  --bs-btn-focus-shadow-rgb: 208, 211, 213;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f7f9fb;
  --bs-btn-active-border-color: #f6f9fb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #f5f8fa;
  --bs-btn-disabled-border-color: #f5f8fa;
}

.btn-body-text {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #151521;
  --bs-btn-border-color: #151521;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #12121c;
  --bs-btn-hover-border-color: #11111a;
  --bs-btn-focus-shadow-rgb: 56, 56, 66;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #11111a;
  --bs-btn-active-border-color: #101019;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #151521;
  --bs-btn-disabled-border-color: #151521;
}

.btn-toolbar-bg {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #ffffff;
}

.btn-toolbar-text {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #1a1a27;
  --bs-btn-border-color: #1a1a27;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #161621;
  --bs-btn-hover-border-color: #15151f;
  --bs-btn-focus-shadow-rgb: 60, 60, 71;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #15151f;
  --bs-btn-active-border-color: #14141d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #1a1a27;
  --bs-btn-disabled-border-color: #1a1a27;
}

.btn-focus-bg {
  --bs-btn-color: #000000;
  --bs-btn-bg: #eef3f7;
  --bs-btn-border-color: #eef3f7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f1f5f8;
  --bs-btn-hover-border-color: #f0f4f8;
  --bs-btn-focus-shadow-rgb: 202, 207, 210;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f1f5f9;
  --bs-btn-active-border-color: #f0f4f8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #eef3f7;
  --bs-btn-disabled-border-color: #eef3f7;
}

.btn-tabbar-text {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #171723;
  --bs-btn-border-color: #171723;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #14141e;
  --bs-btn-hover-border-color: #12121c;
  --bs-btn-focus-shadow-rgb: 58, 58, 68;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #12121c;
  --bs-btn-active-border-color: #11111a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #171723;
  --bs-btn-disabled-border-color: #171723;
}

.btn-card-bg {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #ffffff;
}

.btn-card-text {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #1e1e2d;
  --bs-btn-border-color: #1e1e2d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1a1a26;
  --bs-btn-hover-border-color: #181824;
  --bs-btn-focus-shadow-rgb: 64, 64, 77;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #181824;
  --bs-btn-active-border-color: #171722;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #1e1e2d;
  --bs-btn-disabled-border-color: #1e1e2d;
}

.btn-item-bg {
  --bs-btn-color: #000000;
  --bs-btn-bg: #e4e6ef;
  --bs-btn-border-color: #e4e6ef;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e8eaf1;
  --bs-btn-hover-border-color: #e7e9f1;
  --bs-btn-focus-shadow-rgb: 194, 196, 203;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e9ebf2;
  --bs-btn-active-border-color: #e7e9f1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #e4e6ef;
  --bs-btn-disabled-border-color: #e4e6ef;
}

.btn-item-text {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #1b1b29;
  --bs-btn-border-color: #1b1b29;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #171723;
  --bs-btn-hover-border-color: #161621;
  --bs-btn-focus-shadow-rgb: 61, 61, 73;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #161621;
  --bs-btn-active-border-color: #14141f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #1b1b29;
  --bs-btn-disabled-border-color: #1b1b29;
}

.btn-outline-white {
  --bs-btn-color: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffffff;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #eff2f5;
  --bs-btn-border-color: #eff2f5;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #eff2f5;
  --bs-btn-hover-border-color: #eff2f5;
  --bs-btn-focus-shadow-rgb: 239, 242, 245;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #eff2f5;
  --bs-btn-active-border-color: #eff2f5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #eff2f5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #eff2f5;
  --bs-gradient: none;
}

.btn-outline-primary {
  --bs-btn-color: #e10e8d;
  --bs-btn-border-color: #e10e8d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #e10e8d;
  --bs-btn-hover-border-color: #e10e8d;
  --bs-btn-focus-shadow-rgb: 225, 14, 141;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #e10e8d;
  --bs-btn-active-border-color: #e10e8d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e10e8d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e10e8d;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #e4e6ef;
  --bs-btn-border-color: #e4e6ef;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e4e6ef;
  --bs-btn-hover-border-color: #e4e6ef;
  --bs-btn-focus-shadow-rgb: 228, 230, 239;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e4e6ef;
  --bs-btn-active-border-color: #e4e6ef;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e4e6ef;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e4e6ef;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #1BC5BC;
  --bs-btn-border-color: #1BC5BC;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #1BC5BC;
  --bs-btn-hover-border-color: #1BC5BC;
  --bs-btn-focus-shadow-rgb: 27, 197, 188;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #1BC5BC;
  --bs-btn-active-border-color: #1BC5BC;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1BC5BC;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1BC5BC;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #5F5CF1;
  --bs-btn-border-color: #5F5CF1;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #5F5CF1;
  --bs-btn-hover-border-color: #5F5CF1;
  --bs-btn-focus-shadow-rgb: 95, 92, 241;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #5F5CF1;
  --bs-btn-active-border-color: #5F5CF1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5F5CF1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5F5CF1;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #FFA800;
  --bs-btn-border-color: #FFA800;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #FFA800;
  --bs-btn-hover-border-color: #FFA800;
  --bs-btn-focus-shadow-rgb: 255, 168, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #FFA800;
  --bs-btn-active-border-color: #FFA800;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFA800;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFA800;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #F65464;
  --bs-btn-border-color: #F65464;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #F65464;
  --bs-btn-hover-border-color: #F65464;
  --bs-btn-focus-shadow-rgb: 246, 84, 100;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #F65464;
  --bs-btn-active-border-color: #F65464;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F65464;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F65464;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #3f4254;
  --bs-btn-border-color: #3f4254;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #3f4254;
  --bs-btn-hover-border-color: #3f4254;
  --bs-btn-focus-shadow-rgb: 63, 66, 84;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3f4254;
  --bs-btn-active-border-color: #3f4254;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3f4254;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3f4254;
  --bs-gradient: none;
}

.btn-outline-black {
  --bs-btn-color: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #000000;
  --bs-btn-hover-border-color: #000000;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #000000;
  --bs-btn-active-border-color: #000000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #000000;
  --bs-gradient: none;
}

.btn-outline-muted {
  --bs-btn-color: #a1a5b7;
  --bs-btn-border-color: #a1a5b7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #a1a5b7;
  --bs-btn-hover-border-color: #a1a5b7;
  --bs-btn-focus-shadow-rgb: 161, 165, 183;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #a1a5b7;
  --bs-btn-active-border-color: #a1a5b7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #a1a5b7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #a1a5b7;
  --bs-gradient: none;
}

.btn-outline-blue {
  --bs-btn-color: #3699FF;
  --bs-btn-border-color: #3699FF;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #3699FF;
  --bs-btn-hover-border-color: #3699FF;
  --bs-btn-focus-shadow-rgb: 54, 153, 255;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #3699FF;
  --bs-btn-active-border-color: #3699FF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3699FF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3699FF;
  --bs-gradient: none;
}

.btn-outline-indigo {
  --bs-btn-color: #6610f2;
  --bs-btn-border-color: #6610f2;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #6610f2;
  --bs-btn-hover-border-color: #6610f2;
  --bs-btn-focus-shadow-rgb: 102, 16, 242;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #6610f2;
  --bs-btn-active-border-color: #6610f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6610f2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6610f2;
  --bs-gradient: none;
}

.btn-outline-purple {
  --bs-btn-color: #5F5CF1;
  --bs-btn-border-color: #5F5CF1;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #5F5CF1;
  --bs-btn-hover-border-color: #5F5CF1;
  --bs-btn-focus-shadow-rgb: 95, 92, 241;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #5F5CF1;
  --bs-btn-active-border-color: #5F5CF1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5F5CF1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5F5CF1;
  --bs-gradient: none;
}

.btn-outline-pink {
  --bs-btn-color: #d63384;
  --bs-btn-border-color: #d63384;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d63384;
  --bs-btn-hover-border-color: #d63384;
  --bs-btn-focus-shadow-rgb: 214, 51, 132;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #d63384;
  --bs-btn-active-border-color: #d63384;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d63384;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d63384;
  --bs-gradient: none;
}

.btn-outline-red {
  --bs-btn-color: #F65464;
  --bs-btn-border-color: #F65464;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #F65464;
  --bs-btn-hover-border-color: #F65464;
  --bs-btn-focus-shadow-rgb: 246, 84, 100;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #F65464;
  --bs-btn-active-border-color: #F65464;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F65464;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F65464;
  --bs-gradient: none;
}

.btn-outline-orange {
  --bs-btn-color: #fd7e14;
  --bs-btn-border-color: #fd7e14;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #fd7e14;
  --bs-btn-hover-border-color: #fd7e14;
  --bs-btn-focus-shadow-rgb: 253, 126, 20;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #fd7e14;
  --bs-btn-active-border-color: #fd7e14;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fd7e14;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fd7e14;
  --bs-gradient: none;
}

.btn-outline-yellow {
  --bs-btn-color: #FFA800;
  --bs-btn-border-color: #FFA800;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #FFA800;
  --bs-btn-hover-border-color: #FFA800;
  --bs-btn-focus-shadow-rgb: 255, 168, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #FFA800;
  --bs-btn-active-border-color: #FFA800;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFA800;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFA800;
  --bs-gradient: none;
}

.btn-outline-green {
  --bs-btn-color: #1BC5BC;
  --bs-btn-border-color: #1BC5BC;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #1BC5BC;
  --bs-btn-hover-border-color: #1BC5BC;
  --bs-btn-focus-shadow-rgb: 27, 197, 188;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #1BC5BC;
  --bs-btn-active-border-color: #1BC5BC;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1BC5BC;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1BC5BC;
  --bs-gradient: none;
}

.btn-outline-teal {
  --bs-btn-color: #20c997;
  --bs-btn-border-color: #20c997;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #20c997;
  --bs-btn-hover-border-color: #20c997;
  --bs-btn-focus-shadow-rgb: 32, 201, 151;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #20c997;
  --bs-btn-active-border-color: #20c997;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #20c997;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #20c997;
  --bs-gradient: none;
}

.btn-outline-cyan {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-gray {
  --bs-btn-color: #7e8299;
  --bs-btn-border-color: #7e8299;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #7e8299;
  --bs-btn-hover-border-color: #7e8299;
  --bs-btn-focus-shadow-rgb: 126, 130, 153;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #7e8299;
  --bs-btn-active-border-color: #7e8299;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #7e8299;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #7e8299;
  --bs-gradient: none;
}

.btn-outline-body-bg {
  --bs-btn-color: #f5f8fa;
  --bs-btn-border-color: #f5f8fa;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f5f8fa;
  --bs-btn-hover-border-color: #f5f8fa;
  --bs-btn-focus-shadow-rgb: 245, 248, 250;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f5f8fa;
  --bs-btn-active-border-color: #f5f8fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f5f8fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f5f8fa;
  --bs-gradient: none;
}

.btn-outline-body-text {
  --bs-btn-color: #151521;
  --bs-btn-border-color: #151521;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #151521;
  --bs-btn-hover-border-color: #151521;
  --bs-btn-focus-shadow-rgb: 21, 21, 33;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #151521;
  --bs-btn-active-border-color: #151521;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #151521;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #151521;
  --bs-gradient: none;
}

.btn-outline-toolbar-bg {
  --bs-btn-color: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffffff;
  --bs-gradient: none;
}

.btn-outline-toolbar-text {
  --bs-btn-color: #1a1a27;
  --bs-btn-border-color: #1a1a27;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1a1a27;
  --bs-btn-hover-border-color: #1a1a27;
  --bs-btn-focus-shadow-rgb: 26, 26, 39;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1a1a27;
  --bs-btn-active-border-color: #1a1a27;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1a1a27;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1a1a27;
  --bs-gradient: none;
}

.btn-outline-focus-bg {
  --bs-btn-color: #eef3f7;
  --bs-btn-border-color: #eef3f7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #eef3f7;
  --bs-btn-hover-border-color: #eef3f7;
  --bs-btn-focus-shadow-rgb: 238, 243, 247;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #eef3f7;
  --bs-btn-active-border-color: #eef3f7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #eef3f7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #eef3f7;
  --bs-gradient: none;
}

.btn-outline-tabbar-text {
  --bs-btn-color: #171723;
  --bs-btn-border-color: #171723;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #171723;
  --bs-btn-hover-border-color: #171723;
  --bs-btn-focus-shadow-rgb: 23, 23, 35;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #171723;
  --bs-btn-active-border-color: #171723;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #171723;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #171723;
  --bs-gradient: none;
}

.btn-outline-card-bg {
  --bs-btn-color: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffffff;
  --bs-gradient: none;
}

.btn-outline-card-text {
  --bs-btn-color: #1e1e2d;
  --bs-btn-border-color: #1e1e2d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1e1e2d;
  --bs-btn-hover-border-color: #1e1e2d;
  --bs-btn-focus-shadow-rgb: 30, 30, 45;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1e1e2d;
  --bs-btn-active-border-color: #1e1e2d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1e1e2d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1e1e2d;
  --bs-gradient: none;
}

.btn-outline-item-bg {
  --bs-btn-color: #e4e6ef;
  --bs-btn-border-color: #e4e6ef;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e4e6ef;
  --bs-btn-hover-border-color: #e4e6ef;
  --bs-btn-focus-shadow-rgb: 228, 230, 239;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e4e6ef;
  --bs-btn-active-border-color: #e4e6ef;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e4e6ef;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e4e6ef;
  --bs-gradient: none;
}

.btn-outline-item-text {
  --bs-btn-color: #1b1b29;
  --bs-btn-border-color: #1b1b29;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1b1b29;
  --bs-btn-hover-border-color: #1b1b29;
  --bs-btn-focus-shadow-rgb: 27, 27, 41;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1b1b29;
  --bs-btn-active-border-color: #1b1b29;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1b1b29;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1b1b29;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #e10e8d;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #a90768;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #a90768;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #7e8299;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 230, 50, 158;
  text-decoration: none;
}
.btn-link:hover, .btn-link:focus-visible {
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.825rem;
  --bs-btn-padding-x: 1.75rem;
  --bs-btn-font-size:1.15rem;
  --bs-btn-border-radius: 0.475rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.55rem;
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-font-size:0.925rem;
  --bs-btn-border-radius: 0.475rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size:1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.475rem;
  --bs-dropdown-border-width: 0;
  --bs-dropdown-inner-border-radius: calc(0.475rem - 0);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #e10e8d;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #7e8299;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  box-shadow: var(--bs-dropdown-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.925rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #e4e6ef;
  --bs-dropdown-bg: #3f4254;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #e4e6ef;
  --bs-dropdown-link-hover-color: #ffffff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #e10e8d;
  --bs-dropdown-link-disabled-color: #a1a5b7;
  --bs-dropdown-header-color: #a1a5b7;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.475rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(1px * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(1px * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
  text-decoration: none;
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(225, 14, 141, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #eff2f5;
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) #eff2f5;
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #e10e8d;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 500;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.44375rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.075rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.075rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2821, 21, 33, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: 0.475rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
  text-decoration: none;
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  box-shadow: none;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: #eff2f5;
  --bs-card-border-radius: 0.475rem;
  --bs-card-box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  --bs-card-inner-border-radius: calc(0.475rem - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #ffffff;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: #1e1e2d;
  --bs-card-bg: #ffffff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  box-shadow: var(--bs-card-box-shadow);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: #eff2f5;
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.5rem;
  --bs-accordion-btn-padding-y: 1.5rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23a1a5b7'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.15rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 1.5rem;
  --bs-accordion-body-padding-y: 1.5rem;
  --bs-accordion-active-color: #e10e8d;
  --bs-accordion-active-bg: #f5f8fa;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ed6ebb'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ed6ebb'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #7e8299;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #e10e8d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size:1.075rem;
  --bs-pagination-color: #5e6278;
  --bs-pagination-bg: transparent;
  --bs-pagination-border-width: 0;
  --bs-pagination-border-color: transparent;
  --bs-pagination-border-radius: 0.475rem;
  --bs-pagination-hover-color: #e10e8d;
  --bs-pagination-hover-bg: #fff1fe;
  --bs-pagination-hover-border-color: transparent;
  --bs-pagination-focus-color: #e10e8d;
  --bs-pagination-focus-bg: #fff1fe;
  --bs-pagination-focus-box-shadow: none;
  --bs-pagination-active-color: #ffffff;
  --bs-pagination-active-bg: #e10e8d;
  --bs-pagination-active-border-color: transparent;
  --bs-pagination-disabled-color: #b5b5c3;
  --bs-pagination-disabled-bg: transparent;
  --bs-pagination-disabled-border-color: transparent;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  text-decoration: none;
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(0 * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size:1.075rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size:0.925rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.85em;
  --bs-badge-padding-y: 0.5em;
  --bs-badge-font-size:0.85em;
  --bs-badge-font-weight: 500;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: 0.475rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 500;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-white {
  --bs-alert-color: var(--bs-white-text-emphasis);
  --bs-alert-bg: var(--bs-white-bg-subtle);
  --bs-alert-border-color: var(--bs-white-border-subtle);
  --bs-alert-link-color: var(--bs-white-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

.alert-black {
  --bs-alert-color: var(--bs-black-text-emphasis);
  --bs-alert-bg: var(--bs-black-bg-subtle);
  --bs-alert-border-color: var(--bs-black-border-subtle);
  --bs-alert-link-color: var(--bs-black-text-emphasis);
}

.alert-muted {
  --bs-alert-color: var(--bs-muted-text-emphasis);
  --bs-alert-bg: var(--bs-muted-bg-subtle);
  --bs-alert-border-color: var(--bs-muted-border-subtle);
  --bs-alert-link-color: var(--bs-muted-text-emphasis);
}

.alert-blue {
  --bs-alert-color: var(--bs-blue-text-emphasis);
  --bs-alert-bg: var(--bs-blue-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-border-subtle);
  --bs-alert-link-color: var(--bs-blue-text-emphasis);
}

.alert-indigo {
  --bs-alert-color: var(--bs-indigo-text-emphasis);
  --bs-alert-bg: var(--bs-indigo-bg-subtle);
  --bs-alert-border-color: var(--bs-indigo-border-subtle);
  --bs-alert-link-color: var(--bs-indigo-text-emphasis);
}

.alert-purple {
  --bs-alert-color: var(--bs-purple-text-emphasis);
  --bs-alert-bg: var(--bs-purple-bg-subtle);
  --bs-alert-border-color: var(--bs-purple-border-subtle);
  --bs-alert-link-color: var(--bs-purple-text-emphasis);
}

.alert-pink {
  --bs-alert-color: var(--bs-pink-text-emphasis);
  --bs-alert-bg: var(--bs-pink-bg-subtle);
  --bs-alert-border-color: var(--bs-pink-border-subtle);
  --bs-alert-link-color: var(--bs-pink-text-emphasis);
}

.alert-red {
  --bs-alert-color: var(--bs-red-text-emphasis);
  --bs-alert-bg: var(--bs-red-bg-subtle);
  --bs-alert-border-color: var(--bs-red-border-subtle);
  --bs-alert-link-color: var(--bs-red-text-emphasis);
}

.alert-orange {
  --bs-alert-color: var(--bs-orange-text-emphasis);
  --bs-alert-bg: var(--bs-orange-bg-subtle);
  --bs-alert-border-color: var(--bs-orange-border-subtle);
  --bs-alert-link-color: var(--bs-orange-text-emphasis);
}

.alert-yellow {
  --bs-alert-color: var(--bs-yellow-text-emphasis);
  --bs-alert-bg: var(--bs-yellow-bg-subtle);
  --bs-alert-border-color: var(--bs-yellow-border-subtle);
  --bs-alert-link-color: var(--bs-yellow-text-emphasis);
}

.alert-green {
  --bs-alert-color: var(--bs-green-text-emphasis);
  --bs-alert-bg: var(--bs-green-bg-subtle);
  --bs-alert-border-color: var(--bs-green-border-subtle);
  --bs-alert-link-color: var(--bs-green-text-emphasis);
}

.alert-teal {
  --bs-alert-color: var(--bs-teal-text-emphasis);
  --bs-alert-bg: var(--bs-teal-bg-subtle);
  --bs-alert-border-color: var(--bs-teal-border-subtle);
  --bs-alert-link-color: var(--bs-teal-text-emphasis);
}

.alert-cyan {
  --bs-alert-color: var(--bs-cyan-text-emphasis);
  --bs-alert-bg: var(--bs-cyan-bg-subtle);
  --bs-alert-border-color: var(--bs-cyan-border-subtle);
  --bs-alert-link-color: var(--bs-cyan-text-emphasis);
}

.alert-gray {
  --bs-alert-color: var(--bs-gray-text-emphasis);
  --bs-alert-bg: var(--bs-gray-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-border-subtle);
  --bs-alert-link-color: var(--bs-gray-text-emphasis);
}

.alert-body-bg {
  --bs-alert-color: var(--bs-body-bg-text-emphasis);
  --bs-alert-bg: var(--bs-body-bg-bg-subtle);
  --bs-alert-border-color: var(--bs-body-bg-border-subtle);
  --bs-alert-link-color: var(--bs-body-bg-text-emphasis);
}

.alert-body-text {
  --bs-alert-color: var(--bs-body-text-text-emphasis);
  --bs-alert-bg: var(--bs-body-text-bg-subtle);
  --bs-alert-border-color: var(--bs-body-text-border-subtle);
  --bs-alert-link-color: var(--bs-body-text-text-emphasis);
}

.alert-toolbar-bg {
  --bs-alert-color: var(--bs-toolbar-bg-text-emphasis);
  --bs-alert-bg: var(--bs-toolbar-bg-bg-subtle);
  --bs-alert-border-color: var(--bs-toolbar-bg-border-subtle);
  --bs-alert-link-color: var(--bs-toolbar-bg-text-emphasis);
}

.alert-toolbar-text {
  --bs-alert-color: var(--bs-toolbar-text-text-emphasis);
  --bs-alert-bg: var(--bs-toolbar-text-bg-subtle);
  --bs-alert-border-color: var(--bs-toolbar-text-border-subtle);
  --bs-alert-link-color: var(--bs-toolbar-text-text-emphasis);
}

.alert-focus-bg {
  --bs-alert-color: var(--bs-focus-bg-text-emphasis);
  --bs-alert-bg: var(--bs-focus-bg-bg-subtle);
  --bs-alert-border-color: var(--bs-focus-bg-border-subtle);
  --bs-alert-link-color: var(--bs-focus-bg-text-emphasis);
}

.alert-tabbar-text {
  --bs-alert-color: var(--bs-tabbar-text-text-emphasis);
  --bs-alert-bg: var(--bs-tabbar-text-bg-subtle);
  --bs-alert-border-color: var(--bs-tabbar-text-border-subtle);
  --bs-alert-link-color: var(--bs-tabbar-text-text-emphasis);
}

.alert-card-bg {
  --bs-alert-color: var(--bs-card-bg-text-emphasis);
  --bs-alert-bg: var(--bs-card-bg-bg-subtle);
  --bs-alert-border-color: var(--bs-card-bg-border-subtle);
  --bs-alert-link-color: var(--bs-card-bg-text-emphasis);
}

.alert-card-text {
  --bs-alert-color: var(--bs-card-text-text-emphasis);
  --bs-alert-bg: var(--bs-card-text-bg-subtle);
  --bs-alert-border-color: var(--bs-card-text-border-subtle);
  --bs-alert-link-color: var(--bs-card-text-text-emphasis);
}

.alert-item-bg {
  --bs-alert-color: var(--bs-item-bg-text-emphasis);
  --bs-alert-bg: var(--bs-item-bg-bg-subtle);
  --bs-alert-border-color: var(--bs-item-bg-border-subtle);
  --bs-alert-link-color: var(--bs-item-bg-text-emphasis);
}

.alert-item-text {
  --bs-alert-color: var(--bs-item-text-text-emphasis);
  --bs-alert-bg: var(--bs-item-text-bg-subtle);
  --bs-alert-border-color: var(--bs-item-text-border-subtle);
  --bs-alert-link-color: var(--bs-item-text-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size:0.75rem;
  --bs-progress-bg: #f5f8fa;
  --bs-progress-border-radius: 6px;
  --bs-progress-box-shadow: none;
  --bs-progress-bar-color: #ffffff;
  --bs-progress-bar-bg: #e10e8d;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  box-shadow: var(--bs-progress-box-shadow);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #ffffff;
  --bs-list-group-active-bg: #e10e8d;
  --bs-list-group-active-border-color: #e10e8d;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-white {
  --bs-list-group-color: var(--bs-white-text-emphasis);
  --bs-list-group-bg: var(--bs-white-bg-subtle);
  --bs-list-group-border-color: var(--bs-white-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-white-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-white-border-subtle);
  --bs-list-group-active-color: var(--bs-white-bg-subtle);
  --bs-list-group-active-bg: var(--bs-white-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-white-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.list-group-item-black {
  --bs-list-group-color: var(--bs-black-text-emphasis);
  --bs-list-group-bg: var(--bs-black-bg-subtle);
  --bs-list-group-border-color: var(--bs-black-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-black-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-black-border-subtle);
  --bs-list-group-active-color: var(--bs-black-bg-subtle);
  --bs-list-group-active-bg: var(--bs-black-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-black-text-emphasis);
}

.list-group-item-muted {
  --bs-list-group-color: var(--bs-muted-text-emphasis);
  --bs-list-group-bg: var(--bs-muted-bg-subtle);
  --bs-list-group-border-color: var(--bs-muted-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-muted-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-muted-border-subtle);
  --bs-list-group-active-color: var(--bs-muted-bg-subtle);
  --bs-list-group-active-bg: var(--bs-muted-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-muted-text-emphasis);
}

.list-group-item-blue {
  --bs-list-group-color: var(--bs-blue-text-emphasis);
  --bs-list-group-bg: var(--bs-blue-bg-subtle);
  --bs-list-group-border-color: var(--bs-blue-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-blue-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-blue-border-subtle);
  --bs-list-group-active-color: var(--bs-blue-bg-subtle);
  --bs-list-group-active-bg: var(--bs-blue-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-blue-text-emphasis);
}

.list-group-item-indigo {
  --bs-list-group-color: var(--bs-indigo-text-emphasis);
  --bs-list-group-bg: var(--bs-indigo-bg-subtle);
  --bs-list-group-border-color: var(--bs-indigo-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-indigo-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-indigo-border-subtle);
  --bs-list-group-active-color: var(--bs-indigo-bg-subtle);
  --bs-list-group-active-bg: var(--bs-indigo-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-indigo-text-emphasis);
}

.list-group-item-purple {
  --bs-list-group-color: var(--bs-purple-text-emphasis);
  --bs-list-group-bg: var(--bs-purple-bg-subtle);
  --bs-list-group-border-color: var(--bs-purple-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-purple-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-purple-border-subtle);
  --bs-list-group-active-color: var(--bs-purple-bg-subtle);
  --bs-list-group-active-bg: var(--bs-purple-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-purple-text-emphasis);
}

.list-group-item-pink {
  --bs-list-group-color: var(--bs-pink-text-emphasis);
  --bs-list-group-bg: var(--bs-pink-bg-subtle);
  --bs-list-group-border-color: var(--bs-pink-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-pink-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-pink-border-subtle);
  --bs-list-group-active-color: var(--bs-pink-bg-subtle);
  --bs-list-group-active-bg: var(--bs-pink-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-pink-text-emphasis);
}

.list-group-item-red {
  --bs-list-group-color: var(--bs-red-text-emphasis);
  --bs-list-group-bg: var(--bs-red-bg-subtle);
  --bs-list-group-border-color: var(--bs-red-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-red-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-red-border-subtle);
  --bs-list-group-active-color: var(--bs-red-bg-subtle);
  --bs-list-group-active-bg: var(--bs-red-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-red-text-emphasis);
}

.list-group-item-orange {
  --bs-list-group-color: var(--bs-orange-text-emphasis);
  --bs-list-group-bg: var(--bs-orange-bg-subtle);
  --bs-list-group-border-color: var(--bs-orange-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-orange-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-orange-border-subtle);
  --bs-list-group-active-color: var(--bs-orange-bg-subtle);
  --bs-list-group-active-bg: var(--bs-orange-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-orange-text-emphasis);
}

.list-group-item-yellow {
  --bs-list-group-color: var(--bs-yellow-text-emphasis);
  --bs-list-group-bg: var(--bs-yellow-bg-subtle);
  --bs-list-group-border-color: var(--bs-yellow-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-yellow-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-yellow-border-subtle);
  --bs-list-group-active-color: var(--bs-yellow-bg-subtle);
  --bs-list-group-active-bg: var(--bs-yellow-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-yellow-text-emphasis);
}

.list-group-item-green {
  --bs-list-group-color: var(--bs-green-text-emphasis);
  --bs-list-group-bg: var(--bs-green-bg-subtle);
  --bs-list-group-border-color: var(--bs-green-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-green-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-green-border-subtle);
  --bs-list-group-active-color: var(--bs-green-bg-subtle);
  --bs-list-group-active-bg: var(--bs-green-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-green-text-emphasis);
}

.list-group-item-teal {
  --bs-list-group-color: var(--bs-teal-text-emphasis);
  --bs-list-group-bg: var(--bs-teal-bg-subtle);
  --bs-list-group-border-color: var(--bs-teal-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-teal-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-teal-border-subtle);
  --bs-list-group-active-color: var(--bs-teal-bg-subtle);
  --bs-list-group-active-bg: var(--bs-teal-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-teal-text-emphasis);
}

.list-group-item-cyan {
  --bs-list-group-color: var(--bs-cyan-text-emphasis);
  --bs-list-group-bg: var(--bs-cyan-bg-subtle);
  --bs-list-group-border-color: var(--bs-cyan-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-cyan-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-cyan-border-subtle);
  --bs-list-group-active-color: var(--bs-cyan-bg-subtle);
  --bs-list-group-active-bg: var(--bs-cyan-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-cyan-text-emphasis);
}

.list-group-item-gray {
  --bs-list-group-color: var(--bs-gray-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-text-emphasis);
}

.list-group-item-body-bg {
  --bs-list-group-color: var(--bs-body-bg-text-emphasis);
  --bs-list-group-bg: var(--bs-body-bg-bg-subtle);
  --bs-list-group-border-color: var(--bs-body-bg-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-body-bg-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-body-bg-border-subtle);
  --bs-list-group-active-color: var(--bs-body-bg-bg-subtle);
  --bs-list-group-active-bg: var(--bs-body-bg-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-body-bg-text-emphasis);
}

.list-group-item-body-text {
  --bs-list-group-color: var(--bs-body-text-text-emphasis);
  --bs-list-group-bg: var(--bs-body-text-bg-subtle);
  --bs-list-group-border-color: var(--bs-body-text-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-body-text-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-body-text-border-subtle);
  --bs-list-group-active-color: var(--bs-body-text-bg-subtle);
  --bs-list-group-active-bg: var(--bs-body-text-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-body-text-text-emphasis);
}

.list-group-item-toolbar-bg {
  --bs-list-group-color: var(--bs-toolbar-bg-text-emphasis);
  --bs-list-group-bg: var(--bs-toolbar-bg-bg-subtle);
  --bs-list-group-border-color: var(--bs-toolbar-bg-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-toolbar-bg-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-toolbar-bg-border-subtle);
  --bs-list-group-active-color: var(--bs-toolbar-bg-bg-subtle);
  --bs-list-group-active-bg: var(--bs-toolbar-bg-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-toolbar-bg-text-emphasis);
}

.list-group-item-toolbar-text {
  --bs-list-group-color: var(--bs-toolbar-text-text-emphasis);
  --bs-list-group-bg: var(--bs-toolbar-text-bg-subtle);
  --bs-list-group-border-color: var(--bs-toolbar-text-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-toolbar-text-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-toolbar-text-border-subtle);
  --bs-list-group-active-color: var(--bs-toolbar-text-bg-subtle);
  --bs-list-group-active-bg: var(--bs-toolbar-text-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-toolbar-text-text-emphasis);
}

.list-group-item-focus-bg {
  --bs-list-group-color: var(--bs-focus-bg-text-emphasis);
  --bs-list-group-bg: var(--bs-focus-bg-bg-subtle);
  --bs-list-group-border-color: var(--bs-focus-bg-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-focus-bg-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-focus-bg-border-subtle);
  --bs-list-group-active-color: var(--bs-focus-bg-bg-subtle);
  --bs-list-group-active-bg: var(--bs-focus-bg-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-focus-bg-text-emphasis);
}

.list-group-item-tabbar-text {
  --bs-list-group-color: var(--bs-tabbar-text-text-emphasis);
  --bs-list-group-bg: var(--bs-tabbar-text-bg-subtle);
  --bs-list-group-border-color: var(--bs-tabbar-text-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tabbar-text-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tabbar-text-border-subtle);
  --bs-list-group-active-color: var(--bs-tabbar-text-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tabbar-text-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tabbar-text-text-emphasis);
}

.list-group-item-card-bg {
  --bs-list-group-color: var(--bs-card-bg-text-emphasis);
  --bs-list-group-bg: var(--bs-card-bg-bg-subtle);
  --bs-list-group-border-color: var(--bs-card-bg-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-card-bg-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-card-bg-border-subtle);
  --bs-list-group-active-color: var(--bs-card-bg-bg-subtle);
  --bs-list-group-active-bg: var(--bs-card-bg-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-card-bg-text-emphasis);
}

.list-group-item-card-text {
  --bs-list-group-color: var(--bs-card-text-text-emphasis);
  --bs-list-group-bg: var(--bs-card-text-bg-subtle);
  --bs-list-group-border-color: var(--bs-card-text-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-card-text-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-card-text-border-subtle);
  --bs-list-group-active-color: var(--bs-card-text-bg-subtle);
  --bs-list-group-active-bg: var(--bs-card-text-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-card-text-text-emphasis);
}

.list-group-item-item-bg {
  --bs-list-group-color: var(--bs-item-bg-text-emphasis);
  --bs-list-group-bg: var(--bs-item-bg-bg-subtle);
  --bs-list-group-border-color: var(--bs-item-bg-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-item-bg-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-item-bg-border-subtle);
  --bs-list-group-active-color: var(--bs-item-bg-bg-subtle);
  --bs-list-group-active-bg: var(--bs-item-bg-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-item-bg-text-emphasis);
}

.list-group-item-item-text {
  --bs-list-group-color: var(--bs-item-text-text-emphasis);
  --bs-list-group-bg: var(--bs-item-text-bg-subtle);
  --bs-list-group-border-color: var(--bs-item-text-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-item-text-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-item-text-border-subtle);
  --bs-list-group-active-color: var(--bs-item-text-bg-subtle);
  --bs-list-group-active-bg: var(--bs-item-text-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-item-text-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(225, 14, 141, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.475rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size:0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1.75rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #ffffff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 0;
  --bs-modal-border-radius: 0.475rem;
  --bs-modal-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  --bs-modal-inner-border-radius: 0.475rem;
  --bs-modal-header-padding-x: 1.75rem;
  --bs-modal-header-padding-y: 1.75rem;
  --bs-modal-header-padding: 1.75rem 1.75rem;
  --bs-modal-header-border-color: #eff2f5;
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: #eff2f5;
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  box-shadow: var(--bs-modal-box-shadow);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000000;
  --bs-backdrop-opacity: 0.2;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 1rem;
  --bs-tooltip-padding-y: 0.75rem;
  --bs-tooltip-margin: 0;
  --bs-tooltip-font-size:0.925rem;
  --bs-tooltip-color: #3f4254;
  --bs-tooltip-bg: #ffffff;
  --bs-tooltip-border-radius: 3px;
  --bs-tooltip-opacity: 1;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size:0.925rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: #ffffff;
  --bs-popover-border-radius: 0.475rem;
  --bs-popover-inner-border-radius: calc(0.475rem - var(--bs-border-width));
  --bs-popover-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1.25rem;
  --bs-popover-header-padding-y: 1rem;
  --bs-popover-header-font-size:1rem;
  --bs-popover-header-color: #3f4254;
  --bs-popover-header-bg: #ffffff;
  --bs-popover-body-padding-x: 1.25rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #151521;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  box-shadow: var(--bs-popover-box-shadow);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
.carousel-dark .carousel-caption {
  color: #000000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.185em;
  --bs-spinner-animation-speed: 0.65s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.145em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.65s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.3s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1.75rem;
  --bs-offcanvas-padding-y: 1.75rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: 0;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--bs-offcanvas-box-shadow);
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.2;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin: calc(-.5 * var(--bs-offcanvas-padding-y)) calc(-.5 * var(--bs-offcanvas-padding-x)) calc(-.5 * var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000000 55%, rgba(0, 0, 0, 0.8) 75%, #000000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-white {
  color: #000000 !important;
  background-color: RGBA(var(--bs-white-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000000 !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000000 !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000000 !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-black {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-black-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-muted {
  color: #000000 !important;
  background-color: RGBA(var(--bs-muted-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue {
  color: #000000 !important;
  background-color: RGBA(var(--bs-blue-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-indigo {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-indigo-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-purple-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-pink-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red {
  color: #000000 !important;
  background-color: RGBA(var(--bs-red-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-orange {
  color: #000000 !important;
  background-color: RGBA(var(--bs-orange-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-yellow {
  color: #000000 !important;
  background-color: RGBA(var(--bs-yellow-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green {
  color: #000000 !important;
  background-color: RGBA(var(--bs-green-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-teal {
  color: #000000 !important;
  background-color: RGBA(var(--bs-teal-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-cyan {
  color: #000000 !important;
  background-color: RGBA(var(--bs-cyan-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray {
  color: #000000 !important;
  background-color: RGBA(var(--bs-gray-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-body-bg {
  color: #000000 !important;
  background-color: RGBA(var(--bs-body-bg-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-body-text {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-body-text-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-toolbar-bg {
  color: #000000 !important;
  background-color: RGBA(var(--bs-toolbar-bg-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-toolbar-text {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-toolbar-text-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-focus-bg {
  color: #000000 !important;
  background-color: RGBA(var(--bs-focus-bg-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-tabbar-text {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-tabbar-text-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-card-bg {
  color: #000000 !important;
  background-color: RGBA(var(--bs-card-bg-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-card-text {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-card-text-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-item-bg {
  color: #000000 !important;
  background-color: RGBA(var(--bs-item-bg-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-item-text {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-item-text-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-white {
  color: RGBA(var(--bs-white-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-white-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-white:hover, .link-white:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(242, 245, 247, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(242, 245, 247, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(180, 11, 113, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(180, 11, 113, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(233, 235, 242, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(233, 235, 242, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(73, 209, 201, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(73, 209, 201, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(76, 74, 193, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(76, 74, 193, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 185, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 185, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(248, 118, 131, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(248, 118, 131, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(50, 53, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(50, 53, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-black {
  color: RGBA(var(--bs-black-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-black-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-black:hover, .link-black:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-muted {
  color: RGBA(var(--bs-muted-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-muted-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-muted:hover, .link-muted:focus {
  color: RGBA(180, 183, 197, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(180, 183, 197, var(--bs-link-underline-opacity, 1)) !important;
}

.link-blue {
  color: RGBA(var(--bs-blue-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-blue-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-blue:hover, .link-blue:focus {
  color: RGBA(94, 173, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(94, 173, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-indigo {
  color: RGBA(var(--bs-indigo-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-indigo-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-indigo:hover, .link-indigo:focus {
  color: RGBA(82, 13, 194, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(82, 13, 194, var(--bs-link-underline-opacity, 1)) !important;
}

.link-purple {
  color: RGBA(var(--bs-purple-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-purple-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-purple:hover, .link-purple:focus {
  color: RGBA(76, 74, 193, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(76, 74, 193, var(--bs-link-underline-opacity, 1)) !important;
}

.link-pink {
  color: RGBA(var(--bs-pink-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-pink-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-pink:hover, .link-pink:focus {
  color: RGBA(171, 41, 106, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(171, 41, 106, var(--bs-link-underline-opacity, 1)) !important;
}

.link-red {
  color: RGBA(var(--bs-red-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-red-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-red:hover, .link-red:focus {
  color: RGBA(248, 118, 131, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(248, 118, 131, var(--bs-link-underline-opacity, 1)) !important;
}

.link-orange {
  color: RGBA(var(--bs-orange-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-orange-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-orange:hover, .link-orange:focus {
  color: RGBA(253, 152, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(253, 152, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-yellow {
  color: RGBA(var(--bs-yellow-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-yellow-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-yellow:hover, .link-yellow:focus {
  color: RGBA(255, 185, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 185, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-green {
  color: RGBA(var(--bs-green-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-green-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-green:hover, .link-green:focus {
  color: RGBA(73, 209, 201, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(73, 209, 201, var(--bs-link-underline-opacity, 1)) !important;
}

.link-teal {
  color: RGBA(var(--bs-teal-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-teal-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-teal:hover, .link-teal:focus {
  color: RGBA(77, 212, 172, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(77, 212, 172, var(--bs-link-underline-opacity, 1)) !important;
}

.link-cyan {
  color: RGBA(var(--bs-cyan-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-cyan-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-cyan:hover, .link-cyan:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray {
  color: RGBA(var(--bs-gray-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray:hover, .link-gray:focus {
  color: RGBA(152, 155, 173, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(152, 155, 173, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-bg {
  color: RGBA(var(--bs-body-bg-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-body-bg-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-bg:hover, .link-body-bg:focus {
  color: RGBA(247, 249, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(247, 249, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-text {
  color: RGBA(var(--bs-body-text-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-body-text-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-text:hover, .link-body-text:focus {
  color: RGBA(17, 17, 26, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(17, 17, 26, var(--bs-link-underline-opacity, 1)) !important;
}

.link-toolbar-bg {
  color: RGBA(var(--bs-toolbar-bg-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-toolbar-bg-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-toolbar-bg:hover, .link-toolbar-bg:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-toolbar-text {
  color: RGBA(var(--bs-toolbar-text-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-toolbar-text-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-toolbar-text:hover, .link-toolbar-text:focus {
  color: RGBA(21, 21, 31, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(21, 21, 31, var(--bs-link-underline-opacity, 1)) !important;
}

.link-focus-bg {
  color: RGBA(var(--bs-focus-bg-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-focus-bg-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-focus-bg:hover, .link-focus-bg:focus {
  color: RGBA(241, 245, 249, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(241, 245, 249, var(--bs-link-underline-opacity, 1)) !important;
}

.link-tabbar-text {
  color: RGBA(var(--bs-tabbar-text-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tabbar-text-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-tabbar-text:hover, .link-tabbar-text:focus {
  color: RGBA(18, 18, 28, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(18, 18, 28, var(--bs-link-underline-opacity, 1)) !important;
}

.link-card-bg {
  color: RGBA(var(--bs-card-bg-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-card-bg-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-card-bg:hover, .link-card-bg:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-card-text {
  color: RGBA(var(--bs-card-text-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-card-text-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-card-text:hover, .link-card-text:focus {
  color: RGBA(24, 24, 36, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(24, 24, 36, var(--bs-link-underline-opacity, 1)) !important;
}

.link-item-bg {
  color: RGBA(var(--bs-item-bg-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-item-bg-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-item-bg:hover, .link-item-bg:focus {
  color: RGBA(233, 235, 242, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(233, 235, 242, var(--bs-link-underline-opacity, 1)) !important;
}

.link-item-text {
  color: RGBA(var(--bs-item-text-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-item-text-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-item-text:hover, .link-item-text:focus {
  color: RGBA(22, 22, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(22, 22, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-white {
  --bs-focus-ring-color: rgba(var(--bs-white-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-black {
  --bs-focus-ring-color: rgba(var(--bs-black-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-muted {
  --bs-focus-ring-color: rgba(var(--bs-muted-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-blue {
  --bs-focus-ring-color: rgba(var(--bs-blue-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-indigo {
  --bs-focus-ring-color: rgba(var(--bs-indigo-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-purple {
  --bs-focus-ring-color: rgba(var(--bs-purple-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-pink {
  --bs-focus-ring-color: rgba(var(--bs-pink-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-red {
  --bs-focus-ring-color: rgba(var(--bs-red-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-orange {
  --bs-focus-ring-color: rgba(var(--bs-orange-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-yellow {
  --bs-focus-ring-color: rgba(var(--bs-yellow-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-green {
  --bs-focus-ring-color: rgba(var(--bs-green-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-teal {
  --bs-focus-ring-color: rgba(var(--bs-teal-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-cyan {
  --bs-focus-ring-color: rgba(var(--bs-cyan-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray {
  --bs-focus-ring-color: rgba(var(--bs-gray-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-body-bg {
  --bs-focus-ring-color: rgba(var(--bs-body-bg-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-body-text {
  --bs-focus-ring-color: rgba(var(--bs-body-text-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-toolbar-bg {
  --bs-focus-ring-color: rgba(var(--bs-toolbar-bg-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-toolbar-text {
  --bs-focus-ring-color: rgba(var(--bs-toolbar-text-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-focus-bg {
  --bs-focus-ring-color: rgba(var(--bs-focus-bg-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-tabbar-text {
  --bs-focus-ring-color: rgba(var(--bs-tabbar-text-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-card-bg {
  --bs-focus-ring-color: rgba(var(--bs-card-bg-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-card-text {
  --bs-focus-ring-color: rgba(var(--bs-card-text-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-item-bg {
  --bs-focus-ring-color: rgba(var(--bs-item-bg-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-item-text {
  --bs-focus-ring-color: rgba(var(--bs-item-text-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-25 {
  top: 25% !important;
}

.top-50 {
  top: 50% !important;
}

.top-75 {
  top: 75% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-25 {
  bottom: 25% !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-75 {
  bottom: 75% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-25 {
  left: 25% !important;
}

.start-50 {
  left: 50% !important;
}

.start-75 {
  left: 75% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-25 {
  right: 25% !important;
}

.end-50 {
  right: 50% !important;
}

.end-75 {
  right: 75% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-muted {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-muted-rgb), var(--bs-border-opacity)) !important;
}

.border-blue {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-rgb), var(--bs-border-opacity)) !important;
}

.border-indigo {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-indigo-rgb), var(--bs-border-opacity)) !important;
}

.border-purple {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-rgb), var(--bs-border-opacity)) !important;
}

.border-pink {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-rgb), var(--bs-border-opacity)) !important;
}

.border-red {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-rgb), var(--bs-border-opacity)) !important;
}

.border-orange {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-rgb), var(--bs-border-opacity)) !important;
}

.border-green {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-rgb), var(--bs-border-opacity)) !important;
}

.border-teal {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-rgb), var(--bs-border-opacity)) !important;
}

.border-cyan {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-cyan-rgb), var(--bs-border-opacity)) !important;
}

.border-gray {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-rgb), var(--bs-border-opacity)) !important;
}

.border-body-bg {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-body-bg-rgb), var(--bs-border-opacity)) !important;
}

.border-body-text {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-body-text-rgb), var(--bs-border-opacity)) !important;
}

.border-toolbar-bg {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-toolbar-bg-rgb), var(--bs-border-opacity)) !important;
}

.border-toolbar-text {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-toolbar-text-rgb), var(--bs-border-opacity)) !important;
}

.border-focus-bg {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-focus-bg-rgb), var(--bs-border-opacity)) !important;
}

.border-tabbar-text {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tabbar-text-rgb), var(--bs-border-opacity)) !important;
}

.border-card-bg {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-card-bg-rgb), var(--bs-border-opacity)) !important;
}

.border-card-text {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-card-text-rgb), var(--bs-border-opacity)) !important;
}

.border-item-bg {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-item-bg-rgb), var(--bs-border-opacity)) !important;
}

.border-item-text {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-item-text-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-11 {
  margin: 2.75rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.m-13 {
  margin: 3.25rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.m-15 {
  margin: 3.75rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.m-17 {
  margin: 4.25rem !important;
}

.m-18 {
  margin: 4.5rem !important;
}

.m-19 {
  margin: 4.75rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-4 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-5 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-6 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-7 {
  margin-right: 1.75rem !important;
  margin-left: 1.75rem !important;
}

.mx-8 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-9 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important;
}

.mx-10 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-11 {
  margin-right: 2.75rem !important;
  margin-left: 2.75rem !important;
}

.mx-12 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-13 {
  margin-right: 3.25rem !important;
  margin-left: 3.25rem !important;
}

.mx-14 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-15 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important;
}

.mx-16 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-17 {
  margin-right: 4.25rem !important;
  margin-left: 4.25rem !important;
}

.mx-18 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-19 {
  margin-right: 4.75rem !important;
  margin-left: 4.75rem !important;
}

.mx-20 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-9 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-11 {
  margin-top: 2.75rem !important;
  margin-bottom: 2.75rem !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-13 {
  margin-top: 3.25rem !important;
  margin-bottom: 3.25rem !important;
}

.my-14 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-15 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-17 {
  margin-top: 4.25rem !important;
  margin-bottom: 4.25rem !important;
}

.my-18 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-19 {
  margin-top: 4.75rem !important;
  margin-bottom: 4.75rem !important;
}

.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-9 {
  margin-top: 2.25rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-11 {
  margin-top: 2.75rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mt-13 {
  margin-top: 3.25rem !important;
}

.mt-14 {
  margin-top: 3.5rem !important;
}

.mt-15 {
  margin-top: 3.75rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mt-17 {
  margin-top: 4.25rem !important;
}

.mt-18 {
  margin-top: 4.5rem !important;
}

.mt-19 {
  margin-top: 4.75rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 0.75rem !important;
}

.me-4 {
  margin-right: 1rem !important;
}

.me-5 {
  margin-right: 1.25rem !important;
}

.me-6 {
  margin-right: 1.5rem !important;
}

.me-7 {
  margin-right: 1.75rem !important;
}

.me-8 {
  margin-right: 2rem !important;
}

.me-9 {
  margin-right: 2.25rem !important;
}

.me-10 {
  margin-right: 2.5rem !important;
}

.me-11 {
  margin-right: 2.75rem !important;
}

.me-12 {
  margin-right: 3rem !important;
}

.me-13 {
  margin-right: 3.25rem !important;
}

.me-14 {
  margin-right: 3.5rem !important;
}

.me-15 {
  margin-right: 3.75rem !important;
}

.me-16 {
  margin-right: 4rem !important;
}

.me-17 {
  margin-right: 4.25rem !important;
}

.me-18 {
  margin-right: 4.5rem !important;
}

.me-19 {
  margin-right: 4.75rem !important;
}

.me-20 {
  margin-right: 5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mb-9 {
  margin-bottom: 2.25rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-11 {
  margin-bottom: 2.75rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.mb-13 {
  margin-bottom: 3.25rem !important;
}

.mb-14 {
  margin-bottom: 3.5rem !important;
}

.mb-15 {
  margin-bottom: 3.75rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.mb-17 {
  margin-bottom: 4.25rem !important;
}

.mb-18 {
  margin-bottom: 4.5rem !important;
}

.mb-19 {
  margin-bottom: 4.75rem !important;
}

.mb-20 {
  margin-bottom: 5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 0.75rem !important;
}

.ms-4 {
  margin-left: 1rem !important;
}

.ms-5 {
  margin-left: 1.25rem !important;
}

.ms-6 {
  margin-left: 1.5rem !important;
}

.ms-7 {
  margin-left: 1.75rem !important;
}

.ms-8 {
  margin-left: 2rem !important;
}

.ms-9 {
  margin-left: 2.25rem !important;
}

.ms-10 {
  margin-left: 2.5rem !important;
}

.ms-11 {
  margin-left: 2.75rem !important;
}

.ms-12 {
  margin-left: 3rem !important;
}

.ms-13 {
  margin-left: 3.25rem !important;
}

.ms-14 {
  margin-left: 3.5rem !important;
}

.ms-15 {
  margin-left: 3.75rem !important;
}

.ms-16 {
  margin-left: 4rem !important;
}

.ms-17 {
  margin-left: 4.25rem !important;
}

.ms-18 {
  margin-left: 4.5rem !important;
}

.ms-19 {
  margin-left: 4.75rem !important;
}

.ms-20 {
  margin-left: 5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.m-n5 {
  margin: -1.25rem !important;
}

.m-n6 {
  margin: -1.5rem !important;
}

.m-n7 {
  margin: -1.75rem !important;
}

.m-n8 {
  margin: -2rem !important;
}

.m-n9 {
  margin: -2.25rem !important;
}

.m-n10 {
  margin: -2.5rem !important;
}

.m-n11 {
  margin: -2.75rem !important;
}

.m-n12 {
  margin: -3rem !important;
}

.m-n13 {
  margin: -3.25rem !important;
}

.m-n14 {
  margin: -3.5rem !important;
}

.m-n15 {
  margin: -3.75rem !important;
}

.m-n16 {
  margin: -4rem !important;
}

.m-n17 {
  margin: -4.25rem !important;
}

.m-n18 {
  margin: -4.5rem !important;
}

.m-n19 {
  margin: -4.75rem !important;
}

.m-n20 {
  margin: -5rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}

.mx-n4 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n5 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n6 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n7 {
  margin-right: -1.75rem !important;
  margin-left: -1.75rem !important;
}

.mx-n8 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n9 {
  margin-right: -2.25rem !important;
  margin-left: -2.25rem !important;
}

.mx-n10 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n11 {
  margin-right: -2.75rem !important;
  margin-left: -2.75rem !important;
}

.mx-n12 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n13 {
  margin-right: -3.25rem !important;
  margin-left: -3.25rem !important;
}

.mx-n14 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n15 {
  margin-right: -3.75rem !important;
  margin-left: -3.75rem !important;
}

.mx-n16 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n17 {
  margin-right: -4.25rem !important;
  margin-left: -4.25rem !important;
}

.mx-n18 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.mx-n19 {
  margin-right: -4.75rem !important;
  margin-left: -4.75rem !important;
}

.mx-n20 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.my-n4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n7 {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.my-n8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n9 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.my-n10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n11 {
  margin-top: -2.75rem !important;
  margin-bottom: -2.75rem !important;
}

.my-n12 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n13 {
  margin-top: -3.25rem !important;
  margin-bottom: -3.25rem !important;
}

.my-n14 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n15 {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important;
}

.my-n16 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n17 {
  margin-top: -4.25rem !important;
  margin-bottom: -4.25rem !important;
}

.my-n18 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.my-n19 {
  margin-top: -4.75rem !important;
  margin-bottom: -4.75rem !important;
}

.my-n20 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -0.75rem !important;
}

.mt-n4 {
  margin-top: -1rem !important;
}

.mt-n5 {
  margin-top: -1.25rem !important;
}

.mt-n6 {
  margin-top: -1.5rem !important;
}

.mt-n7 {
  margin-top: -1.75rem !important;
}

.mt-n8 {
  margin-top: -2rem !important;
}

.mt-n9 {
  margin-top: -2.25rem !important;
}

.mt-n10 {
  margin-top: -2.5rem !important;
}

.mt-n11 {
  margin-top: -2.75rem !important;
}

.mt-n12 {
  margin-top: -3rem !important;
}

.mt-n13 {
  margin-top: -3.25rem !important;
}

.mt-n14 {
  margin-top: -3.5rem !important;
}

.mt-n15 {
  margin-top: -3.75rem !important;
}

.mt-n16 {
  margin-top: -4rem !important;
}

.mt-n17 {
  margin-top: -4.25rem !important;
}

.mt-n18 {
  margin-top: -4.5rem !important;
}

.mt-n19 {
  margin-top: -4.75rem !important;
}

.mt-n20 {
  margin-top: -5rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -0.75rem !important;
}

.me-n4 {
  margin-right: -1rem !important;
}

.me-n5 {
  margin-right: -1.25rem !important;
}

.me-n6 {
  margin-right: -1.5rem !important;
}

.me-n7 {
  margin-right: -1.75rem !important;
}

.me-n8 {
  margin-right: -2rem !important;
}

.me-n9 {
  margin-right: -2.25rem !important;
}

.me-n10 {
  margin-right: -2.5rem !important;
}

.me-n11 {
  margin-right: -2.75rem !important;
}

.me-n12 {
  margin-right: -3rem !important;
}

.me-n13 {
  margin-right: -3.25rem !important;
}

.me-n14 {
  margin-right: -3.5rem !important;
}

.me-n15 {
  margin-right: -3.75rem !important;
}

.me-n16 {
  margin-right: -4rem !important;
}

.me-n17 {
  margin-right: -4.25rem !important;
}

.me-n18 {
  margin-right: -4.5rem !important;
}

.me-n19 {
  margin-right: -4.75rem !important;
}

.me-n20 {
  margin-right: -5rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -0.75rem !important;
}

.mb-n4 {
  margin-bottom: -1rem !important;
}

.mb-n5 {
  margin-bottom: -1.25rem !important;
}

.mb-n6 {
  margin-bottom: -1.5rem !important;
}

.mb-n7 {
  margin-bottom: -1.75rem !important;
}

.mb-n8 {
  margin-bottom: -2rem !important;
}

.mb-n9 {
  margin-bottom: -2.25rem !important;
}

.mb-n10 {
  margin-bottom: -2.5rem !important;
}

.mb-n11 {
  margin-bottom: -2.75rem !important;
}

.mb-n12 {
  margin-bottom: -3rem !important;
}

.mb-n13 {
  margin-bottom: -3.25rem !important;
}

.mb-n14 {
  margin-bottom: -3.5rem !important;
}

.mb-n15 {
  margin-bottom: -3.75rem !important;
}

.mb-n16 {
  margin-bottom: -4rem !important;
}

.mb-n17 {
  margin-bottom: -4.25rem !important;
}

.mb-n18 {
  margin-bottom: -4.5rem !important;
}

.mb-n19 {
  margin-bottom: -4.75rem !important;
}

.mb-n20 {
  margin-bottom: -5rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -0.75rem !important;
}

.ms-n4 {
  margin-left: -1rem !important;
}

.ms-n5 {
  margin-left: -1.25rem !important;
}

.ms-n6 {
  margin-left: -1.5rem !important;
}

.ms-n7 {
  margin-left: -1.75rem !important;
}

.ms-n8 {
  margin-left: -2rem !important;
}

.ms-n9 {
  margin-left: -2.25rem !important;
}

.ms-n10 {
  margin-left: -2.5rem !important;
}

.ms-n11 {
  margin-left: -2.75rem !important;
}

.ms-n12 {
  margin-left: -3rem !important;
}

.ms-n13 {
  margin-left: -3.25rem !important;
}

.ms-n14 {
  margin-left: -3.5rem !important;
}

.ms-n15 {
  margin-left: -3.75rem !important;
}

.ms-n16 {
  margin-left: -4rem !important;
}

.ms-n17 {
  margin-left: -4.25rem !important;
}

.ms-n18 {
  margin-left: -4.5rem !important;
}

.ms-n19 {
  margin-left: -4.75rem !important;
}

.ms-n20 {
  margin-left: -5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-11 {
  padding: 2.75rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.p-13 {
  padding: 3.25rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.p-15 {
  padding: 3.75rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.p-17 {
  padding: 4.25rem !important;
}

.p-18 {
  padding: 4.5rem !important;
}

.p-19 {
  padding: 4.75rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-5 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-6 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-7 {
  padding-right: 1.75rem !important;
  padding-left: 1.75rem !important;
}

.px-8 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-9 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}

.px-10 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-11 {
  padding-right: 2.75rem !important;
  padding-left: 2.75rem !important;
}

.px-12 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-13 {
  padding-right: 3.25rem !important;
  padding-left: 3.25rem !important;
}

.px-14 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-15 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important;
}

.px-16 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-17 {
  padding-right: 4.25rem !important;
  padding-left: 4.25rem !important;
}

.px-18 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-19 {
  padding-right: 4.75rem !important;
  padding-left: 4.75rem !important;
}

.px-20 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-9 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-11 {
  padding-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-13 {
  padding-top: 3.25rem !important;
  padding-bottom: 3.25rem !important;
}

.py-14 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-15 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-17 {
  padding-top: 4.25rem !important;
  padding-bottom: 4.25rem !important;
}

.py-18 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-19 {
  padding-top: 4.75rem !important;
  padding-bottom: 4.75rem !important;
}

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-7 {
  padding-top: 1.75rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-9 {
  padding-top: 2.25rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pt-11 {
  padding-top: 2.75rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pt-13 {
  padding-top: 3.25rem !important;
}

.pt-14 {
  padding-top: 3.5rem !important;
}

.pt-15 {
  padding-top: 3.75rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pt-17 {
  padding-top: 4.25rem !important;
}

.pt-18 {
  padding-top: 4.5rem !important;
}

.pt-19 {
  padding-top: 4.75rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 0.75rem !important;
}

.pe-4 {
  padding-right: 1rem !important;
}

.pe-5 {
  padding-right: 1.25rem !important;
}

.pe-6 {
  padding-right: 1.5rem !important;
}

.pe-7 {
  padding-right: 1.75rem !important;
}

.pe-8 {
  padding-right: 2rem !important;
}

.pe-9 {
  padding-right: 2.25rem !important;
}

.pe-10 {
  padding-right: 2.5rem !important;
}

.pe-11 {
  padding-right: 2.75rem !important;
}

.pe-12 {
  padding-right: 3rem !important;
}

.pe-13 {
  padding-right: 3.25rem !important;
}

.pe-14 {
  padding-right: 3.5rem !important;
}

.pe-15 {
  padding-right: 3.75rem !important;
}

.pe-16 {
  padding-right: 4rem !important;
}

.pe-17 {
  padding-right: 4.25rem !important;
}

.pe-18 {
  padding-right: 4.5rem !important;
}

.pe-19 {
  padding-right: 4.75rem !important;
}

.pe-20 {
  padding-right: 5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-7 {
  padding-bottom: 1.75rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pb-9 {
  padding-bottom: 2.25rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pb-11 {
  padding-bottom: 2.75rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pb-13 {
  padding-bottom: 3.25rem !important;
}

.pb-14 {
  padding-bottom: 3.5rem !important;
}

.pb-15 {
  padding-bottom: 3.75rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pb-17 {
  padding-bottom: 4.25rem !important;
}

.pb-18 {
  padding-bottom: 4.5rem !important;
}

.pb-19 {
  padding-bottom: 4.75rem !important;
}

.pb-20 {
  padding-bottom: 5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 0.75rem !important;
}

.ps-4 {
  padding-left: 1rem !important;
}

.ps-5 {
  padding-left: 1.25rem !important;
}

.ps-6 {
  padding-left: 1.5rem !important;
}

.ps-7 {
  padding-left: 1.75rem !important;
}

.ps-8 {
  padding-left: 2rem !important;
}

.ps-9 {
  padding-left: 2.25rem !important;
}

.ps-10 {
  padding-left: 2.5rem !important;
}

.ps-11 {
  padding-left: 2.75rem !important;
}

.ps-12 {
  padding-left: 3rem !important;
}

.ps-13 {
  padding-left: 3.25rem !important;
}

.ps-14 {
  padding-left: 3.5rem !important;
}

.ps-15 {
  padding-left: 3.75rem !important;
}

.ps-16 {
  padding-left: 4rem !important;
}

.ps-17 {
  padding-left: 4.25rem !important;
}

.ps-18 {
  padding-left: 4.5rem !important;
}

.ps-19 {
  padding-left: 4.75rem !important;
}

.ps-20 {
  padding-left: 5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.gap-9 {
  gap: 2.25rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.gap-11 {
  gap: 2.75rem !important;
}

.gap-12 {
  gap: 3rem !important;
}

.gap-13 {
  gap: 3.25rem !important;
}

.gap-14 {
  gap: 3.5rem !important;
}

.gap-15 {
  gap: 3.75rem !important;
}

.gap-16 {
  gap: 4rem !important;
}

.gap-17 {
  gap: 4.25rem !important;
}

.gap-18 {
  gap: 4.5rem !important;
}

.gap-19 {
  gap: 4.75rem !important;
}

.gap-20 {
  gap: 5rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 0.75rem !important;
}

.row-gap-4 {
  row-gap: 1rem !important;
}

.row-gap-5 {
  row-gap: 1.25rem !important;
}

.row-gap-6 {
  row-gap: 1.5rem !important;
}

.row-gap-7 {
  row-gap: 1.75rem !important;
}

.row-gap-8 {
  row-gap: 2rem !important;
}

.row-gap-9 {
  row-gap: 2.25rem !important;
}

.row-gap-10 {
  row-gap: 2.5rem !important;
}

.row-gap-11 {
  row-gap: 2.75rem !important;
}

.row-gap-12 {
  row-gap: 3rem !important;
}

.row-gap-13 {
  row-gap: 3.25rem !important;
}

.row-gap-14 {
  row-gap: 3.5rem !important;
}

.row-gap-15 {
  row-gap: 3.75rem !important;
}

.row-gap-16 {
  row-gap: 4rem !important;
}

.row-gap-17 {
  row-gap: 4.25rem !important;
}

.row-gap-18 {
  row-gap: 4.5rem !important;
}

.row-gap-19 {
  row-gap: 4.75rem !important;
}

.row-gap-20 {
  row-gap: 5rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 0.75rem !important;
}

.column-gap-4 {
  column-gap: 1rem !important;
}

.column-gap-5 {
  column-gap: 1.25rem !important;
}

.column-gap-6 {
  column-gap: 1.5rem !important;
}

.column-gap-7 {
  column-gap: 1.75rem !important;
}

.column-gap-8 {
  column-gap: 2rem !important;
}

.column-gap-9 {
  column-gap: 2.25rem !important;
}

.column-gap-10 {
  column-gap: 2.5rem !important;
}

.column-gap-11 {
  column-gap: 2.75rem !important;
}

.column-gap-12 {
  column-gap: 3rem !important;
}

.column-gap-13 {
  column-gap: 3.25rem !important;
}

.column-gap-14 {
  column-gap: 3.5rem !important;
}

.column-gap-15 {
  column-gap: 3.75rem !important;
}

.column-gap-16 {
  column-gap: 4rem !important;
}

.column-gap-17 {
  column-gap: 4.25rem !important;
}

.column-gap-18 {
  column-gap: 4.5rem !important;
}

.column-gap-19 {
  column-gap: 4.75rem !important;
}

.column-gap-20 {
  column-gap: 5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-2 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-3 {
  font-size: calc(1.26rem + 0.12vw) !important;
}

.fs-4 {
  font-size: 1.25rem !important;
}

.fs-5 {
  font-size: 1.15rem !important;
}

.fs-6 {
  font-size: 1.075rem !important;
}

.fs-7 {
  font-size: 0.95rem !important;
}

.fs-8 {
  font-size: 0.85rem !important;
}

.fs-9 {
  font-size: 0.75rem !important;
}

.fs-10 {
  font-size: 0.5rem !important;
}

.fs-base {
  font-size: 1rem !important;
}

.fs-fluid {
  font-size: 100% !important;
}

.fs-2x {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-2qx {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2hx {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2tx {
  font-size: calc(1.4rem + 1.8vw) !important;
}

.fs-3x {
  font-size: calc(1.425rem + 2.1vw) !important;
}

.fs-3qx {
  font-size: calc(1.45rem + 2.4vw) !important;
}

.fs-3hx {
  font-size: calc(1.475rem + 2.7vw) !important;
}

.fs-3tx {
  font-size: calc(1.5rem + 3vw) !important;
}

.fs-4x {
  font-size: calc(1.525rem + 3.3vw) !important;
}

.fs-4qx {
  font-size: calc(1.55rem + 3.6vw) !important;
}

.fs-4hx {
  font-size: calc(1.575rem + 3.9vw) !important;
}

.fs-4tx {
  font-size: calc(1.6rem + 4.2vw) !important;
}

.fs-5x {
  font-size: calc(1.625rem + 4.5vw) !important;
}

.fs-5qx {
  font-size: calc(1.65rem + 4.8vw) !important;
}

.fs-5hx {
  font-size: calc(1.675rem + 5.1vw) !important;
}

.fs-5tx {
  font-size: calc(1.7rem + 5.4vw) !important;
}

.fs-10x {
  font-size: calc(2.125rem + 10.5vw) !important;
}

.fs-10qx {
  font-size: calc(2.15rem + 10.8vw) !important;
}

.fs-10hx {
  font-size: calc(2.175rem + 11.1vw) !important;
}

.fs-10tx {
  font-size: calc(2.2rem + 11.4vw) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 500 !important;
}

.fw-bolder {
  font-weight: 600 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 1.75 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-blue {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-rgb), var(--bs-text-opacity)) !important;
}

.text-indigo {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-rgb), var(--bs-text-opacity)) !important;
}

.text-purple {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-rgb), var(--bs-text-opacity)) !important;
}

.text-pink {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;
}

.text-red {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-rgb), var(--bs-text-opacity)) !important;
}

.text-orange {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-rgb), var(--bs-text-opacity)) !important;
}

.text-green {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-rgb), var(--bs-text-opacity)) !important;
}

.text-teal {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-rgb), var(--bs-text-opacity)) !important;
}

.text-gray {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity)) !important;
}

.text-body-bg {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-bg-rgb), var(--bs-text-opacity)) !important;
}

.text-body-text {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-text-rgb), var(--bs-text-opacity)) !important;
}

.text-toolbar-bg {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-toolbar-bg-rgb), var(--bs-text-opacity)) !important;
}

.text-toolbar-text {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-toolbar-text-rgb), var(--bs-text-opacity)) !important;
}

.text-focus-bg {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-focus-bg-rgb), var(--bs-text-opacity)) !important;
}

.text-tabbar-text {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tabbar-text-rgb), var(--bs-text-opacity)) !important;
}

.text-card-bg {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-card-bg-rgb), var(--bs-text-opacity)) !important;
}

.text-card-text {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-card-text-rgb), var(--bs-text-opacity)) !important;
}

.text-item-bg {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-item-bg-rgb), var(--bs-text-opacity)) !important;
}

.text-item-text {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-item-text-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-white {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-white-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-black {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-black-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-muted {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-muted-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-blue {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-blue-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-indigo {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-indigo-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-purple {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-purple-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-pink {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-pink-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-red {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-red-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-orange {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-orange-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-yellow {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-yellow-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-green {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-green-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-teal {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-teal-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-cyan {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-cyan-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-body-bg {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-body-bg-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-body-text {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-body-text-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-toolbar-bg {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-toolbar-bg-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-toolbar-text {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-toolbar-text-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-focus-bg {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-focus-bg-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-tabbar-text {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tabbar-text-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-card-bg {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-card-bg-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-card-text {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-card-text-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-item-bg {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-item-bg-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-item-text {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-item-text-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-muted {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-muted-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-bg {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-text {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-text-rgb), var(--bs-bg-opacity)) !important;
}

.bg-toolbar-bg {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-toolbar-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-toolbar-text {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-toolbar-text-rgb), var(--bs-bg-opacity)) !important;
}

.bg-focus-bg {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-focus-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tabbar-text {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tabbar-text-rgb), var(--bs-bg-opacity)) !important;
}

.bg-card-bg {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-card-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-card-text {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-card-text-rgb), var(--bs-bg-opacity)) !important;
}

.bg-item-bg {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-item-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-item-text {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-item-text-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 0.75rem !important;
  }

  .m-sm-4 {
    margin: 1rem !important;
  }

  .m-sm-5 {
    margin: 1.25rem !important;
  }

  .m-sm-6 {
    margin: 1.5rem !important;
  }

  .m-sm-7 {
    margin: 1.75rem !important;
  }

  .m-sm-8 {
    margin: 2rem !important;
  }

  .m-sm-9 {
    margin: 2.25rem !important;
  }

  .m-sm-10 {
    margin: 2.5rem !important;
  }

  .m-sm-11 {
    margin: 2.75rem !important;
  }

  .m-sm-12 {
    margin: 3rem !important;
  }

  .m-sm-13 {
    margin: 3.25rem !important;
  }

  .m-sm-14 {
    margin: 3.5rem !important;
  }

  .m-sm-15 {
    margin: 3.75rem !important;
  }

  .m-sm-16 {
    margin: 4rem !important;
  }

  .m-sm-17 {
    margin: 4.25rem !important;
  }

  .m-sm-18 {
    margin: 4.5rem !important;
  }

  .m-sm-19 {
    margin: 4.75rem !important;
  }

  .m-sm-20 {
    margin: 5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-sm-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-sm-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-sm-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-sm-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-sm-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-sm-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-sm-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-sm-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-sm-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-sm-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-sm-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-sm-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-sm-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-sm-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-sm-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-sm-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-sm-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-sm-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-sm-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }

  .mt-sm-4 {
    margin-top: 1rem !important;
  }

  .mt-sm-5 {
    margin-top: 1.25rem !important;
  }

  .mt-sm-6 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 1.75rem !important;
  }

  .mt-sm-8 {
    margin-top: 2rem !important;
  }

  .mt-sm-9 {
    margin-top: 2.25rem !important;
  }

  .mt-sm-10 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-11 {
    margin-top: 2.75rem !important;
  }

  .mt-sm-12 {
    margin-top: 3rem !important;
  }

  .mt-sm-13 {
    margin-top: 3.25rem !important;
  }

  .mt-sm-14 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-15 {
    margin-top: 3.75rem !important;
  }

  .mt-sm-16 {
    margin-top: 4rem !important;
  }

  .mt-sm-17 {
    margin-top: 4.25rem !important;
  }

  .mt-sm-18 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-19 {
    margin-top: 4.75rem !important;
  }

  .mt-sm-20 {
    margin-top: 5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 0.75rem !important;
  }

  .me-sm-4 {
    margin-right: 1rem !important;
  }

  .me-sm-5 {
    margin-right: 1.25rem !important;
  }

  .me-sm-6 {
    margin-right: 1.5rem !important;
  }

  .me-sm-7 {
    margin-right: 1.75rem !important;
  }

  .me-sm-8 {
    margin-right: 2rem !important;
  }

  .me-sm-9 {
    margin-right: 2.25rem !important;
  }

  .me-sm-10 {
    margin-right: 2.5rem !important;
  }

  .me-sm-11 {
    margin-right: 2.75rem !important;
  }

  .me-sm-12 {
    margin-right: 3rem !important;
  }

  .me-sm-13 {
    margin-right: 3.25rem !important;
  }

  .me-sm-14 {
    margin-right: 3.5rem !important;
  }

  .me-sm-15 {
    margin-right: 3.75rem !important;
  }

  .me-sm-16 {
    margin-right: 4rem !important;
  }

  .me-sm-17 {
    margin-right: 4.25rem !important;
  }

  .me-sm-18 {
    margin-right: 4.5rem !important;
  }

  .me-sm-19 {
    margin-right: 4.75rem !important;
  }

  .me-sm-20 {
    margin-right: 5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-sm-16 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-sm-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-sm-20 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 0.75rem !important;
  }

  .ms-sm-4 {
    margin-left: 1rem !important;
  }

  .ms-sm-5 {
    margin-left: 1.25rem !important;
  }

  .ms-sm-6 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 1.75rem !important;
  }

  .ms-sm-8 {
    margin-left: 2rem !important;
  }

  .ms-sm-9 {
    margin-left: 2.25rem !important;
  }

  .ms-sm-10 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-11 {
    margin-left: 2.75rem !important;
  }

  .ms-sm-12 {
    margin-left: 3rem !important;
  }

  .ms-sm-13 {
    margin-left: 3.25rem !important;
  }

  .ms-sm-14 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-15 {
    margin-left: 3.75rem !important;
  }

  .ms-sm-16 {
    margin-left: 4rem !important;
  }

  .ms-sm-17 {
    margin-left: 4.25rem !important;
  }

  .ms-sm-18 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-19 {
    margin-left: 4.75rem !important;
  }

  .ms-sm-20 {
    margin-left: 5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -0.75rem !important;
  }

  .m-sm-n4 {
    margin: -1rem !important;
  }

  .m-sm-n5 {
    margin: -1.25rem !important;
  }

  .m-sm-n6 {
    margin: -1.5rem !important;
  }

  .m-sm-n7 {
    margin: -1.75rem !important;
  }

  .m-sm-n8 {
    margin: -2rem !important;
  }

  .m-sm-n9 {
    margin: -2.25rem !important;
  }

  .m-sm-n10 {
    margin: -2.5rem !important;
  }

  .m-sm-n11 {
    margin: -2.75rem !important;
  }

  .m-sm-n12 {
    margin: -3rem !important;
  }

  .m-sm-n13 {
    margin: -3.25rem !important;
  }

  .m-sm-n14 {
    margin: -3.5rem !important;
  }

  .m-sm-n15 {
    margin: -3.75rem !important;
  }

  .m-sm-n16 {
    margin: -4rem !important;
  }

  .m-sm-n17 {
    margin: -4.25rem !important;
  }

  .m-sm-n18 {
    margin: -4.5rem !important;
  }

  .m-sm-n19 {
    margin: -4.75rem !important;
  }

  .m-sm-n20 {
    margin: -5rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-sm-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-sm-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-sm-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-sm-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-sm-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-sm-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-sm-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-sm-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-sm-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-sm-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-sm-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-sm-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-sm-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-sm-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-sm-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-sm-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-sm-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-sm-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-sm-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-sm-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-sm-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-sm-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-sm-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-sm-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-sm-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1rem !important;
  }

  .mt-sm-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-sm-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-sm-n8 {
    margin-top: -2rem !important;
  }

  .mt-sm-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-sm-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-sm-n12 {
    margin-top: -3rem !important;
  }

  .mt-sm-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-sm-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-sm-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-sm-n16 {
    margin-top: -4rem !important;
  }

  .mt-sm-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-sm-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-sm-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-sm-n20 {
    margin-top: -5rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -0.75rem !important;
  }

  .me-sm-n4 {
    margin-right: -1rem !important;
  }

  .me-sm-n5 {
    margin-right: -1.25rem !important;
  }

  .me-sm-n6 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n7 {
    margin-right: -1.75rem !important;
  }

  .me-sm-n8 {
    margin-right: -2rem !important;
  }

  .me-sm-n9 {
    margin-right: -2.25rem !important;
  }

  .me-sm-n10 {
    margin-right: -2.5rem !important;
  }

  .me-sm-n11 {
    margin-right: -2.75rem !important;
  }

  .me-sm-n12 {
    margin-right: -3rem !important;
  }

  .me-sm-n13 {
    margin-right: -3.25rem !important;
  }

  .me-sm-n14 {
    margin-right: -3.5rem !important;
  }

  .me-sm-n15 {
    margin-right: -3.75rem !important;
  }

  .me-sm-n16 {
    margin-right: -4rem !important;
  }

  .me-sm-n17 {
    margin-right: -4.25rem !important;
  }

  .me-sm-n18 {
    margin-right: -4.5rem !important;
  }

  .me-sm-n19 {
    margin-right: -4.75rem !important;
  }

  .me-sm-n20 {
    margin-right: -5rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-sm-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-sm-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-sm-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-sm-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-sm-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-sm-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-sm-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1rem !important;
  }

  .ms-sm-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-sm-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-sm-n8 {
    margin-left: -2rem !important;
  }

  .ms-sm-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-sm-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-sm-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-sm-n12 {
    margin-left: -3rem !important;
  }

  .ms-sm-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-sm-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-sm-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-sm-n16 {
    margin-left: -4rem !important;
  }

  .ms-sm-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-sm-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-sm-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-sm-n20 {
    margin-left: -5rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 0.75rem !important;
  }

  .p-sm-4 {
    padding: 1rem !important;
  }

  .p-sm-5 {
    padding: 1.25rem !important;
  }

  .p-sm-6 {
    padding: 1.5rem !important;
  }

  .p-sm-7 {
    padding: 1.75rem !important;
  }

  .p-sm-8 {
    padding: 2rem !important;
  }

  .p-sm-9 {
    padding: 2.25rem !important;
  }

  .p-sm-10 {
    padding: 2.5rem !important;
  }

  .p-sm-11 {
    padding: 2.75rem !important;
  }

  .p-sm-12 {
    padding: 3rem !important;
  }

  .p-sm-13 {
    padding: 3.25rem !important;
  }

  .p-sm-14 {
    padding: 3.5rem !important;
  }

  .p-sm-15 {
    padding: 3.75rem !important;
  }

  .p-sm-16 {
    padding: 4rem !important;
  }

  .p-sm-17 {
    padding: 4.25rem !important;
  }

  .p-sm-18 {
    padding: 4.5rem !important;
  }

  .p-sm-19 {
    padding: 4.75rem !important;
  }

  .p-sm-20 {
    padding: 5rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-sm-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-sm-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-sm-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-sm-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-sm-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-sm-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-sm-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-sm-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-sm-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-sm-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-sm-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-sm-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-sm-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-sm-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-sm-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-sm-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-sm-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-sm-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-sm-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-sm-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }

  .pt-sm-4 {
    padding-top: 1rem !important;
  }

  .pt-sm-5 {
    padding-top: 1.25rem !important;
  }

  .pt-sm-6 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 1.75rem !important;
  }

  .pt-sm-8 {
    padding-top: 2rem !important;
  }

  .pt-sm-9 {
    padding-top: 2.25rem !important;
  }

  .pt-sm-10 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-11 {
    padding-top: 2.75rem !important;
  }

  .pt-sm-12 {
    padding-top: 3rem !important;
  }

  .pt-sm-13 {
    padding-top: 3.25rem !important;
  }

  .pt-sm-14 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-15 {
    padding-top: 3.75rem !important;
  }

  .pt-sm-16 {
    padding-top: 4rem !important;
  }

  .pt-sm-17 {
    padding-top: 4.25rem !important;
  }

  .pt-sm-18 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-19 {
    padding-top: 4.75rem !important;
  }

  .pt-sm-20 {
    padding-top: 5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 0.75rem !important;
  }

  .pe-sm-4 {
    padding-right: 1rem !important;
  }

  .pe-sm-5 {
    padding-right: 1.25rem !important;
  }

  .pe-sm-6 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 1.75rem !important;
  }

  .pe-sm-8 {
    padding-right: 2rem !important;
  }

  .pe-sm-9 {
    padding-right: 2.25rem !important;
  }

  .pe-sm-10 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-11 {
    padding-right: 2.75rem !important;
  }

  .pe-sm-12 {
    padding-right: 3rem !important;
  }

  .pe-sm-13 {
    padding-right: 3.25rem !important;
  }

  .pe-sm-14 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-15 {
    padding-right: 3.75rem !important;
  }

  .pe-sm-16 {
    padding-right: 4rem !important;
  }

  .pe-sm-17 {
    padding-right: 4.25rem !important;
  }

  .pe-sm-18 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-19 {
    padding-right: 4.75rem !important;
  }

  .pe-sm-20 {
    padding-right: 5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-sm-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-sm-16 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-sm-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-sm-20 {
    padding-bottom: 5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 0.75rem !important;
  }

  .ps-sm-4 {
    padding-left: 1rem !important;
  }

  .ps-sm-5 {
    padding-left: 1.25rem !important;
  }

  .ps-sm-6 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 1.75rem !important;
  }

  .ps-sm-8 {
    padding-left: 2rem !important;
  }

  .ps-sm-9 {
    padding-left: 2.25rem !important;
  }

  .ps-sm-10 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-11 {
    padding-left: 2.75rem !important;
  }

  .ps-sm-12 {
    padding-left: 3rem !important;
  }

  .ps-sm-13 {
    padding-left: 3.25rem !important;
  }

  .ps-sm-14 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-15 {
    padding-left: 3.75rem !important;
  }

  .ps-sm-16 {
    padding-left: 4rem !important;
  }

  .ps-sm-17 {
    padding-left: 4.25rem !important;
  }

  .ps-sm-18 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-19 {
    padding-left: 4.75rem !important;
  }

  .ps-sm-20 {
    padding-left: 5rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 0.75rem !important;
  }

  .gap-sm-4 {
    gap: 1rem !important;
  }

  .gap-sm-5 {
    gap: 1.25rem !important;
  }

  .gap-sm-6 {
    gap: 1.5rem !important;
  }

  .gap-sm-7 {
    gap: 1.75rem !important;
  }

  .gap-sm-8 {
    gap: 2rem !important;
  }

  .gap-sm-9 {
    gap: 2.25rem !important;
  }

  .gap-sm-10 {
    gap: 2.5rem !important;
  }

  .gap-sm-11 {
    gap: 2.75rem !important;
  }

  .gap-sm-12 {
    gap: 3rem !important;
  }

  .gap-sm-13 {
    gap: 3.25rem !important;
  }

  .gap-sm-14 {
    gap: 3.5rem !important;
  }

  .gap-sm-15 {
    gap: 3.75rem !important;
  }

  .gap-sm-16 {
    gap: 4rem !important;
  }

  .gap-sm-17 {
    gap: 4.25rem !important;
  }

  .gap-sm-18 {
    gap: 4.5rem !important;
  }

  .gap-sm-19 {
    gap: 4.75rem !important;
  }

  .gap-sm-20 {
    gap: 5rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-sm-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-sm-8 {
    row-gap: 2rem !important;
  }

  .row-gap-sm-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-sm-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-sm-11 {
    row-gap: 2.75rem !important;
  }

  .row-gap-sm-12 {
    row-gap: 3rem !important;
  }

  .row-gap-sm-13 {
    row-gap: 3.25rem !important;
  }

  .row-gap-sm-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-sm-15 {
    row-gap: 3.75rem !important;
  }

  .row-gap-sm-16 {
    row-gap: 4rem !important;
  }

  .row-gap-sm-17 {
    row-gap: 4.25rem !important;
  }

  .row-gap-sm-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-sm-19 {
    row-gap: 4.75rem !important;
  }

  .row-gap-sm-20 {
    row-gap: 5rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-sm-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-sm-8 {
    column-gap: 2rem !important;
  }

  .column-gap-sm-9 {
    column-gap: 2.25rem !important;
  }

  .column-gap-sm-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-sm-11 {
    column-gap: 2.75rem !important;
  }

  .column-gap-sm-12 {
    column-gap: 3rem !important;
  }

  .column-gap-sm-13 {
    column-gap: 3.25rem !important;
  }

  .column-gap-sm-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-sm-15 {
    column-gap: 3.75rem !important;
  }

  .column-gap-sm-16 {
    column-gap: 4rem !important;
  }

  .column-gap-sm-17 {
    column-gap: 4.25rem !important;
  }

  .column-gap-sm-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-sm-19 {
    column-gap: 4.75rem !important;
  }

  .column-gap-sm-20 {
    column-gap: 5rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 0.75rem !important;
  }

  .m-md-4 {
    margin: 1rem !important;
  }

  .m-md-5 {
    margin: 1.25rem !important;
  }

  .m-md-6 {
    margin: 1.5rem !important;
  }

  .m-md-7 {
    margin: 1.75rem !important;
  }

  .m-md-8 {
    margin: 2rem !important;
  }

  .m-md-9 {
    margin: 2.25rem !important;
  }

  .m-md-10 {
    margin: 2.5rem !important;
  }

  .m-md-11 {
    margin: 2.75rem !important;
  }

  .m-md-12 {
    margin: 3rem !important;
  }

  .m-md-13 {
    margin: 3.25rem !important;
  }

  .m-md-14 {
    margin: 3.5rem !important;
  }

  .m-md-15 {
    margin: 3.75rem !important;
  }

  .m-md-16 {
    margin: 4rem !important;
  }

  .m-md-17 {
    margin: 4.25rem !important;
  }

  .m-md-18 {
    margin: 4.5rem !important;
  }

  .m-md-19 {
    margin: 4.75rem !important;
  }

  .m-md-20 {
    margin: 5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-md-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-md-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-md-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-md-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-md-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-md-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-md-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-md-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-md-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-md-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-md-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-md-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-md-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-md-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-md-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-md-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-md-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-md-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-md-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 0.75rem !important;
  }

  .mt-md-4 {
    margin-top: 1rem !important;
  }

  .mt-md-5 {
    margin-top: 1.25rem !important;
  }

  .mt-md-6 {
    margin-top: 1.5rem !important;
  }

  .mt-md-7 {
    margin-top: 1.75rem !important;
  }

  .mt-md-8 {
    margin-top: 2rem !important;
  }

  .mt-md-9 {
    margin-top: 2.25rem !important;
  }

  .mt-md-10 {
    margin-top: 2.5rem !important;
  }

  .mt-md-11 {
    margin-top: 2.75rem !important;
  }

  .mt-md-12 {
    margin-top: 3rem !important;
  }

  .mt-md-13 {
    margin-top: 3.25rem !important;
  }

  .mt-md-14 {
    margin-top: 3.5rem !important;
  }

  .mt-md-15 {
    margin-top: 3.75rem !important;
  }

  .mt-md-16 {
    margin-top: 4rem !important;
  }

  .mt-md-17 {
    margin-top: 4.25rem !important;
  }

  .mt-md-18 {
    margin-top: 4.5rem !important;
  }

  .mt-md-19 {
    margin-top: 4.75rem !important;
  }

  .mt-md-20 {
    margin-top: 5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 0.75rem !important;
  }

  .me-md-4 {
    margin-right: 1rem !important;
  }

  .me-md-5 {
    margin-right: 1.25rem !important;
  }

  .me-md-6 {
    margin-right: 1.5rem !important;
  }

  .me-md-7 {
    margin-right: 1.75rem !important;
  }

  .me-md-8 {
    margin-right: 2rem !important;
  }

  .me-md-9 {
    margin-right: 2.25rem !important;
  }

  .me-md-10 {
    margin-right: 2.5rem !important;
  }

  .me-md-11 {
    margin-right: 2.75rem !important;
  }

  .me-md-12 {
    margin-right: 3rem !important;
  }

  .me-md-13 {
    margin-right: 3.25rem !important;
  }

  .me-md-14 {
    margin-right: 3.5rem !important;
  }

  .me-md-15 {
    margin-right: 3.75rem !important;
  }

  .me-md-16 {
    margin-right: 4rem !important;
  }

  .me-md-17 {
    margin-right: 4.25rem !important;
  }

  .me-md-18 {
    margin-right: 4.5rem !important;
  }

  .me-md-19 {
    margin-right: 4.75rem !important;
  }

  .me-md-20 {
    margin-right: 5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1rem !important;
  }

  .mb-md-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-md-8 {
    margin-bottom: 2rem !important;
  }

  .mb-md-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-md-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-md-12 {
    margin-bottom: 3rem !important;
  }

  .mb-md-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-md-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-md-16 {
    margin-bottom: 4rem !important;
  }

  .mb-md-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-md-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-md-20 {
    margin-bottom: 5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 0.75rem !important;
  }

  .ms-md-4 {
    margin-left: 1rem !important;
  }

  .ms-md-5 {
    margin-left: 1.25rem !important;
  }

  .ms-md-6 {
    margin-left: 1.5rem !important;
  }

  .ms-md-7 {
    margin-left: 1.75rem !important;
  }

  .ms-md-8 {
    margin-left: 2rem !important;
  }

  .ms-md-9 {
    margin-left: 2.25rem !important;
  }

  .ms-md-10 {
    margin-left: 2.5rem !important;
  }

  .ms-md-11 {
    margin-left: 2.75rem !important;
  }

  .ms-md-12 {
    margin-left: 3rem !important;
  }

  .ms-md-13 {
    margin-left: 3.25rem !important;
  }

  .ms-md-14 {
    margin-left: 3.5rem !important;
  }

  .ms-md-15 {
    margin-left: 3.75rem !important;
  }

  .ms-md-16 {
    margin-left: 4rem !important;
  }

  .ms-md-17 {
    margin-left: 4.25rem !important;
  }

  .ms-md-18 {
    margin-left: 4.5rem !important;
  }

  .ms-md-19 {
    margin-left: 4.75rem !important;
  }

  .ms-md-20 {
    margin-left: 5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -0.75rem !important;
  }

  .m-md-n4 {
    margin: -1rem !important;
  }

  .m-md-n5 {
    margin: -1.25rem !important;
  }

  .m-md-n6 {
    margin: -1.5rem !important;
  }

  .m-md-n7 {
    margin: -1.75rem !important;
  }

  .m-md-n8 {
    margin: -2rem !important;
  }

  .m-md-n9 {
    margin: -2.25rem !important;
  }

  .m-md-n10 {
    margin: -2.5rem !important;
  }

  .m-md-n11 {
    margin: -2.75rem !important;
  }

  .m-md-n12 {
    margin: -3rem !important;
  }

  .m-md-n13 {
    margin: -3.25rem !important;
  }

  .m-md-n14 {
    margin: -3.5rem !important;
  }

  .m-md-n15 {
    margin: -3.75rem !important;
  }

  .m-md-n16 {
    margin: -4rem !important;
  }

  .m-md-n17 {
    margin: -4.25rem !important;
  }

  .m-md-n18 {
    margin: -4.5rem !important;
  }

  .m-md-n19 {
    margin: -4.75rem !important;
  }

  .m-md-n20 {
    margin: -5rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-md-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-md-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-md-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-md-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-md-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-md-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-md-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-md-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-md-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-md-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-md-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-md-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-md-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-md-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-md-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-md-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-md-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-md-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-md-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-md-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-md-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-md-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-md-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-md-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-md-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-md-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-md-n4 {
    margin-top: -1rem !important;
  }

  .mt-md-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-md-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-md-n8 {
    margin-top: -2rem !important;
  }

  .mt-md-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-md-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-md-n12 {
    margin-top: -3rem !important;
  }

  .mt-md-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-md-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-md-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-md-n16 {
    margin-top: -4rem !important;
  }

  .mt-md-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-md-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-md-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-md-n20 {
    margin-top: -5rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -0.75rem !important;
  }

  .me-md-n4 {
    margin-right: -1rem !important;
  }

  .me-md-n5 {
    margin-right: -1.25rem !important;
  }

  .me-md-n6 {
    margin-right: -1.5rem !important;
  }

  .me-md-n7 {
    margin-right: -1.75rem !important;
  }

  .me-md-n8 {
    margin-right: -2rem !important;
  }

  .me-md-n9 {
    margin-right: -2.25rem !important;
  }

  .me-md-n10 {
    margin-right: -2.5rem !important;
  }

  .me-md-n11 {
    margin-right: -2.75rem !important;
  }

  .me-md-n12 {
    margin-right: -3rem !important;
  }

  .me-md-n13 {
    margin-right: -3.25rem !important;
  }

  .me-md-n14 {
    margin-right: -3.5rem !important;
  }

  .me-md-n15 {
    margin-right: -3.75rem !important;
  }

  .me-md-n16 {
    margin-right: -4rem !important;
  }

  .me-md-n17 {
    margin-right: -4.25rem !important;
  }

  .me-md-n18 {
    margin-right: -4.5rem !important;
  }

  .me-md-n19 {
    margin-right: -4.75rem !important;
  }

  .me-md-n20 {
    margin-right: -5rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-md-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-md-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-md-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-md-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-md-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-md-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-md-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-md-n4 {
    margin-left: -1rem !important;
  }

  .ms-md-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-md-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-md-n8 {
    margin-left: -2rem !important;
  }

  .ms-md-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-md-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-md-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-md-n12 {
    margin-left: -3rem !important;
  }

  .ms-md-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-md-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-md-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-md-n16 {
    margin-left: -4rem !important;
  }

  .ms-md-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-md-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-md-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-md-n20 {
    margin-left: -5rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 0.75rem !important;
  }

  .p-md-4 {
    padding: 1rem !important;
  }

  .p-md-5 {
    padding: 1.25rem !important;
  }

  .p-md-6 {
    padding: 1.5rem !important;
  }

  .p-md-7 {
    padding: 1.75rem !important;
  }

  .p-md-8 {
    padding: 2rem !important;
  }

  .p-md-9 {
    padding: 2.25rem !important;
  }

  .p-md-10 {
    padding: 2.5rem !important;
  }

  .p-md-11 {
    padding: 2.75rem !important;
  }

  .p-md-12 {
    padding: 3rem !important;
  }

  .p-md-13 {
    padding: 3.25rem !important;
  }

  .p-md-14 {
    padding: 3.5rem !important;
  }

  .p-md-15 {
    padding: 3.75rem !important;
  }

  .p-md-16 {
    padding: 4rem !important;
  }

  .p-md-17 {
    padding: 4.25rem !important;
  }

  .p-md-18 {
    padding: 4.5rem !important;
  }

  .p-md-19 {
    padding: 4.75rem !important;
  }

  .p-md-20 {
    padding: 5rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-md-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-md-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-md-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-md-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-md-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-md-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-md-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-md-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-md-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-md-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-md-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-md-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-md-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-md-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-md-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-md-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-md-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-md-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-md-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-md-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 0.75rem !important;
  }

  .pt-md-4 {
    padding-top: 1rem !important;
  }

  .pt-md-5 {
    padding-top: 1.25rem !important;
  }

  .pt-md-6 {
    padding-top: 1.5rem !important;
  }

  .pt-md-7 {
    padding-top: 1.75rem !important;
  }

  .pt-md-8 {
    padding-top: 2rem !important;
  }

  .pt-md-9 {
    padding-top: 2.25rem !important;
  }

  .pt-md-10 {
    padding-top: 2.5rem !important;
  }

  .pt-md-11 {
    padding-top: 2.75rem !important;
  }

  .pt-md-12 {
    padding-top: 3rem !important;
  }

  .pt-md-13 {
    padding-top: 3.25rem !important;
  }

  .pt-md-14 {
    padding-top: 3.5rem !important;
  }

  .pt-md-15 {
    padding-top: 3.75rem !important;
  }

  .pt-md-16 {
    padding-top: 4rem !important;
  }

  .pt-md-17 {
    padding-top: 4.25rem !important;
  }

  .pt-md-18 {
    padding-top: 4.5rem !important;
  }

  .pt-md-19 {
    padding-top: 4.75rem !important;
  }

  .pt-md-20 {
    padding-top: 5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 0.75rem !important;
  }

  .pe-md-4 {
    padding-right: 1rem !important;
  }

  .pe-md-5 {
    padding-right: 1.25rem !important;
  }

  .pe-md-6 {
    padding-right: 1.5rem !important;
  }

  .pe-md-7 {
    padding-right: 1.75rem !important;
  }

  .pe-md-8 {
    padding-right: 2rem !important;
  }

  .pe-md-9 {
    padding-right: 2.25rem !important;
  }

  .pe-md-10 {
    padding-right: 2.5rem !important;
  }

  .pe-md-11 {
    padding-right: 2.75rem !important;
  }

  .pe-md-12 {
    padding-right: 3rem !important;
  }

  .pe-md-13 {
    padding-right: 3.25rem !important;
  }

  .pe-md-14 {
    padding-right: 3.5rem !important;
  }

  .pe-md-15 {
    padding-right: 3.75rem !important;
  }

  .pe-md-16 {
    padding-right: 4rem !important;
  }

  .pe-md-17 {
    padding-right: 4.25rem !important;
  }

  .pe-md-18 {
    padding-right: 4.5rem !important;
  }

  .pe-md-19 {
    padding-right: 4.75rem !important;
  }

  .pe-md-20 {
    padding-right: 5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1rem !important;
  }

  .pb-md-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-md-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-md-8 {
    padding-bottom: 2rem !important;
  }

  .pb-md-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-md-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-md-12 {
    padding-bottom: 3rem !important;
  }

  .pb-md-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-md-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-md-16 {
    padding-bottom: 4rem !important;
  }

  .pb-md-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-md-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-md-20 {
    padding-bottom: 5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 0.75rem !important;
  }

  .ps-md-4 {
    padding-left: 1rem !important;
  }

  .ps-md-5 {
    padding-left: 1.25rem !important;
  }

  .ps-md-6 {
    padding-left: 1.5rem !important;
  }

  .ps-md-7 {
    padding-left: 1.75rem !important;
  }

  .ps-md-8 {
    padding-left: 2rem !important;
  }

  .ps-md-9 {
    padding-left: 2.25rem !important;
  }

  .ps-md-10 {
    padding-left: 2.5rem !important;
  }

  .ps-md-11 {
    padding-left: 2.75rem !important;
  }

  .ps-md-12 {
    padding-left: 3rem !important;
  }

  .ps-md-13 {
    padding-left: 3.25rem !important;
  }

  .ps-md-14 {
    padding-left: 3.5rem !important;
  }

  .ps-md-15 {
    padding-left: 3.75rem !important;
  }

  .ps-md-16 {
    padding-left: 4rem !important;
  }

  .ps-md-17 {
    padding-left: 4.25rem !important;
  }

  .ps-md-18 {
    padding-left: 4.5rem !important;
  }

  .ps-md-19 {
    padding-left: 4.75rem !important;
  }

  .ps-md-20 {
    padding-left: 5rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 0.75rem !important;
  }

  .gap-md-4 {
    gap: 1rem !important;
  }

  .gap-md-5 {
    gap: 1.25rem !important;
  }

  .gap-md-6 {
    gap: 1.5rem !important;
  }

  .gap-md-7 {
    gap: 1.75rem !important;
  }

  .gap-md-8 {
    gap: 2rem !important;
  }

  .gap-md-9 {
    gap: 2.25rem !important;
  }

  .gap-md-10 {
    gap: 2.5rem !important;
  }

  .gap-md-11 {
    gap: 2.75rem !important;
  }

  .gap-md-12 {
    gap: 3rem !important;
  }

  .gap-md-13 {
    gap: 3.25rem !important;
  }

  .gap-md-14 {
    gap: 3.5rem !important;
  }

  .gap-md-15 {
    gap: 3.75rem !important;
  }

  .gap-md-16 {
    gap: 4rem !important;
  }

  .gap-md-17 {
    gap: 4.25rem !important;
  }

  .gap-md-18 {
    gap: 4.5rem !important;
  }

  .gap-md-19 {
    gap: 4.75rem !important;
  }

  .gap-md-20 {
    gap: 5rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1rem !important;
  }

  .row-gap-md-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-md-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-md-8 {
    row-gap: 2rem !important;
  }

  .row-gap-md-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-md-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-md-11 {
    row-gap: 2.75rem !important;
  }

  .row-gap-md-12 {
    row-gap: 3rem !important;
  }

  .row-gap-md-13 {
    row-gap: 3.25rem !important;
  }

  .row-gap-md-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-md-15 {
    row-gap: 3.75rem !important;
  }

  .row-gap-md-16 {
    row-gap: 4rem !important;
  }

  .row-gap-md-17 {
    row-gap: 4.25rem !important;
  }

  .row-gap-md-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-md-19 {
    row-gap: 4.75rem !important;
  }

  .row-gap-md-20 {
    row-gap: 5rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1rem !important;
  }

  .column-gap-md-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-md-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-md-8 {
    column-gap: 2rem !important;
  }

  .column-gap-md-9 {
    column-gap: 2.25rem !important;
  }

  .column-gap-md-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-md-11 {
    column-gap: 2.75rem !important;
  }

  .column-gap-md-12 {
    column-gap: 3rem !important;
  }

  .column-gap-md-13 {
    column-gap: 3.25rem !important;
  }

  .column-gap-md-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-md-15 {
    column-gap: 3.75rem !important;
  }

  .column-gap-md-16 {
    column-gap: 4rem !important;
  }

  .column-gap-md-17 {
    column-gap: 4.25rem !important;
  }

  .column-gap-md-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-md-19 {
    column-gap: 4.75rem !important;
  }

  .column-gap-md-20 {
    column-gap: 5rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 0.75rem !important;
  }

  .m-lg-4 {
    margin: 1rem !important;
  }

  .m-lg-5 {
    margin: 1.25rem !important;
  }

  .m-lg-6 {
    margin: 1.5rem !important;
  }

  .m-lg-7 {
    margin: 1.75rem !important;
  }

  .m-lg-8 {
    margin: 2rem !important;
  }

  .m-lg-9 {
    margin: 2.25rem !important;
  }

  .m-lg-10 {
    margin: 2.5rem !important;
  }

  .m-lg-11 {
    margin: 2.75rem !important;
  }

  .m-lg-12 {
    margin: 3rem !important;
  }

  .m-lg-13 {
    margin: 3.25rem !important;
  }

  .m-lg-14 {
    margin: 3.5rem !important;
  }

  .m-lg-15 {
    margin: 3.75rem !important;
  }

  .m-lg-16 {
    margin: 4rem !important;
  }

  .m-lg-17 {
    margin: 4.25rem !important;
  }

  .m-lg-18 {
    margin: 4.5rem !important;
  }

  .m-lg-19 {
    margin: 4.75rem !important;
  }

  .m-lg-20 {
    margin: 5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-lg-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-lg-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-lg-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-lg-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-lg-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-lg-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-lg-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-lg-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-lg-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-lg-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-lg-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-lg-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-lg-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-lg-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-lg-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-lg-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-lg-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-lg-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-lg-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }

  .mt-lg-4 {
    margin-top: 1rem !important;
  }

  .mt-lg-5 {
    margin-top: 1.25rem !important;
  }

  .mt-lg-6 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 1.75rem !important;
  }

  .mt-lg-8 {
    margin-top: 2rem !important;
  }

  .mt-lg-9 {
    margin-top: 2.25rem !important;
  }

  .mt-lg-10 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-11 {
    margin-top: 2.75rem !important;
  }

  .mt-lg-12 {
    margin-top: 3rem !important;
  }

  .mt-lg-13 {
    margin-top: 3.25rem !important;
  }

  .mt-lg-14 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-15 {
    margin-top: 3.75rem !important;
  }

  .mt-lg-16 {
    margin-top: 4rem !important;
  }

  .mt-lg-17 {
    margin-top: 4.25rem !important;
  }

  .mt-lg-18 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-19 {
    margin-top: 4.75rem !important;
  }

  .mt-lg-20 {
    margin-top: 5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 0.75rem !important;
  }

  .me-lg-4 {
    margin-right: 1rem !important;
  }

  .me-lg-5 {
    margin-right: 1.25rem !important;
  }

  .me-lg-6 {
    margin-right: 1.5rem !important;
  }

  .me-lg-7 {
    margin-right: 1.75rem !important;
  }

  .me-lg-8 {
    margin-right: 2rem !important;
  }

  .me-lg-9 {
    margin-right: 2.25rem !important;
  }

  .me-lg-10 {
    margin-right: 2.5rem !important;
  }

  .me-lg-11 {
    margin-right: 2.75rem !important;
  }

  .me-lg-12 {
    margin-right: 3rem !important;
  }

  .me-lg-13 {
    margin-right: 3.25rem !important;
  }

  .me-lg-14 {
    margin-right: 3.5rem !important;
  }

  .me-lg-15 {
    margin-right: 3.75rem !important;
  }

  .me-lg-16 {
    margin-right: 4rem !important;
  }

  .me-lg-17 {
    margin-right: 4.25rem !important;
  }

  .me-lg-18 {
    margin-right: 4.5rem !important;
  }

  .me-lg-19 {
    margin-right: 4.75rem !important;
  }

  .me-lg-20 {
    margin-right: 5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-lg-16 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-lg-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-lg-20 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }

  .ms-lg-4 {
    margin-left: 1rem !important;
  }

  .ms-lg-5 {
    margin-left: 1.25rem !important;
  }

  .ms-lg-6 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 1.75rem !important;
  }

  .ms-lg-8 {
    margin-left: 2rem !important;
  }

  .ms-lg-9 {
    margin-left: 2.25rem !important;
  }

  .ms-lg-10 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-11 {
    margin-left: 2.75rem !important;
  }

  .ms-lg-12 {
    margin-left: 3rem !important;
  }

  .ms-lg-13 {
    margin-left: 3.25rem !important;
  }

  .ms-lg-14 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-15 {
    margin-left: 3.75rem !important;
  }

  .ms-lg-16 {
    margin-left: 4rem !important;
  }

  .ms-lg-17 {
    margin-left: 4.25rem !important;
  }

  .ms-lg-18 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-19 {
    margin-left: 4.75rem !important;
  }

  .ms-lg-20 {
    margin-left: 5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -0.75rem !important;
  }

  .m-lg-n4 {
    margin: -1rem !important;
  }

  .m-lg-n5 {
    margin: -1.25rem !important;
  }

  .m-lg-n6 {
    margin: -1.5rem !important;
  }

  .m-lg-n7 {
    margin: -1.75rem !important;
  }

  .m-lg-n8 {
    margin: -2rem !important;
  }

  .m-lg-n9 {
    margin: -2.25rem !important;
  }

  .m-lg-n10 {
    margin: -2.5rem !important;
  }

  .m-lg-n11 {
    margin: -2.75rem !important;
  }

  .m-lg-n12 {
    margin: -3rem !important;
  }

  .m-lg-n13 {
    margin: -3.25rem !important;
  }

  .m-lg-n14 {
    margin: -3.5rem !important;
  }

  .m-lg-n15 {
    margin: -3.75rem !important;
  }

  .m-lg-n16 {
    margin: -4rem !important;
  }

  .m-lg-n17 {
    margin: -4.25rem !important;
  }

  .m-lg-n18 {
    margin: -4.5rem !important;
  }

  .m-lg-n19 {
    margin: -4.75rem !important;
  }

  .m-lg-n20 {
    margin: -5rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-lg-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-lg-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-lg-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-lg-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-lg-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-lg-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-lg-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-lg-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-lg-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-lg-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-lg-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-lg-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-lg-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-lg-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-lg-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-lg-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-lg-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-lg-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-lg-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-lg-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-lg-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-lg-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-lg-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-lg-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-lg-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1rem !important;
  }

  .mt-lg-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-lg-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-lg-n8 {
    margin-top: -2rem !important;
  }

  .mt-lg-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-lg-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-lg-n12 {
    margin-top: -3rem !important;
  }

  .mt-lg-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-lg-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-lg-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-lg-n16 {
    margin-top: -4rem !important;
  }

  .mt-lg-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-lg-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-lg-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-lg-n20 {
    margin-top: -5rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -0.75rem !important;
  }

  .me-lg-n4 {
    margin-right: -1rem !important;
  }

  .me-lg-n5 {
    margin-right: -1.25rem !important;
  }

  .me-lg-n6 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n7 {
    margin-right: -1.75rem !important;
  }

  .me-lg-n8 {
    margin-right: -2rem !important;
  }

  .me-lg-n9 {
    margin-right: -2.25rem !important;
  }

  .me-lg-n10 {
    margin-right: -2.5rem !important;
  }

  .me-lg-n11 {
    margin-right: -2.75rem !important;
  }

  .me-lg-n12 {
    margin-right: -3rem !important;
  }

  .me-lg-n13 {
    margin-right: -3.25rem !important;
  }

  .me-lg-n14 {
    margin-right: -3.5rem !important;
  }

  .me-lg-n15 {
    margin-right: -3.75rem !important;
  }

  .me-lg-n16 {
    margin-right: -4rem !important;
  }

  .me-lg-n17 {
    margin-right: -4.25rem !important;
  }

  .me-lg-n18 {
    margin-right: -4.5rem !important;
  }

  .me-lg-n19 {
    margin-right: -4.75rem !important;
  }

  .me-lg-n20 {
    margin-right: -5rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-lg-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-lg-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-lg-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-lg-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-lg-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-lg-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-lg-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1rem !important;
  }

  .ms-lg-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-lg-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-lg-n8 {
    margin-left: -2rem !important;
  }

  .ms-lg-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-lg-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-lg-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-lg-n12 {
    margin-left: -3rem !important;
  }

  .ms-lg-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-lg-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-lg-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-lg-n16 {
    margin-left: -4rem !important;
  }

  .ms-lg-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-lg-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-lg-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-lg-n20 {
    margin-left: -5rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 0.75rem !important;
  }

  .p-lg-4 {
    padding: 1rem !important;
  }

  .p-lg-5 {
    padding: 1.25rem !important;
  }

  .p-lg-6 {
    padding: 1.5rem !important;
  }

  .p-lg-7 {
    padding: 1.75rem !important;
  }

  .p-lg-8 {
    padding: 2rem !important;
  }

  .p-lg-9 {
    padding: 2.25rem !important;
  }

  .p-lg-10 {
    padding: 2.5rem !important;
  }

  .p-lg-11 {
    padding: 2.75rem !important;
  }

  .p-lg-12 {
    padding: 3rem !important;
  }

  .p-lg-13 {
    padding: 3.25rem !important;
  }

  .p-lg-14 {
    padding: 3.5rem !important;
  }

  .p-lg-15 {
    padding: 3.75rem !important;
  }

  .p-lg-16 {
    padding: 4rem !important;
  }

  .p-lg-17 {
    padding: 4.25rem !important;
  }

  .p-lg-18 {
    padding: 4.5rem !important;
  }

  .p-lg-19 {
    padding: 4.75rem !important;
  }

  .p-lg-20 {
    padding: 5rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-lg-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-lg-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-lg-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-lg-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-lg-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-lg-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-lg-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-lg-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-lg-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-lg-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-lg-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-lg-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-lg-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-lg-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-lg-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-lg-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-lg-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-lg-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-lg-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-lg-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }

  .pt-lg-4 {
    padding-top: 1rem !important;
  }

  .pt-lg-5 {
    padding-top: 1.25rem !important;
  }

  .pt-lg-6 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 1.75rem !important;
  }

  .pt-lg-8 {
    padding-top: 2rem !important;
  }

  .pt-lg-9 {
    padding-top: 2.25rem !important;
  }

  .pt-lg-10 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-11 {
    padding-top: 2.75rem !important;
  }

  .pt-lg-12 {
    padding-top: 3rem !important;
  }

  .pt-lg-13 {
    padding-top: 3.25rem !important;
  }

  .pt-lg-14 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-15 {
    padding-top: 3.75rem !important;
  }

  .pt-lg-16 {
    padding-top: 4rem !important;
  }

  .pt-lg-17 {
    padding-top: 4.25rem !important;
  }

  .pt-lg-18 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-19 {
    padding-top: 4.75rem !important;
  }

  .pt-lg-20 {
    padding-top: 5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 0.75rem !important;
  }

  .pe-lg-4 {
    padding-right: 1rem !important;
  }

  .pe-lg-5 {
    padding-right: 1.25rem !important;
  }

  .pe-lg-6 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 1.75rem !important;
  }

  .pe-lg-8 {
    padding-right: 2rem !important;
  }

  .pe-lg-9 {
    padding-right: 2.25rem !important;
  }

  .pe-lg-10 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-11 {
    padding-right: 2.75rem !important;
  }

  .pe-lg-12 {
    padding-right: 3rem !important;
  }

  .pe-lg-13 {
    padding-right: 3.25rem !important;
  }

  .pe-lg-14 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-15 {
    padding-right: 3.75rem !important;
  }

  .pe-lg-16 {
    padding-right: 4rem !important;
  }

  .pe-lg-17 {
    padding-right: 4.25rem !important;
  }

  .pe-lg-18 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-19 {
    padding-right: 4.75rem !important;
  }

  .pe-lg-20 {
    padding-right: 5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-lg-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-lg-16 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-lg-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-lg-20 {
    padding-bottom: 5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 0.75rem !important;
  }

  .ps-lg-4 {
    padding-left: 1rem !important;
  }

  .ps-lg-5 {
    padding-left: 1.25rem !important;
  }

  .ps-lg-6 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 1.75rem !important;
  }

  .ps-lg-8 {
    padding-left: 2rem !important;
  }

  .ps-lg-9 {
    padding-left: 2.25rem !important;
  }

  .ps-lg-10 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-11 {
    padding-left: 2.75rem !important;
  }

  .ps-lg-12 {
    padding-left: 3rem !important;
  }

  .ps-lg-13 {
    padding-left: 3.25rem !important;
  }

  .ps-lg-14 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-15 {
    padding-left: 3.75rem !important;
  }

  .ps-lg-16 {
    padding-left: 4rem !important;
  }

  .ps-lg-17 {
    padding-left: 4.25rem !important;
  }

  .ps-lg-18 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-19 {
    padding-left: 4.75rem !important;
  }

  .ps-lg-20 {
    padding-left: 5rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 0.75rem !important;
  }

  .gap-lg-4 {
    gap: 1rem !important;
  }

  .gap-lg-5 {
    gap: 1.25rem !important;
  }

  .gap-lg-6 {
    gap: 1.5rem !important;
  }

  .gap-lg-7 {
    gap: 1.75rem !important;
  }

  .gap-lg-8 {
    gap: 2rem !important;
  }

  .gap-lg-9 {
    gap: 2.25rem !important;
  }

  .gap-lg-10 {
    gap: 2.5rem !important;
  }

  .gap-lg-11 {
    gap: 2.75rem !important;
  }

  .gap-lg-12 {
    gap: 3rem !important;
  }

  .gap-lg-13 {
    gap: 3.25rem !important;
  }

  .gap-lg-14 {
    gap: 3.5rem !important;
  }

  .gap-lg-15 {
    gap: 3.75rem !important;
  }

  .gap-lg-16 {
    gap: 4rem !important;
  }

  .gap-lg-17 {
    gap: 4.25rem !important;
  }

  .gap-lg-18 {
    gap: 4.5rem !important;
  }

  .gap-lg-19 {
    gap: 4.75rem !important;
  }

  .gap-lg-20 {
    gap: 5rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-lg-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-lg-8 {
    row-gap: 2rem !important;
  }

  .row-gap-lg-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-lg-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-lg-11 {
    row-gap: 2.75rem !important;
  }

  .row-gap-lg-12 {
    row-gap: 3rem !important;
  }

  .row-gap-lg-13 {
    row-gap: 3.25rem !important;
  }

  .row-gap-lg-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-lg-15 {
    row-gap: 3.75rem !important;
  }

  .row-gap-lg-16 {
    row-gap: 4rem !important;
  }

  .row-gap-lg-17 {
    row-gap: 4.25rem !important;
  }

  .row-gap-lg-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-lg-19 {
    row-gap: 4.75rem !important;
  }

  .row-gap-lg-20 {
    row-gap: 5rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-lg-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-lg-8 {
    column-gap: 2rem !important;
  }

  .column-gap-lg-9 {
    column-gap: 2.25rem !important;
  }

  .column-gap-lg-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-lg-11 {
    column-gap: 2.75rem !important;
  }

  .column-gap-lg-12 {
    column-gap: 3rem !important;
  }

  .column-gap-lg-13 {
    column-gap: 3.25rem !important;
  }

  .column-gap-lg-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-lg-15 {
    column-gap: 3.75rem !important;
  }

  .column-gap-lg-16 {
    column-gap: 4rem !important;
  }

  .column-gap-lg-17 {
    column-gap: 4.25rem !important;
  }

  .column-gap-lg-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-lg-19 {
    column-gap: 4.75rem !important;
  }

  .column-gap-lg-20 {
    column-gap: 5rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 0.75rem !important;
  }

  .m-xl-4 {
    margin: 1rem !important;
  }

  .m-xl-5 {
    margin: 1.25rem !important;
  }

  .m-xl-6 {
    margin: 1.5rem !important;
  }

  .m-xl-7 {
    margin: 1.75rem !important;
  }

  .m-xl-8 {
    margin: 2rem !important;
  }

  .m-xl-9 {
    margin: 2.25rem !important;
  }

  .m-xl-10 {
    margin: 2.5rem !important;
  }

  .m-xl-11 {
    margin: 2.75rem !important;
  }

  .m-xl-12 {
    margin: 3rem !important;
  }

  .m-xl-13 {
    margin: 3.25rem !important;
  }

  .m-xl-14 {
    margin: 3.5rem !important;
  }

  .m-xl-15 {
    margin: 3.75rem !important;
  }

  .m-xl-16 {
    margin: 4rem !important;
  }

  .m-xl-17 {
    margin: 4.25rem !important;
  }

  .m-xl-18 {
    margin: 4.5rem !important;
  }

  .m-xl-19 {
    margin: 4.75rem !important;
  }

  .m-xl-20 {
    margin: 5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-xl-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-xl-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-xl-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-xl-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xl-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-xl-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-xl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-xl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-xl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-xl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-xl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xl-4 {
    margin-top: 1rem !important;
  }

  .mt-xl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xl-8 {
    margin-top: 2rem !important;
  }

  .mt-xl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-11 {
    margin-top: 2.75rem !important;
  }

  .mt-xl-12 {
    margin-top: 3rem !important;
  }

  .mt-xl-13 {
    margin-top: 3.25rem !important;
  }

  .mt-xl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-15 {
    margin-top: 3.75rem !important;
  }

  .mt-xl-16 {
    margin-top: 4rem !important;
  }

  .mt-xl-17 {
    margin-top: 4.25rem !important;
  }

  .mt-xl-18 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-19 {
    margin-top: 4.75rem !important;
  }

  .mt-xl-20 {
    margin-top: 5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xl-4 {
    margin-right: 1rem !important;
  }

  .me-xl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xl-8 {
    margin-right: 2rem !important;
  }

  .me-xl-9 {
    margin-right: 2.25rem !important;
  }

  .me-xl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xl-11 {
    margin-right: 2.75rem !important;
  }

  .me-xl-12 {
    margin-right: 3rem !important;
  }

  .me-xl-13 {
    margin-right: 3.25rem !important;
  }

  .me-xl-14 {
    margin-right: 3.5rem !important;
  }

  .me-xl-15 {
    margin-right: 3.75rem !important;
  }

  .me-xl-16 {
    margin-right: 4rem !important;
  }

  .me-xl-17 {
    margin-right: 4.25rem !important;
  }

  .me-xl-18 {
    margin-right: 4.5rem !important;
  }

  .me-xl-19 {
    margin-right: 4.75rem !important;
  }

  .me-xl-20 {
    margin-right: 5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-xl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-xl-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-xl-20 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xl-4 {
    margin-left: 1rem !important;
  }

  .ms-xl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xl-8 {
    margin-left: 2rem !important;
  }

  .ms-xl-9 {
    margin-left: 2.25rem !important;
  }

  .ms-xl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-11 {
    margin-left: 2.75rem !important;
  }

  .ms-xl-12 {
    margin-left: 3rem !important;
  }

  .ms-xl-13 {
    margin-left: 3.25rem !important;
  }

  .ms-xl-14 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-15 {
    margin-left: 3.75rem !important;
  }

  .ms-xl-16 {
    margin-left: 4rem !important;
  }

  .ms-xl-17 {
    margin-left: 4.25rem !important;
  }

  .ms-xl-18 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-19 {
    margin-left: 4.75rem !important;
  }

  .ms-xl-20 {
    margin-left: 5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -0.75rem !important;
  }

  .m-xl-n4 {
    margin: -1rem !important;
  }

  .m-xl-n5 {
    margin: -1.25rem !important;
  }

  .m-xl-n6 {
    margin: -1.5rem !important;
  }

  .m-xl-n7 {
    margin: -1.75rem !important;
  }

  .m-xl-n8 {
    margin: -2rem !important;
  }

  .m-xl-n9 {
    margin: -2.25rem !important;
  }

  .m-xl-n10 {
    margin: -2.5rem !important;
  }

  .m-xl-n11 {
    margin: -2.75rem !important;
  }

  .m-xl-n12 {
    margin: -3rem !important;
  }

  .m-xl-n13 {
    margin: -3.25rem !important;
  }

  .m-xl-n14 {
    margin: -3.5rem !important;
  }

  .m-xl-n15 {
    margin: -3.75rem !important;
  }

  .m-xl-n16 {
    margin: -4rem !important;
  }

  .m-xl-n17 {
    margin: -4.25rem !important;
  }

  .m-xl-n18 {
    margin: -4.5rem !important;
  }

  .m-xl-n19 {
    margin: -4.75rem !important;
  }

  .m-xl-n20 {
    margin: -5rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-xl-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xl-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-xl-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xl-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-xl-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xl-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-xl-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xl-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-xl-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xl-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-xl-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-xl-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-xl-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-xl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-xl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xl-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-xl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-xl-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xl-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-xl-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xl-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-xl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-xl-n12 {
    margin-top: -3rem !important;
  }

  .mt-xl-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-xl-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-xl-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-xl-n16 {
    margin-top: -4rem !important;
  }

  .mt-xl-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-xl-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-xl-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-xl-n20 {
    margin-top: -5rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -0.75rem !important;
  }

  .me-xl-n4 {
    margin-right: -1rem !important;
  }

  .me-xl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xl-n8 {
    margin-right: -2rem !important;
  }

  .me-xl-n9 {
    margin-right: -2.25rem !important;
  }

  .me-xl-n10 {
    margin-right: -2.5rem !important;
  }

  .me-xl-n11 {
    margin-right: -2.75rem !important;
  }

  .me-xl-n12 {
    margin-right: -3rem !important;
  }

  .me-xl-n13 {
    margin-right: -3.25rem !important;
  }

  .me-xl-n14 {
    margin-right: -3.5rem !important;
  }

  .me-xl-n15 {
    margin-right: -3.75rem !important;
  }

  .me-xl-n16 {
    margin-right: -4rem !important;
  }

  .me-xl-n17 {
    margin-right: -4.25rem !important;
  }

  .me-xl-n18 {
    margin-right: -4.5rem !important;
  }

  .me-xl-n19 {
    margin-right: -4.75rem !important;
  }

  .me-xl-n20 {
    margin-right: -5rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-xl-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xl-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-xl-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-xl-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-xl-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xl-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-xl-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xl-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-xl-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-xl-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-xl-n12 {
    margin-left: -3rem !important;
  }

  .ms-xl-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-xl-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-xl-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-xl-n16 {
    margin-left: -4rem !important;
  }

  .ms-xl-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-xl-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-xl-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-xl-n20 {
    margin-left: -5rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 0.75rem !important;
  }

  .p-xl-4 {
    padding: 1rem !important;
  }

  .p-xl-5 {
    padding: 1.25rem !important;
  }

  .p-xl-6 {
    padding: 1.5rem !important;
  }

  .p-xl-7 {
    padding: 1.75rem !important;
  }

  .p-xl-8 {
    padding: 2rem !important;
  }

  .p-xl-9 {
    padding: 2.25rem !important;
  }

  .p-xl-10 {
    padding: 2.5rem !important;
  }

  .p-xl-11 {
    padding: 2.75rem !important;
  }

  .p-xl-12 {
    padding: 3rem !important;
  }

  .p-xl-13 {
    padding: 3.25rem !important;
  }

  .p-xl-14 {
    padding: 3.5rem !important;
  }

  .p-xl-15 {
    padding: 3.75rem !important;
  }

  .p-xl-16 {
    padding: 4rem !important;
  }

  .p-xl-17 {
    padding: 4.25rem !important;
  }

  .p-xl-18 {
    padding: 4.5rem !important;
  }

  .p-xl-19 {
    padding: 4.75rem !important;
  }

  .p-xl-20 {
    padding: 5rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-xl-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-xl-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-xl-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-xl-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xl-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-xl-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-xl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-xl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-xl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-xl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xl-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-xl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xl-4 {
    padding-top: 1rem !important;
  }

  .pt-xl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xl-8 {
    padding-top: 2rem !important;
  }

  .pt-xl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-11 {
    padding-top: 2.75rem !important;
  }

  .pt-xl-12 {
    padding-top: 3rem !important;
  }

  .pt-xl-13 {
    padding-top: 3.25rem !important;
  }

  .pt-xl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-15 {
    padding-top: 3.75rem !important;
  }

  .pt-xl-16 {
    padding-top: 4rem !important;
  }

  .pt-xl-17 {
    padding-top: 4.25rem !important;
  }

  .pt-xl-18 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-19 {
    padding-top: 4.75rem !important;
  }

  .pt-xl-20 {
    padding-top: 5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xl-4 {
    padding-right: 1rem !important;
  }

  .pe-xl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xl-8 {
    padding-right: 2rem !important;
  }

  .pe-xl-9 {
    padding-right: 2.25rem !important;
  }

  .pe-xl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-11 {
    padding-right: 2.75rem !important;
  }

  .pe-xl-12 {
    padding-right: 3rem !important;
  }

  .pe-xl-13 {
    padding-right: 3.25rem !important;
  }

  .pe-xl-14 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-15 {
    padding-right: 3.75rem !important;
  }

  .pe-xl-16 {
    padding-right: 4rem !important;
  }

  .pe-xl-17 {
    padding-right: 4.25rem !important;
  }

  .pe-xl-18 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-19 {
    padding-right: 4.75rem !important;
  }

  .pe-xl-20 {
    padding-right: 5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-xl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-xl-16 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-xl-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-xl-20 {
    padding-bottom: 5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xl-4 {
    padding-left: 1rem !important;
  }

  .ps-xl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xl-8 {
    padding-left: 2rem !important;
  }

  .ps-xl-9 {
    padding-left: 2.25rem !important;
  }

  .ps-xl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-11 {
    padding-left: 2.75rem !important;
  }

  .ps-xl-12 {
    padding-left: 3rem !important;
  }

  .ps-xl-13 {
    padding-left: 3.25rem !important;
  }

  .ps-xl-14 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-15 {
    padding-left: 3.75rem !important;
  }

  .ps-xl-16 {
    padding-left: 4rem !important;
  }

  .ps-xl-17 {
    padding-left: 4.25rem !important;
  }

  .ps-xl-18 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-19 {
    padding-left: 4.75rem !important;
  }

  .ps-xl-20 {
    padding-left: 5rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 0.75rem !important;
  }

  .gap-xl-4 {
    gap: 1rem !important;
  }

  .gap-xl-5 {
    gap: 1.25rem !important;
  }

  .gap-xl-6 {
    gap: 1.5rem !important;
  }

  .gap-xl-7 {
    gap: 1.75rem !important;
  }

  .gap-xl-8 {
    gap: 2rem !important;
  }

  .gap-xl-9 {
    gap: 2.25rem !important;
  }

  .gap-xl-10 {
    gap: 2.5rem !important;
  }

  .gap-xl-11 {
    gap: 2.75rem !important;
  }

  .gap-xl-12 {
    gap: 3rem !important;
  }

  .gap-xl-13 {
    gap: 3.25rem !important;
  }

  .gap-xl-14 {
    gap: 3.5rem !important;
  }

  .gap-xl-15 {
    gap: 3.75rem !important;
  }

  .gap-xl-16 {
    gap: 4rem !important;
  }

  .gap-xl-17 {
    gap: 4.25rem !important;
  }

  .gap-xl-18 {
    gap: 4.5rem !important;
  }

  .gap-xl-19 {
    gap: 4.75rem !important;
  }

  .gap-xl-20 {
    gap: 5rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-xl-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-xl-8 {
    row-gap: 2rem !important;
  }

  .row-gap-xl-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-xl-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-xl-11 {
    row-gap: 2.75rem !important;
  }

  .row-gap-xl-12 {
    row-gap: 3rem !important;
  }

  .row-gap-xl-13 {
    row-gap: 3.25rem !important;
  }

  .row-gap-xl-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-xl-15 {
    row-gap: 3.75rem !important;
  }

  .row-gap-xl-16 {
    row-gap: 4rem !important;
  }

  .row-gap-xl-17 {
    row-gap: 4.25rem !important;
  }

  .row-gap-xl-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-xl-19 {
    row-gap: 4.75rem !important;
  }

  .row-gap-xl-20 {
    row-gap: 5rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-xl-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-xl-8 {
    column-gap: 2rem !important;
  }

  .column-gap-xl-9 {
    column-gap: 2.25rem !important;
  }

  .column-gap-xl-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-xl-11 {
    column-gap: 2.75rem !important;
  }

  .column-gap-xl-12 {
    column-gap: 3rem !important;
  }

  .column-gap-xl-13 {
    column-gap: 3.25rem !important;
  }

  .column-gap-xl-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-xl-15 {
    column-gap: 3.75rem !important;
  }

  .column-gap-xl-16 {
    column-gap: 4rem !important;
  }

  .column-gap-xl-17 {
    column-gap: 4.25rem !important;
  }

  .column-gap-xl-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-xl-19 {
    column-gap: 4.75rem !important;
  }

  .column-gap-xl-20 {
    column-gap: 5rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 0.75rem !important;
  }

  .m-xxl-4 {
    margin: 1rem !important;
  }

  .m-xxl-5 {
    margin: 1.25rem !important;
  }

  .m-xxl-6 {
    margin: 1.5rem !important;
  }

  .m-xxl-7 {
    margin: 1.75rem !important;
  }

  .m-xxl-8 {
    margin: 2rem !important;
  }

  .m-xxl-9 {
    margin: 2.25rem !important;
  }

  .m-xxl-10 {
    margin: 2.5rem !important;
  }

  .m-xxl-11 {
    margin: 2.75rem !important;
  }

  .m-xxl-12 {
    margin: 3rem !important;
  }

  .m-xxl-13 {
    margin: 3.25rem !important;
  }

  .m-xxl-14 {
    margin: 3.5rem !important;
  }

  .m-xxl-15 {
    margin: 3.75rem !important;
  }

  .m-xxl-16 {
    margin: 4rem !important;
  }

  .m-xxl-17 {
    margin: 4.25rem !important;
  }

  .m-xxl-18 {
    margin: 4.5rem !important;
  }

  .m-xxl-19 {
    margin: 4.75rem !important;
  }

  .m-xxl-20 {
    margin: 5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xxl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xxl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xxl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-xxl-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-xxl-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xxl-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-xxl-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-xxl-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxl-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-xxl-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xxl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xxl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xxl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xxl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-xxl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-xxl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-xxl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-xxl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-xxl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1rem !important;
  }

  .mt-xxl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xxl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xxl-8 {
    margin-top: 2rem !important;
  }

  .mt-xxl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xxl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-11 {
    margin-top: 2.75rem !important;
  }

  .mt-xxl-12 {
    margin-top: 3rem !important;
  }

  .mt-xxl-13 {
    margin-top: 3.25rem !important;
  }

  .mt-xxl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-15 {
    margin-top: 3.75rem !important;
  }

  .mt-xxl-16 {
    margin-top: 4rem !important;
  }

  .mt-xxl-17 {
    margin-top: 4.25rem !important;
  }

  .mt-xxl-18 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-19 {
    margin-top: 4.75rem !important;
  }

  .mt-xxl-20 {
    margin-top: 5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xxl-4 {
    margin-right: 1rem !important;
  }

  .me-xxl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xxl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xxl-8 {
    margin-right: 2rem !important;
  }

  .me-xxl-9 {
    margin-right: 2.25rem !important;
  }

  .me-xxl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-11 {
    margin-right: 2.75rem !important;
  }

  .me-xxl-12 {
    margin-right: 3rem !important;
  }

  .me-xxl-13 {
    margin-right: 3.25rem !important;
  }

  .me-xxl-14 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-15 {
    margin-right: 3.75rem !important;
  }

  .me-xxl-16 {
    margin-right: 4rem !important;
  }

  .me-xxl-17 {
    margin-right: 4.25rem !important;
  }

  .me-xxl-18 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-19 {
    margin-right: 4.75rem !important;
  }

  .me-xxl-20 {
    margin-right: 5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-xxl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-xxl-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-xxl-20 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1rem !important;
  }

  .ms-xxl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xxl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xxl-8 {
    margin-left: 2rem !important;
  }

  .ms-xxl-9 {
    margin-left: 2.25rem !important;
  }

  .ms-xxl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-11 {
    margin-left: 2.75rem !important;
  }

  .ms-xxl-12 {
    margin-left: 3rem !important;
  }

  .ms-xxl-13 {
    margin-left: 3.25rem !important;
  }

  .ms-xxl-14 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-15 {
    margin-left: 3.75rem !important;
  }

  .ms-xxl-16 {
    margin-left: 4rem !important;
  }

  .ms-xxl-17 {
    margin-left: 4.25rem !important;
  }

  .ms-xxl-18 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-19 {
    margin-left: 4.75rem !important;
  }

  .ms-xxl-20 {
    margin-left: 5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -0.75rem !important;
  }

  .m-xxl-n4 {
    margin: -1rem !important;
  }

  .m-xxl-n5 {
    margin: -1.25rem !important;
  }

  .m-xxl-n6 {
    margin: -1.5rem !important;
  }

  .m-xxl-n7 {
    margin: -1.75rem !important;
  }

  .m-xxl-n8 {
    margin: -2rem !important;
  }

  .m-xxl-n9 {
    margin: -2.25rem !important;
  }

  .m-xxl-n10 {
    margin: -2.5rem !important;
  }

  .m-xxl-n11 {
    margin: -2.75rem !important;
  }

  .m-xxl-n12 {
    margin: -3rem !important;
  }

  .m-xxl-n13 {
    margin: -3.25rem !important;
  }

  .m-xxl-n14 {
    margin: -3.5rem !important;
  }

  .m-xxl-n15 {
    margin: -3.75rem !important;
  }

  .m-xxl-n16 {
    margin: -4rem !important;
  }

  .m-xxl-n17 {
    margin: -4.25rem !important;
  }

  .m-xxl-n18 {
    margin: -4.5rem !important;
  }

  .m-xxl-n19 {
    margin: -4.75rem !important;
  }

  .m-xxl-n20 {
    margin: -5rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xxl-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-xxl-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xxl-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-xxl-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xxl-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-xxl-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xxl-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-xxl-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-xxl-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-xxl-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xxl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xxl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xxl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xxl-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-xxl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-xxl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xxl-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-xxl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-xxl-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xxl-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-xxl-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xxl-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-xxl-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-xxl-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-xxl-n16 {
    margin-top: -4rem !important;
  }

  .mt-xxl-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-xxl-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-xxl-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-xxl-n20 {
    margin-top: -5rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -0.75rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1rem !important;
  }

  .me-xxl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xxl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xxl-n8 {
    margin-right: -2rem !important;
  }

  .me-xxl-n9 {
    margin-right: -2.25rem !important;
  }

  .me-xxl-n10 {
    margin-right: -2.5rem !important;
  }

  .me-xxl-n11 {
    margin-right: -2.75rem !important;
  }

  .me-xxl-n12 {
    margin-right: -3rem !important;
  }

  .me-xxl-n13 {
    margin-right: -3.25rem !important;
  }

  .me-xxl-n14 {
    margin-right: -3.5rem !important;
  }

  .me-xxl-n15 {
    margin-right: -3.75rem !important;
  }

  .me-xxl-n16 {
    margin-right: -4rem !important;
  }

  .me-xxl-n17 {
    margin-right: -4.25rem !important;
  }

  .me-xxl-n18 {
    margin-right: -4.5rem !important;
  }

  .me-xxl-n19 {
    margin-right: -4.75rem !important;
  }

  .me-xxl-n20 {
    margin-right: -5rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xxl-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-xxl-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xxl-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-xxl-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-xxl-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xxl-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-xxl-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-xxl-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-xxl-n12 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-xxl-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-xxl-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-xxl-n16 {
    margin-left: -4rem !important;
  }

  .ms-xxl-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-xxl-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-xxl-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-xxl-n20 {
    margin-left: -5rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 0.75rem !important;
  }

  .p-xxl-4 {
    padding: 1rem !important;
  }

  .p-xxl-5 {
    padding: 1.25rem !important;
  }

  .p-xxl-6 {
    padding: 1.5rem !important;
  }

  .p-xxl-7 {
    padding: 1.75rem !important;
  }

  .p-xxl-8 {
    padding: 2rem !important;
  }

  .p-xxl-9 {
    padding: 2.25rem !important;
  }

  .p-xxl-10 {
    padding: 2.5rem !important;
  }

  .p-xxl-11 {
    padding: 2.75rem !important;
  }

  .p-xxl-12 {
    padding: 3rem !important;
  }

  .p-xxl-13 {
    padding: 3.25rem !important;
  }

  .p-xxl-14 {
    padding: 3.5rem !important;
  }

  .p-xxl-15 {
    padding: 3.75rem !important;
  }

  .p-xxl-16 {
    padding: 4rem !important;
  }

  .p-xxl-17 {
    padding: 4.25rem !important;
  }

  .p-xxl-18 {
    padding: 4.5rem !important;
  }

  .p-xxl-19 {
    padding: 4.75rem !important;
  }

  .p-xxl-20 {
    padding: 5rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xxl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xxl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xxl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xxl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xxl-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-xxl-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-xxl-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xxl-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-xxl-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-xxl-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xxl-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-xxl-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xxl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xxl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xxl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xxl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-xxl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-xxl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-xxl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-xxl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xxl-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-xxl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1rem !important;
  }

  .pt-xxl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xxl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xxl-8 {
    padding-top: 2rem !important;
  }

  .pt-xxl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xxl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-11 {
    padding-top: 2.75rem !important;
  }

  .pt-xxl-12 {
    padding-top: 3rem !important;
  }

  .pt-xxl-13 {
    padding-top: 3.25rem !important;
  }

  .pt-xxl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-15 {
    padding-top: 3.75rem !important;
  }

  .pt-xxl-16 {
    padding-top: 4rem !important;
  }

  .pt-xxl-17 {
    padding-top: 4.25rem !important;
  }

  .pt-xxl-18 {
    padding-top: 4.5rem !important;
  }

  .pt-xxl-19 {
    padding-top: 4.75rem !important;
  }

  .pt-xxl-20 {
    padding-top: 5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1rem !important;
  }

  .pe-xxl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xxl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xxl-8 {
    padding-right: 2rem !important;
  }

  .pe-xxl-9 {
    padding-right: 2.25rem !important;
  }

  .pe-xxl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-11 {
    padding-right: 2.75rem !important;
  }

  .pe-xxl-12 {
    padding-right: 3rem !important;
  }

  .pe-xxl-13 {
    padding-right: 3.25rem !important;
  }

  .pe-xxl-14 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-15 {
    padding-right: 3.75rem !important;
  }

  .pe-xxl-16 {
    padding-right: 4rem !important;
  }

  .pe-xxl-17 {
    padding-right: 4.25rem !important;
  }

  .pe-xxl-18 {
    padding-right: 4.5rem !important;
  }

  .pe-xxl-19 {
    padding-right: 4.75rem !important;
  }

  .pe-xxl-20 {
    padding-right: 5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-xxl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-xxl-16 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-xxl-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxl-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-xxl-20 {
    padding-bottom: 5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1rem !important;
  }

  .ps-xxl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xxl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xxl-8 {
    padding-left: 2rem !important;
  }

  .ps-xxl-9 {
    padding-left: 2.25rem !important;
  }

  .ps-xxl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-11 {
    padding-left: 2.75rem !important;
  }

  .ps-xxl-12 {
    padding-left: 3rem !important;
  }

  .ps-xxl-13 {
    padding-left: 3.25rem !important;
  }

  .ps-xxl-14 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-15 {
    padding-left: 3.75rem !important;
  }

  .ps-xxl-16 {
    padding-left: 4rem !important;
  }

  .ps-xxl-17 {
    padding-left: 4.25rem !important;
  }

  .ps-xxl-18 {
    padding-left: 4.5rem !important;
  }

  .ps-xxl-19 {
    padding-left: 4.75rem !important;
  }

  .ps-xxl-20 {
    padding-left: 5rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 0.75rem !important;
  }

  .gap-xxl-4 {
    gap: 1rem !important;
  }

  .gap-xxl-5 {
    gap: 1.25rem !important;
  }

  .gap-xxl-6 {
    gap: 1.5rem !important;
  }

  .gap-xxl-7 {
    gap: 1.75rem !important;
  }

  .gap-xxl-8 {
    gap: 2rem !important;
  }

  .gap-xxl-9 {
    gap: 2.25rem !important;
  }

  .gap-xxl-10 {
    gap: 2.5rem !important;
  }

  .gap-xxl-11 {
    gap: 2.75rem !important;
  }

  .gap-xxl-12 {
    gap: 3rem !important;
  }

  .gap-xxl-13 {
    gap: 3.25rem !important;
  }

  .gap-xxl-14 {
    gap: 3.5rem !important;
  }

  .gap-xxl-15 {
    gap: 3.75rem !important;
  }

  .gap-xxl-16 {
    gap: 4rem !important;
  }

  .gap-xxl-17 {
    gap: 4.25rem !important;
  }

  .gap-xxl-18 {
    gap: 4.5rem !important;
  }

  .gap-xxl-19 {
    gap: 4.75rem !important;
  }

  .gap-xxl-20 {
    gap: 5rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-xxl-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-xxl-8 {
    row-gap: 2rem !important;
  }

  .row-gap-xxl-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-xxl-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-xxl-11 {
    row-gap: 2.75rem !important;
  }

  .row-gap-xxl-12 {
    row-gap: 3rem !important;
  }

  .row-gap-xxl-13 {
    row-gap: 3.25rem !important;
  }

  .row-gap-xxl-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-xxl-15 {
    row-gap: 3.75rem !important;
  }

  .row-gap-xxl-16 {
    row-gap: 4rem !important;
  }

  .row-gap-xxl-17 {
    row-gap: 4.25rem !important;
  }

  .row-gap-xxl-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-xxl-19 {
    row-gap: 4.75rem !important;
  }

  .row-gap-xxl-20 {
    row-gap: 5rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-xxl-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-xxl-8 {
    column-gap: 2rem !important;
  }

  .column-gap-xxl-9 {
    column-gap: 2.25rem !important;
  }

  .column-gap-xxl-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-xxl-11 {
    column-gap: 2.75rem !important;
  }

  .column-gap-xxl-12 {
    column-gap: 3rem !important;
  }

  .column-gap-xxl-13 {
    column-gap: 3.25rem !important;
  }

  .column-gap-xxl-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-xxl-15 {
    column-gap: 3.75rem !important;
  }

  .column-gap-xxl-16 {
    column-gap: 4rem !important;
  }

  .column-gap-xxl-17 {
    column-gap: 4.25rem !important;
  }

  .column-gap-xxl-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-xxl-19 {
    column-gap: 4.75rem !important;
  }

  .column-gap-xxl-20 {
    column-gap: 5rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 1.75rem !important;
  }

  .fs-2 {
    font-size: 1.5rem !important;
  }

  .fs-3 {
    font-size: 1.35rem !important;
  }

  .fs-2x {
    font-size: 2rem !important;
  }

  .fs-2qx {
    font-size: 2.25rem !important;
  }

  .fs-2hx {
    font-size: 2.5rem !important;
  }

  .fs-2tx {
    font-size: 2.75rem !important;
  }

  .fs-3x {
    font-size: 3rem !important;
  }

  .fs-3qx {
    font-size: 3.25rem !important;
  }

  .fs-3hx {
    font-size: 3.5rem !important;
  }

  .fs-3tx {
    font-size: 3.75rem !important;
  }

  .fs-4x {
    font-size: 4rem !important;
  }

  .fs-4qx {
    font-size: 4.25rem !important;
  }

  .fs-4hx {
    font-size: 4.5rem !important;
  }

  .fs-4tx {
    font-size: 4.75rem !important;
  }

  .fs-5x {
    font-size: 5rem !important;
  }

  .fs-5qx {
    font-size: 5.25rem !important;
  }

  .fs-5hx {
    font-size: 5.5rem !important;
  }

  .fs-5tx {
    font-size: 5.75rem !important;
  }

  .fs-10x {
    font-size: 10rem !important;
  }

  .fs-10qx {
    font-size: 10.25rem !important;
  }

  .fs-10hx {
    font-size: 10.5rem !important;
  }

  .fs-10tx {
    font-size: 10.75rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item.center {
  z-index: 5;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: "color-white";
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: "color-gray";
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: "color-white";
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: "color-gray";
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-5 {
  opacity: 0.05 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-15 {
  opacity: 0.15 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-white {
  --bs-focus-ring-color: rgba(var(--bs-white-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-black {
  --bs-focus-ring-color: rgba(var(--bs-black-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-muted {
  --bs-focus-ring-color: rgba(var(--bs-muted-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-blue {
  --bs-focus-ring-color: rgba(var(--bs-blue-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-indigo {
  --bs-focus-ring-color: rgba(var(--bs-indigo-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-purple {
  --bs-focus-ring-color: rgba(var(--bs-purple-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-pink {
  --bs-focus-ring-color: rgba(var(--bs-pink-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-red {
  --bs-focus-ring-color: rgba(var(--bs-red-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-orange {
  --bs-focus-ring-color: rgba(var(--bs-orange-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-yellow {
  --bs-focus-ring-color: rgba(var(--bs-yellow-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-green {
  --bs-focus-ring-color: rgba(var(--bs-green-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-teal {
  --bs-focus-ring-color: rgba(var(--bs-teal-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-cyan {
  --bs-focus-ring-color: rgba(var(--bs-cyan-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray {
  --bs-focus-ring-color: rgba(var(--bs-gray-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-body-bg {
  --bs-focus-ring-color: rgba(var(--bs-body-bg-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-body-text {
  --bs-focus-ring-color: rgba(var(--bs-body-text-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-toolbar-bg {
  --bs-focus-ring-color: rgba(var(--bs-toolbar-bg-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-toolbar-text {
  --bs-focus-ring-color: rgba(var(--bs-toolbar-text-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-focus-bg {
  --bs-focus-ring-color: rgba(var(--bs-focus-bg-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-tabbar-text {
  --bs-focus-ring-color: rgba(var(--bs-tabbar-text-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-card-bg {
  --bs-focus-ring-color: rgba(var(--bs-card-bg-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-card-text {
  --bs-focus-ring-color: rgba(var(--bs-card-text-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-item-bg {
  --bs-focus-ring-color: rgba(var(--bs-item-bg-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-item-text {
  --bs-focus-ring-color: rgba(var(--bs-item-text-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-unset {
  top: unset !important;
}

.top-25 {
  top: 25% !important;
}

.top-50 {
  top: 50% !important;
}

.top-75 {
  top: 75% !important;
}

.top-100 {
  top: 100% !important;
}

.top-auto {
  top: auto !important;
}

.top-1px {
  top: 1px !important;
}

.top-2px {
  top: 2px !important;
}

.top-3px {
  top: 3px !important;
}

.top-4px {
  top: 4px !important;
}

.top-5px {
  top: 5px !important;
}

.top-6px {
  top: 6px !important;
}

.top-7px {
  top: 7px !important;
}

.top-8px {
  top: 8px !important;
}

.top-9px {
  top: 9px !important;
}

.top-10px {
  top: 10px !important;
}

.top-15px {
  top: 15px !important;
}

.top-20px {
  top: 20px !important;
}

.top-25px {
  top: 25px !important;
}

.top-30px {
  top: 30px !important;
}

.top-35px {
  top: 35px !important;
}

.top-40px {
  top: 40px !important;
}

.top-45px {
  top: 45px !important;
}

.top-50px {
  top: 50px !important;
}

.top-55px {
  top: 55px !important;
}

.top-60px {
  top: 60px !important;
}

.top-65px {
  top: 65px !important;
}

.top-70px {
  top: 70px !important;
}

.top-75px {
  top: 75px !important;
}

.top-80px {
  top: 80px !important;
}

.top-85px {
  top: 85px !important;
}

.top-90px {
  top: 90px !important;
}

.top-95px {
  top: 95px !important;
}

.top-100px {
  top: 100px !important;
}

.top-125px {
  top: 125px !important;
}

.top-150px {
  top: 150px !important;
}

.top-175px {
  top: 175px !important;
}

.top-200px {
  top: 200px !important;
}

.top-225px {
  top: 225px !important;
}

.top-250px {
  top: 250px !important;
}

.top-275px {
  top: 275px !important;
}

.top-300px {
  top: 300px !important;
}

.top-325px {
  top: 325px !important;
}

.top-350px {
  top: 350px !important;
}

.top-375px {
  top: 375px !important;
}

.top-400px {
  top: 400px !important;
}

.top-425px {
  top: 425px !important;
}

.top-450px {
  top: 450px !important;
}

.top-475px {
  top: 475px !important;
}

.top-500px {
  top: 500px !important;
}

.top-550px {
  top: 550px !important;
}

.top-600px {
  top: 600px !important;
}

.top-650px {
  top: 650px !important;
}

.top-700px {
  top: 700px !important;
}

.top-750px {
  top: 750px !important;
}

.top-800px {
  top: 800px !important;
}

.top-850px {
  top: 850px !important;
}

.top-900px {
  top: 900px !important;
}

.top-950px {
  top: 950px !important;
}

.top-1000px {
  top: 1000px !important;
}

.bottom-unset {
  bottom: unset !important;
}

.bottom-25 {
  bottom: 25% !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-75 {
  bottom: 75% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.bottom-auto {
  bottom: auto !important;
}

.bottom-1px {
  bottom: 1px !important;
}

.bottom-2px {
  bottom: 2px !important;
}

.bottom-3px {
  bottom: 3px !important;
}

.bottom-4px {
  bottom: 4px !important;
}

.bottom-5px {
  bottom: 5px !important;
}

.bottom-6px {
  bottom: 6px !important;
}

.bottom-7px {
  bottom: 7px !important;
}

.bottom-8px {
  bottom: 8px !important;
}

.bottom-9px {
  bottom: 9px !important;
}

.bottom-10px {
  bottom: 10px !important;
}

.bottom-15px {
  bottom: 15px !important;
}

.bottom-20px {
  bottom: 20px !important;
}

.bottom-25px {
  bottom: 25px !important;
}

.bottom-30px {
  bottom: 30px !important;
}

.bottom-35px {
  bottom: 35px !important;
}

.bottom-40px {
  bottom: 40px !important;
}

.bottom-45px {
  bottom: 45px !important;
}

.bottom-50px {
  bottom: 50px !important;
}

.bottom-55px {
  bottom: 55px !important;
}

.bottom-60px {
  bottom: 60px !important;
}

.bottom-65px {
  bottom: 65px !important;
}

.bottom-70px {
  bottom: 70px !important;
}

.bottom-75px {
  bottom: 75px !important;
}

.bottom-80px {
  bottom: 80px !important;
}

.bottom-85px {
  bottom: 85px !important;
}

.bottom-90px {
  bottom: 90px !important;
}

.bottom-95px {
  bottom: 95px !important;
}

.bottom-100px {
  bottom: 100px !important;
}

.bottom-125px {
  bottom: 125px !important;
}

.bottom-150px {
  bottom: 150px !important;
}

.bottom-175px {
  bottom: 175px !important;
}

.bottom-200px {
  bottom: 200px !important;
}

.bottom-225px {
  bottom: 225px !important;
}

.bottom-250px {
  bottom: 250px !important;
}

.bottom-275px {
  bottom: 275px !important;
}

.bottom-300px {
  bottom: 300px !important;
}

.bottom-325px {
  bottom: 325px !important;
}

.bottom-350px {
  bottom: 350px !important;
}

.bottom-375px {
  bottom: 375px !important;
}

.bottom-400px {
  bottom: 400px !important;
}

.bottom-425px {
  bottom: 425px !important;
}

.bottom-450px {
  bottom: 450px !important;
}

.bottom-475px {
  bottom: 475px !important;
}

.bottom-500px {
  bottom: 500px !important;
}

.bottom-550px {
  bottom: 550px !important;
}

.bottom-600px {
  bottom: 600px !important;
}

.bottom-650px {
  bottom: 650px !important;
}

.bottom-700px {
  bottom: 700px !important;
}

.bottom-750px {
  bottom: 750px !important;
}

.bottom-800px {
  bottom: 800px !important;
}

.bottom-850px {
  bottom: 850px !important;
}

.bottom-900px {
  bottom: 900px !important;
}

.bottom-950px {
  bottom: 950px !important;
}

.bottom-1000px {
  bottom: 1000px !important;
}

.start-unset {
  left: unset !important;
}

.start-25 {
  left: 25% !important;
}

.start-50 {
  left: 50% !important;
}

.start-75 {
  left: 75% !important;
}

.start-100 {
  left: 100% !important;
}

.start-auto {
  left: auto !important;
}

.start-1px {
  left: 1px !important;
}

.start-2px {
  left: 2px !important;
}

.start-3px {
  left: 3px !important;
}

.start-4px {
  left: 4px !important;
}

.start-5px {
  left: 5px !important;
}

.start-6px {
  left: 6px !important;
}

.start-7px {
  left: 7px !important;
}

.start-8px {
  left: 8px !important;
}

.start-9px {
  left: 9px !important;
}

.start-10px {
  left: 10px !important;
}

.start-15px {
  left: 15px !important;
}

.start-20px {
  left: 20px !important;
}

.start-25px {
  left: 25px !important;
}

.start-30px {
  left: 30px !important;
}

.start-35px {
  left: 35px !important;
}

.start-40px {
  left: 40px !important;
}

.start-45px {
  left: 45px !important;
}

.start-50px {
  left: 50px !important;
}

.start-55px {
  left: 55px !important;
}

.start-60px {
  left: 60px !important;
}

.start-65px {
  left: 65px !important;
}

.start-70px {
  left: 70px !important;
}

.start-75px {
  left: 75px !important;
}

.start-80px {
  left: 80px !important;
}

.start-85px {
  left: 85px !important;
}

.start-90px {
  left: 90px !important;
}

.start-95px {
  left: 95px !important;
}

.start-100px {
  left: 100px !important;
}

.start-125px {
  left: 125px !important;
}

.start-150px {
  left: 150px !important;
}

.start-175px {
  left: 175px !important;
}

.start-200px {
  left: 200px !important;
}

.start-225px {
  left: 225px !important;
}

.start-250px {
  left: 250px !important;
}

.start-275px {
  left: 275px !important;
}

.start-300px {
  left: 300px !important;
}

.start-325px {
  left: 325px !important;
}

.start-350px {
  left: 350px !important;
}

.start-375px {
  left: 375px !important;
}

.start-400px {
  left: 400px !important;
}

.start-425px {
  left: 425px !important;
}

.start-450px {
  left: 450px !important;
}

.start-475px {
  left: 475px !important;
}

.start-500px {
  left: 500px !important;
}

.start-550px {
  left: 550px !important;
}

.start-600px {
  left: 600px !important;
}

.start-650px {
  left: 650px !important;
}

.start-700px {
  left: 700px !important;
}

.start-750px {
  left: 750px !important;
}

.start-800px {
  left: 800px !important;
}

.start-850px {
  left: 850px !important;
}

.start-900px {
  left: 900px !important;
}

.start-950px {
  left: 950px !important;
}

.start-1000px {
  left: 1000px !important;
}

.end-unset {
  right: unset !important;
}

.end-25 {
  right: 25% !important;
}

.end-50 {
  right: 50% !important;
}

.end-75 {
  right: 75% !important;
}

.end-100 {
  right: 100% !important;
}

.end-auto {
  right: auto !important;
}

.end-1px {
  right: 1px !important;
}

.end-2px {
  right: 2px !important;
}

.end-3px {
  right: 3px !important;
}

.end-4px {
  right: 4px !important;
}

.end-5px {
  right: 5px !important;
}

.end-6px {
  right: 6px !important;
}

.end-7px {
  right: 7px !important;
}

.end-8px {
  right: 8px !important;
}

.end-9px {
  right: 9px !important;
}

.end-10px {
  right: 10px !important;
}

.end-15px {
  right: 15px !important;
}

.end-20px {
  right: 20px !important;
}

.end-25px {
  right: 25px !important;
}

.end-30px {
  right: 30px !important;
}

.end-35px {
  right: 35px !important;
}

.end-40px {
  right: 40px !important;
}

.end-45px {
  right: 45px !important;
}

.end-50px {
  right: 50px !important;
}

.end-55px {
  right: 55px !important;
}

.end-60px {
  right: 60px !important;
}

.end-65px {
  right: 65px !important;
}

.end-70px {
  right: 70px !important;
}

.end-75px {
  right: 75px !important;
}

.end-80px {
  right: 80px !important;
}

.end-85px {
  right: 85px !important;
}

.end-90px {
  right: 90px !important;
}

.end-95px {
  right: 95px !important;
}

.end-100px {
  right: 100px !important;
}

.end-125px {
  right: 125px !important;
}

.end-150px {
  right: 150px !important;
}

.end-175px {
  right: 175px !important;
}

.end-200px {
  right: 200px !important;
}

.end-225px {
  right: 225px !important;
}

.end-250px {
  right: 250px !important;
}

.end-275px {
  right: 275px !important;
}

.end-300px {
  right: 300px !important;
}

.end-325px {
  right: 325px !important;
}

.end-350px {
  right: 350px !important;
}

.end-375px {
  right: 375px !important;
}

.end-400px {
  right: 400px !important;
}

.end-425px {
  right: 425px !important;
}

.end-450px {
  right: 450px !important;
}

.end-475px {
  right: 475px !important;
}

.end-500px {
  right: 500px !important;
}

.end-550px {
  right: 550px !important;
}

.end-600px {
  right: 600px !important;
}

.end-650px {
  right: 650px !important;
}

.end-700px {
  right: 700px !important;
}

.end-750px {
  right: 750px !important;
}

.end-800px {
  right: 800px !important;
}

.end-850px {
  right: 850px !important;
}

.end-900px {
  right: 900px !important;
}

.end-950px {
  right: 950px !important;
}

.end-1000px {
  right: 1000px !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-muted {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-muted-rgb), var(--bs-border-opacity)) !important;
}

.border-blue {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-rgb), var(--bs-border-opacity)) !important;
}

.border-indigo {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-indigo-rgb), var(--bs-border-opacity)) !important;
}

.border-purple {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-rgb), var(--bs-border-opacity)) !important;
}

.border-pink {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-rgb), var(--bs-border-opacity)) !important;
}

.border-red {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-rgb), var(--bs-border-opacity)) !important;
}

.border-orange {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-rgb), var(--bs-border-opacity)) !important;
}

.border-green {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-rgb), var(--bs-border-opacity)) !important;
}

.border-teal {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-rgb), var(--bs-border-opacity)) !important;
}

.border-cyan {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-cyan-rgb), var(--bs-border-opacity)) !important;
}

.border-gray {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-rgb), var(--bs-border-opacity)) !important;
}

.border-body-bg {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-body-bg-rgb), var(--bs-border-opacity)) !important;
}

.border-body-text {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-body-text-rgb), var(--bs-border-opacity)) !important;
}

.border-toolbar-bg {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-toolbar-bg-rgb), var(--bs-border-opacity)) !important;
}

.border-toolbar-text {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-toolbar-text-rgb), var(--bs-border-opacity)) !important;
}

.border-focus-bg {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-focus-bg-rgb), var(--bs-border-opacity)) !important;
}

.border-tabbar-text {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tabbar-text-rgb), var(--bs-border-opacity)) !important;
}

.border-card-bg {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-card-bg-rgb), var(--bs-border-opacity)) !important;
}

.border-card-text {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-card-text-rgb), var(--bs-border-opacity)) !important;
}

.border-item-bg {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-item-bg-rgb), var(--bs-border-opacity)) !important;
}

.border-item-text {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-item-text-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-unset {
  width: unset !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-1px {
  width: 1px !important;
}

.w-2px {
  width: 2px !important;
}

.w-3px {
  width: 3px !important;
}

.w-4px {
  width: 4px !important;
}

.w-5px {
  width: 5px !important;
}

.w-6px {
  width: 6px !important;
}

.w-7px {
  width: 7px !important;
}

.w-8px {
  width: 8px !important;
}

.w-9px {
  width: 9px !important;
}

.w-10px {
  width: 10px !important;
}

.w-15px {
  width: 15px !important;
}

.w-20px {
  width: 20px !important;
}

.w-25px {
  width: 25px !important;
}

.w-30px {
  width: 30px !important;
}

.w-35px {
  width: 35px !important;
}

.w-40px {
  width: 40px !important;
}

.w-45px {
  width: 45px !important;
}

.w-50px {
  width: 50px !important;
}

.w-55px {
  width: 55px !important;
}

.w-60px {
  width: 60px !important;
}

.w-65px {
  width: 65px !important;
}

.w-70px {
  width: 70px !important;
}

.w-75px {
  width: 75px !important;
}

.w-80px {
  width: 80px !important;
}

.w-85px {
  width: 85px !important;
}

.w-90px {
  width: 90px !important;
}

.w-95px {
  width: 95px !important;
}

.w-100px {
  width: 100px !important;
}

.w-125px {
  width: 125px !important;
}

.w-150px {
  width: 150px !important;
}

.w-175px {
  width: 175px !important;
}

.w-200px {
  width: 200px !important;
}

.w-225px {
  width: 225px !important;
}

.w-250px {
  width: 250px !important;
}

.w-275px {
  width: 275px !important;
}

.w-300px {
  width: 300px !important;
}

.w-325px {
  width: 325px !important;
}

.w-350px {
  width: 350px !important;
}

.w-375px {
  width: 375px !important;
}

.w-400px {
  width: 400px !important;
}

.w-425px {
  width: 425px !important;
}

.w-450px {
  width: 450px !important;
}

.w-475px {
  width: 475px !important;
}

.w-500px {
  width: 500px !important;
}

.w-550px {
  width: 550px !important;
}

.w-600px {
  width: 600px !important;
}

.w-650px {
  width: 650px !important;
}

.w-700px {
  width: 700px !important;
}

.w-750px {
  width: 750px !important;
}

.w-800px {
  width: 800px !important;
}

.w-850px {
  width: 850px !important;
}

.w-900px {
  width: 900px !important;
}

.w-950px {
  width: 950px !important;
}

.w-1000px {
  width: 1000px !important;
}

.mw-unset {
  max-width: unset !important;
}

.mw-25 {
  max-width: 25% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mw-auto {
  max-width: auto !important;
}

.mw-1px {
  max-width: 1px !important;
}

.mw-2px {
  max-width: 2px !important;
}

.mw-3px {
  max-width: 3px !important;
}

.mw-4px {
  max-width: 4px !important;
}

.mw-5px {
  max-width: 5px !important;
}

.mw-6px {
  max-width: 6px !important;
}

.mw-7px {
  max-width: 7px !important;
}

.mw-8px {
  max-width: 8px !important;
}

.mw-9px {
  max-width: 9px !important;
}

.mw-10px {
  max-width: 10px !important;
}

.mw-15px {
  max-width: 15px !important;
}

.mw-20px {
  max-width: 20px !important;
}

.mw-25px {
  max-width: 25px !important;
}

.mw-30px {
  max-width: 30px !important;
}

.mw-35px {
  max-width: 35px !important;
}

.mw-40px {
  max-width: 40px !important;
}

.mw-45px {
  max-width: 45px !important;
}

.mw-50px {
  max-width: 50px !important;
}

.mw-55px {
  max-width: 55px !important;
}

.mw-60px {
  max-width: 60px !important;
}

.mw-65px {
  max-width: 65px !important;
}

.mw-70px {
  max-width: 70px !important;
}

.mw-75px {
  max-width: 75px !important;
}

.mw-80px {
  max-width: 80px !important;
}

.mw-85px {
  max-width: 85px !important;
}

.mw-90px {
  max-width: 90px !important;
}

.mw-95px {
  max-width: 95px !important;
}

.mw-100px {
  max-width: 100px !important;
}

.mw-125px {
  max-width: 125px !important;
}

.mw-150px {
  max-width: 150px !important;
}

.mw-175px {
  max-width: 175px !important;
}

.mw-200px {
  max-width: 200px !important;
}

.mw-225px {
  max-width: 225px !important;
}

.mw-250px {
  max-width: 250px !important;
}

.mw-275px {
  max-width: 275px !important;
}

.mw-300px {
  max-width: 300px !important;
}

.mw-325px {
  max-width: 325px !important;
}

.mw-350px {
  max-width: 350px !important;
}

.mw-375px {
  max-width: 375px !important;
}

.mw-400px {
  max-width: 400px !important;
}

.mw-425px {
  max-width: 425px !important;
}

.mw-450px {
  max-width: 450px !important;
}

.mw-475px {
  max-width: 475px !important;
}

.mw-500px {
  max-width: 500px !important;
}

.mw-550px {
  max-width: 550px !important;
}

.mw-600px {
  max-width: 600px !important;
}

.mw-650px {
  max-width: 650px !important;
}

.mw-700px {
  max-width: 700px !important;
}

.mw-750px {
  max-width: 750px !important;
}

.mw-800px {
  max-width: 800px !important;
}

.mw-850px {
  max-width: 850px !important;
}

.mw-900px {
  max-width: 900px !important;
}

.mw-950px {
  max-width: 950px !important;
}

.mw-1000px {
  max-width: 1000px !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-unset {
  height: unset !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-1px {
  height: 1px !important;
}

.h-2px {
  height: 2px !important;
}

.h-3px {
  height: 3px !important;
}

.h-4px {
  height: 4px !important;
}

.h-5px {
  height: 5px !important;
}

.h-6px {
  height: 6px !important;
}

.h-7px {
  height: 7px !important;
}

.h-8px {
  height: 8px !important;
}

.h-9px {
  height: 9px !important;
}

.h-10px {
  height: 10px !important;
}

.h-15px {
  height: 15px !important;
}

.h-20px {
  height: 20px !important;
}

.h-25px {
  height: 25px !important;
}

.h-30px {
  height: 30px !important;
}

.h-35px {
  height: 35px !important;
}

.h-40px {
  height: 40px !important;
}

.h-45px {
  height: 45px !important;
}

.h-50px {
  height: 50px !important;
}

.h-55px {
  height: 55px !important;
}

.h-60px {
  height: 60px !important;
}

.h-65px {
  height: 65px !important;
}

.h-70px {
  height: 70px !important;
}

.h-75px {
  height: 75px !important;
}

.h-80px {
  height: 80px !important;
}

.h-85px {
  height: 85px !important;
}

.h-90px {
  height: 90px !important;
}

.h-95px {
  height: 95px !important;
}

.h-100px {
  height: 100px !important;
}

.h-125px {
  height: 125px !important;
}

.h-150px {
  height: 150px !important;
}

.h-175px {
  height: 175px !important;
}

.h-200px {
  height: 200px !important;
}

.h-225px {
  height: 225px !important;
}

.h-250px {
  height: 250px !important;
}

.h-275px {
  height: 275px !important;
}

.h-300px {
  height: 300px !important;
}

.h-325px {
  height: 325px !important;
}

.h-350px {
  height: 350px !important;
}

.h-375px {
  height: 375px !important;
}

.h-400px {
  height: 400px !important;
}

.h-425px {
  height: 425px !important;
}

.h-450px {
  height: 450px !important;
}

.h-475px {
  height: 475px !important;
}

.h-500px {
  height: 500px !important;
}

.h-550px {
  height: 550px !important;
}

.h-600px {
  height: 600px !important;
}

.h-650px {
  height: 650px !important;
}

.h-700px {
  height: 700px !important;
}

.h-750px {
  height: 750px !important;
}

.h-800px {
  height: 800px !important;
}

.h-850px {
  height: 850px !important;
}

.h-900px {
  height: 900px !important;
}

.h-950px {
  height: 950px !important;
}

.h-1000px {
  height: 1000px !important;
}

.mh-unset {
  max-height: unset !important;
}

.mh-25 {
  max-height: 25% !important;
}

.mh-50 {
  max-height: 50% !important;
}

.mh-75 {
  max-height: 75% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.mh-auto {
  max-height: auto !important;
}

.mh-1px {
  max-height: 1px !important;
}

.mh-2px {
  max-height: 2px !important;
}

.mh-3px {
  max-height: 3px !important;
}

.mh-4px {
  max-height: 4px !important;
}

.mh-5px {
  max-height: 5px !important;
}

.mh-6px {
  max-height: 6px !important;
}

.mh-7px {
  max-height: 7px !important;
}

.mh-8px {
  max-height: 8px !important;
}

.mh-9px {
  max-height: 9px !important;
}

.mh-10px {
  max-height: 10px !important;
}

.mh-15px {
  max-height: 15px !important;
}

.mh-20px {
  max-height: 20px !important;
}

.mh-25px {
  max-height: 25px !important;
}

.mh-30px {
  max-height: 30px !important;
}

.mh-35px {
  max-height: 35px !important;
}

.mh-40px {
  max-height: 40px !important;
}

.mh-45px {
  max-height: 45px !important;
}

.mh-50px {
  max-height: 50px !important;
}

.mh-55px {
  max-height: 55px !important;
}

.mh-60px {
  max-height: 60px !important;
}

.mh-65px {
  max-height: 65px !important;
}

.mh-70px {
  max-height: 70px !important;
}

.mh-75px {
  max-height: 75px !important;
}

.mh-80px {
  max-height: 80px !important;
}

.mh-85px {
  max-height: 85px !important;
}

.mh-90px {
  max-height: 90px !important;
}

.mh-95px {
  max-height: 95px !important;
}

.mh-100px {
  max-height: 100px !important;
}

.mh-125px {
  max-height: 125px !important;
}

.mh-150px {
  max-height: 150px !important;
}

.mh-175px {
  max-height: 175px !important;
}

.mh-200px {
  max-height: 200px !important;
}

.mh-225px {
  max-height: 225px !important;
}

.mh-250px {
  max-height: 250px !important;
}

.mh-275px {
  max-height: 275px !important;
}

.mh-300px {
  max-height: 300px !important;
}

.mh-325px {
  max-height: 325px !important;
}

.mh-350px {
  max-height: 350px !important;
}

.mh-375px {
  max-height: 375px !important;
}

.mh-400px {
  max-height: 400px !important;
}

.mh-425px {
  max-height: 425px !important;
}

.mh-450px {
  max-height: 450px !important;
}

.mh-475px {
  max-height: 475px !important;
}

.mh-500px {
  max-height: 500px !important;
}

.mh-550px {
  max-height: 550px !important;
}

.mh-600px {
  max-height: 600px !important;
}

.mh-650px {
  max-height: 650px !important;
}

.mh-700px {
  max-height: 700px !important;
}

.mh-750px {
  max-height: 750px !important;
}

.mh-800px {
  max-height: 800px !important;
}

.mh-850px {
  max-height: 850px !important;
}

.mh-900px {
  max-height: 900px !important;
}

.mh-950px {
  max-height: 950px !important;
}

.mh-1000px {
  max-height: 1000px !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-11 {
  margin: 2.75rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.m-13 {
  margin: 3.25rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.m-15 {
  margin: 3.75rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.m-17 {
  margin: 4.25rem !important;
}

.m-18 {
  margin: 4.5rem !important;
}

.m-19 {
  margin: 4.75rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-4 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-5 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-6 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-7 {
  margin-right: 1.75rem !important;
  margin-left: 1.75rem !important;
}

.mx-8 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-9 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important;
}

.mx-10 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-11 {
  margin-right: 2.75rem !important;
  margin-left: 2.75rem !important;
}

.mx-12 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-13 {
  margin-right: 3.25rem !important;
  margin-left: 3.25rem !important;
}

.mx-14 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-15 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important;
}

.mx-16 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-17 {
  margin-right: 4.25rem !important;
  margin-left: 4.25rem !important;
}

.mx-18 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-19 {
  margin-right: 4.75rem !important;
  margin-left: 4.75rem !important;
}

.mx-20 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-9 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-11 {
  margin-top: 2.75rem !important;
  margin-bottom: 2.75rem !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-13 {
  margin-top: 3.25rem !important;
  margin-bottom: 3.25rem !important;
}

.my-14 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-15 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-17 {
  margin-top: 4.25rem !important;
  margin-bottom: 4.25rem !important;
}

.my-18 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-19 {
  margin-top: 4.75rem !important;
  margin-bottom: 4.75rem !important;
}

.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-9 {
  margin-top: 2.25rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-11 {
  margin-top: 2.75rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mt-13 {
  margin-top: 3.25rem !important;
}

.mt-14 {
  margin-top: 3.5rem !important;
}

.mt-15 {
  margin-top: 3.75rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mt-17 {
  margin-top: 4.25rem !important;
}

.mt-18 {
  margin-top: 4.5rem !important;
}

.mt-19 {
  margin-top: 4.75rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 0.75rem !important;
}

.me-4 {
  margin-right: 1rem !important;
}

.me-5 {
  margin-right: 1.25rem !important;
}

.me-6 {
  margin-right: 1.5rem !important;
}

.me-7 {
  margin-right: 1.75rem !important;
}

.me-8 {
  margin-right: 2rem !important;
}

.me-9 {
  margin-right: 2.25rem !important;
}

.me-10 {
  margin-right: 2.5rem !important;
}

.me-11 {
  margin-right: 2.75rem !important;
}

.me-12 {
  margin-right: 3rem !important;
}

.me-13 {
  margin-right: 3.25rem !important;
}

.me-14 {
  margin-right: 3.5rem !important;
}

.me-15 {
  margin-right: 3.75rem !important;
}

.me-16 {
  margin-right: 4rem !important;
}

.me-17 {
  margin-right: 4.25rem !important;
}

.me-18 {
  margin-right: 4.5rem !important;
}

.me-19 {
  margin-right: 4.75rem !important;
}

.me-20 {
  margin-right: 5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mb-9 {
  margin-bottom: 2.25rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-11 {
  margin-bottom: 2.75rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.mb-13 {
  margin-bottom: 3.25rem !important;
}

.mb-14 {
  margin-bottom: 3.5rem !important;
}

.mb-15 {
  margin-bottom: 3.75rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.mb-17 {
  margin-bottom: 4.25rem !important;
}

.mb-18 {
  margin-bottom: 4.5rem !important;
}

.mb-19 {
  margin-bottom: 4.75rem !important;
}

.mb-20 {
  margin-bottom: 5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 0.75rem !important;
}

.ms-4 {
  margin-left: 1rem !important;
}

.ms-5 {
  margin-left: 1.25rem !important;
}

.ms-6 {
  margin-left: 1.5rem !important;
}

.ms-7 {
  margin-left: 1.75rem !important;
}

.ms-8 {
  margin-left: 2rem !important;
}

.ms-9 {
  margin-left: 2.25rem !important;
}

.ms-10 {
  margin-left: 2.5rem !important;
}

.ms-11 {
  margin-left: 2.75rem !important;
}

.ms-12 {
  margin-left: 3rem !important;
}

.ms-13 {
  margin-left: 3.25rem !important;
}

.ms-14 {
  margin-left: 3.5rem !important;
}

.ms-15 {
  margin-left: 3.75rem !important;
}

.ms-16 {
  margin-left: 4rem !important;
}

.ms-17 {
  margin-left: 4.25rem !important;
}

.ms-18 {
  margin-left: 4.5rem !important;
}

.ms-19 {
  margin-left: 4.75rem !important;
}

.ms-20 {
  margin-left: 5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.m-n5 {
  margin: -1.25rem !important;
}

.m-n6 {
  margin: -1.5rem !important;
}

.m-n7 {
  margin: -1.75rem !important;
}

.m-n8 {
  margin: -2rem !important;
}

.m-n9 {
  margin: -2.25rem !important;
}

.m-n10 {
  margin: -2.5rem !important;
}

.m-n11 {
  margin: -2.75rem !important;
}

.m-n12 {
  margin: -3rem !important;
}

.m-n13 {
  margin: -3.25rem !important;
}

.m-n14 {
  margin: -3.5rem !important;
}

.m-n15 {
  margin: -3.75rem !important;
}

.m-n16 {
  margin: -4rem !important;
}

.m-n17 {
  margin: -4.25rem !important;
}

.m-n18 {
  margin: -4.5rem !important;
}

.m-n19 {
  margin: -4.75rem !important;
}

.m-n20 {
  margin: -5rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}

.mx-n4 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n5 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n6 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n7 {
  margin-right: -1.75rem !important;
  margin-left: -1.75rem !important;
}

.mx-n8 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n9 {
  margin-right: -2.25rem !important;
  margin-left: -2.25rem !important;
}

.mx-n10 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n11 {
  margin-right: -2.75rem !important;
  margin-left: -2.75rem !important;
}

.mx-n12 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n13 {
  margin-right: -3.25rem !important;
  margin-left: -3.25rem !important;
}

.mx-n14 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n15 {
  margin-right: -3.75rem !important;
  margin-left: -3.75rem !important;
}

.mx-n16 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n17 {
  margin-right: -4.25rem !important;
  margin-left: -4.25rem !important;
}

.mx-n18 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.mx-n19 {
  margin-right: -4.75rem !important;
  margin-left: -4.75rem !important;
}

.mx-n20 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.my-n4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n7 {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.my-n8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n9 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.my-n10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n11 {
  margin-top: -2.75rem !important;
  margin-bottom: -2.75rem !important;
}

.my-n12 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n13 {
  margin-top: -3.25rem !important;
  margin-bottom: -3.25rem !important;
}

.my-n14 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n15 {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important;
}

.my-n16 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n17 {
  margin-top: -4.25rem !important;
  margin-bottom: -4.25rem !important;
}

.my-n18 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.my-n19 {
  margin-top: -4.75rem !important;
  margin-bottom: -4.75rem !important;
}

.my-n20 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -0.75rem !important;
}

.mt-n4 {
  margin-top: -1rem !important;
}

.mt-n5 {
  margin-top: -1.25rem !important;
}

.mt-n6 {
  margin-top: -1.5rem !important;
}

.mt-n7 {
  margin-top: -1.75rem !important;
}

.mt-n8 {
  margin-top: -2rem !important;
}

.mt-n9 {
  margin-top: -2.25rem !important;
}

.mt-n10 {
  margin-top: -2.5rem !important;
}

.mt-n11 {
  margin-top: -2.75rem !important;
}

.mt-n12 {
  margin-top: -3rem !important;
}

.mt-n13 {
  margin-top: -3.25rem !important;
}

.mt-n14 {
  margin-top: -3.5rem !important;
}

.mt-n15 {
  margin-top: -3.75rem !important;
}

.mt-n16 {
  margin-top: -4rem !important;
}

.mt-n17 {
  margin-top: -4.25rem !important;
}

.mt-n18 {
  margin-top: -4.5rem !important;
}

.mt-n19 {
  margin-top: -4.75rem !important;
}

.mt-n20 {
  margin-top: -5rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -0.75rem !important;
}

.me-n4 {
  margin-right: -1rem !important;
}

.me-n5 {
  margin-right: -1.25rem !important;
}

.me-n6 {
  margin-right: -1.5rem !important;
}

.me-n7 {
  margin-right: -1.75rem !important;
}

.me-n8 {
  margin-right: -2rem !important;
}

.me-n9 {
  margin-right: -2.25rem !important;
}

.me-n10 {
  margin-right: -2.5rem !important;
}

.me-n11 {
  margin-right: -2.75rem !important;
}

.me-n12 {
  margin-right: -3rem !important;
}

.me-n13 {
  margin-right: -3.25rem !important;
}

.me-n14 {
  margin-right: -3.5rem !important;
}

.me-n15 {
  margin-right: -3.75rem !important;
}

.me-n16 {
  margin-right: -4rem !important;
}

.me-n17 {
  margin-right: -4.25rem !important;
}

.me-n18 {
  margin-right: -4.5rem !important;
}

.me-n19 {
  margin-right: -4.75rem !important;
}

.me-n20 {
  margin-right: -5rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -0.75rem !important;
}

.mb-n4 {
  margin-bottom: -1rem !important;
}

.mb-n5 {
  margin-bottom: -1.25rem !important;
}

.mb-n6 {
  margin-bottom: -1.5rem !important;
}

.mb-n7 {
  margin-bottom: -1.75rem !important;
}

.mb-n8 {
  margin-bottom: -2rem !important;
}

.mb-n9 {
  margin-bottom: -2.25rem !important;
}

.mb-n10 {
  margin-bottom: -2.5rem !important;
}

.mb-n11 {
  margin-bottom: -2.75rem !important;
}

.mb-n12 {
  margin-bottom: -3rem !important;
}

.mb-n13 {
  margin-bottom: -3.25rem !important;
}

.mb-n14 {
  margin-bottom: -3.5rem !important;
}

.mb-n15 {
  margin-bottom: -3.75rem !important;
}

.mb-n16 {
  margin-bottom: -4rem !important;
}

.mb-n17 {
  margin-bottom: -4.25rem !important;
}

.mb-n18 {
  margin-bottom: -4.5rem !important;
}

.mb-n19 {
  margin-bottom: -4.75rem !important;
}

.mb-n20 {
  margin-bottom: -5rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -0.75rem !important;
}

.ms-n4 {
  margin-left: -1rem !important;
}

.ms-n5 {
  margin-left: -1.25rem !important;
}

.ms-n6 {
  margin-left: -1.5rem !important;
}

.ms-n7 {
  margin-left: -1.75rem !important;
}

.ms-n8 {
  margin-left: -2rem !important;
}

.ms-n9 {
  margin-left: -2.25rem !important;
}

.ms-n10 {
  margin-left: -2.5rem !important;
}

.ms-n11 {
  margin-left: -2.75rem !important;
}

.ms-n12 {
  margin-left: -3rem !important;
}

.ms-n13 {
  margin-left: -3.25rem !important;
}

.ms-n14 {
  margin-left: -3.5rem !important;
}

.ms-n15 {
  margin-left: -3.75rem !important;
}

.ms-n16 {
  margin-left: -4rem !important;
}

.ms-n17 {
  margin-left: -4.25rem !important;
}

.ms-n18 {
  margin-left: -4.5rem !important;
}

.ms-n19 {
  margin-left: -4.75rem !important;
}

.ms-n20 {
  margin-left: -5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-11 {
  padding: 2.75rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.p-13 {
  padding: 3.25rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.p-15 {
  padding: 3.75rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.p-17 {
  padding: 4.25rem !important;
}

.p-18 {
  padding: 4.5rem !important;
}

.p-19 {
  padding: 4.75rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-5 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-6 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-7 {
  padding-right: 1.75rem !important;
  padding-left: 1.75rem !important;
}

.px-8 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-9 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}

.px-10 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-11 {
  padding-right: 2.75rem !important;
  padding-left: 2.75rem !important;
}

.px-12 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-13 {
  padding-right: 3.25rem !important;
  padding-left: 3.25rem !important;
}

.px-14 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-15 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important;
}

.px-16 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-17 {
  padding-right: 4.25rem !important;
  padding-left: 4.25rem !important;
}

.px-18 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-19 {
  padding-right: 4.75rem !important;
  padding-left: 4.75rem !important;
}

.px-20 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-9 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-11 {
  padding-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-13 {
  padding-top: 3.25rem !important;
  padding-bottom: 3.25rem !important;
}

.py-14 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-15 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-17 {
  padding-top: 4.25rem !important;
  padding-bottom: 4.25rem !important;
}

.py-18 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-19 {
  padding-top: 4.75rem !important;
  padding-bottom: 4.75rem !important;
}

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-7 {
  padding-top: 1.75rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-9 {
  padding-top: 2.25rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pt-11 {
  padding-top: 2.75rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pt-13 {
  padding-top: 3.25rem !important;
}

.pt-14 {
  padding-top: 3.5rem !important;
}

.pt-15 {
  padding-top: 3.75rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pt-17 {
  padding-top: 4.25rem !important;
}

.pt-18 {
  padding-top: 4.5rem !important;
}

.pt-19 {
  padding-top: 4.75rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 0.75rem !important;
}

.pe-4 {
  padding-right: 1rem !important;
}

.pe-5 {
  padding-right: 1.25rem !important;
}

.pe-6 {
  padding-right: 1.5rem !important;
}

.pe-7 {
  padding-right: 1.75rem !important;
}

.pe-8 {
  padding-right: 2rem !important;
}

.pe-9 {
  padding-right: 2.25rem !important;
}

.pe-10 {
  padding-right: 2.5rem !important;
}

.pe-11 {
  padding-right: 2.75rem !important;
}

.pe-12 {
  padding-right: 3rem !important;
}

.pe-13 {
  padding-right: 3.25rem !important;
}

.pe-14 {
  padding-right: 3.5rem !important;
}

.pe-15 {
  padding-right: 3.75rem !important;
}

.pe-16 {
  padding-right: 4rem !important;
}

.pe-17 {
  padding-right: 4.25rem !important;
}

.pe-18 {
  padding-right: 4.5rem !important;
}

.pe-19 {
  padding-right: 4.75rem !important;
}

.pe-20 {
  padding-right: 5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-7 {
  padding-bottom: 1.75rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pb-9 {
  padding-bottom: 2.25rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pb-11 {
  padding-bottom: 2.75rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pb-13 {
  padding-bottom: 3.25rem !important;
}

.pb-14 {
  padding-bottom: 3.5rem !important;
}

.pb-15 {
  padding-bottom: 3.75rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pb-17 {
  padding-bottom: 4.25rem !important;
}

.pb-18 {
  padding-bottom: 4.5rem !important;
}

.pb-19 {
  padding-bottom: 4.75rem !important;
}

.pb-20 {
  padding-bottom: 5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 0.75rem !important;
}

.ps-4 {
  padding-left: 1rem !important;
}

.ps-5 {
  padding-left: 1.25rem !important;
}

.ps-6 {
  padding-left: 1.5rem !important;
}

.ps-7 {
  padding-left: 1.75rem !important;
}

.ps-8 {
  padding-left: 2rem !important;
}

.ps-9 {
  padding-left: 2.25rem !important;
}

.ps-10 {
  padding-left: 2.5rem !important;
}

.ps-11 {
  padding-left: 2.75rem !important;
}

.ps-12 {
  padding-left: 3rem !important;
}

.ps-13 {
  padding-left: 3.25rem !important;
}

.ps-14 {
  padding-left: 3.5rem !important;
}

.ps-15 {
  padding-left: 3.75rem !important;
}

.ps-16 {
  padding-left: 4rem !important;
}

.ps-17 {
  padding-left: 4.25rem !important;
}

.ps-18 {
  padding-left: 4.5rem !important;
}

.ps-19 {
  padding-left: 4.75rem !important;
}

.ps-20 {
  padding-left: 5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.gap-9 {
  gap: 2.25rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.gap-11 {
  gap: 2.75rem !important;
}

.gap-12 {
  gap: 3rem !important;
}

.gap-13 {
  gap: 3.25rem !important;
}

.gap-14 {
  gap: 3.5rem !important;
}

.gap-15 {
  gap: 3.75rem !important;
}

.gap-16 {
  gap: 4rem !important;
}

.gap-17 {
  gap: 4.25rem !important;
}

.gap-18 {
  gap: 4.5rem !important;
}

.gap-19 {
  gap: 4.75rem !important;
}

.gap-20 {
  gap: 5rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 0.75rem !important;
}

.row-gap-4 {
  row-gap: 1rem !important;
}

.row-gap-5 {
  row-gap: 1.25rem !important;
}

.row-gap-6 {
  row-gap: 1.5rem !important;
}

.row-gap-7 {
  row-gap: 1.75rem !important;
}

.row-gap-8 {
  row-gap: 2rem !important;
}

.row-gap-9 {
  row-gap: 2.25rem !important;
}

.row-gap-10 {
  row-gap: 2.5rem !important;
}

.row-gap-11 {
  row-gap: 2.75rem !important;
}

.row-gap-12 {
  row-gap: 3rem !important;
}

.row-gap-13 {
  row-gap: 3.25rem !important;
}

.row-gap-14 {
  row-gap: 3.5rem !important;
}

.row-gap-15 {
  row-gap: 3.75rem !important;
}

.row-gap-16 {
  row-gap: 4rem !important;
}

.row-gap-17 {
  row-gap: 4.25rem !important;
}

.row-gap-18 {
  row-gap: 4.5rem !important;
}

.row-gap-19 {
  row-gap: 4.75rem !important;
}

.row-gap-20 {
  row-gap: 5rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 0.75rem !important;
}

.column-gap-4 {
  column-gap: 1rem !important;
}

.column-gap-5 {
  column-gap: 1.25rem !important;
}

.column-gap-6 {
  column-gap: 1.5rem !important;
}

.column-gap-7 {
  column-gap: 1.75rem !important;
}

.column-gap-8 {
  column-gap: 2rem !important;
}

.column-gap-9 {
  column-gap: 2.25rem !important;
}

.column-gap-10 {
  column-gap: 2.5rem !important;
}

.column-gap-11 {
  column-gap: 2.75rem !important;
}

.column-gap-12 {
  column-gap: 3rem !important;
}

.column-gap-13 {
  column-gap: 3.25rem !important;
}

.column-gap-14 {
  column-gap: 3.5rem !important;
}

.column-gap-15 {
  column-gap: 3.75rem !important;
}

.column-gap-16 {
  column-gap: 4rem !important;
}

.column-gap-17 {
  column-gap: 4.25rem !important;
}

.column-gap-18 {
  column-gap: 4.5rem !important;
}

.column-gap-19 {
  column-gap: 4.75rem !important;
}

.column-gap-20 {
  column-gap: 5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-2 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-3 {
  font-size: calc(1.26rem + 0.12vw) !important;
}

.fs-4 {
  font-size: 1.25rem !important;
}

.fs-5 {
  font-size: 1.15rem !important;
}

.fs-6 {
  font-size: 1.075rem !important;
}

.fs-7 {
  font-size: 0.95rem !important;
}

.fs-8 {
  font-size: 0.85rem !important;
}

.fs-9 {
  font-size: 0.75rem !important;
}

.fs-10 {
  font-size: 0.5rem !important;
}

.fs-base {
  font-size: 1rem !important;
}

.fs-fluid {
  font-size: 100% !important;
}

.fs-2x {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-2qx {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2hx {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2tx {
  font-size: calc(1.4rem + 1.8vw) !important;
}

.fs-3x {
  font-size: calc(1.425rem + 2.1vw) !important;
}

.fs-3qx {
  font-size: calc(1.45rem + 2.4vw) !important;
}

.fs-3hx {
  font-size: calc(1.475rem + 2.7vw) !important;
}

.fs-3tx {
  font-size: calc(1.5rem + 3vw) !important;
}

.fs-4x {
  font-size: calc(1.525rem + 3.3vw) !important;
}

.fs-4qx {
  font-size: calc(1.55rem + 3.6vw) !important;
}

.fs-4hx {
  font-size: calc(1.575rem + 3.9vw) !important;
}

.fs-4tx {
  font-size: calc(1.6rem + 4.2vw) !important;
}

.fs-5x {
  font-size: calc(1.625rem + 4.5vw) !important;
}

.fs-5qx {
  font-size: calc(1.65rem + 4.8vw) !important;
}

.fs-5hx {
  font-size: calc(1.675rem + 5.1vw) !important;
}

.fs-5tx {
  font-size: calc(1.7rem + 5.4vw) !important;
}

.fs-10x {
  font-size: calc(2.125rem + 10.5vw) !important;
}

.fs-10qx {
  font-size: calc(2.15rem + 10.8vw) !important;
}

.fs-10hx {
  font-size: calc(2.175rem + 11.1vw) !important;
}

.fs-10tx {
  font-size: calc(2.2rem + 11.4vw) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 500 !important;
}

.fw-bolder {
  font-weight: 600 !important;
}

.lh-0 {
  line-height: 0 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 1.75 !important;
}

.lh-xl {
  line-height: 2 !important;
}

.lh-xxl {
  line-height: 2.25 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-blue {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-rgb), var(--bs-text-opacity)) !important;
}

.text-indigo {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-rgb), var(--bs-text-opacity)) !important;
}

.text-purple {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-rgb), var(--bs-text-opacity)) !important;
}

.text-pink {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;
}

.text-red {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-rgb), var(--bs-text-opacity)) !important;
}

.text-orange {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-rgb), var(--bs-text-opacity)) !important;
}

.text-green {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-rgb), var(--bs-text-opacity)) !important;
}

.text-teal {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-rgb), var(--bs-text-opacity)) !important;
}

.text-gray {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity)) !important;
}

.text-body-bg {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-bg-rgb), var(--bs-text-opacity)) !important;
}

.text-body-text {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-text-rgb), var(--bs-text-opacity)) !important;
}

.text-toolbar-bg {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-toolbar-bg-rgb), var(--bs-text-opacity)) !important;
}

.text-toolbar-text {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-toolbar-text-rgb), var(--bs-text-opacity)) !important;
}

.text-focus-bg {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-focus-bg-rgb), var(--bs-text-opacity)) !important;
}

.text-tabbar-text {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tabbar-text-rgb), var(--bs-text-opacity)) !important;
}

.text-card-bg {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-card-bg-rgb), var(--bs-text-opacity)) !important;
}

.text-card-text {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-card-text-rgb), var(--bs-text-opacity)) !important;
}

.text-item-bg {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-item-bg-rgb), var(--bs-text-opacity)) !important;
}

.text-item-text {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-item-text-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-white {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-white-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-black {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-black-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-muted {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-muted-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-blue {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-blue-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-indigo {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-indigo-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-purple {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-purple-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-pink {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-pink-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-red {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-red-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-orange {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-orange-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-yellow {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-yellow-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-green {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-green-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-teal {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-teal-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-cyan {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-cyan-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-body-bg {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-body-bg-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-body-text {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-body-text-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-toolbar-bg {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-toolbar-bg-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-toolbar-text {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-toolbar-text-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-focus-bg {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-focus-bg-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-tabbar-text {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tabbar-text-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-card-bg {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-card-bg-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-card-text {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-card-text-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-item-bg {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-item-bg-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-item-text {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-item-text-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-muted {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-muted-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-bg {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-text {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-text-rgb), var(--bs-bg-opacity)) !important;
}

.bg-toolbar-bg {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-toolbar-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-toolbar-text {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-toolbar-text-rgb), var(--bs-bg-opacity)) !important;
}

.bg-focus-bg {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-focus-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tabbar-text {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tabbar-text-rgb), var(--bs-bg-opacity)) !important;
}

.bg-card-bg {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-card-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-card-text {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-card-text-rgb), var(--bs-bg-opacity)) !important;
}

.bg-item-bg {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-item-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-item-text {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-item-text-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-index-n1 {
  z-index: -1 !important;
}

.z-index-n2 {
  z-index: -2 !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-0-hover:hover {
  opacity: 0 !important;
}

.opacity-5 {
  opacity: 0.05 !important;
}

.opacity-5-hover:hover {
  opacity: 0.05 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-10-hover:hover {
  opacity: 0.1 !important;
}

.opacity-15 {
  opacity: 0.15 !important;
}

.opacity-15-hover:hover {
  opacity: 0.15 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-20-hover:hover {
  opacity: 0.2 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-25-hover:hover {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-50-hover:hover {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-75-hover:hover {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.opacity-100-hover:hover {
  opacity: 1 !important;
}

.min-w-unset {
  min-width: unset !important;
}

.min-w-25 {
  min-width: 25% !important;
}

.min-w-50 {
  min-width: 50% !important;
}

.min-w-75 {
  min-width: 75% !important;
}

.min-w-100 {
  min-width: 100% !important;
}

.min-w-auto {
  min-width: auto !important;
}

.min-w-1px {
  min-width: 1px !important;
}

.min-w-2px {
  min-width: 2px !important;
}

.min-w-3px {
  min-width: 3px !important;
}

.min-w-4px {
  min-width: 4px !important;
}

.min-w-5px {
  min-width: 5px !important;
}

.min-w-6px {
  min-width: 6px !important;
}

.min-w-7px {
  min-width: 7px !important;
}

.min-w-8px {
  min-width: 8px !important;
}

.min-w-9px {
  min-width: 9px !important;
}

.min-w-10px {
  min-width: 10px !important;
}

.min-w-15px {
  min-width: 15px !important;
}

.min-w-20px {
  min-width: 20px !important;
}

.min-w-25px {
  min-width: 25px !important;
}

.min-w-30px {
  min-width: 30px !important;
}

.min-w-35px {
  min-width: 35px !important;
}

.min-w-40px {
  min-width: 40px !important;
}

.min-w-45px {
  min-width: 45px !important;
}

.min-w-50px {
  min-width: 50px !important;
}

.min-w-55px {
  min-width: 55px !important;
}

.min-w-60px {
  min-width: 60px !important;
}

.min-w-65px {
  min-width: 65px !important;
}

.min-w-70px {
  min-width: 70px !important;
}

.min-w-75px {
  min-width: 75px !important;
}

.min-w-80px {
  min-width: 80px !important;
}

.min-w-85px {
  min-width: 85px !important;
}

.min-w-90px {
  min-width: 90px !important;
}

.min-w-95px {
  min-width: 95px !important;
}

.min-w-100px {
  min-width: 100px !important;
}

.min-w-125px {
  min-width: 125px !important;
}

.min-w-150px {
  min-width: 150px !important;
}

.min-w-175px {
  min-width: 175px !important;
}

.min-w-200px {
  min-width: 200px !important;
}

.min-w-225px {
  min-width: 225px !important;
}

.min-w-250px {
  min-width: 250px !important;
}

.min-w-275px {
  min-width: 275px !important;
}

.min-w-300px {
  min-width: 300px !important;
}

.min-w-325px {
  min-width: 325px !important;
}

.min-w-350px {
  min-width: 350px !important;
}

.min-w-375px {
  min-width: 375px !important;
}

.min-w-400px {
  min-width: 400px !important;
}

.min-w-425px {
  min-width: 425px !important;
}

.min-w-450px {
  min-width: 450px !important;
}

.min-w-475px {
  min-width: 475px !important;
}

.min-w-500px {
  min-width: 500px !important;
}

.min-w-550px {
  min-width: 550px !important;
}

.min-w-600px {
  min-width: 600px !important;
}

.min-w-650px {
  min-width: 650px !important;
}

.min-w-700px {
  min-width: 700px !important;
}

.min-w-750px {
  min-width: 750px !important;
}

.min-w-800px {
  min-width: 800px !important;
}

.min-w-850px {
  min-width: 850px !important;
}

.min-w-900px {
  min-width: 900px !important;
}

.min-w-950px {
  min-width: 950px !important;
}

.min-w-1000px {
  min-width: 1000px !important;
}

.min-h-unset {
  min-height: unset !important;
}

.min-h-25 {
  min-height: 25% !important;
}

.min-h-50 {
  min-height: 50% !important;
}

.min-h-75 {
  min-height: 75% !important;
}

.min-h-100 {
  min-height: 100% !important;
}

.min-h-auto {
  min-height: auto !important;
}

.min-h-1px {
  min-height: 1px !important;
}

.min-h-2px {
  min-height: 2px !important;
}

.min-h-3px {
  min-height: 3px !important;
}

.min-h-4px {
  min-height: 4px !important;
}

.min-h-5px {
  min-height: 5px !important;
}

.min-h-6px {
  min-height: 6px !important;
}

.min-h-7px {
  min-height: 7px !important;
}

.min-h-8px {
  min-height: 8px !important;
}

.min-h-9px {
  min-height: 9px !important;
}

.min-h-10px {
  min-height: 10px !important;
}

.min-h-15px {
  min-height: 15px !important;
}

.min-h-20px {
  min-height: 20px !important;
}

.min-h-25px {
  min-height: 25px !important;
}

.min-h-30px {
  min-height: 30px !important;
}

.min-h-35px {
  min-height: 35px !important;
}

.min-h-40px {
  min-height: 40px !important;
}

.min-h-45px {
  min-height: 45px !important;
}

.min-h-50px {
  min-height: 50px !important;
}

.min-h-55px {
  min-height: 55px !important;
}

.min-h-60px {
  min-height: 60px !important;
}

.min-h-65px {
  min-height: 65px !important;
}

.min-h-70px {
  min-height: 70px !important;
}

.min-h-75px {
  min-height: 75px !important;
}

.min-h-80px {
  min-height: 80px !important;
}

.min-h-85px {
  min-height: 85px !important;
}

.min-h-90px {
  min-height: 90px !important;
}

.min-h-95px {
  min-height: 95px !important;
}

.min-h-100px {
  min-height: 100px !important;
}

.min-h-125px {
  min-height: 125px !important;
}

.min-h-150px {
  min-height: 150px !important;
}

.min-h-175px {
  min-height: 175px !important;
}

.min-h-200px {
  min-height: 200px !important;
}

.min-h-225px {
  min-height: 225px !important;
}

.min-h-250px {
  min-height: 250px !important;
}

.min-h-275px {
  min-height: 275px !important;
}

.min-h-300px {
  min-height: 300px !important;
}

.min-h-325px {
  min-height: 325px !important;
}

.min-h-350px {
  min-height: 350px !important;
}

.min-h-375px {
  min-height: 375px !important;
}

.min-h-400px {
  min-height: 400px !important;
}

.min-h-425px {
  min-height: 425px !important;
}

.min-h-450px {
  min-height: 450px !important;
}

.min-h-475px {
  min-height: 475px !important;
}

.min-h-500px {
  min-height: 500px !important;
}

.min-h-550px {
  min-height: 550px !important;
}

.min-h-600px {
  min-height: 600px !important;
}

.min-h-650px {
  min-height: 650px !important;
}

.min-h-700px {
  min-height: 700px !important;
}

.min-h-750px {
  min-height: 750px !important;
}

.min-h-800px {
  min-height: 800px !important;
}

.min-h-850px {
  min-height: 850px !important;
}

.min-h-900px {
  min-height: 900px !important;
}

.min-h-950px {
  min-height: 950px !important;
}

.min-h-1000px {
  min-height: 1000px !important;
}

.border-top-0 {
  border-top-width: 0 !important;
}

.border-top-1 {
  border-top-width: 1px !important;
}

.border-top-2 {
  border-top-width: 2px !important;
}

.border-top-3 {
  border-top-width: 3px !important;
}

.border-top-4 {
  border-top-width: 4px !important;
}

.border-top-5 {
  border-top-width: 5px !important;
}

.border-bottom-0 {
  border-bottom-width: 0 !important;
}

.border-bottom-1 {
  border-bottom-width: 1px !important;
}

.border-bottom-2 {
  border-bottom-width: 2px !important;
}

.border-bottom-3 {
  border-bottom-width: 3px !important;
}

.border-bottom-4 {
  border-bottom-width: 4px !important;
}

.border-bottom-5 {
  border-bottom-width: 5px !important;
}

.border-right-0 {
  border-right-width: 0 !important;
}

.border-right-1 {
  border-right-width: 1px !important;
}

.border-right-2 {
  border-right-width: 2px !important;
}

.border-right-3 {
  border-right-width: 3px !important;
}

.border-right-4 {
  border-right-width: 4px !important;
}

.border-right-5 {
  border-right-width: 5px !important;
}

.border-left-0 {
  border-left-width: 0 !important;
}

.border-left-1 {
  border-left-width: 1px !important;
}

.border-left-2 {
  border-left-width: 2px !important;
}

.border-left-3 {
  border-left-width: 3px !important;
}

.border-left-4 {
  border-left-width: 4px !important;
}

.border-left-5 {
  border-left-width: 5px !important;
}

.ls-1 {
  letter-spacing: 0.1rem !important;
}

.ls-2 {
  letter-spacing: 0.115rem !important;
}

.ls-3 {
  letter-spacing: 0.125rem !important;
}

.ls-4 {
  letter-spacing: 0.25rem !important;
}

.ls-5 {
  letter-spacing: 0.5rem !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .w-sm-unset {
    width: unset !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .w-sm-1px {
    width: 1px !important;
  }

  .w-sm-2px {
    width: 2px !important;
  }

  .w-sm-3px {
    width: 3px !important;
  }

  .w-sm-4px {
    width: 4px !important;
  }

  .w-sm-5px {
    width: 5px !important;
  }

  .w-sm-6px {
    width: 6px !important;
  }

  .w-sm-7px {
    width: 7px !important;
  }

  .w-sm-8px {
    width: 8px !important;
  }

  .w-sm-9px {
    width: 9px !important;
  }

  .w-sm-10px {
    width: 10px !important;
  }

  .w-sm-15px {
    width: 15px !important;
  }

  .w-sm-20px {
    width: 20px !important;
  }

  .w-sm-25px {
    width: 25px !important;
  }

  .w-sm-30px {
    width: 30px !important;
  }

  .w-sm-35px {
    width: 35px !important;
  }

  .w-sm-40px {
    width: 40px !important;
  }

  .w-sm-45px {
    width: 45px !important;
  }

  .w-sm-50px {
    width: 50px !important;
  }

  .w-sm-55px {
    width: 55px !important;
  }

  .w-sm-60px {
    width: 60px !important;
  }

  .w-sm-65px {
    width: 65px !important;
  }

  .w-sm-70px {
    width: 70px !important;
  }

  .w-sm-75px {
    width: 75px !important;
  }

  .w-sm-80px {
    width: 80px !important;
  }

  .w-sm-85px {
    width: 85px !important;
  }

  .w-sm-90px {
    width: 90px !important;
  }

  .w-sm-95px {
    width: 95px !important;
  }

  .w-sm-100px {
    width: 100px !important;
  }

  .w-sm-125px {
    width: 125px !important;
  }

  .w-sm-150px {
    width: 150px !important;
  }

  .w-sm-175px {
    width: 175px !important;
  }

  .w-sm-200px {
    width: 200px !important;
  }

  .w-sm-225px {
    width: 225px !important;
  }

  .w-sm-250px {
    width: 250px !important;
  }

  .w-sm-275px {
    width: 275px !important;
  }

  .w-sm-300px {
    width: 300px !important;
  }

  .w-sm-325px {
    width: 325px !important;
  }

  .w-sm-350px {
    width: 350px !important;
  }

  .w-sm-375px {
    width: 375px !important;
  }

  .w-sm-400px {
    width: 400px !important;
  }

  .w-sm-425px {
    width: 425px !important;
  }

  .w-sm-450px {
    width: 450px !important;
  }

  .w-sm-475px {
    width: 475px !important;
  }

  .w-sm-500px {
    width: 500px !important;
  }

  .w-sm-550px {
    width: 550px !important;
  }

  .w-sm-600px {
    width: 600px !important;
  }

  .w-sm-650px {
    width: 650px !important;
  }

  .w-sm-700px {
    width: 700px !important;
  }

  .w-sm-750px {
    width: 750px !important;
  }

  .w-sm-800px {
    width: 800px !important;
  }

  .w-sm-850px {
    width: 850px !important;
  }

  .w-sm-900px {
    width: 900px !important;
  }

  .w-sm-950px {
    width: 950px !important;
  }

  .w-sm-1000px {
    width: 1000px !important;
  }

  .mw-sm-unset {
    max-width: unset !important;
  }

  .mw-sm-25 {
    max-width: 25% !important;
  }

  .mw-sm-50 {
    max-width: 50% !important;
  }

  .mw-sm-75 {
    max-width: 75% !important;
  }

  .mw-sm-100 {
    max-width: 100% !important;
  }

  .mw-sm-auto {
    max-width: auto !important;
  }

  .mw-sm-1px {
    max-width: 1px !important;
  }

  .mw-sm-2px {
    max-width: 2px !important;
  }

  .mw-sm-3px {
    max-width: 3px !important;
  }

  .mw-sm-4px {
    max-width: 4px !important;
  }

  .mw-sm-5px {
    max-width: 5px !important;
  }

  .mw-sm-6px {
    max-width: 6px !important;
  }

  .mw-sm-7px {
    max-width: 7px !important;
  }

  .mw-sm-8px {
    max-width: 8px !important;
  }

  .mw-sm-9px {
    max-width: 9px !important;
  }

  .mw-sm-10px {
    max-width: 10px !important;
  }

  .mw-sm-15px {
    max-width: 15px !important;
  }

  .mw-sm-20px {
    max-width: 20px !important;
  }

  .mw-sm-25px {
    max-width: 25px !important;
  }

  .mw-sm-30px {
    max-width: 30px !important;
  }

  .mw-sm-35px {
    max-width: 35px !important;
  }

  .mw-sm-40px {
    max-width: 40px !important;
  }

  .mw-sm-45px {
    max-width: 45px !important;
  }

  .mw-sm-50px {
    max-width: 50px !important;
  }

  .mw-sm-55px {
    max-width: 55px !important;
  }

  .mw-sm-60px {
    max-width: 60px !important;
  }

  .mw-sm-65px {
    max-width: 65px !important;
  }

  .mw-sm-70px {
    max-width: 70px !important;
  }

  .mw-sm-75px {
    max-width: 75px !important;
  }

  .mw-sm-80px {
    max-width: 80px !important;
  }

  .mw-sm-85px {
    max-width: 85px !important;
  }

  .mw-sm-90px {
    max-width: 90px !important;
  }

  .mw-sm-95px {
    max-width: 95px !important;
  }

  .mw-sm-100px {
    max-width: 100px !important;
  }

  .mw-sm-125px {
    max-width: 125px !important;
  }

  .mw-sm-150px {
    max-width: 150px !important;
  }

  .mw-sm-175px {
    max-width: 175px !important;
  }

  .mw-sm-200px {
    max-width: 200px !important;
  }

  .mw-sm-225px {
    max-width: 225px !important;
  }

  .mw-sm-250px {
    max-width: 250px !important;
  }

  .mw-sm-275px {
    max-width: 275px !important;
  }

  .mw-sm-300px {
    max-width: 300px !important;
  }

  .mw-sm-325px {
    max-width: 325px !important;
  }

  .mw-sm-350px {
    max-width: 350px !important;
  }

  .mw-sm-375px {
    max-width: 375px !important;
  }

  .mw-sm-400px {
    max-width: 400px !important;
  }

  .mw-sm-425px {
    max-width: 425px !important;
  }

  .mw-sm-450px {
    max-width: 450px !important;
  }

  .mw-sm-475px {
    max-width: 475px !important;
  }

  .mw-sm-500px {
    max-width: 500px !important;
  }

  .mw-sm-550px {
    max-width: 550px !important;
  }

  .mw-sm-600px {
    max-width: 600px !important;
  }

  .mw-sm-650px {
    max-width: 650px !important;
  }

  .mw-sm-700px {
    max-width: 700px !important;
  }

  .mw-sm-750px {
    max-width: 750px !important;
  }

  .mw-sm-800px {
    max-width: 800px !important;
  }

  .mw-sm-850px {
    max-width: 850px !important;
  }

  .mw-sm-900px {
    max-width: 900px !important;
  }

  .mw-sm-950px {
    max-width: 950px !important;
  }

  .mw-sm-1000px {
    max-width: 1000px !important;
  }

  .h-sm-unset {
    height: unset !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .h-sm-auto {
    height: auto !important;
  }

  .h-sm-1px {
    height: 1px !important;
  }

  .h-sm-2px {
    height: 2px !important;
  }

  .h-sm-3px {
    height: 3px !important;
  }

  .h-sm-4px {
    height: 4px !important;
  }

  .h-sm-5px {
    height: 5px !important;
  }

  .h-sm-6px {
    height: 6px !important;
  }

  .h-sm-7px {
    height: 7px !important;
  }

  .h-sm-8px {
    height: 8px !important;
  }

  .h-sm-9px {
    height: 9px !important;
  }

  .h-sm-10px {
    height: 10px !important;
  }

  .h-sm-15px {
    height: 15px !important;
  }

  .h-sm-20px {
    height: 20px !important;
  }

  .h-sm-25px {
    height: 25px !important;
  }

  .h-sm-30px {
    height: 30px !important;
  }

  .h-sm-35px {
    height: 35px !important;
  }

  .h-sm-40px {
    height: 40px !important;
  }

  .h-sm-45px {
    height: 45px !important;
  }

  .h-sm-50px {
    height: 50px !important;
  }

  .h-sm-55px {
    height: 55px !important;
  }

  .h-sm-60px {
    height: 60px !important;
  }

  .h-sm-65px {
    height: 65px !important;
  }

  .h-sm-70px {
    height: 70px !important;
  }

  .h-sm-75px {
    height: 75px !important;
  }

  .h-sm-80px {
    height: 80px !important;
  }

  .h-sm-85px {
    height: 85px !important;
  }

  .h-sm-90px {
    height: 90px !important;
  }

  .h-sm-95px {
    height: 95px !important;
  }

  .h-sm-100px {
    height: 100px !important;
  }

  .h-sm-125px {
    height: 125px !important;
  }

  .h-sm-150px {
    height: 150px !important;
  }

  .h-sm-175px {
    height: 175px !important;
  }

  .h-sm-200px {
    height: 200px !important;
  }

  .h-sm-225px {
    height: 225px !important;
  }

  .h-sm-250px {
    height: 250px !important;
  }

  .h-sm-275px {
    height: 275px !important;
  }

  .h-sm-300px {
    height: 300px !important;
  }

  .h-sm-325px {
    height: 325px !important;
  }

  .h-sm-350px {
    height: 350px !important;
  }

  .h-sm-375px {
    height: 375px !important;
  }

  .h-sm-400px {
    height: 400px !important;
  }

  .h-sm-425px {
    height: 425px !important;
  }

  .h-sm-450px {
    height: 450px !important;
  }

  .h-sm-475px {
    height: 475px !important;
  }

  .h-sm-500px {
    height: 500px !important;
  }

  .h-sm-550px {
    height: 550px !important;
  }

  .h-sm-600px {
    height: 600px !important;
  }

  .h-sm-650px {
    height: 650px !important;
  }

  .h-sm-700px {
    height: 700px !important;
  }

  .h-sm-750px {
    height: 750px !important;
  }

  .h-sm-800px {
    height: 800px !important;
  }

  .h-sm-850px {
    height: 850px !important;
  }

  .h-sm-900px {
    height: 900px !important;
  }

  .h-sm-950px {
    height: 950px !important;
  }

  .h-sm-1000px {
    height: 1000px !important;
  }

  .mh-sm-unset {
    max-height: unset !important;
  }

  .mh-sm-25 {
    max-height: 25% !important;
  }

  .mh-sm-50 {
    max-height: 50% !important;
  }

  .mh-sm-75 {
    max-height: 75% !important;
  }

  .mh-sm-100 {
    max-height: 100% !important;
  }

  .mh-sm-auto {
    max-height: auto !important;
  }

  .mh-sm-1px {
    max-height: 1px !important;
  }

  .mh-sm-2px {
    max-height: 2px !important;
  }

  .mh-sm-3px {
    max-height: 3px !important;
  }

  .mh-sm-4px {
    max-height: 4px !important;
  }

  .mh-sm-5px {
    max-height: 5px !important;
  }

  .mh-sm-6px {
    max-height: 6px !important;
  }

  .mh-sm-7px {
    max-height: 7px !important;
  }

  .mh-sm-8px {
    max-height: 8px !important;
  }

  .mh-sm-9px {
    max-height: 9px !important;
  }

  .mh-sm-10px {
    max-height: 10px !important;
  }

  .mh-sm-15px {
    max-height: 15px !important;
  }

  .mh-sm-20px {
    max-height: 20px !important;
  }

  .mh-sm-25px {
    max-height: 25px !important;
  }

  .mh-sm-30px {
    max-height: 30px !important;
  }

  .mh-sm-35px {
    max-height: 35px !important;
  }

  .mh-sm-40px {
    max-height: 40px !important;
  }

  .mh-sm-45px {
    max-height: 45px !important;
  }

  .mh-sm-50px {
    max-height: 50px !important;
  }

  .mh-sm-55px {
    max-height: 55px !important;
  }

  .mh-sm-60px {
    max-height: 60px !important;
  }

  .mh-sm-65px {
    max-height: 65px !important;
  }

  .mh-sm-70px {
    max-height: 70px !important;
  }

  .mh-sm-75px {
    max-height: 75px !important;
  }

  .mh-sm-80px {
    max-height: 80px !important;
  }

  .mh-sm-85px {
    max-height: 85px !important;
  }

  .mh-sm-90px {
    max-height: 90px !important;
  }

  .mh-sm-95px {
    max-height: 95px !important;
  }

  .mh-sm-100px {
    max-height: 100px !important;
  }

  .mh-sm-125px {
    max-height: 125px !important;
  }

  .mh-sm-150px {
    max-height: 150px !important;
  }

  .mh-sm-175px {
    max-height: 175px !important;
  }

  .mh-sm-200px {
    max-height: 200px !important;
  }

  .mh-sm-225px {
    max-height: 225px !important;
  }

  .mh-sm-250px {
    max-height: 250px !important;
  }

  .mh-sm-275px {
    max-height: 275px !important;
  }

  .mh-sm-300px {
    max-height: 300px !important;
  }

  .mh-sm-325px {
    max-height: 325px !important;
  }

  .mh-sm-350px {
    max-height: 350px !important;
  }

  .mh-sm-375px {
    max-height: 375px !important;
  }

  .mh-sm-400px {
    max-height: 400px !important;
  }

  .mh-sm-425px {
    max-height: 425px !important;
  }

  .mh-sm-450px {
    max-height: 450px !important;
  }

  .mh-sm-475px {
    max-height: 475px !important;
  }

  .mh-sm-500px {
    max-height: 500px !important;
  }

  .mh-sm-550px {
    max-height: 550px !important;
  }

  .mh-sm-600px {
    max-height: 600px !important;
  }

  .mh-sm-650px {
    max-height: 650px !important;
  }

  .mh-sm-700px {
    max-height: 700px !important;
  }

  .mh-sm-750px {
    max-height: 750px !important;
  }

  .mh-sm-800px {
    max-height: 800px !important;
  }

  .mh-sm-850px {
    max-height: 850px !important;
  }

  .mh-sm-900px {
    max-height: 900px !important;
  }

  .mh-sm-950px {
    max-height: 950px !important;
  }

  .mh-sm-1000px {
    max-height: 1000px !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 0.75rem !important;
  }

  .m-sm-4 {
    margin: 1rem !important;
  }

  .m-sm-5 {
    margin: 1.25rem !important;
  }

  .m-sm-6 {
    margin: 1.5rem !important;
  }

  .m-sm-7 {
    margin: 1.75rem !important;
  }

  .m-sm-8 {
    margin: 2rem !important;
  }

  .m-sm-9 {
    margin: 2.25rem !important;
  }

  .m-sm-10 {
    margin: 2.5rem !important;
  }

  .m-sm-11 {
    margin: 2.75rem !important;
  }

  .m-sm-12 {
    margin: 3rem !important;
  }

  .m-sm-13 {
    margin: 3.25rem !important;
  }

  .m-sm-14 {
    margin: 3.5rem !important;
  }

  .m-sm-15 {
    margin: 3.75rem !important;
  }

  .m-sm-16 {
    margin: 4rem !important;
  }

  .m-sm-17 {
    margin: 4.25rem !important;
  }

  .m-sm-18 {
    margin: 4.5rem !important;
  }

  .m-sm-19 {
    margin: 4.75rem !important;
  }

  .m-sm-20 {
    margin: 5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-sm-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-sm-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-sm-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-sm-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-sm-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-sm-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-sm-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-sm-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-sm-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-sm-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-sm-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-sm-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-sm-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-sm-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-sm-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-sm-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-sm-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-sm-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-sm-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }

  .mt-sm-4 {
    margin-top: 1rem !important;
  }

  .mt-sm-5 {
    margin-top: 1.25rem !important;
  }

  .mt-sm-6 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 1.75rem !important;
  }

  .mt-sm-8 {
    margin-top: 2rem !important;
  }

  .mt-sm-9 {
    margin-top: 2.25rem !important;
  }

  .mt-sm-10 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-11 {
    margin-top: 2.75rem !important;
  }

  .mt-sm-12 {
    margin-top: 3rem !important;
  }

  .mt-sm-13 {
    margin-top: 3.25rem !important;
  }

  .mt-sm-14 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-15 {
    margin-top: 3.75rem !important;
  }

  .mt-sm-16 {
    margin-top: 4rem !important;
  }

  .mt-sm-17 {
    margin-top: 4.25rem !important;
  }

  .mt-sm-18 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-19 {
    margin-top: 4.75rem !important;
  }

  .mt-sm-20 {
    margin-top: 5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 0.75rem !important;
  }

  .me-sm-4 {
    margin-right: 1rem !important;
  }

  .me-sm-5 {
    margin-right: 1.25rem !important;
  }

  .me-sm-6 {
    margin-right: 1.5rem !important;
  }

  .me-sm-7 {
    margin-right: 1.75rem !important;
  }

  .me-sm-8 {
    margin-right: 2rem !important;
  }

  .me-sm-9 {
    margin-right: 2.25rem !important;
  }

  .me-sm-10 {
    margin-right: 2.5rem !important;
  }

  .me-sm-11 {
    margin-right: 2.75rem !important;
  }

  .me-sm-12 {
    margin-right: 3rem !important;
  }

  .me-sm-13 {
    margin-right: 3.25rem !important;
  }

  .me-sm-14 {
    margin-right: 3.5rem !important;
  }

  .me-sm-15 {
    margin-right: 3.75rem !important;
  }

  .me-sm-16 {
    margin-right: 4rem !important;
  }

  .me-sm-17 {
    margin-right: 4.25rem !important;
  }

  .me-sm-18 {
    margin-right: 4.5rem !important;
  }

  .me-sm-19 {
    margin-right: 4.75rem !important;
  }

  .me-sm-20 {
    margin-right: 5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-sm-16 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-sm-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-sm-20 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 0.75rem !important;
  }

  .ms-sm-4 {
    margin-left: 1rem !important;
  }

  .ms-sm-5 {
    margin-left: 1.25rem !important;
  }

  .ms-sm-6 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 1.75rem !important;
  }

  .ms-sm-8 {
    margin-left: 2rem !important;
  }

  .ms-sm-9 {
    margin-left: 2.25rem !important;
  }

  .ms-sm-10 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-11 {
    margin-left: 2.75rem !important;
  }

  .ms-sm-12 {
    margin-left: 3rem !important;
  }

  .ms-sm-13 {
    margin-left: 3.25rem !important;
  }

  .ms-sm-14 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-15 {
    margin-left: 3.75rem !important;
  }

  .ms-sm-16 {
    margin-left: 4rem !important;
  }

  .ms-sm-17 {
    margin-left: 4.25rem !important;
  }

  .ms-sm-18 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-19 {
    margin-left: 4.75rem !important;
  }

  .ms-sm-20 {
    margin-left: 5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -0.75rem !important;
  }

  .m-sm-n4 {
    margin: -1rem !important;
  }

  .m-sm-n5 {
    margin: -1.25rem !important;
  }

  .m-sm-n6 {
    margin: -1.5rem !important;
  }

  .m-sm-n7 {
    margin: -1.75rem !important;
  }

  .m-sm-n8 {
    margin: -2rem !important;
  }

  .m-sm-n9 {
    margin: -2.25rem !important;
  }

  .m-sm-n10 {
    margin: -2.5rem !important;
  }

  .m-sm-n11 {
    margin: -2.75rem !important;
  }

  .m-sm-n12 {
    margin: -3rem !important;
  }

  .m-sm-n13 {
    margin: -3.25rem !important;
  }

  .m-sm-n14 {
    margin: -3.5rem !important;
  }

  .m-sm-n15 {
    margin: -3.75rem !important;
  }

  .m-sm-n16 {
    margin: -4rem !important;
  }

  .m-sm-n17 {
    margin: -4.25rem !important;
  }

  .m-sm-n18 {
    margin: -4.5rem !important;
  }

  .m-sm-n19 {
    margin: -4.75rem !important;
  }

  .m-sm-n20 {
    margin: -5rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-sm-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-sm-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-sm-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-sm-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-sm-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-sm-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-sm-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-sm-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-sm-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-sm-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-sm-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-sm-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-sm-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-sm-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-sm-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-sm-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-sm-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-sm-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-sm-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-sm-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-sm-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-sm-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-sm-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-sm-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-sm-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1rem !important;
  }

  .mt-sm-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-sm-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-sm-n8 {
    margin-top: -2rem !important;
  }

  .mt-sm-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-sm-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-sm-n12 {
    margin-top: -3rem !important;
  }

  .mt-sm-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-sm-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-sm-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-sm-n16 {
    margin-top: -4rem !important;
  }

  .mt-sm-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-sm-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-sm-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-sm-n20 {
    margin-top: -5rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -0.75rem !important;
  }

  .me-sm-n4 {
    margin-right: -1rem !important;
  }

  .me-sm-n5 {
    margin-right: -1.25rem !important;
  }

  .me-sm-n6 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n7 {
    margin-right: -1.75rem !important;
  }

  .me-sm-n8 {
    margin-right: -2rem !important;
  }

  .me-sm-n9 {
    margin-right: -2.25rem !important;
  }

  .me-sm-n10 {
    margin-right: -2.5rem !important;
  }

  .me-sm-n11 {
    margin-right: -2.75rem !important;
  }

  .me-sm-n12 {
    margin-right: -3rem !important;
  }

  .me-sm-n13 {
    margin-right: -3.25rem !important;
  }

  .me-sm-n14 {
    margin-right: -3.5rem !important;
  }

  .me-sm-n15 {
    margin-right: -3.75rem !important;
  }

  .me-sm-n16 {
    margin-right: -4rem !important;
  }

  .me-sm-n17 {
    margin-right: -4.25rem !important;
  }

  .me-sm-n18 {
    margin-right: -4.5rem !important;
  }

  .me-sm-n19 {
    margin-right: -4.75rem !important;
  }

  .me-sm-n20 {
    margin-right: -5rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-sm-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-sm-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-sm-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-sm-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-sm-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-sm-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-sm-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1rem !important;
  }

  .ms-sm-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-sm-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-sm-n8 {
    margin-left: -2rem !important;
  }

  .ms-sm-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-sm-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-sm-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-sm-n12 {
    margin-left: -3rem !important;
  }

  .ms-sm-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-sm-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-sm-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-sm-n16 {
    margin-left: -4rem !important;
  }

  .ms-sm-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-sm-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-sm-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-sm-n20 {
    margin-left: -5rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 0.75rem !important;
  }

  .p-sm-4 {
    padding: 1rem !important;
  }

  .p-sm-5 {
    padding: 1.25rem !important;
  }

  .p-sm-6 {
    padding: 1.5rem !important;
  }

  .p-sm-7 {
    padding: 1.75rem !important;
  }

  .p-sm-8 {
    padding: 2rem !important;
  }

  .p-sm-9 {
    padding: 2.25rem !important;
  }

  .p-sm-10 {
    padding: 2.5rem !important;
  }

  .p-sm-11 {
    padding: 2.75rem !important;
  }

  .p-sm-12 {
    padding: 3rem !important;
  }

  .p-sm-13 {
    padding: 3.25rem !important;
  }

  .p-sm-14 {
    padding: 3.5rem !important;
  }

  .p-sm-15 {
    padding: 3.75rem !important;
  }

  .p-sm-16 {
    padding: 4rem !important;
  }

  .p-sm-17 {
    padding: 4.25rem !important;
  }

  .p-sm-18 {
    padding: 4.5rem !important;
  }

  .p-sm-19 {
    padding: 4.75rem !important;
  }

  .p-sm-20 {
    padding: 5rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-sm-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-sm-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-sm-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-sm-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-sm-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-sm-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-sm-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-sm-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-sm-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-sm-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-sm-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-sm-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-sm-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-sm-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-sm-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-sm-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-sm-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-sm-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-sm-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-sm-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }

  .pt-sm-4 {
    padding-top: 1rem !important;
  }

  .pt-sm-5 {
    padding-top: 1.25rem !important;
  }

  .pt-sm-6 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 1.75rem !important;
  }

  .pt-sm-8 {
    padding-top: 2rem !important;
  }

  .pt-sm-9 {
    padding-top: 2.25rem !important;
  }

  .pt-sm-10 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-11 {
    padding-top: 2.75rem !important;
  }

  .pt-sm-12 {
    padding-top: 3rem !important;
  }

  .pt-sm-13 {
    padding-top: 3.25rem !important;
  }

  .pt-sm-14 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-15 {
    padding-top: 3.75rem !important;
  }

  .pt-sm-16 {
    padding-top: 4rem !important;
  }

  .pt-sm-17 {
    padding-top: 4.25rem !important;
  }

  .pt-sm-18 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-19 {
    padding-top: 4.75rem !important;
  }

  .pt-sm-20 {
    padding-top: 5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 0.75rem !important;
  }

  .pe-sm-4 {
    padding-right: 1rem !important;
  }

  .pe-sm-5 {
    padding-right: 1.25rem !important;
  }

  .pe-sm-6 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 1.75rem !important;
  }

  .pe-sm-8 {
    padding-right: 2rem !important;
  }

  .pe-sm-9 {
    padding-right: 2.25rem !important;
  }

  .pe-sm-10 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-11 {
    padding-right: 2.75rem !important;
  }

  .pe-sm-12 {
    padding-right: 3rem !important;
  }

  .pe-sm-13 {
    padding-right: 3.25rem !important;
  }

  .pe-sm-14 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-15 {
    padding-right: 3.75rem !important;
  }

  .pe-sm-16 {
    padding-right: 4rem !important;
  }

  .pe-sm-17 {
    padding-right: 4.25rem !important;
  }

  .pe-sm-18 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-19 {
    padding-right: 4.75rem !important;
  }

  .pe-sm-20 {
    padding-right: 5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-sm-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-sm-16 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-sm-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-sm-20 {
    padding-bottom: 5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 0.75rem !important;
  }

  .ps-sm-4 {
    padding-left: 1rem !important;
  }

  .ps-sm-5 {
    padding-left: 1.25rem !important;
  }

  .ps-sm-6 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 1.75rem !important;
  }

  .ps-sm-8 {
    padding-left: 2rem !important;
  }

  .ps-sm-9 {
    padding-left: 2.25rem !important;
  }

  .ps-sm-10 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-11 {
    padding-left: 2.75rem !important;
  }

  .ps-sm-12 {
    padding-left: 3rem !important;
  }

  .ps-sm-13 {
    padding-left: 3.25rem !important;
  }

  .ps-sm-14 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-15 {
    padding-left: 3.75rem !important;
  }

  .ps-sm-16 {
    padding-left: 4rem !important;
  }

  .ps-sm-17 {
    padding-left: 4.25rem !important;
  }

  .ps-sm-18 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-19 {
    padding-left: 4.75rem !important;
  }

  .ps-sm-20 {
    padding-left: 5rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 0.75rem !important;
  }

  .gap-sm-4 {
    gap: 1rem !important;
  }

  .gap-sm-5 {
    gap: 1.25rem !important;
  }

  .gap-sm-6 {
    gap: 1.5rem !important;
  }

  .gap-sm-7 {
    gap: 1.75rem !important;
  }

  .gap-sm-8 {
    gap: 2rem !important;
  }

  .gap-sm-9 {
    gap: 2.25rem !important;
  }

  .gap-sm-10 {
    gap: 2.5rem !important;
  }

  .gap-sm-11 {
    gap: 2.75rem !important;
  }

  .gap-sm-12 {
    gap: 3rem !important;
  }

  .gap-sm-13 {
    gap: 3.25rem !important;
  }

  .gap-sm-14 {
    gap: 3.5rem !important;
  }

  .gap-sm-15 {
    gap: 3.75rem !important;
  }

  .gap-sm-16 {
    gap: 4rem !important;
  }

  .gap-sm-17 {
    gap: 4.25rem !important;
  }

  .gap-sm-18 {
    gap: 4.5rem !important;
  }

  .gap-sm-19 {
    gap: 4.75rem !important;
  }

  .gap-sm-20 {
    gap: 5rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-sm-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-sm-8 {
    row-gap: 2rem !important;
  }

  .row-gap-sm-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-sm-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-sm-11 {
    row-gap: 2.75rem !important;
  }

  .row-gap-sm-12 {
    row-gap: 3rem !important;
  }

  .row-gap-sm-13 {
    row-gap: 3.25rem !important;
  }

  .row-gap-sm-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-sm-15 {
    row-gap: 3.75rem !important;
  }

  .row-gap-sm-16 {
    row-gap: 4rem !important;
  }

  .row-gap-sm-17 {
    row-gap: 4.25rem !important;
  }

  .row-gap-sm-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-sm-19 {
    row-gap: 4.75rem !important;
  }

  .row-gap-sm-20 {
    row-gap: 5rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-sm-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-sm-8 {
    column-gap: 2rem !important;
  }

  .column-gap-sm-9 {
    column-gap: 2.25rem !important;
  }

  .column-gap-sm-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-sm-11 {
    column-gap: 2.75rem !important;
  }

  .column-gap-sm-12 {
    column-gap: 3rem !important;
  }

  .column-gap-sm-13 {
    column-gap: 3.25rem !important;
  }

  .column-gap-sm-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-sm-15 {
    column-gap: 3.75rem !important;
  }

  .column-gap-sm-16 {
    column-gap: 4rem !important;
  }

  .column-gap-sm-17 {
    column-gap: 4.25rem !important;
  }

  .column-gap-sm-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-sm-19 {
    column-gap: 4.75rem !important;
  }

  .column-gap-sm-20 {
    column-gap: 5rem !important;
  }

  .fs-sm-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-sm-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-sm-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-sm-4 {
    font-size: 1.25rem !important;
  }

  .fs-sm-5 {
    font-size: 1.15rem !important;
  }

  .fs-sm-6 {
    font-size: 1.075rem !important;
  }

  .fs-sm-7 {
    font-size: 0.95rem !important;
  }

  .fs-sm-8 {
    font-size: 0.85rem !important;
  }

  .fs-sm-9 {
    font-size: 0.75rem !important;
  }

  .fs-sm-10 {
    font-size: 0.5rem !important;
  }

  .fs-sm-base {
    font-size: 1rem !important;
  }

  .fs-sm-fluid {
    font-size: 100% !important;
  }

  .fs-sm-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-sm-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-sm-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-sm-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-sm-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-sm-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-sm-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-sm-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-sm-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-sm-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-sm-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-sm-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-sm-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-sm-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-sm-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-sm-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }

  .fs-sm-10x {
    font-size: calc(2.125rem + 10.5vw) !important;
  }

  .fs-sm-10qx {
    font-size: calc(2.15rem + 10.8vw) !important;
  }

  .fs-sm-10hx {
    font-size: calc(2.175rem + 11.1vw) !important;
  }

  .fs-sm-10tx {
    font-size: calc(2.2rem + 11.4vw) !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .min-w-sm-unset {
    min-width: unset !important;
  }

  .min-w-sm-25 {
    min-width: 25% !important;
  }

  .min-w-sm-50 {
    min-width: 50% !important;
  }

  .min-w-sm-75 {
    min-width: 75% !important;
  }

  .min-w-sm-100 {
    min-width: 100% !important;
  }

  .min-w-sm-auto {
    min-width: auto !important;
  }

  .min-w-sm-1px {
    min-width: 1px !important;
  }

  .min-w-sm-2px {
    min-width: 2px !important;
  }

  .min-w-sm-3px {
    min-width: 3px !important;
  }

  .min-w-sm-4px {
    min-width: 4px !important;
  }

  .min-w-sm-5px {
    min-width: 5px !important;
  }

  .min-w-sm-6px {
    min-width: 6px !important;
  }

  .min-w-sm-7px {
    min-width: 7px !important;
  }

  .min-w-sm-8px {
    min-width: 8px !important;
  }

  .min-w-sm-9px {
    min-width: 9px !important;
  }

  .min-w-sm-10px {
    min-width: 10px !important;
  }

  .min-w-sm-15px {
    min-width: 15px !important;
  }

  .min-w-sm-20px {
    min-width: 20px !important;
  }

  .min-w-sm-25px {
    min-width: 25px !important;
  }

  .min-w-sm-30px {
    min-width: 30px !important;
  }

  .min-w-sm-35px {
    min-width: 35px !important;
  }

  .min-w-sm-40px {
    min-width: 40px !important;
  }

  .min-w-sm-45px {
    min-width: 45px !important;
  }

  .min-w-sm-50px {
    min-width: 50px !important;
  }

  .min-w-sm-55px {
    min-width: 55px !important;
  }

  .min-w-sm-60px {
    min-width: 60px !important;
  }

  .min-w-sm-65px {
    min-width: 65px !important;
  }

  .min-w-sm-70px {
    min-width: 70px !important;
  }

  .min-w-sm-75px {
    min-width: 75px !important;
  }

  .min-w-sm-80px {
    min-width: 80px !important;
  }

  .min-w-sm-85px {
    min-width: 85px !important;
  }

  .min-w-sm-90px {
    min-width: 90px !important;
  }

  .min-w-sm-95px {
    min-width: 95px !important;
  }

  .min-w-sm-100px {
    min-width: 100px !important;
  }

  .min-w-sm-125px {
    min-width: 125px !important;
  }

  .min-w-sm-150px {
    min-width: 150px !important;
  }

  .min-w-sm-175px {
    min-width: 175px !important;
  }

  .min-w-sm-200px {
    min-width: 200px !important;
  }

  .min-w-sm-225px {
    min-width: 225px !important;
  }

  .min-w-sm-250px {
    min-width: 250px !important;
  }

  .min-w-sm-275px {
    min-width: 275px !important;
  }

  .min-w-sm-300px {
    min-width: 300px !important;
  }

  .min-w-sm-325px {
    min-width: 325px !important;
  }

  .min-w-sm-350px {
    min-width: 350px !important;
  }

  .min-w-sm-375px {
    min-width: 375px !important;
  }

  .min-w-sm-400px {
    min-width: 400px !important;
  }

  .min-w-sm-425px {
    min-width: 425px !important;
  }

  .min-w-sm-450px {
    min-width: 450px !important;
  }

  .min-w-sm-475px {
    min-width: 475px !important;
  }

  .min-w-sm-500px {
    min-width: 500px !important;
  }

  .min-w-sm-550px {
    min-width: 550px !important;
  }

  .min-w-sm-600px {
    min-width: 600px !important;
  }

  .min-w-sm-650px {
    min-width: 650px !important;
  }

  .min-w-sm-700px {
    min-width: 700px !important;
  }

  .min-w-sm-750px {
    min-width: 750px !important;
  }

  .min-w-sm-800px {
    min-width: 800px !important;
  }

  .min-w-sm-850px {
    min-width: 850px !important;
  }

  .min-w-sm-900px {
    min-width: 900px !important;
  }

  .min-w-sm-950px {
    min-width: 950px !important;
  }

  .min-w-sm-1000px {
    min-width: 1000px !important;
  }

  .min-h-sm-unset {
    min-height: unset !important;
  }

  .min-h-sm-25 {
    min-height: 25% !important;
  }

  .min-h-sm-50 {
    min-height: 50% !important;
  }

  .min-h-sm-75 {
    min-height: 75% !important;
  }

  .min-h-sm-100 {
    min-height: 100% !important;
  }

  .min-h-sm-auto {
    min-height: auto !important;
  }

  .min-h-sm-1px {
    min-height: 1px !important;
  }

  .min-h-sm-2px {
    min-height: 2px !important;
  }

  .min-h-sm-3px {
    min-height: 3px !important;
  }

  .min-h-sm-4px {
    min-height: 4px !important;
  }

  .min-h-sm-5px {
    min-height: 5px !important;
  }

  .min-h-sm-6px {
    min-height: 6px !important;
  }

  .min-h-sm-7px {
    min-height: 7px !important;
  }

  .min-h-sm-8px {
    min-height: 8px !important;
  }

  .min-h-sm-9px {
    min-height: 9px !important;
  }

  .min-h-sm-10px {
    min-height: 10px !important;
  }

  .min-h-sm-15px {
    min-height: 15px !important;
  }

  .min-h-sm-20px {
    min-height: 20px !important;
  }

  .min-h-sm-25px {
    min-height: 25px !important;
  }

  .min-h-sm-30px {
    min-height: 30px !important;
  }

  .min-h-sm-35px {
    min-height: 35px !important;
  }

  .min-h-sm-40px {
    min-height: 40px !important;
  }

  .min-h-sm-45px {
    min-height: 45px !important;
  }

  .min-h-sm-50px {
    min-height: 50px !important;
  }

  .min-h-sm-55px {
    min-height: 55px !important;
  }

  .min-h-sm-60px {
    min-height: 60px !important;
  }

  .min-h-sm-65px {
    min-height: 65px !important;
  }

  .min-h-sm-70px {
    min-height: 70px !important;
  }

  .min-h-sm-75px {
    min-height: 75px !important;
  }

  .min-h-sm-80px {
    min-height: 80px !important;
  }

  .min-h-sm-85px {
    min-height: 85px !important;
  }

  .min-h-sm-90px {
    min-height: 90px !important;
  }

  .min-h-sm-95px {
    min-height: 95px !important;
  }

  .min-h-sm-100px {
    min-height: 100px !important;
  }

  .min-h-sm-125px {
    min-height: 125px !important;
  }

  .min-h-sm-150px {
    min-height: 150px !important;
  }

  .min-h-sm-175px {
    min-height: 175px !important;
  }

  .min-h-sm-200px {
    min-height: 200px !important;
  }

  .min-h-sm-225px {
    min-height: 225px !important;
  }

  .min-h-sm-250px {
    min-height: 250px !important;
  }

  .min-h-sm-275px {
    min-height: 275px !important;
  }

  .min-h-sm-300px {
    min-height: 300px !important;
  }

  .min-h-sm-325px {
    min-height: 325px !important;
  }

  .min-h-sm-350px {
    min-height: 350px !important;
  }

  .min-h-sm-375px {
    min-height: 375px !important;
  }

  .min-h-sm-400px {
    min-height: 400px !important;
  }

  .min-h-sm-425px {
    min-height: 425px !important;
  }

  .min-h-sm-450px {
    min-height: 450px !important;
  }

  .min-h-sm-475px {
    min-height: 475px !important;
  }

  .min-h-sm-500px {
    min-height: 500px !important;
  }

  .min-h-sm-550px {
    min-height: 550px !important;
  }

  .min-h-sm-600px {
    min-height: 600px !important;
  }

  .min-h-sm-650px {
    min-height: 650px !important;
  }

  .min-h-sm-700px {
    min-height: 700px !important;
  }

  .min-h-sm-750px {
    min-height: 750px !important;
  }

  .min-h-sm-800px {
    min-height: 800px !important;
  }

  .min-h-sm-850px {
    min-height: 850px !important;
  }

  .min-h-sm-900px {
    min-height: 900px !important;
  }

  .min-h-sm-950px {
    min-height: 950px !important;
  }

  .min-h-sm-1000px {
    min-height: 1000px !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .w-md-unset {
    width: unset !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .w-md-1px {
    width: 1px !important;
  }

  .w-md-2px {
    width: 2px !important;
  }

  .w-md-3px {
    width: 3px !important;
  }

  .w-md-4px {
    width: 4px !important;
  }

  .w-md-5px {
    width: 5px !important;
  }

  .w-md-6px {
    width: 6px !important;
  }

  .w-md-7px {
    width: 7px !important;
  }

  .w-md-8px {
    width: 8px !important;
  }

  .w-md-9px {
    width: 9px !important;
  }

  .w-md-10px {
    width: 10px !important;
  }

  .w-md-15px {
    width: 15px !important;
  }

  .w-md-20px {
    width: 20px !important;
  }

  .w-md-25px {
    width: 25px !important;
  }

  .w-md-30px {
    width: 30px !important;
  }

  .w-md-35px {
    width: 35px !important;
  }

  .w-md-40px {
    width: 40px !important;
  }

  .w-md-45px {
    width: 45px !important;
  }

  .w-md-50px {
    width: 50px !important;
  }

  .w-md-55px {
    width: 55px !important;
  }

  .w-md-60px {
    width: 60px !important;
  }

  .w-md-65px {
    width: 65px !important;
  }

  .w-md-70px {
    width: 70px !important;
  }

  .w-md-75px {
    width: 75px !important;
  }

  .w-md-80px {
    width: 80px !important;
  }

  .w-md-85px {
    width: 85px !important;
  }

  .w-md-90px {
    width: 90px !important;
  }

  .w-md-95px {
    width: 95px !important;
  }

  .w-md-100px {
    width: 100px !important;
  }

  .w-md-125px {
    width: 125px !important;
  }

  .w-md-150px {
    width: 150px !important;
  }

  .w-md-175px {
    width: 175px !important;
  }

  .w-md-200px {
    width: 200px !important;
  }

  .w-md-225px {
    width: 225px !important;
  }

  .w-md-250px {
    width: 250px !important;
  }

  .w-md-275px {
    width: 275px !important;
  }

  .w-md-300px {
    width: 300px !important;
  }

  .w-md-325px {
    width: 325px !important;
  }

  .w-md-350px {
    width: 350px !important;
  }

  .w-md-375px {
    width: 375px !important;
  }

  .w-md-400px {
    width: 400px !important;
  }

  .w-md-425px {
    width: 425px !important;
  }

  .w-md-450px {
    width: 450px !important;
  }

  .w-md-475px {
    width: 475px !important;
  }

  .w-md-500px {
    width: 500px !important;
  }

  .w-md-550px {
    width: 550px !important;
  }

  .w-md-600px {
    width: 600px !important;
  }

  .w-md-650px {
    width: 650px !important;
  }

  .w-md-700px {
    width: 700px !important;
  }

  .w-md-750px {
    width: 750px !important;
  }

  .w-md-800px {
    width: 800px !important;
  }

  .w-md-850px {
    width: 850px !important;
  }

  .w-md-900px {
    width: 900px !important;
  }

  .w-md-950px {
    width: 950px !important;
  }

  .w-md-1000px {
    width: 1000px !important;
  }

  .mw-md-unset {
    max-width: unset !important;
  }

  .mw-md-25 {
    max-width: 25% !important;
  }

  .mw-md-50 {
    max-width: 50% !important;
  }

  .mw-md-75 {
    max-width: 75% !important;
  }

  .mw-md-100 {
    max-width: 100% !important;
  }

  .mw-md-auto {
    max-width: auto !important;
  }

  .mw-md-1px {
    max-width: 1px !important;
  }

  .mw-md-2px {
    max-width: 2px !important;
  }

  .mw-md-3px {
    max-width: 3px !important;
  }

  .mw-md-4px {
    max-width: 4px !important;
  }

  .mw-md-5px {
    max-width: 5px !important;
  }

  .mw-md-6px {
    max-width: 6px !important;
  }

  .mw-md-7px {
    max-width: 7px !important;
  }

  .mw-md-8px {
    max-width: 8px !important;
  }

  .mw-md-9px {
    max-width: 9px !important;
  }

  .mw-md-10px {
    max-width: 10px !important;
  }

  .mw-md-15px {
    max-width: 15px !important;
  }

  .mw-md-20px {
    max-width: 20px !important;
  }

  .mw-md-25px {
    max-width: 25px !important;
  }

  .mw-md-30px {
    max-width: 30px !important;
  }

  .mw-md-35px {
    max-width: 35px !important;
  }

  .mw-md-40px {
    max-width: 40px !important;
  }

  .mw-md-45px {
    max-width: 45px !important;
  }

  .mw-md-50px {
    max-width: 50px !important;
  }

  .mw-md-55px {
    max-width: 55px !important;
  }

  .mw-md-60px {
    max-width: 60px !important;
  }

  .mw-md-65px {
    max-width: 65px !important;
  }

  .mw-md-70px {
    max-width: 70px !important;
  }

  .mw-md-75px {
    max-width: 75px !important;
  }

  .mw-md-80px {
    max-width: 80px !important;
  }

  .mw-md-85px {
    max-width: 85px !important;
  }

  .mw-md-90px {
    max-width: 90px !important;
  }

  .mw-md-95px {
    max-width: 95px !important;
  }

  .mw-md-100px {
    max-width: 100px !important;
  }

  .mw-md-125px {
    max-width: 125px !important;
  }

  .mw-md-150px {
    max-width: 150px !important;
  }

  .mw-md-175px {
    max-width: 175px !important;
  }

  .mw-md-200px {
    max-width: 200px !important;
  }

  .mw-md-225px {
    max-width: 225px !important;
  }

  .mw-md-250px {
    max-width: 250px !important;
  }

  .mw-md-275px {
    max-width: 275px !important;
  }

  .mw-md-300px {
    max-width: 300px !important;
  }

  .mw-md-325px {
    max-width: 325px !important;
  }

  .mw-md-350px {
    max-width: 350px !important;
  }

  .mw-md-375px {
    max-width: 375px !important;
  }

  .mw-md-400px {
    max-width: 400px !important;
  }

  .mw-md-425px {
    max-width: 425px !important;
  }

  .mw-md-450px {
    max-width: 450px !important;
  }

  .mw-md-475px {
    max-width: 475px !important;
  }

  .mw-md-500px {
    max-width: 500px !important;
  }

  .mw-md-550px {
    max-width: 550px !important;
  }

  .mw-md-600px {
    max-width: 600px !important;
  }

  .mw-md-650px {
    max-width: 650px !important;
  }

  .mw-md-700px {
    max-width: 700px !important;
  }

  .mw-md-750px {
    max-width: 750px !important;
  }

  .mw-md-800px {
    max-width: 800px !important;
  }

  .mw-md-850px {
    max-width: 850px !important;
  }

  .mw-md-900px {
    max-width: 900px !important;
  }

  .mw-md-950px {
    max-width: 950px !important;
  }

  .mw-md-1000px {
    max-width: 1000px !important;
  }

  .h-md-unset {
    height: unset !important;
  }

  .h-md-25 {
    height: 25% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .h-md-auto {
    height: auto !important;
  }

  .h-md-1px {
    height: 1px !important;
  }

  .h-md-2px {
    height: 2px !important;
  }

  .h-md-3px {
    height: 3px !important;
  }

  .h-md-4px {
    height: 4px !important;
  }

  .h-md-5px {
    height: 5px !important;
  }

  .h-md-6px {
    height: 6px !important;
  }

  .h-md-7px {
    height: 7px !important;
  }

  .h-md-8px {
    height: 8px !important;
  }

  .h-md-9px {
    height: 9px !important;
  }

  .h-md-10px {
    height: 10px !important;
  }

  .h-md-15px {
    height: 15px !important;
  }

  .h-md-20px {
    height: 20px !important;
  }

  .h-md-25px {
    height: 25px !important;
  }

  .h-md-30px {
    height: 30px !important;
  }

  .h-md-35px {
    height: 35px !important;
  }

  .h-md-40px {
    height: 40px !important;
  }

  .h-md-45px {
    height: 45px !important;
  }

  .h-md-50px {
    height: 50px !important;
  }

  .h-md-55px {
    height: 55px !important;
  }

  .h-md-60px {
    height: 60px !important;
  }

  .h-md-65px {
    height: 65px !important;
  }

  .h-md-70px {
    height: 70px !important;
  }

  .h-md-75px {
    height: 75px !important;
  }

  .h-md-80px {
    height: 80px !important;
  }

  .h-md-85px {
    height: 85px !important;
  }

  .h-md-90px {
    height: 90px !important;
  }

  .h-md-95px {
    height: 95px !important;
  }

  .h-md-100px {
    height: 100px !important;
  }

  .h-md-125px {
    height: 125px !important;
  }

  .h-md-150px {
    height: 150px !important;
  }

  .h-md-175px {
    height: 175px !important;
  }

  .h-md-200px {
    height: 200px !important;
  }

  .h-md-225px {
    height: 225px !important;
  }

  .h-md-250px {
    height: 250px !important;
  }

  .h-md-275px {
    height: 275px !important;
  }

  .h-md-300px {
    height: 300px !important;
  }

  .h-md-325px {
    height: 325px !important;
  }

  .h-md-350px {
    height: 350px !important;
  }

  .h-md-375px {
    height: 375px !important;
  }

  .h-md-400px {
    height: 400px !important;
  }

  .h-md-425px {
    height: 425px !important;
  }

  .h-md-450px {
    height: 450px !important;
  }

  .h-md-475px {
    height: 475px !important;
  }

  .h-md-500px {
    height: 500px !important;
  }

  .h-md-550px {
    height: 550px !important;
  }

  .h-md-600px {
    height: 600px !important;
  }

  .h-md-650px {
    height: 650px !important;
  }

  .h-md-700px {
    height: 700px !important;
  }

  .h-md-750px {
    height: 750px !important;
  }

  .h-md-800px {
    height: 800px !important;
  }

  .h-md-850px {
    height: 850px !important;
  }

  .h-md-900px {
    height: 900px !important;
  }

  .h-md-950px {
    height: 950px !important;
  }

  .h-md-1000px {
    height: 1000px !important;
  }

  .mh-md-unset {
    max-height: unset !important;
  }

  .mh-md-25 {
    max-height: 25% !important;
  }

  .mh-md-50 {
    max-height: 50% !important;
  }

  .mh-md-75 {
    max-height: 75% !important;
  }

  .mh-md-100 {
    max-height: 100% !important;
  }

  .mh-md-auto {
    max-height: auto !important;
  }

  .mh-md-1px {
    max-height: 1px !important;
  }

  .mh-md-2px {
    max-height: 2px !important;
  }

  .mh-md-3px {
    max-height: 3px !important;
  }

  .mh-md-4px {
    max-height: 4px !important;
  }

  .mh-md-5px {
    max-height: 5px !important;
  }

  .mh-md-6px {
    max-height: 6px !important;
  }

  .mh-md-7px {
    max-height: 7px !important;
  }

  .mh-md-8px {
    max-height: 8px !important;
  }

  .mh-md-9px {
    max-height: 9px !important;
  }

  .mh-md-10px {
    max-height: 10px !important;
  }

  .mh-md-15px {
    max-height: 15px !important;
  }

  .mh-md-20px {
    max-height: 20px !important;
  }

  .mh-md-25px {
    max-height: 25px !important;
  }

  .mh-md-30px {
    max-height: 30px !important;
  }

  .mh-md-35px {
    max-height: 35px !important;
  }

  .mh-md-40px {
    max-height: 40px !important;
  }

  .mh-md-45px {
    max-height: 45px !important;
  }

  .mh-md-50px {
    max-height: 50px !important;
  }

  .mh-md-55px {
    max-height: 55px !important;
  }

  .mh-md-60px {
    max-height: 60px !important;
  }

  .mh-md-65px {
    max-height: 65px !important;
  }

  .mh-md-70px {
    max-height: 70px !important;
  }

  .mh-md-75px {
    max-height: 75px !important;
  }

  .mh-md-80px {
    max-height: 80px !important;
  }

  .mh-md-85px {
    max-height: 85px !important;
  }

  .mh-md-90px {
    max-height: 90px !important;
  }

  .mh-md-95px {
    max-height: 95px !important;
  }

  .mh-md-100px {
    max-height: 100px !important;
  }

  .mh-md-125px {
    max-height: 125px !important;
  }

  .mh-md-150px {
    max-height: 150px !important;
  }

  .mh-md-175px {
    max-height: 175px !important;
  }

  .mh-md-200px {
    max-height: 200px !important;
  }

  .mh-md-225px {
    max-height: 225px !important;
  }

  .mh-md-250px {
    max-height: 250px !important;
  }

  .mh-md-275px {
    max-height: 275px !important;
  }

  .mh-md-300px {
    max-height: 300px !important;
  }

  .mh-md-325px {
    max-height: 325px !important;
  }

  .mh-md-350px {
    max-height: 350px !important;
  }

  .mh-md-375px {
    max-height: 375px !important;
  }

  .mh-md-400px {
    max-height: 400px !important;
  }

  .mh-md-425px {
    max-height: 425px !important;
  }

  .mh-md-450px {
    max-height: 450px !important;
  }

  .mh-md-475px {
    max-height: 475px !important;
  }

  .mh-md-500px {
    max-height: 500px !important;
  }

  .mh-md-550px {
    max-height: 550px !important;
  }

  .mh-md-600px {
    max-height: 600px !important;
  }

  .mh-md-650px {
    max-height: 650px !important;
  }

  .mh-md-700px {
    max-height: 700px !important;
  }

  .mh-md-750px {
    max-height: 750px !important;
  }

  .mh-md-800px {
    max-height: 800px !important;
  }

  .mh-md-850px {
    max-height: 850px !important;
  }

  .mh-md-900px {
    max-height: 900px !important;
  }

  .mh-md-950px {
    max-height: 950px !important;
  }

  .mh-md-1000px {
    max-height: 1000px !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 0.75rem !important;
  }

  .m-md-4 {
    margin: 1rem !important;
  }

  .m-md-5 {
    margin: 1.25rem !important;
  }

  .m-md-6 {
    margin: 1.5rem !important;
  }

  .m-md-7 {
    margin: 1.75rem !important;
  }

  .m-md-8 {
    margin: 2rem !important;
  }

  .m-md-9 {
    margin: 2.25rem !important;
  }

  .m-md-10 {
    margin: 2.5rem !important;
  }

  .m-md-11 {
    margin: 2.75rem !important;
  }

  .m-md-12 {
    margin: 3rem !important;
  }

  .m-md-13 {
    margin: 3.25rem !important;
  }

  .m-md-14 {
    margin: 3.5rem !important;
  }

  .m-md-15 {
    margin: 3.75rem !important;
  }

  .m-md-16 {
    margin: 4rem !important;
  }

  .m-md-17 {
    margin: 4.25rem !important;
  }

  .m-md-18 {
    margin: 4.5rem !important;
  }

  .m-md-19 {
    margin: 4.75rem !important;
  }

  .m-md-20 {
    margin: 5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-md-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-md-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-md-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-md-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-md-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-md-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-md-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-md-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-md-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-md-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-md-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-md-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-md-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-md-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-md-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-md-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-md-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-md-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-md-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 0.75rem !important;
  }

  .mt-md-4 {
    margin-top: 1rem !important;
  }

  .mt-md-5 {
    margin-top: 1.25rem !important;
  }

  .mt-md-6 {
    margin-top: 1.5rem !important;
  }

  .mt-md-7 {
    margin-top: 1.75rem !important;
  }

  .mt-md-8 {
    margin-top: 2rem !important;
  }

  .mt-md-9 {
    margin-top: 2.25rem !important;
  }

  .mt-md-10 {
    margin-top: 2.5rem !important;
  }

  .mt-md-11 {
    margin-top: 2.75rem !important;
  }

  .mt-md-12 {
    margin-top: 3rem !important;
  }

  .mt-md-13 {
    margin-top: 3.25rem !important;
  }

  .mt-md-14 {
    margin-top: 3.5rem !important;
  }

  .mt-md-15 {
    margin-top: 3.75rem !important;
  }

  .mt-md-16 {
    margin-top: 4rem !important;
  }

  .mt-md-17 {
    margin-top: 4.25rem !important;
  }

  .mt-md-18 {
    margin-top: 4.5rem !important;
  }

  .mt-md-19 {
    margin-top: 4.75rem !important;
  }

  .mt-md-20 {
    margin-top: 5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 0.75rem !important;
  }

  .me-md-4 {
    margin-right: 1rem !important;
  }

  .me-md-5 {
    margin-right: 1.25rem !important;
  }

  .me-md-6 {
    margin-right: 1.5rem !important;
  }

  .me-md-7 {
    margin-right: 1.75rem !important;
  }

  .me-md-8 {
    margin-right: 2rem !important;
  }

  .me-md-9 {
    margin-right: 2.25rem !important;
  }

  .me-md-10 {
    margin-right: 2.5rem !important;
  }

  .me-md-11 {
    margin-right: 2.75rem !important;
  }

  .me-md-12 {
    margin-right: 3rem !important;
  }

  .me-md-13 {
    margin-right: 3.25rem !important;
  }

  .me-md-14 {
    margin-right: 3.5rem !important;
  }

  .me-md-15 {
    margin-right: 3.75rem !important;
  }

  .me-md-16 {
    margin-right: 4rem !important;
  }

  .me-md-17 {
    margin-right: 4.25rem !important;
  }

  .me-md-18 {
    margin-right: 4.5rem !important;
  }

  .me-md-19 {
    margin-right: 4.75rem !important;
  }

  .me-md-20 {
    margin-right: 5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1rem !important;
  }

  .mb-md-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-md-8 {
    margin-bottom: 2rem !important;
  }

  .mb-md-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-md-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-md-12 {
    margin-bottom: 3rem !important;
  }

  .mb-md-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-md-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-md-16 {
    margin-bottom: 4rem !important;
  }

  .mb-md-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-md-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-md-20 {
    margin-bottom: 5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 0.75rem !important;
  }

  .ms-md-4 {
    margin-left: 1rem !important;
  }

  .ms-md-5 {
    margin-left: 1.25rem !important;
  }

  .ms-md-6 {
    margin-left: 1.5rem !important;
  }

  .ms-md-7 {
    margin-left: 1.75rem !important;
  }

  .ms-md-8 {
    margin-left: 2rem !important;
  }

  .ms-md-9 {
    margin-left: 2.25rem !important;
  }

  .ms-md-10 {
    margin-left: 2.5rem !important;
  }

  .ms-md-11 {
    margin-left: 2.75rem !important;
  }

  .ms-md-12 {
    margin-left: 3rem !important;
  }

  .ms-md-13 {
    margin-left: 3.25rem !important;
  }

  .ms-md-14 {
    margin-left: 3.5rem !important;
  }

  .ms-md-15 {
    margin-left: 3.75rem !important;
  }

  .ms-md-16 {
    margin-left: 4rem !important;
  }

  .ms-md-17 {
    margin-left: 4.25rem !important;
  }

  .ms-md-18 {
    margin-left: 4.5rem !important;
  }

  .ms-md-19 {
    margin-left: 4.75rem !important;
  }

  .ms-md-20 {
    margin-left: 5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -0.75rem !important;
  }

  .m-md-n4 {
    margin: -1rem !important;
  }

  .m-md-n5 {
    margin: -1.25rem !important;
  }

  .m-md-n6 {
    margin: -1.5rem !important;
  }

  .m-md-n7 {
    margin: -1.75rem !important;
  }

  .m-md-n8 {
    margin: -2rem !important;
  }

  .m-md-n9 {
    margin: -2.25rem !important;
  }

  .m-md-n10 {
    margin: -2.5rem !important;
  }

  .m-md-n11 {
    margin: -2.75rem !important;
  }

  .m-md-n12 {
    margin: -3rem !important;
  }

  .m-md-n13 {
    margin: -3.25rem !important;
  }

  .m-md-n14 {
    margin: -3.5rem !important;
  }

  .m-md-n15 {
    margin: -3.75rem !important;
  }

  .m-md-n16 {
    margin: -4rem !important;
  }

  .m-md-n17 {
    margin: -4.25rem !important;
  }

  .m-md-n18 {
    margin: -4.5rem !important;
  }

  .m-md-n19 {
    margin: -4.75rem !important;
  }

  .m-md-n20 {
    margin: -5rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-md-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-md-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-md-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-md-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-md-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-md-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-md-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-md-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-md-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-md-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-md-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-md-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-md-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-md-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-md-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-md-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-md-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-md-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-md-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-md-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-md-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-md-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-md-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-md-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-md-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-md-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-md-n4 {
    margin-top: -1rem !important;
  }

  .mt-md-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-md-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-md-n8 {
    margin-top: -2rem !important;
  }

  .mt-md-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-md-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-md-n12 {
    margin-top: -3rem !important;
  }

  .mt-md-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-md-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-md-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-md-n16 {
    margin-top: -4rem !important;
  }

  .mt-md-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-md-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-md-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-md-n20 {
    margin-top: -5rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -0.75rem !important;
  }

  .me-md-n4 {
    margin-right: -1rem !important;
  }

  .me-md-n5 {
    margin-right: -1.25rem !important;
  }

  .me-md-n6 {
    margin-right: -1.5rem !important;
  }

  .me-md-n7 {
    margin-right: -1.75rem !important;
  }

  .me-md-n8 {
    margin-right: -2rem !important;
  }

  .me-md-n9 {
    margin-right: -2.25rem !important;
  }

  .me-md-n10 {
    margin-right: -2.5rem !important;
  }

  .me-md-n11 {
    margin-right: -2.75rem !important;
  }

  .me-md-n12 {
    margin-right: -3rem !important;
  }

  .me-md-n13 {
    margin-right: -3.25rem !important;
  }

  .me-md-n14 {
    margin-right: -3.5rem !important;
  }

  .me-md-n15 {
    margin-right: -3.75rem !important;
  }

  .me-md-n16 {
    margin-right: -4rem !important;
  }

  .me-md-n17 {
    margin-right: -4.25rem !important;
  }

  .me-md-n18 {
    margin-right: -4.5rem !important;
  }

  .me-md-n19 {
    margin-right: -4.75rem !important;
  }

  .me-md-n20 {
    margin-right: -5rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-md-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-md-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-md-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-md-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-md-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-md-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-md-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-md-n4 {
    margin-left: -1rem !important;
  }

  .ms-md-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-md-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-md-n8 {
    margin-left: -2rem !important;
  }

  .ms-md-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-md-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-md-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-md-n12 {
    margin-left: -3rem !important;
  }

  .ms-md-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-md-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-md-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-md-n16 {
    margin-left: -4rem !important;
  }

  .ms-md-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-md-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-md-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-md-n20 {
    margin-left: -5rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 0.75rem !important;
  }

  .p-md-4 {
    padding: 1rem !important;
  }

  .p-md-5 {
    padding: 1.25rem !important;
  }

  .p-md-6 {
    padding: 1.5rem !important;
  }

  .p-md-7 {
    padding: 1.75rem !important;
  }

  .p-md-8 {
    padding: 2rem !important;
  }

  .p-md-9 {
    padding: 2.25rem !important;
  }

  .p-md-10 {
    padding: 2.5rem !important;
  }

  .p-md-11 {
    padding: 2.75rem !important;
  }

  .p-md-12 {
    padding: 3rem !important;
  }

  .p-md-13 {
    padding: 3.25rem !important;
  }

  .p-md-14 {
    padding: 3.5rem !important;
  }

  .p-md-15 {
    padding: 3.75rem !important;
  }

  .p-md-16 {
    padding: 4rem !important;
  }

  .p-md-17 {
    padding: 4.25rem !important;
  }

  .p-md-18 {
    padding: 4.5rem !important;
  }

  .p-md-19 {
    padding: 4.75rem !important;
  }

  .p-md-20 {
    padding: 5rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-md-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-md-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-md-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-md-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-md-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-md-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-md-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-md-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-md-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-md-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-md-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-md-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-md-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-md-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-md-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-md-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-md-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-md-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-md-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-md-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 0.75rem !important;
  }

  .pt-md-4 {
    padding-top: 1rem !important;
  }

  .pt-md-5 {
    padding-top: 1.25rem !important;
  }

  .pt-md-6 {
    padding-top: 1.5rem !important;
  }

  .pt-md-7 {
    padding-top: 1.75rem !important;
  }

  .pt-md-8 {
    padding-top: 2rem !important;
  }

  .pt-md-9 {
    padding-top: 2.25rem !important;
  }

  .pt-md-10 {
    padding-top: 2.5rem !important;
  }

  .pt-md-11 {
    padding-top: 2.75rem !important;
  }

  .pt-md-12 {
    padding-top: 3rem !important;
  }

  .pt-md-13 {
    padding-top: 3.25rem !important;
  }

  .pt-md-14 {
    padding-top: 3.5rem !important;
  }

  .pt-md-15 {
    padding-top: 3.75rem !important;
  }

  .pt-md-16 {
    padding-top: 4rem !important;
  }

  .pt-md-17 {
    padding-top: 4.25rem !important;
  }

  .pt-md-18 {
    padding-top: 4.5rem !important;
  }

  .pt-md-19 {
    padding-top: 4.75rem !important;
  }

  .pt-md-20 {
    padding-top: 5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 0.75rem !important;
  }

  .pe-md-4 {
    padding-right: 1rem !important;
  }

  .pe-md-5 {
    padding-right: 1.25rem !important;
  }

  .pe-md-6 {
    padding-right: 1.5rem !important;
  }

  .pe-md-7 {
    padding-right: 1.75rem !important;
  }

  .pe-md-8 {
    padding-right: 2rem !important;
  }

  .pe-md-9 {
    padding-right: 2.25rem !important;
  }

  .pe-md-10 {
    padding-right: 2.5rem !important;
  }

  .pe-md-11 {
    padding-right: 2.75rem !important;
  }

  .pe-md-12 {
    padding-right: 3rem !important;
  }

  .pe-md-13 {
    padding-right: 3.25rem !important;
  }

  .pe-md-14 {
    padding-right: 3.5rem !important;
  }

  .pe-md-15 {
    padding-right: 3.75rem !important;
  }

  .pe-md-16 {
    padding-right: 4rem !important;
  }

  .pe-md-17 {
    padding-right: 4.25rem !important;
  }

  .pe-md-18 {
    padding-right: 4.5rem !important;
  }

  .pe-md-19 {
    padding-right: 4.75rem !important;
  }

  .pe-md-20 {
    padding-right: 5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1rem !important;
  }

  .pb-md-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-md-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-md-8 {
    padding-bottom: 2rem !important;
  }

  .pb-md-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-md-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-md-12 {
    padding-bottom: 3rem !important;
  }

  .pb-md-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-md-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-md-16 {
    padding-bottom: 4rem !important;
  }

  .pb-md-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-md-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-md-20 {
    padding-bottom: 5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 0.75rem !important;
  }

  .ps-md-4 {
    padding-left: 1rem !important;
  }

  .ps-md-5 {
    padding-left: 1.25rem !important;
  }

  .ps-md-6 {
    padding-left: 1.5rem !important;
  }

  .ps-md-7 {
    padding-left: 1.75rem !important;
  }

  .ps-md-8 {
    padding-left: 2rem !important;
  }

  .ps-md-9 {
    padding-left: 2.25rem !important;
  }

  .ps-md-10 {
    padding-left: 2.5rem !important;
  }

  .ps-md-11 {
    padding-left: 2.75rem !important;
  }

  .ps-md-12 {
    padding-left: 3rem !important;
  }

  .ps-md-13 {
    padding-left: 3.25rem !important;
  }

  .ps-md-14 {
    padding-left: 3.5rem !important;
  }

  .ps-md-15 {
    padding-left: 3.75rem !important;
  }

  .ps-md-16 {
    padding-left: 4rem !important;
  }

  .ps-md-17 {
    padding-left: 4.25rem !important;
  }

  .ps-md-18 {
    padding-left: 4.5rem !important;
  }

  .ps-md-19 {
    padding-left: 4.75rem !important;
  }

  .ps-md-20 {
    padding-left: 5rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 0.75rem !important;
  }

  .gap-md-4 {
    gap: 1rem !important;
  }

  .gap-md-5 {
    gap: 1.25rem !important;
  }

  .gap-md-6 {
    gap: 1.5rem !important;
  }

  .gap-md-7 {
    gap: 1.75rem !important;
  }

  .gap-md-8 {
    gap: 2rem !important;
  }

  .gap-md-9 {
    gap: 2.25rem !important;
  }

  .gap-md-10 {
    gap: 2.5rem !important;
  }

  .gap-md-11 {
    gap: 2.75rem !important;
  }

  .gap-md-12 {
    gap: 3rem !important;
  }

  .gap-md-13 {
    gap: 3.25rem !important;
  }

  .gap-md-14 {
    gap: 3.5rem !important;
  }

  .gap-md-15 {
    gap: 3.75rem !important;
  }

  .gap-md-16 {
    gap: 4rem !important;
  }

  .gap-md-17 {
    gap: 4.25rem !important;
  }

  .gap-md-18 {
    gap: 4.5rem !important;
  }

  .gap-md-19 {
    gap: 4.75rem !important;
  }

  .gap-md-20 {
    gap: 5rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1rem !important;
  }

  .row-gap-md-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-md-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-md-8 {
    row-gap: 2rem !important;
  }

  .row-gap-md-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-md-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-md-11 {
    row-gap: 2.75rem !important;
  }

  .row-gap-md-12 {
    row-gap: 3rem !important;
  }

  .row-gap-md-13 {
    row-gap: 3.25rem !important;
  }

  .row-gap-md-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-md-15 {
    row-gap: 3.75rem !important;
  }

  .row-gap-md-16 {
    row-gap: 4rem !important;
  }

  .row-gap-md-17 {
    row-gap: 4.25rem !important;
  }

  .row-gap-md-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-md-19 {
    row-gap: 4.75rem !important;
  }

  .row-gap-md-20 {
    row-gap: 5rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1rem !important;
  }

  .column-gap-md-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-md-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-md-8 {
    column-gap: 2rem !important;
  }

  .column-gap-md-9 {
    column-gap: 2.25rem !important;
  }

  .column-gap-md-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-md-11 {
    column-gap: 2.75rem !important;
  }

  .column-gap-md-12 {
    column-gap: 3rem !important;
  }

  .column-gap-md-13 {
    column-gap: 3.25rem !important;
  }

  .column-gap-md-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-md-15 {
    column-gap: 3.75rem !important;
  }

  .column-gap-md-16 {
    column-gap: 4rem !important;
  }

  .column-gap-md-17 {
    column-gap: 4.25rem !important;
  }

  .column-gap-md-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-md-19 {
    column-gap: 4.75rem !important;
  }

  .column-gap-md-20 {
    column-gap: 5rem !important;
  }

  .fs-md-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-md-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-md-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-md-4 {
    font-size: 1.25rem !important;
  }

  .fs-md-5 {
    font-size: 1.15rem !important;
  }

  .fs-md-6 {
    font-size: 1.075rem !important;
  }

  .fs-md-7 {
    font-size: 0.95rem !important;
  }

  .fs-md-8 {
    font-size: 0.85rem !important;
  }

  .fs-md-9 {
    font-size: 0.75rem !important;
  }

  .fs-md-10 {
    font-size: 0.5rem !important;
  }

  .fs-md-base {
    font-size: 1rem !important;
  }

  .fs-md-fluid {
    font-size: 100% !important;
  }

  .fs-md-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-md-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-md-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-md-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-md-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-md-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-md-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-md-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-md-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-md-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-md-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-md-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-md-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-md-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-md-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-md-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }

  .fs-md-10x {
    font-size: calc(2.125rem + 10.5vw) !important;
  }

  .fs-md-10qx {
    font-size: calc(2.15rem + 10.8vw) !important;
  }

  .fs-md-10hx {
    font-size: calc(2.175rem + 11.1vw) !important;
  }

  .fs-md-10tx {
    font-size: calc(2.2rem + 11.4vw) !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .min-w-md-unset {
    min-width: unset !important;
  }

  .min-w-md-25 {
    min-width: 25% !important;
  }

  .min-w-md-50 {
    min-width: 50% !important;
  }

  .min-w-md-75 {
    min-width: 75% !important;
  }

  .min-w-md-100 {
    min-width: 100% !important;
  }

  .min-w-md-auto {
    min-width: auto !important;
  }

  .min-w-md-1px {
    min-width: 1px !important;
  }

  .min-w-md-2px {
    min-width: 2px !important;
  }

  .min-w-md-3px {
    min-width: 3px !important;
  }

  .min-w-md-4px {
    min-width: 4px !important;
  }

  .min-w-md-5px {
    min-width: 5px !important;
  }

  .min-w-md-6px {
    min-width: 6px !important;
  }

  .min-w-md-7px {
    min-width: 7px !important;
  }

  .min-w-md-8px {
    min-width: 8px !important;
  }

  .min-w-md-9px {
    min-width: 9px !important;
  }

  .min-w-md-10px {
    min-width: 10px !important;
  }

  .min-w-md-15px {
    min-width: 15px !important;
  }

  .min-w-md-20px {
    min-width: 20px !important;
  }

  .min-w-md-25px {
    min-width: 25px !important;
  }

  .min-w-md-30px {
    min-width: 30px !important;
  }

  .min-w-md-35px {
    min-width: 35px !important;
  }

  .min-w-md-40px {
    min-width: 40px !important;
  }

  .min-w-md-45px {
    min-width: 45px !important;
  }

  .min-w-md-50px {
    min-width: 50px !important;
  }

  .min-w-md-55px {
    min-width: 55px !important;
  }

  .min-w-md-60px {
    min-width: 60px !important;
  }

  .min-w-md-65px {
    min-width: 65px !important;
  }

  .min-w-md-70px {
    min-width: 70px !important;
  }

  .min-w-md-75px {
    min-width: 75px !important;
  }

  .min-w-md-80px {
    min-width: 80px !important;
  }

  .min-w-md-85px {
    min-width: 85px !important;
  }

  .min-w-md-90px {
    min-width: 90px !important;
  }

  .min-w-md-95px {
    min-width: 95px !important;
  }

  .min-w-md-100px {
    min-width: 100px !important;
  }

  .min-w-md-125px {
    min-width: 125px !important;
  }

  .min-w-md-150px {
    min-width: 150px !important;
  }

  .min-w-md-175px {
    min-width: 175px !important;
  }

  .min-w-md-200px {
    min-width: 200px !important;
  }

  .min-w-md-225px {
    min-width: 225px !important;
  }

  .min-w-md-250px {
    min-width: 250px !important;
  }

  .min-w-md-275px {
    min-width: 275px !important;
  }

  .min-w-md-300px {
    min-width: 300px !important;
  }

  .min-w-md-325px {
    min-width: 325px !important;
  }

  .min-w-md-350px {
    min-width: 350px !important;
  }

  .min-w-md-375px {
    min-width: 375px !important;
  }

  .min-w-md-400px {
    min-width: 400px !important;
  }

  .min-w-md-425px {
    min-width: 425px !important;
  }

  .min-w-md-450px {
    min-width: 450px !important;
  }

  .min-w-md-475px {
    min-width: 475px !important;
  }

  .min-w-md-500px {
    min-width: 500px !important;
  }

  .min-w-md-550px {
    min-width: 550px !important;
  }

  .min-w-md-600px {
    min-width: 600px !important;
  }

  .min-w-md-650px {
    min-width: 650px !important;
  }

  .min-w-md-700px {
    min-width: 700px !important;
  }

  .min-w-md-750px {
    min-width: 750px !important;
  }

  .min-w-md-800px {
    min-width: 800px !important;
  }

  .min-w-md-850px {
    min-width: 850px !important;
  }

  .min-w-md-900px {
    min-width: 900px !important;
  }

  .min-w-md-950px {
    min-width: 950px !important;
  }

  .min-w-md-1000px {
    min-width: 1000px !important;
  }

  .min-h-md-unset {
    min-height: unset !important;
  }

  .min-h-md-25 {
    min-height: 25% !important;
  }

  .min-h-md-50 {
    min-height: 50% !important;
  }

  .min-h-md-75 {
    min-height: 75% !important;
  }

  .min-h-md-100 {
    min-height: 100% !important;
  }

  .min-h-md-auto {
    min-height: auto !important;
  }

  .min-h-md-1px {
    min-height: 1px !important;
  }

  .min-h-md-2px {
    min-height: 2px !important;
  }

  .min-h-md-3px {
    min-height: 3px !important;
  }

  .min-h-md-4px {
    min-height: 4px !important;
  }

  .min-h-md-5px {
    min-height: 5px !important;
  }

  .min-h-md-6px {
    min-height: 6px !important;
  }

  .min-h-md-7px {
    min-height: 7px !important;
  }

  .min-h-md-8px {
    min-height: 8px !important;
  }

  .min-h-md-9px {
    min-height: 9px !important;
  }

  .min-h-md-10px {
    min-height: 10px !important;
  }

  .min-h-md-15px {
    min-height: 15px !important;
  }

  .min-h-md-20px {
    min-height: 20px !important;
  }

  .min-h-md-25px {
    min-height: 25px !important;
  }

  .min-h-md-30px {
    min-height: 30px !important;
  }

  .min-h-md-35px {
    min-height: 35px !important;
  }

  .min-h-md-40px {
    min-height: 40px !important;
  }

  .min-h-md-45px {
    min-height: 45px !important;
  }

  .min-h-md-50px {
    min-height: 50px !important;
  }

  .min-h-md-55px {
    min-height: 55px !important;
  }

  .min-h-md-60px {
    min-height: 60px !important;
  }

  .min-h-md-65px {
    min-height: 65px !important;
  }

  .min-h-md-70px {
    min-height: 70px !important;
  }

  .min-h-md-75px {
    min-height: 75px !important;
  }

  .min-h-md-80px {
    min-height: 80px !important;
  }

  .min-h-md-85px {
    min-height: 85px !important;
  }

  .min-h-md-90px {
    min-height: 90px !important;
  }

  .min-h-md-95px {
    min-height: 95px !important;
  }

  .min-h-md-100px {
    min-height: 100px !important;
  }

  .min-h-md-125px {
    min-height: 125px !important;
  }

  .min-h-md-150px {
    min-height: 150px !important;
  }

  .min-h-md-175px {
    min-height: 175px !important;
  }

  .min-h-md-200px {
    min-height: 200px !important;
  }

  .min-h-md-225px {
    min-height: 225px !important;
  }

  .min-h-md-250px {
    min-height: 250px !important;
  }

  .min-h-md-275px {
    min-height: 275px !important;
  }

  .min-h-md-300px {
    min-height: 300px !important;
  }

  .min-h-md-325px {
    min-height: 325px !important;
  }

  .min-h-md-350px {
    min-height: 350px !important;
  }

  .min-h-md-375px {
    min-height: 375px !important;
  }

  .min-h-md-400px {
    min-height: 400px !important;
  }

  .min-h-md-425px {
    min-height: 425px !important;
  }

  .min-h-md-450px {
    min-height: 450px !important;
  }

  .min-h-md-475px {
    min-height: 475px !important;
  }

  .min-h-md-500px {
    min-height: 500px !important;
  }

  .min-h-md-550px {
    min-height: 550px !important;
  }

  .min-h-md-600px {
    min-height: 600px !important;
  }

  .min-h-md-650px {
    min-height: 650px !important;
  }

  .min-h-md-700px {
    min-height: 700px !important;
  }

  .min-h-md-750px {
    min-height: 750px !important;
  }

  .min-h-md-800px {
    min-height: 800px !important;
  }

  .min-h-md-850px {
    min-height: 850px !important;
  }

  .min-h-md-900px {
    min-height: 900px !important;
  }

  .min-h-md-950px {
    min-height: 950px !important;
  }

  .min-h-md-1000px {
    min-height: 1000px !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .w-lg-unset {
    width: unset !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .w-lg-1px {
    width: 1px !important;
  }

  .w-lg-2px {
    width: 2px !important;
  }

  .w-lg-3px {
    width: 3px !important;
  }

  .w-lg-4px {
    width: 4px !important;
  }

  .w-lg-5px {
    width: 5px !important;
  }

  .w-lg-6px {
    width: 6px !important;
  }

  .w-lg-7px {
    width: 7px !important;
  }

  .w-lg-8px {
    width: 8px !important;
  }

  .w-lg-9px {
    width: 9px !important;
  }

  .w-lg-10px {
    width: 10px !important;
  }

  .w-lg-15px {
    width: 15px !important;
  }

  .w-lg-20px {
    width: 20px !important;
  }

  .w-lg-25px {
    width: 25px !important;
  }

  .w-lg-30px {
    width: 30px !important;
  }

  .w-lg-35px {
    width: 35px !important;
  }

  .w-lg-40px {
    width: 40px !important;
  }

  .w-lg-45px {
    width: 45px !important;
  }

  .w-lg-50px {
    width: 50px !important;
  }

  .w-lg-55px {
    width: 55px !important;
  }

  .w-lg-60px {
    width: 60px !important;
  }

  .w-lg-65px {
    width: 65px !important;
  }

  .w-lg-70px {
    width: 70px !important;
  }

  .w-lg-75px {
    width: 75px !important;
  }

  .w-lg-80px {
    width: 80px !important;
  }

  .w-lg-85px {
    width: 85px !important;
  }

  .w-lg-90px {
    width: 90px !important;
  }

  .w-lg-95px {
    width: 95px !important;
  }

  .w-lg-100px {
    width: 100px !important;
  }

  .w-lg-125px {
    width: 125px !important;
  }

  .w-lg-150px {
    width: 150px !important;
  }

  .w-lg-175px {
    width: 175px !important;
  }

  .w-lg-200px {
    width: 200px !important;
  }

  .w-lg-225px {
    width: 225px !important;
  }

  .w-lg-250px {
    width: 250px !important;
  }

  .w-lg-275px {
    width: 275px !important;
  }

  .w-lg-300px {
    width: 300px !important;
  }

  .w-lg-325px {
    width: 325px !important;
  }

  .w-lg-350px {
    width: 350px !important;
  }

  .w-lg-375px {
    width: 375px !important;
  }

  .w-lg-400px {
    width: 400px !important;
  }

  .w-lg-425px {
    width: 425px !important;
  }

  .w-lg-450px {
    width: 450px !important;
  }

  .w-lg-475px {
    width: 475px !important;
  }

  .w-lg-500px {
    width: 500px !important;
  }

  .w-lg-550px {
    width: 550px !important;
  }

  .w-lg-600px {
    width: 600px !important;
  }

  .w-lg-650px {
    width: 650px !important;
  }

  .w-lg-700px {
    width: 700px !important;
  }

  .w-lg-750px {
    width: 750px !important;
  }

  .w-lg-800px {
    width: 800px !important;
  }

  .w-lg-850px {
    width: 850px !important;
  }

  .w-lg-900px {
    width: 900px !important;
  }

  .w-lg-950px {
    width: 950px !important;
  }

  .w-lg-1000px {
    width: 1000px !important;
  }

  .mw-lg-unset {
    max-width: unset !important;
  }

  .mw-lg-25 {
    max-width: 25% !important;
  }

  .mw-lg-50 {
    max-width: 50% !important;
  }

  .mw-lg-75 {
    max-width: 75% !important;
  }

  .mw-lg-100 {
    max-width: 100% !important;
  }

  .mw-lg-auto {
    max-width: auto !important;
  }

  .mw-lg-1px {
    max-width: 1px !important;
  }

  .mw-lg-2px {
    max-width: 2px !important;
  }

  .mw-lg-3px {
    max-width: 3px !important;
  }

  .mw-lg-4px {
    max-width: 4px !important;
  }

  .mw-lg-5px {
    max-width: 5px !important;
  }

  .mw-lg-6px {
    max-width: 6px !important;
  }

  .mw-lg-7px {
    max-width: 7px !important;
  }

  .mw-lg-8px {
    max-width: 8px !important;
  }

  .mw-lg-9px {
    max-width: 9px !important;
  }

  .mw-lg-10px {
    max-width: 10px !important;
  }

  .mw-lg-15px {
    max-width: 15px !important;
  }

  .mw-lg-20px {
    max-width: 20px !important;
  }

  .mw-lg-25px {
    max-width: 25px !important;
  }

  .mw-lg-30px {
    max-width: 30px !important;
  }

  .mw-lg-35px {
    max-width: 35px !important;
  }

  .mw-lg-40px {
    max-width: 40px !important;
  }

  .mw-lg-45px {
    max-width: 45px !important;
  }

  .mw-lg-50px {
    max-width: 50px !important;
  }

  .mw-lg-55px {
    max-width: 55px !important;
  }

  .mw-lg-60px {
    max-width: 60px !important;
  }

  .mw-lg-65px {
    max-width: 65px !important;
  }

  .mw-lg-70px {
    max-width: 70px !important;
  }

  .mw-lg-75px {
    max-width: 75px !important;
  }

  .mw-lg-80px {
    max-width: 80px !important;
  }

  .mw-lg-85px {
    max-width: 85px !important;
  }

  .mw-lg-90px {
    max-width: 90px !important;
  }

  .mw-lg-95px {
    max-width: 95px !important;
  }

  .mw-lg-100px {
    max-width: 100px !important;
  }

  .mw-lg-125px {
    max-width: 125px !important;
  }

  .mw-lg-150px {
    max-width: 150px !important;
  }

  .mw-lg-175px {
    max-width: 175px !important;
  }

  .mw-lg-200px {
    max-width: 200px !important;
  }

  .mw-lg-225px {
    max-width: 225px !important;
  }

  .mw-lg-250px {
    max-width: 250px !important;
  }

  .mw-lg-275px {
    max-width: 275px !important;
  }

  .mw-lg-300px {
    max-width: 300px !important;
  }

  .mw-lg-325px {
    max-width: 325px !important;
  }

  .mw-lg-350px {
    max-width: 350px !important;
  }

  .mw-lg-375px {
    max-width: 375px !important;
  }

  .mw-lg-400px {
    max-width: 400px !important;
  }

  .mw-lg-425px {
    max-width: 425px !important;
  }

  .mw-lg-450px {
    max-width: 450px !important;
  }

  .mw-lg-475px {
    max-width: 475px !important;
  }

  .mw-lg-500px {
    max-width: 500px !important;
  }

  .mw-lg-550px {
    max-width: 550px !important;
  }

  .mw-lg-600px {
    max-width: 600px !important;
  }

  .mw-lg-650px {
    max-width: 650px !important;
  }

  .mw-lg-700px {
    max-width: 700px !important;
  }

  .mw-lg-750px {
    max-width: 750px !important;
  }

  .mw-lg-800px {
    max-width: 800px !important;
  }

  .mw-lg-850px {
    max-width: 850px !important;
  }

  .mw-lg-900px {
    max-width: 900px !important;
  }

  .mw-lg-950px {
    max-width: 950px !important;
  }

  .mw-lg-1000px {
    max-width: 1000px !important;
  }

  .h-lg-unset {
    height: unset !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .h-lg-auto {
    height: auto !important;
  }

  .h-lg-1px {
    height: 1px !important;
  }

  .h-lg-2px {
    height: 2px !important;
  }

  .h-lg-3px {
    height: 3px !important;
  }

  .h-lg-4px {
    height: 4px !important;
  }

  .h-lg-5px {
    height: 5px !important;
  }

  .h-lg-6px {
    height: 6px !important;
  }

  .h-lg-7px {
    height: 7px !important;
  }

  .h-lg-8px {
    height: 8px !important;
  }

  .h-lg-9px {
    height: 9px !important;
  }

  .h-lg-10px {
    height: 10px !important;
  }

  .h-lg-15px {
    height: 15px !important;
  }

  .h-lg-20px {
    height: 20px !important;
  }

  .h-lg-25px {
    height: 25px !important;
  }

  .h-lg-30px {
    height: 30px !important;
  }

  .h-lg-35px {
    height: 35px !important;
  }

  .h-lg-40px {
    height: 40px !important;
  }

  .h-lg-45px {
    height: 45px !important;
  }

  .h-lg-50px {
    height: 50px !important;
  }

  .h-lg-55px {
    height: 55px !important;
  }

  .h-lg-60px {
    height: 60px !important;
  }

  .h-lg-65px {
    height: 65px !important;
  }

  .h-lg-70px {
    height: 70px !important;
  }

  .h-lg-75px {
    height: 75px !important;
  }

  .h-lg-80px {
    height: 80px !important;
  }

  .h-lg-85px {
    height: 85px !important;
  }

  .h-lg-90px {
    height: 90px !important;
  }

  .h-lg-95px {
    height: 95px !important;
  }

  .h-lg-100px {
    height: 100px !important;
  }

  .h-lg-125px {
    height: 125px !important;
  }

  .h-lg-150px {
    height: 150px !important;
  }

  .h-lg-175px {
    height: 175px !important;
  }

  .h-lg-200px {
    height: 200px !important;
  }

  .h-lg-225px {
    height: 225px !important;
  }

  .h-lg-250px {
    height: 250px !important;
  }

  .h-lg-275px {
    height: 275px !important;
  }

  .h-lg-300px {
    height: 300px !important;
  }

  .h-lg-325px {
    height: 325px !important;
  }

  .h-lg-350px {
    height: 350px !important;
  }

  .h-lg-375px {
    height: 375px !important;
  }

  .h-lg-400px {
    height: 400px !important;
  }

  .h-lg-425px {
    height: 425px !important;
  }

  .h-lg-450px {
    height: 450px !important;
  }

  .h-lg-475px {
    height: 475px !important;
  }

  .h-lg-500px {
    height: 500px !important;
  }

  .h-lg-550px {
    height: 550px !important;
  }

  .h-lg-600px {
    height: 600px !important;
  }

  .h-lg-650px {
    height: 650px !important;
  }

  .h-lg-700px {
    height: 700px !important;
  }

  .h-lg-750px {
    height: 750px !important;
  }

  .h-lg-800px {
    height: 800px !important;
  }

  .h-lg-850px {
    height: 850px !important;
  }

  .h-lg-900px {
    height: 900px !important;
  }

  .h-lg-950px {
    height: 950px !important;
  }

  .h-lg-1000px {
    height: 1000px !important;
  }

  .mh-lg-unset {
    max-height: unset !important;
  }

  .mh-lg-25 {
    max-height: 25% !important;
  }

  .mh-lg-50 {
    max-height: 50% !important;
  }

  .mh-lg-75 {
    max-height: 75% !important;
  }

  .mh-lg-100 {
    max-height: 100% !important;
  }

  .mh-lg-auto {
    max-height: auto !important;
  }

  .mh-lg-1px {
    max-height: 1px !important;
  }

  .mh-lg-2px {
    max-height: 2px !important;
  }

  .mh-lg-3px {
    max-height: 3px !important;
  }

  .mh-lg-4px {
    max-height: 4px !important;
  }

  .mh-lg-5px {
    max-height: 5px !important;
  }

  .mh-lg-6px {
    max-height: 6px !important;
  }

  .mh-lg-7px {
    max-height: 7px !important;
  }

  .mh-lg-8px {
    max-height: 8px !important;
  }

  .mh-lg-9px {
    max-height: 9px !important;
  }

  .mh-lg-10px {
    max-height: 10px !important;
  }

  .mh-lg-15px {
    max-height: 15px !important;
  }

  .mh-lg-20px {
    max-height: 20px !important;
  }

  .mh-lg-25px {
    max-height: 25px !important;
  }

  .mh-lg-30px {
    max-height: 30px !important;
  }

  .mh-lg-35px {
    max-height: 35px !important;
  }

  .mh-lg-40px {
    max-height: 40px !important;
  }

  .mh-lg-45px {
    max-height: 45px !important;
  }

  .mh-lg-50px {
    max-height: 50px !important;
  }

  .mh-lg-55px {
    max-height: 55px !important;
  }

  .mh-lg-60px {
    max-height: 60px !important;
  }

  .mh-lg-65px {
    max-height: 65px !important;
  }

  .mh-lg-70px {
    max-height: 70px !important;
  }

  .mh-lg-75px {
    max-height: 75px !important;
  }

  .mh-lg-80px {
    max-height: 80px !important;
  }

  .mh-lg-85px {
    max-height: 85px !important;
  }

  .mh-lg-90px {
    max-height: 90px !important;
  }

  .mh-lg-95px {
    max-height: 95px !important;
  }

  .mh-lg-100px {
    max-height: 100px !important;
  }

  .mh-lg-125px {
    max-height: 125px !important;
  }

  .mh-lg-150px {
    max-height: 150px !important;
  }

  .mh-lg-175px {
    max-height: 175px !important;
  }

  .mh-lg-200px {
    max-height: 200px !important;
  }

  .mh-lg-225px {
    max-height: 225px !important;
  }

  .mh-lg-250px {
    max-height: 250px !important;
  }

  .mh-lg-275px {
    max-height: 275px !important;
  }

  .mh-lg-300px {
    max-height: 300px !important;
  }

  .mh-lg-325px {
    max-height: 325px !important;
  }

  .mh-lg-350px {
    max-height: 350px !important;
  }

  .mh-lg-375px {
    max-height: 375px !important;
  }

  .mh-lg-400px {
    max-height: 400px !important;
  }

  .mh-lg-425px {
    max-height: 425px !important;
  }

  .mh-lg-450px {
    max-height: 450px !important;
  }

  .mh-lg-475px {
    max-height: 475px !important;
  }

  .mh-lg-500px {
    max-height: 500px !important;
  }

  .mh-lg-550px {
    max-height: 550px !important;
  }

  .mh-lg-600px {
    max-height: 600px !important;
  }

  .mh-lg-650px {
    max-height: 650px !important;
  }

  .mh-lg-700px {
    max-height: 700px !important;
  }

  .mh-lg-750px {
    max-height: 750px !important;
  }

  .mh-lg-800px {
    max-height: 800px !important;
  }

  .mh-lg-850px {
    max-height: 850px !important;
  }

  .mh-lg-900px {
    max-height: 900px !important;
  }

  .mh-lg-950px {
    max-height: 950px !important;
  }

  .mh-lg-1000px {
    max-height: 1000px !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 0.75rem !important;
  }

  .m-lg-4 {
    margin: 1rem !important;
  }

  .m-lg-5 {
    margin: 1.25rem !important;
  }

  .m-lg-6 {
    margin: 1.5rem !important;
  }

  .m-lg-7 {
    margin: 1.75rem !important;
  }

  .m-lg-8 {
    margin: 2rem !important;
  }

  .m-lg-9 {
    margin: 2.25rem !important;
  }

  .m-lg-10 {
    margin: 2.5rem !important;
  }

  .m-lg-11 {
    margin: 2.75rem !important;
  }

  .m-lg-12 {
    margin: 3rem !important;
  }

  .m-lg-13 {
    margin: 3.25rem !important;
  }

  .m-lg-14 {
    margin: 3.5rem !important;
  }

  .m-lg-15 {
    margin: 3.75rem !important;
  }

  .m-lg-16 {
    margin: 4rem !important;
  }

  .m-lg-17 {
    margin: 4.25rem !important;
  }

  .m-lg-18 {
    margin: 4.5rem !important;
  }

  .m-lg-19 {
    margin: 4.75rem !important;
  }

  .m-lg-20 {
    margin: 5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-lg-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-lg-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-lg-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-lg-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-lg-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-lg-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-lg-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-lg-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-lg-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-lg-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-lg-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-lg-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-lg-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-lg-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-lg-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-lg-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-lg-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-lg-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-lg-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }

  .mt-lg-4 {
    margin-top: 1rem !important;
  }

  .mt-lg-5 {
    margin-top: 1.25rem !important;
  }

  .mt-lg-6 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 1.75rem !important;
  }

  .mt-lg-8 {
    margin-top: 2rem !important;
  }

  .mt-lg-9 {
    margin-top: 2.25rem !important;
  }

  .mt-lg-10 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-11 {
    margin-top: 2.75rem !important;
  }

  .mt-lg-12 {
    margin-top: 3rem !important;
  }

  .mt-lg-13 {
    margin-top: 3.25rem !important;
  }

  .mt-lg-14 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-15 {
    margin-top: 3.75rem !important;
  }

  .mt-lg-16 {
    margin-top: 4rem !important;
  }

  .mt-lg-17 {
    margin-top: 4.25rem !important;
  }

  .mt-lg-18 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-19 {
    margin-top: 4.75rem !important;
  }

  .mt-lg-20 {
    margin-top: 5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 0.75rem !important;
  }

  .me-lg-4 {
    margin-right: 1rem !important;
  }

  .me-lg-5 {
    margin-right: 1.25rem !important;
  }

  .me-lg-6 {
    margin-right: 1.5rem !important;
  }

  .me-lg-7 {
    margin-right: 1.75rem !important;
  }

  .me-lg-8 {
    margin-right: 2rem !important;
  }

  .me-lg-9 {
    margin-right: 2.25rem !important;
  }

  .me-lg-10 {
    margin-right: 2.5rem !important;
  }

  .me-lg-11 {
    margin-right: 2.75rem !important;
  }

  .me-lg-12 {
    margin-right: 3rem !important;
  }

  .me-lg-13 {
    margin-right: 3.25rem !important;
  }

  .me-lg-14 {
    margin-right: 3.5rem !important;
  }

  .me-lg-15 {
    margin-right: 3.75rem !important;
  }

  .me-lg-16 {
    margin-right: 4rem !important;
  }

  .me-lg-17 {
    margin-right: 4.25rem !important;
  }

  .me-lg-18 {
    margin-right: 4.5rem !important;
  }

  .me-lg-19 {
    margin-right: 4.75rem !important;
  }

  .me-lg-20 {
    margin-right: 5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-lg-16 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-lg-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-lg-20 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }

  .ms-lg-4 {
    margin-left: 1rem !important;
  }

  .ms-lg-5 {
    margin-left: 1.25rem !important;
  }

  .ms-lg-6 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 1.75rem !important;
  }

  .ms-lg-8 {
    margin-left: 2rem !important;
  }

  .ms-lg-9 {
    margin-left: 2.25rem !important;
  }

  .ms-lg-10 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-11 {
    margin-left: 2.75rem !important;
  }

  .ms-lg-12 {
    margin-left: 3rem !important;
  }

  .ms-lg-13 {
    margin-left: 3.25rem !important;
  }

  .ms-lg-14 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-15 {
    margin-left: 3.75rem !important;
  }

  .ms-lg-16 {
    margin-left: 4rem !important;
  }

  .ms-lg-17 {
    margin-left: 4.25rem !important;
  }

  .ms-lg-18 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-19 {
    margin-left: 4.75rem !important;
  }

  .ms-lg-20 {
    margin-left: 5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -0.75rem !important;
  }

  .m-lg-n4 {
    margin: -1rem !important;
  }

  .m-lg-n5 {
    margin: -1.25rem !important;
  }

  .m-lg-n6 {
    margin: -1.5rem !important;
  }

  .m-lg-n7 {
    margin: -1.75rem !important;
  }

  .m-lg-n8 {
    margin: -2rem !important;
  }

  .m-lg-n9 {
    margin: -2.25rem !important;
  }

  .m-lg-n10 {
    margin: -2.5rem !important;
  }

  .m-lg-n11 {
    margin: -2.75rem !important;
  }

  .m-lg-n12 {
    margin: -3rem !important;
  }

  .m-lg-n13 {
    margin: -3.25rem !important;
  }

  .m-lg-n14 {
    margin: -3.5rem !important;
  }

  .m-lg-n15 {
    margin: -3.75rem !important;
  }

  .m-lg-n16 {
    margin: -4rem !important;
  }

  .m-lg-n17 {
    margin: -4.25rem !important;
  }

  .m-lg-n18 {
    margin: -4.5rem !important;
  }

  .m-lg-n19 {
    margin: -4.75rem !important;
  }

  .m-lg-n20 {
    margin: -5rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-lg-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-lg-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-lg-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-lg-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-lg-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-lg-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-lg-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-lg-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-lg-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-lg-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-lg-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-lg-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-lg-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-lg-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-lg-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-lg-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-lg-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-lg-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-lg-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-lg-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-lg-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-lg-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-lg-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-lg-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-lg-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1rem !important;
  }

  .mt-lg-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-lg-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-lg-n8 {
    margin-top: -2rem !important;
  }

  .mt-lg-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-lg-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-lg-n12 {
    margin-top: -3rem !important;
  }

  .mt-lg-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-lg-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-lg-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-lg-n16 {
    margin-top: -4rem !important;
  }

  .mt-lg-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-lg-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-lg-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-lg-n20 {
    margin-top: -5rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -0.75rem !important;
  }

  .me-lg-n4 {
    margin-right: -1rem !important;
  }

  .me-lg-n5 {
    margin-right: -1.25rem !important;
  }

  .me-lg-n6 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n7 {
    margin-right: -1.75rem !important;
  }

  .me-lg-n8 {
    margin-right: -2rem !important;
  }

  .me-lg-n9 {
    margin-right: -2.25rem !important;
  }

  .me-lg-n10 {
    margin-right: -2.5rem !important;
  }

  .me-lg-n11 {
    margin-right: -2.75rem !important;
  }

  .me-lg-n12 {
    margin-right: -3rem !important;
  }

  .me-lg-n13 {
    margin-right: -3.25rem !important;
  }

  .me-lg-n14 {
    margin-right: -3.5rem !important;
  }

  .me-lg-n15 {
    margin-right: -3.75rem !important;
  }

  .me-lg-n16 {
    margin-right: -4rem !important;
  }

  .me-lg-n17 {
    margin-right: -4.25rem !important;
  }

  .me-lg-n18 {
    margin-right: -4.5rem !important;
  }

  .me-lg-n19 {
    margin-right: -4.75rem !important;
  }

  .me-lg-n20 {
    margin-right: -5rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-lg-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-lg-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-lg-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-lg-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-lg-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-lg-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-lg-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1rem !important;
  }

  .ms-lg-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-lg-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-lg-n8 {
    margin-left: -2rem !important;
  }

  .ms-lg-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-lg-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-lg-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-lg-n12 {
    margin-left: -3rem !important;
  }

  .ms-lg-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-lg-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-lg-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-lg-n16 {
    margin-left: -4rem !important;
  }

  .ms-lg-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-lg-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-lg-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-lg-n20 {
    margin-left: -5rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 0.75rem !important;
  }

  .p-lg-4 {
    padding: 1rem !important;
  }

  .p-lg-5 {
    padding: 1.25rem !important;
  }

  .p-lg-6 {
    padding: 1.5rem !important;
  }

  .p-lg-7 {
    padding: 1.75rem !important;
  }

  .p-lg-8 {
    padding: 2rem !important;
  }

  .p-lg-9 {
    padding: 2.25rem !important;
  }

  .p-lg-10 {
    padding: 2.5rem !important;
  }

  .p-lg-11 {
    padding: 2.75rem !important;
  }

  .p-lg-12 {
    padding: 3rem !important;
  }

  .p-lg-13 {
    padding: 3.25rem !important;
  }

  .p-lg-14 {
    padding: 3.5rem !important;
  }

  .p-lg-15 {
    padding: 3.75rem !important;
  }

  .p-lg-16 {
    padding: 4rem !important;
  }

  .p-lg-17 {
    padding: 4.25rem !important;
  }

  .p-lg-18 {
    padding: 4.5rem !important;
  }

  .p-lg-19 {
    padding: 4.75rem !important;
  }

  .p-lg-20 {
    padding: 5rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-lg-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-lg-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-lg-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-lg-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-lg-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-lg-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-lg-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-lg-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-lg-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-lg-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-lg-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-lg-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-lg-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-lg-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-lg-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-lg-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-lg-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-lg-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-lg-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-lg-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }

  .pt-lg-4 {
    padding-top: 1rem !important;
  }

  .pt-lg-5 {
    padding-top: 1.25rem !important;
  }

  .pt-lg-6 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 1.75rem !important;
  }

  .pt-lg-8 {
    padding-top: 2rem !important;
  }

  .pt-lg-9 {
    padding-top: 2.25rem !important;
  }

  .pt-lg-10 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-11 {
    padding-top: 2.75rem !important;
  }

  .pt-lg-12 {
    padding-top: 3rem !important;
  }

  .pt-lg-13 {
    padding-top: 3.25rem !important;
  }

  .pt-lg-14 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-15 {
    padding-top: 3.75rem !important;
  }

  .pt-lg-16 {
    padding-top: 4rem !important;
  }

  .pt-lg-17 {
    padding-top: 4.25rem !important;
  }

  .pt-lg-18 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-19 {
    padding-top: 4.75rem !important;
  }

  .pt-lg-20 {
    padding-top: 5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 0.75rem !important;
  }

  .pe-lg-4 {
    padding-right: 1rem !important;
  }

  .pe-lg-5 {
    padding-right: 1.25rem !important;
  }

  .pe-lg-6 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 1.75rem !important;
  }

  .pe-lg-8 {
    padding-right: 2rem !important;
  }

  .pe-lg-9 {
    padding-right: 2.25rem !important;
  }

  .pe-lg-10 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-11 {
    padding-right: 2.75rem !important;
  }

  .pe-lg-12 {
    padding-right: 3rem !important;
  }

  .pe-lg-13 {
    padding-right: 3.25rem !important;
  }

  .pe-lg-14 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-15 {
    padding-right: 3.75rem !important;
  }

  .pe-lg-16 {
    padding-right: 4rem !important;
  }

  .pe-lg-17 {
    padding-right: 4.25rem !important;
  }

  .pe-lg-18 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-19 {
    padding-right: 4.75rem !important;
  }

  .pe-lg-20 {
    padding-right: 5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-lg-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-lg-16 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-lg-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-lg-20 {
    padding-bottom: 5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 0.75rem !important;
  }

  .ps-lg-4 {
    padding-left: 1rem !important;
  }

  .ps-lg-5 {
    padding-left: 1.25rem !important;
  }

  .ps-lg-6 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 1.75rem !important;
  }

  .ps-lg-8 {
    padding-left: 2rem !important;
  }

  .ps-lg-9 {
    padding-left: 2.25rem !important;
  }

  .ps-lg-10 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-11 {
    padding-left: 2.75rem !important;
  }

  .ps-lg-12 {
    padding-left: 3rem !important;
  }

  .ps-lg-13 {
    padding-left: 3.25rem !important;
  }

  .ps-lg-14 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-15 {
    padding-left: 3.75rem !important;
  }

  .ps-lg-16 {
    padding-left: 4rem !important;
  }

  .ps-lg-17 {
    padding-left: 4.25rem !important;
  }

  .ps-lg-18 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-19 {
    padding-left: 4.75rem !important;
  }

  .ps-lg-20 {
    padding-left: 5rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 0.75rem !important;
  }

  .gap-lg-4 {
    gap: 1rem !important;
  }

  .gap-lg-5 {
    gap: 1.25rem !important;
  }

  .gap-lg-6 {
    gap: 1.5rem !important;
  }

  .gap-lg-7 {
    gap: 1.75rem !important;
  }

  .gap-lg-8 {
    gap: 2rem !important;
  }

  .gap-lg-9 {
    gap: 2.25rem !important;
  }

  .gap-lg-10 {
    gap: 2.5rem !important;
  }

  .gap-lg-11 {
    gap: 2.75rem !important;
  }

  .gap-lg-12 {
    gap: 3rem !important;
  }

  .gap-lg-13 {
    gap: 3.25rem !important;
  }

  .gap-lg-14 {
    gap: 3.5rem !important;
  }

  .gap-lg-15 {
    gap: 3.75rem !important;
  }

  .gap-lg-16 {
    gap: 4rem !important;
  }

  .gap-lg-17 {
    gap: 4.25rem !important;
  }

  .gap-lg-18 {
    gap: 4.5rem !important;
  }

  .gap-lg-19 {
    gap: 4.75rem !important;
  }

  .gap-lg-20 {
    gap: 5rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-lg-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-lg-8 {
    row-gap: 2rem !important;
  }

  .row-gap-lg-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-lg-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-lg-11 {
    row-gap: 2.75rem !important;
  }

  .row-gap-lg-12 {
    row-gap: 3rem !important;
  }

  .row-gap-lg-13 {
    row-gap: 3.25rem !important;
  }

  .row-gap-lg-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-lg-15 {
    row-gap: 3.75rem !important;
  }

  .row-gap-lg-16 {
    row-gap: 4rem !important;
  }

  .row-gap-lg-17 {
    row-gap: 4.25rem !important;
  }

  .row-gap-lg-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-lg-19 {
    row-gap: 4.75rem !important;
  }

  .row-gap-lg-20 {
    row-gap: 5rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-lg-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-lg-8 {
    column-gap: 2rem !important;
  }

  .column-gap-lg-9 {
    column-gap: 2.25rem !important;
  }

  .column-gap-lg-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-lg-11 {
    column-gap: 2.75rem !important;
  }

  .column-gap-lg-12 {
    column-gap: 3rem !important;
  }

  .column-gap-lg-13 {
    column-gap: 3.25rem !important;
  }

  .column-gap-lg-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-lg-15 {
    column-gap: 3.75rem !important;
  }

  .column-gap-lg-16 {
    column-gap: 4rem !important;
  }

  .column-gap-lg-17 {
    column-gap: 4.25rem !important;
  }

  .column-gap-lg-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-lg-19 {
    column-gap: 4.75rem !important;
  }

  .column-gap-lg-20 {
    column-gap: 5rem !important;
  }

  .fs-lg-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-lg-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-lg-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-lg-4 {
    font-size: 1.25rem !important;
  }

  .fs-lg-5 {
    font-size: 1.15rem !important;
  }

  .fs-lg-6 {
    font-size: 1.075rem !important;
  }

  .fs-lg-7 {
    font-size: 0.95rem !important;
  }

  .fs-lg-8 {
    font-size: 0.85rem !important;
  }

  .fs-lg-9 {
    font-size: 0.75rem !important;
  }

  .fs-lg-10 {
    font-size: 0.5rem !important;
  }

  .fs-lg-base {
    font-size: 1rem !important;
  }

  .fs-lg-fluid {
    font-size: 100% !important;
  }

  .fs-lg-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-lg-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-lg-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-lg-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-lg-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-lg-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-lg-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-lg-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-lg-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-lg-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-lg-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-lg-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-lg-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-lg-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-lg-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-lg-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }

  .fs-lg-10x {
    font-size: calc(2.125rem + 10.5vw) !important;
  }

  .fs-lg-10qx {
    font-size: calc(2.15rem + 10.8vw) !important;
  }

  .fs-lg-10hx {
    font-size: calc(2.175rem + 11.1vw) !important;
  }

  .fs-lg-10tx {
    font-size: calc(2.2rem + 11.4vw) !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .min-w-lg-unset {
    min-width: unset !important;
  }

  .min-w-lg-25 {
    min-width: 25% !important;
  }

  .min-w-lg-50 {
    min-width: 50% !important;
  }

  .min-w-lg-75 {
    min-width: 75% !important;
  }

  .min-w-lg-100 {
    min-width: 100% !important;
  }

  .min-w-lg-auto {
    min-width: auto !important;
  }

  .min-w-lg-1px {
    min-width: 1px !important;
  }

  .min-w-lg-2px {
    min-width: 2px !important;
  }

  .min-w-lg-3px {
    min-width: 3px !important;
  }

  .min-w-lg-4px {
    min-width: 4px !important;
  }

  .min-w-lg-5px {
    min-width: 5px !important;
  }

  .min-w-lg-6px {
    min-width: 6px !important;
  }

  .min-w-lg-7px {
    min-width: 7px !important;
  }

  .min-w-lg-8px {
    min-width: 8px !important;
  }

  .min-w-lg-9px {
    min-width: 9px !important;
  }

  .min-w-lg-10px {
    min-width: 10px !important;
  }

  .min-w-lg-15px {
    min-width: 15px !important;
  }

  .min-w-lg-20px {
    min-width: 20px !important;
  }

  .min-w-lg-25px {
    min-width: 25px !important;
  }

  .min-w-lg-30px {
    min-width: 30px !important;
  }

  .min-w-lg-35px {
    min-width: 35px !important;
  }

  .min-w-lg-40px {
    min-width: 40px !important;
  }

  .min-w-lg-45px {
    min-width: 45px !important;
  }

  .min-w-lg-50px {
    min-width: 50px !important;
  }

  .min-w-lg-55px {
    min-width: 55px !important;
  }

  .min-w-lg-60px {
    min-width: 60px !important;
  }

  .min-w-lg-65px {
    min-width: 65px !important;
  }

  .min-w-lg-70px {
    min-width: 70px !important;
  }

  .min-w-lg-75px {
    min-width: 75px !important;
  }

  .min-w-lg-80px {
    min-width: 80px !important;
  }

  .min-w-lg-85px {
    min-width: 85px !important;
  }

  .min-w-lg-90px {
    min-width: 90px !important;
  }

  .min-w-lg-95px {
    min-width: 95px !important;
  }

  .min-w-lg-100px {
    min-width: 100px !important;
  }

  .min-w-lg-125px {
    min-width: 125px !important;
  }

  .min-w-lg-150px {
    min-width: 150px !important;
  }

  .min-w-lg-175px {
    min-width: 175px !important;
  }

  .min-w-lg-200px {
    min-width: 200px !important;
  }

  .min-w-lg-225px {
    min-width: 225px !important;
  }

  .min-w-lg-250px {
    min-width: 250px !important;
  }

  .min-w-lg-275px {
    min-width: 275px !important;
  }

  .min-w-lg-300px {
    min-width: 300px !important;
  }

  .min-w-lg-325px {
    min-width: 325px !important;
  }

  .min-w-lg-350px {
    min-width: 350px !important;
  }

  .min-w-lg-375px {
    min-width: 375px !important;
  }

  .min-w-lg-400px {
    min-width: 400px !important;
  }

  .min-w-lg-425px {
    min-width: 425px !important;
  }

  .min-w-lg-450px {
    min-width: 450px !important;
  }

  .min-w-lg-475px {
    min-width: 475px !important;
  }

  .min-w-lg-500px {
    min-width: 500px !important;
  }

  .min-w-lg-550px {
    min-width: 550px !important;
  }

  .min-w-lg-600px {
    min-width: 600px !important;
  }

  .min-w-lg-650px {
    min-width: 650px !important;
  }

  .min-w-lg-700px {
    min-width: 700px !important;
  }

  .min-w-lg-750px {
    min-width: 750px !important;
  }

  .min-w-lg-800px {
    min-width: 800px !important;
  }

  .min-w-lg-850px {
    min-width: 850px !important;
  }

  .min-w-lg-900px {
    min-width: 900px !important;
  }

  .min-w-lg-950px {
    min-width: 950px !important;
  }

  .min-w-lg-1000px {
    min-width: 1000px !important;
  }

  .min-h-lg-unset {
    min-height: unset !important;
  }

  .min-h-lg-25 {
    min-height: 25% !important;
  }

  .min-h-lg-50 {
    min-height: 50% !important;
  }

  .min-h-lg-75 {
    min-height: 75% !important;
  }

  .min-h-lg-100 {
    min-height: 100% !important;
  }

  .min-h-lg-auto {
    min-height: auto !important;
  }

  .min-h-lg-1px {
    min-height: 1px !important;
  }

  .min-h-lg-2px {
    min-height: 2px !important;
  }

  .min-h-lg-3px {
    min-height: 3px !important;
  }

  .min-h-lg-4px {
    min-height: 4px !important;
  }

  .min-h-lg-5px {
    min-height: 5px !important;
  }

  .min-h-lg-6px {
    min-height: 6px !important;
  }

  .min-h-lg-7px {
    min-height: 7px !important;
  }

  .min-h-lg-8px {
    min-height: 8px !important;
  }

  .min-h-lg-9px {
    min-height: 9px !important;
  }

  .min-h-lg-10px {
    min-height: 10px !important;
  }

  .min-h-lg-15px {
    min-height: 15px !important;
  }

  .min-h-lg-20px {
    min-height: 20px !important;
  }

  .min-h-lg-25px {
    min-height: 25px !important;
  }

  .min-h-lg-30px {
    min-height: 30px !important;
  }

  .min-h-lg-35px {
    min-height: 35px !important;
  }

  .min-h-lg-40px {
    min-height: 40px !important;
  }

  .min-h-lg-45px {
    min-height: 45px !important;
  }

  .min-h-lg-50px {
    min-height: 50px !important;
  }

  .min-h-lg-55px {
    min-height: 55px !important;
  }

  .min-h-lg-60px {
    min-height: 60px !important;
  }

  .min-h-lg-65px {
    min-height: 65px !important;
  }

  .min-h-lg-70px {
    min-height: 70px !important;
  }

  .min-h-lg-75px {
    min-height: 75px !important;
  }

  .min-h-lg-80px {
    min-height: 80px !important;
  }

  .min-h-lg-85px {
    min-height: 85px !important;
  }

  .min-h-lg-90px {
    min-height: 90px !important;
  }

  .min-h-lg-95px {
    min-height: 95px !important;
  }

  .min-h-lg-100px {
    min-height: 100px !important;
  }

  .min-h-lg-125px {
    min-height: 125px !important;
  }

  .min-h-lg-150px {
    min-height: 150px !important;
  }

  .min-h-lg-175px {
    min-height: 175px !important;
  }

  .min-h-lg-200px {
    min-height: 200px !important;
  }

  .min-h-lg-225px {
    min-height: 225px !important;
  }

  .min-h-lg-250px {
    min-height: 250px !important;
  }

  .min-h-lg-275px {
    min-height: 275px !important;
  }

  .min-h-lg-300px {
    min-height: 300px !important;
  }

  .min-h-lg-325px {
    min-height: 325px !important;
  }

  .min-h-lg-350px {
    min-height: 350px !important;
  }

  .min-h-lg-375px {
    min-height: 375px !important;
  }

  .min-h-lg-400px {
    min-height: 400px !important;
  }

  .min-h-lg-425px {
    min-height: 425px !important;
  }

  .min-h-lg-450px {
    min-height: 450px !important;
  }

  .min-h-lg-475px {
    min-height: 475px !important;
  }

  .min-h-lg-500px {
    min-height: 500px !important;
  }

  .min-h-lg-550px {
    min-height: 550px !important;
  }

  .min-h-lg-600px {
    min-height: 600px !important;
  }

  .min-h-lg-650px {
    min-height: 650px !important;
  }

  .min-h-lg-700px {
    min-height: 700px !important;
  }

  .min-h-lg-750px {
    min-height: 750px !important;
  }

  .min-h-lg-800px {
    min-height: 800px !important;
  }

  .min-h-lg-850px {
    min-height: 850px !important;
  }

  .min-h-lg-900px {
    min-height: 900px !important;
  }

  .min-h-lg-950px {
    min-height: 950px !important;
  }

  .min-h-lg-1000px {
    min-height: 1000px !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .w-xl-unset {
    width: unset !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }

  .w-xl-1px {
    width: 1px !important;
  }

  .w-xl-2px {
    width: 2px !important;
  }

  .w-xl-3px {
    width: 3px !important;
  }

  .w-xl-4px {
    width: 4px !important;
  }

  .w-xl-5px {
    width: 5px !important;
  }

  .w-xl-6px {
    width: 6px !important;
  }

  .w-xl-7px {
    width: 7px !important;
  }

  .w-xl-8px {
    width: 8px !important;
  }

  .w-xl-9px {
    width: 9px !important;
  }

  .w-xl-10px {
    width: 10px !important;
  }

  .w-xl-15px {
    width: 15px !important;
  }

  .w-xl-20px {
    width: 20px !important;
  }

  .w-xl-25px {
    width: 25px !important;
  }

  .w-xl-30px {
    width: 30px !important;
  }

  .w-xl-35px {
    width: 35px !important;
  }

  .w-xl-40px {
    width: 40px !important;
  }

  .w-xl-45px {
    width: 45px !important;
  }

  .w-xl-50px {
    width: 50px !important;
  }

  .w-xl-55px {
    width: 55px !important;
  }

  .w-xl-60px {
    width: 60px !important;
  }

  .w-xl-65px {
    width: 65px !important;
  }

  .w-xl-70px {
    width: 70px !important;
  }

  .w-xl-75px {
    width: 75px !important;
  }

  .w-xl-80px {
    width: 80px !important;
  }

  .w-xl-85px {
    width: 85px !important;
  }

  .w-xl-90px {
    width: 90px !important;
  }

  .w-xl-95px {
    width: 95px !important;
  }

  .w-xl-100px {
    width: 100px !important;
  }

  .w-xl-125px {
    width: 125px !important;
  }

  .w-xl-150px {
    width: 150px !important;
  }

  .w-xl-175px {
    width: 175px !important;
  }

  .w-xl-200px {
    width: 200px !important;
  }

  .w-xl-225px {
    width: 225px !important;
  }

  .w-xl-250px {
    width: 250px !important;
  }

  .w-xl-275px {
    width: 275px !important;
  }

  .w-xl-300px {
    width: 300px !important;
  }

  .w-xl-325px {
    width: 325px !important;
  }

  .w-xl-350px {
    width: 350px !important;
  }

  .w-xl-375px {
    width: 375px !important;
  }

  .w-xl-400px {
    width: 400px !important;
  }

  .w-xl-425px {
    width: 425px !important;
  }

  .w-xl-450px {
    width: 450px !important;
  }

  .w-xl-475px {
    width: 475px !important;
  }

  .w-xl-500px {
    width: 500px !important;
  }

  .w-xl-550px {
    width: 550px !important;
  }

  .w-xl-600px {
    width: 600px !important;
  }

  .w-xl-650px {
    width: 650px !important;
  }

  .w-xl-700px {
    width: 700px !important;
  }

  .w-xl-750px {
    width: 750px !important;
  }

  .w-xl-800px {
    width: 800px !important;
  }

  .w-xl-850px {
    width: 850px !important;
  }

  .w-xl-900px {
    width: 900px !important;
  }

  .w-xl-950px {
    width: 950px !important;
  }

  .w-xl-1000px {
    width: 1000px !important;
  }

  .mw-xl-unset {
    max-width: unset !important;
  }

  .mw-xl-25 {
    max-width: 25% !important;
  }

  .mw-xl-50 {
    max-width: 50% !important;
  }

  .mw-xl-75 {
    max-width: 75% !important;
  }

  .mw-xl-100 {
    max-width: 100% !important;
  }

  .mw-xl-auto {
    max-width: auto !important;
  }

  .mw-xl-1px {
    max-width: 1px !important;
  }

  .mw-xl-2px {
    max-width: 2px !important;
  }

  .mw-xl-3px {
    max-width: 3px !important;
  }

  .mw-xl-4px {
    max-width: 4px !important;
  }

  .mw-xl-5px {
    max-width: 5px !important;
  }

  .mw-xl-6px {
    max-width: 6px !important;
  }

  .mw-xl-7px {
    max-width: 7px !important;
  }

  .mw-xl-8px {
    max-width: 8px !important;
  }

  .mw-xl-9px {
    max-width: 9px !important;
  }

  .mw-xl-10px {
    max-width: 10px !important;
  }

  .mw-xl-15px {
    max-width: 15px !important;
  }

  .mw-xl-20px {
    max-width: 20px !important;
  }

  .mw-xl-25px {
    max-width: 25px !important;
  }

  .mw-xl-30px {
    max-width: 30px !important;
  }

  .mw-xl-35px {
    max-width: 35px !important;
  }

  .mw-xl-40px {
    max-width: 40px !important;
  }

  .mw-xl-45px {
    max-width: 45px !important;
  }

  .mw-xl-50px {
    max-width: 50px !important;
  }

  .mw-xl-55px {
    max-width: 55px !important;
  }

  .mw-xl-60px {
    max-width: 60px !important;
  }

  .mw-xl-65px {
    max-width: 65px !important;
  }

  .mw-xl-70px {
    max-width: 70px !important;
  }

  .mw-xl-75px {
    max-width: 75px !important;
  }

  .mw-xl-80px {
    max-width: 80px !important;
  }

  .mw-xl-85px {
    max-width: 85px !important;
  }

  .mw-xl-90px {
    max-width: 90px !important;
  }

  .mw-xl-95px {
    max-width: 95px !important;
  }

  .mw-xl-100px {
    max-width: 100px !important;
  }

  .mw-xl-125px {
    max-width: 125px !important;
  }

  .mw-xl-150px {
    max-width: 150px !important;
  }

  .mw-xl-175px {
    max-width: 175px !important;
  }

  .mw-xl-200px {
    max-width: 200px !important;
  }

  .mw-xl-225px {
    max-width: 225px !important;
  }

  .mw-xl-250px {
    max-width: 250px !important;
  }

  .mw-xl-275px {
    max-width: 275px !important;
  }

  .mw-xl-300px {
    max-width: 300px !important;
  }

  .mw-xl-325px {
    max-width: 325px !important;
  }

  .mw-xl-350px {
    max-width: 350px !important;
  }

  .mw-xl-375px {
    max-width: 375px !important;
  }

  .mw-xl-400px {
    max-width: 400px !important;
  }

  .mw-xl-425px {
    max-width: 425px !important;
  }

  .mw-xl-450px {
    max-width: 450px !important;
  }

  .mw-xl-475px {
    max-width: 475px !important;
  }

  .mw-xl-500px {
    max-width: 500px !important;
  }

  .mw-xl-550px {
    max-width: 550px !important;
  }

  .mw-xl-600px {
    max-width: 600px !important;
  }

  .mw-xl-650px {
    max-width: 650px !important;
  }

  .mw-xl-700px {
    max-width: 700px !important;
  }

  .mw-xl-750px {
    max-width: 750px !important;
  }

  .mw-xl-800px {
    max-width: 800px !important;
  }

  .mw-xl-850px {
    max-width: 850px !important;
  }

  .mw-xl-900px {
    max-width: 900px !important;
  }

  .mw-xl-950px {
    max-width: 950px !important;
  }

  .mw-xl-1000px {
    max-width: 1000px !important;
  }

  .h-xl-unset {
    height: unset !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .h-xl-auto {
    height: auto !important;
  }

  .h-xl-1px {
    height: 1px !important;
  }

  .h-xl-2px {
    height: 2px !important;
  }

  .h-xl-3px {
    height: 3px !important;
  }

  .h-xl-4px {
    height: 4px !important;
  }

  .h-xl-5px {
    height: 5px !important;
  }

  .h-xl-6px {
    height: 6px !important;
  }

  .h-xl-7px {
    height: 7px !important;
  }

  .h-xl-8px {
    height: 8px !important;
  }

  .h-xl-9px {
    height: 9px !important;
  }

  .h-xl-10px {
    height: 10px !important;
  }

  .h-xl-15px {
    height: 15px !important;
  }

  .h-xl-20px {
    height: 20px !important;
  }

  .h-xl-25px {
    height: 25px !important;
  }

  .h-xl-30px {
    height: 30px !important;
  }

  .h-xl-35px {
    height: 35px !important;
  }

  .h-xl-40px {
    height: 40px !important;
  }

  .h-xl-45px {
    height: 45px !important;
  }

  .h-xl-50px {
    height: 50px !important;
  }

  .h-xl-55px {
    height: 55px !important;
  }

  .h-xl-60px {
    height: 60px !important;
  }

  .h-xl-65px {
    height: 65px !important;
  }

  .h-xl-70px {
    height: 70px !important;
  }

  .h-xl-75px {
    height: 75px !important;
  }

  .h-xl-80px {
    height: 80px !important;
  }

  .h-xl-85px {
    height: 85px !important;
  }

  .h-xl-90px {
    height: 90px !important;
  }

  .h-xl-95px {
    height: 95px !important;
  }

  .h-xl-100px {
    height: 100px !important;
  }

  .h-xl-125px {
    height: 125px !important;
  }

  .h-xl-150px {
    height: 150px !important;
  }

  .h-xl-175px {
    height: 175px !important;
  }

  .h-xl-200px {
    height: 200px !important;
  }

  .h-xl-225px {
    height: 225px !important;
  }

  .h-xl-250px {
    height: 250px !important;
  }

  .h-xl-275px {
    height: 275px !important;
  }

  .h-xl-300px {
    height: 300px !important;
  }

  .h-xl-325px {
    height: 325px !important;
  }

  .h-xl-350px {
    height: 350px !important;
  }

  .h-xl-375px {
    height: 375px !important;
  }

  .h-xl-400px {
    height: 400px !important;
  }

  .h-xl-425px {
    height: 425px !important;
  }

  .h-xl-450px {
    height: 450px !important;
  }

  .h-xl-475px {
    height: 475px !important;
  }

  .h-xl-500px {
    height: 500px !important;
  }

  .h-xl-550px {
    height: 550px !important;
  }

  .h-xl-600px {
    height: 600px !important;
  }

  .h-xl-650px {
    height: 650px !important;
  }

  .h-xl-700px {
    height: 700px !important;
  }

  .h-xl-750px {
    height: 750px !important;
  }

  .h-xl-800px {
    height: 800px !important;
  }

  .h-xl-850px {
    height: 850px !important;
  }

  .h-xl-900px {
    height: 900px !important;
  }

  .h-xl-950px {
    height: 950px !important;
  }

  .h-xl-1000px {
    height: 1000px !important;
  }

  .mh-xl-unset {
    max-height: unset !important;
  }

  .mh-xl-25 {
    max-height: 25% !important;
  }

  .mh-xl-50 {
    max-height: 50% !important;
  }

  .mh-xl-75 {
    max-height: 75% !important;
  }

  .mh-xl-100 {
    max-height: 100% !important;
  }

  .mh-xl-auto {
    max-height: auto !important;
  }

  .mh-xl-1px {
    max-height: 1px !important;
  }

  .mh-xl-2px {
    max-height: 2px !important;
  }

  .mh-xl-3px {
    max-height: 3px !important;
  }

  .mh-xl-4px {
    max-height: 4px !important;
  }

  .mh-xl-5px {
    max-height: 5px !important;
  }

  .mh-xl-6px {
    max-height: 6px !important;
  }

  .mh-xl-7px {
    max-height: 7px !important;
  }

  .mh-xl-8px {
    max-height: 8px !important;
  }

  .mh-xl-9px {
    max-height: 9px !important;
  }

  .mh-xl-10px {
    max-height: 10px !important;
  }

  .mh-xl-15px {
    max-height: 15px !important;
  }

  .mh-xl-20px {
    max-height: 20px !important;
  }

  .mh-xl-25px {
    max-height: 25px !important;
  }

  .mh-xl-30px {
    max-height: 30px !important;
  }

  .mh-xl-35px {
    max-height: 35px !important;
  }

  .mh-xl-40px {
    max-height: 40px !important;
  }

  .mh-xl-45px {
    max-height: 45px !important;
  }

  .mh-xl-50px {
    max-height: 50px !important;
  }

  .mh-xl-55px {
    max-height: 55px !important;
  }

  .mh-xl-60px {
    max-height: 60px !important;
  }

  .mh-xl-65px {
    max-height: 65px !important;
  }

  .mh-xl-70px {
    max-height: 70px !important;
  }

  .mh-xl-75px {
    max-height: 75px !important;
  }

  .mh-xl-80px {
    max-height: 80px !important;
  }

  .mh-xl-85px {
    max-height: 85px !important;
  }

  .mh-xl-90px {
    max-height: 90px !important;
  }

  .mh-xl-95px {
    max-height: 95px !important;
  }

  .mh-xl-100px {
    max-height: 100px !important;
  }

  .mh-xl-125px {
    max-height: 125px !important;
  }

  .mh-xl-150px {
    max-height: 150px !important;
  }

  .mh-xl-175px {
    max-height: 175px !important;
  }

  .mh-xl-200px {
    max-height: 200px !important;
  }

  .mh-xl-225px {
    max-height: 225px !important;
  }

  .mh-xl-250px {
    max-height: 250px !important;
  }

  .mh-xl-275px {
    max-height: 275px !important;
  }

  .mh-xl-300px {
    max-height: 300px !important;
  }

  .mh-xl-325px {
    max-height: 325px !important;
  }

  .mh-xl-350px {
    max-height: 350px !important;
  }

  .mh-xl-375px {
    max-height: 375px !important;
  }

  .mh-xl-400px {
    max-height: 400px !important;
  }

  .mh-xl-425px {
    max-height: 425px !important;
  }

  .mh-xl-450px {
    max-height: 450px !important;
  }

  .mh-xl-475px {
    max-height: 475px !important;
  }

  .mh-xl-500px {
    max-height: 500px !important;
  }

  .mh-xl-550px {
    max-height: 550px !important;
  }

  .mh-xl-600px {
    max-height: 600px !important;
  }

  .mh-xl-650px {
    max-height: 650px !important;
  }

  .mh-xl-700px {
    max-height: 700px !important;
  }

  .mh-xl-750px {
    max-height: 750px !important;
  }

  .mh-xl-800px {
    max-height: 800px !important;
  }

  .mh-xl-850px {
    max-height: 850px !important;
  }

  .mh-xl-900px {
    max-height: 900px !important;
  }

  .mh-xl-950px {
    max-height: 950px !important;
  }

  .mh-xl-1000px {
    max-height: 1000px !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 0.75rem !important;
  }

  .m-xl-4 {
    margin: 1rem !important;
  }

  .m-xl-5 {
    margin: 1.25rem !important;
  }

  .m-xl-6 {
    margin: 1.5rem !important;
  }

  .m-xl-7 {
    margin: 1.75rem !important;
  }

  .m-xl-8 {
    margin: 2rem !important;
  }

  .m-xl-9 {
    margin: 2.25rem !important;
  }

  .m-xl-10 {
    margin: 2.5rem !important;
  }

  .m-xl-11 {
    margin: 2.75rem !important;
  }

  .m-xl-12 {
    margin: 3rem !important;
  }

  .m-xl-13 {
    margin: 3.25rem !important;
  }

  .m-xl-14 {
    margin: 3.5rem !important;
  }

  .m-xl-15 {
    margin: 3.75rem !important;
  }

  .m-xl-16 {
    margin: 4rem !important;
  }

  .m-xl-17 {
    margin: 4.25rem !important;
  }

  .m-xl-18 {
    margin: 4.5rem !important;
  }

  .m-xl-19 {
    margin: 4.75rem !important;
  }

  .m-xl-20 {
    margin: 5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-xl-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-xl-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-xl-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-xl-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xl-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-xl-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-xl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-xl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-xl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-xl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-xl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xl-4 {
    margin-top: 1rem !important;
  }

  .mt-xl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xl-8 {
    margin-top: 2rem !important;
  }

  .mt-xl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-11 {
    margin-top: 2.75rem !important;
  }

  .mt-xl-12 {
    margin-top: 3rem !important;
  }

  .mt-xl-13 {
    margin-top: 3.25rem !important;
  }

  .mt-xl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-15 {
    margin-top: 3.75rem !important;
  }

  .mt-xl-16 {
    margin-top: 4rem !important;
  }

  .mt-xl-17 {
    margin-top: 4.25rem !important;
  }

  .mt-xl-18 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-19 {
    margin-top: 4.75rem !important;
  }

  .mt-xl-20 {
    margin-top: 5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xl-4 {
    margin-right: 1rem !important;
  }

  .me-xl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xl-8 {
    margin-right: 2rem !important;
  }

  .me-xl-9 {
    margin-right: 2.25rem !important;
  }

  .me-xl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xl-11 {
    margin-right: 2.75rem !important;
  }

  .me-xl-12 {
    margin-right: 3rem !important;
  }

  .me-xl-13 {
    margin-right: 3.25rem !important;
  }

  .me-xl-14 {
    margin-right: 3.5rem !important;
  }

  .me-xl-15 {
    margin-right: 3.75rem !important;
  }

  .me-xl-16 {
    margin-right: 4rem !important;
  }

  .me-xl-17 {
    margin-right: 4.25rem !important;
  }

  .me-xl-18 {
    margin-right: 4.5rem !important;
  }

  .me-xl-19 {
    margin-right: 4.75rem !important;
  }

  .me-xl-20 {
    margin-right: 5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-xl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-xl-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-xl-20 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xl-4 {
    margin-left: 1rem !important;
  }

  .ms-xl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xl-8 {
    margin-left: 2rem !important;
  }

  .ms-xl-9 {
    margin-left: 2.25rem !important;
  }

  .ms-xl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-11 {
    margin-left: 2.75rem !important;
  }

  .ms-xl-12 {
    margin-left: 3rem !important;
  }

  .ms-xl-13 {
    margin-left: 3.25rem !important;
  }

  .ms-xl-14 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-15 {
    margin-left: 3.75rem !important;
  }

  .ms-xl-16 {
    margin-left: 4rem !important;
  }

  .ms-xl-17 {
    margin-left: 4.25rem !important;
  }

  .ms-xl-18 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-19 {
    margin-left: 4.75rem !important;
  }

  .ms-xl-20 {
    margin-left: 5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -0.75rem !important;
  }

  .m-xl-n4 {
    margin: -1rem !important;
  }

  .m-xl-n5 {
    margin: -1.25rem !important;
  }

  .m-xl-n6 {
    margin: -1.5rem !important;
  }

  .m-xl-n7 {
    margin: -1.75rem !important;
  }

  .m-xl-n8 {
    margin: -2rem !important;
  }

  .m-xl-n9 {
    margin: -2.25rem !important;
  }

  .m-xl-n10 {
    margin: -2.5rem !important;
  }

  .m-xl-n11 {
    margin: -2.75rem !important;
  }

  .m-xl-n12 {
    margin: -3rem !important;
  }

  .m-xl-n13 {
    margin: -3.25rem !important;
  }

  .m-xl-n14 {
    margin: -3.5rem !important;
  }

  .m-xl-n15 {
    margin: -3.75rem !important;
  }

  .m-xl-n16 {
    margin: -4rem !important;
  }

  .m-xl-n17 {
    margin: -4.25rem !important;
  }

  .m-xl-n18 {
    margin: -4.5rem !important;
  }

  .m-xl-n19 {
    margin: -4.75rem !important;
  }

  .m-xl-n20 {
    margin: -5rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-xl-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xl-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-xl-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xl-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-xl-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xl-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-xl-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xl-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-xl-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xl-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-xl-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-xl-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-xl-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-xl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-xl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xl-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-xl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-xl-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xl-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-xl-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xl-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-xl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-xl-n12 {
    margin-top: -3rem !important;
  }

  .mt-xl-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-xl-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-xl-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-xl-n16 {
    margin-top: -4rem !important;
  }

  .mt-xl-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-xl-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-xl-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-xl-n20 {
    margin-top: -5rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -0.75rem !important;
  }

  .me-xl-n4 {
    margin-right: -1rem !important;
  }

  .me-xl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xl-n8 {
    margin-right: -2rem !important;
  }

  .me-xl-n9 {
    margin-right: -2.25rem !important;
  }

  .me-xl-n10 {
    margin-right: -2.5rem !important;
  }

  .me-xl-n11 {
    margin-right: -2.75rem !important;
  }

  .me-xl-n12 {
    margin-right: -3rem !important;
  }

  .me-xl-n13 {
    margin-right: -3.25rem !important;
  }

  .me-xl-n14 {
    margin-right: -3.5rem !important;
  }

  .me-xl-n15 {
    margin-right: -3.75rem !important;
  }

  .me-xl-n16 {
    margin-right: -4rem !important;
  }

  .me-xl-n17 {
    margin-right: -4.25rem !important;
  }

  .me-xl-n18 {
    margin-right: -4.5rem !important;
  }

  .me-xl-n19 {
    margin-right: -4.75rem !important;
  }

  .me-xl-n20 {
    margin-right: -5rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-xl-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xl-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-xl-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-xl-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-xl-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xl-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-xl-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xl-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-xl-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-xl-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-xl-n12 {
    margin-left: -3rem !important;
  }

  .ms-xl-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-xl-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-xl-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-xl-n16 {
    margin-left: -4rem !important;
  }

  .ms-xl-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-xl-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-xl-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-xl-n20 {
    margin-left: -5rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 0.75rem !important;
  }

  .p-xl-4 {
    padding: 1rem !important;
  }

  .p-xl-5 {
    padding: 1.25rem !important;
  }

  .p-xl-6 {
    padding: 1.5rem !important;
  }

  .p-xl-7 {
    padding: 1.75rem !important;
  }

  .p-xl-8 {
    padding: 2rem !important;
  }

  .p-xl-9 {
    padding: 2.25rem !important;
  }

  .p-xl-10 {
    padding: 2.5rem !important;
  }

  .p-xl-11 {
    padding: 2.75rem !important;
  }

  .p-xl-12 {
    padding: 3rem !important;
  }

  .p-xl-13 {
    padding: 3.25rem !important;
  }

  .p-xl-14 {
    padding: 3.5rem !important;
  }

  .p-xl-15 {
    padding: 3.75rem !important;
  }

  .p-xl-16 {
    padding: 4rem !important;
  }

  .p-xl-17 {
    padding: 4.25rem !important;
  }

  .p-xl-18 {
    padding: 4.5rem !important;
  }

  .p-xl-19 {
    padding: 4.75rem !important;
  }

  .p-xl-20 {
    padding: 5rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-xl-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-xl-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-xl-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-xl-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xl-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-xl-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-xl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-xl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-xl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-xl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xl-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-xl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xl-4 {
    padding-top: 1rem !important;
  }

  .pt-xl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xl-8 {
    padding-top: 2rem !important;
  }

  .pt-xl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-11 {
    padding-top: 2.75rem !important;
  }

  .pt-xl-12 {
    padding-top: 3rem !important;
  }

  .pt-xl-13 {
    padding-top: 3.25rem !important;
  }

  .pt-xl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-15 {
    padding-top: 3.75rem !important;
  }

  .pt-xl-16 {
    padding-top: 4rem !important;
  }

  .pt-xl-17 {
    padding-top: 4.25rem !important;
  }

  .pt-xl-18 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-19 {
    padding-top: 4.75rem !important;
  }

  .pt-xl-20 {
    padding-top: 5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xl-4 {
    padding-right: 1rem !important;
  }

  .pe-xl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xl-8 {
    padding-right: 2rem !important;
  }

  .pe-xl-9 {
    padding-right: 2.25rem !important;
  }

  .pe-xl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-11 {
    padding-right: 2.75rem !important;
  }

  .pe-xl-12 {
    padding-right: 3rem !important;
  }

  .pe-xl-13 {
    padding-right: 3.25rem !important;
  }

  .pe-xl-14 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-15 {
    padding-right: 3.75rem !important;
  }

  .pe-xl-16 {
    padding-right: 4rem !important;
  }

  .pe-xl-17 {
    padding-right: 4.25rem !important;
  }

  .pe-xl-18 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-19 {
    padding-right: 4.75rem !important;
  }

  .pe-xl-20 {
    padding-right: 5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-xl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-xl-16 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-xl-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-xl-20 {
    padding-bottom: 5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xl-4 {
    padding-left: 1rem !important;
  }

  .ps-xl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xl-8 {
    padding-left: 2rem !important;
  }

  .ps-xl-9 {
    padding-left: 2.25rem !important;
  }

  .ps-xl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-11 {
    padding-left: 2.75rem !important;
  }

  .ps-xl-12 {
    padding-left: 3rem !important;
  }

  .ps-xl-13 {
    padding-left: 3.25rem !important;
  }

  .ps-xl-14 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-15 {
    padding-left: 3.75rem !important;
  }

  .ps-xl-16 {
    padding-left: 4rem !important;
  }

  .ps-xl-17 {
    padding-left: 4.25rem !important;
  }

  .ps-xl-18 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-19 {
    padding-left: 4.75rem !important;
  }

  .ps-xl-20 {
    padding-left: 5rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 0.75rem !important;
  }

  .gap-xl-4 {
    gap: 1rem !important;
  }

  .gap-xl-5 {
    gap: 1.25rem !important;
  }

  .gap-xl-6 {
    gap: 1.5rem !important;
  }

  .gap-xl-7 {
    gap: 1.75rem !important;
  }

  .gap-xl-8 {
    gap: 2rem !important;
  }

  .gap-xl-9 {
    gap: 2.25rem !important;
  }

  .gap-xl-10 {
    gap: 2.5rem !important;
  }

  .gap-xl-11 {
    gap: 2.75rem !important;
  }

  .gap-xl-12 {
    gap: 3rem !important;
  }

  .gap-xl-13 {
    gap: 3.25rem !important;
  }

  .gap-xl-14 {
    gap: 3.5rem !important;
  }

  .gap-xl-15 {
    gap: 3.75rem !important;
  }

  .gap-xl-16 {
    gap: 4rem !important;
  }

  .gap-xl-17 {
    gap: 4.25rem !important;
  }

  .gap-xl-18 {
    gap: 4.5rem !important;
  }

  .gap-xl-19 {
    gap: 4.75rem !important;
  }

  .gap-xl-20 {
    gap: 5rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-xl-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-xl-8 {
    row-gap: 2rem !important;
  }

  .row-gap-xl-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-xl-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-xl-11 {
    row-gap: 2.75rem !important;
  }

  .row-gap-xl-12 {
    row-gap: 3rem !important;
  }

  .row-gap-xl-13 {
    row-gap: 3.25rem !important;
  }

  .row-gap-xl-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-xl-15 {
    row-gap: 3.75rem !important;
  }

  .row-gap-xl-16 {
    row-gap: 4rem !important;
  }

  .row-gap-xl-17 {
    row-gap: 4.25rem !important;
  }

  .row-gap-xl-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-xl-19 {
    row-gap: 4.75rem !important;
  }

  .row-gap-xl-20 {
    row-gap: 5rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-xl-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-xl-8 {
    column-gap: 2rem !important;
  }

  .column-gap-xl-9 {
    column-gap: 2.25rem !important;
  }

  .column-gap-xl-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-xl-11 {
    column-gap: 2.75rem !important;
  }

  .column-gap-xl-12 {
    column-gap: 3rem !important;
  }

  .column-gap-xl-13 {
    column-gap: 3.25rem !important;
  }

  .column-gap-xl-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-xl-15 {
    column-gap: 3.75rem !important;
  }

  .column-gap-xl-16 {
    column-gap: 4rem !important;
  }

  .column-gap-xl-17 {
    column-gap: 4.25rem !important;
  }

  .column-gap-xl-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-xl-19 {
    column-gap: 4.75rem !important;
  }

  .column-gap-xl-20 {
    column-gap: 5rem !important;
  }

  .fs-xl-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-xl-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-xl-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-xl-4 {
    font-size: 1.25rem !important;
  }

  .fs-xl-5 {
    font-size: 1.15rem !important;
  }

  .fs-xl-6 {
    font-size: 1.075rem !important;
  }

  .fs-xl-7 {
    font-size: 0.95rem !important;
  }

  .fs-xl-8 {
    font-size: 0.85rem !important;
  }

  .fs-xl-9 {
    font-size: 0.75rem !important;
  }

  .fs-xl-10 {
    font-size: 0.5rem !important;
  }

  .fs-xl-base {
    font-size: 1rem !important;
  }

  .fs-xl-fluid {
    font-size: 100% !important;
  }

  .fs-xl-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-xl-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-xl-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-xl-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-xl-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-xl-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-xl-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-xl-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-xl-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-xl-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-xl-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-xl-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-xl-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-xl-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-xl-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-xl-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }

  .fs-xl-10x {
    font-size: calc(2.125rem + 10.5vw) !important;
  }

  .fs-xl-10qx {
    font-size: calc(2.15rem + 10.8vw) !important;
  }

  .fs-xl-10hx {
    font-size: calc(2.175rem + 11.1vw) !important;
  }

  .fs-xl-10tx {
    font-size: calc(2.2rem + 11.4vw) !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .min-w-xl-unset {
    min-width: unset !important;
  }

  .min-w-xl-25 {
    min-width: 25% !important;
  }

  .min-w-xl-50 {
    min-width: 50% !important;
  }

  .min-w-xl-75 {
    min-width: 75% !important;
  }

  .min-w-xl-100 {
    min-width: 100% !important;
  }

  .min-w-xl-auto {
    min-width: auto !important;
  }

  .min-w-xl-1px {
    min-width: 1px !important;
  }

  .min-w-xl-2px {
    min-width: 2px !important;
  }

  .min-w-xl-3px {
    min-width: 3px !important;
  }

  .min-w-xl-4px {
    min-width: 4px !important;
  }

  .min-w-xl-5px {
    min-width: 5px !important;
  }

  .min-w-xl-6px {
    min-width: 6px !important;
  }

  .min-w-xl-7px {
    min-width: 7px !important;
  }

  .min-w-xl-8px {
    min-width: 8px !important;
  }

  .min-w-xl-9px {
    min-width: 9px !important;
  }

  .min-w-xl-10px {
    min-width: 10px !important;
  }

  .min-w-xl-15px {
    min-width: 15px !important;
  }

  .min-w-xl-20px {
    min-width: 20px !important;
  }

  .min-w-xl-25px {
    min-width: 25px !important;
  }

  .min-w-xl-30px {
    min-width: 30px !important;
  }

  .min-w-xl-35px {
    min-width: 35px !important;
  }

  .min-w-xl-40px {
    min-width: 40px !important;
  }

  .min-w-xl-45px {
    min-width: 45px !important;
  }

  .min-w-xl-50px {
    min-width: 50px !important;
  }

  .min-w-xl-55px {
    min-width: 55px !important;
  }

  .min-w-xl-60px {
    min-width: 60px !important;
  }

  .min-w-xl-65px {
    min-width: 65px !important;
  }

  .min-w-xl-70px {
    min-width: 70px !important;
  }

  .min-w-xl-75px {
    min-width: 75px !important;
  }

  .min-w-xl-80px {
    min-width: 80px !important;
  }

  .min-w-xl-85px {
    min-width: 85px !important;
  }

  .min-w-xl-90px {
    min-width: 90px !important;
  }

  .min-w-xl-95px {
    min-width: 95px !important;
  }

  .min-w-xl-100px {
    min-width: 100px !important;
  }

  .min-w-xl-125px {
    min-width: 125px !important;
  }

  .min-w-xl-150px {
    min-width: 150px !important;
  }

  .min-w-xl-175px {
    min-width: 175px !important;
  }

  .min-w-xl-200px {
    min-width: 200px !important;
  }

  .min-w-xl-225px {
    min-width: 225px !important;
  }

  .min-w-xl-250px {
    min-width: 250px !important;
  }

  .min-w-xl-275px {
    min-width: 275px !important;
  }

  .min-w-xl-300px {
    min-width: 300px !important;
  }

  .min-w-xl-325px {
    min-width: 325px !important;
  }

  .min-w-xl-350px {
    min-width: 350px !important;
  }

  .min-w-xl-375px {
    min-width: 375px !important;
  }

  .min-w-xl-400px {
    min-width: 400px !important;
  }

  .min-w-xl-425px {
    min-width: 425px !important;
  }

  .min-w-xl-450px {
    min-width: 450px !important;
  }

  .min-w-xl-475px {
    min-width: 475px !important;
  }

  .min-w-xl-500px {
    min-width: 500px !important;
  }

  .min-w-xl-550px {
    min-width: 550px !important;
  }

  .min-w-xl-600px {
    min-width: 600px !important;
  }

  .min-w-xl-650px {
    min-width: 650px !important;
  }

  .min-w-xl-700px {
    min-width: 700px !important;
  }

  .min-w-xl-750px {
    min-width: 750px !important;
  }

  .min-w-xl-800px {
    min-width: 800px !important;
  }

  .min-w-xl-850px {
    min-width: 850px !important;
  }

  .min-w-xl-900px {
    min-width: 900px !important;
  }

  .min-w-xl-950px {
    min-width: 950px !important;
  }

  .min-w-xl-1000px {
    min-width: 1000px !important;
  }

  .min-h-xl-unset {
    min-height: unset !important;
  }

  .min-h-xl-25 {
    min-height: 25% !important;
  }

  .min-h-xl-50 {
    min-height: 50% !important;
  }

  .min-h-xl-75 {
    min-height: 75% !important;
  }

  .min-h-xl-100 {
    min-height: 100% !important;
  }

  .min-h-xl-auto {
    min-height: auto !important;
  }

  .min-h-xl-1px {
    min-height: 1px !important;
  }

  .min-h-xl-2px {
    min-height: 2px !important;
  }

  .min-h-xl-3px {
    min-height: 3px !important;
  }

  .min-h-xl-4px {
    min-height: 4px !important;
  }

  .min-h-xl-5px {
    min-height: 5px !important;
  }

  .min-h-xl-6px {
    min-height: 6px !important;
  }

  .min-h-xl-7px {
    min-height: 7px !important;
  }

  .min-h-xl-8px {
    min-height: 8px !important;
  }

  .min-h-xl-9px {
    min-height: 9px !important;
  }

  .min-h-xl-10px {
    min-height: 10px !important;
  }

  .min-h-xl-15px {
    min-height: 15px !important;
  }

  .min-h-xl-20px {
    min-height: 20px !important;
  }

  .min-h-xl-25px {
    min-height: 25px !important;
  }

  .min-h-xl-30px {
    min-height: 30px !important;
  }

  .min-h-xl-35px {
    min-height: 35px !important;
  }

  .min-h-xl-40px {
    min-height: 40px !important;
  }

  .min-h-xl-45px {
    min-height: 45px !important;
  }

  .min-h-xl-50px {
    min-height: 50px !important;
  }

  .min-h-xl-55px {
    min-height: 55px !important;
  }

  .min-h-xl-60px {
    min-height: 60px !important;
  }

  .min-h-xl-65px {
    min-height: 65px !important;
  }

  .min-h-xl-70px {
    min-height: 70px !important;
  }

  .min-h-xl-75px {
    min-height: 75px !important;
  }

  .min-h-xl-80px {
    min-height: 80px !important;
  }

  .min-h-xl-85px {
    min-height: 85px !important;
  }

  .min-h-xl-90px {
    min-height: 90px !important;
  }

  .min-h-xl-95px {
    min-height: 95px !important;
  }

  .min-h-xl-100px {
    min-height: 100px !important;
  }

  .min-h-xl-125px {
    min-height: 125px !important;
  }

  .min-h-xl-150px {
    min-height: 150px !important;
  }

  .min-h-xl-175px {
    min-height: 175px !important;
  }

  .min-h-xl-200px {
    min-height: 200px !important;
  }

  .min-h-xl-225px {
    min-height: 225px !important;
  }

  .min-h-xl-250px {
    min-height: 250px !important;
  }

  .min-h-xl-275px {
    min-height: 275px !important;
  }

  .min-h-xl-300px {
    min-height: 300px !important;
  }

  .min-h-xl-325px {
    min-height: 325px !important;
  }

  .min-h-xl-350px {
    min-height: 350px !important;
  }

  .min-h-xl-375px {
    min-height: 375px !important;
  }

  .min-h-xl-400px {
    min-height: 400px !important;
  }

  .min-h-xl-425px {
    min-height: 425px !important;
  }

  .min-h-xl-450px {
    min-height: 450px !important;
  }

  .min-h-xl-475px {
    min-height: 475px !important;
  }

  .min-h-xl-500px {
    min-height: 500px !important;
  }

  .min-h-xl-550px {
    min-height: 550px !important;
  }

  .min-h-xl-600px {
    min-height: 600px !important;
  }

  .min-h-xl-650px {
    min-height: 650px !important;
  }

  .min-h-xl-700px {
    min-height: 700px !important;
  }

  .min-h-xl-750px {
    min-height: 750px !important;
  }

  .min-h-xl-800px {
    min-height: 800px !important;
  }

  .min-h-xl-850px {
    min-height: 850px !important;
  }

  .min-h-xl-900px {
    min-height: 900px !important;
  }

  .min-h-xl-950px {
    min-height: 950px !important;
  }

  .min-h-xl-1000px {
    min-height: 1000px !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .w-xxl-unset {
    width: unset !important;
  }

  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }

  .w-xxl-auto {
    width: auto !important;
  }

  .w-xxl-1px {
    width: 1px !important;
  }

  .w-xxl-2px {
    width: 2px !important;
  }

  .w-xxl-3px {
    width: 3px !important;
  }

  .w-xxl-4px {
    width: 4px !important;
  }

  .w-xxl-5px {
    width: 5px !important;
  }

  .w-xxl-6px {
    width: 6px !important;
  }

  .w-xxl-7px {
    width: 7px !important;
  }

  .w-xxl-8px {
    width: 8px !important;
  }

  .w-xxl-9px {
    width: 9px !important;
  }

  .w-xxl-10px {
    width: 10px !important;
  }

  .w-xxl-15px {
    width: 15px !important;
  }

  .w-xxl-20px {
    width: 20px !important;
  }

  .w-xxl-25px {
    width: 25px !important;
  }

  .w-xxl-30px {
    width: 30px !important;
  }

  .w-xxl-35px {
    width: 35px !important;
  }

  .w-xxl-40px {
    width: 40px !important;
  }

  .w-xxl-45px {
    width: 45px !important;
  }

  .w-xxl-50px {
    width: 50px !important;
  }

  .w-xxl-55px {
    width: 55px !important;
  }

  .w-xxl-60px {
    width: 60px !important;
  }

  .w-xxl-65px {
    width: 65px !important;
  }

  .w-xxl-70px {
    width: 70px !important;
  }

  .w-xxl-75px {
    width: 75px !important;
  }

  .w-xxl-80px {
    width: 80px !important;
  }

  .w-xxl-85px {
    width: 85px !important;
  }

  .w-xxl-90px {
    width: 90px !important;
  }

  .w-xxl-95px {
    width: 95px !important;
  }

  .w-xxl-100px {
    width: 100px !important;
  }

  .w-xxl-125px {
    width: 125px !important;
  }

  .w-xxl-150px {
    width: 150px !important;
  }

  .w-xxl-175px {
    width: 175px !important;
  }

  .w-xxl-200px {
    width: 200px !important;
  }

  .w-xxl-225px {
    width: 225px !important;
  }

  .w-xxl-250px {
    width: 250px !important;
  }

  .w-xxl-275px {
    width: 275px !important;
  }

  .w-xxl-300px {
    width: 300px !important;
  }

  .w-xxl-325px {
    width: 325px !important;
  }

  .w-xxl-350px {
    width: 350px !important;
  }

  .w-xxl-375px {
    width: 375px !important;
  }

  .w-xxl-400px {
    width: 400px !important;
  }

  .w-xxl-425px {
    width: 425px !important;
  }

  .w-xxl-450px {
    width: 450px !important;
  }

  .w-xxl-475px {
    width: 475px !important;
  }

  .w-xxl-500px {
    width: 500px !important;
  }

  .w-xxl-550px {
    width: 550px !important;
  }

  .w-xxl-600px {
    width: 600px !important;
  }

  .w-xxl-650px {
    width: 650px !important;
  }

  .w-xxl-700px {
    width: 700px !important;
  }

  .w-xxl-750px {
    width: 750px !important;
  }

  .w-xxl-800px {
    width: 800px !important;
  }

  .w-xxl-850px {
    width: 850px !important;
  }

  .w-xxl-900px {
    width: 900px !important;
  }

  .w-xxl-950px {
    width: 950px !important;
  }

  .w-xxl-1000px {
    width: 1000px !important;
  }

  .mw-xxl-unset {
    max-width: unset !important;
  }

  .mw-xxl-25 {
    max-width: 25% !important;
  }

  .mw-xxl-50 {
    max-width: 50% !important;
  }

  .mw-xxl-75 {
    max-width: 75% !important;
  }

  .mw-xxl-100 {
    max-width: 100% !important;
  }

  .mw-xxl-auto {
    max-width: auto !important;
  }

  .mw-xxl-1px {
    max-width: 1px !important;
  }

  .mw-xxl-2px {
    max-width: 2px !important;
  }

  .mw-xxl-3px {
    max-width: 3px !important;
  }

  .mw-xxl-4px {
    max-width: 4px !important;
  }

  .mw-xxl-5px {
    max-width: 5px !important;
  }

  .mw-xxl-6px {
    max-width: 6px !important;
  }

  .mw-xxl-7px {
    max-width: 7px !important;
  }

  .mw-xxl-8px {
    max-width: 8px !important;
  }

  .mw-xxl-9px {
    max-width: 9px !important;
  }

  .mw-xxl-10px {
    max-width: 10px !important;
  }

  .mw-xxl-15px {
    max-width: 15px !important;
  }

  .mw-xxl-20px {
    max-width: 20px !important;
  }

  .mw-xxl-25px {
    max-width: 25px !important;
  }

  .mw-xxl-30px {
    max-width: 30px !important;
  }

  .mw-xxl-35px {
    max-width: 35px !important;
  }

  .mw-xxl-40px {
    max-width: 40px !important;
  }

  .mw-xxl-45px {
    max-width: 45px !important;
  }

  .mw-xxl-50px {
    max-width: 50px !important;
  }

  .mw-xxl-55px {
    max-width: 55px !important;
  }

  .mw-xxl-60px {
    max-width: 60px !important;
  }

  .mw-xxl-65px {
    max-width: 65px !important;
  }

  .mw-xxl-70px {
    max-width: 70px !important;
  }

  .mw-xxl-75px {
    max-width: 75px !important;
  }

  .mw-xxl-80px {
    max-width: 80px !important;
  }

  .mw-xxl-85px {
    max-width: 85px !important;
  }

  .mw-xxl-90px {
    max-width: 90px !important;
  }

  .mw-xxl-95px {
    max-width: 95px !important;
  }

  .mw-xxl-100px {
    max-width: 100px !important;
  }

  .mw-xxl-125px {
    max-width: 125px !important;
  }

  .mw-xxl-150px {
    max-width: 150px !important;
  }

  .mw-xxl-175px {
    max-width: 175px !important;
  }

  .mw-xxl-200px {
    max-width: 200px !important;
  }

  .mw-xxl-225px {
    max-width: 225px !important;
  }

  .mw-xxl-250px {
    max-width: 250px !important;
  }

  .mw-xxl-275px {
    max-width: 275px !important;
  }

  .mw-xxl-300px {
    max-width: 300px !important;
  }

  .mw-xxl-325px {
    max-width: 325px !important;
  }

  .mw-xxl-350px {
    max-width: 350px !important;
  }

  .mw-xxl-375px {
    max-width: 375px !important;
  }

  .mw-xxl-400px {
    max-width: 400px !important;
  }

  .mw-xxl-425px {
    max-width: 425px !important;
  }

  .mw-xxl-450px {
    max-width: 450px !important;
  }

  .mw-xxl-475px {
    max-width: 475px !important;
  }

  .mw-xxl-500px {
    max-width: 500px !important;
  }

  .mw-xxl-550px {
    max-width: 550px !important;
  }

  .mw-xxl-600px {
    max-width: 600px !important;
  }

  .mw-xxl-650px {
    max-width: 650px !important;
  }

  .mw-xxl-700px {
    max-width: 700px !important;
  }

  .mw-xxl-750px {
    max-width: 750px !important;
  }

  .mw-xxl-800px {
    max-width: 800px !important;
  }

  .mw-xxl-850px {
    max-width: 850px !important;
  }

  .mw-xxl-900px {
    max-width: 900px !important;
  }

  .mw-xxl-950px {
    max-width: 950px !important;
  }

  .mw-xxl-1000px {
    max-width: 1000px !important;
  }

  .h-xxl-unset {
    height: unset !important;
  }

  .h-xxl-25 {
    height: 25% !important;
  }

  .h-xxl-50 {
    height: 50% !important;
  }

  .h-xxl-75 {
    height: 75% !important;
  }

  .h-xxl-100 {
    height: 100% !important;
  }

  .h-xxl-auto {
    height: auto !important;
  }

  .h-xxl-1px {
    height: 1px !important;
  }

  .h-xxl-2px {
    height: 2px !important;
  }

  .h-xxl-3px {
    height: 3px !important;
  }

  .h-xxl-4px {
    height: 4px !important;
  }

  .h-xxl-5px {
    height: 5px !important;
  }

  .h-xxl-6px {
    height: 6px !important;
  }

  .h-xxl-7px {
    height: 7px !important;
  }

  .h-xxl-8px {
    height: 8px !important;
  }

  .h-xxl-9px {
    height: 9px !important;
  }

  .h-xxl-10px {
    height: 10px !important;
  }

  .h-xxl-15px {
    height: 15px !important;
  }

  .h-xxl-20px {
    height: 20px !important;
  }

  .h-xxl-25px {
    height: 25px !important;
  }

  .h-xxl-30px {
    height: 30px !important;
  }

  .h-xxl-35px {
    height: 35px !important;
  }

  .h-xxl-40px {
    height: 40px !important;
  }

  .h-xxl-45px {
    height: 45px !important;
  }

  .h-xxl-50px {
    height: 50px !important;
  }

  .h-xxl-55px {
    height: 55px !important;
  }

  .h-xxl-60px {
    height: 60px !important;
  }

  .h-xxl-65px {
    height: 65px !important;
  }

  .h-xxl-70px {
    height: 70px !important;
  }

  .h-xxl-75px {
    height: 75px !important;
  }

  .h-xxl-80px {
    height: 80px !important;
  }

  .h-xxl-85px {
    height: 85px !important;
  }

  .h-xxl-90px {
    height: 90px !important;
  }

  .h-xxl-95px {
    height: 95px !important;
  }

  .h-xxl-100px {
    height: 100px !important;
  }

  .h-xxl-125px {
    height: 125px !important;
  }

  .h-xxl-150px {
    height: 150px !important;
  }

  .h-xxl-175px {
    height: 175px !important;
  }

  .h-xxl-200px {
    height: 200px !important;
  }

  .h-xxl-225px {
    height: 225px !important;
  }

  .h-xxl-250px {
    height: 250px !important;
  }

  .h-xxl-275px {
    height: 275px !important;
  }

  .h-xxl-300px {
    height: 300px !important;
  }

  .h-xxl-325px {
    height: 325px !important;
  }

  .h-xxl-350px {
    height: 350px !important;
  }

  .h-xxl-375px {
    height: 375px !important;
  }

  .h-xxl-400px {
    height: 400px !important;
  }

  .h-xxl-425px {
    height: 425px !important;
  }

  .h-xxl-450px {
    height: 450px !important;
  }

  .h-xxl-475px {
    height: 475px !important;
  }

  .h-xxl-500px {
    height: 500px !important;
  }

  .h-xxl-550px {
    height: 550px !important;
  }

  .h-xxl-600px {
    height: 600px !important;
  }

  .h-xxl-650px {
    height: 650px !important;
  }

  .h-xxl-700px {
    height: 700px !important;
  }

  .h-xxl-750px {
    height: 750px !important;
  }

  .h-xxl-800px {
    height: 800px !important;
  }

  .h-xxl-850px {
    height: 850px !important;
  }

  .h-xxl-900px {
    height: 900px !important;
  }

  .h-xxl-950px {
    height: 950px !important;
  }

  .h-xxl-1000px {
    height: 1000px !important;
  }

  .mh-xxl-unset {
    max-height: unset !important;
  }

  .mh-xxl-25 {
    max-height: 25% !important;
  }

  .mh-xxl-50 {
    max-height: 50% !important;
  }

  .mh-xxl-75 {
    max-height: 75% !important;
  }

  .mh-xxl-100 {
    max-height: 100% !important;
  }

  .mh-xxl-auto {
    max-height: auto !important;
  }

  .mh-xxl-1px {
    max-height: 1px !important;
  }

  .mh-xxl-2px {
    max-height: 2px !important;
  }

  .mh-xxl-3px {
    max-height: 3px !important;
  }

  .mh-xxl-4px {
    max-height: 4px !important;
  }

  .mh-xxl-5px {
    max-height: 5px !important;
  }

  .mh-xxl-6px {
    max-height: 6px !important;
  }

  .mh-xxl-7px {
    max-height: 7px !important;
  }

  .mh-xxl-8px {
    max-height: 8px !important;
  }

  .mh-xxl-9px {
    max-height: 9px !important;
  }

  .mh-xxl-10px {
    max-height: 10px !important;
  }

  .mh-xxl-15px {
    max-height: 15px !important;
  }

  .mh-xxl-20px {
    max-height: 20px !important;
  }

  .mh-xxl-25px {
    max-height: 25px !important;
  }

  .mh-xxl-30px {
    max-height: 30px !important;
  }

  .mh-xxl-35px {
    max-height: 35px !important;
  }

  .mh-xxl-40px {
    max-height: 40px !important;
  }

  .mh-xxl-45px {
    max-height: 45px !important;
  }

  .mh-xxl-50px {
    max-height: 50px !important;
  }

  .mh-xxl-55px {
    max-height: 55px !important;
  }

  .mh-xxl-60px {
    max-height: 60px !important;
  }

  .mh-xxl-65px {
    max-height: 65px !important;
  }

  .mh-xxl-70px {
    max-height: 70px !important;
  }

  .mh-xxl-75px {
    max-height: 75px !important;
  }

  .mh-xxl-80px {
    max-height: 80px !important;
  }

  .mh-xxl-85px {
    max-height: 85px !important;
  }

  .mh-xxl-90px {
    max-height: 90px !important;
  }

  .mh-xxl-95px {
    max-height: 95px !important;
  }

  .mh-xxl-100px {
    max-height: 100px !important;
  }

  .mh-xxl-125px {
    max-height: 125px !important;
  }

  .mh-xxl-150px {
    max-height: 150px !important;
  }

  .mh-xxl-175px {
    max-height: 175px !important;
  }

  .mh-xxl-200px {
    max-height: 200px !important;
  }

  .mh-xxl-225px {
    max-height: 225px !important;
  }

  .mh-xxl-250px {
    max-height: 250px !important;
  }

  .mh-xxl-275px {
    max-height: 275px !important;
  }

  .mh-xxl-300px {
    max-height: 300px !important;
  }

  .mh-xxl-325px {
    max-height: 325px !important;
  }

  .mh-xxl-350px {
    max-height: 350px !important;
  }

  .mh-xxl-375px {
    max-height: 375px !important;
  }

  .mh-xxl-400px {
    max-height: 400px !important;
  }

  .mh-xxl-425px {
    max-height: 425px !important;
  }

  .mh-xxl-450px {
    max-height: 450px !important;
  }

  .mh-xxl-475px {
    max-height: 475px !important;
  }

  .mh-xxl-500px {
    max-height: 500px !important;
  }

  .mh-xxl-550px {
    max-height: 550px !important;
  }

  .mh-xxl-600px {
    max-height: 600px !important;
  }

  .mh-xxl-650px {
    max-height: 650px !important;
  }

  .mh-xxl-700px {
    max-height: 700px !important;
  }

  .mh-xxl-750px {
    max-height: 750px !important;
  }

  .mh-xxl-800px {
    max-height: 800px !important;
  }

  .mh-xxl-850px {
    max-height: 850px !important;
  }

  .mh-xxl-900px {
    max-height: 900px !important;
  }

  .mh-xxl-950px {
    max-height: 950px !important;
  }

  .mh-xxl-1000px {
    max-height: 1000px !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 0.75rem !important;
  }

  .m-xxl-4 {
    margin: 1rem !important;
  }

  .m-xxl-5 {
    margin: 1.25rem !important;
  }

  .m-xxl-6 {
    margin: 1.5rem !important;
  }

  .m-xxl-7 {
    margin: 1.75rem !important;
  }

  .m-xxl-8 {
    margin: 2rem !important;
  }

  .m-xxl-9 {
    margin: 2.25rem !important;
  }

  .m-xxl-10 {
    margin: 2.5rem !important;
  }

  .m-xxl-11 {
    margin: 2.75rem !important;
  }

  .m-xxl-12 {
    margin: 3rem !important;
  }

  .m-xxl-13 {
    margin: 3.25rem !important;
  }

  .m-xxl-14 {
    margin: 3.5rem !important;
  }

  .m-xxl-15 {
    margin: 3.75rem !important;
  }

  .m-xxl-16 {
    margin: 4rem !important;
  }

  .m-xxl-17 {
    margin: 4.25rem !important;
  }

  .m-xxl-18 {
    margin: 4.5rem !important;
  }

  .m-xxl-19 {
    margin: 4.75rem !important;
  }

  .m-xxl-20 {
    margin: 5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xxl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xxl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xxl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-xxl-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-xxl-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xxl-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-xxl-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-xxl-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxl-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-xxl-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xxl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xxl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xxl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xxl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-xxl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-xxl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-xxl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-xxl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-xxl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1rem !important;
  }

  .mt-xxl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xxl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xxl-8 {
    margin-top: 2rem !important;
  }

  .mt-xxl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xxl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-11 {
    margin-top: 2.75rem !important;
  }

  .mt-xxl-12 {
    margin-top: 3rem !important;
  }

  .mt-xxl-13 {
    margin-top: 3.25rem !important;
  }

  .mt-xxl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-15 {
    margin-top: 3.75rem !important;
  }

  .mt-xxl-16 {
    margin-top: 4rem !important;
  }

  .mt-xxl-17 {
    margin-top: 4.25rem !important;
  }

  .mt-xxl-18 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-19 {
    margin-top: 4.75rem !important;
  }

  .mt-xxl-20 {
    margin-top: 5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xxl-4 {
    margin-right: 1rem !important;
  }

  .me-xxl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xxl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xxl-8 {
    margin-right: 2rem !important;
  }

  .me-xxl-9 {
    margin-right: 2.25rem !important;
  }

  .me-xxl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-11 {
    margin-right: 2.75rem !important;
  }

  .me-xxl-12 {
    margin-right: 3rem !important;
  }

  .me-xxl-13 {
    margin-right: 3.25rem !important;
  }

  .me-xxl-14 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-15 {
    margin-right: 3.75rem !important;
  }

  .me-xxl-16 {
    margin-right: 4rem !important;
  }

  .me-xxl-17 {
    margin-right: 4.25rem !important;
  }

  .me-xxl-18 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-19 {
    margin-right: 4.75rem !important;
  }

  .me-xxl-20 {
    margin-right: 5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-xxl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-xxl-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-xxl-20 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1rem !important;
  }

  .ms-xxl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xxl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xxl-8 {
    margin-left: 2rem !important;
  }

  .ms-xxl-9 {
    margin-left: 2.25rem !important;
  }

  .ms-xxl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-11 {
    margin-left: 2.75rem !important;
  }

  .ms-xxl-12 {
    margin-left: 3rem !important;
  }

  .ms-xxl-13 {
    margin-left: 3.25rem !important;
  }

  .ms-xxl-14 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-15 {
    margin-left: 3.75rem !important;
  }

  .ms-xxl-16 {
    margin-left: 4rem !important;
  }

  .ms-xxl-17 {
    margin-left: 4.25rem !important;
  }

  .ms-xxl-18 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-19 {
    margin-left: 4.75rem !important;
  }

  .ms-xxl-20 {
    margin-left: 5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -0.75rem !important;
  }

  .m-xxl-n4 {
    margin: -1rem !important;
  }

  .m-xxl-n5 {
    margin: -1.25rem !important;
  }

  .m-xxl-n6 {
    margin: -1.5rem !important;
  }

  .m-xxl-n7 {
    margin: -1.75rem !important;
  }

  .m-xxl-n8 {
    margin: -2rem !important;
  }

  .m-xxl-n9 {
    margin: -2.25rem !important;
  }

  .m-xxl-n10 {
    margin: -2.5rem !important;
  }

  .m-xxl-n11 {
    margin: -2.75rem !important;
  }

  .m-xxl-n12 {
    margin: -3rem !important;
  }

  .m-xxl-n13 {
    margin: -3.25rem !important;
  }

  .m-xxl-n14 {
    margin: -3.5rem !important;
  }

  .m-xxl-n15 {
    margin: -3.75rem !important;
  }

  .m-xxl-n16 {
    margin: -4rem !important;
  }

  .m-xxl-n17 {
    margin: -4.25rem !important;
  }

  .m-xxl-n18 {
    margin: -4.5rem !important;
  }

  .m-xxl-n19 {
    margin: -4.75rem !important;
  }

  .m-xxl-n20 {
    margin: -5rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xxl-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-xxl-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xxl-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-xxl-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xxl-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-xxl-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xxl-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-xxl-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-xxl-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-xxl-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xxl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xxl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xxl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xxl-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-xxl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-xxl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xxl-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-xxl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-xxl-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xxl-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-xxl-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xxl-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-xxl-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-xxl-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-xxl-n16 {
    margin-top: -4rem !important;
  }

  .mt-xxl-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-xxl-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-xxl-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-xxl-n20 {
    margin-top: -5rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -0.75rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1rem !important;
  }

  .me-xxl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xxl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xxl-n8 {
    margin-right: -2rem !important;
  }

  .me-xxl-n9 {
    margin-right: -2.25rem !important;
  }

  .me-xxl-n10 {
    margin-right: -2.5rem !important;
  }

  .me-xxl-n11 {
    margin-right: -2.75rem !important;
  }

  .me-xxl-n12 {
    margin-right: -3rem !important;
  }

  .me-xxl-n13 {
    margin-right: -3.25rem !important;
  }

  .me-xxl-n14 {
    margin-right: -3.5rem !important;
  }

  .me-xxl-n15 {
    margin-right: -3.75rem !important;
  }

  .me-xxl-n16 {
    margin-right: -4rem !important;
  }

  .me-xxl-n17 {
    margin-right: -4.25rem !important;
  }

  .me-xxl-n18 {
    margin-right: -4.5rem !important;
  }

  .me-xxl-n19 {
    margin-right: -4.75rem !important;
  }

  .me-xxl-n20 {
    margin-right: -5rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xxl-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-xxl-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xxl-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-xxl-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-xxl-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xxl-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-xxl-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-xxl-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-xxl-n12 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-xxl-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-xxl-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-xxl-n16 {
    margin-left: -4rem !important;
  }

  .ms-xxl-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-xxl-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-xxl-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-xxl-n20 {
    margin-left: -5rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 0.75rem !important;
  }

  .p-xxl-4 {
    padding: 1rem !important;
  }

  .p-xxl-5 {
    padding: 1.25rem !important;
  }

  .p-xxl-6 {
    padding: 1.5rem !important;
  }

  .p-xxl-7 {
    padding: 1.75rem !important;
  }

  .p-xxl-8 {
    padding: 2rem !important;
  }

  .p-xxl-9 {
    padding: 2.25rem !important;
  }

  .p-xxl-10 {
    padding: 2.5rem !important;
  }

  .p-xxl-11 {
    padding: 2.75rem !important;
  }

  .p-xxl-12 {
    padding: 3rem !important;
  }

  .p-xxl-13 {
    padding: 3.25rem !important;
  }

  .p-xxl-14 {
    padding: 3.5rem !important;
  }

  .p-xxl-15 {
    padding: 3.75rem !important;
  }

  .p-xxl-16 {
    padding: 4rem !important;
  }

  .p-xxl-17 {
    padding: 4.25rem !important;
  }

  .p-xxl-18 {
    padding: 4.5rem !important;
  }

  .p-xxl-19 {
    padding: 4.75rem !important;
  }

  .p-xxl-20 {
    padding: 5rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xxl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xxl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xxl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xxl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xxl-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-xxl-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-xxl-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xxl-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-xxl-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-xxl-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xxl-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-xxl-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xxl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xxl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xxl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xxl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-xxl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-xxl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-xxl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-xxl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xxl-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-xxl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1rem !important;
  }

  .pt-xxl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xxl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xxl-8 {
    padding-top: 2rem !important;
  }

  .pt-xxl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xxl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-11 {
    padding-top: 2.75rem !important;
  }

  .pt-xxl-12 {
    padding-top: 3rem !important;
  }

  .pt-xxl-13 {
    padding-top: 3.25rem !important;
  }

  .pt-xxl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-15 {
    padding-top: 3.75rem !important;
  }

  .pt-xxl-16 {
    padding-top: 4rem !important;
  }

  .pt-xxl-17 {
    padding-top: 4.25rem !important;
  }

  .pt-xxl-18 {
    padding-top: 4.5rem !important;
  }

  .pt-xxl-19 {
    padding-top: 4.75rem !important;
  }

  .pt-xxl-20 {
    padding-top: 5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1rem !important;
  }

  .pe-xxl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xxl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xxl-8 {
    padding-right: 2rem !important;
  }

  .pe-xxl-9 {
    padding-right: 2.25rem !important;
  }

  .pe-xxl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-11 {
    padding-right: 2.75rem !important;
  }

  .pe-xxl-12 {
    padding-right: 3rem !important;
  }

  .pe-xxl-13 {
    padding-right: 3.25rem !important;
  }

  .pe-xxl-14 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-15 {
    padding-right: 3.75rem !important;
  }

  .pe-xxl-16 {
    padding-right: 4rem !important;
  }

  .pe-xxl-17 {
    padding-right: 4.25rem !important;
  }

  .pe-xxl-18 {
    padding-right: 4.5rem !important;
  }

  .pe-xxl-19 {
    padding-right: 4.75rem !important;
  }

  .pe-xxl-20 {
    padding-right: 5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-xxl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-xxl-16 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-xxl-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxl-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-xxl-20 {
    padding-bottom: 5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1rem !important;
  }

  .ps-xxl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xxl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xxl-8 {
    padding-left: 2rem !important;
  }

  .ps-xxl-9 {
    padding-left: 2.25rem !important;
  }

  .ps-xxl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-11 {
    padding-left: 2.75rem !important;
  }

  .ps-xxl-12 {
    padding-left: 3rem !important;
  }

  .ps-xxl-13 {
    padding-left: 3.25rem !important;
  }

  .ps-xxl-14 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-15 {
    padding-left: 3.75rem !important;
  }

  .ps-xxl-16 {
    padding-left: 4rem !important;
  }

  .ps-xxl-17 {
    padding-left: 4.25rem !important;
  }

  .ps-xxl-18 {
    padding-left: 4.5rem !important;
  }

  .ps-xxl-19 {
    padding-left: 4.75rem !important;
  }

  .ps-xxl-20 {
    padding-left: 5rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 0.75rem !important;
  }

  .gap-xxl-4 {
    gap: 1rem !important;
  }

  .gap-xxl-5 {
    gap: 1.25rem !important;
  }

  .gap-xxl-6 {
    gap: 1.5rem !important;
  }

  .gap-xxl-7 {
    gap: 1.75rem !important;
  }

  .gap-xxl-8 {
    gap: 2rem !important;
  }

  .gap-xxl-9 {
    gap: 2.25rem !important;
  }

  .gap-xxl-10 {
    gap: 2.5rem !important;
  }

  .gap-xxl-11 {
    gap: 2.75rem !important;
  }

  .gap-xxl-12 {
    gap: 3rem !important;
  }

  .gap-xxl-13 {
    gap: 3.25rem !important;
  }

  .gap-xxl-14 {
    gap: 3.5rem !important;
  }

  .gap-xxl-15 {
    gap: 3.75rem !important;
  }

  .gap-xxl-16 {
    gap: 4rem !important;
  }

  .gap-xxl-17 {
    gap: 4.25rem !important;
  }

  .gap-xxl-18 {
    gap: 4.5rem !important;
  }

  .gap-xxl-19 {
    gap: 4.75rem !important;
  }

  .gap-xxl-20 {
    gap: 5rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-xxl-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-xxl-8 {
    row-gap: 2rem !important;
  }

  .row-gap-xxl-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-xxl-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-xxl-11 {
    row-gap: 2.75rem !important;
  }

  .row-gap-xxl-12 {
    row-gap: 3rem !important;
  }

  .row-gap-xxl-13 {
    row-gap: 3.25rem !important;
  }

  .row-gap-xxl-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-xxl-15 {
    row-gap: 3.75rem !important;
  }

  .row-gap-xxl-16 {
    row-gap: 4rem !important;
  }

  .row-gap-xxl-17 {
    row-gap: 4.25rem !important;
  }

  .row-gap-xxl-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-xxl-19 {
    row-gap: 4.75rem !important;
  }

  .row-gap-xxl-20 {
    row-gap: 5rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-xxl-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-xxl-8 {
    column-gap: 2rem !important;
  }

  .column-gap-xxl-9 {
    column-gap: 2.25rem !important;
  }

  .column-gap-xxl-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-xxl-11 {
    column-gap: 2.75rem !important;
  }

  .column-gap-xxl-12 {
    column-gap: 3rem !important;
  }

  .column-gap-xxl-13 {
    column-gap: 3.25rem !important;
  }

  .column-gap-xxl-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-xxl-15 {
    column-gap: 3.75rem !important;
  }

  .column-gap-xxl-16 {
    column-gap: 4rem !important;
  }

  .column-gap-xxl-17 {
    column-gap: 4.25rem !important;
  }

  .column-gap-xxl-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-xxl-19 {
    column-gap: 4.75rem !important;
  }

  .column-gap-xxl-20 {
    column-gap: 5rem !important;
  }

  .fs-xxl-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-xxl-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-xxl-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-xxl-4 {
    font-size: 1.25rem !important;
  }

  .fs-xxl-5 {
    font-size: 1.15rem !important;
  }

  .fs-xxl-6 {
    font-size: 1.075rem !important;
  }

  .fs-xxl-7 {
    font-size: 0.95rem !important;
  }

  .fs-xxl-8 {
    font-size: 0.85rem !important;
  }

  .fs-xxl-9 {
    font-size: 0.75rem !important;
  }

  .fs-xxl-10 {
    font-size: 0.5rem !important;
  }

  .fs-xxl-base {
    font-size: 1rem !important;
  }

  .fs-xxl-fluid {
    font-size: 100% !important;
  }

  .fs-xxl-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-xxl-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-xxl-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-xxl-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-xxl-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-xxl-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-xxl-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-xxl-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-xxl-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-xxl-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-xxl-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-xxl-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-xxl-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-xxl-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-xxl-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-xxl-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }

  .fs-xxl-10x {
    font-size: calc(2.125rem + 10.5vw) !important;
  }

  .fs-xxl-10qx {
    font-size: calc(2.15rem + 10.8vw) !important;
  }

  .fs-xxl-10hx {
    font-size: calc(2.175rem + 11.1vw) !important;
  }

  .fs-xxl-10tx {
    font-size: calc(2.2rem + 11.4vw) !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .min-w-xxl-unset {
    min-width: unset !important;
  }

  .min-w-xxl-25 {
    min-width: 25% !important;
  }

  .min-w-xxl-50 {
    min-width: 50% !important;
  }

  .min-w-xxl-75 {
    min-width: 75% !important;
  }

  .min-w-xxl-100 {
    min-width: 100% !important;
  }

  .min-w-xxl-auto {
    min-width: auto !important;
  }

  .min-w-xxl-1px {
    min-width: 1px !important;
  }

  .min-w-xxl-2px {
    min-width: 2px !important;
  }

  .min-w-xxl-3px {
    min-width: 3px !important;
  }

  .min-w-xxl-4px {
    min-width: 4px !important;
  }

  .min-w-xxl-5px {
    min-width: 5px !important;
  }

  .min-w-xxl-6px {
    min-width: 6px !important;
  }

  .min-w-xxl-7px {
    min-width: 7px !important;
  }

  .min-w-xxl-8px {
    min-width: 8px !important;
  }

  .min-w-xxl-9px {
    min-width: 9px !important;
  }

  .min-w-xxl-10px {
    min-width: 10px !important;
  }

  .min-w-xxl-15px {
    min-width: 15px !important;
  }

  .min-w-xxl-20px {
    min-width: 20px !important;
  }

  .min-w-xxl-25px {
    min-width: 25px !important;
  }

  .min-w-xxl-30px {
    min-width: 30px !important;
  }

  .min-w-xxl-35px {
    min-width: 35px !important;
  }

  .min-w-xxl-40px {
    min-width: 40px !important;
  }

  .min-w-xxl-45px {
    min-width: 45px !important;
  }

  .min-w-xxl-50px {
    min-width: 50px !important;
  }

  .min-w-xxl-55px {
    min-width: 55px !important;
  }

  .min-w-xxl-60px {
    min-width: 60px !important;
  }

  .min-w-xxl-65px {
    min-width: 65px !important;
  }

  .min-w-xxl-70px {
    min-width: 70px !important;
  }

  .min-w-xxl-75px {
    min-width: 75px !important;
  }

  .min-w-xxl-80px {
    min-width: 80px !important;
  }

  .min-w-xxl-85px {
    min-width: 85px !important;
  }

  .min-w-xxl-90px {
    min-width: 90px !important;
  }

  .min-w-xxl-95px {
    min-width: 95px !important;
  }

  .min-w-xxl-100px {
    min-width: 100px !important;
  }

  .min-w-xxl-125px {
    min-width: 125px !important;
  }

  .min-w-xxl-150px {
    min-width: 150px !important;
  }

  .min-w-xxl-175px {
    min-width: 175px !important;
  }

  .min-w-xxl-200px {
    min-width: 200px !important;
  }

  .min-w-xxl-225px {
    min-width: 225px !important;
  }

  .min-w-xxl-250px {
    min-width: 250px !important;
  }

  .min-w-xxl-275px {
    min-width: 275px !important;
  }

  .min-w-xxl-300px {
    min-width: 300px !important;
  }

  .min-w-xxl-325px {
    min-width: 325px !important;
  }

  .min-w-xxl-350px {
    min-width: 350px !important;
  }

  .min-w-xxl-375px {
    min-width: 375px !important;
  }

  .min-w-xxl-400px {
    min-width: 400px !important;
  }

  .min-w-xxl-425px {
    min-width: 425px !important;
  }

  .min-w-xxl-450px {
    min-width: 450px !important;
  }

  .min-w-xxl-475px {
    min-width: 475px !important;
  }

  .min-w-xxl-500px {
    min-width: 500px !important;
  }

  .min-w-xxl-550px {
    min-width: 550px !important;
  }

  .min-w-xxl-600px {
    min-width: 600px !important;
  }

  .min-w-xxl-650px {
    min-width: 650px !important;
  }

  .min-w-xxl-700px {
    min-width: 700px !important;
  }

  .min-w-xxl-750px {
    min-width: 750px !important;
  }

  .min-w-xxl-800px {
    min-width: 800px !important;
  }

  .min-w-xxl-850px {
    min-width: 850px !important;
  }

  .min-w-xxl-900px {
    min-width: 900px !important;
  }

  .min-w-xxl-950px {
    min-width: 950px !important;
  }

  .min-w-xxl-1000px {
    min-width: 1000px !important;
  }

  .min-h-xxl-unset {
    min-height: unset !important;
  }

  .min-h-xxl-25 {
    min-height: 25% !important;
  }

  .min-h-xxl-50 {
    min-height: 50% !important;
  }

  .min-h-xxl-75 {
    min-height: 75% !important;
  }

  .min-h-xxl-100 {
    min-height: 100% !important;
  }

  .min-h-xxl-auto {
    min-height: auto !important;
  }

  .min-h-xxl-1px {
    min-height: 1px !important;
  }

  .min-h-xxl-2px {
    min-height: 2px !important;
  }

  .min-h-xxl-3px {
    min-height: 3px !important;
  }

  .min-h-xxl-4px {
    min-height: 4px !important;
  }

  .min-h-xxl-5px {
    min-height: 5px !important;
  }

  .min-h-xxl-6px {
    min-height: 6px !important;
  }

  .min-h-xxl-7px {
    min-height: 7px !important;
  }

  .min-h-xxl-8px {
    min-height: 8px !important;
  }

  .min-h-xxl-9px {
    min-height: 9px !important;
  }

  .min-h-xxl-10px {
    min-height: 10px !important;
  }

  .min-h-xxl-15px {
    min-height: 15px !important;
  }

  .min-h-xxl-20px {
    min-height: 20px !important;
  }

  .min-h-xxl-25px {
    min-height: 25px !important;
  }

  .min-h-xxl-30px {
    min-height: 30px !important;
  }

  .min-h-xxl-35px {
    min-height: 35px !important;
  }

  .min-h-xxl-40px {
    min-height: 40px !important;
  }

  .min-h-xxl-45px {
    min-height: 45px !important;
  }

  .min-h-xxl-50px {
    min-height: 50px !important;
  }

  .min-h-xxl-55px {
    min-height: 55px !important;
  }

  .min-h-xxl-60px {
    min-height: 60px !important;
  }

  .min-h-xxl-65px {
    min-height: 65px !important;
  }

  .min-h-xxl-70px {
    min-height: 70px !important;
  }

  .min-h-xxl-75px {
    min-height: 75px !important;
  }

  .min-h-xxl-80px {
    min-height: 80px !important;
  }

  .min-h-xxl-85px {
    min-height: 85px !important;
  }

  .min-h-xxl-90px {
    min-height: 90px !important;
  }

  .min-h-xxl-95px {
    min-height: 95px !important;
  }

  .min-h-xxl-100px {
    min-height: 100px !important;
  }

  .min-h-xxl-125px {
    min-height: 125px !important;
  }

  .min-h-xxl-150px {
    min-height: 150px !important;
  }

  .min-h-xxl-175px {
    min-height: 175px !important;
  }

  .min-h-xxl-200px {
    min-height: 200px !important;
  }

  .min-h-xxl-225px {
    min-height: 225px !important;
  }

  .min-h-xxl-250px {
    min-height: 250px !important;
  }

  .min-h-xxl-275px {
    min-height: 275px !important;
  }

  .min-h-xxl-300px {
    min-height: 300px !important;
  }

  .min-h-xxl-325px {
    min-height: 325px !important;
  }

  .min-h-xxl-350px {
    min-height: 350px !important;
  }

  .min-h-xxl-375px {
    min-height: 375px !important;
  }

  .min-h-xxl-400px {
    min-height: 400px !important;
  }

  .min-h-xxl-425px {
    min-height: 425px !important;
  }

  .min-h-xxl-450px {
    min-height: 450px !important;
  }

  .min-h-xxl-475px {
    min-height: 475px !important;
  }

  .min-h-xxl-500px {
    min-height: 500px !important;
  }

  .min-h-xxl-550px {
    min-height: 550px !important;
  }

  .min-h-xxl-600px {
    min-height: 600px !important;
  }

  .min-h-xxl-650px {
    min-height: 650px !important;
  }

  .min-h-xxl-700px {
    min-height: 700px !important;
  }

  .min-h-xxl-750px {
    min-height: 750px !important;
  }

  .min-h-xxl-800px {
    min-height: 800px !important;
  }

  .min-h-xxl-850px {
    min-height: 850px !important;
  }

  .min-h-xxl-900px {
    min-height: 900px !important;
  }

  .min-h-xxl-950px {
    min-height: 950px !important;
  }

  .min-h-xxl-1000px {
    min-height: 1000px !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 1.75rem !important;
  }

  .fs-2 {
    font-size: 1.5rem !important;
  }

  .fs-3 {
    font-size: 1.35rem !important;
  }

  .fs-2x {
    font-size: 2rem !important;
  }

  .fs-2qx {
    font-size: 2.25rem !important;
  }

  .fs-2hx {
    font-size: 2.5rem !important;
  }

  .fs-2tx {
    font-size: 2.75rem !important;
  }

  .fs-3x {
    font-size: 3rem !important;
  }

  .fs-3qx {
    font-size: 3.25rem !important;
  }

  .fs-3hx {
    font-size: 3.5rem !important;
  }

  .fs-3tx {
    font-size: 3.75rem !important;
  }

  .fs-4x {
    font-size: 4rem !important;
  }

  .fs-4qx {
    font-size: 4.25rem !important;
  }

  .fs-4hx {
    font-size: 4.5rem !important;
  }

  .fs-4tx {
    font-size: 4.75rem !important;
  }

  .fs-5x {
    font-size: 5rem !important;
  }

  .fs-5qx {
    font-size: 5.25rem !important;
  }

  .fs-5hx {
    font-size: 5.5rem !important;
  }

  .fs-5tx {
    font-size: 5.75rem !important;
  }

  .fs-10x {
    font-size: 10rem !important;
  }

  .fs-10qx {
    font-size: 10.25rem !important;
  }

  .fs-10hx {
    font-size: 10.5rem !important;
  }

  .fs-10tx {
    font-size: 10.75rem !important;
  }

  .fs-sm-1 {
    font-size: 1.75rem !important;
  }

  .fs-sm-2 {
    font-size: 1.5rem !important;
  }

  .fs-sm-3 {
    font-size: 1.35rem !important;
  }

  .fs-sm-2x {
    font-size: 2rem !important;
  }

  .fs-sm-2qx {
    font-size: 2.25rem !important;
  }

  .fs-sm-2hx {
    font-size: 2.5rem !important;
  }

  .fs-sm-2tx {
    font-size: 2.75rem !important;
  }

  .fs-sm-3x {
    font-size: 3rem !important;
  }

  .fs-sm-3qx {
    font-size: 3.25rem !important;
  }

  .fs-sm-3hx {
    font-size: 3.5rem !important;
  }

  .fs-sm-3tx {
    font-size: 3.75rem !important;
  }

  .fs-sm-4x {
    font-size: 4rem !important;
  }

  .fs-sm-4qx {
    font-size: 4.25rem !important;
  }

  .fs-sm-4hx {
    font-size: 4.5rem !important;
  }

  .fs-sm-4tx {
    font-size: 4.75rem !important;
  }

  .fs-sm-5x {
    font-size: 5rem !important;
  }

  .fs-sm-5qx {
    font-size: 5.25rem !important;
  }

  .fs-sm-5hx {
    font-size: 5.5rem !important;
  }

  .fs-sm-5tx {
    font-size: 5.75rem !important;
  }

  .fs-sm-10x {
    font-size: 10rem !important;
  }

  .fs-sm-10qx {
    font-size: 10.25rem !important;
  }

  .fs-sm-10hx {
    font-size: 10.5rem !important;
  }

  .fs-sm-10tx {
    font-size: 10.75rem !important;
  }

  .fs-md-1 {
    font-size: 1.75rem !important;
  }

  .fs-md-2 {
    font-size: 1.5rem !important;
  }

  .fs-md-3 {
    font-size: 1.35rem !important;
  }

  .fs-md-2x {
    font-size: 2rem !important;
  }

  .fs-md-2qx {
    font-size: 2.25rem !important;
  }

  .fs-md-2hx {
    font-size: 2.5rem !important;
  }

  .fs-md-2tx {
    font-size: 2.75rem !important;
  }

  .fs-md-3x {
    font-size: 3rem !important;
  }

  .fs-md-3qx {
    font-size: 3.25rem !important;
  }

  .fs-md-3hx {
    font-size: 3.5rem !important;
  }

  .fs-md-3tx {
    font-size: 3.75rem !important;
  }

  .fs-md-4x {
    font-size: 4rem !important;
  }

  .fs-md-4qx {
    font-size: 4.25rem !important;
  }

  .fs-md-4hx {
    font-size: 4.5rem !important;
  }

  .fs-md-4tx {
    font-size: 4.75rem !important;
  }

  .fs-md-5x {
    font-size: 5rem !important;
  }

  .fs-md-5qx {
    font-size: 5.25rem !important;
  }

  .fs-md-5hx {
    font-size: 5.5rem !important;
  }

  .fs-md-5tx {
    font-size: 5.75rem !important;
  }

  .fs-md-10x {
    font-size: 10rem !important;
  }

  .fs-md-10qx {
    font-size: 10.25rem !important;
  }

  .fs-md-10hx {
    font-size: 10.5rem !important;
  }

  .fs-md-10tx {
    font-size: 10.75rem !important;
  }

  .fs-lg-1 {
    font-size: 1.75rem !important;
  }

  .fs-lg-2 {
    font-size: 1.5rem !important;
  }

  .fs-lg-3 {
    font-size: 1.35rem !important;
  }

  .fs-lg-2x {
    font-size: 2rem !important;
  }

  .fs-lg-2qx {
    font-size: 2.25rem !important;
  }

  .fs-lg-2hx {
    font-size: 2.5rem !important;
  }

  .fs-lg-2tx {
    font-size: 2.75rem !important;
  }

  .fs-lg-3x {
    font-size: 3rem !important;
  }

  .fs-lg-3qx {
    font-size: 3.25rem !important;
  }

  .fs-lg-3hx {
    font-size: 3.5rem !important;
  }

  .fs-lg-3tx {
    font-size: 3.75rem !important;
  }

  .fs-lg-4x {
    font-size: 4rem !important;
  }

  .fs-lg-4qx {
    font-size: 4.25rem !important;
  }

  .fs-lg-4hx {
    font-size: 4.5rem !important;
  }

  .fs-lg-4tx {
    font-size: 4.75rem !important;
  }

  .fs-lg-5x {
    font-size: 5rem !important;
  }

  .fs-lg-5qx {
    font-size: 5.25rem !important;
  }

  .fs-lg-5hx {
    font-size: 5.5rem !important;
  }

  .fs-lg-5tx {
    font-size: 5.75rem !important;
  }

  .fs-lg-10x {
    font-size: 10rem !important;
  }

  .fs-lg-10qx {
    font-size: 10.25rem !important;
  }

  .fs-lg-10hx {
    font-size: 10.5rem !important;
  }

  .fs-lg-10tx {
    font-size: 10.75rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.card {
  border: 0;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.05);
}
.light-theme .card {
  color: #181c32;
  background-color: #ffffff;
}
.dark-theme .card {
  color: #f5f8fa;
  background-color: #1e1e2d;
}
.card > .card-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 2.25rem;
  min-height: 70px;
  background-color: transparent;
}
.light-theme .card > .card-header {
  color: #181c32;
  border-bottom: 1px solid #eff2f5;
}
.dark-theme .card > .card-header {
  color: #f5f8fa;
  border-bottom: 1px solid #3f4254;
}
.card > .card-header .card-title {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin: 0.5rem;
  margin-left: 0;
}
.card > .card-header .card-title.flex-column {
  align-items: flex-start;
  justify-content: center;
}
.card > .card-header .card-title .card-icon {
  margin-right: 0.75rem;
  line-height: 0;
}
.card > .card-header .card-title .card-icon i {
  font-size: 1.25rem;
  color: #7e8299;
  line-height: 0;
}
.card > .card-header .card-title .card-icon i:after, .card > .card-header .card-title .card-icon i:before {
  line-height: 0;
}
.card > .card-header .card-title .card-icon .svg-icon svg {
  height: 24px;
  width: 24px;
}
.card > .card-header .card-title .card-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.card > .card-header .card-title .card-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.card > .card-header .card-title,
.card > .card-header .card-title .card-label {
  font-size: 1.275rem;
}
.light-theme .card > .card-header .card-title,
.light-theme .card > .card-header .card-title .card-label {
  color: #1e1e2d;
}
.dark-theme .card > .card-header .card-title,
.dark-theme .card > .card-header .card-title .card-label {
  color: #ffffff;
}
.card > .card-header .card-title .card-label {
  margin: 0 0.75rem 0 0;
  flex-wrap: wrap;
}
.card > .card-header .card-title small, .card > .card-header .card-title .small {
  color: #a1a5b7;
  font-size: 1rem;
}
.card > .card-header .card-title h1, .card > .card-header .card-title .h1,
.card > .card-header .card-title h2,
.card > .card-header .card-title .h2,
.card > .card-header .card-title h3,
.card > .card-header .card-title .h3,
.card > .card-header .card-title h4,
.card > .card-header .card-title .h4,
.card > .card-header .card-title h5,
.card > .card-header .card-title .h5,
.card > .card-header .card-title h6,
.card > .card-header .card-title .h6 {
  margin-bottom: 0;
}
.card > .card-header .card-toolbar {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  flex-wrap: wrap;
}
.card > .card-body {
  padding: 2rem 2.25rem;
}
.light-theme .card > .card-body {
  color: #181c32;
}
.dark-theme .card > .card-body {
  color: #f5f8fa;
}
.card > .card-footer {
  padding: 2rem 2.25rem;
  background-color: transparent;
}
.light-theme .card > .card-footer {
  border-top: 1px solid #eff2f5;
}
.dark-theme .card > .card-footer {
  border-top: 1px solid #3f4254;
}
.card.card-dark {
  color: #ffffff;
  background-color: #1e1e2d;
}
.card.card-dark > .card-header {
  color: #f5f8fa;
  border-bottom: 1px solid #3f4254;
  background-color: #1a1a27;
}
.card.card-dark > .card-header .card-title,
.card.card-dark > .card-header .card-title .card-label {
  color: #ffffff;
}
.card.card-dark > .card-body {
  color: #f5f8fa;
}
.card.card-dark > .card-footer {
  border-top: 1px solid #3f4254;
}
.card .card-scroll {
  position: relative;
  overflow: auto;
}
.card.card-px-0 .card-header,
.card.card-px-0 .card-body,
.card.card-px-0 .card-footer {
  padding-left: 0;
  padding-right: 0;
}
.card.card-dashed {
  box-shadow: none;
}
.light-theme .card.card-dashed {
  border: 1px dashed #eff2f5 !important;
}
.dark-theme .card.card-dashed {
  border: 1px dashed #3f4254 !important;
}
.light-theme .card.card-dashed > .card-header {
  border-bottom: 1px dashed #eff2f5 !important;
}
.dark-theme .card.card-dashed > .card-header {
  border-bottom: 1px dashed #3f4254 !important;
}
.light-theme .card.card-dashed > .card-footer {
  border-top: 1px dashed #eff2f5 !important;
}
.dark-theme .card.card-dashed > .card-footer {
  border-top: 1px dashed #3f4254 !important;
}
.card.card-bordered {
  box-shadow: none;
}
.light-theme .card.card-bordered {
  border: 1px solid #eff2f5 !important;
}
.dark-theme .card.card-bordered {
  border: 1px solid #3f4254 !important;
}
.card.card-flush > .card-header {
  border-bottom: 0;
}
.card.card-flush > .card-footer {
  border-top: 0;
}
.card.card-outline {
  box-shadow: none;
}
.light-theme .card.card-outline {
  border: 1px solid #eff2f5 !important;
}
.dark-theme .card.card-outline {
  border: 1px solid #3f4254 !important;
}
.card.card-transparent {
  box-shadow: none;
  background-color: transparent;
}
.card.card-shadow {
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  border: 0;
}

.card.card-stretch {
  height: calc(100% - var(--bs-gutter-y));
}
.card.card-stretch-75 {
  height: calc(75% - var(--bs-gutter-y));
}
.card.card-stretch-50 {
  height: calc(50% - var(--bs-gutter-y));
}
.card.card-stretch-33 {
  height: calc(33.333% - var(--bs-gutter-y));
}
.card.card-stretch-25 {
  height: calc(25% - var(--bs-gutter-y));
}
.card .card-header-stretch {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  align-items: stretch;
}
.card .card-header-stretch .card-toolbar {
  margin: 0;
  align-items: stretch;
}
@media (min-width: 576px) {
  .card.card-sm-stretch {
    height: calc(100% - var(--bs-gutter-y));
  }
  .card.card-sm-stretch-75 {
    height: calc(75% - var(--bs-gutter-y));
  }
  .card.card-sm-stretch-50 {
    height: calc(50% - var(--bs-gutter-y));
  }
  .card.card-sm-stretch-33 {
    height: calc(33.333% - var(--bs-gutter-y));
  }
  .card.card-sm-stretch-25 {
    height: calc(25% - var(--bs-gutter-y));
  }
  .card .card-header-sm-stretch {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    align-items: stretch;
  }
  .card .card-header-sm-stretch .card-toolbar {
    margin: 0;
    align-items: stretch;
  }
}
@media (min-width: 768px) {
  .card.card-md-stretch {
    height: calc(100% - var(--bs-gutter-y));
  }
  .card.card-md-stretch-75 {
    height: calc(75% - var(--bs-gutter-y));
  }
  .card.card-md-stretch-50 {
    height: calc(50% - var(--bs-gutter-y));
  }
  .card.card-md-stretch-33 {
    height: calc(33.333% - var(--bs-gutter-y));
  }
  .card.card-md-stretch-25 {
    height: calc(25% - var(--bs-gutter-y));
  }
  .card .card-header-md-stretch {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    align-items: stretch;
  }
  .card .card-header-md-stretch .card-toolbar {
    margin: 0;
    align-items: stretch;
  }
}
@media (min-width: 992px) {
  .card.card-lg-stretch {
    height: calc(100% - var(--bs-gutter-y));
  }
  .card.card-lg-stretch-75 {
    height: calc(75% - var(--bs-gutter-y));
  }
  .card.card-lg-stretch-50 {
    height: calc(50% - var(--bs-gutter-y));
  }
  .card.card-lg-stretch-33 {
    height: calc(33.333% - var(--bs-gutter-y));
  }
  .card.card-lg-stretch-25 {
    height: calc(25% - var(--bs-gutter-y));
  }
  .card .card-header-lg-stretch {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    align-items: stretch;
  }
  .card .card-header-lg-stretch .card-toolbar {
    margin: 0;
    align-items: stretch;
  }
}
@media (min-width: 1200px) {
  .card.card-xl-stretch {
    height: calc(100% - var(--bs-gutter-y));
  }
  .card.card-xl-stretch-75 {
    height: calc(75% - var(--bs-gutter-y));
  }
  .card.card-xl-stretch-50 {
    height: calc(50% - var(--bs-gutter-y));
  }
  .card.card-xl-stretch-33 {
    height: calc(33.333% - var(--bs-gutter-y));
  }
  .card.card-xl-stretch-25 {
    height: calc(25% - var(--bs-gutter-y));
  }
  .card .card-header-xl-stretch {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    align-items: stretch;
  }
  .card .card-header-xl-stretch .card-toolbar {
    margin: 0;
    align-items: stretch;
  }
}
@media (min-width: 1400px) {
  .card.card-xxl-stretch {
    height: calc(100% - var(--bs-gutter-y));
  }
  .card.card-xxl-stretch-75 {
    height: calc(75% - var(--bs-gutter-y));
  }
  .card.card-xxl-stretch-50 {
    height: calc(50% - var(--bs-gutter-y));
  }
  .card.card-xxl-stretch-33 {
    height: calc(33.333% - var(--bs-gutter-y));
  }
  .card.card-xxl-stretch-25 {
    height: calc(25% - var(--bs-gutter-y));
  }
  .card .card-header-xxl-stretch {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    align-items: stretch;
  }
  .card .card-header-xxl-stretch .card-toolbar {
    margin: 0;
    align-items: stretch;
  }
}

.card-p {
  padding: 2rem 2.25rem !important;
}

.card-m {
  margin: 2rem 2.25rem;
}

.card-px {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}

.card-py {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.card-rounded {
  border-radius: 0.475rem;
}

.card-rounded-top {
  border-top-left-radius: 0.475rem;
  border-top-right-radius: 0.475rem;
}

.card-rounded-bottom {
  border-bottom-left-radius: 0.475rem;
  border-bottom-right-radius: 0.475rem;
}

@media (max-width: 767.98px) {
  .card > .card-header:not(.flex-nowrap) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
.menu-group {
  display: flex;
}

.menu,
.menu-wrapper {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

.menu-sub {
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-direction: column;
}

.menu-item {
  display: block;
  padding: 0;
}
.menu-item .menu-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  flex: 0 0 100%;
  padding: 1rem 1.5rem;
  transition: none;
}
.menu-item .menu-link .menu-icon {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  margin-right: 0.5rem;
}
.menu-item .menu-link .menu-icon .svg-icon {
  line-height: 1;
}
.menu-item .menu-link .menu-bullet {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  margin-right: 0.5rem;
}
.menu-item .menu-link .menu-title {
  flex-grow: 1;
}
.menu-item .menu-link .menu-badge {
  flex-shrink: 0;
  margin-left: 0.5rem;
}
.menu-item .menu-link .menu-arrow {
  display: flex;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  margin-left: 0.5rem;
  width: 0.8rem;
  height: 0.8rem;
}
.menu-item .menu-link .menu-arrow:after {
  display: block;
  width: 100%;
  content: " ";
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23a1a5b7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.menu-item .menu-content {
  padding: 1rem 1.5rem;
}

.menu-item.show .menu-link .menu-arrow:after {
  -webkit-backface-visibility: hidden;
  transition: transform 0.3s ease;
}

.menu-center {
  justify-content: center;
}

.menu-item.menu-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
  /*rtl:ignore*/
  transform: rotateZ(90deg);
  transition: transform 0.3s ease;
}
[direction=rtl] .menu-item.menu-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
  /*rtl:ignore*/
  transform: rotateZ(-90deg);
}

.menu-sub-dropdown {
  display: none;
  border-radius: 0.475rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  z-index: 105;
}
.show.menu-dropdown > .menu-sub-dropdown, .menu-sub-dropdown.menu.show, .menu-sub-dropdown.show[data-popper-placement] {
  display: flex;
  animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
}
.show.menu-dropdown > .menu-sub-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-dropdown[data-popper-placement=top-end], .menu-sub-dropdown.menu.show[data-popper-placement=top], .menu-sub-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
  animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
}

.menu-sub-accordion {
  display: none;
}
.show:not(.menu-dropdown) > .menu-sub-accordion, .menu-sub-accordion.show {
  display: flex;
}

.menu-inline {
  display: flex;
}

.menu-fit > .menu-item > .menu-content,
.menu-fit > .menu-item > .menu-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.menu-column {
  flex-direction: column;
  width: 100%;
}

.menu-row {
  flex-direction: row;
}
.menu-row > .menu-item {
  display: flex;
  align-items: center;
}
.menu-row > .menu-item > .menu-link .menu-arrow:after {
  /*rtl:ignore*/
  transform: rotateZ(90deg);
  transition: transform 0.3s ease;
}
[direction=rtl] .menu-row > .menu-item > .menu-link .menu-arrow:after {
  /*rtl:ignore*/
  transform: rotateZ(-90deg);
}

.menu-rounded .menu-link {
  border-radius: 0.475rem;
}

.menu-pill .menu-link {
  border-radius: 50px;
}

.menu-rounded-0 .menu-link {
  border-radius: 0 !important;
}

@media (min-width: 576px) {
  .menu-item.menu-sm-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-sm-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-sm-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-sm-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-sub-sm-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-sm-dropdown, .menu-sub-sm-dropdown.menu.show, .menu-sub-sm-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-sm-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-sm-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-sm-dropdown[data-popper-placement=top-end], .menu-sub-sm-dropdown.menu.show[data-popper-placement=top], .menu-sub-sm-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-sm-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-sm-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-sm-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-sm-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-sm-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-sm-accordion, .menu-sub-sm-accordion.show {
    display: flex;
  }

  .menu-sm-inline {
    display: flex;
  }

  .menu-sm-fit > .menu-item > .menu-content,
.menu-sm-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-sm-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-sm-row {
    flex-direction: row;
  }
  .menu-sm-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-sm-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-sm-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-sm-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-sm-pill .menu-link {
    border-radius: 50px;
  }

  .menu-sm-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (min-width: 768px) {
  .menu-item.menu-md-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-md-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-md-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-md-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-sub-md-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-md-dropdown, .menu-sub-md-dropdown.menu.show, .menu-sub-md-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-md-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-md-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-md-dropdown[data-popper-placement=top-end], .menu-sub-md-dropdown.menu.show[data-popper-placement=top], .menu-sub-md-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-md-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-md-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-md-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-md-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-md-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-md-accordion, .menu-sub-md-accordion.show {
    display: flex;
  }

  .menu-md-inline {
    display: flex;
  }

  .menu-md-fit > .menu-item > .menu-content,
.menu-md-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-md-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-md-row {
    flex-direction: row;
  }
  .menu-md-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-md-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-md-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-md-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-md-pill .menu-link {
    border-radius: 50px;
  }

  .menu-md-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (min-width: 992px) {
  .menu-item.menu-lg-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-lg-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-lg-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-lg-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-sub-lg-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-lg-dropdown, .menu-sub-lg-dropdown.menu.show, .menu-sub-lg-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-lg-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-lg-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-lg-dropdown[data-popper-placement=top-end], .menu-sub-lg-dropdown.menu.show[data-popper-placement=top], .menu-sub-lg-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-lg-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-lg-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-lg-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-lg-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-lg-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-lg-accordion, .menu-sub-lg-accordion.show {
    display: flex;
  }

  .menu-lg-inline {
    display: flex;
  }

  .menu-lg-fit > .menu-item > .menu-content,
.menu-lg-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-lg-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-lg-row {
    flex-direction: row;
  }
  .menu-lg-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-lg-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-lg-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-lg-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-lg-pill .menu-link {
    border-radius: 50px;
  }

  .menu-lg-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (min-width: 1200px) {
  .menu-item.menu-xl-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-xl-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-xl-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-xl-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-sub-xl-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-xl-dropdown, .menu-sub-xl-dropdown.menu.show, .menu-sub-xl-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-xl-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-xl-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-xl-dropdown[data-popper-placement=top-end], .menu-sub-xl-dropdown.menu.show[data-popper-placement=top], .menu-sub-xl-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-xl-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-xl-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-xl-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-xl-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-xl-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-xl-accordion, .menu-sub-xl-accordion.show {
    display: flex;
  }

  .menu-xl-inline {
    display: flex;
  }

  .menu-xl-fit > .menu-item > .menu-content,
.menu-xl-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-xl-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-xl-row {
    flex-direction: row;
  }
  .menu-xl-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-xl-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-xl-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-xl-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-xl-pill .menu-link {
    border-radius: 50px;
  }

  .menu-xl-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (min-width: 1400px) {
  .menu-item.menu-xxl-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-xxl-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-xxl-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-xxl-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-sub-xxl-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-xxl-dropdown, .menu-sub-xxl-dropdown.menu.show, .menu-sub-xxl-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-xxl-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-xxl-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-xxl-dropdown[data-popper-placement=top-end], .menu-sub-xxl-dropdown.menu.show[data-popper-placement=top], .menu-sub-xxl-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-xxl-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-xxl-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-xxl-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-xxl-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-xxl-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-xxl-accordion, .menu-sub-xxl-accordion.show {
    display: flex;
  }

  .menu-xxl-inline {
    display: flex;
  }

  .menu-xxl-fit > .menu-item > .menu-content,
.menu-xxl-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-xxl-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-xxl-row {
    flex-direction: row;
  }
  .menu-xxl-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-xxl-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-xxl-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-xxl-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-xxl-pill .menu-link {
    border-radius: 50px;
  }

  .menu-xxl-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .menu-item.menu-sm-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-sm-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-sm-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-sm-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-sub-sm-down-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-sm-down-dropdown, .menu-sub-sm-down-dropdown.menu.show, .menu-sub-sm-down-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-sm-down-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-sm-down-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-sm-down-dropdown[data-popper-placement=top-end], .menu-sub-sm-down-dropdown.menu.show[data-popper-placement=top], .menu-sub-sm-down-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-sm-down-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-sm-down-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-sm-down-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-sm-down-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-sm-down-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-sm-down-accordion, .menu-sub-sm-down-accordion.show {
    display: flex;
  }

  .menu-sm-down-inline {
    display: flex;
  }

  .menu-sm-down-fit > .menu-item > .menu-content,
.menu-sm-down-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-sm-down-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-sm-down-row {
    flex-direction: row;
  }
  .menu-sm-down-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-sm-down-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-sm-down-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-sm-down-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-sm-down-pill .menu-link {
    border-radius: 50px;
  }

  .menu-sm-down-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (max-width: 767.98px) {
  .menu-item.menu-md-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-md-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-md-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-md-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-sub-md-down-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-md-down-dropdown, .menu-sub-md-down-dropdown.menu.show, .menu-sub-md-down-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-md-down-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-md-down-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-md-down-dropdown[data-popper-placement=top-end], .menu-sub-md-down-dropdown.menu.show[data-popper-placement=top], .menu-sub-md-down-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-md-down-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-md-down-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-md-down-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-md-down-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-md-down-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-md-down-accordion, .menu-sub-md-down-accordion.show {
    display: flex;
  }

  .menu-md-down-inline {
    display: flex;
  }

  .menu-md-down-fit > .menu-item > .menu-content,
.menu-md-down-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-md-down-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-md-down-row {
    flex-direction: row;
  }
  .menu-md-down-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-md-down-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-md-down-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-md-down-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-md-down-pill .menu-link {
    border-radius: 50px;
  }

  .menu-md-down-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (max-width: 991.98px) {
  .menu-item.menu-lg-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-lg-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-lg-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-lg-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-sub-lg-down-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-lg-down-dropdown, .menu-sub-lg-down-dropdown.menu.show, .menu-sub-lg-down-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-lg-down-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-lg-down-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-lg-down-dropdown[data-popper-placement=top-end], .menu-sub-lg-down-dropdown.menu.show[data-popper-placement=top], .menu-sub-lg-down-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-lg-down-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-lg-down-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-lg-down-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-lg-down-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-lg-down-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-lg-down-accordion, .menu-sub-lg-down-accordion.show {
    display: flex;
  }

  .menu-lg-down-inline {
    display: flex;
  }

  .menu-lg-down-fit > .menu-item > .menu-content,
.menu-lg-down-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-lg-down-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-lg-down-row {
    flex-direction: row;
  }
  .menu-lg-down-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-lg-down-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-lg-down-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-lg-down-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-lg-down-pill .menu-link {
    border-radius: 50px;
  }

  .menu-lg-down-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (max-width: 1199.98px) {
  .menu-item.menu-xl-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-xl-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-xl-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-xl-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-sub-xl-down-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-xl-down-dropdown, .menu-sub-xl-down-dropdown.menu.show, .menu-sub-xl-down-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-xl-down-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-xl-down-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-xl-down-dropdown[data-popper-placement=top-end], .menu-sub-xl-down-dropdown.menu.show[data-popper-placement=top], .menu-sub-xl-down-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-xl-down-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-xl-down-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-xl-down-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-xl-down-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-xl-down-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-xl-down-accordion, .menu-sub-xl-down-accordion.show {
    display: flex;
  }

  .menu-xl-down-inline {
    display: flex;
  }

  .menu-xl-down-fit > .menu-item > .menu-content,
.menu-xl-down-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-xl-down-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-xl-down-row {
    flex-direction: row;
  }
  .menu-xl-down-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-xl-down-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-xl-down-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-xl-down-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-xl-down-pill .menu-link {
    border-radius: 50px;
  }

  .menu-xl-down-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
@media (max-width: 1399.98px) {
  .menu-item.menu-xxl-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, .menu-item.menu-xxl-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-item.menu-xxl-down-accordion.showing:not(.menu-dropdown) > .menu-link .menu-arrow:after, [direction=rtl] .menu-item.menu-xxl-down-accordion.show:not(.hiding):not(.menu-dropdown) > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-sub-xxl-down-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    z-index: 105;
  }
  .show.menu-dropdown > .menu-sub-xxl-down-dropdown, .menu-sub-xxl-down-dropdown.menu.show, .menu-sub-xxl-down-dropdown.show[data-popper-placement] {
    display: flex;
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
  }
  .show.menu-dropdown > .menu-sub-xxl-down-dropdown[data-popper-placement=top], .show.menu-dropdown > .menu-sub-xxl-down-dropdown[data-popper-placement=top-start], .show.menu-dropdown > .menu-sub-xxl-down-dropdown[data-popper-placement=top-end], .menu-sub-xxl-down-dropdown.menu.show[data-popper-placement=top], .menu-sub-xxl-down-dropdown.menu.show[data-popper-placement=top-start], .menu-sub-xxl-down-dropdown.menu.show[data-popper-placement=top-end], .menu-sub-xxl-down-dropdown.show[data-popper-placement][data-popper-placement=top], .menu-sub-xxl-down-dropdown.show[data-popper-placement][data-popper-placement=top-start], .menu-sub-xxl-down-dropdown.show[data-popper-placement][data-popper-placement=top-end] {
    animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-down 0.3s ease 1;
  }

  .menu-sub-xxl-down-accordion {
    display: none;
  }
  .show:not(.menu-dropdown) > .menu-sub-xxl-down-accordion, .menu-sub-xxl-down-accordion.show {
    display: flex;
  }

  .menu-xxl-down-inline {
    display: flex;
  }

  .menu-xxl-down-fit > .menu-item > .menu-content,
.menu-xxl-down-fit > .menu-item > .menu-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .menu-xxl-down-column {
    flex-direction: column;
    width: 100%;
  }

  .menu-xxl-down-row {
    flex-direction: row;
  }
  .menu-xxl-down-row > .menu-item {
    display: flex;
    align-items: center;
  }
  .menu-xxl-down-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(90deg);
    transition: transform 0.3s ease;
  }
  [direction=rtl] .menu-xxl-down-row > .menu-item > .menu-link .menu-arrow:after {
    /*rtl:ignore*/
    transform: rotateZ(-90deg);
  }

  .menu-xxl-down-rounded .menu-link {
    border-radius: 0.475rem;
  }

  .menu-xxl-down-pill .menu-link {
    border-radius: 50px;
  }

  .menu-xxl-down-rounded-0 .menu-link {
    border-radius: 0 !important;
  }
}
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(1rem + 1.5rem);
}
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(2rem + 1.5rem);
}
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(3rem + 1.5rem);
}
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(4rem + 1.5rem);
}
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(5rem + 1.5rem);
}
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(6rem + 1.5rem);
}
.menu-fit .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-fit .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: 1rem;
  padding-right: 0;
}
.menu-fit .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-fit .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(2rem);
  padding-right: 0;
}
.menu-fit .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-fit .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(3rem);
  padding-right: 0;
}
.menu-fit .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-fit .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(4rem);
  padding-right: 0;
}

@keyframes menu-sub-dropdown-animation-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes menu-sub-dropdown-animation-move-up {
  from {
    margin-top: 0.75rem;
  }
  to {
    margin-top: 0;
  }
}
@keyframes menu-sub-dropdown-animation-move-down {
  from {
    margin-bottom: 0.75rem;
  }
  to {
    margin-bottom: 0;
  }
}
.menu-white .menu-item .menu-link {
  color: #ffffff;
}
.menu-white .menu-item .menu-link .menu-title {
  color: #ffffff;
}
.menu-white .menu-item .menu-link .menu-icon i {
  color: #ffffff;
}
.menu-white .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.menu-white .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-white .menu-item .menu-link .menu-bullet .bullet {
  background-color: #ffffff;
}
.menu-white .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-white .menu-item .menu-link {
  color: #ffffff;
}
.menu-title-white .menu-item .menu-link .menu-title {
  color: #ffffff;
}

.menu-icon-white .menu-item .menu-link .menu-icon i {
  color: #ffffff;
}
.menu-icon-white .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.menu-icon-white .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-white .menu-item .menu-link .menu-bullet .bullet {
  background-color: #ffffff;
}

.menu-arrow-white .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-primary .menu-item .menu-link {
  color: #e10e8d;
}
.menu-primary .menu-item .menu-link .menu-title {
  color: #e10e8d;
}
.menu-primary .menu-item .menu-link .menu-icon i {
  color: #e10e8d;
}
.menu-primary .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-primary .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-primary .menu-item .menu-link .menu-bullet .bullet {
  background-color: #e10e8d;
}
.menu-primary .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-primary .menu-item .menu-link {
  color: #e10e8d;
}
.menu-title-primary .menu-item .menu-link .menu-title {
  color: #e10e8d;
}

.menu-icon-primary .menu-item .menu-link .menu-icon i {
  color: #e10e8d;
}
.menu-icon-primary .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-icon-primary .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-primary .menu-item .menu-link .menu-bullet .bullet {
  background-color: #e10e8d;
}

.menu-arrow-primary .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-secondary .menu-item .menu-link {
  color: #e4e6ef;
}
.menu-secondary .menu-item .menu-link .menu-title {
  color: #e4e6ef;
}
.menu-secondary .menu-item .menu-link .menu-icon i {
  color: #e4e6ef;
}
.menu-secondary .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.menu-secondary .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-secondary .menu-item .menu-link .menu-bullet .bullet {
  background-color: #e4e6ef;
}
.menu-secondary .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e4e6ef'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e4e6ef'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-secondary .menu-item .menu-link {
  color: #e4e6ef;
}
.menu-title-secondary .menu-item .menu-link .menu-title {
  color: #e4e6ef;
}

.menu-icon-secondary .menu-item .menu-link .menu-icon i {
  color: #e4e6ef;
}
.menu-icon-secondary .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.menu-icon-secondary .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-secondary .menu-item .menu-link .menu-bullet .bullet {
  background-color: #e4e6ef;
}

.menu-arrow-secondary .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e4e6ef'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e4e6ef'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-light .menu-item .menu-link {
  color: #eff2f5;
}
.menu-light .menu-item .menu-link .menu-title {
  color: #eff2f5;
}
.menu-light .menu-item .menu-link .menu-icon i {
  color: #eff2f5;
}
.menu-light .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.menu-light .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-light .menu-item .menu-link .menu-bullet .bullet {
  background-color: #eff2f5;
}
.menu-light .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23eff2f5'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23eff2f5'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-light .menu-item .menu-link {
  color: #eff2f5;
}
.menu-title-light .menu-item .menu-link .menu-title {
  color: #eff2f5;
}

.menu-icon-light .menu-item .menu-link .menu-icon i {
  color: #eff2f5;
}
.menu-icon-light .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.menu-icon-light .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-light .menu-item .menu-link .menu-bullet .bullet {
  background-color: #eff2f5;
}

.menu-arrow-light .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23eff2f5'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23eff2f5'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-success .menu-item .menu-link {
  color: #1BC5BC;
}
.menu-success .menu-item .menu-link .menu-title {
  color: #1BC5BC;
}
.menu-success .menu-item .menu-link .menu-icon i {
  color: #1BC5BC;
}
.menu-success .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.menu-success .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-success .menu-item .menu-link .menu-bullet .bullet {
  background-color: #1BC5BC;
}
.menu-success .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%231BC5BC'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%231BC5BC'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-success .menu-item .menu-link {
  color: #1BC5BC;
}
.menu-title-success .menu-item .menu-link .menu-title {
  color: #1BC5BC;
}

.menu-icon-success .menu-item .menu-link .menu-icon i {
  color: #1BC5BC;
}
.menu-icon-success .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.menu-icon-success .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-success .menu-item .menu-link .menu-bullet .bullet {
  background-color: #1BC5BC;
}

.menu-arrow-success .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%231BC5BC'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%231BC5BC'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-info .menu-item .menu-link {
  color: #5F5CF1;
}
.menu-info .menu-item .menu-link .menu-title {
  color: #5F5CF1;
}
.menu-info .menu-item .menu-link .menu-icon i {
  color: #5F5CF1;
}
.menu-info .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.menu-info .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-info .menu-item .menu-link .menu-bullet .bullet {
  background-color: #5F5CF1;
}
.menu-info .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235F5CF1'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235F5CF1'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-info .menu-item .menu-link {
  color: #5F5CF1;
}
.menu-title-info .menu-item .menu-link .menu-title {
  color: #5F5CF1;
}

.menu-icon-info .menu-item .menu-link .menu-icon i {
  color: #5F5CF1;
}
.menu-icon-info .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.menu-icon-info .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-info .menu-item .menu-link .menu-bullet .bullet {
  background-color: #5F5CF1;
}

.menu-arrow-info .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235F5CF1'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235F5CF1'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-warning .menu-item .menu-link {
  color: #FFA800;
}
.menu-warning .menu-item .menu-link .menu-title {
  color: #FFA800;
}
.menu-warning .menu-item .menu-link .menu-icon i {
  color: #FFA800;
}
.menu-warning .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.menu-warning .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-warning .menu-item .menu-link .menu-bullet .bullet {
  background-color: #FFA800;
}
.menu-warning .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFA800'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFA800'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-warning .menu-item .menu-link {
  color: #FFA800;
}
.menu-title-warning .menu-item .menu-link .menu-title {
  color: #FFA800;
}

.menu-icon-warning .menu-item .menu-link .menu-icon i {
  color: #FFA800;
}
.menu-icon-warning .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.menu-icon-warning .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-warning .menu-item .menu-link .menu-bullet .bullet {
  background-color: #FFA800;
}

.menu-arrow-warning .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFA800'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFA800'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-danger .menu-item .menu-link {
  color: #F65464;
}
.menu-danger .menu-item .menu-link .menu-title {
  color: #F65464;
}
.menu-danger .menu-item .menu-link .menu-icon i {
  color: #F65464;
}
.menu-danger .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.menu-danger .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-danger .menu-item .menu-link .menu-bullet .bullet {
  background-color: #F65464;
}
.menu-danger .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23F65464'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23F65464'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-danger .menu-item .menu-link {
  color: #F65464;
}
.menu-title-danger .menu-item .menu-link .menu-title {
  color: #F65464;
}

.menu-icon-danger .menu-item .menu-link .menu-icon i {
  color: #F65464;
}
.menu-icon-danger .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.menu-icon-danger .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-danger .menu-item .menu-link .menu-bullet .bullet {
  background-color: #F65464;
}

.menu-arrow-danger .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23F65464'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23F65464'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-dark .menu-item .menu-link {
  color: #3f4254;
}
.menu-dark .menu-item .menu-link .menu-title {
  color: #3f4254;
}
.menu-dark .menu-item .menu-link .menu-icon i {
  color: #3f4254;
}
.menu-dark .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.menu-dark .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-dark .menu-item .menu-link .menu-bullet .bullet {
  background-color: #3f4254;
}
.menu-dark .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%233f4254'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%233f4254'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-dark .menu-item .menu-link {
  color: #3f4254;
}
.menu-title-dark .menu-item .menu-link .menu-title {
  color: #3f4254;
}

.menu-icon-dark .menu-item .menu-link .menu-icon i {
  color: #3f4254;
}
.menu-icon-dark .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.menu-icon-dark .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-dark .menu-item .menu-link .menu-bullet .bullet {
  background-color: #3f4254;
}

.menu-arrow-dark .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%233f4254'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%233f4254'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-black .menu-item .menu-link {
  color: #000000;
}
.menu-black .menu-item .menu-link .menu-title {
  color: #000000;
}
.menu-black .menu-item .menu-link .menu-icon i {
  color: #000000;
}
.menu-black .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #000000;
}
.menu-black .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-black .menu-item .menu-link .menu-bullet .bullet {
  background-color: #000000;
}
.menu-black .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23000000'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23000000'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-black .menu-item .menu-link {
  color: #000000;
}
.menu-title-black .menu-item .menu-link .menu-title {
  color: #000000;
}

.menu-icon-black .menu-item .menu-link .menu-icon i {
  color: #000000;
}
.menu-icon-black .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #000000;
}
.menu-icon-black .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-black .menu-item .menu-link .menu-bullet .bullet {
  background-color: #000000;
}

.menu-arrow-black .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23000000'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23000000'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-muted .menu-item .menu-link {
  color: #a1a5b7;
}
.menu-muted .menu-item .menu-link .menu-title {
  color: #a1a5b7;
}
.menu-muted .menu-item .menu-link .menu-icon i {
  color: #a1a5b7;
}
.menu-muted .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.menu-muted .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-muted .menu-item .menu-link .menu-bullet .bullet {
  background-color: #a1a5b7;
}
.menu-muted .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23a1a5b7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23a1a5b7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-muted .menu-item .menu-link {
  color: #a1a5b7;
}
.menu-title-muted .menu-item .menu-link .menu-title {
  color: #a1a5b7;
}

.menu-icon-muted .menu-item .menu-link .menu-icon i {
  color: #a1a5b7;
}
.menu-icon-muted .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.menu-icon-muted .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-muted .menu-item .menu-link .menu-bullet .bullet {
  background-color: #a1a5b7;
}

.menu-arrow-muted .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23a1a5b7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23a1a5b7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-gray-100 .menu-item .menu-link {
  color: #f5f8fa;
}
.menu-gray-100 .menu-item .menu-link .menu-title {
  color: #f5f8fa;
}
.menu-gray-100 .menu-item .menu-link .menu-icon i {
  color: #f5f8fa;
}
.menu-gray-100 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #f5f8fa;
}
.menu-gray-100 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-100 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #f5f8fa;
}
.menu-gray-100 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23f5f8fa'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23f5f8fa'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-gray-100 .menu-item .menu-link {
  color: #f5f8fa;
}
.menu-title-gray-100 .menu-item .menu-link .menu-title {
  color: #f5f8fa;
}

.menu-icon-gray-100 .menu-item .menu-link .menu-icon i {
  color: #f5f8fa;
}
.menu-icon-gray-100 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #f5f8fa;
}
.menu-icon-gray-100 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-100 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #f5f8fa;
}

.menu-arrow-gray-100 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23f5f8fa'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23f5f8fa'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-gray-200 .menu-item .menu-link {
  color: #eff2f5;
}
.menu-gray-200 .menu-item .menu-link .menu-title {
  color: #eff2f5;
}
.menu-gray-200 .menu-item .menu-link .menu-icon i {
  color: #eff2f5;
}
.menu-gray-200 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.menu-gray-200 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-200 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #eff2f5;
}
.menu-gray-200 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23eff2f5'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23eff2f5'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-gray-200 .menu-item .menu-link {
  color: #eff2f5;
}
.menu-title-gray-200 .menu-item .menu-link .menu-title {
  color: #eff2f5;
}

.menu-icon-gray-200 .menu-item .menu-link .menu-icon i {
  color: #eff2f5;
}
.menu-icon-gray-200 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.menu-icon-gray-200 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-200 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #eff2f5;
}

.menu-arrow-gray-200 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23eff2f5'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23eff2f5'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-gray-300 .menu-item .menu-link {
  color: #e4e6ef;
}
.menu-gray-300 .menu-item .menu-link .menu-title {
  color: #e4e6ef;
}
.menu-gray-300 .menu-item .menu-link .menu-icon i {
  color: #e4e6ef;
}
.menu-gray-300 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.menu-gray-300 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-300 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #e4e6ef;
}
.menu-gray-300 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e4e6ef'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e4e6ef'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-gray-300 .menu-item .menu-link {
  color: #e4e6ef;
}
.menu-title-gray-300 .menu-item .menu-link .menu-title {
  color: #e4e6ef;
}

.menu-icon-gray-300 .menu-item .menu-link .menu-icon i {
  color: #e4e6ef;
}
.menu-icon-gray-300 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.menu-icon-gray-300 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-300 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #e4e6ef;
}

.menu-arrow-gray-300 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e4e6ef'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e4e6ef'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-gray-400 .menu-item .menu-link {
  color: #b5b5c3;
}
.menu-gray-400 .menu-item .menu-link .menu-title {
  color: #b5b5c3;
}
.menu-gray-400 .menu-item .menu-link .menu-icon i {
  color: #b5b5c3;
}
.menu-gray-400 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.menu-gray-400 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-400 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #b5b5c3;
}
.menu-gray-400 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23b5b5c3'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23b5b5c3'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-gray-400 .menu-item .menu-link {
  color: #b5b5c3;
}
.menu-title-gray-400 .menu-item .menu-link .menu-title {
  color: #b5b5c3;
}

.menu-icon-gray-400 .menu-item .menu-link .menu-icon i {
  color: #b5b5c3;
}
.menu-icon-gray-400 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.menu-icon-gray-400 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-400 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #b5b5c3;
}

.menu-arrow-gray-400 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23b5b5c3'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23b5b5c3'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-gray-500 .menu-item .menu-link {
  color: #a1a5b7;
}
.menu-gray-500 .menu-item .menu-link .menu-title {
  color: #a1a5b7;
}
.menu-gray-500 .menu-item .menu-link .menu-icon i {
  color: #a1a5b7;
}
.menu-gray-500 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.menu-gray-500 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-500 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #a1a5b7;
}
.menu-gray-500 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23a1a5b7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23a1a5b7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-gray-500 .menu-item .menu-link {
  color: #a1a5b7;
}
.menu-title-gray-500 .menu-item .menu-link .menu-title {
  color: #a1a5b7;
}

.menu-icon-gray-500 .menu-item .menu-link .menu-icon i {
  color: #a1a5b7;
}
.menu-icon-gray-500 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.menu-icon-gray-500 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-500 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #a1a5b7;
}

.menu-arrow-gray-500 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23a1a5b7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23a1a5b7'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-gray-600 .menu-item .menu-link {
  color: #7e8299;
}
.menu-gray-600 .menu-item .menu-link .menu-title {
  color: #7e8299;
}
.menu-gray-600 .menu-item .menu-link .menu-icon i {
  color: #7e8299;
}
.menu-gray-600 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.menu-gray-600 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-600 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #7e8299;
}
.menu-gray-600 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%237e8299'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%237e8299'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-gray-600 .menu-item .menu-link {
  color: #7e8299;
}
.menu-title-gray-600 .menu-item .menu-link .menu-title {
  color: #7e8299;
}

.menu-icon-gray-600 .menu-item .menu-link .menu-icon i {
  color: #7e8299;
}
.menu-icon-gray-600 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.menu-icon-gray-600 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-600 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #7e8299;
}

.menu-arrow-gray-600 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%237e8299'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%237e8299'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-gray-700 .menu-item .menu-link {
  color: #5e6278;
}
.menu-gray-700 .menu-item .menu-link .menu-title {
  color: #5e6278;
}
.menu-gray-700 .menu-item .menu-link .menu-icon i {
  color: #5e6278;
}
.menu-gray-700 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5e6278;
}
.menu-gray-700 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-700 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #5e6278;
}
.menu-gray-700 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235e6278'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235e6278'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-gray-700 .menu-item .menu-link {
  color: #5e6278;
}
.menu-title-gray-700 .menu-item .menu-link .menu-title {
  color: #5e6278;
}

.menu-icon-gray-700 .menu-item .menu-link .menu-icon i {
  color: #5e6278;
}
.menu-icon-gray-700 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5e6278;
}
.menu-icon-gray-700 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-700 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #5e6278;
}

.menu-arrow-gray-700 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235e6278'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235e6278'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-gray-800 .menu-item .menu-link {
  color: #3f4254;
}
.menu-gray-800 .menu-item .menu-link .menu-title {
  color: #3f4254;
}
.menu-gray-800 .menu-item .menu-link .menu-icon i {
  color: #3f4254;
}
.menu-gray-800 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.menu-gray-800 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-800 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #3f4254;
}
.menu-gray-800 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%233f4254'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%233f4254'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-gray-800 .menu-item .menu-link {
  color: #3f4254;
}
.menu-title-gray-800 .menu-item .menu-link .menu-title {
  color: #3f4254;
}

.menu-icon-gray-800 .menu-item .menu-link .menu-icon i {
  color: #3f4254;
}
.menu-icon-gray-800 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.menu-icon-gray-800 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-800 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #3f4254;
}

.menu-arrow-gray-800 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%233f4254'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%233f4254'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-gray-900 .menu-item .menu-link {
  color: #181c32;
}
.menu-gray-900 .menu-item .menu-link .menu-title {
  color: #181c32;
}
.menu-gray-900 .menu-item .menu-link .menu-icon i {
  color: #181c32;
}
.menu-gray-900 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181c32;
}
.menu-gray-900 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray-900 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #181c32;
}
.menu-gray-900 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23181c32'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23181c32'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-gray-900 .menu-item .menu-link {
  color: #181c32;
}
.menu-title-gray-900 .menu-item .menu-link .menu-title {
  color: #181c32;
}

.menu-icon-gray-900 .menu-item .menu-link .menu-icon i {
  color: #181c32;
}
.menu-icon-gray-900 .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181c32;
}
.menu-icon-gray-900 .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray-900 .menu-item .menu-link .menu-bullet .bullet {
  background-color: #181c32;
}

.menu-arrow-gray-900 .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23181c32'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23181c32'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-blue .menu-item .menu-link {
  color: #3699FF;
}
.menu-blue .menu-item .menu-link .menu-title {
  color: #3699FF;
}
.menu-blue .menu-item .menu-link .menu-icon i {
  color: #3699FF;
}
.menu-blue .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3699FF;
}
.menu-blue .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-blue .menu-item .menu-link .menu-bullet .bullet {
  background-color: #3699FF;
}
.menu-blue .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%233699FF'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%233699FF'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-blue .menu-item .menu-link {
  color: #3699FF;
}
.menu-title-blue .menu-item .menu-link .menu-title {
  color: #3699FF;
}

.menu-icon-blue .menu-item .menu-link .menu-icon i {
  color: #3699FF;
}
.menu-icon-blue .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3699FF;
}
.menu-icon-blue .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-blue .menu-item .menu-link .menu-bullet .bullet {
  background-color: #3699FF;
}

.menu-arrow-blue .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%233699FF'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%233699FF'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-indigo .menu-item .menu-link {
  color: #6610f2;
}
.menu-indigo .menu-item .menu-link .menu-title {
  color: #6610f2;
}
.menu-indigo .menu-item .menu-link .menu-icon i {
  color: #6610f2;
}
.menu-indigo .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #6610f2;
}
.menu-indigo .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-indigo .menu-item .menu-link .menu-bullet .bullet {
  background-color: #6610f2;
}
.menu-indigo .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%236610f2'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%236610f2'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-indigo .menu-item .menu-link {
  color: #6610f2;
}
.menu-title-indigo .menu-item .menu-link .menu-title {
  color: #6610f2;
}

.menu-icon-indigo .menu-item .menu-link .menu-icon i {
  color: #6610f2;
}
.menu-icon-indigo .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #6610f2;
}
.menu-icon-indigo .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-indigo .menu-item .menu-link .menu-bullet .bullet {
  background-color: #6610f2;
}

.menu-arrow-indigo .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%236610f2'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%236610f2'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-purple .menu-item .menu-link {
  color: #5F5CF1;
}
.menu-purple .menu-item .menu-link .menu-title {
  color: #5F5CF1;
}
.menu-purple .menu-item .menu-link .menu-icon i {
  color: #5F5CF1;
}
.menu-purple .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.menu-purple .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-purple .menu-item .menu-link .menu-bullet .bullet {
  background-color: #5F5CF1;
}
.menu-purple .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235F5CF1'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235F5CF1'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-purple .menu-item .menu-link {
  color: #5F5CF1;
}
.menu-title-purple .menu-item .menu-link .menu-title {
  color: #5F5CF1;
}

.menu-icon-purple .menu-item .menu-link .menu-icon i {
  color: #5F5CF1;
}
.menu-icon-purple .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.menu-icon-purple .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-purple .menu-item .menu-link .menu-bullet .bullet {
  background-color: #5F5CF1;
}

.menu-arrow-purple .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235F5CF1'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235F5CF1'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-pink .menu-item .menu-link {
  color: #d63384;
}
.menu-pink .menu-item .menu-link .menu-title {
  color: #d63384;
}
.menu-pink .menu-item .menu-link .menu-icon i {
  color: #d63384;
}
.menu-pink .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #d63384;
}
.menu-pink .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-pink .menu-item .menu-link .menu-bullet .bullet {
  background-color: #d63384;
}
.menu-pink .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23d63384'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23d63384'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-pink .menu-item .menu-link {
  color: #d63384;
}
.menu-title-pink .menu-item .menu-link .menu-title {
  color: #d63384;
}

.menu-icon-pink .menu-item .menu-link .menu-icon i {
  color: #d63384;
}
.menu-icon-pink .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #d63384;
}
.menu-icon-pink .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-pink .menu-item .menu-link .menu-bullet .bullet {
  background-color: #d63384;
}

.menu-arrow-pink .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23d63384'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23d63384'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-red .menu-item .menu-link {
  color: #F65464;
}
.menu-red .menu-item .menu-link .menu-title {
  color: #F65464;
}
.menu-red .menu-item .menu-link .menu-icon i {
  color: #F65464;
}
.menu-red .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.menu-red .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-red .menu-item .menu-link .menu-bullet .bullet {
  background-color: #F65464;
}
.menu-red .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23F65464'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23F65464'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-red .menu-item .menu-link {
  color: #F65464;
}
.menu-title-red .menu-item .menu-link .menu-title {
  color: #F65464;
}

.menu-icon-red .menu-item .menu-link .menu-icon i {
  color: #F65464;
}
.menu-icon-red .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.menu-icon-red .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-red .menu-item .menu-link .menu-bullet .bullet {
  background-color: #F65464;
}

.menu-arrow-red .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23F65464'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23F65464'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-orange .menu-item .menu-link {
  color: #fd7e14;
}
.menu-orange .menu-item .menu-link .menu-title {
  color: #fd7e14;
}
.menu-orange .menu-item .menu-link .menu-icon i {
  color: #fd7e14;
}
.menu-orange .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #fd7e14;
}
.menu-orange .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-orange .menu-item .menu-link .menu-bullet .bullet {
  background-color: #fd7e14;
}
.menu-orange .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23fd7e14'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23fd7e14'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-orange .menu-item .menu-link {
  color: #fd7e14;
}
.menu-title-orange .menu-item .menu-link .menu-title {
  color: #fd7e14;
}

.menu-icon-orange .menu-item .menu-link .menu-icon i {
  color: #fd7e14;
}
.menu-icon-orange .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #fd7e14;
}
.menu-icon-orange .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-orange .menu-item .menu-link .menu-bullet .bullet {
  background-color: #fd7e14;
}

.menu-arrow-orange .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23fd7e14'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23fd7e14'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-yellow .menu-item .menu-link {
  color: #FFA800;
}
.menu-yellow .menu-item .menu-link .menu-title {
  color: #FFA800;
}
.menu-yellow .menu-item .menu-link .menu-icon i {
  color: #FFA800;
}
.menu-yellow .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.menu-yellow .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-yellow .menu-item .menu-link .menu-bullet .bullet {
  background-color: #FFA800;
}
.menu-yellow .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFA800'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFA800'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-yellow .menu-item .menu-link {
  color: #FFA800;
}
.menu-title-yellow .menu-item .menu-link .menu-title {
  color: #FFA800;
}

.menu-icon-yellow .menu-item .menu-link .menu-icon i {
  color: #FFA800;
}
.menu-icon-yellow .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.menu-icon-yellow .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-yellow .menu-item .menu-link .menu-bullet .bullet {
  background-color: #FFA800;
}

.menu-arrow-yellow .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFA800'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23FFA800'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-green .menu-item .menu-link {
  color: #1BC5BC;
}
.menu-green .menu-item .menu-link .menu-title {
  color: #1BC5BC;
}
.menu-green .menu-item .menu-link .menu-icon i {
  color: #1BC5BC;
}
.menu-green .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.menu-green .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-green .menu-item .menu-link .menu-bullet .bullet {
  background-color: #1BC5BC;
}
.menu-green .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%231BC5BC'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%231BC5BC'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-green .menu-item .menu-link {
  color: #1BC5BC;
}
.menu-title-green .menu-item .menu-link .menu-title {
  color: #1BC5BC;
}

.menu-icon-green .menu-item .menu-link .menu-icon i {
  color: #1BC5BC;
}
.menu-icon-green .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.menu-icon-green .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-green .menu-item .menu-link .menu-bullet .bullet {
  background-color: #1BC5BC;
}

.menu-arrow-green .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%231BC5BC'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%231BC5BC'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-teal .menu-item .menu-link {
  color: #20c997;
}
.menu-teal .menu-item .menu-link .menu-title {
  color: #20c997;
}
.menu-teal .menu-item .menu-link .menu-icon i {
  color: #20c997;
}
.menu-teal .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #20c997;
}
.menu-teal .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-teal .menu-item .menu-link .menu-bullet .bullet {
  background-color: #20c997;
}
.menu-teal .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%2320c997'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%2320c997'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-teal .menu-item .menu-link {
  color: #20c997;
}
.menu-title-teal .menu-item .menu-link .menu-title {
  color: #20c997;
}

.menu-icon-teal .menu-item .menu-link .menu-icon i {
  color: #20c997;
}
.menu-icon-teal .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #20c997;
}
.menu-icon-teal .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-teal .menu-item .menu-link .menu-bullet .bullet {
  background-color: #20c997;
}

.menu-arrow-teal .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%2320c997'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%2320c997'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-cyan .menu-item .menu-link {
  color: #0dcaf0;
}
.menu-cyan .menu-item .menu-link .menu-title {
  color: #0dcaf0;
}
.menu-cyan .menu-item .menu-link .menu-icon i {
  color: #0dcaf0;
}
.menu-cyan .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #0dcaf0;
}
.menu-cyan .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-cyan .menu-item .menu-link .menu-bullet .bullet {
  background-color: #0dcaf0;
}
.menu-cyan .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%230dcaf0'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%230dcaf0'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-cyan .menu-item .menu-link {
  color: #0dcaf0;
}
.menu-title-cyan .menu-item .menu-link .menu-title {
  color: #0dcaf0;
}

.menu-icon-cyan .menu-item .menu-link .menu-icon i {
  color: #0dcaf0;
}
.menu-icon-cyan .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #0dcaf0;
}
.menu-icon-cyan .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-cyan .menu-item .menu-link .menu-bullet .bullet {
  background-color: #0dcaf0;
}

.menu-arrow-cyan .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%230dcaf0'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%230dcaf0'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-gray .menu-item .menu-link {
  color: #7e8299;
}
.menu-gray .menu-item .menu-link .menu-title {
  color: #7e8299;
}
.menu-gray .menu-item .menu-link .menu-icon i {
  color: #7e8299;
}
.menu-gray .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.menu-gray .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-gray .menu-item .menu-link .menu-bullet .bullet {
  background-color: #7e8299;
}
.menu-gray .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%237e8299'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%237e8299'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-title-gray .menu-item .menu-link {
  color: #7e8299;
}
.menu-title-gray .menu-item .menu-link .menu-title {
  color: #7e8299;
}

.menu-icon-gray .menu-item .menu-link .menu-icon i {
  color: #7e8299;
}
.menu-icon-gray .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.menu-icon-gray .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-bullet-gray .menu-item .menu-link .menu-bullet .bullet {
  background-color: #7e8299;
}

.menu-arrow-gray .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%237e8299'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%237e8299'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-hover-bg .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-hover-bg .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(245, 248, 250, 0.8);
}

.menu-show-bg .menu-item.here > .menu-link, .menu-show-bg .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(245, 248, 250, 0.8);
}

.menu-active-bg .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(245, 248, 250, 0.8);
}

.menu-state-bg .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-state-bg .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(245, 248, 250, 0.8);
}
.menu-state-bg .menu-item.here > .menu-link, .menu-state-bg .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(245, 248, 250, 0.8);
}
.menu-state-bg .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(245, 248, 250, 0.8);
}

.menu-hover-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-hover-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e10e8d;
}
.menu-hover-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.menu-hover-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #e10e8d;
}
.menu-hover-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.menu-hover-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #e10e8d;
}
.menu-hover-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.menu-hover-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-hover-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.menu-hover-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-hover-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.menu-hover-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #e10e8d;
}
.menu-hover-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.menu-hover-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-show-primary .menu-item.here > .menu-link, .menu-show-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e10e8d;
}
.menu-show-primary .menu-item.here > .menu-link .menu-title, .menu-show-primary .menu-item.show > .menu-link .menu-title {
  color: #e10e8d;
}
.menu-show-primary .menu-item.here > .menu-link .menu-icon i, .menu-show-primary .menu-item.show > .menu-link .menu-icon i {
  color: #e10e8d;
}
.menu-show-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .menu-show-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-show-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .menu-show-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-show-primary .menu-item.here > .menu-link .menu-bullet .bullet, .menu-show-primary .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #e10e8d;
}
.menu-show-primary .menu-item.here > .menu-link .menu-arrow:after, .menu-show-primary .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-active-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e10e8d;
}
.menu-active-primary .menu-item .menu-link.active .menu-title {
  color: #e10e8d;
}
.menu-active-primary .menu-item .menu-link.active .menu-icon i {
  color: #e10e8d;
}
.menu-active-primary .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-active-primary .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-active-primary .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #e10e8d;
}
.menu-active-primary .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-state-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e10e8d;
}
.menu-state-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #e10e8d;
}
.menu-state-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #e10e8d;
}
.menu-state-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-state-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #e10e8d;
}
.menu-state-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.menu-state-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}
.menu-state-primary .menu-item.here > .menu-link, .menu-state-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e10e8d;
}
.menu-state-primary .menu-item.here > .menu-link .menu-title, .menu-state-primary .menu-item.show > .menu-link .menu-title {
  color: #e10e8d;
}
.menu-state-primary .menu-item.here > .menu-link .menu-icon i, .menu-state-primary .menu-item.show > .menu-link .menu-icon i {
  color: #e10e8d;
}
.menu-state-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .menu-state-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-state-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .menu-state-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-primary .menu-item.here > .menu-link .menu-bullet .bullet, .menu-state-primary .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #e10e8d;
}
.menu-state-primary .menu-item.here > .menu-link .menu-arrow:after, .menu-state-primary .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}
.menu-state-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e10e8d;
}
.menu-state-primary .menu-item .menu-link.active .menu-title {
  color: #e10e8d;
}
.menu-state-primary .menu-item .menu-link.active .menu-icon i {
  color: #e10e8d;
}
.menu-state-primary .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-state-primary .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-primary .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #e10e8d;
}
.menu-state-primary .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-hover-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-hover-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #e10e8d;
  color: #ffffff;
}
.menu-hover-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.menu-hover-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #ffffff;
}
.menu-hover-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.menu-hover-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #ffffff;
}
.menu-hover-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.menu-hover-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.menu-hover-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.menu-hover-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-hover-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.menu-hover-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #ffffff;
}
.menu-hover-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.menu-hover-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-show-bg-primary .menu-item.here > .menu-link, .menu-show-bg-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #e10e8d;
  color: #ffffff;
}
.menu-show-bg-primary .menu-item.here > .menu-link .menu-title, .menu-show-bg-primary .menu-item.show > .menu-link .menu-title {
  color: #ffffff;
}
.menu-show-bg-primary .menu-item.here > .menu-link .menu-icon i, .menu-show-bg-primary .menu-item.show > .menu-link .menu-icon i {
  color: #ffffff;
}
.menu-show-bg-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .menu-show-bg-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.menu-show-bg-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .menu-show-bg-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-show-bg-primary .menu-item.here > .menu-link .menu-bullet .bullet, .menu-show-bg-primary .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #ffffff;
}
.menu-show-bg-primary .menu-item.here > .menu-link .menu-arrow:after, .menu-show-bg-primary .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-active-bg-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #e10e8d;
  color: #ffffff;
}
.menu-active-bg-primary .menu-item .menu-link.active .menu-title {
  color: #ffffff;
}
.menu-active-bg-primary .menu-item .menu-link.active .menu-icon i {
  color: #ffffff;
}
.menu-active-bg-primary .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.menu-active-bg-primary .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-active-bg-primary .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #ffffff;
}
.menu-active-bg-primary .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-state-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-state-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #e10e8d;
  color: #ffffff;
}
.menu-state-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.menu-state-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #ffffff;
}
.menu-state-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.menu-state-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #ffffff;
}
.menu-state-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.menu-state-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.menu-state-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.menu-state-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.menu-state-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #ffffff;
}
.menu-state-bg-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.menu-state-bg-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}
.menu-state-bg-primary .menu-item.here > .menu-link, .menu-state-bg-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #e10e8d;
  color: #ffffff;
}
.menu-state-bg-primary .menu-item.here > .menu-link .menu-title, .menu-state-bg-primary .menu-item.show > .menu-link .menu-title {
  color: #ffffff;
}
.menu-state-bg-primary .menu-item.here > .menu-link .menu-icon i, .menu-state-bg-primary .menu-item.show > .menu-link .menu-icon i {
  color: #ffffff;
}
.menu-state-bg-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .menu-state-bg-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.menu-state-bg-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .menu-state-bg-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-bg-primary .menu-item.here > .menu-link .menu-bullet .bullet, .menu-state-bg-primary .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #ffffff;
}
.menu-state-bg-primary .menu-item.here > .menu-link .menu-arrow:after, .menu-state-bg-primary .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}
.menu-state-bg-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #e10e8d;
  color: #ffffff;
}
.menu-state-bg-primary .menu-item .menu-link.active .menu-title {
  color: #ffffff;
}
.menu-state-bg-primary .menu-item .menu-link.active .menu-icon i {
  color: #ffffff;
}
.menu-state-bg-primary .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.menu-state-bg-primary .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-bg-primary .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #ffffff;
}
.menu-state-bg-primary .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-show-bg-light-primary .menu-item.here > .menu-link, .menu-show-bg-light-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #fff1fe;
  color: #e10e8d;
}
.menu-show-bg-light-primary .menu-item.here > .menu-link .menu-title, .menu-show-bg-light-primary .menu-item.show > .menu-link .menu-title {
  color: #e10e8d;
}
.menu-show-bg-light-primary .menu-item.here > .menu-link .menu-icon i, .menu-show-bg-light-primary .menu-item.show > .menu-link .menu-icon i {
  color: #e10e8d;
}
.menu-show-bg-light-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .menu-show-bg-light-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-show-bg-light-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .menu-show-bg-light-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-show-bg-light-primary .menu-item.here > .menu-link .menu-bullet .bullet, .menu-show-bg-light-primary .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #e10e8d;
}
.menu-show-bg-light-primary .menu-item.here > .menu-link .menu-arrow:after, .menu-show-bg-light-primary .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-hover-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-hover-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #fff1fe;
  color: #e10e8d;
}
.menu-hover-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.menu-hover-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #e10e8d;
}
.menu-hover-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.menu-hover-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #e10e8d;
}
.menu-hover-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.menu-hover-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-hover-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.menu-hover-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-hover-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.menu-hover-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #e10e8d;
}
.menu-hover-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.menu-hover-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-active-bg-light-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #fff1fe;
  color: #e10e8d;
}
.menu-active-bg-light-primary .menu-item .menu-link.active .menu-title {
  color: #e10e8d;
}
.menu-active-bg-light-primary .menu-item .menu-link.active .menu-icon i {
  color: #e10e8d;
}
.menu-active-bg-light-primary .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-active-bg-light-primary .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-active-bg-light-primary .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #e10e8d;
}
.menu-active-bg-light-primary .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-state-bg-light-primary .menu-item.here > .menu-link, .menu-state-bg-light-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #fff1fe;
  color: #e10e8d;
}
.menu-state-bg-light-primary .menu-item.here > .menu-link .menu-title, .menu-state-bg-light-primary .menu-item.show > .menu-link .menu-title {
  color: #e10e8d;
}
.menu-state-bg-light-primary .menu-item.here > .menu-link .menu-icon i, .menu-state-bg-light-primary .menu-item.show > .menu-link .menu-icon i {
  color: #e10e8d;
}
.menu-state-bg-light-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .menu-state-bg-light-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-state-bg-light-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .menu-state-bg-light-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-bg-light-primary .menu-item.here > .menu-link .menu-bullet .bullet, .menu-state-bg-light-primary .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #e10e8d;
}
.menu-state-bg-light-primary .menu-item.here > .menu-link .menu-arrow:after, .menu-state-bg-light-primary .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}
.menu-state-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #fff1fe;
  color: #e10e8d;
}
.menu-state-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #e10e8d;
}
.menu-state-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #e10e8d;
}
.menu-state-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-state-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #e10e8d;
}
.menu-state-bg-light-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.menu-state-bg-light-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}
.menu-state-bg-light-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #fff1fe;
  color: #e10e8d;
}
.menu-state-bg-light-primary .menu-item .menu-link.active .menu-title {
  color: #e10e8d;
}
.menu-state-bg-light-primary .menu-item .menu-link.active .menu-icon i {
  color: #e10e8d;
}
.menu-state-bg-light-primary .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-state-bg-light-primary .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-bg-light-primary .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #e10e8d;
}
.menu-state-bg-light-primary .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-hover-title-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-hover-title-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e10e8d;
}
.menu-hover-title-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.menu-hover-title-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #e10e8d;
}

.menu-show-title-primary .menu-item.here > .menu-link, .menu-show-title-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e10e8d;
}
.menu-show-title-primary .menu-item.here > .menu-link .menu-title, .menu-show-title-primary .menu-item.show > .menu-link .menu-title {
  color: #e10e8d;
}

.menu-active-title-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e10e8d;
}
.menu-active-title-primary .menu-item .menu-link.active .menu-title {
  color: #e10e8d;
}

.menu-state-title-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-state-title-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e10e8d;
}
.menu-state-title-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.menu-state-title-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #e10e8d;
}
.menu-state-title-primary .menu-item.here > .menu-link, .menu-state-title-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e10e8d;
}
.menu-state-title-primary .menu-item.here > .menu-link .menu-title, .menu-state-title-primary .menu-item.show > .menu-link .menu-title {
  color: #e10e8d;
}
.menu-state-title-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e10e8d;
}
.menu-state-title-primary .menu-item .menu-link.active .menu-title {
  color: #e10e8d;
}

.menu-hover-icon-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-hover-icon-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-hover-icon-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.menu-hover-icon-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #e10e8d;
}
.menu-hover-icon-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.menu-hover-icon-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-hover-icon-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.menu-hover-icon-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-show-icon-primary .menu-item.here > .menu-link, .menu-show-icon-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-show-icon-primary .menu-item.here > .menu-link .menu-icon i, .menu-show-icon-primary .menu-item.show > .menu-link .menu-icon i {
  color: #e10e8d;
}
.menu-show-icon-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .menu-show-icon-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-show-icon-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .menu-show-icon-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-active-icon-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-active-icon-primary .menu-item .menu-link.active .menu-icon i {
  color: #e10e8d;
}
.menu-active-icon-primary .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-active-icon-primary .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-state-icon-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-state-icon-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-icon-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.menu-state-icon-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #e10e8d;
}
.menu-state-icon-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.menu-state-icon-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-state-icon-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.menu-state-icon-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-icon-primary .menu-item.here > .menu-link, .menu-state-icon-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-icon-primary .menu-item.here > .menu-link .menu-icon i, .menu-state-icon-primary .menu-item.show > .menu-link .menu-icon i {
  color: #e10e8d;
}
.menu-state-icon-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .menu-state-icon-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-state-icon-primary .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .menu-state-icon-primary .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.menu-state-icon-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-icon-primary .menu-item .menu-link.active .menu-icon i {
  color: #e10e8d;
}
.menu-state-icon-primary .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.menu-state-icon-primary .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.menu-hover-bullet-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-hover-bullet-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-hover-bullet-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.menu-hover-bullet-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #e10e8d;
}

.menu-show-bullet-primary .menu-item.here > .menu-link, .menu-show-bullet-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-show-bullet-primary .menu-item.here > .menu-link .menu-bullet .bullet, .menu-show-bullet-primary .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #e10e8d;
}

.menu-active-bullet-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-active-bullet-primary .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #e10e8d;
}

.menu-state-bullet-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-state-bullet-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-bullet-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.menu-state-bullet-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #e10e8d;
}
.menu-state-bullet-primary .menu-item.here > .menu-link, .menu-state-bullet-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-bullet-primary .menu-item.here > .menu-link .menu-bullet .bullet, .menu-state-bullet-primary .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #e10e8d;
}
.menu-state-bullet-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-bullet-primary .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #e10e8d;
}

.menu-hover-arrow-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-hover-arrow-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-hover-arrow-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.menu-hover-arrow-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-show-arrow-primary .menu-item.here > .menu-link, .menu-show-arrow-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-show-arrow-primary .menu-item.here > .menu-link .menu-arrow:after, .menu-show-arrow-primary .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-active-arrow-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-active-arrow-primary .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.menu-state-arrow-primary .menu-item.hover > .menu-link:not(.disabled):not(.active),
.menu-state-arrow-primary .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-arrow-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.menu-state-arrow-primary .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}
.menu-state-arrow-primary .menu-item.here > .menu-link, .menu-state-arrow-primary .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-arrow-primary .menu-item.here > .menu-link .menu-arrow:after, .menu-state-arrow-primary .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}
.menu-state-arrow-primary .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.menu-state-arrow-primary .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.btn {
  outline: none !important;
}
.light-theme .btn {
  color: #151521;
}
.dark-theme .btn {
  color: #f5f8fa;
}
.btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
  box-shadow: none !important;
}
.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  border: 0;
  padding: calc(0.75rem + 1px) calc(1.5rem + 1px);
}
.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon).btn-lg, .btn-group-lg > .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  padding: calc(0.825rem + 1px) calc(1.75rem + 1px);
}
.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon).btn-sm, .btn-group-sm > .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  padding: calc(0.55rem + 1px) calc(1.25rem + 1px);
}
.btn.btn-link {
  border: 0;
  border-radius: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-decoration: none;
  font-weight: 500;
}
.btn.btn-outline-dashed {
  border: 1px dashed #e4e6ef;
}
.btn.btn-flush {
  appearance: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  outline: none !important;
  margin: 0;
  padding: 0;
}
.btn.btn-flex {
  display: inline-flex;
  align-items: center;
}

.btn i {
  display: inline-flex;
  font-size: 1.5rem;
  padding-right: 0.35rem;
  vertical-align: middle;
  line-height: 0;
  transition: color 0.15s ease-in-out;
}
.btn .svg-icon {
  flex-shrink: 0;
  line-height: 0;
  margin-right: 0.5rem;
}
.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(1.5em + 1.5rem + calc(1px * 2));
  width: calc(1.5em + 1.5rem + calc(1px * 2));
}
.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: 0;
}
.btn.btn-icon.btn-sm, .btn-group-sm > .btn.btn-icon {
  height: calc(1.5em + 1.1rem + calc(1px * 2));
  width: calc(1.5em + 1.1rem + calc(1px * 2));
}
.btn.btn-icon.btn-lg, .btn-group-lg > .btn.btn-icon {
  height: calc(1.5em + 1.65rem + calc(1px * 2));
  width: calc(1.5em + 1.65rem + calc(1px * 2));
}
.btn.btn-icon.btn-circle {
  border-radius: 50%;
}
.btn.btn-icon i,
.btn.btn-icon .svg-icon {
  padding: 0;
  margin: 0;
  line-height: 1;
}

.btn.btn-white {
  color: #000000;
  border-color: #ffffff;
  background-color: #ffffff;
}
.btn.btn-white .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #000000;
}
.btn.btn-white .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-white.dropdown-toggle:after {
  color: #000000;
}
.btn-check:checked + .btn.btn-white, .btn-check:active + .btn.btn-white, .btn.btn-white:focus:not(.btn-active), .btn.btn-white:hover:not(.btn-active), .btn.btn-white:active:not(.btn-active), .btn.btn-white.active, .btn.btn-white.show,
.btn.btn-white .show, .btn.btn-white[aria-expanded=true], [aria-expanded=true] > .btn.btn-white {
  color: #000000;
  border-color: #f5f8fa;
  background-color: #f5f8fa;
}
.btn-check:checked + .btn.btn-white i, .btn-check:active + .btn.btn-white i, .btn.btn-white:focus:not(.btn-active) i, .btn.btn-white:hover:not(.btn-active) i, .btn.btn-white:active:not(.btn-active) i, .btn.btn-white.active i, .btn.btn-white.show i,
.btn.btn-white .show i, .btn.btn-white[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-white i {
  color: #000000;
}
.btn-check:checked + .btn.btn-white .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-white .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-white:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-white:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-white:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-white.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-white.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-white .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-white[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-white .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #000000;
}
.btn-check:checked + .btn.btn-white .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-white .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-white:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-white:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-white:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-white.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-white.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-white .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-white[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-white .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-white.dropdown-toggle:after, .btn-check:active + .btn.btn-white.dropdown-toggle:after, .btn.btn-white:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-white:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-white:active:not(.btn-active).dropdown-toggle:after, .btn.btn-white.active.dropdown-toggle:after, .btn.btn-white.show.dropdown-toggle:after,
.btn.btn-white .show.dropdown-toggle:after, .btn.btn-white[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-white.dropdown-toggle:after {
  color: #000000;
}

.btn.btn-bg-white {
  border-color: #ffffff;
  background-color: #ffffff;
}
.btn-check:checked + .btn.btn-active-white, .btn-check:active + .btn.btn-active-white, .btn.btn-active-white:focus:not(.btn-active), .btn.btn-active-white:hover:not(.btn-active), .btn.btn-active-white:active:not(.btn-active), .btn.btn-active-white.active, .btn.btn-active-white.show,
.btn.btn-active-white .show, .btn.btn-active-white[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-white {
  color: #000000;
  border-color: #ffffff;
  background-color: #ffffff;
}
.btn-check:checked + .btn.btn-active-white i, .btn-check:active + .btn.btn-active-white i, .btn.btn-active-white:focus:not(.btn-active) i, .btn.btn-active-white:hover:not(.btn-active) i, .btn.btn-active-white:active:not(.btn-active) i, .btn.btn-active-white.active i, .btn.btn-active-white.show i,
.btn.btn-active-white .show i, .btn.btn-active-white[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-white i {
  color: #000000;
}
.btn-check:checked + .btn.btn-active-white .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-white .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-white:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-white:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-white:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-white.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-white.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-white .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-white[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-white .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #000000;
}
.btn-check:checked + .btn.btn-active-white .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-white .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-white:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-white:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-white:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-white.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-white.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-white .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-white[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-white .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-white.dropdown-toggle:after, .btn-check:active + .btn.btn-active-white.dropdown-toggle:after, .btn.btn-active-white:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-white:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-white:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-white.active.dropdown-toggle:after, .btn.btn-active-white.show.dropdown-toggle:after,
.btn.btn-active-white .show.dropdown-toggle:after, .btn.btn-active-white[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-white.dropdown-toggle:after {
  color: #000000;
}

.btn.btn-light {
  color: #3f4254;
  border-color: #eff2f5;
  background-color: #eff2f5;
}
.btn.btn-light .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.btn.btn-light .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light.dropdown-toggle:after {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-light, .btn-check:active + .btn.btn-light, .btn.btn-light:focus:not(.btn-active), .btn.btn-light:hover:not(.btn-active), .btn.btn-light:active:not(.btn-active), .btn.btn-light.active, .btn.btn-light.show,
.btn.btn-light .show, .btn.btn-light[aria-expanded=true], [aria-expanded=true] > .btn.btn-light {
  color: #3f4254;
  border-color: #e6eaef;
  background-color: #e6eaef;
}
.btn-check:checked + .btn.btn-light i, .btn-check:active + .btn.btn-light i, .btn.btn-light:focus:not(.btn-active) i, .btn.btn-light:hover:not(.btn-active) i, .btn.btn-light:active:not(.btn-active) i, .btn.btn-light.active i, .btn.btn-light.show i,
.btn.btn-light .show i, .btn.btn-light[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light i {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-light .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.btn-check:checked + .btn.btn-light .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light.dropdown-toggle:after, .btn-check:active + .btn.btn-light.dropdown-toggle:after, .btn.btn-light:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light.active.dropdown-toggle:after, .btn.btn-light.show.dropdown-toggle:after,
.btn.btn-light .show.dropdown-toggle:after, .btn.btn-light[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light.dropdown-toggle:after {
  color: #3f4254;
}

.btn.btn-bg-light {
  border-color: #eff2f5;
  background-color: #eff2f5;
}
.btn-check:checked + .btn.btn-active-light, .btn-check:active + .btn.btn-active-light, .btn.btn-active-light:focus:not(.btn-active), .btn.btn-active-light:hover:not(.btn-active), .btn.btn-active-light:active:not(.btn-active), .btn.btn-active-light.active, .btn.btn-active-light.show,
.btn.btn-active-light .show, .btn.btn-active-light[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light {
  color: #3f4254;
  border-color: #eff2f5;
  background-color: #eff2f5;
}
.btn-check:checked + .btn.btn-active-light i, .btn-check:active + .btn.btn-active-light i, .btn.btn-active-light:focus:not(.btn-active) i, .btn.btn-active-light:hover:not(.btn-active) i, .btn.btn-active-light:active:not(.btn-active) i, .btn.btn-active-light.active i, .btn.btn-active-light.show i,
.btn.btn-active-light .show i, .btn.btn-active-light[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light i {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-active-light .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.btn-check:checked + .btn.btn-active-light .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light.dropdown-toggle:after, .btn.btn-active-light:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light.active.dropdown-toggle:after, .btn.btn-active-light.show.dropdown-toggle:after,
.btn.btn-active-light .show.dropdown-toggle:after, .btn.btn-active-light[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light.dropdown-toggle:after {
  color: #3f4254;
}

.btn.btn-primary {
  color: #ffffff;
  border-color: #e10e8d;
  background-color: #e10e8d;
}
.btn.btn-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-primary.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-primary, .btn-check:active + .btn.btn-primary, .btn.btn-primary:focus:not(.btn-active), .btn.btn-primary:hover:not(.btn-active), .btn.btn-primary:active:not(.btn-active), .btn.btn-primary.active, .btn.btn-primary.show,
.btn.btn-primary .show, .btn.btn-primary[aria-expanded=true], [aria-expanded=true] > .btn.btn-primary {
  color: #ffffff;
  border-color: #a90768;
  background-color: #a90768;
}
.btn-check:checked + .btn.btn-primary i, .btn-check:active + .btn.btn-primary i, .btn.btn-primary:focus:not(.btn-active) i, .btn.btn-primary:hover:not(.btn-active) i, .btn.btn-primary:active:not(.btn-active) i, .btn.btn-primary.active i, .btn.btn-primary.show i,
.btn.btn-primary .show i, .btn.btn-primary[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-primary i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-primary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-primary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-primary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-primary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-primary.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-primary .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-primary[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-primary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-primary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-primary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-primary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-primary.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-primary .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-primary[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-primary.dropdown-toggle:after, .btn-check:active + .btn.btn-primary.dropdown-toggle:after, .btn.btn-primary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-primary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-primary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-primary.active.dropdown-toggle:after, .btn.btn-primary.show.dropdown-toggle:after,
.btn.btn-primary .show.dropdown-toggle:after, .btn.btn-primary[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-primary.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-primary {
  color: #e10e8d;
  border-color: #fff1fe;
  background-color: #fff1fe;
}
.btn.btn-light-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.btn.btn-light-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-primary.dropdown-toggle:after {
  color: #e10e8d;
}
.btn-check:checked + .btn.btn-light-primary, .btn-check:active + .btn.btn-light-primary, .btn.btn-light-primary:focus:not(.btn-active), .btn.btn-light-primary:hover:not(.btn-active), .btn.btn-light-primary:active:not(.btn-active), .btn.btn-light-primary.active, .btn.btn-light-primary.show,
.btn.btn-light-primary .show, .btn.btn-light-primary[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-primary {
  color: #ffffff;
  border-color: #e10e8d;
  background-color: #e10e8d;
}
.btn-check:checked + .btn.btn-light-primary i, .btn-check:active + .btn.btn-light-primary i, .btn.btn-light-primary:focus:not(.btn-active) i, .btn.btn-light-primary:hover:not(.btn-active) i, .btn.btn-light-primary:active:not(.btn-active) i, .btn.btn-light-primary.active i, .btn.btn-light-primary.show i,
.btn.btn-light-primary .show i, .btn.btn-light-primary[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-primary i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-primary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-primary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-primary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-primary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-primary.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-primary .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-primary[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-primary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-primary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-primary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-primary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-primary.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-primary .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-primary[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-primary.dropdown-toggle:after, .btn-check:active + .btn.btn-light-primary.dropdown-toggle:after, .btn.btn-light-primary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-primary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-primary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-primary.active.dropdown-toggle:after, .btn.btn-light-primary.show.dropdown-toggle:after,
.btn.btn-light-primary .show.dropdown-toggle:after, .btn.btn-light-primary[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-primary.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-bg-primary {
  border-color: #e10e8d;
  background-color: #e10e8d;
}
.btn-check:checked + .btn.btn-active-primary, .btn-check:active + .btn.btn-active-primary, .btn.btn-active-primary:focus:not(.btn-active), .btn.btn-active-primary:hover:not(.btn-active), .btn.btn-active-primary:active:not(.btn-active), .btn.btn-active-primary.active, .btn.btn-active-primary.show,
.btn.btn-active-primary .show, .btn.btn-active-primary[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-primary {
  color: #ffffff;
  border-color: #e10e8d;
  background-color: #e10e8d;
}
.btn-check:checked + .btn.btn-active-primary i, .btn-check:active + .btn.btn-active-primary i, .btn.btn-active-primary:focus:not(.btn-active) i, .btn.btn-active-primary:hover:not(.btn-active) i, .btn.btn-active-primary:active:not(.btn-active) i, .btn.btn-active-primary.active i, .btn.btn-active-primary.show i,
.btn.btn-active-primary .show i, .btn.btn-active-primary[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-primary i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-primary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-primary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-primary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-primary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-primary.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-primary .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-primary[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-primary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-primary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-primary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-primary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-primary.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-primary .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-primary[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-primary.dropdown-toggle:after, .btn-check:active + .btn.btn-active-primary.dropdown-toggle:after, .btn.btn-active-primary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-primary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-primary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-primary.active.dropdown-toggle:after, .btn.btn-active-primary.show.dropdown-toggle:after,
.btn.btn-active-primary .show.dropdown-toggle:after, .btn.btn-active-primary[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-primary.dropdown-toggle:after {
  color: #ffffff;
}

.btn-check:checked + .btn.btn-active-light-primary, .btn-check:active + .btn.btn-active-light-primary, .btn.btn-active-light-primary:focus:not(.btn-active), .btn.btn-active-light-primary:hover:not(.btn-active), .btn.btn-active-light-primary:active:not(.btn-active), .btn.btn-active-light-primary.active, .btn.btn-active-light-primary.show,
.btn.btn-active-light-primary .show, .btn.btn-active-light-primary[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-primary {
  color: #e10e8d;
  border-color: #fff1fe;
  background-color: #fff1fe;
}
.btn-check:checked + .btn.btn-active-light-primary i, .btn-check:active + .btn.btn-active-light-primary i, .btn.btn-active-light-primary:focus:not(.btn-active) i, .btn.btn-active-light-primary:hover:not(.btn-active) i, .btn.btn-active-light-primary:active:not(.btn-active) i, .btn.btn-active-light-primary.active i, .btn.btn-active-light-primary.show i,
.btn.btn-active-light-primary .show i, .btn.btn-active-light-primary[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light-primary i {
  color: #e10e8d;
}
.btn-check:checked + .btn.btn-active-light-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-primary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-primary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-primary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-primary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-primary.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-primary[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.btn-check:checked + .btn.btn-active-light-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-primary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-primary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-primary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-primary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-primary.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-primary[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-primary.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-primary.dropdown-toggle:after, .btn.btn-active-light-primary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-primary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-primary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-primary.active.dropdown-toggle:after, .btn.btn-active-light-primary.show.dropdown-toggle:after,
.btn.btn-active-light-primary .show.dropdown-toggle:after, .btn.btn-active-light-primary[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light-primary.dropdown-toggle:after {
  color: #e10e8d;
}
.btn.btn-active-light-primary.btn-outline:not(.btn-outline-default) {
  border-color: #e10e8d !important;
}

.btn.btn-secondary {
  color: #3f4254;
  border-color: #e4e6ef;
  background-color: #e4e6ef;
}
.btn.btn-secondary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.btn.btn-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-secondary.dropdown-toggle:after {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-secondary, .btn-check:active + .btn.btn-secondary, .btn.btn-secondary:focus:not(.btn-active), .btn.btn-secondary:hover:not(.btn-active), .btn.btn-secondary:active:not(.btn-active), .btn.btn-secondary.active, .btn.btn-secondary.show,
.btn.btn-secondary .show, .btn.btn-secondary[aria-expanded=true], [aria-expanded=true] > .btn.btn-secondary {
  color: #3f4254;
  border-color: #b5b5c3;
  background-color: #b5b5c3;
}
.btn-check:checked + .btn.btn-secondary i, .btn-check:active + .btn.btn-secondary i, .btn.btn-secondary:focus:not(.btn-active) i, .btn.btn-secondary:hover:not(.btn-active) i, .btn.btn-secondary:active:not(.btn-active) i, .btn.btn-secondary.active i, .btn.btn-secondary.show i,
.btn.btn-secondary .show i, .btn.btn-secondary[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-secondary i {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-secondary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-secondary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-secondary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-secondary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-secondary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-secondary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-secondary.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-secondary .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-secondary[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-secondary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.btn-check:checked + .btn.btn-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-secondary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-secondary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-secondary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-secondary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-secondary.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-secondary .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-secondary[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-secondary.dropdown-toggle:after, .btn-check:active + .btn.btn-secondary.dropdown-toggle:after, .btn.btn-secondary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-secondary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-secondary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-secondary.active.dropdown-toggle:after, .btn.btn-secondary.show.dropdown-toggle:after,
.btn.btn-secondary .show.dropdown-toggle:after, .btn.btn-secondary[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-secondary.dropdown-toggle:after {
  color: #3f4254;
}

.btn.btn-bg-secondary {
  border-color: #e4e6ef;
  background-color: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-secondary, .btn-check:active + .btn.btn-active-secondary, .btn.btn-active-secondary:focus:not(.btn-active), .btn.btn-active-secondary:hover:not(.btn-active), .btn.btn-active-secondary:active:not(.btn-active), .btn.btn-active-secondary.active, .btn.btn-active-secondary.show,
.btn.btn-active-secondary .show, .btn.btn-active-secondary[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-secondary {
  color: #3f4254;
  border-color: #e4e6ef;
  background-color: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-secondary i, .btn-check:active + .btn.btn-active-secondary i, .btn.btn-active-secondary:focus:not(.btn-active) i, .btn.btn-active-secondary:hover:not(.btn-active) i, .btn.btn-active-secondary:active:not(.btn-active) i, .btn.btn-active-secondary.active i, .btn.btn-active-secondary.show i,
.btn.btn-active-secondary .show i, .btn.btn-active-secondary[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-secondary i {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-active-secondary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-secondary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-secondary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-secondary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-secondary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-secondary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-secondary.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-secondary .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-secondary[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-secondary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.btn-check:checked + .btn.btn-active-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-secondary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-secondary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-secondary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-secondary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-secondary.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-secondary .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-secondary[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-secondary.dropdown-toggle:after, .btn-check:active + .btn.btn-active-secondary.dropdown-toggle:after, .btn.btn-active-secondary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-secondary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-secondary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-secondary.active.dropdown-toggle:after, .btn.btn-active-secondary.show.dropdown-toggle:after,
.btn.btn-active-secondary .show.dropdown-toggle:after, .btn.btn-active-secondary[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-secondary.dropdown-toggle:after {
  color: #3f4254;
}

.btn.btn-success {
  color: #ffffff;
  border-color: #1BC5BC;
  background-color: #1BC5BC;
}
.btn.btn-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-success.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-success, .btn-check:active + .btn.btn-success, .btn.btn-success:focus:not(.btn-active), .btn.btn-success:hover:not(.btn-active), .btn.btn-success:active:not(.btn-active), .btn.btn-success.active, .btn.btn-success.show,
.btn.btn-success .show, .btn.btn-success[aria-expanded=true], [aria-expanded=true] > .btn.btn-success {
  color: #ffffff;
  border-color: #12827c;
  background-color: #12827c;
}
.btn-check:checked + .btn.btn-success i, .btn-check:active + .btn.btn-success i, .btn.btn-success:focus:not(.btn-active) i, .btn.btn-success:hover:not(.btn-active) i, .btn.btn-success:active:not(.btn-active) i, .btn.btn-success.active i, .btn.btn-success.show i,
.btn.btn-success .show i, .btn.btn-success[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-success i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-success .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-success .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-success:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-success:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-success:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-success.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-success.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-success .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-success[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-success:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-success:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-success:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-success.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-success.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-success .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-success[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-success.dropdown-toggle:after, .btn-check:active + .btn.btn-success.dropdown-toggle:after, .btn.btn-success:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-success:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-success:active:not(.btn-active).dropdown-toggle:after, .btn.btn-success.active.dropdown-toggle:after, .btn.btn-success.show.dropdown-toggle:after,
.btn.btn-success .show.dropdown-toggle:after, .btn.btn-success[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-success.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-success {
  color: #1BC5BC;
  border-color: rgba(27, 197, 188, 0.1);
  background-color: rgba(27, 197, 188, 0.1);
}
.btn.btn-light-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.btn.btn-light-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-success.dropdown-toggle:after {
  color: #1BC5BC;
}
.btn-check:checked + .btn.btn-light-success, .btn-check:active + .btn.btn-light-success, .btn.btn-light-success:focus:not(.btn-active), .btn.btn-light-success:hover:not(.btn-active), .btn.btn-light-success:active:not(.btn-active), .btn.btn-light-success.active, .btn.btn-light-success.show,
.btn.btn-light-success .show, .btn.btn-light-success[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-success {
  color: #ffffff;
  border-color: #1BC5BC;
  background-color: #1BC5BC;
}
.btn-check:checked + .btn.btn-light-success i, .btn-check:active + .btn.btn-light-success i, .btn.btn-light-success:focus:not(.btn-active) i, .btn.btn-light-success:hover:not(.btn-active) i, .btn.btn-light-success:active:not(.btn-active) i, .btn.btn-light-success.active i, .btn.btn-light-success.show i,
.btn.btn-light-success .show i, .btn.btn-light-success[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-success i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-success .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-success .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-success:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-success:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-success:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-success.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-success.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-success .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-success[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-success:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-success:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-success:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-success.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-success.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-success .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-success[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-success.dropdown-toggle:after, .btn-check:active + .btn.btn-light-success.dropdown-toggle:after, .btn.btn-light-success:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-success:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-success:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-success.active.dropdown-toggle:after, .btn.btn-light-success.show.dropdown-toggle:after,
.btn.btn-light-success .show.dropdown-toggle:after, .btn.btn-light-success[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-success.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-bg-success {
  border-color: #1BC5BC;
  background-color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-success, .btn-check:active + .btn.btn-active-success, .btn.btn-active-success:focus:not(.btn-active), .btn.btn-active-success:hover:not(.btn-active), .btn.btn-active-success:active:not(.btn-active), .btn.btn-active-success.active, .btn.btn-active-success.show,
.btn.btn-active-success .show, .btn.btn-active-success[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-success {
  color: #ffffff;
  border-color: #1BC5BC;
  background-color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-success i, .btn-check:active + .btn.btn-active-success i, .btn.btn-active-success:focus:not(.btn-active) i, .btn.btn-active-success:hover:not(.btn-active) i, .btn.btn-active-success:active:not(.btn-active) i, .btn.btn-active-success.active i, .btn.btn-active-success.show i,
.btn.btn-active-success .show i, .btn.btn-active-success[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-success i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-success .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-success .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-success:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-success:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-success:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-success.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-success.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-success .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-success[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-success:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-success:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-success:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-success.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-success.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-success .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-success[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-success.dropdown-toggle:after, .btn-check:active + .btn.btn-active-success.dropdown-toggle:after, .btn.btn-active-success:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-success:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-success:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-success.active.dropdown-toggle:after, .btn.btn-active-success.show.dropdown-toggle:after,
.btn.btn-active-success .show.dropdown-toggle:after, .btn.btn-active-success[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-success.dropdown-toggle:after {
  color: #ffffff;
}

.btn-check:checked + .btn.btn-active-light-success, .btn-check:active + .btn.btn-active-light-success, .btn.btn-active-light-success:focus:not(.btn-active), .btn.btn-active-light-success:hover:not(.btn-active), .btn.btn-active-light-success:active:not(.btn-active), .btn.btn-active-light-success.active, .btn.btn-active-light-success.show,
.btn.btn-active-light-success .show, .btn.btn-active-light-success[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-success {
  color: #1BC5BC;
  border-color: rgba(27, 197, 188, 0.1);
  background-color: rgba(27, 197, 188, 0.1);
}
.btn-check:checked + .btn.btn-active-light-success i, .btn-check:active + .btn.btn-active-light-success i, .btn.btn-active-light-success:focus:not(.btn-active) i, .btn.btn-active-light-success:hover:not(.btn-active) i, .btn.btn-active-light-success:active:not(.btn-active) i, .btn.btn-active-light-success.active i, .btn.btn-active-light-success.show i,
.btn.btn-active-light-success .show i, .btn.btn-active-light-success[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light-success i {
  color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-light-success .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-success .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-success:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-success:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-success:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-success.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-success.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-success .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-success[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-light-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-success:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-success:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-success:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-success.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-success.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-success .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-success[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-success.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-success.dropdown-toggle:after, .btn.btn-active-light-success:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-success:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-success:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-success.active.dropdown-toggle:after, .btn.btn-active-light-success.show.dropdown-toggle:after,
.btn.btn-active-light-success .show.dropdown-toggle:after, .btn.btn-active-light-success[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light-success.dropdown-toggle:after {
  color: #1BC5BC;
}
.btn.btn-active-light-success.btn-outline:not(.btn-outline-default) {
  border-color: #1BC5BC !important;
}

.btn.btn-info {
  color: #ffffff;
  border-color: #5F5CF1;
  background-color: #5F5CF1;
}
.btn.btn-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-info.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-info, .btn-check:active + .btn.btn-info, .btn.btn-info:focus:not(.btn-active), .btn.btn-info:hover:not(.btn-active), .btn.btn-info:active:not(.btn-active), .btn.btn-info.active, .btn.btn-info.show,
.btn.btn-info .show, .btn.btn-info[aria-expanded=true], [aria-expanded=true] > .btn.btn-info {
  color: #ffffff;
  border-color: #1a16eb;
  background-color: #1a16eb;
}
.btn-check:checked + .btn.btn-info i, .btn-check:active + .btn.btn-info i, .btn.btn-info:focus:not(.btn-active) i, .btn.btn-info:hover:not(.btn-active) i, .btn.btn-info:active:not(.btn-active) i, .btn.btn-info.active i, .btn.btn-info.show i,
.btn.btn-info .show i, .btn.btn-info[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-info i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-info .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-info .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-info:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-info:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-info:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-info.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-info.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-info .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-info[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-info:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-info:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-info:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-info.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-info.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-info .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-info[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-info.dropdown-toggle:after, .btn-check:active + .btn.btn-info.dropdown-toggle:after, .btn.btn-info:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-info:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-info:active:not(.btn-active).dropdown-toggle:after, .btn.btn-info.active.dropdown-toggle:after, .btn.btn-info.show.dropdown-toggle:after,
.btn.btn-info .show.dropdown-toggle:after, .btn.btn-info[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-info.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-info {
  color: #5F5CF1;
  border-color: rgba(95, 92, 241, 0.1);
  background-color: rgba(95, 92, 241, 0.1);
}
.btn.btn-light-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.btn.btn-light-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-info.dropdown-toggle:after {
  color: #5F5CF1;
}
.btn-check:checked + .btn.btn-light-info, .btn-check:active + .btn.btn-light-info, .btn.btn-light-info:focus:not(.btn-active), .btn.btn-light-info:hover:not(.btn-active), .btn.btn-light-info:active:not(.btn-active), .btn.btn-light-info.active, .btn.btn-light-info.show,
.btn.btn-light-info .show, .btn.btn-light-info[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-info {
  color: #ffffff;
  border-color: #5F5CF1;
  background-color: #5F5CF1;
}
.btn-check:checked + .btn.btn-light-info i, .btn-check:active + .btn.btn-light-info i, .btn.btn-light-info:focus:not(.btn-active) i, .btn.btn-light-info:hover:not(.btn-active) i, .btn.btn-light-info:active:not(.btn-active) i, .btn.btn-light-info.active i, .btn.btn-light-info.show i,
.btn.btn-light-info .show i, .btn.btn-light-info[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-info i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-info .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-info .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-info:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-info:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-info:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-info.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-info.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-info .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-info[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-info:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-info:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-info:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-info.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-info.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-info .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-info[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-info.dropdown-toggle:after, .btn-check:active + .btn.btn-light-info.dropdown-toggle:after, .btn.btn-light-info:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-info:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-info:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-info.active.dropdown-toggle:after, .btn.btn-light-info.show.dropdown-toggle:after,
.btn.btn-light-info .show.dropdown-toggle:after, .btn.btn-light-info[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-info.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-bg-info {
  border-color: #5F5CF1;
  background-color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-info, .btn-check:active + .btn.btn-active-info, .btn.btn-active-info:focus:not(.btn-active), .btn.btn-active-info:hover:not(.btn-active), .btn.btn-active-info:active:not(.btn-active), .btn.btn-active-info.active, .btn.btn-active-info.show,
.btn.btn-active-info .show, .btn.btn-active-info[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-info {
  color: #ffffff;
  border-color: #5F5CF1;
  background-color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-info i, .btn-check:active + .btn.btn-active-info i, .btn.btn-active-info:focus:not(.btn-active) i, .btn.btn-active-info:hover:not(.btn-active) i, .btn.btn-active-info:active:not(.btn-active) i, .btn.btn-active-info.active i, .btn.btn-active-info.show i,
.btn.btn-active-info .show i, .btn.btn-active-info[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-info i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-info .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-info .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-info:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-info:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-info:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-info.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-info.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-info .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-info[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-info:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-info:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-info:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-info.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-info.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-info .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-info[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-info.dropdown-toggle:after, .btn-check:active + .btn.btn-active-info.dropdown-toggle:after, .btn.btn-active-info:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-info:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-info:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-info.active.dropdown-toggle:after, .btn.btn-active-info.show.dropdown-toggle:after,
.btn.btn-active-info .show.dropdown-toggle:after, .btn.btn-active-info[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-info.dropdown-toggle:after {
  color: #ffffff;
}

.btn-check:checked + .btn.btn-active-light-info, .btn-check:active + .btn.btn-active-light-info, .btn.btn-active-light-info:focus:not(.btn-active), .btn.btn-active-light-info:hover:not(.btn-active), .btn.btn-active-light-info:active:not(.btn-active), .btn.btn-active-light-info.active, .btn.btn-active-light-info.show,
.btn.btn-active-light-info .show, .btn.btn-active-light-info[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-info {
  color: #5F5CF1;
  border-color: rgba(95, 92, 241, 0.1);
  background-color: rgba(95, 92, 241, 0.1);
}
.btn-check:checked + .btn.btn-active-light-info i, .btn-check:active + .btn.btn-active-light-info i, .btn.btn-active-light-info:focus:not(.btn-active) i, .btn.btn-active-light-info:hover:not(.btn-active) i, .btn.btn-active-light-info:active:not(.btn-active) i, .btn.btn-active-light-info.active i, .btn.btn-active-light-info.show i,
.btn.btn-active-light-info .show i, .btn.btn-active-light-info[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light-info i {
  color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-light-info .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-info .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-info:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-info:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-info:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-info.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-info.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-info .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-info[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-light-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-info:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-info:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-info:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-info.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-info.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-info .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-info[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-info.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-info.dropdown-toggle:after, .btn.btn-active-light-info:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-info:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-info:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-info.active.dropdown-toggle:after, .btn.btn-active-light-info.show.dropdown-toggle:after,
.btn.btn-active-light-info .show.dropdown-toggle:after, .btn.btn-active-light-info[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light-info.dropdown-toggle:after {
  color: #5F5CF1;
}
.btn.btn-active-light-info.btn-outline:not(.btn-outline-default) {
  border-color: #5F5CF1 !important;
}

.btn.btn-warning {
  color: #eff2f5;
  border-color: #FFA800;
  background-color: #FFA800;
}
.btn.btn-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.btn.btn-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-warning.dropdown-toggle:after {
  color: #eff2f5;
}
.btn-check:checked + .btn.btn-warning, .btn-check:active + .btn.btn-warning, .btn.btn-warning:focus:not(.btn-active), .btn.btn-warning:hover:not(.btn-active), .btn.btn-warning:active:not(.btn-active), .btn.btn-warning.active, .btn.btn-warning.show,
.btn.btn-warning .show, .btn.btn-warning[aria-expanded=true], [aria-expanded=true] > .btn.btn-warning {
  color: #eff2f5;
  border-color: #b37600;
  background-color: #b37600;
}
.btn-check:checked + .btn.btn-warning i, .btn-check:active + .btn.btn-warning i, .btn.btn-warning:focus:not(.btn-active) i, .btn.btn-warning:hover:not(.btn-active) i, .btn.btn-warning:active:not(.btn-active) i, .btn.btn-warning.active i, .btn.btn-warning.show i,
.btn.btn-warning .show i, .btn.btn-warning[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-warning i {
  color: #eff2f5;
}
.btn-check:checked + .btn.btn-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-warning:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-warning:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-warning:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-warning.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-warning.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-warning .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-warning[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.btn-check:checked + .btn.btn-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-warning:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-warning:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-warning:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-warning.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-warning.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-warning .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-warning[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-warning.dropdown-toggle:after, .btn-check:active + .btn.btn-warning.dropdown-toggle:after, .btn.btn-warning:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-warning:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-warning:active:not(.btn-active).dropdown-toggle:after, .btn.btn-warning.active.dropdown-toggle:after, .btn.btn-warning.show.dropdown-toggle:after,
.btn.btn-warning .show.dropdown-toggle:after, .btn.btn-warning[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-warning.dropdown-toggle:after {
  color: #eff2f5;
}

.btn.btn-light-warning {
  color: #FFA800;
  border-color: rgba(255, 168, 0, 0.15);
  background-color: rgba(255, 168, 0, 0.15);
}
.btn.btn-light-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.btn.btn-light-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-warning.dropdown-toggle:after {
  color: #FFA800;
}
.btn-check:checked + .btn.btn-light-warning, .btn-check:active + .btn.btn-light-warning, .btn.btn-light-warning:focus:not(.btn-active), .btn.btn-light-warning:hover:not(.btn-active), .btn.btn-light-warning:active:not(.btn-active), .btn.btn-light-warning.active, .btn.btn-light-warning.show,
.btn.btn-light-warning .show, .btn.btn-light-warning[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-warning {
  color: #eff2f5;
  border-color: #FFA800;
  background-color: #FFA800;
}
.btn-check:checked + .btn.btn-light-warning i, .btn-check:active + .btn.btn-light-warning i, .btn.btn-light-warning:focus:not(.btn-active) i, .btn.btn-light-warning:hover:not(.btn-active) i, .btn.btn-light-warning:active:not(.btn-active) i, .btn.btn-light-warning.active i, .btn.btn-light-warning.show i,
.btn.btn-light-warning .show i, .btn.btn-light-warning[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-warning i {
  color: #eff2f5;
}
.btn-check:checked + .btn.btn-light-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-warning:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-warning:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-warning:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-warning.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-warning.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-warning .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-warning[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.btn-check:checked + .btn.btn-light-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-warning:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-warning:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-warning:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-warning.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-warning.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-warning .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-warning[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-warning.dropdown-toggle:after, .btn-check:active + .btn.btn-light-warning.dropdown-toggle:after, .btn.btn-light-warning:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-warning:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-warning:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-warning.active.dropdown-toggle:after, .btn.btn-light-warning.show.dropdown-toggle:after,
.btn.btn-light-warning .show.dropdown-toggle:after, .btn.btn-light-warning[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-warning.dropdown-toggle:after {
  color: #eff2f5;
}

.btn.btn-bg-warning {
  border-color: #FFA800;
  background-color: #FFA800;
}
.btn-check:checked + .btn.btn-active-warning, .btn-check:active + .btn.btn-active-warning, .btn.btn-active-warning:focus:not(.btn-active), .btn.btn-active-warning:hover:not(.btn-active), .btn.btn-active-warning:active:not(.btn-active), .btn.btn-active-warning.active, .btn.btn-active-warning.show,
.btn.btn-active-warning .show, .btn.btn-active-warning[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-warning {
  color: #eff2f5;
  border-color: #FFA800;
  background-color: #FFA800;
}
.btn-check:checked + .btn.btn-active-warning i, .btn-check:active + .btn.btn-active-warning i, .btn.btn-active-warning:focus:not(.btn-active) i, .btn.btn-active-warning:hover:not(.btn-active) i, .btn.btn-active-warning:active:not(.btn-active) i, .btn.btn-active-warning.active i, .btn.btn-active-warning.show i,
.btn.btn-active-warning .show i, .btn.btn-active-warning[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-warning i {
  color: #eff2f5;
}
.btn-check:checked + .btn.btn-active-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-warning:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-warning:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-warning:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-warning.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-warning.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-warning .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-warning[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.btn-check:checked + .btn.btn-active-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-warning:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-warning:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-warning:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-warning.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-warning.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-warning .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-warning[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-warning.dropdown-toggle:after, .btn-check:active + .btn.btn-active-warning.dropdown-toggle:after, .btn.btn-active-warning:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-warning:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-warning:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-warning.active.dropdown-toggle:after, .btn.btn-active-warning.show.dropdown-toggle:after,
.btn.btn-active-warning .show.dropdown-toggle:after, .btn.btn-active-warning[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-warning.dropdown-toggle:after {
  color: #eff2f5;
}

.btn-check:checked + .btn.btn-active-light-warning, .btn-check:active + .btn.btn-active-light-warning, .btn.btn-active-light-warning:focus:not(.btn-active), .btn.btn-active-light-warning:hover:not(.btn-active), .btn.btn-active-light-warning:active:not(.btn-active), .btn.btn-active-light-warning.active, .btn.btn-active-light-warning.show,
.btn.btn-active-light-warning .show, .btn.btn-active-light-warning[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-warning {
  color: #FFA800;
  border-color: rgba(255, 168, 0, 0.15);
  background-color: rgba(255, 168, 0, 0.15);
}
.btn-check:checked + .btn.btn-active-light-warning i, .btn-check:active + .btn.btn-active-light-warning i, .btn.btn-active-light-warning:focus:not(.btn-active) i, .btn.btn-active-light-warning:hover:not(.btn-active) i, .btn.btn-active-light-warning:active:not(.btn-active) i, .btn.btn-active-light-warning.active i, .btn.btn-active-light-warning.show i,
.btn.btn-active-light-warning .show i, .btn.btn-active-light-warning[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light-warning i {
  color: #FFA800;
}
.btn-check:checked + .btn.btn-active-light-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-warning:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-warning:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-warning:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-warning.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-warning.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-warning .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-warning[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.btn-check:checked + .btn.btn-active-light-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-warning:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-warning:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-warning:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-warning.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-warning.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-warning .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-warning[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-warning.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-warning.dropdown-toggle:after, .btn.btn-active-light-warning:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-warning:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-warning:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-warning.active.dropdown-toggle:after, .btn.btn-active-light-warning.show.dropdown-toggle:after,
.btn.btn-active-light-warning .show.dropdown-toggle:after, .btn.btn-active-light-warning[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light-warning.dropdown-toggle:after {
  color: #FFA800;
}
.btn.btn-active-light-warning.btn-outline:not(.btn-outline-default) {
  border-color: #FFA800 !important;
}

.btn.btn-danger {
  color: #ffffff;
  border-color: #F65464;
  background-color: #F65464;
}
.btn.btn-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-danger.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-danger, .btn-check:active + .btn.btn-danger, .btn.btn-danger:focus:not(.btn-active), .btn.btn-danger:hover:not(.btn-active), .btn.btn-danger:active:not(.btn-active), .btn.btn-danger.active, .btn.btn-danger.show,
.btn.btn-danger .show, .btn.btn-danger[aria-expanded=true], [aria-expanded=true] > .btn.btn-danger {
  color: #ffffff;
  border-color: #f10d23;
  background-color: #f10d23;
}
.btn-check:checked + .btn.btn-danger i, .btn-check:active + .btn.btn-danger i, .btn.btn-danger:focus:not(.btn-active) i, .btn.btn-danger:hover:not(.btn-active) i, .btn.btn-danger:active:not(.btn-active) i, .btn.btn-danger.active i, .btn.btn-danger.show i,
.btn.btn-danger .show i, .btn.btn-danger[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-danger i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-danger.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-danger.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-danger .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-danger[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-danger .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-danger[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-danger.dropdown-toggle:after, .btn-check:active + .btn.btn-danger.dropdown-toggle:after, .btn.btn-danger:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-danger:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-danger:active:not(.btn-active).dropdown-toggle:after, .btn.btn-danger.active.dropdown-toggle:after, .btn.btn-danger.show.dropdown-toggle:after,
.btn.btn-danger .show.dropdown-toggle:after, .btn.btn-danger[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-danger.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-danger {
  color: #F65464;
  border-color: rgba(246, 84, 100, 0.1);
  background-color: rgba(246, 84, 100, 0.1);
}
.btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-danger.dropdown-toggle:after {
  color: #F65464;
}
.btn-check:checked + .btn.btn-light-danger, .btn-check:active + .btn.btn-light-danger, .btn.btn-light-danger:focus:not(.btn-active), .btn.btn-light-danger:hover:not(.btn-active), .btn.btn-light-danger:active:not(.btn-active), .btn.btn-light-danger.active, .btn.btn-light-danger.show,
.btn.btn-light-danger .show, .btn.btn-light-danger[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-danger {
  color: #ffffff;
  border-color: #F65464;
  background-color: #F65464;
}
.btn-check:checked + .btn.btn-light-danger i, .btn-check:active + .btn.btn-light-danger i, .btn.btn-light-danger:focus:not(.btn-active) i, .btn.btn-light-danger:hover:not(.btn-active) i, .btn.btn-light-danger:active:not(.btn-active) i, .btn.btn-light-danger.active i, .btn.btn-light-danger.show i,
.btn.btn-light-danger .show i, .btn.btn-light-danger[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-danger i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-danger.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-danger.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-danger .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-danger[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-danger .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-danger[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-danger.dropdown-toggle:after, .btn-check:active + .btn.btn-light-danger.dropdown-toggle:after, .btn.btn-light-danger:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-danger:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-danger:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-danger.active.dropdown-toggle:after, .btn.btn-light-danger.show.dropdown-toggle:after,
.btn.btn-light-danger .show.dropdown-toggle:after, .btn.btn-light-danger[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-danger.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-bg-danger {
  border-color: #F65464;
  background-color: #F65464;
}
.btn-check:checked + .btn.btn-active-danger, .btn-check:active + .btn.btn-active-danger, .btn.btn-active-danger:focus:not(.btn-active), .btn.btn-active-danger:hover:not(.btn-active), .btn.btn-active-danger:active:not(.btn-active), .btn.btn-active-danger.active, .btn.btn-active-danger.show,
.btn.btn-active-danger .show, .btn.btn-active-danger[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-danger {
  color: #ffffff;
  border-color: #F65464;
  background-color: #F65464;
}
.btn-check:checked + .btn.btn-active-danger i, .btn-check:active + .btn.btn-active-danger i, .btn.btn-active-danger:focus:not(.btn-active) i, .btn.btn-active-danger:hover:not(.btn-active) i, .btn.btn-active-danger:active:not(.btn-active) i, .btn.btn-active-danger.active i, .btn.btn-active-danger.show i,
.btn.btn-active-danger .show i, .btn.btn-active-danger[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-danger i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-danger.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-danger.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-danger .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-danger[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-danger .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-danger[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-danger.dropdown-toggle:after, .btn-check:active + .btn.btn-active-danger.dropdown-toggle:after, .btn.btn-active-danger:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-danger:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-danger:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-danger.active.dropdown-toggle:after, .btn.btn-active-danger.show.dropdown-toggle:after,
.btn.btn-active-danger .show.dropdown-toggle:after, .btn.btn-active-danger[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-danger.dropdown-toggle:after {
  color: #ffffff;
}

.btn-check:checked + .btn.btn-active-light-danger, .btn-check:active + .btn.btn-active-light-danger, .btn.btn-active-light-danger:focus:not(.btn-active), .btn.btn-active-light-danger:hover:not(.btn-active), .btn.btn-active-light-danger:active:not(.btn-active), .btn.btn-active-light-danger.active, .btn.btn-active-light-danger.show,
.btn.btn-active-light-danger .show, .btn.btn-active-light-danger[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-danger {
  color: #F65464;
  border-color: rgba(246, 84, 100, 0.1);
  background-color: rgba(246, 84, 100, 0.1);
}
.btn-check:checked + .btn.btn-active-light-danger i, .btn-check:active + .btn.btn-active-light-danger i, .btn.btn-active-light-danger:focus:not(.btn-active) i, .btn.btn-active-light-danger:hover:not(.btn-active) i, .btn.btn-active-light-danger:active:not(.btn-active) i, .btn.btn-active-light-danger.active i, .btn.btn-active-light-danger.show i,
.btn.btn-active-light-danger .show i, .btn.btn-active-light-danger[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light-danger i {
  color: #F65464;
}
.btn-check:checked + .btn.btn-active-light-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-danger.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-danger.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-danger[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.btn-check:checked + .btn.btn-active-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-danger[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-danger.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-danger.dropdown-toggle:after, .btn.btn-active-light-danger:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-danger:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-danger:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-danger.active.dropdown-toggle:after, .btn.btn-active-light-danger.show.dropdown-toggle:after,
.btn.btn-active-light-danger .show.dropdown-toggle:after, .btn.btn-active-light-danger[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light-danger.dropdown-toggle:after {
  color: #F65464;
}
.btn.btn-active-light-danger.btn-outline:not(.btn-outline-default) {
  border-color: #F65464 !important;
}

.btn.btn-dark {
  color: #f5f8fa;
  border-color: #3f4254;
  background-color: #3f4254;
}
.btn.btn-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #f5f8fa;
}
.btn.btn-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-dark.dropdown-toggle:after {
  color: #f5f8fa;
}
.btn-check:checked + .btn.btn-dark, .btn-check:active + .btn.btn-dark, .btn.btn-dark:focus:not(.btn-active), .btn.btn-dark:hover:not(.btn-active), .btn.btn-dark:active:not(.btn-active), .btn.btn-dark.active, .btn.btn-dark.show,
.btn.btn-dark .show, .btn.btn-dark[aria-expanded=true], [aria-expanded=true] > .btn.btn-dark {
  color: #f5f8fa;
  border-color: #383b4b;
  background-color: #383b4b;
}
.btn-check:checked + .btn.btn-dark i, .btn-check:active + .btn.btn-dark i, .btn.btn-dark:focus:not(.btn-active) i, .btn.btn-dark:hover:not(.btn-active) i, .btn.btn-dark:active:not(.btn-active) i, .btn.btn-dark.active i, .btn.btn-dark.show i,
.btn.btn-dark .show i, .btn.btn-dark[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-dark i {
  color: #f5f8fa;
}
.btn-check:checked + .btn.btn-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-dark:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-dark:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-dark:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-dark.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-dark.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-dark .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-dark[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #f5f8fa;
}
.btn-check:checked + .btn.btn-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-dark:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-dark:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-dark:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-dark.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-dark.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-dark .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-dark[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-dark.dropdown-toggle:after, .btn-check:active + .btn.btn-dark.dropdown-toggle:after, .btn.btn-dark:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-dark:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-dark:active:not(.btn-active).dropdown-toggle:after, .btn.btn-dark.active.dropdown-toggle:after, .btn.btn-dark.show.dropdown-toggle:after,
.btn.btn-dark .show.dropdown-toggle:after, .btn.btn-dark[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-dark.dropdown-toggle:after {
  color: #f5f8fa;
}

.btn.btn-light-dark {
  color: #3f4254;
  border-color: #eff2f5;
  background-color: #eff2f5;
}
.btn.btn-light-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.btn.btn-light-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-dark.dropdown-toggle:after {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-light-dark, .btn-check:active + .btn.btn-light-dark, .btn.btn-light-dark:focus:not(.btn-active), .btn.btn-light-dark:hover:not(.btn-active), .btn.btn-light-dark:active:not(.btn-active), .btn.btn-light-dark.active, .btn.btn-light-dark.show,
.btn.btn-light-dark .show, .btn.btn-light-dark[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-dark {
  color: #f5f8fa;
  border-color: #3f4254;
  background-color: #3f4254;
}
.btn-check:checked + .btn.btn-light-dark i, .btn-check:active + .btn.btn-light-dark i, .btn.btn-light-dark:focus:not(.btn-active) i, .btn.btn-light-dark:hover:not(.btn-active) i, .btn.btn-light-dark:active:not(.btn-active) i, .btn.btn-light-dark.active i, .btn.btn-light-dark.show i,
.btn.btn-light-dark .show i, .btn.btn-light-dark[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-dark i {
  color: #f5f8fa;
}
.btn-check:checked + .btn.btn-light-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-dark:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-dark:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-dark:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-dark.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-dark.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-dark .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-dark[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #f5f8fa;
}
.btn-check:checked + .btn.btn-light-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-dark:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-dark:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-dark:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-dark.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-dark.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-dark .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-dark[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-dark.dropdown-toggle:after, .btn-check:active + .btn.btn-light-dark.dropdown-toggle:after, .btn.btn-light-dark:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-dark:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-dark:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-dark.active.dropdown-toggle:after, .btn.btn-light-dark.show.dropdown-toggle:after,
.btn.btn-light-dark .show.dropdown-toggle:after, .btn.btn-light-dark[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-dark.dropdown-toggle:after {
  color: #f5f8fa;
}

.btn.btn-bg-dark {
  border-color: #3f4254;
  background-color: #3f4254;
}
.btn-check:checked + .btn.btn-active-dark, .btn-check:active + .btn.btn-active-dark, .btn.btn-active-dark:focus:not(.btn-active), .btn.btn-active-dark:hover:not(.btn-active), .btn.btn-active-dark:active:not(.btn-active), .btn.btn-active-dark.active, .btn.btn-active-dark.show,
.btn.btn-active-dark .show, .btn.btn-active-dark[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-dark {
  color: #f5f8fa;
  border-color: #3f4254;
  background-color: #3f4254;
}
.btn-check:checked + .btn.btn-active-dark i, .btn-check:active + .btn.btn-active-dark i, .btn.btn-active-dark:focus:not(.btn-active) i, .btn.btn-active-dark:hover:not(.btn-active) i, .btn.btn-active-dark:active:not(.btn-active) i, .btn.btn-active-dark.active i, .btn.btn-active-dark.show i,
.btn.btn-active-dark .show i, .btn.btn-active-dark[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-dark i {
  color: #f5f8fa;
}
.btn-check:checked + .btn.btn-active-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-dark:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-dark:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-dark:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-dark.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-dark.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-dark .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-dark[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #f5f8fa;
}
.btn-check:checked + .btn.btn-active-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-dark:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-dark:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-dark:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-dark.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-dark.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-dark .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-dark[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-dark.dropdown-toggle:after, .btn-check:active + .btn.btn-active-dark.dropdown-toggle:after, .btn.btn-active-dark:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-dark:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-dark:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-dark.active.dropdown-toggle:after, .btn.btn-active-dark.show.dropdown-toggle:after,
.btn.btn-active-dark .show.dropdown-toggle:after, .btn.btn-active-dark[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-dark.dropdown-toggle:after {
  color: #f5f8fa;
}

.btn-check:checked + .btn.btn-active-light-dark, .btn-check:active + .btn.btn-active-light-dark, .btn.btn-active-light-dark:focus:not(.btn-active), .btn.btn-active-light-dark:hover:not(.btn-active), .btn.btn-active-light-dark:active:not(.btn-active), .btn.btn-active-light-dark.active, .btn.btn-active-light-dark.show,
.btn.btn-active-light-dark .show, .btn.btn-active-light-dark[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-dark {
  color: #3f4254;
  border-color: #eff2f5;
  background-color: #eff2f5;
}
.btn-check:checked + .btn.btn-active-light-dark i, .btn-check:active + .btn.btn-active-light-dark i, .btn.btn-active-light-dark:focus:not(.btn-active) i, .btn.btn-active-light-dark:hover:not(.btn-active) i, .btn.btn-active-light-dark:active:not(.btn-active) i, .btn.btn-active-light-dark.active i, .btn.btn-active-light-dark.show i,
.btn.btn-active-light-dark .show i, .btn.btn-active-light-dark[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light-dark i {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-active-light-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-dark:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-dark:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-dark:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-dark.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-dark.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-dark .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-dark[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.btn-check:checked + .btn.btn-active-light-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-dark:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-dark:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-dark:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-dark.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-dark.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-dark .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-dark[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-dark.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-dark.dropdown-toggle:after, .btn.btn-active-light-dark:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-dark:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-dark:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-dark.active.dropdown-toggle:after, .btn.btn-active-light-dark.show.dropdown-toggle:after,
.btn.btn-active-light-dark .show.dropdown-toggle:after, .btn.btn-active-light-dark[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light-dark.dropdown-toggle:after {
  color: #3f4254;
}
.btn.btn-active-light-dark.btn-outline:not(.btn-outline-default) {
  border-color: #3f4254 !important;
}

.btn.btn-black {
  color: #ffffff;
  border-color: #000000;
  background-color: #000000;
}
.btn.btn-black .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-black .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-black.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-black, .btn-check:active + .btn.btn-black, .btn.btn-black:focus:not(.btn-active), .btn.btn-black:hover:not(.btn-active), .btn.btn-black:active:not(.btn-active), .btn.btn-black.active, .btn.btn-black.show,
.btn.btn-black .show, .btn.btn-black[aria-expanded=true], [aria-expanded=true] > .btn.btn-black {
  color: #ffffff;
  border-color: #181c32;
  background-color: #181c32;
}
.btn-check:checked + .btn.btn-black i, .btn-check:active + .btn.btn-black i, .btn.btn-black:focus:not(.btn-active) i, .btn.btn-black:hover:not(.btn-active) i, .btn.btn-black:active:not(.btn-active) i, .btn.btn-black.active i, .btn.btn-black.show i,
.btn.btn-black .show i, .btn.btn-black[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-black i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-black .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-black .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-black:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-black:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-black:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-black.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-black.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-black .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-black[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-black .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-black .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-black .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-black:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-black:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-black:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-black.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-black.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-black .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-black[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-black .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-black.dropdown-toggle:after, .btn-check:active + .btn.btn-black.dropdown-toggle:after, .btn.btn-black:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-black:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-black:active:not(.btn-active).dropdown-toggle:after, .btn.btn-black.active.dropdown-toggle:after, .btn.btn-black.show.dropdown-toggle:after,
.btn.btn-black .show.dropdown-toggle:after, .btn.btn-black[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-black.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-bg-black {
  border-color: #000000;
  background-color: #000000;
}
.btn-check:checked + .btn.btn-active-black, .btn-check:active + .btn.btn-active-black, .btn.btn-active-black:focus:not(.btn-active), .btn.btn-active-black:hover:not(.btn-active), .btn.btn-active-black:active:not(.btn-active), .btn.btn-active-black.active, .btn.btn-active-black.show,
.btn.btn-active-black .show, .btn.btn-active-black[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-black {
  color: #ffffff;
  border-color: #000000;
  background-color: #000000;
}
.btn-check:checked + .btn.btn-active-black i, .btn-check:active + .btn.btn-active-black i, .btn.btn-active-black:focus:not(.btn-active) i, .btn.btn-active-black:hover:not(.btn-active) i, .btn.btn-active-black:active:not(.btn-active) i, .btn.btn-active-black.active i, .btn.btn-active-black.show i,
.btn.btn-active-black .show i, .btn.btn-active-black[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-black i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-black .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-black .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-black:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-black:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-black:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-black.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-black.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-black .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-black[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-black .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-black .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-black .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-black:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-black:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-black:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-black.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-black.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-black .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-black[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-black .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-black.dropdown-toggle:after, .btn-check:active + .btn.btn-active-black.dropdown-toggle:after, .btn.btn-active-black:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-black:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-black:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-black.active.dropdown-toggle:after, .btn.btn-active-black.show.dropdown-toggle:after,
.btn.btn-active-black .show.dropdown-toggle:after, .btn.btn-active-black[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-black.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-muted {
  border-color: #a1a5b7;
  background-color: #a1a5b7;
}
.btn.btn-bg-muted {
  border-color: #a1a5b7;
  background-color: #a1a5b7;
}
.btn-check:checked + .btn.btn-active-muted, .btn-check:active + .btn.btn-active-muted, .btn.btn-active-muted:focus:not(.btn-active), .btn.btn-active-muted:hover:not(.btn-active), .btn.btn-active-muted:active:not(.btn-active), .btn.btn-active-muted.active, .btn.btn-active-muted.show,
.btn.btn-active-muted .show, .btn.btn-active-muted[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-muted {
  border-color: #a1a5b7;
  background-color: #a1a5b7;
}

.btn.btn-blue {
  color: #ffffff;
  border-color: #3699FF;
  background-color: #3699FF;
}
.btn.btn-blue .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-blue .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-blue.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-blue, .btn-check:active + .btn.btn-blue, .btn.btn-blue:focus:not(.btn-active), .btn.btn-blue:hover:not(.btn-active), .btn.btn-blue:active:not(.btn-active), .btn.btn-blue.active, .btn.btn-blue.show,
.btn.btn-blue .show, .btn.btn-blue[aria-expanded=true], [aria-expanded=true] > .btn.btn-blue {
  color: #ffffff;
  border-color: #0073e9;
  background-color: #0073e9;
}
.btn-check:checked + .btn.btn-blue i, .btn-check:active + .btn.btn-blue i, .btn.btn-blue:focus:not(.btn-active) i, .btn.btn-blue:hover:not(.btn-active) i, .btn.btn-blue:active:not(.btn-active) i, .btn.btn-blue.active i, .btn.btn-blue.show i,
.btn.btn-blue .show i, .btn.btn-blue[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-blue i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-blue .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-blue .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-blue:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-blue:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-blue:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-blue.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-blue.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-blue .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-blue[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-blue .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-blue .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-blue .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-blue:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-blue:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-blue:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-blue.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-blue.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-blue .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-blue[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-blue .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-blue.dropdown-toggle:after, .btn-check:active + .btn.btn-blue.dropdown-toggle:after, .btn.btn-blue:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-blue:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-blue:active:not(.btn-active).dropdown-toggle:after, .btn.btn-blue.active.dropdown-toggle:after, .btn.btn-blue.show.dropdown-toggle:after,
.btn.btn-blue .show.dropdown-toggle:after, .btn.btn-blue[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-blue.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-blue {
  color: #3699FF;
  border-color: rgba(54, 153, 255, 0.1);
  background-color: rgba(54, 153, 255, 0.1);
}
.btn.btn-light-blue .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3699FF;
}
.btn.btn-light-blue .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-blue.dropdown-toggle:after {
  color: #3699FF;
}
.btn-check:checked + .btn.btn-light-blue, .btn-check:active + .btn.btn-light-blue, .btn.btn-light-blue:focus:not(.btn-active), .btn.btn-light-blue:hover:not(.btn-active), .btn.btn-light-blue:active:not(.btn-active), .btn.btn-light-blue.active, .btn.btn-light-blue.show,
.btn.btn-light-blue .show, .btn.btn-light-blue[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-blue {
  color: #ffffff;
  border-color: #3699FF;
  background-color: #3699FF;
}
.btn-check:checked + .btn.btn-light-blue i, .btn-check:active + .btn.btn-light-blue i, .btn.btn-light-blue:focus:not(.btn-active) i, .btn.btn-light-blue:hover:not(.btn-active) i, .btn.btn-light-blue:active:not(.btn-active) i, .btn.btn-light-blue.active i, .btn.btn-light-blue.show i,
.btn.btn-light-blue .show i, .btn.btn-light-blue[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-blue i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-blue .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-blue .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-blue:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-blue:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-blue:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-blue.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-blue.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-blue .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-blue[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-blue .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-blue .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-blue .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-blue:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-blue:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-blue:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-blue.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-blue.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-blue .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-blue[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-blue .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-blue.dropdown-toggle:after, .btn-check:active + .btn.btn-light-blue.dropdown-toggle:after, .btn.btn-light-blue:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-blue:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-blue:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-blue.active.dropdown-toggle:after, .btn.btn-light-blue.show.dropdown-toggle:after,
.btn.btn-light-blue .show.dropdown-toggle:after, .btn.btn-light-blue[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-blue.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-bg-blue {
  border-color: #3699FF;
  background-color: #3699FF;
}
.btn-check:checked + .btn.btn-active-blue, .btn-check:active + .btn.btn-active-blue, .btn.btn-active-blue:focus:not(.btn-active), .btn.btn-active-blue:hover:not(.btn-active), .btn.btn-active-blue:active:not(.btn-active), .btn.btn-active-blue.active, .btn.btn-active-blue.show,
.btn.btn-active-blue .show, .btn.btn-active-blue[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-blue {
  color: #ffffff;
  border-color: #3699FF;
  background-color: #3699FF;
}
.btn-check:checked + .btn.btn-active-blue i, .btn-check:active + .btn.btn-active-blue i, .btn.btn-active-blue:focus:not(.btn-active) i, .btn.btn-active-blue:hover:not(.btn-active) i, .btn.btn-active-blue:active:not(.btn-active) i, .btn.btn-active-blue.active i, .btn.btn-active-blue.show i,
.btn.btn-active-blue .show i, .btn.btn-active-blue[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-blue i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-blue .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-blue .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-blue:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-blue:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-blue:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-blue.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-blue.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-blue .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-blue[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-blue .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-blue .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-blue .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-blue:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-blue:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-blue:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-blue.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-blue.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-blue .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-blue[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-blue .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-blue.dropdown-toggle:after, .btn-check:active + .btn.btn-active-blue.dropdown-toggle:after, .btn.btn-active-blue:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-blue:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-blue:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-blue.active.dropdown-toggle:after, .btn.btn-active-blue.show.dropdown-toggle:after,
.btn.btn-active-blue .show.dropdown-toggle:after, .btn.btn-active-blue[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-blue.dropdown-toggle:after {
  color: #ffffff;
}

.btn-check:checked + .btn.btn-active-light-blue, .btn-check:active + .btn.btn-active-light-blue, .btn.btn-active-light-blue:focus:not(.btn-active), .btn.btn-active-light-blue:hover:not(.btn-active), .btn.btn-active-light-blue:active:not(.btn-active), .btn.btn-active-light-blue.active, .btn.btn-active-light-blue.show,
.btn.btn-active-light-blue .show, .btn.btn-active-light-blue[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-blue {
  color: #3699FF;
  border-color: rgba(54, 153, 255, 0.1);
  background-color: rgba(54, 153, 255, 0.1);
}
.btn-check:checked + .btn.btn-active-light-blue i, .btn-check:active + .btn.btn-active-light-blue i, .btn.btn-active-light-blue:focus:not(.btn-active) i, .btn.btn-active-light-blue:hover:not(.btn-active) i, .btn.btn-active-light-blue:active:not(.btn-active) i, .btn.btn-active-light-blue.active i, .btn.btn-active-light-blue.show i,
.btn.btn-active-light-blue .show i, .btn.btn-active-light-blue[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light-blue i {
  color: #3699FF;
}
.btn-check:checked + .btn.btn-active-light-blue .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-blue .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-blue:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-blue:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-blue:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-blue.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-blue.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-blue .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-blue[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-blue .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3699FF;
}
.btn-check:checked + .btn.btn-active-light-blue .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-blue .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-blue:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-blue:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-blue:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-blue.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-blue.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-blue .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-blue[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-blue .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-blue.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-blue.dropdown-toggle:after, .btn.btn-active-light-blue:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-blue:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-blue:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-blue.active.dropdown-toggle:after, .btn.btn-active-light-blue.show.dropdown-toggle:after,
.btn.btn-active-light-blue .show.dropdown-toggle:after, .btn.btn-active-light-blue[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light-blue.dropdown-toggle:after {
  color: #3699FF;
}
.btn.btn-active-light-blue.btn-outline:not(.btn-outline-default) {
  border-color: #3699FF !important;
}

.btn.btn-indigo {
  color: #ffffff;
  border-color: #6610f2;
  background-color: #6610f2;
}
.btn.btn-indigo .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-indigo.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-indigo, .btn-check:active + .btn.btn-indigo, .btn.btn-indigo:focus:not(.btn-active), .btn.btn-indigo:hover:not(.btn-active), .btn.btn-indigo:active:not(.btn-active), .btn.btn-indigo.active, .btn.btn-indigo.show,
.btn.btn-indigo .show, .btn.btn-indigo[aria-expanded=true], [aria-expanded=true] > .btn.btn-indigo {
  color: #ffffff;
  border-color: #4709ac;
  background-color: #4709ac;
}
.btn-check:checked + .btn.btn-indigo i, .btn-check:active + .btn.btn-indigo i, .btn.btn-indigo:focus:not(.btn-active) i, .btn.btn-indigo:hover:not(.btn-active) i, .btn.btn-indigo:active:not(.btn-active) i, .btn.btn-indigo.active i, .btn.btn-indigo.show i,
.btn.btn-indigo .show i, .btn.btn-indigo[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-indigo i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-indigo .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-indigo .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-indigo:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-indigo:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-indigo:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-indigo.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-indigo.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-indigo .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-indigo[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-indigo .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-indigo:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-indigo:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-indigo:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-indigo.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-indigo.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-indigo .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-indigo[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-indigo.dropdown-toggle:after, .btn-check:active + .btn.btn-indigo.dropdown-toggle:after, .btn.btn-indigo:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-indigo:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-indigo:active:not(.btn-active).dropdown-toggle:after, .btn.btn-indigo.active.dropdown-toggle:after, .btn.btn-indigo.show.dropdown-toggle:after,
.btn.btn-indigo .show.dropdown-toggle:after, .btn.btn-indigo[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-indigo.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-indigo {
  color: #6610f2;
  border-color: rgba(102, 16, 242, 0.1);
  background-color: rgba(102, 16, 242, 0.1);
}
.btn.btn-light-indigo .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #6610f2;
}
.btn.btn-light-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-indigo.dropdown-toggle:after {
  color: #6610f2;
}
.btn-check:checked + .btn.btn-light-indigo, .btn-check:active + .btn.btn-light-indigo, .btn.btn-light-indigo:focus:not(.btn-active), .btn.btn-light-indigo:hover:not(.btn-active), .btn.btn-light-indigo:active:not(.btn-active), .btn.btn-light-indigo.active, .btn.btn-light-indigo.show,
.btn.btn-light-indigo .show, .btn.btn-light-indigo[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-indigo {
  color: #ffffff;
  border-color: #6610f2;
  background-color: #6610f2;
}
.btn-check:checked + .btn.btn-light-indigo i, .btn-check:active + .btn.btn-light-indigo i, .btn.btn-light-indigo:focus:not(.btn-active) i, .btn.btn-light-indigo:hover:not(.btn-active) i, .btn.btn-light-indigo:active:not(.btn-active) i, .btn.btn-light-indigo.active i, .btn.btn-light-indigo.show i,
.btn.btn-light-indigo .show i, .btn.btn-light-indigo[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-indigo i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-indigo .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-indigo .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-indigo:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-indigo:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-indigo:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-indigo.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-indigo.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-indigo .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-indigo[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-indigo .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-indigo:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-indigo:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-indigo:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-indigo.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-indigo.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-indigo .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-indigo[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-indigo.dropdown-toggle:after, .btn-check:active + .btn.btn-light-indigo.dropdown-toggle:after, .btn.btn-light-indigo:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-indigo:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-indigo:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-indigo.active.dropdown-toggle:after, .btn.btn-light-indigo.show.dropdown-toggle:after,
.btn.btn-light-indigo .show.dropdown-toggle:after, .btn.btn-light-indigo[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-indigo.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-bg-indigo {
  border-color: #6610f2;
  background-color: #6610f2;
}
.btn-check:checked + .btn.btn-active-indigo, .btn-check:active + .btn.btn-active-indigo, .btn.btn-active-indigo:focus:not(.btn-active), .btn.btn-active-indigo:hover:not(.btn-active), .btn.btn-active-indigo:active:not(.btn-active), .btn.btn-active-indigo.active, .btn.btn-active-indigo.show,
.btn.btn-active-indigo .show, .btn.btn-active-indigo[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-indigo {
  color: #ffffff;
  border-color: #6610f2;
  background-color: #6610f2;
}
.btn-check:checked + .btn.btn-active-indigo i, .btn-check:active + .btn.btn-active-indigo i, .btn.btn-active-indigo:focus:not(.btn-active) i, .btn.btn-active-indigo:hover:not(.btn-active) i, .btn.btn-active-indigo:active:not(.btn-active) i, .btn.btn-active-indigo.active i, .btn.btn-active-indigo.show i,
.btn.btn-active-indigo .show i, .btn.btn-active-indigo[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-indigo i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-indigo .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-indigo .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-indigo:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-indigo:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-indigo:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-indigo.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-indigo.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-indigo .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-indigo[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-indigo .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-indigo:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-indigo:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-indigo:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-indigo.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-indigo.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-indigo .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-indigo[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-indigo.dropdown-toggle:after, .btn-check:active + .btn.btn-active-indigo.dropdown-toggle:after, .btn.btn-active-indigo:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-indigo:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-indigo:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-indigo.active.dropdown-toggle:after, .btn.btn-active-indigo.show.dropdown-toggle:after,
.btn.btn-active-indigo .show.dropdown-toggle:after, .btn.btn-active-indigo[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-indigo.dropdown-toggle:after {
  color: #ffffff;
}

.btn-check:checked + .btn.btn-active-light-indigo, .btn-check:active + .btn.btn-active-light-indigo, .btn.btn-active-light-indigo:focus:not(.btn-active), .btn.btn-active-light-indigo:hover:not(.btn-active), .btn.btn-active-light-indigo:active:not(.btn-active), .btn.btn-active-light-indigo.active, .btn.btn-active-light-indigo.show,
.btn.btn-active-light-indigo .show, .btn.btn-active-light-indigo[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-indigo {
  color: #6610f2;
  border-color: rgba(102, 16, 242, 0.1);
  background-color: rgba(102, 16, 242, 0.1);
}
.btn-check:checked + .btn.btn-active-light-indigo i, .btn-check:active + .btn.btn-active-light-indigo i, .btn.btn-active-light-indigo:focus:not(.btn-active) i, .btn.btn-active-light-indigo:hover:not(.btn-active) i, .btn.btn-active-light-indigo:active:not(.btn-active) i, .btn.btn-active-light-indigo.active i, .btn.btn-active-light-indigo.show i,
.btn.btn-active-light-indigo .show i, .btn.btn-active-light-indigo[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light-indigo i {
  color: #6610f2;
}
.btn-check:checked + .btn.btn-active-light-indigo .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-indigo .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-indigo:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-indigo:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-indigo:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-indigo.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-indigo.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-indigo .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-indigo[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-indigo .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #6610f2;
}
.btn-check:checked + .btn.btn-active-light-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-indigo:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-indigo:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-indigo:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-indigo.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-indigo.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-indigo .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-indigo[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-indigo.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-indigo.dropdown-toggle:after, .btn.btn-active-light-indigo:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-indigo:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-indigo:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-indigo.active.dropdown-toggle:after, .btn.btn-active-light-indigo.show.dropdown-toggle:after,
.btn.btn-active-light-indigo .show.dropdown-toggle:after, .btn.btn-active-light-indigo[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light-indigo.dropdown-toggle:after {
  color: #6610f2;
}
.btn.btn-active-light-indigo.btn-outline:not(.btn-outline-default) {
  border-color: #6610f2 !important;
}

.btn.btn-purple {
  color: #ffffff;
  border-color: #5F5CF1;
  background-color: #5F5CF1;
}
.btn.btn-purple .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-purple .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-purple.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-purple, .btn-check:active + .btn.btn-purple, .btn.btn-purple:focus:not(.btn-active), .btn.btn-purple:hover:not(.btn-active), .btn.btn-purple:active:not(.btn-active), .btn.btn-purple.active, .btn.btn-purple.show,
.btn.btn-purple .show, .btn.btn-purple[aria-expanded=true], [aria-expanded=true] > .btn.btn-purple {
  color: #ffffff;
  border-color: #1a16eb;
  background-color: #1a16eb;
}
.btn-check:checked + .btn.btn-purple i, .btn-check:active + .btn.btn-purple i, .btn.btn-purple:focus:not(.btn-active) i, .btn.btn-purple:hover:not(.btn-active) i, .btn.btn-purple:active:not(.btn-active) i, .btn.btn-purple.active i, .btn.btn-purple.show i,
.btn.btn-purple .show i, .btn.btn-purple[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-purple i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-purple .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-purple .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-purple:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-purple:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-purple:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-purple.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-purple.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-purple .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-purple[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-purple .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-purple .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-purple .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-purple:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-purple:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-purple:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-purple.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-purple.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-purple .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-purple[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-purple .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-purple.dropdown-toggle:after, .btn-check:active + .btn.btn-purple.dropdown-toggle:after, .btn.btn-purple:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-purple:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-purple:active:not(.btn-active).dropdown-toggle:after, .btn.btn-purple.active.dropdown-toggle:after, .btn.btn-purple.show.dropdown-toggle:after,
.btn.btn-purple .show.dropdown-toggle:after, .btn.btn-purple[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-purple.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-purple {
  color: #5F5CF1;
  border-color: rgba(95, 92, 241, 0.1);
  background-color: rgba(95, 92, 241, 0.1);
}
.btn.btn-light-purple .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.btn.btn-light-purple .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-purple.dropdown-toggle:after {
  color: #5F5CF1;
}
.btn-check:checked + .btn.btn-light-purple, .btn-check:active + .btn.btn-light-purple, .btn.btn-light-purple:focus:not(.btn-active), .btn.btn-light-purple:hover:not(.btn-active), .btn.btn-light-purple:active:not(.btn-active), .btn.btn-light-purple.active, .btn.btn-light-purple.show,
.btn.btn-light-purple .show, .btn.btn-light-purple[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-purple {
  color: #ffffff;
  border-color: #5F5CF1;
  background-color: #5F5CF1;
}
.btn-check:checked + .btn.btn-light-purple i, .btn-check:active + .btn.btn-light-purple i, .btn.btn-light-purple:focus:not(.btn-active) i, .btn.btn-light-purple:hover:not(.btn-active) i, .btn.btn-light-purple:active:not(.btn-active) i, .btn.btn-light-purple.active i, .btn.btn-light-purple.show i,
.btn.btn-light-purple .show i, .btn.btn-light-purple[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-purple i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-purple .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-purple .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-purple:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-purple:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-purple:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-purple.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-purple.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-purple .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-purple[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-purple .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-purple .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-purple .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-purple:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-purple:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-purple:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-purple.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-purple.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-purple .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-purple[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-purple .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-purple.dropdown-toggle:after, .btn-check:active + .btn.btn-light-purple.dropdown-toggle:after, .btn.btn-light-purple:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-purple:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-purple:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-purple.active.dropdown-toggle:after, .btn.btn-light-purple.show.dropdown-toggle:after,
.btn.btn-light-purple .show.dropdown-toggle:after, .btn.btn-light-purple[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-purple.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-bg-purple {
  border-color: #5F5CF1;
  background-color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-purple, .btn-check:active + .btn.btn-active-purple, .btn.btn-active-purple:focus:not(.btn-active), .btn.btn-active-purple:hover:not(.btn-active), .btn.btn-active-purple:active:not(.btn-active), .btn.btn-active-purple.active, .btn.btn-active-purple.show,
.btn.btn-active-purple .show, .btn.btn-active-purple[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-purple {
  color: #ffffff;
  border-color: #5F5CF1;
  background-color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-purple i, .btn-check:active + .btn.btn-active-purple i, .btn.btn-active-purple:focus:not(.btn-active) i, .btn.btn-active-purple:hover:not(.btn-active) i, .btn.btn-active-purple:active:not(.btn-active) i, .btn.btn-active-purple.active i, .btn.btn-active-purple.show i,
.btn.btn-active-purple .show i, .btn.btn-active-purple[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-purple i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-purple .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-purple .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-purple:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-purple:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-purple:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-purple.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-purple.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-purple .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-purple[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-purple .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-purple .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-purple .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-purple:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-purple:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-purple:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-purple.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-purple.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-purple .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-purple[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-purple .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-purple.dropdown-toggle:after, .btn-check:active + .btn.btn-active-purple.dropdown-toggle:after, .btn.btn-active-purple:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-purple:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-purple:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-purple.active.dropdown-toggle:after, .btn.btn-active-purple.show.dropdown-toggle:after,
.btn.btn-active-purple .show.dropdown-toggle:after, .btn.btn-active-purple[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-purple.dropdown-toggle:after {
  color: #ffffff;
}

.btn-check:checked + .btn.btn-active-light-purple, .btn-check:active + .btn.btn-active-light-purple, .btn.btn-active-light-purple:focus:not(.btn-active), .btn.btn-active-light-purple:hover:not(.btn-active), .btn.btn-active-light-purple:active:not(.btn-active), .btn.btn-active-light-purple.active, .btn.btn-active-light-purple.show,
.btn.btn-active-light-purple .show, .btn.btn-active-light-purple[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-purple {
  color: #5F5CF1;
  border-color: rgba(95, 92, 241, 0.1);
  background-color: rgba(95, 92, 241, 0.1);
}
.btn-check:checked + .btn.btn-active-light-purple i, .btn-check:active + .btn.btn-active-light-purple i, .btn.btn-active-light-purple:focus:not(.btn-active) i, .btn.btn-active-light-purple:hover:not(.btn-active) i, .btn.btn-active-light-purple:active:not(.btn-active) i, .btn.btn-active-light-purple.active i, .btn.btn-active-light-purple.show i,
.btn.btn-active-light-purple .show i, .btn.btn-active-light-purple[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light-purple i {
  color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-light-purple .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-purple .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-purple:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-purple:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-purple:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-purple.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-purple.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-purple .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-purple[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-purple .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-light-purple .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-purple .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-purple:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-purple:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-purple:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-purple.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-purple.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-purple .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-purple[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-purple .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-purple.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-purple.dropdown-toggle:after, .btn.btn-active-light-purple:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-purple:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-purple:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-purple.active.dropdown-toggle:after, .btn.btn-active-light-purple.show.dropdown-toggle:after,
.btn.btn-active-light-purple .show.dropdown-toggle:after, .btn.btn-active-light-purple[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light-purple.dropdown-toggle:after {
  color: #5F5CF1;
}
.btn.btn-active-light-purple.btn-outline:not(.btn-outline-default) {
  border-color: #5F5CF1 !important;
}

.btn.btn-pink {
  color: #ffffff;
  border-color: #d63384;
  background-color: #d63384;
}
.btn.btn-pink .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-pink .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-pink.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-pink, .btn-check:active + .btn.btn-pink, .btn.btn-pink:focus:not(.btn-active), .btn.btn-pink:hover:not(.btn-active), .btn.btn-pink:active:not(.btn-active), .btn.btn-pink.active, .btn.btn-pink.show,
.btn.btn-pink .show, .btn.btn-pink[aria-expanded=true], [aria-expanded=true] > .btn.btn-pink {
  color: #ffffff;
  border-color: #9d205e;
  background-color: #9d205e;
}
.btn-check:checked + .btn.btn-pink i, .btn-check:active + .btn.btn-pink i, .btn.btn-pink:focus:not(.btn-active) i, .btn.btn-pink:hover:not(.btn-active) i, .btn.btn-pink:active:not(.btn-active) i, .btn.btn-pink.active i, .btn.btn-pink.show i,
.btn.btn-pink .show i, .btn.btn-pink[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-pink i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-pink .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-pink .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-pink:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-pink:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-pink:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-pink.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-pink.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-pink .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-pink[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-pink .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-pink .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-pink .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-pink:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-pink:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-pink:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-pink.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-pink.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-pink .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-pink[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-pink .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-pink.dropdown-toggle:after, .btn-check:active + .btn.btn-pink.dropdown-toggle:after, .btn.btn-pink:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-pink:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-pink:active:not(.btn-active).dropdown-toggle:after, .btn.btn-pink.active.dropdown-toggle:after, .btn.btn-pink.show.dropdown-toggle:after,
.btn.btn-pink .show.dropdown-toggle:after, .btn.btn-pink[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-pink.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-pink {
  color: #d63384;
  border-color: rgba(214, 51, 132, 0.1);
  background-color: rgba(214, 51, 132, 0.1);
}
.btn.btn-light-pink .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #d63384;
}
.btn.btn-light-pink .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-pink.dropdown-toggle:after {
  color: #d63384;
}
.btn-check:checked + .btn.btn-light-pink, .btn-check:active + .btn.btn-light-pink, .btn.btn-light-pink:focus:not(.btn-active), .btn.btn-light-pink:hover:not(.btn-active), .btn.btn-light-pink:active:not(.btn-active), .btn.btn-light-pink.active, .btn.btn-light-pink.show,
.btn.btn-light-pink .show, .btn.btn-light-pink[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-pink {
  color: #ffffff;
  border-color: #d63384;
  background-color: #d63384;
}
.btn-check:checked + .btn.btn-light-pink i, .btn-check:active + .btn.btn-light-pink i, .btn.btn-light-pink:focus:not(.btn-active) i, .btn.btn-light-pink:hover:not(.btn-active) i, .btn.btn-light-pink:active:not(.btn-active) i, .btn.btn-light-pink.active i, .btn.btn-light-pink.show i,
.btn.btn-light-pink .show i, .btn.btn-light-pink[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-pink i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-pink .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-pink .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-pink:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-pink:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-pink:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-pink.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-pink.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-pink .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-pink[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-pink .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-pink .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-pink .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-pink:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-pink:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-pink:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-pink.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-pink.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-pink .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-pink[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-pink .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-pink.dropdown-toggle:after, .btn-check:active + .btn.btn-light-pink.dropdown-toggle:after, .btn.btn-light-pink:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-pink:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-pink:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-pink.active.dropdown-toggle:after, .btn.btn-light-pink.show.dropdown-toggle:after,
.btn.btn-light-pink .show.dropdown-toggle:after, .btn.btn-light-pink[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-pink.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-bg-pink {
  border-color: #d63384;
  background-color: #d63384;
}
.btn-check:checked + .btn.btn-active-pink, .btn-check:active + .btn.btn-active-pink, .btn.btn-active-pink:focus:not(.btn-active), .btn.btn-active-pink:hover:not(.btn-active), .btn.btn-active-pink:active:not(.btn-active), .btn.btn-active-pink.active, .btn.btn-active-pink.show,
.btn.btn-active-pink .show, .btn.btn-active-pink[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-pink {
  color: #ffffff;
  border-color: #d63384;
  background-color: #d63384;
}
.btn-check:checked + .btn.btn-active-pink i, .btn-check:active + .btn.btn-active-pink i, .btn.btn-active-pink:focus:not(.btn-active) i, .btn.btn-active-pink:hover:not(.btn-active) i, .btn.btn-active-pink:active:not(.btn-active) i, .btn.btn-active-pink.active i, .btn.btn-active-pink.show i,
.btn.btn-active-pink .show i, .btn.btn-active-pink[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-pink i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-pink .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-pink .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-pink:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-pink:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-pink:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-pink.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-pink.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-pink .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-pink[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-pink .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-pink .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-pink .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-pink:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-pink:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-pink:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-pink.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-pink.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-pink .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-pink[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-pink .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-pink.dropdown-toggle:after, .btn-check:active + .btn.btn-active-pink.dropdown-toggle:after, .btn.btn-active-pink:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-pink:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-pink:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-pink.active.dropdown-toggle:after, .btn.btn-active-pink.show.dropdown-toggle:after,
.btn.btn-active-pink .show.dropdown-toggle:after, .btn.btn-active-pink[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-pink.dropdown-toggle:after {
  color: #ffffff;
}

.btn-check:checked + .btn.btn-active-light-pink, .btn-check:active + .btn.btn-active-light-pink, .btn.btn-active-light-pink:focus:not(.btn-active), .btn.btn-active-light-pink:hover:not(.btn-active), .btn.btn-active-light-pink:active:not(.btn-active), .btn.btn-active-light-pink.active, .btn.btn-active-light-pink.show,
.btn.btn-active-light-pink .show, .btn.btn-active-light-pink[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-pink {
  color: #d63384;
  border-color: rgba(214, 51, 132, 0.1);
  background-color: rgba(214, 51, 132, 0.1);
}
.btn-check:checked + .btn.btn-active-light-pink i, .btn-check:active + .btn.btn-active-light-pink i, .btn.btn-active-light-pink:focus:not(.btn-active) i, .btn.btn-active-light-pink:hover:not(.btn-active) i, .btn.btn-active-light-pink:active:not(.btn-active) i, .btn.btn-active-light-pink.active i, .btn.btn-active-light-pink.show i,
.btn.btn-active-light-pink .show i, .btn.btn-active-light-pink[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light-pink i {
  color: #d63384;
}
.btn-check:checked + .btn.btn-active-light-pink .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-pink .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-pink:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-pink:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-pink:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-pink.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-pink.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-pink .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-pink[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-pink .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #d63384;
}
.btn-check:checked + .btn.btn-active-light-pink .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-pink .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-pink:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-pink:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-pink:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-pink.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-pink.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-pink .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-pink[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-pink .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-pink.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-pink.dropdown-toggle:after, .btn.btn-active-light-pink:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-pink:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-pink:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-pink.active.dropdown-toggle:after, .btn.btn-active-light-pink.show.dropdown-toggle:after,
.btn.btn-active-light-pink .show.dropdown-toggle:after, .btn.btn-active-light-pink[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light-pink.dropdown-toggle:after {
  color: #d63384;
}
.btn.btn-active-light-pink.btn-outline:not(.btn-outline-default) {
  border-color: #d63384 !important;
}

.btn.btn-red {
  color: #ffffff;
  border-color: #F65464;
  background-color: #F65464;
}
.btn.btn-red .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-red .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-red.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-red, .btn-check:active + .btn.btn-red, .btn.btn-red:focus:not(.btn-active), .btn.btn-red:hover:not(.btn-active), .btn.btn-red:active:not(.btn-active), .btn.btn-red.active, .btn.btn-red.show,
.btn.btn-red .show, .btn.btn-red[aria-expanded=true], [aria-expanded=true] > .btn.btn-red {
  color: #ffffff;
  border-color: #f10d23;
  background-color: #f10d23;
}
.btn-check:checked + .btn.btn-red i, .btn-check:active + .btn.btn-red i, .btn.btn-red:focus:not(.btn-active) i, .btn.btn-red:hover:not(.btn-active) i, .btn.btn-red:active:not(.btn-active) i, .btn.btn-red.active i, .btn.btn-red.show i,
.btn.btn-red .show i, .btn.btn-red[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-red i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-red .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-red .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-red:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-red:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-red:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-red.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-red.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-red .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-red[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-red .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-red .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-red .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-red:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-red:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-red:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-red.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-red.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-red .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-red[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-red .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-red.dropdown-toggle:after, .btn-check:active + .btn.btn-red.dropdown-toggle:after, .btn.btn-red:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-red:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-red:active:not(.btn-active).dropdown-toggle:after, .btn.btn-red.active.dropdown-toggle:after, .btn.btn-red.show.dropdown-toggle:after,
.btn.btn-red .show.dropdown-toggle:after, .btn.btn-red[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-red.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-red {
  color: #F65464;
  border-color: rgba(246, 84, 100, 0.1);
  background-color: rgba(246, 84, 100, 0.1);
}
.btn.btn-light-red .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.btn.btn-light-red .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-red.dropdown-toggle:after {
  color: #F65464;
}
.btn-check:checked + .btn.btn-light-red, .btn-check:active + .btn.btn-light-red, .btn.btn-light-red:focus:not(.btn-active), .btn.btn-light-red:hover:not(.btn-active), .btn.btn-light-red:active:not(.btn-active), .btn.btn-light-red.active, .btn.btn-light-red.show,
.btn.btn-light-red .show, .btn.btn-light-red[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-red {
  color: #ffffff;
  border-color: #F65464;
  background-color: #F65464;
}
.btn-check:checked + .btn.btn-light-red i, .btn-check:active + .btn.btn-light-red i, .btn.btn-light-red:focus:not(.btn-active) i, .btn.btn-light-red:hover:not(.btn-active) i, .btn.btn-light-red:active:not(.btn-active) i, .btn.btn-light-red.active i, .btn.btn-light-red.show i,
.btn.btn-light-red .show i, .btn.btn-light-red[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-red i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-red .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-red .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-red:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-red:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-red:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-red.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-red.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-red .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-red[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-red .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-red .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-red .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-red:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-red:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-red:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-red.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-red.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-red .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-red[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-red .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-red.dropdown-toggle:after, .btn-check:active + .btn.btn-light-red.dropdown-toggle:after, .btn.btn-light-red:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-red:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-red:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-red.active.dropdown-toggle:after, .btn.btn-light-red.show.dropdown-toggle:after,
.btn.btn-light-red .show.dropdown-toggle:after, .btn.btn-light-red[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-red.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-bg-red {
  border-color: #F65464;
  background-color: #F65464;
}
.btn-check:checked + .btn.btn-active-red, .btn-check:active + .btn.btn-active-red, .btn.btn-active-red:focus:not(.btn-active), .btn.btn-active-red:hover:not(.btn-active), .btn.btn-active-red:active:not(.btn-active), .btn.btn-active-red.active, .btn.btn-active-red.show,
.btn.btn-active-red .show, .btn.btn-active-red[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-red {
  color: #ffffff;
  border-color: #F65464;
  background-color: #F65464;
}
.btn-check:checked + .btn.btn-active-red i, .btn-check:active + .btn.btn-active-red i, .btn.btn-active-red:focus:not(.btn-active) i, .btn.btn-active-red:hover:not(.btn-active) i, .btn.btn-active-red:active:not(.btn-active) i, .btn.btn-active-red.active i, .btn.btn-active-red.show i,
.btn.btn-active-red .show i, .btn.btn-active-red[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-red i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-red .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-red .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-red:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-red:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-red:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-red.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-red.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-red .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-red[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-red .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-red .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-red .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-red:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-red:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-red:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-red.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-red.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-red .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-red[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-red .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-red.dropdown-toggle:after, .btn-check:active + .btn.btn-active-red.dropdown-toggle:after, .btn.btn-active-red:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-red:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-red:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-red.active.dropdown-toggle:after, .btn.btn-active-red.show.dropdown-toggle:after,
.btn.btn-active-red .show.dropdown-toggle:after, .btn.btn-active-red[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-red.dropdown-toggle:after {
  color: #ffffff;
}

.btn-check:checked + .btn.btn-active-light-red, .btn-check:active + .btn.btn-active-light-red, .btn.btn-active-light-red:focus:not(.btn-active), .btn.btn-active-light-red:hover:not(.btn-active), .btn.btn-active-light-red:active:not(.btn-active), .btn.btn-active-light-red.active, .btn.btn-active-light-red.show,
.btn.btn-active-light-red .show, .btn.btn-active-light-red[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-red {
  color: #F65464;
  border-color: rgba(246, 84, 100, 0.1);
  background-color: rgba(246, 84, 100, 0.1);
}
.btn-check:checked + .btn.btn-active-light-red i, .btn-check:active + .btn.btn-active-light-red i, .btn.btn-active-light-red:focus:not(.btn-active) i, .btn.btn-active-light-red:hover:not(.btn-active) i, .btn.btn-active-light-red:active:not(.btn-active) i, .btn.btn-active-light-red.active i, .btn.btn-active-light-red.show i,
.btn.btn-active-light-red .show i, .btn.btn-active-light-red[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light-red i {
  color: #F65464;
}
.btn-check:checked + .btn.btn-active-light-red .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-red .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-red:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-red:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-red:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-red.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-red.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-red .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-red[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-red .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.btn-check:checked + .btn.btn-active-light-red .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-red .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-red:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-red:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-red:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-red.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-red.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-red .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-red[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-red .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-red.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-red.dropdown-toggle:after, .btn.btn-active-light-red:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-red:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-red:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-red.active.dropdown-toggle:after, .btn.btn-active-light-red.show.dropdown-toggle:after,
.btn.btn-active-light-red .show.dropdown-toggle:after, .btn.btn-active-light-red[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light-red.dropdown-toggle:after {
  color: #F65464;
}
.btn.btn-active-light-red.btn-outline:not(.btn-outline-default) {
  border-color: #F65464 !important;
}

.btn.btn-orange {
  color: #ffffff;
  border-color: #fd7e14;
  background-color: #fd7e14;
}
.btn.btn-orange .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-orange .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-orange.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-orange, .btn-check:active + .btn.btn-orange, .btn.btn-orange:focus:not(.btn-active), .btn.btn-orange:hover:not(.btn-active), .btn.btn-orange:active:not(.btn-active), .btn.btn-orange.active, .btn.btn-orange.show,
.btn.btn-orange .show, .btn.btn-orange[aria-expanded=true], [aria-expanded=true] > .btn.btn-orange {
  color: #ffffff;
  border-color: #c35a02;
  background-color: #c35a02;
}
.btn-check:checked + .btn.btn-orange i, .btn-check:active + .btn.btn-orange i, .btn.btn-orange:focus:not(.btn-active) i, .btn.btn-orange:hover:not(.btn-active) i, .btn.btn-orange:active:not(.btn-active) i, .btn.btn-orange.active i, .btn.btn-orange.show i,
.btn.btn-orange .show i, .btn.btn-orange[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-orange i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-orange .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-orange .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-orange:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-orange:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-orange:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-orange.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-orange.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-orange .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-orange[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-orange .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-orange .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-orange .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-orange:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-orange:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-orange:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-orange.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-orange.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-orange .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-orange[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-orange .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-orange.dropdown-toggle:after, .btn-check:active + .btn.btn-orange.dropdown-toggle:after, .btn.btn-orange:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-orange:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-orange:active:not(.btn-active).dropdown-toggle:after, .btn.btn-orange.active.dropdown-toggle:after, .btn.btn-orange.show.dropdown-toggle:after,
.btn.btn-orange .show.dropdown-toggle:after, .btn.btn-orange[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-orange.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-orange {
  color: #fd7e14;
  border-color: rgba(253, 126, 20, 0.1);
  background-color: rgba(253, 126, 20, 0.1);
}
.btn.btn-light-orange .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #fd7e14;
}
.btn.btn-light-orange .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-orange.dropdown-toggle:after {
  color: #fd7e14;
}
.btn-check:checked + .btn.btn-light-orange, .btn-check:active + .btn.btn-light-orange, .btn.btn-light-orange:focus:not(.btn-active), .btn.btn-light-orange:hover:not(.btn-active), .btn.btn-light-orange:active:not(.btn-active), .btn.btn-light-orange.active, .btn.btn-light-orange.show,
.btn.btn-light-orange .show, .btn.btn-light-orange[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-orange {
  color: #ffffff;
  border-color: #fd7e14;
  background-color: #fd7e14;
}
.btn-check:checked + .btn.btn-light-orange i, .btn-check:active + .btn.btn-light-orange i, .btn.btn-light-orange:focus:not(.btn-active) i, .btn.btn-light-orange:hover:not(.btn-active) i, .btn.btn-light-orange:active:not(.btn-active) i, .btn.btn-light-orange.active i, .btn.btn-light-orange.show i,
.btn.btn-light-orange .show i, .btn.btn-light-orange[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-orange i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-orange .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-orange .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-orange:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-orange:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-orange:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-orange.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-orange.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-orange .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-orange[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-orange .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-orange .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-orange .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-orange:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-orange:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-orange:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-orange.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-orange.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-orange .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-orange[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-orange .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-orange.dropdown-toggle:after, .btn-check:active + .btn.btn-light-orange.dropdown-toggle:after, .btn.btn-light-orange:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-orange:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-orange:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-orange.active.dropdown-toggle:after, .btn.btn-light-orange.show.dropdown-toggle:after,
.btn.btn-light-orange .show.dropdown-toggle:after, .btn.btn-light-orange[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-orange.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-bg-orange {
  border-color: #fd7e14;
  background-color: #fd7e14;
}
.btn-check:checked + .btn.btn-active-orange, .btn-check:active + .btn.btn-active-orange, .btn.btn-active-orange:focus:not(.btn-active), .btn.btn-active-orange:hover:not(.btn-active), .btn.btn-active-orange:active:not(.btn-active), .btn.btn-active-orange.active, .btn.btn-active-orange.show,
.btn.btn-active-orange .show, .btn.btn-active-orange[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-orange {
  color: #ffffff;
  border-color: #fd7e14;
  background-color: #fd7e14;
}
.btn-check:checked + .btn.btn-active-orange i, .btn-check:active + .btn.btn-active-orange i, .btn.btn-active-orange:focus:not(.btn-active) i, .btn.btn-active-orange:hover:not(.btn-active) i, .btn.btn-active-orange:active:not(.btn-active) i, .btn.btn-active-orange.active i, .btn.btn-active-orange.show i,
.btn.btn-active-orange .show i, .btn.btn-active-orange[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-orange i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-orange .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-orange .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-orange:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-orange:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-orange:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-orange.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-orange.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-orange .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-orange[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-orange .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-orange .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-orange .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-orange:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-orange:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-orange:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-orange.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-orange.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-orange .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-orange[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-orange .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-orange.dropdown-toggle:after, .btn-check:active + .btn.btn-active-orange.dropdown-toggle:after, .btn.btn-active-orange:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-orange:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-orange:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-orange.active.dropdown-toggle:after, .btn.btn-active-orange.show.dropdown-toggle:after,
.btn.btn-active-orange .show.dropdown-toggle:after, .btn.btn-active-orange[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-orange.dropdown-toggle:after {
  color: #ffffff;
}

.btn-check:checked + .btn.btn-active-light-orange, .btn-check:active + .btn.btn-active-light-orange, .btn.btn-active-light-orange:focus:not(.btn-active), .btn.btn-active-light-orange:hover:not(.btn-active), .btn.btn-active-light-orange:active:not(.btn-active), .btn.btn-active-light-orange.active, .btn.btn-active-light-orange.show,
.btn.btn-active-light-orange .show, .btn.btn-active-light-orange[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-orange {
  color: #fd7e14;
  border-color: rgba(253, 126, 20, 0.1);
  background-color: rgba(253, 126, 20, 0.1);
}
.btn-check:checked + .btn.btn-active-light-orange i, .btn-check:active + .btn.btn-active-light-orange i, .btn.btn-active-light-orange:focus:not(.btn-active) i, .btn.btn-active-light-orange:hover:not(.btn-active) i, .btn.btn-active-light-orange:active:not(.btn-active) i, .btn.btn-active-light-orange.active i, .btn.btn-active-light-orange.show i,
.btn.btn-active-light-orange .show i, .btn.btn-active-light-orange[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light-orange i {
  color: #fd7e14;
}
.btn-check:checked + .btn.btn-active-light-orange .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-orange .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-orange:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-orange:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-orange:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-orange.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-orange.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-orange .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-orange[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-orange .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #fd7e14;
}
.btn-check:checked + .btn.btn-active-light-orange .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-orange .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-orange:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-orange:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-orange:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-orange.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-orange.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-orange .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-orange[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-orange .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-orange.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-orange.dropdown-toggle:after, .btn.btn-active-light-orange:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-orange:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-orange:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-orange.active.dropdown-toggle:after, .btn.btn-active-light-orange.show.dropdown-toggle:after,
.btn.btn-active-light-orange .show.dropdown-toggle:after, .btn.btn-active-light-orange[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light-orange.dropdown-toggle:after {
  color: #fd7e14;
}
.btn.btn-active-light-orange.btn-outline:not(.btn-outline-default) {
  border-color: #fd7e14 !important;
}

.btn.btn-yellow {
  color: #ffffff;
  border-color: #FFA800;
  background-color: #FFA800;
}
.btn.btn-yellow .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-yellow.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-yellow, .btn-check:active + .btn.btn-yellow, .btn.btn-yellow:focus:not(.btn-active), .btn.btn-yellow:hover:not(.btn-active), .btn.btn-yellow:active:not(.btn-active), .btn.btn-yellow.active, .btn.btn-yellow.show,
.btn.btn-yellow .show, .btn.btn-yellow[aria-expanded=true], [aria-expanded=true] > .btn.btn-yellow {
  color: #ffffff;
  border-color: #b37600;
  background-color: #b37600;
}
.btn-check:checked + .btn.btn-yellow i, .btn-check:active + .btn.btn-yellow i, .btn.btn-yellow:focus:not(.btn-active) i, .btn.btn-yellow:hover:not(.btn-active) i, .btn.btn-yellow:active:not(.btn-active) i, .btn.btn-yellow.active i, .btn.btn-yellow.show i,
.btn.btn-yellow .show i, .btn.btn-yellow[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-yellow i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-yellow .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-yellow .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-yellow:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-yellow:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-yellow:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-yellow.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-yellow.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-yellow .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-yellow[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-yellow .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-yellow:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-yellow:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-yellow:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-yellow.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-yellow.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-yellow .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-yellow[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-yellow.dropdown-toggle:after, .btn-check:active + .btn.btn-yellow.dropdown-toggle:after, .btn.btn-yellow:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-yellow:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-yellow:active:not(.btn-active).dropdown-toggle:after, .btn.btn-yellow.active.dropdown-toggle:after, .btn.btn-yellow.show.dropdown-toggle:after,
.btn.btn-yellow .show.dropdown-toggle:after, .btn.btn-yellow[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-yellow.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-yellow {
  color: #FFA800;
  border-color: rgba(255, 168, 0, 0.1);
  background-color: rgba(255, 168, 0, 0.1);
}
.btn.btn-light-yellow .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.btn.btn-light-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-yellow.dropdown-toggle:after {
  color: #FFA800;
}
.btn-check:checked + .btn.btn-light-yellow, .btn-check:active + .btn.btn-light-yellow, .btn.btn-light-yellow:focus:not(.btn-active), .btn.btn-light-yellow:hover:not(.btn-active), .btn.btn-light-yellow:active:not(.btn-active), .btn.btn-light-yellow.active, .btn.btn-light-yellow.show,
.btn.btn-light-yellow .show, .btn.btn-light-yellow[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-yellow {
  color: #ffffff;
  border-color: #FFA800;
  background-color: #FFA800;
}
.btn-check:checked + .btn.btn-light-yellow i, .btn-check:active + .btn.btn-light-yellow i, .btn.btn-light-yellow:focus:not(.btn-active) i, .btn.btn-light-yellow:hover:not(.btn-active) i, .btn.btn-light-yellow:active:not(.btn-active) i, .btn.btn-light-yellow.active i, .btn.btn-light-yellow.show i,
.btn.btn-light-yellow .show i, .btn.btn-light-yellow[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-yellow i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-yellow .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-yellow .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-yellow:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-yellow:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-yellow:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-yellow.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-yellow.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-yellow .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-yellow[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-yellow .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-yellow:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-yellow:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-yellow:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-yellow.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-yellow.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-yellow .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-yellow[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-yellow.dropdown-toggle:after, .btn-check:active + .btn.btn-light-yellow.dropdown-toggle:after, .btn.btn-light-yellow:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-yellow:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-yellow:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-yellow.active.dropdown-toggle:after, .btn.btn-light-yellow.show.dropdown-toggle:after,
.btn.btn-light-yellow .show.dropdown-toggle:after, .btn.btn-light-yellow[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-yellow.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-bg-yellow {
  border-color: #FFA800;
  background-color: #FFA800;
}
.btn-check:checked + .btn.btn-active-yellow, .btn-check:active + .btn.btn-active-yellow, .btn.btn-active-yellow:focus:not(.btn-active), .btn.btn-active-yellow:hover:not(.btn-active), .btn.btn-active-yellow:active:not(.btn-active), .btn.btn-active-yellow.active, .btn.btn-active-yellow.show,
.btn.btn-active-yellow .show, .btn.btn-active-yellow[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-yellow {
  color: #ffffff;
  border-color: #FFA800;
  background-color: #FFA800;
}
.btn-check:checked + .btn.btn-active-yellow i, .btn-check:active + .btn.btn-active-yellow i, .btn.btn-active-yellow:focus:not(.btn-active) i, .btn.btn-active-yellow:hover:not(.btn-active) i, .btn.btn-active-yellow:active:not(.btn-active) i, .btn.btn-active-yellow.active i, .btn.btn-active-yellow.show i,
.btn.btn-active-yellow .show i, .btn.btn-active-yellow[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-yellow i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-yellow .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-yellow .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-yellow:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-yellow:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-yellow:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-yellow.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-yellow.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-yellow .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-yellow[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-yellow .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-yellow:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-yellow:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-yellow:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-yellow.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-yellow.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-yellow .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-yellow[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-yellow.dropdown-toggle:after, .btn-check:active + .btn.btn-active-yellow.dropdown-toggle:after, .btn.btn-active-yellow:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-yellow:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-yellow:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-yellow.active.dropdown-toggle:after, .btn.btn-active-yellow.show.dropdown-toggle:after,
.btn.btn-active-yellow .show.dropdown-toggle:after, .btn.btn-active-yellow[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-yellow.dropdown-toggle:after {
  color: #ffffff;
}

.btn-check:checked + .btn.btn-active-light-yellow, .btn-check:active + .btn.btn-active-light-yellow, .btn.btn-active-light-yellow:focus:not(.btn-active), .btn.btn-active-light-yellow:hover:not(.btn-active), .btn.btn-active-light-yellow:active:not(.btn-active), .btn.btn-active-light-yellow.active, .btn.btn-active-light-yellow.show,
.btn.btn-active-light-yellow .show, .btn.btn-active-light-yellow[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-yellow {
  color: #FFA800;
  border-color: rgba(255, 168, 0, 0.1);
  background-color: rgba(255, 168, 0, 0.1);
}
.btn-check:checked + .btn.btn-active-light-yellow i, .btn-check:active + .btn.btn-active-light-yellow i, .btn.btn-active-light-yellow:focus:not(.btn-active) i, .btn.btn-active-light-yellow:hover:not(.btn-active) i, .btn.btn-active-light-yellow:active:not(.btn-active) i, .btn.btn-active-light-yellow.active i, .btn.btn-active-light-yellow.show i,
.btn.btn-active-light-yellow .show i, .btn.btn-active-light-yellow[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light-yellow i {
  color: #FFA800;
}
.btn-check:checked + .btn.btn-active-light-yellow .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-yellow .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-yellow:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-yellow:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-yellow:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-yellow.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-yellow.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-yellow .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-yellow[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-yellow .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.btn-check:checked + .btn.btn-active-light-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-yellow:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-yellow:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-yellow:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-yellow.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-yellow.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-yellow .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-yellow[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-yellow.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-yellow.dropdown-toggle:after, .btn.btn-active-light-yellow:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-yellow:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-yellow:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-yellow.active.dropdown-toggle:after, .btn.btn-active-light-yellow.show.dropdown-toggle:after,
.btn.btn-active-light-yellow .show.dropdown-toggle:after, .btn.btn-active-light-yellow[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light-yellow.dropdown-toggle:after {
  color: #FFA800;
}
.btn.btn-active-light-yellow.btn-outline:not(.btn-outline-default) {
  border-color: #FFA800 !important;
}

.btn.btn-green {
  color: #ffffff;
  border-color: #1BC5BC;
  background-color: #1BC5BC;
}
.btn.btn-green .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-green .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-green.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-green, .btn-check:active + .btn.btn-green, .btn.btn-green:focus:not(.btn-active), .btn.btn-green:hover:not(.btn-active), .btn.btn-green:active:not(.btn-active), .btn.btn-green.active, .btn.btn-green.show,
.btn.btn-green .show, .btn.btn-green[aria-expanded=true], [aria-expanded=true] > .btn.btn-green {
  color: #ffffff;
  border-color: #12827c;
  background-color: #12827c;
}
.btn-check:checked + .btn.btn-green i, .btn-check:active + .btn.btn-green i, .btn.btn-green:focus:not(.btn-active) i, .btn.btn-green:hover:not(.btn-active) i, .btn.btn-green:active:not(.btn-active) i, .btn.btn-green.active i, .btn.btn-green.show i,
.btn.btn-green .show i, .btn.btn-green[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-green i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-green .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-green .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-green:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-green:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-green:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-green.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-green.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-green .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-green[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-green .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-green .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-green .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-green:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-green:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-green:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-green.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-green.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-green .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-green[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-green .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-green.dropdown-toggle:after, .btn-check:active + .btn.btn-green.dropdown-toggle:after, .btn.btn-green:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-green:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-green:active:not(.btn-active).dropdown-toggle:after, .btn.btn-green.active.dropdown-toggle:after, .btn.btn-green.show.dropdown-toggle:after,
.btn.btn-green .show.dropdown-toggle:after, .btn.btn-green[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-green.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-green {
  color: #1BC5BC;
  border-color: rgba(27, 197, 188, 0.1);
  background-color: rgba(27, 197, 188, 0.1);
}
.btn.btn-light-green .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.btn.btn-light-green .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-green.dropdown-toggle:after {
  color: #1BC5BC;
}
.btn-check:checked + .btn.btn-light-green, .btn-check:active + .btn.btn-light-green, .btn.btn-light-green:focus:not(.btn-active), .btn.btn-light-green:hover:not(.btn-active), .btn.btn-light-green:active:not(.btn-active), .btn.btn-light-green.active, .btn.btn-light-green.show,
.btn.btn-light-green .show, .btn.btn-light-green[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-green {
  color: #ffffff;
  border-color: #1BC5BC;
  background-color: #1BC5BC;
}
.btn-check:checked + .btn.btn-light-green i, .btn-check:active + .btn.btn-light-green i, .btn.btn-light-green:focus:not(.btn-active) i, .btn.btn-light-green:hover:not(.btn-active) i, .btn.btn-light-green:active:not(.btn-active) i, .btn.btn-light-green.active i, .btn.btn-light-green.show i,
.btn.btn-light-green .show i, .btn.btn-light-green[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-green i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-green .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-green .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-green:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-green:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-green:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-green.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-green.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-green .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-green[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-green .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-green .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-green .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-green:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-green:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-green:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-green.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-green.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-green .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-green[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-green .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-green.dropdown-toggle:after, .btn-check:active + .btn.btn-light-green.dropdown-toggle:after, .btn.btn-light-green:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-green:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-green:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-green.active.dropdown-toggle:after, .btn.btn-light-green.show.dropdown-toggle:after,
.btn.btn-light-green .show.dropdown-toggle:after, .btn.btn-light-green[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-green.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-bg-green {
  border-color: #1BC5BC;
  background-color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-green, .btn-check:active + .btn.btn-active-green, .btn.btn-active-green:focus:not(.btn-active), .btn.btn-active-green:hover:not(.btn-active), .btn.btn-active-green:active:not(.btn-active), .btn.btn-active-green.active, .btn.btn-active-green.show,
.btn.btn-active-green .show, .btn.btn-active-green[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-green {
  color: #ffffff;
  border-color: #1BC5BC;
  background-color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-green i, .btn-check:active + .btn.btn-active-green i, .btn.btn-active-green:focus:not(.btn-active) i, .btn.btn-active-green:hover:not(.btn-active) i, .btn.btn-active-green:active:not(.btn-active) i, .btn.btn-active-green.active i, .btn.btn-active-green.show i,
.btn.btn-active-green .show i, .btn.btn-active-green[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-green i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-green .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-green .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-green:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-green:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-green:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-green.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-green.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-green .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-green[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-green .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-green .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-green .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-green:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-green:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-green:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-green.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-green.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-green .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-green[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-green .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-green.dropdown-toggle:after, .btn-check:active + .btn.btn-active-green.dropdown-toggle:after, .btn.btn-active-green:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-green:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-green:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-green.active.dropdown-toggle:after, .btn.btn-active-green.show.dropdown-toggle:after,
.btn.btn-active-green .show.dropdown-toggle:after, .btn.btn-active-green[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-green.dropdown-toggle:after {
  color: #ffffff;
}

.btn-check:checked + .btn.btn-active-light-green, .btn-check:active + .btn.btn-active-light-green, .btn.btn-active-light-green:focus:not(.btn-active), .btn.btn-active-light-green:hover:not(.btn-active), .btn.btn-active-light-green:active:not(.btn-active), .btn.btn-active-light-green.active, .btn.btn-active-light-green.show,
.btn.btn-active-light-green .show, .btn.btn-active-light-green[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-green {
  color: #1BC5BC;
  border-color: rgba(27, 197, 188, 0.1);
  background-color: rgba(27, 197, 188, 0.1);
}
.btn-check:checked + .btn.btn-active-light-green i, .btn-check:active + .btn.btn-active-light-green i, .btn.btn-active-light-green:focus:not(.btn-active) i, .btn.btn-active-light-green:hover:not(.btn-active) i, .btn.btn-active-light-green:active:not(.btn-active) i, .btn.btn-active-light-green.active i, .btn.btn-active-light-green.show i,
.btn.btn-active-light-green .show i, .btn.btn-active-light-green[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light-green i {
  color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-light-green .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-green .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-green:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-green:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-green:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-green.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-green.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-green .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-green[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-green .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-light-green .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-green .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-green:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-green:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-green:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-green.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-green.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-green .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-green[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-green .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-green.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-green.dropdown-toggle:after, .btn.btn-active-light-green:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-green:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-green:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-green.active.dropdown-toggle:after, .btn.btn-active-light-green.show.dropdown-toggle:after,
.btn.btn-active-light-green .show.dropdown-toggle:after, .btn.btn-active-light-green[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light-green.dropdown-toggle:after {
  color: #1BC5BC;
}
.btn.btn-active-light-green.btn-outline:not(.btn-outline-default) {
  border-color: #1BC5BC !important;
}

.btn.btn-teal {
  color: #ffffff;
  border-color: #20c997;
  background-color: #20c997;
}
.btn.btn-teal .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-teal .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-teal.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-teal, .btn-check:active + .btn.btn-teal, .btn.btn-teal:focus:not(.btn-active), .btn.btn-teal:hover:not(.btn-active), .btn.btn-teal:active:not(.btn-active), .btn.btn-teal.active, .btn.btn-teal.show,
.btn.btn-teal .show, .btn.btn-teal[aria-expanded=true], [aria-expanded=true] > .btn.btn-teal {
  color: #ffffff;
  border-color: #158765;
  background-color: #158765;
}
.btn-check:checked + .btn.btn-teal i, .btn-check:active + .btn.btn-teal i, .btn.btn-teal:focus:not(.btn-active) i, .btn.btn-teal:hover:not(.btn-active) i, .btn.btn-teal:active:not(.btn-active) i, .btn.btn-teal.active i, .btn.btn-teal.show i,
.btn.btn-teal .show i, .btn.btn-teal[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-teal i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-teal .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-teal .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-teal:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-teal:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-teal:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-teal.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-teal.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-teal .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-teal[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-teal .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-teal .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-teal .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-teal:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-teal:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-teal:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-teal.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-teal.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-teal .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-teal[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-teal .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-teal.dropdown-toggle:after, .btn-check:active + .btn.btn-teal.dropdown-toggle:after, .btn.btn-teal:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-teal:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-teal:active:not(.btn-active).dropdown-toggle:after, .btn.btn-teal.active.dropdown-toggle:after, .btn.btn-teal.show.dropdown-toggle:after,
.btn.btn-teal .show.dropdown-toggle:after, .btn.btn-teal[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-teal.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-teal {
  color: #20c997;
  border-color: rgba(32, 201, 151, 0.1);
  background-color: rgba(32, 201, 151, 0.1);
}
.btn.btn-light-teal .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #20c997;
}
.btn.btn-light-teal .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-teal.dropdown-toggle:after {
  color: #20c997;
}
.btn-check:checked + .btn.btn-light-teal, .btn-check:active + .btn.btn-light-teal, .btn.btn-light-teal:focus:not(.btn-active), .btn.btn-light-teal:hover:not(.btn-active), .btn.btn-light-teal:active:not(.btn-active), .btn.btn-light-teal.active, .btn.btn-light-teal.show,
.btn.btn-light-teal .show, .btn.btn-light-teal[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-teal {
  color: #ffffff;
  border-color: #20c997;
  background-color: #20c997;
}
.btn-check:checked + .btn.btn-light-teal i, .btn-check:active + .btn.btn-light-teal i, .btn.btn-light-teal:focus:not(.btn-active) i, .btn.btn-light-teal:hover:not(.btn-active) i, .btn.btn-light-teal:active:not(.btn-active) i, .btn.btn-light-teal.active i, .btn.btn-light-teal.show i,
.btn.btn-light-teal .show i, .btn.btn-light-teal[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-teal i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-teal .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-teal .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-teal:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-teal:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-teal:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-teal.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-teal.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-teal .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-teal[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-teal .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-teal .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-teal .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-teal:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-teal:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-teal:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-teal.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-teal.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-teal .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-teal[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-teal .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-teal.dropdown-toggle:after, .btn-check:active + .btn.btn-light-teal.dropdown-toggle:after, .btn.btn-light-teal:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-teal:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-teal:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-teal.active.dropdown-toggle:after, .btn.btn-light-teal.show.dropdown-toggle:after,
.btn.btn-light-teal .show.dropdown-toggle:after, .btn.btn-light-teal[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-teal.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-bg-teal {
  border-color: #20c997;
  background-color: #20c997;
}
.btn-check:checked + .btn.btn-active-teal, .btn-check:active + .btn.btn-active-teal, .btn.btn-active-teal:focus:not(.btn-active), .btn.btn-active-teal:hover:not(.btn-active), .btn.btn-active-teal:active:not(.btn-active), .btn.btn-active-teal.active, .btn.btn-active-teal.show,
.btn.btn-active-teal .show, .btn.btn-active-teal[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-teal {
  color: #ffffff;
  border-color: #20c997;
  background-color: #20c997;
}
.btn-check:checked + .btn.btn-active-teal i, .btn-check:active + .btn.btn-active-teal i, .btn.btn-active-teal:focus:not(.btn-active) i, .btn.btn-active-teal:hover:not(.btn-active) i, .btn.btn-active-teal:active:not(.btn-active) i, .btn.btn-active-teal.active i, .btn.btn-active-teal.show i,
.btn.btn-active-teal .show i, .btn.btn-active-teal[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-teal i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-teal .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-teal .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-teal:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-teal:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-teal:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-teal.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-teal.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-teal .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-teal[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-teal .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-teal .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-teal .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-teal:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-teal:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-teal:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-teal.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-teal.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-teal .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-teal[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-teal .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-teal.dropdown-toggle:after, .btn-check:active + .btn.btn-active-teal.dropdown-toggle:after, .btn.btn-active-teal:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-teal:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-teal:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-teal.active.dropdown-toggle:after, .btn.btn-active-teal.show.dropdown-toggle:after,
.btn.btn-active-teal .show.dropdown-toggle:after, .btn.btn-active-teal[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-teal.dropdown-toggle:after {
  color: #ffffff;
}

.btn-check:checked + .btn.btn-active-light-teal, .btn-check:active + .btn.btn-active-light-teal, .btn.btn-active-light-teal:focus:not(.btn-active), .btn.btn-active-light-teal:hover:not(.btn-active), .btn.btn-active-light-teal:active:not(.btn-active), .btn.btn-active-light-teal.active, .btn.btn-active-light-teal.show,
.btn.btn-active-light-teal .show, .btn.btn-active-light-teal[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-teal {
  color: #20c997;
  border-color: rgba(32, 201, 151, 0.1);
  background-color: rgba(32, 201, 151, 0.1);
}
.btn-check:checked + .btn.btn-active-light-teal i, .btn-check:active + .btn.btn-active-light-teal i, .btn.btn-active-light-teal:focus:not(.btn-active) i, .btn.btn-active-light-teal:hover:not(.btn-active) i, .btn.btn-active-light-teal:active:not(.btn-active) i, .btn.btn-active-light-teal.active i, .btn.btn-active-light-teal.show i,
.btn.btn-active-light-teal .show i, .btn.btn-active-light-teal[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light-teal i {
  color: #20c997;
}
.btn-check:checked + .btn.btn-active-light-teal .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-teal .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-teal:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-teal:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-teal:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-teal.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-teal.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-teal .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-teal[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-teal .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #20c997;
}
.btn-check:checked + .btn.btn-active-light-teal .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-teal .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-teal:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-teal:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-teal:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-teal.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-teal.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-teal .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-teal[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-teal .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-teal.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-teal.dropdown-toggle:after, .btn.btn-active-light-teal:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-teal:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-teal:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-teal.active.dropdown-toggle:after, .btn.btn-active-light-teal.show.dropdown-toggle:after,
.btn.btn-active-light-teal .show.dropdown-toggle:after, .btn.btn-active-light-teal[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light-teal.dropdown-toggle:after {
  color: #20c997;
}
.btn.btn-active-light-teal.btn-outline:not(.btn-outline-default) {
  border-color: #20c997 !important;
}

.btn.btn-cyan {
  color: #ffffff;
  border-color: #0dcaf0;
  background-color: #0dcaf0;
}
.btn.btn-cyan .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-cyan.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-cyan, .btn-check:active + .btn.btn-cyan, .btn.btn-cyan:focus:not(.btn-active), .btn.btn-cyan:hover:not(.btn-active), .btn.btn-cyan:active:not(.btn-active), .btn.btn-cyan.active, .btn.btn-cyan.show,
.btn.btn-cyan .show, .btn.btn-cyan[aria-expanded=true], [aria-expanded=true] > .btn.btn-cyan {
  color: #ffffff;
  border-color: #098da7;
  background-color: #098da7;
}
.btn-check:checked + .btn.btn-cyan i, .btn-check:active + .btn.btn-cyan i, .btn.btn-cyan:focus:not(.btn-active) i, .btn.btn-cyan:hover:not(.btn-active) i, .btn.btn-cyan:active:not(.btn-active) i, .btn.btn-cyan.active i, .btn.btn-cyan.show i,
.btn.btn-cyan .show i, .btn.btn-cyan[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-cyan i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-cyan .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-cyan .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-cyan:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-cyan:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-cyan:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-cyan.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-cyan.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-cyan .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-cyan[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-cyan .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-cyan:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-cyan:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-cyan:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-cyan.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-cyan.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-cyan .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-cyan[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-cyan.dropdown-toggle:after, .btn-check:active + .btn.btn-cyan.dropdown-toggle:after, .btn.btn-cyan:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-cyan:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-cyan:active:not(.btn-active).dropdown-toggle:after, .btn.btn-cyan.active.dropdown-toggle:after, .btn.btn-cyan.show.dropdown-toggle:after,
.btn.btn-cyan .show.dropdown-toggle:after, .btn.btn-cyan[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-cyan.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-light-cyan {
  color: #0dcaf0;
  border-color: rgba(13, 202, 240, 0.1);
  background-color: rgba(13, 202, 240, 0.1);
}
.btn.btn-light-cyan .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #0dcaf0;
}
.btn.btn-light-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-cyan.dropdown-toggle:after {
  color: #0dcaf0;
}
.btn-check:checked + .btn.btn-light-cyan, .btn-check:active + .btn.btn-light-cyan, .btn.btn-light-cyan:focus:not(.btn-active), .btn.btn-light-cyan:hover:not(.btn-active), .btn.btn-light-cyan:active:not(.btn-active), .btn.btn-light-cyan.active, .btn.btn-light-cyan.show,
.btn.btn-light-cyan .show, .btn.btn-light-cyan[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-cyan {
  color: #ffffff;
  border-color: #0dcaf0;
  background-color: #0dcaf0;
}
.btn-check:checked + .btn.btn-light-cyan i, .btn-check:active + .btn.btn-light-cyan i, .btn.btn-light-cyan:focus:not(.btn-active) i, .btn.btn-light-cyan:hover:not(.btn-active) i, .btn.btn-light-cyan:active:not(.btn-active) i, .btn.btn-light-cyan.active i, .btn.btn-light-cyan.show i,
.btn.btn-light-cyan .show i, .btn.btn-light-cyan[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-cyan i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-cyan .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-cyan .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-cyan:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-cyan:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-cyan:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-cyan.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-cyan.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-cyan .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-cyan[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-cyan .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-cyan:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-cyan:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-cyan:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-cyan.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-cyan.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-cyan .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-cyan[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-cyan.dropdown-toggle:after, .btn-check:active + .btn.btn-light-cyan.dropdown-toggle:after, .btn.btn-light-cyan:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-cyan:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-cyan:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-cyan.active.dropdown-toggle:after, .btn.btn-light-cyan.show.dropdown-toggle:after,
.btn.btn-light-cyan .show.dropdown-toggle:after, .btn.btn-light-cyan[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-cyan.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-bg-cyan {
  border-color: #0dcaf0;
  background-color: #0dcaf0;
}
.btn-check:checked + .btn.btn-active-cyan, .btn-check:active + .btn.btn-active-cyan, .btn.btn-active-cyan:focus:not(.btn-active), .btn.btn-active-cyan:hover:not(.btn-active), .btn.btn-active-cyan:active:not(.btn-active), .btn.btn-active-cyan.active, .btn.btn-active-cyan.show,
.btn.btn-active-cyan .show, .btn.btn-active-cyan[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-cyan {
  color: #ffffff;
  border-color: #0dcaf0;
  background-color: #0dcaf0;
}
.btn-check:checked + .btn.btn-active-cyan i, .btn-check:active + .btn.btn-active-cyan i, .btn.btn-active-cyan:focus:not(.btn-active) i, .btn.btn-active-cyan:hover:not(.btn-active) i, .btn.btn-active-cyan:active:not(.btn-active) i, .btn.btn-active-cyan.active i, .btn.btn-active-cyan.show i,
.btn.btn-active-cyan .show i, .btn.btn-active-cyan[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-cyan i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-cyan .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-cyan .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-cyan:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-cyan:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-cyan:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-cyan.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-cyan.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-cyan .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-cyan[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-cyan .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-cyan:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-cyan:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-cyan:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-cyan.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-cyan.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-cyan .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-cyan[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-cyan.dropdown-toggle:after, .btn-check:active + .btn.btn-active-cyan.dropdown-toggle:after, .btn.btn-active-cyan:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-cyan:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-cyan:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-cyan.active.dropdown-toggle:after, .btn.btn-active-cyan.show.dropdown-toggle:after,
.btn.btn-active-cyan .show.dropdown-toggle:after, .btn.btn-active-cyan[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-cyan.dropdown-toggle:after {
  color: #ffffff;
}

.btn-check:checked + .btn.btn-active-light-cyan, .btn-check:active + .btn.btn-active-light-cyan, .btn.btn-active-light-cyan:focus:not(.btn-active), .btn.btn-active-light-cyan:hover:not(.btn-active), .btn.btn-active-light-cyan:active:not(.btn-active), .btn.btn-active-light-cyan.active, .btn.btn-active-light-cyan.show,
.btn.btn-active-light-cyan .show, .btn.btn-active-light-cyan[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-cyan {
  color: #0dcaf0;
  border-color: rgba(13, 202, 240, 0.1);
  background-color: rgba(13, 202, 240, 0.1);
}
.btn-check:checked + .btn.btn-active-light-cyan i, .btn-check:active + .btn.btn-active-light-cyan i, .btn.btn-active-light-cyan:focus:not(.btn-active) i, .btn.btn-active-light-cyan:hover:not(.btn-active) i, .btn.btn-active-light-cyan:active:not(.btn-active) i, .btn.btn-active-light-cyan.active i, .btn.btn-active-light-cyan.show i,
.btn.btn-active-light-cyan .show i, .btn.btn-active-light-cyan[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light-cyan i {
  color: #0dcaf0;
}
.btn-check:checked + .btn.btn-active-light-cyan .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-cyan .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-cyan:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-cyan:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-cyan:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-cyan.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-cyan.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-cyan .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-cyan[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-cyan .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #0dcaf0;
}
.btn-check:checked + .btn.btn-active-light-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-cyan:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-cyan:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-cyan:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-cyan.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-cyan.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-cyan .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-cyan[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-cyan.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-cyan.dropdown-toggle:after, .btn.btn-active-light-cyan:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-cyan:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-cyan:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-cyan.active.dropdown-toggle:after, .btn.btn-active-light-cyan.show.dropdown-toggle:after,
.btn.btn-active-light-cyan .show.dropdown-toggle:after, .btn.btn-active-light-cyan[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light-cyan.dropdown-toggle:after {
  color: #0dcaf0;
}
.btn.btn-active-light-cyan.btn-outline:not(.btn-outline-default) {
  border-color: #0dcaf0 !important;
}

.btn.btn-gray {
  color: #b5b5c3;
  border-color: #7e8299;
  background-color: #7e8299;
}
.btn.btn-gray .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.btn.btn-gray .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-gray.dropdown-toggle:after {
  color: #b5b5c3;
}
.btn-check:checked + .btn.btn-gray, .btn-check:active + .btn.btn-gray, .btn.btn-gray:focus:not(.btn-active), .btn.btn-gray:hover:not(.btn-active), .btn.btn-gray:active:not(.btn-active), .btn.btn-gray.active, .btn.btn-gray.show,
.btn.btn-gray .show, .btn.btn-gray[aria-expanded=true], [aria-expanded=true] > .btn.btn-gray {
  color: #b5b5c3;
  border-color: #3f4254;
  background-color: #3f4254;
}
.btn-check:checked + .btn.btn-gray i, .btn-check:active + .btn.btn-gray i, .btn.btn-gray:focus:not(.btn-active) i, .btn.btn-gray:hover:not(.btn-active) i, .btn.btn-gray:active:not(.btn-active) i, .btn.btn-gray.active i, .btn.btn-gray.show i,
.btn.btn-gray .show i, .btn.btn-gray[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-gray i {
  color: #b5b5c3;
}
.btn-check:checked + .btn.btn-gray .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-gray .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-gray:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-gray:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-gray:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-gray.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-gray.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-gray .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-gray[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-gray .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.btn-check:checked + .btn.btn-gray .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-gray .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-gray:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-gray:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-gray:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-gray.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-gray.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-gray .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-gray[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-gray .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-gray.dropdown-toggle:after, .btn-check:active + .btn.btn-gray.dropdown-toggle:after, .btn.btn-gray:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-gray:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-gray:active:not(.btn-active).dropdown-toggle:after, .btn.btn-gray.active.dropdown-toggle:after, .btn.btn-gray.show.dropdown-toggle:after,
.btn.btn-gray .show.dropdown-toggle:after, .btn.btn-gray[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-gray.dropdown-toggle:after {
  color: #b5b5c3;
}

.btn.btn-light-gray {
  color: #7e8299;
  border-color: rgba(126, 130, 153, 0.1);
  background-color: rgba(126, 130, 153, 0.1);
}
.btn.btn-light-gray .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.btn.btn-light-gray .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-gray.dropdown-toggle:after {
  color: #7e8299;
}
.btn-check:checked + .btn.btn-light-gray, .btn-check:active + .btn.btn-light-gray, .btn.btn-light-gray:focus:not(.btn-active), .btn.btn-light-gray:hover:not(.btn-active), .btn.btn-light-gray:active:not(.btn-active), .btn.btn-light-gray.active, .btn.btn-light-gray.show,
.btn.btn-light-gray .show, .btn.btn-light-gray[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-gray {
  color: #b5b5c3;
  border-color: #7e8299;
  background-color: #7e8299;
}
.btn-check:checked + .btn.btn-light-gray i, .btn-check:active + .btn.btn-light-gray i, .btn.btn-light-gray:focus:not(.btn-active) i, .btn.btn-light-gray:hover:not(.btn-active) i, .btn.btn-light-gray:active:not(.btn-active) i, .btn.btn-light-gray.active i, .btn.btn-light-gray.show i,
.btn.btn-light-gray .show i, .btn.btn-light-gray[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-gray i {
  color: #b5b5c3;
}
.btn-check:checked + .btn.btn-light-gray .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-gray .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-gray:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-gray:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-gray:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-gray.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-gray.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-gray .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-gray[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-gray .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.btn-check:checked + .btn.btn-light-gray .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-gray .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-gray:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-gray:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-gray:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-gray.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-gray.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-gray .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-gray[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-gray .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-gray.dropdown-toggle:after, .btn-check:active + .btn.btn-light-gray.dropdown-toggle:after, .btn.btn-light-gray:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-gray:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-gray:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-gray.active.dropdown-toggle:after, .btn.btn-light-gray.show.dropdown-toggle:after,
.btn.btn-light-gray .show.dropdown-toggle:after, .btn.btn-light-gray[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-gray.dropdown-toggle:after {
  color: #b5b5c3;
}

.btn.btn-bg-gray {
  border-color: #7e8299;
  background-color: #7e8299;
}
.btn-check:checked + .btn.btn-active-gray, .btn-check:active + .btn.btn-active-gray, .btn.btn-active-gray:focus:not(.btn-active), .btn.btn-active-gray:hover:not(.btn-active), .btn.btn-active-gray:active:not(.btn-active), .btn.btn-active-gray.active, .btn.btn-active-gray.show,
.btn.btn-active-gray .show, .btn.btn-active-gray[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-gray {
  color: #b5b5c3;
  border-color: #7e8299;
  background-color: #7e8299;
}
.btn-check:checked + .btn.btn-active-gray i, .btn-check:active + .btn.btn-active-gray i, .btn.btn-active-gray:focus:not(.btn-active) i, .btn.btn-active-gray:hover:not(.btn-active) i, .btn.btn-active-gray:active:not(.btn-active) i, .btn.btn-active-gray.active i, .btn.btn-active-gray.show i,
.btn.btn-active-gray .show i, .btn.btn-active-gray[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-gray i {
  color: #b5b5c3;
}
.btn-check:checked + .btn.btn-active-gray .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-gray .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-gray:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-gray:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-gray:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-gray.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-gray.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-gray .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-gray[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-gray .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.btn-check:checked + .btn.btn-active-gray .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-gray .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-gray:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-gray:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-gray:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-gray.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-gray.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-gray .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-gray[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-gray .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-gray.dropdown-toggle:after, .btn-check:active + .btn.btn-active-gray.dropdown-toggle:after, .btn.btn-active-gray:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-gray:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-gray:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-gray.active.dropdown-toggle:after, .btn.btn-active-gray.show.dropdown-toggle:after,
.btn.btn-active-gray .show.dropdown-toggle:after, .btn.btn-active-gray[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-gray.dropdown-toggle:after {
  color: #b5b5c3;
}

.btn-check:checked + .btn.btn-active-light-gray, .btn-check:active + .btn.btn-active-light-gray, .btn.btn-active-light-gray:focus:not(.btn-active), .btn.btn-active-light-gray:hover:not(.btn-active), .btn.btn-active-light-gray:active:not(.btn-active), .btn.btn-active-light-gray.active, .btn.btn-active-light-gray.show,
.btn.btn-active-light-gray .show, .btn.btn-active-light-gray[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-light-gray {
  color: #7e8299;
  border-color: rgba(126, 130, 153, 0.1);
  background-color: rgba(126, 130, 153, 0.1);
}
.btn-check:checked + .btn.btn-active-light-gray i, .btn-check:active + .btn.btn-active-light-gray i, .btn.btn-active-light-gray:focus:not(.btn-active) i, .btn.btn-active-light-gray:hover:not(.btn-active) i, .btn.btn-active-light-gray:active:not(.btn-active) i, .btn.btn-active-light-gray.active i, .btn.btn-active-light-gray.show i,
.btn.btn-active-light-gray .show i, .btn.btn-active-light-gray[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-light-gray i {
  color: #7e8299;
}
.btn-check:checked + .btn.btn-active-light-gray .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-gray .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-gray:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-gray:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-gray:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-gray.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-gray.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-gray .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-gray[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-gray .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.btn-check:checked + .btn.btn-active-light-gray .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-light-gray .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-gray:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-gray:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-gray:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-gray.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-gray.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-gray .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-light-gray[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-light-gray .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-light-gray.dropdown-toggle:after, .btn-check:active + .btn.btn-active-light-gray.dropdown-toggle:after, .btn.btn-active-light-gray:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-gray:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-gray:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-light-gray.active.dropdown-toggle:after, .btn.btn-active-light-gray.show.dropdown-toggle:after,
.btn.btn-active-light-gray .show.dropdown-toggle:after, .btn.btn-active-light-gray[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-light-gray.dropdown-toggle:after {
  color: #7e8299;
}
.btn.btn-active-light-gray.btn-outline:not(.btn-outline-default) {
  border-color: #7e8299 !important;
}

.btn.btn-body-bg {
  border-color: #f5f8fa;
  background-color: #f5f8fa;
}
.btn.btn-bg-body-bg {
  border-color: #f5f8fa;
  background-color: #f5f8fa;
}
.btn-check:checked + .btn.btn-active-body-bg, .btn-check:active + .btn.btn-active-body-bg, .btn.btn-active-body-bg:focus:not(.btn-active), .btn.btn-active-body-bg:hover:not(.btn-active), .btn.btn-active-body-bg:active:not(.btn-active), .btn.btn-active-body-bg.active, .btn.btn-active-body-bg.show,
.btn.btn-active-body-bg .show, .btn.btn-active-body-bg[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-body-bg {
  border-color: #f5f8fa;
  background-color: #f5f8fa;
}

.btn.btn-body-text {
  border-color: #151521;
  background-color: #151521;
}
.btn.btn-bg-body-text {
  border-color: #151521;
  background-color: #151521;
}
.btn-check:checked + .btn.btn-active-body-text, .btn-check:active + .btn.btn-active-body-text, .btn.btn-active-body-text:focus:not(.btn-active), .btn.btn-active-body-text:hover:not(.btn-active), .btn.btn-active-body-text:active:not(.btn-active), .btn.btn-active-body-text.active, .btn.btn-active-body-text.show,
.btn.btn-active-body-text .show, .btn.btn-active-body-text[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-body-text {
  border-color: #151521;
  background-color: #151521;
}

.btn.btn-toolbar-bg {
  border-color: #ffffff;
  background-color: #ffffff;
}
.btn.btn-bg-toolbar-bg {
  border-color: #ffffff;
  background-color: #ffffff;
}
.btn-check:checked + .btn.btn-active-toolbar-bg, .btn-check:active + .btn.btn-active-toolbar-bg, .btn.btn-active-toolbar-bg:focus:not(.btn-active), .btn.btn-active-toolbar-bg:hover:not(.btn-active), .btn.btn-active-toolbar-bg:active:not(.btn-active), .btn.btn-active-toolbar-bg.active, .btn.btn-active-toolbar-bg.show,
.btn.btn-active-toolbar-bg .show, .btn.btn-active-toolbar-bg[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-toolbar-bg {
  border-color: #ffffff;
  background-color: #ffffff;
}

.btn.btn-toolbar-text {
  border-color: #1a1a27;
  background-color: #1a1a27;
}
.btn.btn-bg-toolbar-text {
  border-color: #1a1a27;
  background-color: #1a1a27;
}
.btn-check:checked + .btn.btn-active-toolbar-text, .btn-check:active + .btn.btn-active-toolbar-text, .btn.btn-active-toolbar-text:focus:not(.btn-active), .btn.btn-active-toolbar-text:hover:not(.btn-active), .btn.btn-active-toolbar-text:active:not(.btn-active), .btn.btn-active-toolbar-text.active, .btn.btn-active-toolbar-text.show,
.btn.btn-active-toolbar-text .show, .btn.btn-active-toolbar-text[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-toolbar-text {
  border-color: #1a1a27;
  background-color: #1a1a27;
}

.btn.btn-focus-bg {
  border-color: #eef3f7;
  background-color: #eef3f7;
}
.btn.btn-bg-focus-bg {
  border-color: #eef3f7;
  background-color: #eef3f7;
}
.btn-check:checked + .btn.btn-active-focus-bg, .btn-check:active + .btn.btn-active-focus-bg, .btn.btn-active-focus-bg:focus:not(.btn-active), .btn.btn-active-focus-bg:hover:not(.btn-active), .btn.btn-active-focus-bg:active:not(.btn-active), .btn.btn-active-focus-bg.active, .btn.btn-active-focus-bg.show,
.btn.btn-active-focus-bg .show, .btn.btn-active-focus-bg[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-focus-bg {
  border-color: #eef3f7;
  background-color: #eef3f7;
}

.btn.btn-tabbar-text {
  border-color: #171723;
  background-color: #171723;
}
.btn.btn-bg-tabbar-text {
  border-color: #171723;
  background-color: #171723;
}
.btn-check:checked + .btn.btn-active-tabbar-text, .btn-check:active + .btn.btn-active-tabbar-text, .btn.btn-active-tabbar-text:focus:not(.btn-active), .btn.btn-active-tabbar-text:hover:not(.btn-active), .btn.btn-active-tabbar-text:active:not(.btn-active), .btn.btn-active-tabbar-text.active, .btn.btn-active-tabbar-text.show,
.btn.btn-active-tabbar-text .show, .btn.btn-active-tabbar-text[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-tabbar-text {
  border-color: #171723;
  background-color: #171723;
}

.btn.btn-card-bg {
  border-color: #ffffff;
  background-color: #ffffff;
}
.btn.btn-bg-card-bg {
  border-color: #ffffff;
  background-color: #ffffff;
}
.btn-check:checked + .btn.btn-active-card-bg, .btn-check:active + .btn.btn-active-card-bg, .btn.btn-active-card-bg:focus:not(.btn-active), .btn.btn-active-card-bg:hover:not(.btn-active), .btn.btn-active-card-bg:active:not(.btn-active), .btn.btn-active-card-bg.active, .btn.btn-active-card-bg.show,
.btn.btn-active-card-bg .show, .btn.btn-active-card-bg[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-card-bg {
  border-color: #ffffff;
  background-color: #ffffff;
}

.btn.btn-card-text {
  border-color: #1e1e2d;
  background-color: #1e1e2d;
}
.btn.btn-bg-card-text {
  border-color: #1e1e2d;
  background-color: #1e1e2d;
}
.btn-check:checked + .btn.btn-active-card-text, .btn-check:active + .btn.btn-active-card-text, .btn.btn-active-card-text:focus:not(.btn-active), .btn.btn-active-card-text:hover:not(.btn-active), .btn.btn-active-card-text:active:not(.btn-active), .btn.btn-active-card-text.active, .btn.btn-active-card-text.show,
.btn.btn-active-card-text .show, .btn.btn-active-card-text[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-card-text {
  border-color: #1e1e2d;
  background-color: #1e1e2d;
}

.btn.btn-item-bg {
  border-color: #e4e6ef;
  background-color: #e4e6ef;
}
.btn.btn-bg-item-bg {
  border-color: #e4e6ef;
  background-color: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-item-bg, .btn-check:active + .btn.btn-active-item-bg, .btn.btn-active-item-bg:focus:not(.btn-active), .btn.btn-active-item-bg:hover:not(.btn-active), .btn.btn-active-item-bg:active:not(.btn-active), .btn.btn-active-item-bg.active, .btn.btn-active-item-bg.show,
.btn.btn-active-item-bg .show, .btn.btn-active-item-bg[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-item-bg {
  border-color: #e4e6ef;
  background-color: #e4e6ef;
}

.btn.btn-item-text {
  border-color: #1b1b29;
  background-color: #1b1b29;
}
.btn.btn-bg-item-text {
  border-color: #1b1b29;
  background-color: #1b1b29;
}
.btn-check:checked + .btn.btn-active-item-text, .btn-check:active + .btn.btn-active-item-text, .btn.btn-active-item-text:focus:not(.btn-active), .btn.btn-active-item-text:hover:not(.btn-active), .btn.btn-active-item-text:active:not(.btn-active), .btn.btn-active-item-text.active, .btn.btn-active-item-text.show,
.btn.btn-active-item-text .show, .btn.btn-active-item-text[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-item-text {
  border-color: #1b1b29;
  background-color: #1b1b29;
}

.btn.btn-color-white {
  color: #ffffff;
}
.btn.btn-color-white .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-color-white .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-white.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-color-white, .btn-check:active + .btn.btn-active-color-white, .btn.btn-active-color-white:focus:not(.btn-active), .btn.btn-active-color-white:hover:not(.btn-active), .btn.btn-active-color-white:active:not(.btn-active), .btn.btn-active-color-white.active, .btn.btn-active-color-white.show,
.btn.btn-active-color-white .show, .btn.btn-active-color-white[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-white {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-color-white i, .btn-check:active + .btn.btn-active-color-white i, .btn.btn-active-color-white:focus:not(.btn-active) i, .btn.btn-active-color-white:hover:not(.btn-active) i, .btn.btn-active-color-white:active:not(.btn-active) i, .btn.btn-active-color-white.active i, .btn.btn-active-color-white.show i,
.btn.btn-active-color-white .show i, .btn.btn-active-color-white[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-white i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-color-white .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-white .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-white:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-white:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-white:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-white.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-white.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-white .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-white[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-white .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-color-white .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-white .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-white:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-white:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-white:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-white.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-white.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-white .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-white[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-white .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-white.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-white.dropdown-toggle:after, .btn.btn-active-color-white:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-white:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-white:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-white.active.dropdown-toggle:after, .btn.btn-active-color-white.show.dropdown-toggle:after,
.btn.btn-active-color-white .show.dropdown-toggle:after, .btn.btn-active-color-white[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-white.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-icon-white .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-icon-white .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-white.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-icon-white i, .btn-check:active + .btn.btn-active-icon-white i, .btn.btn-active-icon-white:focus:not(.btn-active) i, .btn.btn-active-icon-white:hover:not(.btn-active) i, .btn.btn-active-icon-white:active:not(.btn-active) i, .btn.btn-active-icon-white.active i, .btn.btn-active-icon-white.show i,
.btn.btn-active-icon-white .show i, .btn.btn-active-icon-white[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-white i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-icon-white .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-white .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-white:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-white:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-white:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-white.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-white.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-white .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-white[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-white .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-active-icon-white .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-white .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-white:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-white:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-white:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-white.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-white.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-white .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-white[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-white .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-white.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-white.dropdown-toggle:after, .btn.btn-active-icon-white:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-white:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-white:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-white.active.dropdown-toggle:after, .btn.btn-active-icon-white.show.dropdown-toggle:after,
.btn.btn-active-icon-white .show.dropdown-toggle:after, .btn.btn-active-icon-white[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-white.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-text-white {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-active-text-white, .btn-check:active + .btn.btn-active-text-white, .btn.btn-active-text-white:focus:not(.btn-active), .btn.btn-active-text-white:hover:not(.btn-active), .btn.btn-active-text-white:active:not(.btn-active), .btn.btn-active-text-white.active, .btn.btn-active-text-white.show,
.btn.btn-active-text-white .show, .btn.btn-active-text-white[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-white {
  color: #ffffff;
}

.btn.btn-color-primary {
  color: #e10e8d;
}
.btn.btn-color-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.btn.btn-color-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-primary.dropdown-toggle:after {
  color: #e10e8d;
}
.btn-check:checked + .btn.btn-active-color-primary, .btn-check:active + .btn.btn-active-color-primary, .btn.btn-active-color-primary:focus:not(.btn-active), .btn.btn-active-color-primary:hover:not(.btn-active), .btn.btn-active-color-primary:active:not(.btn-active), .btn.btn-active-color-primary.active, .btn.btn-active-color-primary.show,
.btn.btn-active-color-primary .show, .btn.btn-active-color-primary[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-primary {
  color: #e10e8d;
}
.btn-check:checked + .btn.btn-active-color-primary i, .btn-check:active + .btn.btn-active-color-primary i, .btn.btn-active-color-primary:focus:not(.btn-active) i, .btn.btn-active-color-primary:hover:not(.btn-active) i, .btn.btn-active-color-primary:active:not(.btn-active) i, .btn.btn-active-color-primary.active i, .btn.btn-active-color-primary.show i,
.btn.btn-active-color-primary .show i, .btn.btn-active-color-primary[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-primary i {
  color: #e10e8d;
}
.btn-check:checked + .btn.btn-active-color-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-primary .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.btn-check:checked + .btn.btn-active-color-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-primary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-primary.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-primary .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-primary[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-primary.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-primary.dropdown-toggle:after, .btn.btn-active-color-primary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-primary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-primary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-primary.active.dropdown-toggle:after, .btn.btn-active-color-primary.show.dropdown-toggle:after,
.btn.btn-active-color-primary .show.dropdown-toggle:after, .btn.btn-active-color-primary[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-primary.dropdown-toggle:after {
  color: #e10e8d;
}

.btn.btn-icon-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.btn.btn-icon-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-primary.dropdown-toggle:after {
  color: #e10e8d;
}
.btn-check:checked + .btn.btn-active-icon-primary i, .btn-check:active + .btn.btn-active-icon-primary i, .btn.btn-active-icon-primary:focus:not(.btn-active) i, .btn.btn-active-icon-primary:hover:not(.btn-active) i, .btn.btn-active-icon-primary:active:not(.btn-active) i, .btn.btn-active-icon-primary.active i, .btn.btn-active-icon-primary.show i,
.btn.btn-active-icon-primary .show i, .btn.btn-active-icon-primary[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-primary i {
  color: #e10e8d;
}
.btn-check:checked + .btn.btn-active-icon-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-primary .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.btn-check:checked + .btn.btn-active-icon-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-primary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-primary .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-primary[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-primary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-primary.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-primary.dropdown-toggle:after, .btn.btn-active-icon-primary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-primary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-primary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-primary.active.dropdown-toggle:after, .btn.btn-active-icon-primary.show.dropdown-toggle:after,
.btn.btn-active-icon-primary .show.dropdown-toggle:after, .btn.btn-active-icon-primary[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-primary.dropdown-toggle:after {
  color: #e10e8d;
}

.btn.btn-text-primary {
  color: #e10e8d;
}
.btn-check:checked + .btn.btn-active-text-primary, .btn-check:active + .btn.btn-active-text-primary, .btn.btn-active-text-primary:focus:not(.btn-active), .btn.btn-active-text-primary:hover:not(.btn-active), .btn.btn-active-text-primary:active:not(.btn-active), .btn.btn-active-text-primary.active, .btn.btn-active-text-primary.show,
.btn.btn-active-text-primary .show, .btn.btn-active-text-primary[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-primary {
  color: #e10e8d;
}

.btn.btn-color-secondary {
  color: #e4e6ef;
}
.btn.btn-color-secondary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.btn.btn-color-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-secondary.dropdown-toggle:after {
  color: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-color-secondary, .btn-check:active + .btn.btn-active-color-secondary, .btn.btn-active-color-secondary:focus:not(.btn-active), .btn.btn-active-color-secondary:hover:not(.btn-active), .btn.btn-active-color-secondary:active:not(.btn-active), .btn.btn-active-color-secondary.active, .btn.btn-active-color-secondary.show,
.btn.btn-active-color-secondary .show, .btn.btn-active-color-secondary[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-secondary {
  color: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-color-secondary i, .btn-check:active + .btn.btn-active-color-secondary i, .btn.btn-active-color-secondary:focus:not(.btn-active) i, .btn.btn-active-color-secondary:hover:not(.btn-active) i, .btn.btn-active-color-secondary:active:not(.btn-active) i, .btn.btn-active-color-secondary.active i, .btn.btn-active-color-secondary.show i,
.btn.btn-active-color-secondary .show i, .btn.btn-active-color-secondary[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-secondary i {
  color: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-color-secondary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-secondary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-secondary .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-secondary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-color-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-secondary .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-secondary[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-secondary.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-secondary.dropdown-toggle:after, .btn.btn-active-color-secondary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-secondary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-secondary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-secondary.active.dropdown-toggle:after, .btn.btn-active-color-secondary.show.dropdown-toggle:after,
.btn.btn-active-color-secondary .show.dropdown-toggle:after, .btn.btn-active-color-secondary[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-secondary.dropdown-toggle:after {
  color: #e4e6ef;
}

.btn.btn-icon-secondary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.btn.btn-icon-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-secondary.dropdown-toggle:after {
  color: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-icon-secondary i, .btn-check:active + .btn.btn-active-icon-secondary i, .btn.btn-active-icon-secondary:focus:not(.btn-active) i, .btn.btn-active-icon-secondary:hover:not(.btn-active) i, .btn.btn-active-icon-secondary:active:not(.btn-active) i, .btn.btn-active-icon-secondary.active i, .btn.btn-active-icon-secondary.show i,
.btn.btn-active-icon-secondary .show i, .btn.btn-active-icon-secondary[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-secondary i {
  color: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-icon-secondary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-secondary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-secondary .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-secondary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-icon-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-secondary .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-secondary[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-secondary .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-secondary.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-secondary.dropdown-toggle:after, .btn.btn-active-icon-secondary:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-secondary:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-secondary:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-secondary.active.dropdown-toggle:after, .btn.btn-active-icon-secondary.show.dropdown-toggle:after,
.btn.btn-active-icon-secondary .show.dropdown-toggle:after, .btn.btn-active-icon-secondary[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-secondary.dropdown-toggle:after {
  color: #e4e6ef;
}

.btn.btn-text-secondary {
  color: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-text-secondary, .btn-check:active + .btn.btn-active-text-secondary, .btn.btn-active-text-secondary:focus:not(.btn-active), .btn.btn-active-text-secondary:hover:not(.btn-active), .btn.btn-active-text-secondary:active:not(.btn-active), .btn.btn-active-text-secondary.active, .btn.btn-active-text-secondary.show,
.btn.btn-active-text-secondary .show, .btn.btn-active-text-secondary[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-secondary {
  color: #e4e6ef;
}

.btn.btn-color-light {
  color: #eff2f5;
}
.btn.btn-color-light .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.btn.btn-color-light .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-light.dropdown-toggle:after {
  color: #eff2f5;
}
.btn-check:checked + .btn.btn-active-color-light, .btn-check:active + .btn.btn-active-color-light, .btn.btn-active-color-light:focus:not(.btn-active), .btn.btn-active-color-light:hover:not(.btn-active), .btn.btn-active-color-light:active:not(.btn-active), .btn.btn-active-color-light.active, .btn.btn-active-color-light.show,
.btn.btn-active-color-light .show, .btn.btn-active-color-light[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-light {
  color: #eff2f5;
}
.btn-check:checked + .btn.btn-active-color-light i, .btn-check:active + .btn.btn-active-color-light i, .btn.btn-active-color-light:focus:not(.btn-active) i, .btn.btn-active-color-light:hover:not(.btn-active) i, .btn.btn-active-color-light:active:not(.btn-active) i, .btn.btn-active-color-light.active i, .btn.btn-active-color-light.show i,
.btn.btn-active-color-light .show i, .btn.btn-active-color-light[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-light i {
  color: #eff2f5;
}
.btn-check:checked + .btn.btn-active-color-light .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-light .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-light:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-light:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-light:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-light.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-light.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-light .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-light[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-light .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.btn-check:checked + .btn.btn-active-color-light .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-light .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-light:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-light:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-light:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-light.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-light.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-light .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-light[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-light .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-light.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-light.dropdown-toggle:after, .btn.btn-active-color-light:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-light:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-light:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-light.active.dropdown-toggle:after, .btn.btn-active-color-light.show.dropdown-toggle:after,
.btn.btn-active-color-light .show.dropdown-toggle:after, .btn.btn-active-color-light[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-light.dropdown-toggle:after {
  color: #eff2f5;
}

.btn.btn-icon-light .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.btn.btn-icon-light .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-light.dropdown-toggle:after {
  color: #eff2f5;
}
.btn-check:checked + .btn.btn-active-icon-light i, .btn-check:active + .btn.btn-active-icon-light i, .btn.btn-active-icon-light:focus:not(.btn-active) i, .btn.btn-active-icon-light:hover:not(.btn-active) i, .btn.btn-active-icon-light:active:not(.btn-active) i, .btn.btn-active-icon-light.active i, .btn.btn-active-icon-light.show i,
.btn.btn-active-icon-light .show i, .btn.btn-active-icon-light[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-light i {
  color: #eff2f5;
}
.btn-check:checked + .btn.btn-active-icon-light .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-light .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-light:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-light:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-light:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-light.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-light.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-light .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-light[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-light .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.btn-check:checked + .btn.btn-active-icon-light .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-light .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-light:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-light:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-light:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-light.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-light.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-light .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-light[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-light .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-light.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-light.dropdown-toggle:after, .btn.btn-active-icon-light:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-light:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-light:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-light.active.dropdown-toggle:after, .btn.btn-active-icon-light.show.dropdown-toggle:after,
.btn.btn-active-icon-light .show.dropdown-toggle:after, .btn.btn-active-icon-light[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-light.dropdown-toggle:after {
  color: #eff2f5;
}

.btn.btn-text-light {
  color: #eff2f5;
}
.btn-check:checked + .btn.btn-active-text-light, .btn-check:active + .btn.btn-active-text-light, .btn.btn-active-text-light:focus:not(.btn-active), .btn.btn-active-text-light:hover:not(.btn-active), .btn.btn-active-text-light:active:not(.btn-active), .btn.btn-active-text-light.active, .btn.btn-active-text-light.show,
.btn.btn-active-text-light .show, .btn.btn-active-text-light[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-light {
  color: #eff2f5;
}

.btn.btn-color-success {
  color: #1BC5BC;
}
.btn.btn-color-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.btn.btn-color-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-success.dropdown-toggle:after {
  color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-color-success, .btn-check:active + .btn.btn-active-color-success, .btn.btn-active-color-success:focus:not(.btn-active), .btn.btn-active-color-success:hover:not(.btn-active), .btn.btn-active-color-success:active:not(.btn-active), .btn.btn-active-color-success.active, .btn.btn-active-color-success.show,
.btn.btn-active-color-success .show, .btn.btn-active-color-success[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-success {
  color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-color-success i, .btn-check:active + .btn.btn-active-color-success i, .btn.btn-active-color-success:focus:not(.btn-active) i, .btn.btn-active-color-success:hover:not(.btn-active) i, .btn.btn-active-color-success:active:not(.btn-active) i, .btn.btn-active-color-success.active i, .btn.btn-active-color-success.show i,
.btn.btn-active-color-success .show i, .btn.btn-active-color-success[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-success i {
  color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-color-success .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-success .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-success:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-success:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-success:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-success.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-success.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-success .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-success[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-color-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-success:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-success:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-success:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-success.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-success.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-success .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-success[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-success.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-success.dropdown-toggle:after, .btn.btn-active-color-success:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-success:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-success:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-success.active.dropdown-toggle:after, .btn.btn-active-color-success.show.dropdown-toggle:after,
.btn.btn-active-color-success .show.dropdown-toggle:after, .btn.btn-active-color-success[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-success.dropdown-toggle:after {
  color: #1BC5BC;
}

.btn.btn-icon-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.btn.btn-icon-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-success.dropdown-toggle:after {
  color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-icon-success i, .btn-check:active + .btn.btn-active-icon-success i, .btn.btn-active-icon-success:focus:not(.btn-active) i, .btn.btn-active-icon-success:hover:not(.btn-active) i, .btn.btn-active-icon-success:active:not(.btn-active) i, .btn.btn-active-icon-success.active i, .btn.btn-active-icon-success.show i,
.btn.btn-active-icon-success .show i, .btn.btn-active-icon-success[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-success i {
  color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-icon-success .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-success .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-success:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-success:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-success:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-success.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-success.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-success .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-success[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-success .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-icon-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-success .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-success:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-success:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-success:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-success.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-success.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-success .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-success[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-success .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-success.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-success.dropdown-toggle:after, .btn.btn-active-icon-success:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-success:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-success:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-success.active.dropdown-toggle:after, .btn.btn-active-icon-success.show.dropdown-toggle:after,
.btn.btn-active-icon-success .show.dropdown-toggle:after, .btn.btn-active-icon-success[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-success.dropdown-toggle:after {
  color: #1BC5BC;
}

.btn.btn-text-success {
  color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-text-success, .btn-check:active + .btn.btn-active-text-success, .btn.btn-active-text-success:focus:not(.btn-active), .btn.btn-active-text-success:hover:not(.btn-active), .btn.btn-active-text-success:active:not(.btn-active), .btn.btn-active-text-success.active, .btn.btn-active-text-success.show,
.btn.btn-active-text-success .show, .btn.btn-active-text-success[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-success {
  color: #1BC5BC;
}

.btn.btn-color-info {
  color: #5F5CF1;
}
.btn.btn-color-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.btn.btn-color-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-info.dropdown-toggle:after {
  color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-color-info, .btn-check:active + .btn.btn-active-color-info, .btn.btn-active-color-info:focus:not(.btn-active), .btn.btn-active-color-info:hover:not(.btn-active), .btn.btn-active-color-info:active:not(.btn-active), .btn.btn-active-color-info.active, .btn.btn-active-color-info.show,
.btn.btn-active-color-info .show, .btn.btn-active-color-info[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-info {
  color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-color-info i, .btn-check:active + .btn.btn-active-color-info i, .btn.btn-active-color-info:focus:not(.btn-active) i, .btn.btn-active-color-info:hover:not(.btn-active) i, .btn.btn-active-color-info:active:not(.btn-active) i, .btn.btn-active-color-info.active i, .btn.btn-active-color-info.show i,
.btn.btn-active-color-info .show i, .btn.btn-active-color-info[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-info i {
  color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-color-info .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-info .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-info:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-info:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-info:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-info.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-info.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-info .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-info[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-color-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-info:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-info:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-info:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-info.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-info.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-info .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-info[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-info.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-info.dropdown-toggle:after, .btn.btn-active-color-info:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-info:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-info:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-info.active.dropdown-toggle:after, .btn.btn-active-color-info.show.dropdown-toggle:after,
.btn.btn-active-color-info .show.dropdown-toggle:after, .btn.btn-active-color-info[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-info.dropdown-toggle:after {
  color: #5F5CF1;
}

.btn.btn-icon-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.btn.btn-icon-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-info.dropdown-toggle:after {
  color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-icon-info i, .btn-check:active + .btn.btn-active-icon-info i, .btn.btn-active-icon-info:focus:not(.btn-active) i, .btn.btn-active-icon-info:hover:not(.btn-active) i, .btn.btn-active-icon-info:active:not(.btn-active) i, .btn.btn-active-icon-info.active i, .btn.btn-active-icon-info.show i,
.btn.btn-active-icon-info .show i, .btn.btn-active-icon-info[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-info i {
  color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-icon-info .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-info .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-info:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-info:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-info:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-info.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-info.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-info .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-info[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-info .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-icon-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-info .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-info:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-info:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-info:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-info.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-info.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-info .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-info[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-info .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-info.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-info.dropdown-toggle:after, .btn.btn-active-icon-info:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-info:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-info:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-info.active.dropdown-toggle:after, .btn.btn-active-icon-info.show.dropdown-toggle:after,
.btn.btn-active-icon-info .show.dropdown-toggle:after, .btn.btn-active-icon-info[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-info.dropdown-toggle:after {
  color: #5F5CF1;
}

.btn.btn-text-info {
  color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-text-info, .btn-check:active + .btn.btn-active-text-info, .btn.btn-active-text-info:focus:not(.btn-active), .btn.btn-active-text-info:hover:not(.btn-active), .btn.btn-active-text-info:active:not(.btn-active), .btn.btn-active-text-info.active, .btn.btn-active-text-info.show,
.btn.btn-active-text-info .show, .btn.btn-active-text-info[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-info {
  color: #5F5CF1;
}

.btn.btn-color-warning {
  color: #FFA800;
}
.btn.btn-color-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.btn.btn-color-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-warning.dropdown-toggle:after {
  color: #FFA800;
}
.btn-check:checked + .btn.btn-active-color-warning, .btn-check:active + .btn.btn-active-color-warning, .btn.btn-active-color-warning:focus:not(.btn-active), .btn.btn-active-color-warning:hover:not(.btn-active), .btn.btn-active-color-warning:active:not(.btn-active), .btn.btn-active-color-warning.active, .btn.btn-active-color-warning.show,
.btn.btn-active-color-warning .show, .btn.btn-active-color-warning[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-warning {
  color: #FFA800;
}
.btn-check:checked + .btn.btn-active-color-warning i, .btn-check:active + .btn.btn-active-color-warning i, .btn.btn-active-color-warning:focus:not(.btn-active) i, .btn.btn-active-color-warning:hover:not(.btn-active) i, .btn.btn-active-color-warning:active:not(.btn-active) i, .btn.btn-active-color-warning.active i, .btn.btn-active-color-warning.show i,
.btn.btn-active-color-warning .show i, .btn.btn-active-color-warning[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-warning i {
  color: #FFA800;
}
.btn-check:checked + .btn.btn-active-color-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-warning:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-warning:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-warning:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-warning.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-warning.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-warning .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-warning[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.btn-check:checked + .btn.btn-active-color-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-warning:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-warning:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-warning:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-warning.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-warning.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-warning .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-warning[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-warning.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-warning.dropdown-toggle:after, .btn.btn-active-color-warning:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-warning:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-warning:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-warning.active.dropdown-toggle:after, .btn.btn-active-color-warning.show.dropdown-toggle:after,
.btn.btn-active-color-warning .show.dropdown-toggle:after, .btn.btn-active-color-warning[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-warning.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-icon-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.btn.btn-icon-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-warning.dropdown-toggle:after {
  color: #FFA800;
}
.btn-check:checked + .btn.btn-active-icon-warning i, .btn-check:active + .btn.btn-active-icon-warning i, .btn.btn-active-icon-warning:focus:not(.btn-active) i, .btn.btn-active-icon-warning:hover:not(.btn-active) i, .btn.btn-active-icon-warning:active:not(.btn-active) i, .btn.btn-active-icon-warning.active i, .btn.btn-active-icon-warning.show i,
.btn.btn-active-icon-warning .show i, .btn.btn-active-icon-warning[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-warning i {
  color: #FFA800;
}
.btn-check:checked + .btn.btn-active-icon-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-warning .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-warning .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-warning .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.btn-check:checked + .btn.btn-active-icon-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-warning .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-warning .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-warning[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-warning .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-warning.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-warning.dropdown-toggle:after, .btn.btn-active-icon-warning:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-warning:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-warning:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-warning.active.dropdown-toggle:after, .btn.btn-active-icon-warning.show.dropdown-toggle:after,
.btn.btn-active-icon-warning .show.dropdown-toggle:after, .btn.btn-active-icon-warning[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-warning.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-text-warning {
  color: #FFA800;
}
.btn-check:checked + .btn.btn-active-text-warning, .btn-check:active + .btn.btn-active-text-warning, .btn.btn-active-text-warning:focus:not(.btn-active), .btn.btn-active-text-warning:hover:not(.btn-active), .btn.btn-active-text-warning:active:not(.btn-active), .btn.btn-active-text-warning.active, .btn.btn-active-text-warning.show,
.btn.btn-active-text-warning .show, .btn.btn-active-text-warning[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-warning {
  color: #FFA800;
}

.btn.btn-color-danger {
  color: #F65464;
}
.btn.btn-color-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.btn.btn-color-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-danger.dropdown-toggle:after {
  color: #F65464;
}
.btn-check:checked + .btn.btn-active-color-danger, .btn-check:active + .btn.btn-active-color-danger, .btn.btn-active-color-danger:focus:not(.btn-active), .btn.btn-active-color-danger:hover:not(.btn-active), .btn.btn-active-color-danger:active:not(.btn-active), .btn.btn-active-color-danger.active, .btn.btn-active-color-danger.show,
.btn.btn-active-color-danger .show, .btn.btn-active-color-danger[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-danger {
  color: #F65464;
}
.btn-check:checked + .btn.btn-active-color-danger i, .btn-check:active + .btn.btn-active-color-danger i, .btn.btn-active-color-danger:focus:not(.btn-active) i, .btn.btn-active-color-danger:hover:not(.btn-active) i, .btn.btn-active-color-danger:active:not(.btn-active) i, .btn.btn-active-color-danger.active i, .btn.btn-active-color-danger.show i,
.btn.btn-active-color-danger .show i, .btn.btn-active-color-danger[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-danger i {
  color: #F65464;
}
.btn-check:checked + .btn.btn-active-color-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-danger.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-danger.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-danger .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-danger[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.btn-check:checked + .btn.btn-active-color-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-danger .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-danger[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-danger.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-danger.dropdown-toggle:after, .btn.btn-active-color-danger:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-danger:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-danger:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-danger.active.dropdown-toggle:after, .btn.btn-active-color-danger.show.dropdown-toggle:after,
.btn.btn-active-color-danger .show.dropdown-toggle:after, .btn.btn-active-color-danger[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-danger.dropdown-toggle:after {
  color: #F65464;
}

.btn.btn-icon-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.btn.btn-icon-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-danger.dropdown-toggle:after {
  color: #F65464;
}
.btn-check:checked + .btn.btn-active-icon-danger i, .btn-check:active + .btn.btn-active-icon-danger i, .btn.btn-active-icon-danger:focus:not(.btn-active) i, .btn.btn-active-icon-danger:hover:not(.btn-active) i, .btn.btn-active-icon-danger:active:not(.btn-active) i, .btn.btn-active-icon-danger.active i, .btn.btn-active-icon-danger.show i,
.btn.btn-active-icon-danger .show i, .btn.btn-active-icon-danger[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-danger i {
  color: #F65464;
}
.btn-check:checked + .btn.btn-active-icon-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-danger .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-danger .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-danger .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.btn-check:checked + .btn.btn-active-icon-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-danger .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-danger .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-danger[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-danger.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-danger.dropdown-toggle:after, .btn.btn-active-icon-danger:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-danger:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-danger:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-danger.active.dropdown-toggle:after, .btn.btn-active-icon-danger.show.dropdown-toggle:after,
.btn.btn-active-icon-danger .show.dropdown-toggle:after, .btn.btn-active-icon-danger[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-danger.dropdown-toggle:after {
  color: #F65464;
}

.btn.btn-text-danger {
  color: #F65464;
}
.btn-check:checked + .btn.btn-active-text-danger, .btn-check:active + .btn.btn-active-text-danger, .btn.btn-active-text-danger:focus:not(.btn-active), .btn.btn-active-text-danger:hover:not(.btn-active), .btn.btn-active-text-danger:active:not(.btn-active), .btn.btn-active-text-danger.active, .btn.btn-active-text-danger.show,
.btn.btn-active-text-danger .show, .btn.btn-active-text-danger[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-danger {
  color: #F65464;
}

.btn.btn-color-dark {
  color: #3f4254;
}
.btn.btn-color-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.btn.btn-color-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-dark.dropdown-toggle:after {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-active-color-dark, .btn-check:active + .btn.btn-active-color-dark, .btn.btn-active-color-dark:focus:not(.btn-active), .btn.btn-active-color-dark:hover:not(.btn-active), .btn.btn-active-color-dark:active:not(.btn-active), .btn.btn-active-color-dark.active, .btn.btn-active-color-dark.show,
.btn.btn-active-color-dark .show, .btn.btn-active-color-dark[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-dark {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-active-color-dark i, .btn-check:active + .btn.btn-active-color-dark i, .btn.btn-active-color-dark:focus:not(.btn-active) i, .btn.btn-active-color-dark:hover:not(.btn-active) i, .btn.btn-active-color-dark:active:not(.btn-active) i, .btn.btn-active-color-dark.active i, .btn.btn-active-color-dark.show i,
.btn.btn-active-color-dark .show i, .btn.btn-active-color-dark[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-dark i {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-active-color-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-dark:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-dark:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-dark:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-dark.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-dark.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-dark .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-dark[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.btn-check:checked + .btn.btn-active-color-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-dark:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-dark:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-dark:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-dark.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-dark.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-dark .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-dark[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-dark.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-dark.dropdown-toggle:after, .btn.btn-active-color-dark:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-dark:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-dark:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-dark.active.dropdown-toggle:after, .btn.btn-active-color-dark.show.dropdown-toggle:after,
.btn.btn-active-color-dark .show.dropdown-toggle:after, .btn.btn-active-color-dark[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-dark.dropdown-toggle:after {
  color: #3f4254;
}

.btn.btn-icon-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.btn.btn-icon-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-dark.dropdown-toggle:after {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-active-icon-dark i, .btn-check:active + .btn.btn-active-icon-dark i, .btn.btn-active-icon-dark:focus:not(.btn-active) i, .btn.btn-active-icon-dark:hover:not(.btn-active) i, .btn.btn-active-icon-dark:active:not(.btn-active) i, .btn.btn-active-icon-dark.active i, .btn.btn-active-icon-dark.show i,
.btn.btn-active-icon-dark .show i, .btn.btn-active-icon-dark[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-dark i {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-active-icon-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-dark .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-dark .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-dark .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.btn-check:checked + .btn.btn-active-icon-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-dark .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-dark .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-dark[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-dark .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-dark.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-dark.dropdown-toggle:after, .btn.btn-active-icon-dark:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-dark:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-dark:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-dark.active.dropdown-toggle:after, .btn.btn-active-icon-dark.show.dropdown-toggle:after,
.btn.btn-active-icon-dark .show.dropdown-toggle:after, .btn.btn-active-icon-dark[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-dark.dropdown-toggle:after {
  color: #3f4254;
}

.btn.btn-text-dark {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-active-text-dark, .btn-check:active + .btn.btn-active-text-dark, .btn.btn-active-text-dark:focus:not(.btn-active), .btn.btn-active-text-dark:hover:not(.btn-active), .btn.btn-active-text-dark:active:not(.btn-active), .btn.btn-active-text-dark.active, .btn.btn-active-text-dark.show,
.btn.btn-active-text-dark .show, .btn.btn-active-text-dark[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-dark {
  color: #3f4254;
}

.btn.btn-color-black {
  color: #000000;
}
.btn.btn-color-black .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #000000;
}
.btn.btn-color-black .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-black.dropdown-toggle:after {
  color: #000000;
}
.btn-check:checked + .btn.btn-active-color-black, .btn-check:active + .btn.btn-active-color-black, .btn.btn-active-color-black:focus:not(.btn-active), .btn.btn-active-color-black:hover:not(.btn-active), .btn.btn-active-color-black:active:not(.btn-active), .btn.btn-active-color-black.active, .btn.btn-active-color-black.show,
.btn.btn-active-color-black .show, .btn.btn-active-color-black[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-black {
  color: #000000;
}
.btn-check:checked + .btn.btn-active-color-black i, .btn-check:active + .btn.btn-active-color-black i, .btn.btn-active-color-black:focus:not(.btn-active) i, .btn.btn-active-color-black:hover:not(.btn-active) i, .btn.btn-active-color-black:active:not(.btn-active) i, .btn.btn-active-color-black.active i, .btn.btn-active-color-black.show i,
.btn.btn-active-color-black .show i, .btn.btn-active-color-black[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-black i {
  color: #000000;
}
.btn-check:checked + .btn.btn-active-color-black .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-black .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-black:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-black:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-black:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-black.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-black.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-black .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-black[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-black .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #000000;
}
.btn-check:checked + .btn.btn-active-color-black .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-black .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-black:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-black:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-black:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-black.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-black.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-black .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-black[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-black .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-black.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-black.dropdown-toggle:after, .btn.btn-active-color-black:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-black:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-black:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-black.active.dropdown-toggle:after, .btn.btn-active-color-black.show.dropdown-toggle:after,
.btn.btn-active-color-black .show.dropdown-toggle:after, .btn.btn-active-color-black[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-black.dropdown-toggle:after {
  color: #000000;
}

.btn.btn-icon-black .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #000000;
}
.btn.btn-icon-black .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-black.dropdown-toggle:after {
  color: #000000;
}
.btn-check:checked + .btn.btn-active-icon-black i, .btn-check:active + .btn.btn-active-icon-black i, .btn.btn-active-icon-black:focus:not(.btn-active) i, .btn.btn-active-icon-black:hover:not(.btn-active) i, .btn.btn-active-icon-black:active:not(.btn-active) i, .btn.btn-active-icon-black.active i, .btn.btn-active-icon-black.show i,
.btn.btn-active-icon-black .show i, .btn.btn-active-icon-black[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-black i {
  color: #000000;
}
.btn-check:checked + .btn.btn-active-icon-black .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-black .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-black:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-black:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-black:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-black.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-black.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-black .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-black[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-black .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #000000;
}
.btn-check:checked + .btn.btn-active-icon-black .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-black .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-black:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-black:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-black:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-black.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-black.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-black .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-black[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-black .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-black.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-black.dropdown-toggle:after, .btn.btn-active-icon-black:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-black:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-black:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-black.active.dropdown-toggle:after, .btn.btn-active-icon-black.show.dropdown-toggle:after,
.btn.btn-active-icon-black .show.dropdown-toggle:after, .btn.btn-active-icon-black[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-black.dropdown-toggle:after {
  color: #000000;
}

.btn.btn-text-black {
  color: #000000;
}
.btn-check:checked + .btn.btn-active-text-black, .btn-check:active + .btn.btn-active-text-black, .btn.btn-active-text-black:focus:not(.btn-active), .btn.btn-active-text-black:hover:not(.btn-active), .btn.btn-active-text-black:active:not(.btn-active), .btn.btn-active-text-black.active, .btn.btn-active-text-black.show,
.btn.btn-active-text-black .show, .btn.btn-active-text-black[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-black {
  color: #000000;
}

.btn.btn-color-muted {
  color: #a1a5b7;
}
.btn.btn-color-muted .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.btn.btn-color-muted .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-muted.dropdown-toggle:after {
  color: #a1a5b7;
}
.btn-check:checked + .btn.btn-active-color-muted, .btn-check:active + .btn.btn-active-color-muted, .btn.btn-active-color-muted:focus:not(.btn-active), .btn.btn-active-color-muted:hover:not(.btn-active), .btn.btn-active-color-muted:active:not(.btn-active), .btn.btn-active-color-muted.active, .btn.btn-active-color-muted.show,
.btn.btn-active-color-muted .show, .btn.btn-active-color-muted[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-muted {
  color: #a1a5b7;
}
.btn-check:checked + .btn.btn-active-color-muted i, .btn-check:active + .btn.btn-active-color-muted i, .btn.btn-active-color-muted:focus:not(.btn-active) i, .btn.btn-active-color-muted:hover:not(.btn-active) i, .btn.btn-active-color-muted:active:not(.btn-active) i, .btn.btn-active-color-muted.active i, .btn.btn-active-color-muted.show i,
.btn.btn-active-color-muted .show i, .btn.btn-active-color-muted[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-muted i {
  color: #a1a5b7;
}
.btn-check:checked + .btn.btn-active-color-muted .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-muted .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-muted:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-muted:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-muted:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-muted.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-muted.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-muted .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-muted[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-muted .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.btn-check:checked + .btn.btn-active-color-muted .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-muted .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-muted:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-muted:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-muted:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-muted.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-muted.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-muted .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-muted[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-muted .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-muted.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-muted.dropdown-toggle:after, .btn.btn-active-color-muted:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-muted:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-muted:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-muted.active.dropdown-toggle:after, .btn.btn-active-color-muted.show.dropdown-toggle:after,
.btn.btn-active-color-muted .show.dropdown-toggle:after, .btn.btn-active-color-muted[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-muted.dropdown-toggle:after {
  color: #a1a5b7;
}

.btn.btn-icon-muted .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.btn.btn-icon-muted .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-muted.dropdown-toggle:after {
  color: #a1a5b7;
}
.btn-check:checked + .btn.btn-active-icon-muted i, .btn-check:active + .btn.btn-active-icon-muted i, .btn.btn-active-icon-muted:focus:not(.btn-active) i, .btn.btn-active-icon-muted:hover:not(.btn-active) i, .btn.btn-active-icon-muted:active:not(.btn-active) i, .btn.btn-active-icon-muted.active i, .btn.btn-active-icon-muted.show i,
.btn.btn-active-icon-muted .show i, .btn.btn-active-icon-muted[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-muted i {
  color: #a1a5b7;
}
.btn-check:checked + .btn.btn-active-icon-muted .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-muted .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-muted .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-muted .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.btn-check:checked + .btn.btn-active-icon-muted .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-muted .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-muted .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-muted[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-muted .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-muted.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-muted.dropdown-toggle:after, .btn.btn-active-icon-muted:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-muted:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-muted:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-muted.active.dropdown-toggle:after, .btn.btn-active-icon-muted.show.dropdown-toggle:after,
.btn.btn-active-icon-muted .show.dropdown-toggle:after, .btn.btn-active-icon-muted[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-muted.dropdown-toggle:after {
  color: #a1a5b7;
}

.btn.btn-text-muted {
  color: #a1a5b7;
}
.btn-check:checked + .btn.btn-active-text-muted, .btn-check:active + .btn.btn-active-text-muted, .btn.btn-active-text-muted:focus:not(.btn-active), .btn.btn-active-text-muted:hover:not(.btn-active), .btn.btn-active-text-muted:active:not(.btn-active), .btn.btn-active-text-muted.active, .btn.btn-active-text-muted.show,
.btn.btn-active-text-muted .show, .btn.btn-active-text-muted[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-muted {
  color: #a1a5b7;
}

.btn.btn-color-gray-100 {
  color: #f5f8fa;
}
.btn.btn-color-gray-100 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #f5f8fa;
}
.btn.btn-color-gray-100 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-100.dropdown-toggle:after {
  color: #f5f8fa;
}
.btn-check:checked + .btn.btn-active-color-gray-100, .btn-check:active + .btn.btn-active-color-gray-100, .btn.btn-active-color-gray-100:focus:not(.btn-active), .btn.btn-active-color-gray-100:hover:not(.btn-active), .btn.btn-active-color-gray-100:active:not(.btn-active), .btn.btn-active-color-gray-100.active, .btn.btn-active-color-gray-100.show,
.btn.btn-active-color-gray-100 .show, .btn.btn-active-color-gray-100[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-gray-100 {
  color: #f5f8fa;
}
.btn-check:checked + .btn.btn-active-color-gray-100 i, .btn-check:active + .btn.btn-active-color-gray-100 i, .btn.btn-active-color-gray-100:focus:not(.btn-active) i, .btn.btn-active-color-gray-100:hover:not(.btn-active) i, .btn.btn-active-color-gray-100:active:not(.btn-active) i, .btn.btn-active-color-gray-100.active i, .btn.btn-active-color-gray-100.show i,
.btn.btn-active-color-gray-100 .show i, .btn.btn-active-color-gray-100[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-gray-100 i {
  color: #f5f8fa;
}
.btn-check:checked + .btn.btn-active-color-gray-100 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-100 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-100 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-100 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #f5f8fa;
}
.btn-check:checked + .btn.btn-active-color-gray-100 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-100 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-100 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-100[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-100 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-100.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-100.dropdown-toggle:after, .btn.btn-active-color-gray-100:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-100:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-100:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-100.active.dropdown-toggle:after, .btn.btn-active-color-gray-100.show.dropdown-toggle:after,
.btn.btn-active-color-gray-100 .show.dropdown-toggle:after, .btn.btn-active-color-gray-100[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-gray-100.dropdown-toggle:after {
  color: #f5f8fa;
}

.btn.btn-icon-gray-100 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #f5f8fa;
}
.btn.btn-icon-gray-100 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-100.dropdown-toggle:after {
  color: #f5f8fa;
}
.btn-check:checked + .btn.btn-active-icon-gray-100 i, .btn-check:active + .btn.btn-active-icon-gray-100 i, .btn.btn-active-icon-gray-100:focus:not(.btn-active) i, .btn.btn-active-icon-gray-100:hover:not(.btn-active) i, .btn.btn-active-icon-gray-100:active:not(.btn-active) i, .btn.btn-active-icon-gray-100.active i, .btn.btn-active-icon-gray-100.show i,
.btn.btn-active-icon-gray-100 .show i, .btn.btn-active-icon-gray-100[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-gray-100 i {
  color: #f5f8fa;
}
.btn-check:checked + .btn.btn-active-icon-gray-100 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-100 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-100 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-100 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #f5f8fa;
}
.btn-check:checked + .btn.btn-active-icon-gray-100 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-100 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-100 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-100[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-100 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-100.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-100.dropdown-toggle:after, .btn.btn-active-icon-gray-100:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-100:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-100:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-100.active.dropdown-toggle:after, .btn.btn-active-icon-gray-100.show.dropdown-toggle:after,
.btn.btn-active-icon-gray-100 .show.dropdown-toggle:after, .btn.btn-active-icon-gray-100[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-gray-100.dropdown-toggle:after {
  color: #f5f8fa;
}

.btn.btn-text-gray-100 {
  color: #f5f8fa;
}
.btn-check:checked + .btn.btn-active-text-gray-100, .btn-check:active + .btn.btn-active-text-gray-100, .btn.btn-active-text-gray-100:focus:not(.btn-active), .btn.btn-active-text-gray-100:hover:not(.btn-active), .btn.btn-active-text-gray-100:active:not(.btn-active), .btn.btn-active-text-gray-100.active, .btn.btn-active-text-gray-100.show,
.btn.btn-active-text-gray-100 .show, .btn.btn-active-text-gray-100[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-gray-100 {
  color: #f5f8fa;
}

.btn.btn-color-gray-200 {
  color: #eff2f5;
}
.btn.btn-color-gray-200 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.btn.btn-color-gray-200 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-200.dropdown-toggle:after {
  color: #eff2f5;
}
.btn-check:checked + .btn.btn-active-color-gray-200, .btn-check:active + .btn.btn-active-color-gray-200, .btn.btn-active-color-gray-200:focus:not(.btn-active), .btn.btn-active-color-gray-200:hover:not(.btn-active), .btn.btn-active-color-gray-200:active:not(.btn-active), .btn.btn-active-color-gray-200.active, .btn.btn-active-color-gray-200.show,
.btn.btn-active-color-gray-200 .show, .btn.btn-active-color-gray-200[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-gray-200 {
  color: #eff2f5;
}
.btn-check:checked + .btn.btn-active-color-gray-200 i, .btn-check:active + .btn.btn-active-color-gray-200 i, .btn.btn-active-color-gray-200:focus:not(.btn-active) i, .btn.btn-active-color-gray-200:hover:not(.btn-active) i, .btn.btn-active-color-gray-200:active:not(.btn-active) i, .btn.btn-active-color-gray-200.active i, .btn.btn-active-color-gray-200.show i,
.btn.btn-active-color-gray-200 .show i, .btn.btn-active-color-gray-200[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-gray-200 i {
  color: #eff2f5;
}
.btn-check:checked + .btn.btn-active-color-gray-200 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-200 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-200 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-200 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.btn-check:checked + .btn.btn-active-color-gray-200 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-200 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-200 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-200[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-200 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-200.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-200.dropdown-toggle:after, .btn.btn-active-color-gray-200:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-200:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-200:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-200.active.dropdown-toggle:after, .btn.btn-active-color-gray-200.show.dropdown-toggle:after,
.btn.btn-active-color-gray-200 .show.dropdown-toggle:after, .btn.btn-active-color-gray-200[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-gray-200.dropdown-toggle:after {
  color: #eff2f5;
}

.btn.btn-icon-gray-200 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.btn.btn-icon-gray-200 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-200.dropdown-toggle:after {
  color: #eff2f5;
}
.btn-check:checked + .btn.btn-active-icon-gray-200 i, .btn-check:active + .btn.btn-active-icon-gray-200 i, .btn.btn-active-icon-gray-200:focus:not(.btn-active) i, .btn.btn-active-icon-gray-200:hover:not(.btn-active) i, .btn.btn-active-icon-gray-200:active:not(.btn-active) i, .btn.btn-active-icon-gray-200.active i, .btn.btn-active-icon-gray-200.show i,
.btn.btn-active-icon-gray-200 .show i, .btn.btn-active-icon-gray-200[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-gray-200 i {
  color: #eff2f5;
}
.btn-check:checked + .btn.btn-active-icon-gray-200 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-200 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-200 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-200 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.btn-check:checked + .btn.btn-active-icon-gray-200 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-200 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-200 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-200[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-200 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-200.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-200.dropdown-toggle:after, .btn.btn-active-icon-gray-200:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-200:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-200:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-200.active.dropdown-toggle:after, .btn.btn-active-icon-gray-200.show.dropdown-toggle:after,
.btn.btn-active-icon-gray-200 .show.dropdown-toggle:after, .btn.btn-active-icon-gray-200[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-gray-200.dropdown-toggle:after {
  color: #eff2f5;
}

.btn.btn-text-gray-200 {
  color: #eff2f5;
}
.btn-check:checked + .btn.btn-active-text-gray-200, .btn-check:active + .btn.btn-active-text-gray-200, .btn.btn-active-text-gray-200:focus:not(.btn-active), .btn.btn-active-text-gray-200:hover:not(.btn-active), .btn.btn-active-text-gray-200:active:not(.btn-active), .btn.btn-active-text-gray-200.active, .btn.btn-active-text-gray-200.show,
.btn.btn-active-text-gray-200 .show, .btn.btn-active-text-gray-200[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-gray-200 {
  color: #eff2f5;
}

.btn.btn-color-gray-300 {
  color: #e4e6ef;
}
.btn.btn-color-gray-300 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.btn.btn-color-gray-300 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-300.dropdown-toggle:after {
  color: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-color-gray-300, .btn-check:active + .btn.btn-active-color-gray-300, .btn.btn-active-color-gray-300:focus:not(.btn-active), .btn.btn-active-color-gray-300:hover:not(.btn-active), .btn.btn-active-color-gray-300:active:not(.btn-active), .btn.btn-active-color-gray-300.active, .btn.btn-active-color-gray-300.show,
.btn.btn-active-color-gray-300 .show, .btn.btn-active-color-gray-300[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-gray-300 {
  color: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-color-gray-300 i, .btn-check:active + .btn.btn-active-color-gray-300 i, .btn.btn-active-color-gray-300:focus:not(.btn-active) i, .btn.btn-active-color-gray-300:hover:not(.btn-active) i, .btn.btn-active-color-gray-300:active:not(.btn-active) i, .btn.btn-active-color-gray-300.active i, .btn.btn-active-color-gray-300.show i,
.btn.btn-active-color-gray-300 .show i, .btn.btn-active-color-gray-300[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-gray-300 i {
  color: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-color-gray-300 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-300 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-300 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-300 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-color-gray-300 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-300 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-300 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-300[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-300 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-300.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-300.dropdown-toggle:after, .btn.btn-active-color-gray-300:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-300:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-300:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-300.active.dropdown-toggle:after, .btn.btn-active-color-gray-300.show.dropdown-toggle:after,
.btn.btn-active-color-gray-300 .show.dropdown-toggle:after, .btn.btn-active-color-gray-300[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-gray-300.dropdown-toggle:after {
  color: #e4e6ef;
}

.btn.btn-icon-gray-300 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.btn.btn-icon-gray-300 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-300.dropdown-toggle:after {
  color: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-icon-gray-300 i, .btn-check:active + .btn.btn-active-icon-gray-300 i, .btn.btn-active-icon-gray-300:focus:not(.btn-active) i, .btn.btn-active-icon-gray-300:hover:not(.btn-active) i, .btn.btn-active-icon-gray-300:active:not(.btn-active) i, .btn.btn-active-icon-gray-300.active i, .btn.btn-active-icon-gray-300.show i,
.btn.btn-active-icon-gray-300 .show i, .btn.btn-active-icon-gray-300[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-gray-300 i {
  color: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-icon-gray-300 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-300 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-300 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-300 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-icon-gray-300 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-300 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-300 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-300[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-300 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-300.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-300.dropdown-toggle:after, .btn.btn-active-icon-gray-300:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-300:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-300:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-300.active.dropdown-toggle:after, .btn.btn-active-icon-gray-300.show.dropdown-toggle:after,
.btn.btn-active-icon-gray-300 .show.dropdown-toggle:after, .btn.btn-active-icon-gray-300[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-gray-300.dropdown-toggle:after {
  color: #e4e6ef;
}

.btn.btn-text-gray-300 {
  color: #e4e6ef;
}
.btn-check:checked + .btn.btn-active-text-gray-300, .btn-check:active + .btn.btn-active-text-gray-300, .btn.btn-active-text-gray-300:focus:not(.btn-active), .btn.btn-active-text-gray-300:hover:not(.btn-active), .btn.btn-active-text-gray-300:active:not(.btn-active), .btn.btn-active-text-gray-300.active, .btn.btn-active-text-gray-300.show,
.btn.btn-active-text-gray-300 .show, .btn.btn-active-text-gray-300[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-gray-300 {
  color: #e4e6ef;
}

.btn.btn-color-gray-400 {
  color: #b5b5c3;
}
.btn.btn-color-gray-400 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.btn.btn-color-gray-400 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-400.dropdown-toggle:after {
  color: #b5b5c3;
}
.btn-check:checked + .btn.btn-active-color-gray-400, .btn-check:active + .btn.btn-active-color-gray-400, .btn.btn-active-color-gray-400:focus:not(.btn-active), .btn.btn-active-color-gray-400:hover:not(.btn-active), .btn.btn-active-color-gray-400:active:not(.btn-active), .btn.btn-active-color-gray-400.active, .btn.btn-active-color-gray-400.show,
.btn.btn-active-color-gray-400 .show, .btn.btn-active-color-gray-400[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-gray-400 {
  color: #b5b5c3;
}
.btn-check:checked + .btn.btn-active-color-gray-400 i, .btn-check:active + .btn.btn-active-color-gray-400 i, .btn.btn-active-color-gray-400:focus:not(.btn-active) i, .btn.btn-active-color-gray-400:hover:not(.btn-active) i, .btn.btn-active-color-gray-400:active:not(.btn-active) i, .btn.btn-active-color-gray-400.active i, .btn.btn-active-color-gray-400.show i,
.btn.btn-active-color-gray-400 .show i, .btn.btn-active-color-gray-400[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-gray-400 i {
  color: #b5b5c3;
}
.btn-check:checked + .btn.btn-active-color-gray-400 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-400 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-400 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-400 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.btn-check:checked + .btn.btn-active-color-gray-400 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-400 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-400 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-400[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-400 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-400.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-400.dropdown-toggle:after, .btn.btn-active-color-gray-400:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-400:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-400:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-400.active.dropdown-toggle:after, .btn.btn-active-color-gray-400.show.dropdown-toggle:after,
.btn.btn-active-color-gray-400 .show.dropdown-toggle:after, .btn.btn-active-color-gray-400[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-gray-400.dropdown-toggle:after {
  color: #b5b5c3;
}

.btn.btn-icon-gray-400 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.btn.btn-icon-gray-400 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-400.dropdown-toggle:after {
  color: #b5b5c3;
}
.btn-check:checked + .btn.btn-active-icon-gray-400 i, .btn-check:active + .btn.btn-active-icon-gray-400 i, .btn.btn-active-icon-gray-400:focus:not(.btn-active) i, .btn.btn-active-icon-gray-400:hover:not(.btn-active) i, .btn.btn-active-icon-gray-400:active:not(.btn-active) i, .btn.btn-active-icon-gray-400.active i, .btn.btn-active-icon-gray-400.show i,
.btn.btn-active-icon-gray-400 .show i, .btn.btn-active-icon-gray-400[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-gray-400 i {
  color: #b5b5c3;
}
.btn-check:checked + .btn.btn-active-icon-gray-400 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-400 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-400 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-400 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.btn-check:checked + .btn.btn-active-icon-gray-400 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-400 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-400 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-400[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-400 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-400.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-400.dropdown-toggle:after, .btn.btn-active-icon-gray-400:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-400:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-400:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-400.active.dropdown-toggle:after, .btn.btn-active-icon-gray-400.show.dropdown-toggle:after,
.btn.btn-active-icon-gray-400 .show.dropdown-toggle:after, .btn.btn-active-icon-gray-400[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-gray-400.dropdown-toggle:after {
  color: #b5b5c3;
}

.btn.btn-text-gray-400 {
  color: #b5b5c3;
}
.btn-check:checked + .btn.btn-active-text-gray-400, .btn-check:active + .btn.btn-active-text-gray-400, .btn.btn-active-text-gray-400:focus:not(.btn-active), .btn.btn-active-text-gray-400:hover:not(.btn-active), .btn.btn-active-text-gray-400:active:not(.btn-active), .btn.btn-active-text-gray-400.active, .btn.btn-active-text-gray-400.show,
.btn.btn-active-text-gray-400 .show, .btn.btn-active-text-gray-400[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-gray-400 {
  color: #b5b5c3;
}

.btn.btn-color-gray-500 {
  color: #a1a5b7;
}
.btn.btn-color-gray-500 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.btn.btn-color-gray-500 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-500.dropdown-toggle:after {
  color: #a1a5b7;
}
.btn-check:checked + .btn.btn-active-color-gray-500, .btn-check:active + .btn.btn-active-color-gray-500, .btn.btn-active-color-gray-500:focus:not(.btn-active), .btn.btn-active-color-gray-500:hover:not(.btn-active), .btn.btn-active-color-gray-500:active:not(.btn-active), .btn.btn-active-color-gray-500.active, .btn.btn-active-color-gray-500.show,
.btn.btn-active-color-gray-500 .show, .btn.btn-active-color-gray-500[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-gray-500 {
  color: #a1a5b7;
}
.btn-check:checked + .btn.btn-active-color-gray-500 i, .btn-check:active + .btn.btn-active-color-gray-500 i, .btn.btn-active-color-gray-500:focus:not(.btn-active) i, .btn.btn-active-color-gray-500:hover:not(.btn-active) i, .btn.btn-active-color-gray-500:active:not(.btn-active) i, .btn.btn-active-color-gray-500.active i, .btn.btn-active-color-gray-500.show i,
.btn.btn-active-color-gray-500 .show i, .btn.btn-active-color-gray-500[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-gray-500 i {
  color: #a1a5b7;
}
.btn-check:checked + .btn.btn-active-color-gray-500 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-500 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-500 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-500 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.btn-check:checked + .btn.btn-active-color-gray-500 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-500 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-500 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-500[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-500 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-500.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-500.dropdown-toggle:after, .btn.btn-active-color-gray-500:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-500:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-500:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-500.active.dropdown-toggle:after, .btn.btn-active-color-gray-500.show.dropdown-toggle:after,
.btn.btn-active-color-gray-500 .show.dropdown-toggle:after, .btn.btn-active-color-gray-500[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-gray-500.dropdown-toggle:after {
  color: #a1a5b7;
}

.btn.btn-icon-gray-500 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.btn.btn-icon-gray-500 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-500.dropdown-toggle:after {
  color: #a1a5b7;
}
.btn-check:checked + .btn.btn-active-icon-gray-500 i, .btn-check:active + .btn.btn-active-icon-gray-500 i, .btn.btn-active-icon-gray-500:focus:not(.btn-active) i, .btn.btn-active-icon-gray-500:hover:not(.btn-active) i, .btn.btn-active-icon-gray-500:active:not(.btn-active) i, .btn.btn-active-icon-gray-500.active i, .btn.btn-active-icon-gray-500.show i,
.btn.btn-active-icon-gray-500 .show i, .btn.btn-active-icon-gray-500[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-gray-500 i {
  color: #a1a5b7;
}
.btn-check:checked + .btn.btn-active-icon-gray-500 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-500 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-500 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-500 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.btn-check:checked + .btn.btn-active-icon-gray-500 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-500 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-500 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-500[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-500 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-500.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-500.dropdown-toggle:after, .btn.btn-active-icon-gray-500:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-500:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-500:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-500.active.dropdown-toggle:after, .btn.btn-active-icon-gray-500.show.dropdown-toggle:after,
.btn.btn-active-icon-gray-500 .show.dropdown-toggle:after, .btn.btn-active-icon-gray-500[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-gray-500.dropdown-toggle:after {
  color: #a1a5b7;
}

.btn.btn-text-gray-500 {
  color: #a1a5b7;
}
.btn-check:checked + .btn.btn-active-text-gray-500, .btn-check:active + .btn.btn-active-text-gray-500, .btn.btn-active-text-gray-500:focus:not(.btn-active), .btn.btn-active-text-gray-500:hover:not(.btn-active), .btn.btn-active-text-gray-500:active:not(.btn-active), .btn.btn-active-text-gray-500.active, .btn.btn-active-text-gray-500.show,
.btn.btn-active-text-gray-500 .show, .btn.btn-active-text-gray-500[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-gray-500 {
  color: #a1a5b7;
}

.btn.btn-color-gray-600 {
  color: #7e8299;
}
.btn.btn-color-gray-600 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.btn.btn-color-gray-600 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-600.dropdown-toggle:after {
  color: #7e8299;
}
.btn-check:checked + .btn.btn-active-color-gray-600, .btn-check:active + .btn.btn-active-color-gray-600, .btn.btn-active-color-gray-600:focus:not(.btn-active), .btn.btn-active-color-gray-600:hover:not(.btn-active), .btn.btn-active-color-gray-600:active:not(.btn-active), .btn.btn-active-color-gray-600.active, .btn.btn-active-color-gray-600.show,
.btn.btn-active-color-gray-600 .show, .btn.btn-active-color-gray-600[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-gray-600 {
  color: #7e8299;
}
.btn-check:checked + .btn.btn-active-color-gray-600 i, .btn-check:active + .btn.btn-active-color-gray-600 i, .btn.btn-active-color-gray-600:focus:not(.btn-active) i, .btn.btn-active-color-gray-600:hover:not(.btn-active) i, .btn.btn-active-color-gray-600:active:not(.btn-active) i, .btn.btn-active-color-gray-600.active i, .btn.btn-active-color-gray-600.show i,
.btn.btn-active-color-gray-600 .show i, .btn.btn-active-color-gray-600[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-gray-600 i {
  color: #7e8299;
}
.btn-check:checked + .btn.btn-active-color-gray-600 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-600 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-600 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-600 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.btn-check:checked + .btn.btn-active-color-gray-600 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-600 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-600 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-600[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-600 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-600.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-600.dropdown-toggle:after, .btn.btn-active-color-gray-600:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-600:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-600:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-600.active.dropdown-toggle:after, .btn.btn-active-color-gray-600.show.dropdown-toggle:after,
.btn.btn-active-color-gray-600 .show.dropdown-toggle:after, .btn.btn-active-color-gray-600[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-gray-600.dropdown-toggle:after {
  color: #7e8299;
}

.btn.btn-icon-gray-600 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.btn.btn-icon-gray-600 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-600.dropdown-toggle:after {
  color: #7e8299;
}
.btn-check:checked + .btn.btn-active-icon-gray-600 i, .btn-check:active + .btn.btn-active-icon-gray-600 i, .btn.btn-active-icon-gray-600:focus:not(.btn-active) i, .btn.btn-active-icon-gray-600:hover:not(.btn-active) i, .btn.btn-active-icon-gray-600:active:not(.btn-active) i, .btn.btn-active-icon-gray-600.active i, .btn.btn-active-icon-gray-600.show i,
.btn.btn-active-icon-gray-600 .show i, .btn.btn-active-icon-gray-600[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-gray-600 i {
  color: #7e8299;
}
.btn-check:checked + .btn.btn-active-icon-gray-600 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-600 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-600 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-600 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.btn-check:checked + .btn.btn-active-icon-gray-600 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-600 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-600 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-600[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-600 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-600.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-600.dropdown-toggle:after, .btn.btn-active-icon-gray-600:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-600:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-600:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-600.active.dropdown-toggle:after, .btn.btn-active-icon-gray-600.show.dropdown-toggle:after,
.btn.btn-active-icon-gray-600 .show.dropdown-toggle:after, .btn.btn-active-icon-gray-600[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-gray-600.dropdown-toggle:after {
  color: #7e8299;
}

.btn.btn-text-gray-600 {
  color: #7e8299;
}
.btn-check:checked + .btn.btn-active-text-gray-600, .btn-check:active + .btn.btn-active-text-gray-600, .btn.btn-active-text-gray-600:focus:not(.btn-active), .btn.btn-active-text-gray-600:hover:not(.btn-active), .btn.btn-active-text-gray-600:active:not(.btn-active), .btn.btn-active-text-gray-600.active, .btn.btn-active-text-gray-600.show,
.btn.btn-active-text-gray-600 .show, .btn.btn-active-text-gray-600[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-gray-600 {
  color: #7e8299;
}

.btn.btn-color-gray-700 {
  color: #5e6278;
}
.btn.btn-color-gray-700 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5e6278;
}
.btn.btn-color-gray-700 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-700.dropdown-toggle:after {
  color: #5e6278;
}
.btn-check:checked + .btn.btn-active-color-gray-700, .btn-check:active + .btn.btn-active-color-gray-700, .btn.btn-active-color-gray-700:focus:not(.btn-active), .btn.btn-active-color-gray-700:hover:not(.btn-active), .btn.btn-active-color-gray-700:active:not(.btn-active), .btn.btn-active-color-gray-700.active, .btn.btn-active-color-gray-700.show,
.btn.btn-active-color-gray-700 .show, .btn.btn-active-color-gray-700[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-gray-700 {
  color: #5e6278;
}
.btn-check:checked + .btn.btn-active-color-gray-700 i, .btn-check:active + .btn.btn-active-color-gray-700 i, .btn.btn-active-color-gray-700:focus:not(.btn-active) i, .btn.btn-active-color-gray-700:hover:not(.btn-active) i, .btn.btn-active-color-gray-700:active:not(.btn-active) i, .btn.btn-active-color-gray-700.active i, .btn.btn-active-color-gray-700.show i,
.btn.btn-active-color-gray-700 .show i, .btn.btn-active-color-gray-700[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-gray-700 i {
  color: #5e6278;
}
.btn-check:checked + .btn.btn-active-color-gray-700 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-700 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-700 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-700 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5e6278;
}
.btn-check:checked + .btn.btn-active-color-gray-700 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-700 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-700 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-700[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-700 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-700.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-700.dropdown-toggle:after, .btn.btn-active-color-gray-700:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-700:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-700:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-700.active.dropdown-toggle:after, .btn.btn-active-color-gray-700.show.dropdown-toggle:after,
.btn.btn-active-color-gray-700 .show.dropdown-toggle:after, .btn.btn-active-color-gray-700[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-gray-700.dropdown-toggle:after {
  color: #5e6278;
}

.btn.btn-icon-gray-700 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5e6278;
}
.btn.btn-icon-gray-700 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-700.dropdown-toggle:after {
  color: #5e6278;
}
.btn-check:checked + .btn.btn-active-icon-gray-700 i, .btn-check:active + .btn.btn-active-icon-gray-700 i, .btn.btn-active-icon-gray-700:focus:not(.btn-active) i, .btn.btn-active-icon-gray-700:hover:not(.btn-active) i, .btn.btn-active-icon-gray-700:active:not(.btn-active) i, .btn.btn-active-icon-gray-700.active i, .btn.btn-active-icon-gray-700.show i,
.btn.btn-active-icon-gray-700 .show i, .btn.btn-active-icon-gray-700[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-gray-700 i {
  color: #5e6278;
}
.btn-check:checked + .btn.btn-active-icon-gray-700 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-700 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-700 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-700 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5e6278;
}
.btn-check:checked + .btn.btn-active-icon-gray-700 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-700 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-700 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-700[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-700 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-700.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-700.dropdown-toggle:after, .btn.btn-active-icon-gray-700:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-700:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-700:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-700.active.dropdown-toggle:after, .btn.btn-active-icon-gray-700.show.dropdown-toggle:after,
.btn.btn-active-icon-gray-700 .show.dropdown-toggle:after, .btn.btn-active-icon-gray-700[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-gray-700.dropdown-toggle:after {
  color: #5e6278;
}

.btn.btn-text-gray-700 {
  color: #5e6278;
}
.btn-check:checked + .btn.btn-active-text-gray-700, .btn-check:active + .btn.btn-active-text-gray-700, .btn.btn-active-text-gray-700:focus:not(.btn-active), .btn.btn-active-text-gray-700:hover:not(.btn-active), .btn.btn-active-text-gray-700:active:not(.btn-active), .btn.btn-active-text-gray-700.active, .btn.btn-active-text-gray-700.show,
.btn.btn-active-text-gray-700 .show, .btn.btn-active-text-gray-700[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-gray-700 {
  color: #5e6278;
}

.btn.btn-color-gray-800 {
  color: #3f4254;
}
.btn.btn-color-gray-800 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.btn.btn-color-gray-800 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-800.dropdown-toggle:after {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-active-color-gray-800, .btn-check:active + .btn.btn-active-color-gray-800, .btn.btn-active-color-gray-800:focus:not(.btn-active), .btn.btn-active-color-gray-800:hover:not(.btn-active), .btn.btn-active-color-gray-800:active:not(.btn-active), .btn.btn-active-color-gray-800.active, .btn.btn-active-color-gray-800.show,
.btn.btn-active-color-gray-800 .show, .btn.btn-active-color-gray-800[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-gray-800 {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-active-color-gray-800 i, .btn-check:active + .btn.btn-active-color-gray-800 i, .btn.btn-active-color-gray-800:focus:not(.btn-active) i, .btn.btn-active-color-gray-800:hover:not(.btn-active) i, .btn.btn-active-color-gray-800:active:not(.btn-active) i, .btn.btn-active-color-gray-800.active i, .btn.btn-active-color-gray-800.show i,
.btn.btn-active-color-gray-800 .show i, .btn.btn-active-color-gray-800[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-gray-800 i {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-active-color-gray-800 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-800 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-800 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-800 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.btn-check:checked + .btn.btn-active-color-gray-800 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-800 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-800 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-800[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-800 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-800.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-800.dropdown-toggle:after, .btn.btn-active-color-gray-800:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-800:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-800:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-800.active.dropdown-toggle:after, .btn.btn-active-color-gray-800.show.dropdown-toggle:after,
.btn.btn-active-color-gray-800 .show.dropdown-toggle:after, .btn.btn-active-color-gray-800[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-gray-800.dropdown-toggle:after {
  color: #3f4254;
}

.btn.btn-icon-gray-800 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.btn.btn-icon-gray-800 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-800.dropdown-toggle:after {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-active-icon-gray-800 i, .btn-check:active + .btn.btn-active-icon-gray-800 i, .btn.btn-active-icon-gray-800:focus:not(.btn-active) i, .btn.btn-active-icon-gray-800:hover:not(.btn-active) i, .btn.btn-active-icon-gray-800:active:not(.btn-active) i, .btn.btn-active-icon-gray-800.active i, .btn.btn-active-icon-gray-800.show i,
.btn.btn-active-icon-gray-800 .show i, .btn.btn-active-icon-gray-800[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-gray-800 i {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-active-icon-gray-800 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-800 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-800 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-800 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.btn-check:checked + .btn.btn-active-icon-gray-800 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-800 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-800 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-800[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-800 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-800.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-800.dropdown-toggle:after, .btn.btn-active-icon-gray-800:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-800:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-800:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-800.active.dropdown-toggle:after, .btn.btn-active-icon-gray-800.show.dropdown-toggle:after,
.btn.btn-active-icon-gray-800 .show.dropdown-toggle:after, .btn.btn-active-icon-gray-800[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-gray-800.dropdown-toggle:after {
  color: #3f4254;
}

.btn.btn-text-gray-800 {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-active-text-gray-800, .btn-check:active + .btn.btn-active-text-gray-800, .btn.btn-active-text-gray-800:focus:not(.btn-active), .btn.btn-active-text-gray-800:hover:not(.btn-active), .btn.btn-active-text-gray-800:active:not(.btn-active), .btn.btn-active-text-gray-800.active, .btn.btn-active-text-gray-800.show,
.btn.btn-active-text-gray-800 .show, .btn.btn-active-text-gray-800[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-gray-800 {
  color: #3f4254;
}

.btn.btn-color-gray-900 {
  color: #181c32;
}
.btn.btn-color-gray-900 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181c32;
}
.btn.btn-color-gray-900 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray-900.dropdown-toggle:after {
  color: #181c32;
}
.btn-check:checked + .btn.btn-active-color-gray-900, .btn-check:active + .btn.btn-active-color-gray-900, .btn.btn-active-color-gray-900:focus:not(.btn-active), .btn.btn-active-color-gray-900:hover:not(.btn-active), .btn.btn-active-color-gray-900:active:not(.btn-active), .btn.btn-active-color-gray-900.active, .btn.btn-active-color-gray-900.show,
.btn.btn-active-color-gray-900 .show, .btn.btn-active-color-gray-900[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-gray-900 {
  color: #181c32;
}
.btn-check:checked + .btn.btn-active-color-gray-900 i, .btn-check:active + .btn.btn-active-color-gray-900 i, .btn.btn-active-color-gray-900:focus:not(.btn-active) i, .btn.btn-active-color-gray-900:hover:not(.btn-active) i, .btn.btn-active-color-gray-900:active:not(.btn-active) i, .btn.btn-active-color-gray-900.active i, .btn.btn-active-color-gray-900.show i,
.btn.btn-active-color-gray-900 .show i, .btn.btn-active-color-gray-900[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-gray-900 i {
  color: #181c32;
}
.btn-check:checked + .btn.btn-active-color-gray-900 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-900 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-900 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-900 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181c32;
}
.btn-check:checked + .btn.btn-active-color-gray-900 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray-900 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray-900 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray-900[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray-900 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray-900.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray-900.dropdown-toggle:after, .btn.btn-active-color-gray-900:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-900:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-900:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray-900.active.dropdown-toggle:after, .btn.btn-active-color-gray-900.show.dropdown-toggle:after,
.btn.btn-active-color-gray-900 .show.dropdown-toggle:after, .btn.btn-active-color-gray-900[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-gray-900.dropdown-toggle:after {
  color: #181c32;
}

.btn.btn-icon-gray-900 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181c32;
}
.btn.btn-icon-gray-900 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray-900.dropdown-toggle:after {
  color: #181c32;
}
.btn-check:checked + .btn.btn-active-icon-gray-900 i, .btn-check:active + .btn.btn-active-icon-gray-900 i, .btn.btn-active-icon-gray-900:focus:not(.btn-active) i, .btn.btn-active-icon-gray-900:hover:not(.btn-active) i, .btn.btn-active-icon-gray-900:active:not(.btn-active) i, .btn.btn-active-icon-gray-900.active i, .btn.btn-active-icon-gray-900.show i,
.btn.btn-active-icon-gray-900 .show i, .btn.btn-active-icon-gray-900[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-gray-900 i {
  color: #181c32;
}
.btn-check:checked + .btn.btn-active-icon-gray-900 .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-900 .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-900 .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-900 .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181c32;
}
.btn-check:checked + .btn.btn-active-icon-gray-900 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray-900 .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray-900 .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray-900[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray-900 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray-900.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray-900.dropdown-toggle:after, .btn.btn-active-icon-gray-900:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-900:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-900:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray-900.active.dropdown-toggle:after, .btn.btn-active-icon-gray-900.show.dropdown-toggle:after,
.btn.btn-active-icon-gray-900 .show.dropdown-toggle:after, .btn.btn-active-icon-gray-900[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-gray-900.dropdown-toggle:after {
  color: #181c32;
}

.btn.btn-text-gray-900 {
  color: #181c32;
}
.btn-check:checked + .btn.btn-active-text-gray-900, .btn-check:active + .btn.btn-active-text-gray-900, .btn.btn-active-text-gray-900:focus:not(.btn-active), .btn.btn-active-text-gray-900:hover:not(.btn-active), .btn.btn-active-text-gray-900:active:not(.btn-active), .btn.btn-active-text-gray-900.active, .btn.btn-active-text-gray-900.show,
.btn.btn-active-text-gray-900 .show, .btn.btn-active-text-gray-900[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-gray-900 {
  color: #181c32;
}

.btn.btn-color-blue {
  color: #3699FF;
}
.btn.btn-color-blue .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3699FF;
}
.btn.btn-color-blue .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-blue.dropdown-toggle:after {
  color: #3699FF;
}
.btn-check:checked + .btn.btn-active-color-blue, .btn-check:active + .btn.btn-active-color-blue, .btn.btn-active-color-blue:focus:not(.btn-active), .btn.btn-active-color-blue:hover:not(.btn-active), .btn.btn-active-color-blue:active:not(.btn-active), .btn.btn-active-color-blue.active, .btn.btn-active-color-blue.show,
.btn.btn-active-color-blue .show, .btn.btn-active-color-blue[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-blue {
  color: #3699FF;
}
.btn-check:checked + .btn.btn-active-color-blue i, .btn-check:active + .btn.btn-active-color-blue i, .btn.btn-active-color-blue:focus:not(.btn-active) i, .btn.btn-active-color-blue:hover:not(.btn-active) i, .btn.btn-active-color-blue:active:not(.btn-active) i, .btn.btn-active-color-blue.active i, .btn.btn-active-color-blue.show i,
.btn.btn-active-color-blue .show i, .btn.btn-active-color-blue[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-blue i {
  color: #3699FF;
}
.btn-check:checked + .btn.btn-active-color-blue .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-blue .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-blue:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-blue:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-blue:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-blue.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-blue.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-blue .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-blue[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-blue .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3699FF;
}
.btn-check:checked + .btn.btn-active-color-blue .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-blue .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-blue:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-blue:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-blue:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-blue.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-blue.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-blue .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-blue[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-blue .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-blue.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-blue.dropdown-toggle:after, .btn.btn-active-color-blue:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-blue:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-blue:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-blue.active.dropdown-toggle:after, .btn.btn-active-color-blue.show.dropdown-toggle:after,
.btn.btn-active-color-blue .show.dropdown-toggle:after, .btn.btn-active-color-blue[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-blue.dropdown-toggle:after {
  color: #3699FF;
}

.btn.btn-icon-blue .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3699FF;
}
.btn.btn-icon-blue .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-blue.dropdown-toggle:after {
  color: #3699FF;
}
.btn-check:checked + .btn.btn-active-icon-blue i, .btn-check:active + .btn.btn-active-icon-blue i, .btn.btn-active-icon-blue:focus:not(.btn-active) i, .btn.btn-active-icon-blue:hover:not(.btn-active) i, .btn.btn-active-icon-blue:active:not(.btn-active) i, .btn.btn-active-icon-blue.active i, .btn.btn-active-icon-blue.show i,
.btn.btn-active-icon-blue .show i, .btn.btn-active-icon-blue[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-blue i {
  color: #3699FF;
}
.btn-check:checked + .btn.btn-active-icon-blue .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-blue .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-blue:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-blue:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-blue:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-blue.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-blue.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-blue .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-blue[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-blue .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3699FF;
}
.btn-check:checked + .btn.btn-active-icon-blue .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-blue .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-blue:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-blue:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-blue:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-blue.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-blue.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-blue .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-blue[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-blue .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-blue.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-blue.dropdown-toggle:after, .btn.btn-active-icon-blue:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-blue:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-blue:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-blue.active.dropdown-toggle:after, .btn.btn-active-icon-blue.show.dropdown-toggle:after,
.btn.btn-active-icon-blue .show.dropdown-toggle:after, .btn.btn-active-icon-blue[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-blue.dropdown-toggle:after {
  color: #3699FF;
}

.btn.btn-text-blue {
  color: #3699FF;
}
.btn-check:checked + .btn.btn-active-text-blue, .btn-check:active + .btn.btn-active-text-blue, .btn.btn-active-text-blue:focus:not(.btn-active), .btn.btn-active-text-blue:hover:not(.btn-active), .btn.btn-active-text-blue:active:not(.btn-active), .btn.btn-active-text-blue.active, .btn.btn-active-text-blue.show,
.btn.btn-active-text-blue .show, .btn.btn-active-text-blue[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-blue {
  color: #3699FF;
}

.btn.btn-color-indigo {
  color: #6610f2;
}
.btn.btn-color-indigo .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #6610f2;
}
.btn.btn-color-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-indigo.dropdown-toggle:after {
  color: #6610f2;
}
.btn-check:checked + .btn.btn-active-color-indigo, .btn-check:active + .btn.btn-active-color-indigo, .btn.btn-active-color-indigo:focus:not(.btn-active), .btn.btn-active-color-indigo:hover:not(.btn-active), .btn.btn-active-color-indigo:active:not(.btn-active), .btn.btn-active-color-indigo.active, .btn.btn-active-color-indigo.show,
.btn.btn-active-color-indigo .show, .btn.btn-active-color-indigo[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-indigo {
  color: #6610f2;
}
.btn-check:checked + .btn.btn-active-color-indigo i, .btn-check:active + .btn.btn-active-color-indigo i, .btn.btn-active-color-indigo:focus:not(.btn-active) i, .btn.btn-active-color-indigo:hover:not(.btn-active) i, .btn.btn-active-color-indigo:active:not(.btn-active) i, .btn.btn-active-color-indigo.active i, .btn.btn-active-color-indigo.show i,
.btn.btn-active-color-indigo .show i, .btn.btn-active-color-indigo[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-indigo i {
  color: #6610f2;
}
.btn-check:checked + .btn.btn-active-color-indigo .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-indigo .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-indigo:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-indigo:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-indigo:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-indigo.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-indigo.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-indigo .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-indigo[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-indigo .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #6610f2;
}
.btn-check:checked + .btn.btn-active-color-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-indigo:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-indigo:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-indigo:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-indigo.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-indigo.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-indigo .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-indigo[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-indigo.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-indigo.dropdown-toggle:after, .btn.btn-active-color-indigo:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-indigo:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-indigo:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-indigo.active.dropdown-toggle:after, .btn.btn-active-color-indigo.show.dropdown-toggle:after,
.btn.btn-active-color-indigo .show.dropdown-toggle:after, .btn.btn-active-color-indigo[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-indigo.dropdown-toggle:after {
  color: #6610f2;
}

.btn.btn-icon-indigo .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #6610f2;
}
.btn.btn-icon-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-indigo.dropdown-toggle:after {
  color: #6610f2;
}
.btn-check:checked + .btn.btn-active-icon-indigo i, .btn-check:active + .btn.btn-active-icon-indigo i, .btn.btn-active-icon-indigo:focus:not(.btn-active) i, .btn.btn-active-icon-indigo:hover:not(.btn-active) i, .btn.btn-active-icon-indigo:active:not(.btn-active) i, .btn.btn-active-icon-indigo.active i, .btn.btn-active-icon-indigo.show i,
.btn.btn-active-icon-indigo .show i, .btn.btn-active-icon-indigo[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-indigo i {
  color: #6610f2;
}
.btn-check:checked + .btn.btn-active-icon-indigo .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-indigo .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-indigo:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-indigo:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-indigo:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-indigo.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-indigo.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-indigo .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-indigo[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-indigo .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #6610f2;
}
.btn-check:checked + .btn.btn-active-icon-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-indigo:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-indigo:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-indigo:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-indigo.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-indigo.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-indigo .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-indigo[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-indigo .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-indigo.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-indigo.dropdown-toggle:after, .btn.btn-active-icon-indigo:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-indigo:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-indigo:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-indigo.active.dropdown-toggle:after, .btn.btn-active-icon-indigo.show.dropdown-toggle:after,
.btn.btn-active-icon-indigo .show.dropdown-toggle:after, .btn.btn-active-icon-indigo[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-indigo.dropdown-toggle:after {
  color: #6610f2;
}

.btn.btn-text-indigo {
  color: #6610f2;
}
.btn-check:checked + .btn.btn-active-text-indigo, .btn-check:active + .btn.btn-active-text-indigo, .btn.btn-active-text-indigo:focus:not(.btn-active), .btn.btn-active-text-indigo:hover:not(.btn-active), .btn.btn-active-text-indigo:active:not(.btn-active), .btn.btn-active-text-indigo.active, .btn.btn-active-text-indigo.show,
.btn.btn-active-text-indigo .show, .btn.btn-active-text-indigo[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-indigo {
  color: #6610f2;
}

.btn.btn-color-purple {
  color: #5F5CF1;
}
.btn.btn-color-purple .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.btn.btn-color-purple .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-purple.dropdown-toggle:after {
  color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-color-purple, .btn-check:active + .btn.btn-active-color-purple, .btn.btn-active-color-purple:focus:not(.btn-active), .btn.btn-active-color-purple:hover:not(.btn-active), .btn.btn-active-color-purple:active:not(.btn-active), .btn.btn-active-color-purple.active, .btn.btn-active-color-purple.show,
.btn.btn-active-color-purple .show, .btn.btn-active-color-purple[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-purple {
  color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-color-purple i, .btn-check:active + .btn.btn-active-color-purple i, .btn.btn-active-color-purple:focus:not(.btn-active) i, .btn.btn-active-color-purple:hover:not(.btn-active) i, .btn.btn-active-color-purple:active:not(.btn-active) i, .btn.btn-active-color-purple.active i, .btn.btn-active-color-purple.show i,
.btn.btn-active-color-purple .show i, .btn.btn-active-color-purple[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-purple i {
  color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-color-purple .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-purple .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-purple:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-purple:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-purple:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-purple.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-purple.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-purple .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-purple[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-purple .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-color-purple .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-purple .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-purple:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-purple:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-purple:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-purple.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-purple.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-purple .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-purple[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-purple .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-purple.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-purple.dropdown-toggle:after, .btn.btn-active-color-purple:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-purple:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-purple:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-purple.active.dropdown-toggle:after, .btn.btn-active-color-purple.show.dropdown-toggle:after,
.btn.btn-active-color-purple .show.dropdown-toggle:after, .btn.btn-active-color-purple[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-purple.dropdown-toggle:after {
  color: #5F5CF1;
}

.btn.btn-icon-purple .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.btn.btn-icon-purple .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-purple.dropdown-toggle:after {
  color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-icon-purple i, .btn-check:active + .btn.btn-active-icon-purple i, .btn.btn-active-icon-purple:focus:not(.btn-active) i, .btn.btn-active-icon-purple:hover:not(.btn-active) i, .btn.btn-active-icon-purple:active:not(.btn-active) i, .btn.btn-active-icon-purple.active i, .btn.btn-active-icon-purple.show i,
.btn.btn-active-icon-purple .show i, .btn.btn-active-icon-purple[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-purple i {
  color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-icon-purple .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-purple .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-purple:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-purple:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-purple:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-purple.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-purple.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-purple .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-purple[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-purple .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-icon-purple .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-purple .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-purple:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-purple:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-purple:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-purple.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-purple.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-purple .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-purple[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-purple .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-purple.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-purple.dropdown-toggle:after, .btn.btn-active-icon-purple:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-purple:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-purple:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-purple.active.dropdown-toggle:after, .btn.btn-active-icon-purple.show.dropdown-toggle:after,
.btn.btn-active-icon-purple .show.dropdown-toggle:after, .btn.btn-active-icon-purple[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-purple.dropdown-toggle:after {
  color: #5F5CF1;
}

.btn.btn-text-purple {
  color: #5F5CF1;
}
.btn-check:checked + .btn.btn-active-text-purple, .btn-check:active + .btn.btn-active-text-purple, .btn.btn-active-text-purple:focus:not(.btn-active), .btn.btn-active-text-purple:hover:not(.btn-active), .btn.btn-active-text-purple:active:not(.btn-active), .btn.btn-active-text-purple.active, .btn.btn-active-text-purple.show,
.btn.btn-active-text-purple .show, .btn.btn-active-text-purple[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-purple {
  color: #5F5CF1;
}

.btn.btn-color-pink {
  color: #d63384;
}
.btn.btn-color-pink .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #d63384;
}
.btn.btn-color-pink .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-pink.dropdown-toggle:after {
  color: #d63384;
}
.btn-check:checked + .btn.btn-active-color-pink, .btn-check:active + .btn.btn-active-color-pink, .btn.btn-active-color-pink:focus:not(.btn-active), .btn.btn-active-color-pink:hover:not(.btn-active), .btn.btn-active-color-pink:active:not(.btn-active), .btn.btn-active-color-pink.active, .btn.btn-active-color-pink.show,
.btn.btn-active-color-pink .show, .btn.btn-active-color-pink[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-pink {
  color: #d63384;
}
.btn-check:checked + .btn.btn-active-color-pink i, .btn-check:active + .btn.btn-active-color-pink i, .btn.btn-active-color-pink:focus:not(.btn-active) i, .btn.btn-active-color-pink:hover:not(.btn-active) i, .btn.btn-active-color-pink:active:not(.btn-active) i, .btn.btn-active-color-pink.active i, .btn.btn-active-color-pink.show i,
.btn.btn-active-color-pink .show i, .btn.btn-active-color-pink[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-pink i {
  color: #d63384;
}
.btn-check:checked + .btn.btn-active-color-pink .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-pink .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-pink:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-pink:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-pink:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-pink.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-pink.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-pink .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-pink[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-pink .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #d63384;
}
.btn-check:checked + .btn.btn-active-color-pink .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-pink .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-pink:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-pink:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-pink:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-pink.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-pink.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-pink .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-pink[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-pink .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-pink.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-pink.dropdown-toggle:after, .btn.btn-active-color-pink:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-pink:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-pink:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-pink.active.dropdown-toggle:after, .btn.btn-active-color-pink.show.dropdown-toggle:after,
.btn.btn-active-color-pink .show.dropdown-toggle:after, .btn.btn-active-color-pink[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-pink.dropdown-toggle:after {
  color: #d63384;
}

.btn.btn-icon-pink .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #d63384;
}
.btn.btn-icon-pink .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-pink.dropdown-toggle:after {
  color: #d63384;
}
.btn-check:checked + .btn.btn-active-icon-pink i, .btn-check:active + .btn.btn-active-icon-pink i, .btn.btn-active-icon-pink:focus:not(.btn-active) i, .btn.btn-active-icon-pink:hover:not(.btn-active) i, .btn.btn-active-icon-pink:active:not(.btn-active) i, .btn.btn-active-icon-pink.active i, .btn.btn-active-icon-pink.show i,
.btn.btn-active-icon-pink .show i, .btn.btn-active-icon-pink[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-pink i {
  color: #d63384;
}
.btn-check:checked + .btn.btn-active-icon-pink .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-pink .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-pink:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-pink:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-pink:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-pink.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-pink.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-pink .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-pink[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-pink .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #d63384;
}
.btn-check:checked + .btn.btn-active-icon-pink .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-pink .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-pink:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-pink:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-pink:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-pink.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-pink.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-pink .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-pink[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-pink .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-pink.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-pink.dropdown-toggle:after, .btn.btn-active-icon-pink:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-pink:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-pink:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-pink.active.dropdown-toggle:after, .btn.btn-active-icon-pink.show.dropdown-toggle:after,
.btn.btn-active-icon-pink .show.dropdown-toggle:after, .btn.btn-active-icon-pink[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-pink.dropdown-toggle:after {
  color: #d63384;
}

.btn.btn-text-pink {
  color: #d63384;
}
.btn-check:checked + .btn.btn-active-text-pink, .btn-check:active + .btn.btn-active-text-pink, .btn.btn-active-text-pink:focus:not(.btn-active), .btn.btn-active-text-pink:hover:not(.btn-active), .btn.btn-active-text-pink:active:not(.btn-active), .btn.btn-active-text-pink.active, .btn.btn-active-text-pink.show,
.btn.btn-active-text-pink .show, .btn.btn-active-text-pink[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-pink {
  color: #d63384;
}

.btn.btn-color-red {
  color: #F65464;
}
.btn.btn-color-red .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.btn.btn-color-red .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-red.dropdown-toggle:after {
  color: #F65464;
}
.btn-check:checked + .btn.btn-active-color-red, .btn-check:active + .btn.btn-active-color-red, .btn.btn-active-color-red:focus:not(.btn-active), .btn.btn-active-color-red:hover:not(.btn-active), .btn.btn-active-color-red:active:not(.btn-active), .btn.btn-active-color-red.active, .btn.btn-active-color-red.show,
.btn.btn-active-color-red .show, .btn.btn-active-color-red[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-red {
  color: #F65464;
}
.btn-check:checked + .btn.btn-active-color-red i, .btn-check:active + .btn.btn-active-color-red i, .btn.btn-active-color-red:focus:not(.btn-active) i, .btn.btn-active-color-red:hover:not(.btn-active) i, .btn.btn-active-color-red:active:not(.btn-active) i, .btn.btn-active-color-red.active i, .btn.btn-active-color-red.show i,
.btn.btn-active-color-red .show i, .btn.btn-active-color-red[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-red i {
  color: #F65464;
}
.btn-check:checked + .btn.btn-active-color-red .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-red .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-red:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-red:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-red:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-red.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-red.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-red .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-red[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-red .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.btn-check:checked + .btn.btn-active-color-red .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-red .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-red:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-red:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-red:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-red.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-red.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-red .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-red[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-red .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-red.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-red.dropdown-toggle:after, .btn.btn-active-color-red:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-red:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-red:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-red.active.dropdown-toggle:after, .btn.btn-active-color-red.show.dropdown-toggle:after,
.btn.btn-active-color-red .show.dropdown-toggle:after, .btn.btn-active-color-red[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-red.dropdown-toggle:after {
  color: #F65464;
}

.btn.btn-icon-red .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.btn.btn-icon-red .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-red.dropdown-toggle:after {
  color: #F65464;
}
.btn-check:checked + .btn.btn-active-icon-red i, .btn-check:active + .btn.btn-active-icon-red i, .btn.btn-active-icon-red:focus:not(.btn-active) i, .btn.btn-active-icon-red:hover:not(.btn-active) i, .btn.btn-active-icon-red:active:not(.btn-active) i, .btn.btn-active-icon-red.active i, .btn.btn-active-icon-red.show i,
.btn.btn-active-icon-red .show i, .btn.btn-active-icon-red[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-red i {
  color: #F65464;
}
.btn-check:checked + .btn.btn-active-icon-red .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-red .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-red:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-red:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-red:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-red.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-red.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-red .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-red[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-red .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.btn-check:checked + .btn.btn-active-icon-red .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-red .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-red:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-red:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-red:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-red.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-red.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-red .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-red[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-red .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-red.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-red.dropdown-toggle:after, .btn.btn-active-icon-red:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-red:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-red:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-red.active.dropdown-toggle:after, .btn.btn-active-icon-red.show.dropdown-toggle:after,
.btn.btn-active-icon-red .show.dropdown-toggle:after, .btn.btn-active-icon-red[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-red.dropdown-toggle:after {
  color: #F65464;
}

.btn.btn-text-red {
  color: #F65464;
}
.btn-check:checked + .btn.btn-active-text-red, .btn-check:active + .btn.btn-active-text-red, .btn.btn-active-text-red:focus:not(.btn-active), .btn.btn-active-text-red:hover:not(.btn-active), .btn.btn-active-text-red:active:not(.btn-active), .btn.btn-active-text-red.active, .btn.btn-active-text-red.show,
.btn.btn-active-text-red .show, .btn.btn-active-text-red[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-red {
  color: #F65464;
}

.btn.btn-color-orange {
  color: #fd7e14;
}
.btn.btn-color-orange .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #fd7e14;
}
.btn.btn-color-orange .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-orange.dropdown-toggle:after {
  color: #fd7e14;
}
.btn-check:checked + .btn.btn-active-color-orange, .btn-check:active + .btn.btn-active-color-orange, .btn.btn-active-color-orange:focus:not(.btn-active), .btn.btn-active-color-orange:hover:not(.btn-active), .btn.btn-active-color-orange:active:not(.btn-active), .btn.btn-active-color-orange.active, .btn.btn-active-color-orange.show,
.btn.btn-active-color-orange .show, .btn.btn-active-color-orange[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-orange {
  color: #fd7e14;
}
.btn-check:checked + .btn.btn-active-color-orange i, .btn-check:active + .btn.btn-active-color-orange i, .btn.btn-active-color-orange:focus:not(.btn-active) i, .btn.btn-active-color-orange:hover:not(.btn-active) i, .btn.btn-active-color-orange:active:not(.btn-active) i, .btn.btn-active-color-orange.active i, .btn.btn-active-color-orange.show i,
.btn.btn-active-color-orange .show i, .btn.btn-active-color-orange[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-orange i {
  color: #fd7e14;
}
.btn-check:checked + .btn.btn-active-color-orange .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-orange .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-orange:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-orange:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-orange:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-orange.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-orange.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-orange .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-orange[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-orange .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #fd7e14;
}
.btn-check:checked + .btn.btn-active-color-orange .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-orange .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-orange:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-orange:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-orange:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-orange.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-orange.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-orange .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-orange[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-orange .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-orange.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-orange.dropdown-toggle:after, .btn.btn-active-color-orange:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-orange:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-orange:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-orange.active.dropdown-toggle:after, .btn.btn-active-color-orange.show.dropdown-toggle:after,
.btn.btn-active-color-orange .show.dropdown-toggle:after, .btn.btn-active-color-orange[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-orange.dropdown-toggle:after {
  color: #fd7e14;
}

.btn.btn-icon-orange .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #fd7e14;
}
.btn.btn-icon-orange .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-orange.dropdown-toggle:after {
  color: #fd7e14;
}
.btn-check:checked + .btn.btn-active-icon-orange i, .btn-check:active + .btn.btn-active-icon-orange i, .btn.btn-active-icon-orange:focus:not(.btn-active) i, .btn.btn-active-icon-orange:hover:not(.btn-active) i, .btn.btn-active-icon-orange:active:not(.btn-active) i, .btn.btn-active-icon-orange.active i, .btn.btn-active-icon-orange.show i,
.btn.btn-active-icon-orange .show i, .btn.btn-active-icon-orange[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-orange i {
  color: #fd7e14;
}
.btn-check:checked + .btn.btn-active-icon-orange .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-orange .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-orange:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-orange:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-orange:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-orange.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-orange.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-orange .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-orange[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-orange .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #fd7e14;
}
.btn-check:checked + .btn.btn-active-icon-orange .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-orange .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-orange:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-orange:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-orange:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-orange.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-orange.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-orange .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-orange[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-orange .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-orange.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-orange.dropdown-toggle:after, .btn.btn-active-icon-orange:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-orange:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-orange:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-orange.active.dropdown-toggle:after, .btn.btn-active-icon-orange.show.dropdown-toggle:after,
.btn.btn-active-icon-orange .show.dropdown-toggle:after, .btn.btn-active-icon-orange[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-orange.dropdown-toggle:after {
  color: #fd7e14;
}

.btn.btn-text-orange {
  color: #fd7e14;
}
.btn-check:checked + .btn.btn-active-text-orange, .btn-check:active + .btn.btn-active-text-orange, .btn.btn-active-text-orange:focus:not(.btn-active), .btn.btn-active-text-orange:hover:not(.btn-active), .btn.btn-active-text-orange:active:not(.btn-active), .btn.btn-active-text-orange.active, .btn.btn-active-text-orange.show,
.btn.btn-active-text-orange .show, .btn.btn-active-text-orange[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-orange {
  color: #fd7e14;
}

.btn.btn-color-yellow {
  color: #FFA800;
}
.btn.btn-color-yellow .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.btn.btn-color-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-yellow.dropdown-toggle:after {
  color: #FFA800;
}
.btn-check:checked + .btn.btn-active-color-yellow, .btn-check:active + .btn.btn-active-color-yellow, .btn.btn-active-color-yellow:focus:not(.btn-active), .btn.btn-active-color-yellow:hover:not(.btn-active), .btn.btn-active-color-yellow:active:not(.btn-active), .btn.btn-active-color-yellow.active, .btn.btn-active-color-yellow.show,
.btn.btn-active-color-yellow .show, .btn.btn-active-color-yellow[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-yellow {
  color: #FFA800;
}
.btn-check:checked + .btn.btn-active-color-yellow i, .btn-check:active + .btn.btn-active-color-yellow i, .btn.btn-active-color-yellow:focus:not(.btn-active) i, .btn.btn-active-color-yellow:hover:not(.btn-active) i, .btn.btn-active-color-yellow:active:not(.btn-active) i, .btn.btn-active-color-yellow.active i, .btn.btn-active-color-yellow.show i,
.btn.btn-active-color-yellow .show i, .btn.btn-active-color-yellow[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-yellow i {
  color: #FFA800;
}
.btn-check:checked + .btn.btn-active-color-yellow .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-yellow .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-yellow:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-yellow:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-yellow:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-yellow.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-yellow.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-yellow .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-yellow[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-yellow .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.btn-check:checked + .btn.btn-active-color-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-yellow:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-yellow:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-yellow:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-yellow.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-yellow.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-yellow .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-yellow[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-yellow.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-yellow.dropdown-toggle:after, .btn.btn-active-color-yellow:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-yellow:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-yellow:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-yellow.active.dropdown-toggle:after, .btn.btn-active-color-yellow.show.dropdown-toggle:after,
.btn.btn-active-color-yellow .show.dropdown-toggle:after, .btn.btn-active-color-yellow[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-yellow.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-icon-yellow .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.btn.btn-icon-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-yellow.dropdown-toggle:after {
  color: #FFA800;
}
.btn-check:checked + .btn.btn-active-icon-yellow i, .btn-check:active + .btn.btn-active-icon-yellow i, .btn.btn-active-icon-yellow:focus:not(.btn-active) i, .btn.btn-active-icon-yellow:hover:not(.btn-active) i, .btn.btn-active-icon-yellow:active:not(.btn-active) i, .btn.btn-active-icon-yellow.active i, .btn.btn-active-icon-yellow.show i,
.btn.btn-active-icon-yellow .show i, .btn.btn-active-icon-yellow[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-yellow i {
  color: #FFA800;
}
.btn-check:checked + .btn.btn-active-icon-yellow .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-yellow .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-yellow:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-yellow:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-yellow:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-yellow.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-yellow.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-yellow .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-yellow[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-yellow .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.btn-check:checked + .btn.btn-active-icon-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-yellow:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-yellow:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-yellow:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-yellow.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-yellow.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-yellow .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-yellow[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-yellow .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-yellow.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-yellow.dropdown-toggle:after, .btn.btn-active-icon-yellow:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-yellow:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-yellow:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-yellow.active.dropdown-toggle:after, .btn.btn-active-icon-yellow.show.dropdown-toggle:after,
.btn.btn-active-icon-yellow .show.dropdown-toggle:after, .btn.btn-active-icon-yellow[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-yellow.dropdown-toggle:after {
  color: #FFA800;
}

.btn.btn-text-yellow {
  color: #FFA800;
}
.btn-check:checked + .btn.btn-active-text-yellow, .btn-check:active + .btn.btn-active-text-yellow, .btn.btn-active-text-yellow:focus:not(.btn-active), .btn.btn-active-text-yellow:hover:not(.btn-active), .btn.btn-active-text-yellow:active:not(.btn-active), .btn.btn-active-text-yellow.active, .btn.btn-active-text-yellow.show,
.btn.btn-active-text-yellow .show, .btn.btn-active-text-yellow[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-yellow {
  color: #FFA800;
}

.btn.btn-color-green {
  color: #1BC5BC;
}
.btn.btn-color-green .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.btn.btn-color-green .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-green.dropdown-toggle:after {
  color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-color-green, .btn-check:active + .btn.btn-active-color-green, .btn.btn-active-color-green:focus:not(.btn-active), .btn.btn-active-color-green:hover:not(.btn-active), .btn.btn-active-color-green:active:not(.btn-active), .btn.btn-active-color-green.active, .btn.btn-active-color-green.show,
.btn.btn-active-color-green .show, .btn.btn-active-color-green[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-green {
  color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-color-green i, .btn-check:active + .btn.btn-active-color-green i, .btn.btn-active-color-green:focus:not(.btn-active) i, .btn.btn-active-color-green:hover:not(.btn-active) i, .btn.btn-active-color-green:active:not(.btn-active) i, .btn.btn-active-color-green.active i, .btn.btn-active-color-green.show i,
.btn.btn-active-color-green .show i, .btn.btn-active-color-green[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-green i {
  color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-color-green .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-green .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-green:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-green:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-green:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-green.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-green.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-green .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-green[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-green .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-color-green .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-green .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-green:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-green:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-green:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-green.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-green.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-green .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-green[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-green .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-green.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-green.dropdown-toggle:after, .btn.btn-active-color-green:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-green:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-green:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-green.active.dropdown-toggle:after, .btn.btn-active-color-green.show.dropdown-toggle:after,
.btn.btn-active-color-green .show.dropdown-toggle:after, .btn.btn-active-color-green[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-green.dropdown-toggle:after {
  color: #1BC5BC;
}

.btn.btn-icon-green .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.btn.btn-icon-green .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-green.dropdown-toggle:after {
  color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-icon-green i, .btn-check:active + .btn.btn-active-icon-green i, .btn.btn-active-icon-green:focus:not(.btn-active) i, .btn.btn-active-icon-green:hover:not(.btn-active) i, .btn.btn-active-icon-green:active:not(.btn-active) i, .btn.btn-active-icon-green.active i, .btn.btn-active-icon-green.show i,
.btn.btn-active-icon-green .show i, .btn.btn-active-icon-green[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-green i {
  color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-icon-green .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-green .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-green:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-green:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-green:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-green.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-green.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-green .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-green[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-green .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-icon-green .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-green .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-green:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-green:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-green:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-green.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-green.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-green .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-green[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-green .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-green.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-green.dropdown-toggle:after, .btn.btn-active-icon-green:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-green:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-green:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-green.active.dropdown-toggle:after, .btn.btn-active-icon-green.show.dropdown-toggle:after,
.btn.btn-active-icon-green .show.dropdown-toggle:after, .btn.btn-active-icon-green[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-green.dropdown-toggle:after {
  color: #1BC5BC;
}

.btn.btn-text-green {
  color: #1BC5BC;
}
.btn-check:checked + .btn.btn-active-text-green, .btn-check:active + .btn.btn-active-text-green, .btn.btn-active-text-green:focus:not(.btn-active), .btn.btn-active-text-green:hover:not(.btn-active), .btn.btn-active-text-green:active:not(.btn-active), .btn.btn-active-text-green.active, .btn.btn-active-text-green.show,
.btn.btn-active-text-green .show, .btn.btn-active-text-green[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-green {
  color: #1BC5BC;
}

.btn.btn-color-teal {
  color: #20c997;
}
.btn.btn-color-teal .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #20c997;
}
.btn.btn-color-teal .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-teal.dropdown-toggle:after {
  color: #20c997;
}
.btn-check:checked + .btn.btn-active-color-teal, .btn-check:active + .btn.btn-active-color-teal, .btn.btn-active-color-teal:focus:not(.btn-active), .btn.btn-active-color-teal:hover:not(.btn-active), .btn.btn-active-color-teal:active:not(.btn-active), .btn.btn-active-color-teal.active, .btn.btn-active-color-teal.show,
.btn.btn-active-color-teal .show, .btn.btn-active-color-teal[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-teal {
  color: #20c997;
}
.btn-check:checked + .btn.btn-active-color-teal i, .btn-check:active + .btn.btn-active-color-teal i, .btn.btn-active-color-teal:focus:not(.btn-active) i, .btn.btn-active-color-teal:hover:not(.btn-active) i, .btn.btn-active-color-teal:active:not(.btn-active) i, .btn.btn-active-color-teal.active i, .btn.btn-active-color-teal.show i,
.btn.btn-active-color-teal .show i, .btn.btn-active-color-teal[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-teal i {
  color: #20c997;
}
.btn-check:checked + .btn.btn-active-color-teal .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-teal .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-teal:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-teal:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-teal:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-teal.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-teal.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-teal .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-teal[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-teal .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #20c997;
}
.btn-check:checked + .btn.btn-active-color-teal .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-teal .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-teal:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-teal:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-teal:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-teal.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-teal.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-teal .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-teal[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-teal .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-teal.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-teal.dropdown-toggle:after, .btn.btn-active-color-teal:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-teal:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-teal:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-teal.active.dropdown-toggle:after, .btn.btn-active-color-teal.show.dropdown-toggle:after,
.btn.btn-active-color-teal .show.dropdown-toggle:after, .btn.btn-active-color-teal[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-teal.dropdown-toggle:after {
  color: #20c997;
}

.btn.btn-icon-teal .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #20c997;
}
.btn.btn-icon-teal .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-teal.dropdown-toggle:after {
  color: #20c997;
}
.btn-check:checked + .btn.btn-active-icon-teal i, .btn-check:active + .btn.btn-active-icon-teal i, .btn.btn-active-icon-teal:focus:not(.btn-active) i, .btn.btn-active-icon-teal:hover:not(.btn-active) i, .btn.btn-active-icon-teal:active:not(.btn-active) i, .btn.btn-active-icon-teal.active i, .btn.btn-active-icon-teal.show i,
.btn.btn-active-icon-teal .show i, .btn.btn-active-icon-teal[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-teal i {
  color: #20c997;
}
.btn-check:checked + .btn.btn-active-icon-teal .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-teal .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-teal:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-teal:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-teal:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-teal.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-teal.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-teal .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-teal[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-teal .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #20c997;
}
.btn-check:checked + .btn.btn-active-icon-teal .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-teal .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-teal:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-teal:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-teal:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-teal.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-teal.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-teal .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-teal[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-teal .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-teal.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-teal.dropdown-toggle:after, .btn.btn-active-icon-teal:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-teal:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-teal:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-teal.active.dropdown-toggle:after, .btn.btn-active-icon-teal.show.dropdown-toggle:after,
.btn.btn-active-icon-teal .show.dropdown-toggle:after, .btn.btn-active-icon-teal[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-teal.dropdown-toggle:after {
  color: #20c997;
}

.btn.btn-text-teal {
  color: #20c997;
}
.btn-check:checked + .btn.btn-active-text-teal, .btn-check:active + .btn.btn-active-text-teal, .btn.btn-active-text-teal:focus:not(.btn-active), .btn.btn-active-text-teal:hover:not(.btn-active), .btn.btn-active-text-teal:active:not(.btn-active), .btn.btn-active-text-teal.active, .btn.btn-active-text-teal.show,
.btn.btn-active-text-teal .show, .btn.btn-active-text-teal[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-teal {
  color: #20c997;
}

.btn.btn-color-cyan {
  color: #0dcaf0;
}
.btn.btn-color-cyan .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #0dcaf0;
}
.btn.btn-color-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-cyan.dropdown-toggle:after {
  color: #0dcaf0;
}
.btn-check:checked + .btn.btn-active-color-cyan, .btn-check:active + .btn.btn-active-color-cyan, .btn.btn-active-color-cyan:focus:not(.btn-active), .btn.btn-active-color-cyan:hover:not(.btn-active), .btn.btn-active-color-cyan:active:not(.btn-active), .btn.btn-active-color-cyan.active, .btn.btn-active-color-cyan.show,
.btn.btn-active-color-cyan .show, .btn.btn-active-color-cyan[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-cyan {
  color: #0dcaf0;
}
.btn-check:checked + .btn.btn-active-color-cyan i, .btn-check:active + .btn.btn-active-color-cyan i, .btn.btn-active-color-cyan:focus:not(.btn-active) i, .btn.btn-active-color-cyan:hover:not(.btn-active) i, .btn.btn-active-color-cyan:active:not(.btn-active) i, .btn.btn-active-color-cyan.active i, .btn.btn-active-color-cyan.show i,
.btn.btn-active-color-cyan .show i, .btn.btn-active-color-cyan[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-cyan i {
  color: #0dcaf0;
}
.btn-check:checked + .btn.btn-active-color-cyan .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-cyan .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-cyan:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-cyan:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-cyan:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-cyan.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-cyan.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-cyan .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-cyan[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-cyan .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #0dcaf0;
}
.btn-check:checked + .btn.btn-active-color-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-cyan:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-cyan:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-cyan:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-cyan.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-cyan.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-cyan .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-cyan[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-cyan.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-cyan.dropdown-toggle:after, .btn.btn-active-color-cyan:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-cyan:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-cyan:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-cyan.active.dropdown-toggle:after, .btn.btn-active-color-cyan.show.dropdown-toggle:after,
.btn.btn-active-color-cyan .show.dropdown-toggle:after, .btn.btn-active-color-cyan[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-cyan.dropdown-toggle:after {
  color: #0dcaf0;
}

.btn.btn-icon-cyan .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #0dcaf0;
}
.btn.btn-icon-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-cyan.dropdown-toggle:after {
  color: #0dcaf0;
}
.btn-check:checked + .btn.btn-active-icon-cyan i, .btn-check:active + .btn.btn-active-icon-cyan i, .btn.btn-active-icon-cyan:focus:not(.btn-active) i, .btn.btn-active-icon-cyan:hover:not(.btn-active) i, .btn.btn-active-icon-cyan:active:not(.btn-active) i, .btn.btn-active-icon-cyan.active i, .btn.btn-active-icon-cyan.show i,
.btn.btn-active-icon-cyan .show i, .btn.btn-active-icon-cyan[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-cyan i {
  color: #0dcaf0;
}
.btn-check:checked + .btn.btn-active-icon-cyan .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-cyan .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-cyan:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-cyan:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-cyan:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-cyan.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-cyan.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-cyan .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-cyan[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-cyan .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #0dcaf0;
}
.btn-check:checked + .btn.btn-active-icon-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-cyan:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-cyan:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-cyan:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-cyan.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-cyan.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-cyan .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-cyan[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-cyan .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-cyan.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-cyan.dropdown-toggle:after, .btn.btn-active-icon-cyan:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-cyan:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-cyan:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-cyan.active.dropdown-toggle:after, .btn.btn-active-icon-cyan.show.dropdown-toggle:after,
.btn.btn-active-icon-cyan .show.dropdown-toggle:after, .btn.btn-active-icon-cyan[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-cyan.dropdown-toggle:after {
  color: #0dcaf0;
}

.btn.btn-text-cyan {
  color: #0dcaf0;
}
.btn-check:checked + .btn.btn-active-text-cyan, .btn-check:active + .btn.btn-active-text-cyan, .btn.btn-active-text-cyan:focus:not(.btn-active), .btn.btn-active-text-cyan:hover:not(.btn-active), .btn.btn-active-text-cyan:active:not(.btn-active), .btn.btn-active-text-cyan.active, .btn.btn-active-text-cyan.show,
.btn.btn-active-text-cyan .show, .btn.btn-active-text-cyan[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-cyan {
  color: #0dcaf0;
}

.btn.btn-color-gray {
  color: #7e8299;
}
.btn.btn-color-gray .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.btn.btn-color-gray .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-color-gray.dropdown-toggle:after {
  color: #7e8299;
}
.btn-check:checked + .btn.btn-active-color-gray, .btn-check:active + .btn.btn-active-color-gray, .btn.btn-active-color-gray:focus:not(.btn-active), .btn.btn-active-color-gray:hover:not(.btn-active), .btn.btn-active-color-gray:active:not(.btn-active), .btn.btn-active-color-gray.active, .btn.btn-active-color-gray.show,
.btn.btn-active-color-gray .show, .btn.btn-active-color-gray[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-color-gray {
  color: #7e8299;
}
.btn-check:checked + .btn.btn-active-color-gray i, .btn-check:active + .btn.btn-active-color-gray i, .btn.btn-active-color-gray:focus:not(.btn-active) i, .btn.btn-active-color-gray:hover:not(.btn-active) i, .btn.btn-active-color-gray:active:not(.btn-active) i, .btn.btn-active-color-gray.active i, .btn.btn-active-color-gray.show i,
.btn.btn-active-color-gray .show i, .btn.btn-active-color-gray[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-color-gray i {
  color: #7e8299;
}
.btn-check:checked + .btn.btn-active-color-gray .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-gray[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.btn-check:checked + .btn.btn-active-color-gray .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-gray .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-color-gray .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-color-gray[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-color-gray .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-color-gray.dropdown-toggle:after, .btn-check:active + .btn.btn-active-color-gray.dropdown-toggle:after, .btn.btn-active-color-gray:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-color-gray.active.dropdown-toggle:after, .btn.btn-active-color-gray.show.dropdown-toggle:after,
.btn.btn-active-color-gray .show.dropdown-toggle:after, .btn.btn-active-color-gray[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-color-gray.dropdown-toggle:after {
  color: #7e8299;
}

.btn.btn-icon-gray .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.btn.btn-icon-gray .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-icon-gray.dropdown-toggle:after {
  color: #7e8299;
}
.btn-check:checked + .btn.btn-active-icon-gray i, .btn-check:active + .btn.btn-active-icon-gray i, .btn.btn-active-icon-gray:focus:not(.btn-active) i, .btn.btn-active-icon-gray:hover:not(.btn-active) i, .btn.btn-active-icon-gray:active:not(.btn-active) i, .btn.btn-active-icon-gray.active i, .btn.btn-active-icon-gray.show i,
.btn.btn-active-icon-gray .show i, .btn.btn-active-icon-gray[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-active-icon-gray i {
  color: #7e8299;
}
.btn-check:checked + .btn.btn-active-icon-gray .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.btn-check:checked + .btn.btn-active-icon-gray .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-icon-gray .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-icon-gray .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-active-icon-gray[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-active-icon-gray .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-active-icon-gray.dropdown-toggle:after, .btn-check:active + .btn.btn-active-icon-gray.dropdown-toggle:after, .btn.btn-active-icon-gray:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray:active:not(.btn-active).dropdown-toggle:after, .btn.btn-active-icon-gray.active.dropdown-toggle:after, .btn.btn-active-icon-gray.show.dropdown-toggle:after,
.btn.btn-active-icon-gray .show.dropdown-toggle:after, .btn.btn-active-icon-gray[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-active-icon-gray.dropdown-toggle:after {
  color: #7e8299;
}

.btn.btn-text-gray {
  color: #7e8299;
}
.btn-check:checked + .btn.btn-active-text-gray, .btn-check:active + .btn.btn-active-text-gray, .btn.btn-active-text-gray:focus:not(.btn-active), .btn.btn-active-text-gray:hover:not(.btn-active), .btn.btn-active-text-gray:active:not(.btn-active), .btn.btn-active-text-gray.active, .btn.btn-active-text-gray.show,
.btn.btn-active-text-gray .show, .btn.btn-active-text-gray[aria-expanded=true], [aria-expanded=true] > .btn.btn-active-text-gray {
  color: #7e8299;
}

.btn.btn-facebook {
  color: #ffffff;
  border-color: #3b5998;
  background-color: #3b5998;
}
.btn.btn-facebook .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-facebook .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-facebook.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-facebook, .btn-check:active + .btn.btn-facebook, .btn.btn-facebook:focus:not(.btn-active), .btn.btn-facebook:hover:not(.btn-active), .btn.btn-facebook:active:not(.btn-active), .btn.btn-facebook.active, .btn.btn-facebook.show,
.btn.btn-facebook .show, .btn.btn-facebook[aria-expanded=true], [aria-expanded=true] > .btn.btn-facebook {
  border-color: #30497c;
  background-color: #30497c;
}

.btn.btn-light-facebook {
  color: #3b5998;
  border-color: rgba(59, 89, 152, 0.1);
  background-color: rgba(59, 89, 152, 0.1);
}
.btn.btn-light-facebook .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3b5998;
}
.btn.btn-light-facebook .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-facebook.dropdown-toggle:after {
  color: #3b5998;
}
.btn-check:checked + .btn.btn-light-facebook, .btn-check:active + .btn.btn-light-facebook, .btn.btn-light-facebook:focus:not(.btn-active), .btn.btn-light-facebook:hover:not(.btn-active), .btn.btn-light-facebook:active:not(.btn-active), .btn.btn-light-facebook.active, .btn.btn-light-facebook.show,
.btn.btn-light-facebook .show, .btn.btn-light-facebook[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-facebook {
  color: #ffffff;
  border-color: #3b5998;
  background-color: #3b5998;
}
.btn-check:checked + .btn.btn-light-facebook i, .btn-check:active + .btn.btn-light-facebook i, .btn.btn-light-facebook:focus:not(.btn-active) i, .btn.btn-light-facebook:hover:not(.btn-active) i, .btn.btn-light-facebook:active:not(.btn-active) i, .btn.btn-light-facebook.active i, .btn.btn-light-facebook.show i,
.btn.btn-light-facebook .show i, .btn.btn-light-facebook[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-facebook i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-facebook .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-facebook .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-facebook:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-facebook:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-facebook:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-facebook.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-facebook.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-facebook .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-facebook[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-facebook .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-facebook .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-facebook .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-facebook:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-facebook:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-facebook:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-facebook.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-facebook.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-facebook .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-facebook[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-facebook .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-facebook.dropdown-toggle:after, .btn-check:active + .btn.btn-light-facebook.dropdown-toggle:after, .btn.btn-light-facebook:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-facebook:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-facebook:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-facebook.active.dropdown-toggle:after, .btn.btn-light-facebook.show.dropdown-toggle:after,
.btn.btn-light-facebook .show.dropdown-toggle:after, .btn.btn-light-facebook[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-facebook.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-google {
  color: #ffffff;
  border-color: #dd4b39;
  background-color: #dd4b39;
}
.btn.btn-google .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-google .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-google.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-google, .btn-check:active + .btn.btn-google, .btn.btn-google:focus:not(.btn-active), .btn.btn-google:hover:not(.btn-active), .btn.btn-google:active:not(.btn-active), .btn.btn-google.active, .btn.btn-google.show,
.btn.btn-google .show, .btn.btn-google[aria-expanded=true], [aria-expanded=true] > .btn.btn-google {
  border-color: #cd3623;
  background-color: #cd3623;
}

.btn.btn-light-google {
  color: #dd4b39;
  border-color: rgba(221, 75, 57, 0.1);
  background-color: rgba(221, 75, 57, 0.1);
}
.btn.btn-light-google .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #dd4b39;
}
.btn.btn-light-google .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-google.dropdown-toggle:after {
  color: #dd4b39;
}
.btn-check:checked + .btn.btn-light-google, .btn-check:active + .btn.btn-light-google, .btn.btn-light-google:focus:not(.btn-active), .btn.btn-light-google:hover:not(.btn-active), .btn.btn-light-google:active:not(.btn-active), .btn.btn-light-google.active, .btn.btn-light-google.show,
.btn.btn-light-google .show, .btn.btn-light-google[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-google {
  color: #ffffff;
  border-color: #dd4b39;
  background-color: #dd4b39;
}
.btn-check:checked + .btn.btn-light-google i, .btn-check:active + .btn.btn-light-google i, .btn.btn-light-google:focus:not(.btn-active) i, .btn.btn-light-google:hover:not(.btn-active) i, .btn.btn-light-google:active:not(.btn-active) i, .btn.btn-light-google.active i, .btn.btn-light-google.show i,
.btn.btn-light-google .show i, .btn.btn-light-google[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-google i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-google .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-google .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-google:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-google:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-google:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-google.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-google.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-google .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-google[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-google .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-google .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-google .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-google:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-google:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-google:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-google.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-google.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-google .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-google[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-google .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-google.dropdown-toggle:after, .btn-check:active + .btn.btn-light-google.dropdown-toggle:after, .btn.btn-light-google:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-google:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-google:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-google.active.dropdown-toggle:after, .btn.btn-light-google.show.dropdown-toggle:after,
.btn.btn-light-google .show.dropdown-toggle:after, .btn.btn-light-google[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-google.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-twitter {
  color: #ffffff;
  border-color: #1da1f2;
  background-color: #1da1f2;
}
.btn.btn-twitter .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-twitter .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-twitter.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-twitter, .btn-check:active + .btn.btn-twitter, .btn.btn-twitter:focus:not(.btn-active), .btn.btn-twitter:hover:not(.btn-active), .btn.btn-twitter:active:not(.btn-active), .btn.btn-twitter.active, .btn.btn-twitter.show,
.btn.btn-twitter .show, .btn.btn-twitter[aria-expanded=true], [aria-expanded=true] > .btn.btn-twitter {
  border-color: #0d8ddc;
  background-color: #0d8ddc;
}

.btn.btn-light-twitter {
  color: #1da1f2;
  border-color: rgba(29, 161, 242, 0.1);
  background-color: rgba(29, 161, 242, 0.1);
}
.btn.btn-light-twitter .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1da1f2;
}
.btn.btn-light-twitter .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-twitter.dropdown-toggle:after {
  color: #1da1f2;
}
.btn-check:checked + .btn.btn-light-twitter, .btn-check:active + .btn.btn-light-twitter, .btn.btn-light-twitter:focus:not(.btn-active), .btn.btn-light-twitter:hover:not(.btn-active), .btn.btn-light-twitter:active:not(.btn-active), .btn.btn-light-twitter.active, .btn.btn-light-twitter.show,
.btn.btn-light-twitter .show, .btn.btn-light-twitter[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-twitter {
  color: #ffffff;
  border-color: #1da1f2;
  background-color: #1da1f2;
}
.btn-check:checked + .btn.btn-light-twitter i, .btn-check:active + .btn.btn-light-twitter i, .btn.btn-light-twitter:focus:not(.btn-active) i, .btn.btn-light-twitter:hover:not(.btn-active) i, .btn.btn-light-twitter:active:not(.btn-active) i, .btn.btn-light-twitter.active i, .btn.btn-light-twitter.show i,
.btn.btn-light-twitter .show i, .btn.btn-light-twitter[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-twitter i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-twitter .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-twitter .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-twitter:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-twitter:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-twitter:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-twitter.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-twitter.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-twitter .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-twitter[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-twitter .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-twitter .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-twitter .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-twitter:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-twitter:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-twitter:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-twitter.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-twitter.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-twitter .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-twitter[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-twitter .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-twitter.dropdown-toggle:after, .btn-check:active + .btn.btn-light-twitter.dropdown-toggle:after, .btn.btn-light-twitter:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-twitter:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-twitter:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-twitter.active.dropdown-toggle:after, .btn.btn-light-twitter.show.dropdown-toggle:after,
.btn.btn-light-twitter .show.dropdown-toggle:after, .btn.btn-light-twitter[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-twitter.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-instagram {
  color: #ffffff;
  border-color: #e1306c;
  background-color: #e1306c;
}
.btn.btn-instagram .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-instagram .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-instagram.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-instagram, .btn-check:active + .btn.btn-instagram, .btn.btn-instagram:focus:not(.btn-active), .btn.btn-instagram:hover:not(.btn-active), .btn.btn-instagram:active:not(.btn-active), .btn.btn-instagram.active, .btn.btn-instagram.show,
.btn.btn-instagram .show, .btn.btn-instagram[aria-expanded=true], [aria-expanded=true] > .btn.btn-instagram {
  border-color: #cd1e59;
  background-color: #cd1e59;
}

.btn.btn-light-instagram {
  color: #e1306c;
  border-color: rgba(225, 48, 108, 0.1);
  background-color: rgba(225, 48, 108, 0.1);
}
.btn.btn-light-instagram .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e1306c;
}
.btn.btn-light-instagram .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-instagram.dropdown-toggle:after {
  color: #e1306c;
}
.btn-check:checked + .btn.btn-light-instagram, .btn-check:active + .btn.btn-light-instagram, .btn.btn-light-instagram:focus:not(.btn-active), .btn.btn-light-instagram:hover:not(.btn-active), .btn.btn-light-instagram:active:not(.btn-active), .btn.btn-light-instagram.active, .btn.btn-light-instagram.show,
.btn.btn-light-instagram .show, .btn.btn-light-instagram[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-instagram {
  color: #ffffff;
  border-color: #e1306c;
  background-color: #e1306c;
}
.btn-check:checked + .btn.btn-light-instagram i, .btn-check:active + .btn.btn-light-instagram i, .btn.btn-light-instagram:focus:not(.btn-active) i, .btn.btn-light-instagram:hover:not(.btn-active) i, .btn.btn-light-instagram:active:not(.btn-active) i, .btn.btn-light-instagram.active i, .btn.btn-light-instagram.show i,
.btn.btn-light-instagram .show i, .btn.btn-light-instagram[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-instagram i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-instagram .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-instagram .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-instagram:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-instagram:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-instagram:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-instagram.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-instagram.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-instagram .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-instagram[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-instagram .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-instagram .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-instagram .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-instagram:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-instagram:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-instagram:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-instagram.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-instagram.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-instagram .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-instagram[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-instagram .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-instagram.dropdown-toggle:after, .btn-check:active + .btn.btn-light-instagram.dropdown-toggle:after, .btn.btn-light-instagram:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-instagram:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-instagram:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-instagram.active.dropdown-toggle:after, .btn.btn-light-instagram.show.dropdown-toggle:after,
.btn.btn-light-instagram .show.dropdown-toggle:after, .btn.btn-light-instagram[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-instagram.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-youtube {
  color: #ffffff;
  border-color: #ff0000;
  background-color: #ff0000;
}
.btn.btn-youtube .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-youtube .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-youtube.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-youtube, .btn-check:active + .btn.btn-youtube, .btn.btn-youtube:focus:not(.btn-active), .btn.btn-youtube:hover:not(.btn-active), .btn.btn-youtube:active:not(.btn-active), .btn.btn-youtube.active, .btn.btn-youtube.show,
.btn.btn-youtube .show, .btn.btn-youtube[aria-expanded=true], [aria-expanded=true] > .btn.btn-youtube {
  border-color: #d90000;
  background-color: #d90000;
}

.btn.btn-light-youtube {
  color: #ff0000;
  border-color: rgba(255, 0, 0, 0.1);
  background-color: rgba(255, 0, 0, 0.1);
}
.btn.btn-light-youtube .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ff0000;
}
.btn.btn-light-youtube .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-youtube.dropdown-toggle:after {
  color: #ff0000;
}
.btn-check:checked + .btn.btn-light-youtube, .btn-check:active + .btn.btn-light-youtube, .btn.btn-light-youtube:focus:not(.btn-active), .btn.btn-light-youtube:hover:not(.btn-active), .btn.btn-light-youtube:active:not(.btn-active), .btn.btn-light-youtube.active, .btn.btn-light-youtube.show,
.btn.btn-light-youtube .show, .btn.btn-light-youtube[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-youtube {
  color: #ffffff;
  border-color: #ff0000;
  background-color: #ff0000;
}
.btn-check:checked + .btn.btn-light-youtube i, .btn-check:active + .btn.btn-light-youtube i, .btn.btn-light-youtube:focus:not(.btn-active) i, .btn.btn-light-youtube:hover:not(.btn-active) i, .btn.btn-light-youtube:active:not(.btn-active) i, .btn.btn-light-youtube.active i, .btn.btn-light-youtube.show i,
.btn.btn-light-youtube .show i, .btn.btn-light-youtube[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-youtube i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-youtube .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-youtube .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-youtube:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-youtube:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-youtube:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-youtube.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-youtube.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-youtube .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-youtube[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-youtube .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-youtube .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-youtube .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-youtube:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-youtube:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-youtube:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-youtube.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-youtube.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-youtube .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-youtube[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-youtube .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-youtube.dropdown-toggle:after, .btn-check:active + .btn.btn-light-youtube.dropdown-toggle:after, .btn.btn-light-youtube:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-youtube:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-youtube:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-youtube.active.dropdown-toggle:after, .btn.btn-light-youtube.show.dropdown-toggle:after,
.btn.btn-light-youtube .show.dropdown-toggle:after, .btn.btn-light-youtube[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-youtube.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-linkedin {
  color: #ffffff;
  border-color: #0077b5;
  background-color: #0077b5;
}
.btn.btn-linkedin .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn.btn-linkedin .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-linkedin.dropdown-toggle:after {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-linkedin, .btn-check:active + .btn.btn-linkedin, .btn.btn-linkedin:focus:not(.btn-active), .btn.btn-linkedin:hover:not(.btn-active), .btn.btn-linkedin:active:not(.btn-active), .btn.btn-linkedin.active, .btn.btn-linkedin.show,
.btn.btn-linkedin .show, .btn.btn-linkedin[aria-expanded=true], [aria-expanded=true] > .btn.btn-linkedin {
  border-color: #005e8f;
  background-color: #005e8f;
}

.btn.btn-light-linkedin {
  color: #0077b5;
  border-color: rgba(0, 119, 181, 0.1);
  background-color: rgba(0, 119, 181, 0.1);
}
.btn.btn-light-linkedin .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #0077b5;
}
.btn.btn-light-linkedin .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-light-linkedin.dropdown-toggle:after {
  color: #0077b5;
}
.btn-check:checked + .btn.btn-light-linkedin, .btn-check:active + .btn.btn-light-linkedin, .btn.btn-light-linkedin:focus:not(.btn-active), .btn.btn-light-linkedin:hover:not(.btn-active), .btn.btn-light-linkedin:active:not(.btn-active), .btn.btn-light-linkedin.active, .btn.btn-light-linkedin.show,
.btn.btn-light-linkedin .show, .btn.btn-light-linkedin[aria-expanded=true], [aria-expanded=true] > .btn.btn-light-linkedin {
  color: #ffffff;
  border-color: #0077b5;
  background-color: #0077b5;
}
.btn-check:checked + .btn.btn-light-linkedin i, .btn-check:active + .btn.btn-light-linkedin i, .btn.btn-light-linkedin:focus:not(.btn-active) i, .btn.btn-light-linkedin:hover:not(.btn-active) i, .btn.btn-light-linkedin:active:not(.btn-active) i, .btn.btn-light-linkedin.active i, .btn.btn-light-linkedin.show i,
.btn.btn-light-linkedin .show i, .btn.btn-light-linkedin[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-light-linkedin i {
  color: #ffffff;
}
.btn-check:checked + .btn.btn-light-linkedin .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-linkedin .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-linkedin:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-linkedin:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-linkedin:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-linkedin.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-linkedin.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-linkedin .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-light-linkedin[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-linkedin .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.btn-check:checked + .btn.btn-light-linkedin .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-light-linkedin .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-linkedin:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-linkedin:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-linkedin:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-linkedin.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-linkedin.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-linkedin .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-light-linkedin[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-light-linkedin .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-light-linkedin.dropdown-toggle:after, .btn-check:active + .btn.btn-light-linkedin.dropdown-toggle:after, .btn.btn-light-linkedin:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-light-linkedin:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-light-linkedin:active:not(.btn-active).dropdown-toggle:after, .btn.btn-light-linkedin.active.dropdown-toggle:after, .btn.btn-light-linkedin.show.dropdown-toggle:after,
.btn.btn-light-linkedin .show.dropdown-toggle:after, .btn.btn-light-linkedin[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-light-linkedin.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline.btn-outline-dashed.btn-outline-default {
  border-width: 1px;
  border-style: dashed;
  color: #3f4254;
  border-color: #e4e6ef;
}
.btn.btn-outline.btn-outline-dashed.btn-outline-default .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.btn.btn-outline.btn-outline-dashed.btn-outline-default .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn.btn-outline.btn-outline-dashed.btn-outline-default.dropdown-toggle:after {
  color: #3f4254;
}
.btn-check:checked + .btn.btn-outline.btn-outline-dashed.btn-outline-default, .btn-check:active + .btn.btn-outline.btn-outline-dashed.btn-outline-default, .btn.btn-outline.btn-outline-dashed.btn-outline-default:focus:not(.btn-active), .btn.btn-outline.btn-outline-dashed.btn-outline-default:hover:not(.btn-active), .btn.btn-outline.btn-outline-dashed.btn-outline-default:active:not(.btn-active), .btn.btn-outline.btn-outline-dashed.btn-outline-default.active, .btn.btn-outline.btn-outline-dashed.btn-outline-default.show,
.btn.btn-outline.btn-outline-dashed.btn-outline-default .show, .btn.btn-outline.btn-outline-dashed.btn-outline-default[aria-expanded=true], [aria-expanded=true] > .btn.btn-outline.btn-outline-dashed.btn-outline-default {
  color: #e10e8d;
  border-color: #e10e8d;
  background-color: #fff1fe;
}
.btn-check:checked + .btn.btn-outline.btn-outline-dashed.btn-outline-default i, .btn-check:active + .btn.btn-outline.btn-outline-dashed.btn-outline-default i, .btn.btn-outline.btn-outline-dashed.btn-outline-default:focus:not(.btn-active) i, .btn.btn-outline.btn-outline-dashed.btn-outline-default:hover:not(.btn-active) i, .btn.btn-outline.btn-outline-dashed.btn-outline-default:active:not(.btn-active) i, .btn.btn-outline.btn-outline-dashed.btn-outline-default.active i, .btn.btn-outline.btn-outline-dashed.btn-outline-default.show i,
.btn.btn-outline.btn-outline-dashed.btn-outline-default .show i, .btn.btn-outline.btn-outline-dashed.btn-outline-default[aria-expanded=true] i, [aria-expanded=true] > .btn.btn-outline.btn-outline-dashed.btn-outline-default i {
  color: #e10e8d;
}
.btn-check:checked + .btn.btn-outline.btn-outline-dashed.btn-outline-default .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-outline.btn-outline-dashed.btn-outline-default .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-outline.btn-outline-dashed.btn-outline-default:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-outline.btn-outline-dashed.btn-outline-default:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-outline.btn-outline-dashed.btn-outline-default:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-outline.btn-outline-dashed.btn-outline-default.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-outline.btn-outline-dashed.btn-outline-default.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-outline.btn-outline-dashed.btn-outline-default .show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-outline.btn-outline-dashed.btn-outline-default[aria-expanded=true] .svg-icon svg [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-outline.btn-outline-dashed.btn-outline-default .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.btn-check:checked + .btn.btn-outline.btn-outline-dashed.btn-outline-default .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-outline.btn-outline-dashed.btn-outline-default .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-outline.btn-outline-dashed.btn-outline-default:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-outline.btn-outline-dashed.btn-outline-default:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-outline.btn-outline-dashed.btn-outline-default:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-outline.btn-outline-dashed.btn-outline-default.active .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-outline.btn-outline-dashed.btn-outline-default.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-outline.btn-outline-dashed.btn-outline-default .show .svg-icon svg:hover [fill]:not(.permanent):not(g), .btn.btn-outline.btn-outline-dashed.btn-outline-default[aria-expanded=true] .svg-icon svg:hover [fill]:not(.permanent):not(g), [aria-expanded=true] > .btn.btn-outline.btn-outline-dashed.btn-outline-default .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:checked + .btn.btn-outline.btn-outline-dashed.btn-outline-default.dropdown-toggle:after, .btn-check:active + .btn.btn-outline.btn-outline-dashed.btn-outline-default.dropdown-toggle:after, .btn.btn-outline.btn-outline-dashed.btn-outline-default:focus:not(.btn-active).dropdown-toggle:after, .btn.btn-outline.btn-outline-dashed.btn-outline-default:hover:not(.btn-active).dropdown-toggle:after, .btn.btn-outline.btn-outline-dashed.btn-outline-default:active:not(.btn-active).dropdown-toggle:after, .btn.btn-outline.btn-outline-dashed.btn-outline-default.active.dropdown-toggle:after, .btn.btn-outline.btn-outline-dashed.btn-outline-default.show.dropdown-toggle:after,
.btn.btn-outline.btn-outline-dashed.btn-outline-default .show.dropdown-toggle:after, .btn.btn-outline.btn-outline-dashed.btn-outline-default[aria-expanded=true].dropdown-toggle:after, [aria-expanded=true] > .btn.btn-outline.btn-outline-dashed.btn-outline-default.dropdown-toggle:after {
  color: #e10e8d;
}

.bg-white {
  --bg-color: 255,
  255,
  255;
}
.bg-white.hoverable:hover {
  background-color: #f5f8fa;
}

.bg-hover-white {
  cursor: pointer;
}
.bg-hover-white:hover {
  background-color: var(--active-white);
}
.light-theme .bg-hover-white:hover {
  background-color: #f5f8fa;
}
.dark-theme .bg-hover-white:hover {
  background-color: #181c32;
}

.bg-active-white.active {
  --bg-color: 255,
  255,
  255;
  background-color: #ffffff !important;
}

.bg-state-white {
  cursor: pointer;
}
.bg-state-white > div,
.bg-state-white td {
  position: relative;
  z-index: 1;
}
.bg-state-white.active, .bg-state-white:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-white.active::after, .bg-state-white:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-white.active::after, .light-theme .bg-state-white:hover::after {
  background-color: #f5f8fa !important;
}
.dark-theme .bg-state-white.active::after, .dark-theme .bg-state-white:hover::after {
  background-color: #181c32 !important;
}

.bg-light {
  --bg-color: 239,
  242,
  245;
}
.bg-light.hoverable:hover {
  background-color: #e6eaef;
}

.bg-hover-light {
  cursor: pointer;
}
.bg-hover-light:hover {
  background-color: var(--active-light);
}
.light-theme .bg-hover-light:hover {
  background-color: #e6eaef;
}
.dark-theme .bg-hover-light:hover {
  background-color: #383b4b;
}

.bg-active-light.active {
  --bg-color: 239,
  242,
  245;
  background-color: #eff2f5 !important;
}

.bg-state-light {
  cursor: pointer;
}
.bg-state-light > div,
.bg-state-light td {
  position: relative;
  z-index: 1;
}
.bg-state-light.active, .bg-state-light:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-light.active::after, .bg-state-light:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-light.active::after, .light-theme .bg-state-light:hover::after {
  background-color: #e6eaef !important;
}
.dark-theme .bg-state-light.active::after, .dark-theme .bg-state-light:hover::after {
  background-color: #383b4b !important;
}

.bg-light-primary {
  background-color: #fff1fe;
}
.bg-light-primary.hoverable:hover {
  background-color: #ffd2fc;
}

.light-theme .bg-lighten-primary {
  background-color: rgba(255, 241, 254, 0.5);
}
.dark-theme .bg-lighten-primary {
  background-color: #361a2b;
}
.light-theme .bg-lighten-primary.hoverable:hover {
  background-color: #a90768;
}
.dark-theme .bg-lighten-primary.hoverable:hover {
  background-color: #a90768;
}

.bg-primary {
  --bg-color: 225,
  14,
  141;
}
.bg-primary.hoverable:hover {
  background-color: #a90768;
}

.bg-hover-light-primary {
  cursor: pointer;
}
.bg-hover-light-primary:hover {
  background-color: #fff1fe;
}

.bg-state-light-primary {
  cursor: pointer;
}
.bg-state-light-primary.active, .bg-state-light-primary:hover {
  background-color: #fff1fe !important;
}

.bg-hover-lighten-primary {
  cursor: pointer;
}
.bg-hover-lighten-primary:hover {
  background-color: rgba(255, 241, 254, 0.5) !important;
}

.bg-state-light-primary {
  cursor: pointer;
}
.bg-state-light-primary.active, .bg-state-light-primary:hover {
  background-color: rgba(255, 241, 254, 0.5) !important;
}

.bg-hover-primary {
  cursor: pointer;
}
.bg-hover-primary:hover {
  background-color: var(--active-primary);
}
.light-theme .bg-hover-primary:hover {
  background-color: #a90768;
}
.dark-theme .bg-hover-primary:hover {
  background-color: #a90768;
}

.bg-active-primary.active {
  --bg-color: 225,
  14,
  141;
  background-color: #e10e8d !important;
}

.bg-state-primary {
  cursor: pointer;
}
.bg-state-primary > div,
.bg-state-primary td {
  position: relative;
  z-index: 1;
}
.bg-state-primary.active, .bg-state-primary:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-primary.active::after, .bg-state-primary:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-primary.active::after, .light-theme .bg-state-primary:hover::after {
  background-color: #a90768 !important;
}
.dark-theme .bg-state-primary.active::after, .dark-theme .bg-state-primary:hover::after {
  background-color: #a90768 !important;
}

.bg-secondary {
  --bg-color: 228,
  230,
  239;
}
.bg-secondary.hoverable:hover {
  background-color: #b5b5c3;
}

.bg-hover-secondary {
  cursor: pointer;
}
.bg-hover-secondary:hover {
  background-color: var(--active-secondary);
}
.light-theme .bg-hover-secondary:hover {
  background-color: #b5b5c3;
}
.dark-theme .bg-hover-secondary:hover {
  background-color: #b5b5c3;
}

.bg-active-secondary.active {
  --bg-color: 228,
  230,
  239;
  background-color: #e4e6ef !important;
}

.bg-state-secondary {
  cursor: pointer;
}
.bg-state-secondary > div,
.bg-state-secondary td {
  position: relative;
  z-index: 1;
}
.bg-state-secondary.active, .bg-state-secondary:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-secondary.active::after, .bg-state-secondary:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-secondary.active::after, .light-theme .bg-state-secondary:hover::after {
  background-color: #b5b5c3 !important;
}
.dark-theme .bg-state-secondary.active::after, .dark-theme .bg-state-secondary:hover::after {
  background-color: #b5b5c3 !important;
}

.bg-light-success {
  background-color: rgba(27, 197, 188, 0.1);
}
.bg-light-success.hoverable:hover {
  background-color: rgba(23, 170, 162, 0.1);
}

.light-theme .bg-lighten-success.hoverable:hover {
  background-color: #12827c;
}
.dark-theme .bg-lighten-success.hoverable:hover {
  background-color: #12827c;
}

.bg-success {
  --bg-color: 27,
  197,
  188;
}
.bg-success.hoverable:hover {
  background-color: #12827c;
}

.bg-hover-light-success {
  cursor: pointer;
}
.bg-hover-light-success:hover {
  background-color: rgba(27, 197, 188, 0.1);
}

.bg-state-light-success {
  cursor: pointer;
}
.bg-state-light-success.active, .bg-state-light-success:hover {
  background-color: rgba(27, 197, 188, 0.1) !important;
}

.bg-hover-lighten-success {
  cursor: pointer;
}
.bg-hover-lighten-success:hover {
  background-color: #b7f5f2 !important;
}

.bg-state-light-success {
  cursor: pointer;
}
.bg-state-light-success.active, .bg-state-light-success:hover {
  background-color: #b7f5f2 !important;
}

.bg-hover-success {
  cursor: pointer;
}
.bg-hover-success:hover {
  background-color: var(--active-success);
}
.light-theme .bg-hover-success:hover {
  background-color: #12827c;
}
.dark-theme .bg-hover-success:hover {
  background-color: #12827c;
}

.bg-active-success.active {
  --bg-color: 27,
  197,
  188;
  background-color: #1BC5BC !important;
}

.bg-state-success {
  cursor: pointer;
}
.bg-state-success > div,
.bg-state-success td {
  position: relative;
  z-index: 1;
}
.bg-state-success.active, .bg-state-success:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-success.active::after, .bg-state-success:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-success.active::after, .light-theme .bg-state-success:hover::after {
  background-color: #12827c !important;
}
.dark-theme .bg-state-success.active::after, .dark-theme .bg-state-success:hover::after {
  background-color: #12827c !important;
}

.bg-light-info {
  background-color: rgba(95, 92, 241, 0.1);
}
.bg-light-info.hoverable:hover {
  background-color: rgba(67, 64, 239, 0.1);
}

.light-theme .bg-lighten-info.hoverable:hover {
  background-color: #1a16eb;
}
.dark-theme .bg-lighten-info.hoverable:hover {
  background-color: #1a16eb;
}

.bg-info {
  --bg-color: 95,
  92,
  241;
}
.bg-info.hoverable:hover {
  background-color: #1a16eb;
}

.bg-hover-light-info {
  cursor: pointer;
}
.bg-hover-light-info:hover {
  background-color: rgba(95, 92, 241, 0.1);
}

.bg-state-light-info {
  cursor: pointer;
}
.bg-state-light-info.active, .bg-state-light-info:hover {
  background-color: rgba(95, 92, 241, 0.1) !important;
}

.bg-hover-lighten-info {
  cursor: pointer;
}
.bg-hover-lighten-info:hover {
  background-color: white !important;
}

.bg-state-light-info {
  cursor: pointer;
}
.bg-state-light-info.active, .bg-state-light-info:hover {
  background-color: white !important;
}

.bg-hover-info {
  cursor: pointer;
}
.bg-hover-info:hover {
  background-color: var(--active-info);
}
.light-theme .bg-hover-info:hover {
  background-color: #1a16eb;
}
.dark-theme .bg-hover-info:hover {
  background-color: #1a16eb;
}

.bg-active-info.active {
  --bg-color: 95,
  92,
  241;
  background-color: #5F5CF1 !important;
}

.bg-state-info {
  cursor: pointer;
}
.bg-state-info > div,
.bg-state-info td {
  position: relative;
  z-index: 1;
}
.bg-state-info.active, .bg-state-info:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-info.active::after, .bg-state-info:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-info.active::after, .light-theme .bg-state-info:hover::after {
  background-color: #1a16eb !important;
}
.dark-theme .bg-state-info.active::after, .dark-theme .bg-state-info:hover::after {
  background-color: #1a16eb !important;
}

.bg-light-warning {
  background-color: rgba(255, 168, 0, 0.15);
}
.bg-light-warning.hoverable:hover {
  background-color: rgba(224, 148, 0, 0.15);
}

.light-theme .bg-lighten-warning.hoverable:hover {
  background-color: #b37600;
}
.dark-theme .bg-lighten-warning.hoverable:hover {
  background-color: #b37600;
}

.bg-warning {
  --bg-color: 255,
  168,
  0;
}
.bg-warning.hoverable:hover {
  background-color: #b37600;
}

.bg-hover-light-warning {
  cursor: pointer;
}
.bg-hover-light-warning:hover {
  background-color: rgba(255, 168, 0, 0.15);
}

.bg-state-light-warning {
  cursor: pointer;
}
.bg-state-light-warning.active, .bg-state-light-warning:hover {
  background-color: rgba(255, 168, 0, 0.15) !important;
}

.bg-hover-lighten-warning {
  cursor: pointer;
}
.bg-hover-lighten-warning:hover {
  background-color: #ffeecc !important;
}

.bg-state-light-warning {
  cursor: pointer;
}
.bg-state-light-warning.active, .bg-state-light-warning:hover {
  background-color: #ffeecc !important;
}

.bg-hover-warning {
  cursor: pointer;
}
.bg-hover-warning:hover {
  background-color: var(--active-warning);
}
.light-theme .bg-hover-warning:hover {
  background-color: #b37600;
}
.dark-theme .bg-hover-warning:hover {
  background-color: #b37600;
}

.bg-active-warning.active {
  --bg-color: 255,
  168,
  0;
  background-color: #FFA800 !important;
}

.bg-state-warning {
  cursor: pointer;
}
.bg-state-warning > div,
.bg-state-warning td {
  position: relative;
  z-index: 1;
}
.bg-state-warning.active, .bg-state-warning:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-warning.active::after, .bg-state-warning:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-warning.active::after, .light-theme .bg-state-warning:hover::after {
  background-color: #b37600 !important;
}
.dark-theme .bg-state-warning.active::after, .dark-theme .bg-state-warning:hover::after {
  background-color: #b37600 !important;
}

.bg-light-danger {
  background-color: rgba(246, 84, 100, 0.1);
}
.bg-light-danger.hoverable:hover {
  background-color: rgba(244, 55, 74, 0.1);
}

.light-theme .bg-lighten-danger.hoverable:hover {
  background-color: #f10d23;
}
.dark-theme .bg-lighten-danger.hoverable:hover {
  background-color: #f10d23;
}

.bg-danger {
  --bg-color: 246,
  84,
  100;
}
.bg-danger.hoverable:hover {
  background-color: #f10d23;
}

.bg-hover-light-danger {
  cursor: pointer;
}
.bg-hover-light-danger:hover {
  background-color: rgba(246, 84, 100, 0.1);
}

.bg-state-light-danger {
  cursor: pointer;
}
.bg-state-light-danger.active, .bg-state-light-danger:hover {
  background-color: rgba(246, 84, 100, 0.1) !important;
}

.bg-hover-lighten-danger {
  cursor: pointer;
}
.bg-hover-lighten-danger:hover {
  background-color: white !important;
}

.bg-state-light-danger {
  cursor: pointer;
}
.bg-state-light-danger.active, .bg-state-light-danger:hover {
  background-color: white !important;
}

.bg-hover-danger {
  cursor: pointer;
}
.bg-hover-danger:hover {
  background-color: var(--active-danger);
}
.light-theme .bg-hover-danger:hover {
  background-color: #f10d23;
}
.dark-theme .bg-hover-danger:hover {
  background-color: #f10d23;
}

.bg-active-danger.active {
  --bg-color: 246,
  84,
  100;
  background-color: #F65464 !important;
}

.bg-state-danger {
  cursor: pointer;
}
.bg-state-danger > div,
.bg-state-danger td {
  position: relative;
  z-index: 1;
}
.bg-state-danger.active, .bg-state-danger:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-danger.active::after, .bg-state-danger:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-danger.active::after, .light-theme .bg-state-danger:hover::after {
  background-color: #f10d23 !important;
}
.dark-theme .bg-state-danger.active::after, .dark-theme .bg-state-danger:hover::after {
  background-color: #f10d23 !important;
}

.bg-light-dark {
  background-color: #eff2f5;
}
.bg-light-dark.hoverable:hover {
  background-color: #dce3e9;
}

.bg-dark {
  --bg-color: 63,
  66,
  84;
}
.bg-dark.hoverable:hover {
  background-color: #383b4b;
}

.bg-hover-light-dark {
  cursor: pointer;
}
.bg-hover-light-dark:hover {
  background-color: #eff2f5;
}

.bg-state-light-dark {
  cursor: pointer;
}
.bg-state-light-dark.active, .bg-state-light-dark:hover {
  background-color: #eff2f5 !important;
}

.bg-hover-dark {
  cursor: pointer;
}
.bg-hover-dark:hover {
  background-color: var(--active-dark);
}
.light-theme .bg-hover-dark:hover {
  background-color: #383b4b;
}
.dark-theme .bg-hover-dark:hover {
  background-color: #e6eaef;
}

.bg-active-dark.active {
  --bg-color: 63,
  66,
  84;
  background-color: #3f4254 !important;
}

.bg-state-dark {
  cursor: pointer;
}
.bg-state-dark > div,
.bg-state-dark td {
  position: relative;
  z-index: 1;
}
.bg-state-dark.active, .bg-state-dark:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-dark.active::after, .bg-state-dark:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-dark.active::after, .light-theme .bg-state-dark:hover::after {
  background-color: #383b4b !important;
}
.dark-theme .bg-state-dark.active::after, .dark-theme .bg-state-dark:hover::after {
  background-color: #e6eaef !important;
}

.bg-black {
  --bg-color: 0,
  0,
  0;
}
.bg-black.hoverable:hover {
  background-color: #181c32;
}

.bg-hover-black {
  cursor: pointer;
}
.bg-hover-black:hover {
  background-color: var(--active-black);
}
.light-theme .bg-hover-black:hover {
  background-color: #181c32;
}
.dark-theme .bg-hover-black:hover {
  background-color: #f5f8fa;
}

.bg-active-black.active {
  --bg-color: 0,
  0,
  0;
  background-color: #000000 !important;
}

.bg-state-black {
  cursor: pointer;
}
.bg-state-black > div,
.bg-state-black td {
  position: relative;
  z-index: 1;
}
.bg-state-black.active, .bg-state-black:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-black.active::after, .bg-state-black:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-black.active::after, .light-theme .bg-state-black:hover::after {
  background-color: #181c32 !important;
}
.dark-theme .bg-state-black.active::after, .dark-theme .bg-state-black:hover::after {
  background-color: #f5f8fa !important;
}

.bg-muted {
  --bg-color: 161,
  165,
  183;
}
.bg-hover-muted {
  cursor: pointer;
}
.bg-hover-muted:hover {
  background-color: var(--active-muted);
}
.bg-active-muted.active {
  --bg-color: 161,
  165,
  183;
  background-color: #a1a5b7 !important;
}

.bg-state-muted {
  cursor: pointer;
}
.bg-state-muted > div,
.bg-state-muted td {
  position: relative;
  z-index: 1;
}
.bg-state-muted.active, .bg-state-muted:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-muted.active::after, .bg-state-muted:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-muted.active::after, .light-theme .bg-state-muted:hover::after {
  background-color: !important;
}
.dark-theme .bg-state-muted.active::after, .dark-theme .bg-state-muted:hover::after {
  background-color: !important;
}

.bg-light-blue {
  background-color: rgba(54, 153, 255, 0.1);
}
.bg-light-blue.hoverable:hover {
  background-color: rgba(23, 137, 255, 0.1);
}

.light-theme .bg-lighten-blue.hoverable:hover {
  background-color: #0073e9;
}
.dark-theme .bg-lighten-blue.hoverable:hover {
  background-color: #0073e9;
}

.bg-blue {
  --bg-color: 54,
  153,
  255;
}
.bg-blue.hoverable:hover {
  background-color: #0073e9;
}

.bg-hover-light-blue {
  cursor: pointer;
}
.bg-hover-light-blue:hover {
  background-color: rgba(54, 153, 255, 0.1);
}

.bg-state-light-blue {
  cursor: pointer;
}
.bg-state-light-blue.active, .bg-state-light-blue:hover {
  background-color: rgba(54, 153, 255, 0.1) !important;
}

.bg-hover-lighten-blue {
  cursor: pointer;
}
.bg-hover-lighten-blue:hover {
  background-color: white !important;
}

.bg-state-light-blue {
  cursor: pointer;
}
.bg-state-light-blue.active, .bg-state-light-blue:hover {
  background-color: white !important;
}

.bg-hover-blue {
  cursor: pointer;
}
.bg-hover-blue:hover {
  background-color: var(--active-blue);
}
.light-theme .bg-hover-blue:hover {
  background-color: #0073e9;
}
.dark-theme .bg-hover-blue:hover {
  background-color: #0073e9;
}

.bg-active-blue.active {
  --bg-color: 54,
  153,
  255;
  background-color: #3699FF !important;
}

.bg-state-blue {
  cursor: pointer;
}
.bg-state-blue > div,
.bg-state-blue td {
  position: relative;
  z-index: 1;
}
.bg-state-blue.active, .bg-state-blue:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-blue.active::after, .bg-state-blue:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-blue.active::after, .light-theme .bg-state-blue:hover::after {
  background-color: #0073e9 !important;
}
.dark-theme .bg-state-blue.active::after, .dark-theme .bg-state-blue:hover::after {
  background-color: #0073e9 !important;
}

.bg-light-indigo {
  background-color: rgba(102, 16, 242, 0.1);
}
.bg-light-indigo.hoverable:hover {
  background-color: rgba(89, 12, 216, 0.1);
}

.light-theme .bg-lighten-indigo.hoverable:hover {
  background-color: #4709ac;
}
.dark-theme .bg-lighten-indigo.hoverable:hover {
  background-color: #4709ac;
}

.bg-indigo {
  --bg-color: 102,
  16,
  242;
}
.bg-indigo.hoverable:hover {
  background-color: #4709ac;
}

.bg-hover-light-indigo {
  cursor: pointer;
}
.bg-hover-light-indigo:hover {
  background-color: rgba(102, 16, 242, 0.1);
}

.bg-state-light-indigo {
  cursor: pointer;
}
.bg-state-light-indigo.active, .bg-state-light-indigo:hover {
  background-color: rgba(102, 16, 242, 0.1) !important;
}

.bg-hover-lighten-indigo {
  cursor: pointer;
}
.bg-hover-lighten-indigo:hover {
  background-color: #e2d1fd !important;
}

.bg-state-light-indigo {
  cursor: pointer;
}
.bg-state-light-indigo.active, .bg-state-light-indigo:hover {
  background-color: #e2d1fd !important;
}

.bg-hover-indigo {
  cursor: pointer;
}
.bg-hover-indigo:hover {
  background-color: var(--active-indigo);
}
.light-theme .bg-hover-indigo:hover {
  background-color: #4709ac;
}
.dark-theme .bg-hover-indigo:hover {
  background-color: #4709ac;
}

.bg-active-indigo.active {
  --bg-color: 102,
  16,
  242;
  background-color: #6610f2 !important;
}

.bg-state-indigo {
  cursor: pointer;
}
.bg-state-indigo > div,
.bg-state-indigo td {
  position: relative;
  z-index: 1;
}
.bg-state-indigo.active, .bg-state-indigo:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-indigo.active::after, .bg-state-indigo:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-indigo.active::after, .light-theme .bg-state-indigo:hover::after {
  background-color: #4709ac !important;
}
.dark-theme .bg-state-indigo.active::after, .dark-theme .bg-state-indigo:hover::after {
  background-color: #4709ac !important;
}

.bg-light-purple {
  background-color: rgba(95, 92, 241, 0.1);
}
.bg-light-purple.hoverable:hover {
  background-color: rgba(67, 64, 239, 0.1);
}

.light-theme .bg-lighten-purple.hoverable:hover {
  background-color: #1a16eb;
}
.dark-theme .bg-lighten-purple.hoverable:hover {
  background-color: #1a16eb;
}

.bg-purple {
  --bg-color: 95,
  92,
  241;
}
.bg-purple.hoverable:hover {
  background-color: #1a16eb;
}

.bg-hover-light-purple {
  cursor: pointer;
}
.bg-hover-light-purple:hover {
  background-color: rgba(95, 92, 241, 0.1);
}

.bg-state-light-purple {
  cursor: pointer;
}
.bg-state-light-purple.active, .bg-state-light-purple:hover {
  background-color: rgba(95, 92, 241, 0.1) !important;
}

.bg-hover-lighten-purple {
  cursor: pointer;
}
.bg-hover-lighten-purple:hover {
  background-color: white !important;
}

.bg-state-light-purple {
  cursor: pointer;
}
.bg-state-light-purple.active, .bg-state-light-purple:hover {
  background-color: white !important;
}

.bg-hover-purple {
  cursor: pointer;
}
.bg-hover-purple:hover {
  background-color: var(--active-purple);
}
.light-theme .bg-hover-purple:hover {
  background-color: #1a16eb;
}
.dark-theme .bg-hover-purple:hover {
  background-color: #1a16eb;
}

.bg-active-purple.active {
  --bg-color: 95,
  92,
  241;
  background-color: #5F5CF1 !important;
}

.bg-state-purple {
  cursor: pointer;
}
.bg-state-purple > div,
.bg-state-purple td {
  position: relative;
  z-index: 1;
}
.bg-state-purple.active, .bg-state-purple:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-purple.active::after, .bg-state-purple:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-purple.active::after, .light-theme .bg-state-purple:hover::after {
  background-color: #1a16eb !important;
}
.dark-theme .bg-state-purple.active::after, .dark-theme .bg-state-purple:hover::after {
  background-color: #1a16eb !important;
}

.bg-light-pink {
  background-color: rgba(214, 51, 132, 0.1);
}
.bg-light-pink.hoverable:hover {
  background-color: rgba(195, 39, 117, 0.1);
}

.light-theme .bg-lighten-pink.hoverable:hover {
  background-color: #9d205e;
}
.dark-theme .bg-lighten-pink.hoverable:hover {
  background-color: #9d205e;
}

.bg-pink {
  --bg-color: 214,
  51,
  132;
}
.bg-pink.hoverable:hover {
  background-color: #9d205e;
}

.bg-hover-light-pink {
  cursor: pointer;
}
.bg-hover-light-pink:hover {
  background-color: rgba(214, 51, 132, 0.1);
}

.bg-state-light-pink {
  cursor: pointer;
}
.bg-state-light-pink.active, .bg-state-light-pink:hover {
  background-color: rgba(214, 51, 132, 0.1) !important;
}

.bg-hover-lighten-pink {
  cursor: pointer;
}
.bg-hover-lighten-pink:hover {
  background-color: #f8ddea !important;
}

.bg-state-light-pink {
  cursor: pointer;
}
.bg-state-light-pink.active, .bg-state-light-pink:hover {
  background-color: #f8ddea !important;
}

.bg-hover-pink {
  cursor: pointer;
}
.bg-hover-pink:hover {
  background-color: var(--active-pink);
}
.light-theme .bg-hover-pink:hover {
  background-color: #9d205e;
}
.dark-theme .bg-hover-pink:hover {
  background-color: #9d205e;
}

.bg-active-pink.active {
  --bg-color: 214,
  51,
  132;
  background-color: #d63384 !important;
}

.bg-state-pink {
  cursor: pointer;
}
.bg-state-pink > div,
.bg-state-pink td {
  position: relative;
  z-index: 1;
}
.bg-state-pink.active, .bg-state-pink:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-pink.active::after, .bg-state-pink:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-pink.active::after, .light-theme .bg-state-pink:hover::after {
  background-color: #9d205e !important;
}
.dark-theme .bg-state-pink.active::after, .dark-theme .bg-state-pink:hover::after {
  background-color: #9d205e !important;
}

.bg-light-red {
  background-color: rgba(246, 84, 100, 0.1);
}
.bg-light-red.hoverable:hover {
  background-color: rgba(244, 55, 74, 0.1);
}

.light-theme .bg-lighten-red.hoverable:hover {
  background-color: #f10d23;
}
.dark-theme .bg-lighten-red.hoverable:hover {
  background-color: #f10d23;
}

.bg-red {
  --bg-color: 246,
  84,
  100;
}
.bg-red.hoverable:hover {
  background-color: #f10d23;
}

.bg-hover-light-red {
  cursor: pointer;
}
.bg-hover-light-red:hover {
  background-color: rgba(246, 84, 100, 0.1);
}

.bg-state-light-red {
  cursor: pointer;
}
.bg-state-light-red.active, .bg-state-light-red:hover {
  background-color: rgba(246, 84, 100, 0.1) !important;
}

.bg-hover-lighten-red {
  cursor: pointer;
}
.bg-hover-lighten-red:hover {
  background-color: white !important;
}

.bg-state-light-red {
  cursor: pointer;
}
.bg-state-light-red.active, .bg-state-light-red:hover {
  background-color: white !important;
}

.bg-hover-red {
  cursor: pointer;
}
.bg-hover-red:hover {
  background-color: var(--active-red);
}
.light-theme .bg-hover-red:hover {
  background-color: #f10d23;
}
.dark-theme .bg-hover-red:hover {
  background-color: #f10d23;
}

.bg-active-red.active {
  --bg-color: 246,
  84,
  100;
  background-color: #F65464 !important;
}

.bg-state-red {
  cursor: pointer;
}
.bg-state-red > div,
.bg-state-red td {
  position: relative;
  z-index: 1;
}
.bg-state-red.active, .bg-state-red:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-red.active::after, .bg-state-red:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-red.active::after, .light-theme .bg-state-red:hover::after {
  background-color: #f10d23 !important;
}
.dark-theme .bg-state-red.active::after, .dark-theme .bg-state-red:hover::after {
  background-color: #f10d23 !important;
}

.bg-light-orange {
  background-color: rgba(253, 126, 20, 0.1);
}
.bg-light-orange.hoverable:hover {
  background-color: rgba(240, 110, 2, 0.1);
}

.light-theme .bg-lighten-orange.hoverable:hover {
  background-color: #c35a02;
}
.dark-theme .bg-lighten-orange.hoverable:hover {
  background-color: #c35a02;
}

.bg-orange {
  --bg-color: 253,
  126,
  20;
}
.bg-orange.hoverable:hover {
  background-color: #c35a02;
}

.bg-hover-light-orange {
  cursor: pointer;
}
.bg-hover-light-orange:hover {
  background-color: rgba(253, 126, 20, 0.1);
}

.bg-state-light-orange {
  cursor: pointer;
}
.bg-state-light-orange.active, .bg-state-light-orange:hover {
  background-color: rgba(253, 126, 20, 0.1) !important;
}

.bg-hover-lighten-orange {
  cursor: pointer;
}
.bg-hover-lighten-orange:hover {
  background-color: #ffedde !important;
}

.bg-state-light-orange {
  cursor: pointer;
}
.bg-state-light-orange.active, .bg-state-light-orange:hover {
  background-color: #ffedde !important;
}

.bg-hover-orange {
  cursor: pointer;
}
.bg-hover-orange:hover {
  background-color: var(--active-orange);
}
.light-theme .bg-hover-orange:hover {
  background-color: #c35a02;
}
.dark-theme .bg-hover-orange:hover {
  background-color: #c35a02;
}

.bg-active-orange.active {
  --bg-color: 253,
  126,
  20;
  background-color: #fd7e14 !important;
}

.bg-state-orange {
  cursor: pointer;
}
.bg-state-orange > div,
.bg-state-orange td {
  position: relative;
  z-index: 1;
}
.bg-state-orange.active, .bg-state-orange:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-orange.active::after, .bg-state-orange:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-orange.active::after, .light-theme .bg-state-orange:hover::after {
  background-color: #c35a02 !important;
}
.dark-theme .bg-state-orange.active::after, .dark-theme .bg-state-orange:hover::after {
  background-color: #c35a02 !important;
}

.bg-light-yellow {
  background-color: rgba(255, 168, 0, 0.1);
}
.bg-light-yellow.hoverable:hover {
  background-color: rgba(224, 148, 0, 0.1);
}

.light-theme .bg-lighten-yellow.hoverable:hover {
  background-color: #b37600;
}
.dark-theme .bg-lighten-yellow.hoverable:hover {
  background-color: #b37600;
}

.bg-yellow {
  --bg-color: 255,
  168,
  0;
}
.bg-yellow.hoverable:hover {
  background-color: #b37600;
}

.bg-hover-light-yellow {
  cursor: pointer;
}
.bg-hover-light-yellow:hover {
  background-color: rgba(255, 168, 0, 0.1);
}

.bg-state-light-yellow {
  cursor: pointer;
}
.bg-state-light-yellow.active, .bg-state-light-yellow:hover {
  background-color: rgba(255, 168, 0, 0.1) !important;
}

.bg-hover-lighten-yellow {
  cursor: pointer;
}
.bg-hover-lighten-yellow:hover {
  background-color: #ffeecc !important;
}

.bg-state-light-yellow {
  cursor: pointer;
}
.bg-state-light-yellow.active, .bg-state-light-yellow:hover {
  background-color: #ffeecc !important;
}

.bg-hover-yellow {
  cursor: pointer;
}
.bg-hover-yellow:hover {
  background-color: var(--active-yellow);
}
.light-theme .bg-hover-yellow:hover {
  background-color: #b37600;
}
.dark-theme .bg-hover-yellow:hover {
  background-color: #b37600;
}

.bg-active-yellow.active {
  --bg-color: 255,
  168,
  0;
  background-color: #FFA800 !important;
}

.bg-state-yellow {
  cursor: pointer;
}
.bg-state-yellow > div,
.bg-state-yellow td {
  position: relative;
  z-index: 1;
}
.bg-state-yellow.active, .bg-state-yellow:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-yellow.active::after, .bg-state-yellow:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-yellow.active::after, .light-theme .bg-state-yellow:hover::after {
  background-color: #b37600 !important;
}
.dark-theme .bg-state-yellow.active::after, .dark-theme .bg-state-yellow:hover::after {
  background-color: #b37600 !important;
}

.bg-light-green {
  background-color: rgba(27, 197, 188, 0.1);
}
.bg-light-green.hoverable:hover {
  background-color: rgba(23, 170, 162, 0.1);
}

.light-theme .bg-lighten-green.hoverable:hover {
  background-color: #12827c;
}
.bg-green {
  --bg-color: 27,
  197,
  188;
}
.bg-green.hoverable:hover {
  background-color: #12827c;
}

.bg-hover-light-green {
  cursor: pointer;
}
.bg-hover-light-green:hover {
  background-color: rgba(27, 197, 188, 0.1);
}

.bg-state-light-green {
  cursor: pointer;
}
.bg-state-light-green.active, .bg-state-light-green:hover {
  background-color: rgba(27, 197, 188, 0.1) !important;
}

.bg-hover-lighten-green {
  cursor: pointer;
}
.bg-hover-lighten-green:hover {
  background-color: #b7f5f2 !important;
}

.bg-state-light-green {
  cursor: pointer;
}
.bg-state-light-green.active, .bg-state-light-green:hover {
  background-color: #b7f5f2 !important;
}

.bg-hover-green {
  cursor: pointer;
}
.bg-hover-green:hover {
  background-color: var(--active-green);
}
.light-theme .bg-hover-green:hover {
  background-color: #12827c;
}
.bg-active-green.active {
  --bg-color: 27,
  197,
  188;
  background-color: #1BC5BC !important;
}

.bg-state-green {
  cursor: pointer;
}
.bg-state-green > div,
.bg-state-green td {
  position: relative;
  z-index: 1;
}
.bg-state-green.active, .bg-state-green:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-green.active::after, .bg-state-green:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-green.active::after, .light-theme .bg-state-green:hover::after {
  background-color: #12827c !important;
}
.dark-theme .bg-state-green.active::after, .dark-theme .bg-state-green:hover::after {
  background-color: !important;
}

.bg-light-teal {
  background-color: rgba(32, 201, 151, 0.1);
}
.bg-light-teal.hoverable:hover {
  background-color: rgba(28, 175, 131, 0.1);
}

.light-theme .bg-lighten-teal.hoverable:hover {
  background-color: #158765;
}
.dark-theme .bg-lighten-teal.hoverable:hover {
  background-color: #158765;
}

.bg-teal {
  --bg-color: 32,
  201,
  151;
}
.bg-teal.hoverable:hover {
  background-color: #158765;
}

.bg-hover-light-teal {
  cursor: pointer;
}
.bg-hover-light-teal:hover {
  background-color: rgba(32, 201, 151, 0.1);
}

.bg-state-light-teal {
  cursor: pointer;
}
.bg-state-light-teal.active, .bg-state-light-teal:hover {
  background-color: rgba(32, 201, 151, 0.1) !important;
}

.bg-hover-lighten-teal {
  cursor: pointer;
}
.bg-hover-lighten-teal:hover {
  background-color: #c0f5e5 !important;
}

.bg-state-light-teal {
  cursor: pointer;
}
.bg-state-light-teal.active, .bg-state-light-teal:hover {
  background-color: #c0f5e5 !important;
}

.bg-hover-teal {
  cursor: pointer;
}
.bg-hover-teal:hover {
  background-color: var(--active-teal);
}
.light-theme .bg-hover-teal:hover {
  background-color: #158765;
}
.dark-theme .bg-hover-teal:hover {
  background-color: #158765;
}

.bg-active-teal.active {
  --bg-color: 32,
  201,
  151;
  background-color: #20c997 !important;
}

.bg-state-teal {
  cursor: pointer;
}
.bg-state-teal > div,
.bg-state-teal td {
  position: relative;
  z-index: 1;
}
.bg-state-teal.active, .bg-state-teal:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-teal.active::after, .bg-state-teal:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-teal.active::after, .light-theme .bg-state-teal:hover::after {
  background-color: #158765 !important;
}
.dark-theme .bg-state-teal.active::after, .dark-theme .bg-state-teal:hover::after {
  background-color: #158765 !important;
}

.bg-light-cyan {
  background-color: rgba(13, 202, 240, 0.1);
}
.bg-light-cyan.hoverable:hover {
  background-color: rgba(11, 178, 211, 0.1);
}

.light-theme .bg-lighten-cyan.hoverable:hover {
  background-color: #098da7;
}
.dark-theme .bg-lighten-cyan.hoverable:hover {
  background-color: #098da7;
}

.bg-cyan {
  --bg-color: 13,
  202,
  240;
}
.bg-cyan.hoverable:hover {
  background-color: #098da7;
}

.bg-hover-light-cyan {
  cursor: pointer;
}
.bg-hover-light-cyan:hover {
  background-color: rgba(13, 202, 240, 0.1);
}

.bg-state-light-cyan {
  cursor: pointer;
}
.bg-state-light-cyan.active, .bg-state-light-cyan:hover {
  background-color: rgba(13, 202, 240, 0.1) !important;
}

.bg-hover-lighten-cyan {
  cursor: pointer;
}
.bg-hover-lighten-cyan:hover {
  background-color: #cdf4fc !important;
}

.bg-state-light-cyan {
  cursor: pointer;
}
.bg-state-light-cyan.active, .bg-state-light-cyan:hover {
  background-color: #cdf4fc !important;
}

.bg-hover-cyan {
  cursor: pointer;
}
.bg-hover-cyan:hover {
  background-color: var(--active-cyan);
}
.light-theme .bg-hover-cyan:hover {
  background-color: #098da7;
}
.dark-theme .bg-hover-cyan:hover {
  background-color: #098da7;
}

.bg-active-cyan.active {
  --bg-color: 13,
  202,
  240;
  background-color: #0dcaf0 !important;
}

.bg-state-cyan {
  cursor: pointer;
}
.bg-state-cyan > div,
.bg-state-cyan td {
  position: relative;
  z-index: 1;
}
.bg-state-cyan.active, .bg-state-cyan:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-cyan.active::after, .bg-state-cyan:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-cyan.active::after, .light-theme .bg-state-cyan:hover::after {
  background-color: #098da7 !important;
}
.dark-theme .bg-state-cyan.active::after, .dark-theme .bg-state-cyan:hover::after {
  background-color: #098da7 !important;
}

.bg-light-gray {
  background-color: rgba(126, 130, 153, 0.1);
}
.bg-light-gray.hoverable:hover {
  background-color: rgba(110, 114, 139, 0.1);
}

.light-theme .bg-lighten-gray.hoverable:hover {
  background-color: #3f4254;
}
.dark-theme .bg-lighten-gray.hoverable:hover {
  background-color: #3f4254;
}

.bg-gray {
  --bg-color: 126,
  130,
  153;
}
.bg-gray.hoverable:hover {
  background-color: #3f4254;
}

.bg-hover-light-gray {
  cursor: pointer;
}
.bg-hover-light-gray:hover {
  background-color: rgba(126, 130, 153, 0.1);
}

.bg-state-light-gray {
  cursor: pointer;
}
.bg-state-light-gray.active, .bg-state-light-gray:hover {
  background-color: rgba(126, 130, 153, 0.1) !important;
}

.bg-hover-lighten-gray {
  cursor: pointer;
}
.bg-hover-lighten-gray:hover {
  background-color: #b5b5c3 !important;
}

.bg-state-light-gray {
  cursor: pointer;
}
.bg-state-light-gray.active, .bg-state-light-gray:hover {
  background-color: #b5b5c3 !important;
}

.bg-hover-gray {
  cursor: pointer;
}
.bg-hover-gray:hover {
  background-color: var(--active-gray);
}
.light-theme .bg-hover-gray:hover {
  background-color: #3f4254;
}
.dark-theme .bg-hover-gray:hover {
  background-color: #3f4254;
}

.bg-active-gray.active {
  --bg-color: 126,
  130,
  153;
  background-color: #7e8299 !important;
}

.bg-state-gray {
  cursor: pointer;
}
.bg-state-gray > div,
.bg-state-gray td {
  position: relative;
  z-index: 1;
}
.bg-state-gray.active, .bg-state-gray:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-gray.active::after, .bg-state-gray:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-gray.active::after, .light-theme .bg-state-gray:hover::after {
  background-color: #3f4254 !important;
}
.dark-theme .bg-state-gray.active::after, .dark-theme .bg-state-gray:hover::after {
  background-color: #3f4254 !important;
}

.bg-body-bg {
  --bg-color: 245,
  248,
  250;
}
.bg-hover-body-bg {
  cursor: pointer;
}
.bg-hover-body-bg:hover {
  background-color: var(--active-body-bg);
}
.bg-active-body-bg.active {
  --bg-color: 245,
  248,
  250;
  background-color: #f5f8fa !important;
}

.bg-state-body-bg {
  cursor: pointer;
}
.bg-state-body-bg > div,
.bg-state-body-bg td {
  position: relative;
  z-index: 1;
}
.bg-state-body-bg.active, .bg-state-body-bg:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-body-bg.active::after, .bg-state-body-bg:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-body-bg.active::after, .light-theme .bg-state-body-bg:hover::after {
  background-color: !important;
}
.dark-theme .bg-state-body-bg.active::after, .dark-theme .bg-state-body-bg:hover::after {
  background-color: !important;
}

.bg-body-text {
  --bg-color: 21,
  21,
  33;
}
.bg-hover-body-text {
  cursor: pointer;
}
.bg-hover-body-text:hover {
  background-color: var(--active-body-text);
}
.bg-active-body-text.active {
  --bg-color: 21,
  21,
  33;
  background-color: #151521 !important;
}

.bg-state-body-text {
  cursor: pointer;
}
.bg-state-body-text > div,
.bg-state-body-text td {
  position: relative;
  z-index: 1;
}
.bg-state-body-text.active, .bg-state-body-text:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-body-text.active::after, .bg-state-body-text:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-body-text.active::after, .light-theme .bg-state-body-text:hover::after {
  background-color: !important;
}
.dark-theme .bg-state-body-text.active::after, .dark-theme .bg-state-body-text:hover::after {
  background-color: !important;
}

.bg-toolbar-bg {
  --bg-color: 255,
  255,
  255;
}
.bg-hover-toolbar-bg {
  cursor: pointer;
}
.bg-hover-toolbar-bg:hover {
  background-color: var(--active-toolbar-bg);
}
.bg-active-toolbar-bg.active {
  --bg-color: 255,
  255,
  255;
  background-color: #ffffff !important;
}

.bg-state-toolbar-bg {
  cursor: pointer;
}
.bg-state-toolbar-bg > div,
.bg-state-toolbar-bg td {
  position: relative;
  z-index: 1;
}
.bg-state-toolbar-bg.active, .bg-state-toolbar-bg:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-toolbar-bg.active::after, .bg-state-toolbar-bg:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-toolbar-bg.active::after, .light-theme .bg-state-toolbar-bg:hover::after {
  background-color: !important;
}
.dark-theme .bg-state-toolbar-bg.active::after, .dark-theme .bg-state-toolbar-bg:hover::after {
  background-color: !important;
}

.bg-toolbar-text {
  --bg-color: 26,
  26,
  39;
}
.bg-hover-toolbar-text {
  cursor: pointer;
}
.bg-hover-toolbar-text:hover {
  background-color: var(--active-toolbar-text);
}
.bg-active-toolbar-text.active {
  --bg-color: 26,
  26,
  39;
  background-color: #1a1a27 !important;
}

.bg-state-toolbar-text {
  cursor: pointer;
}
.bg-state-toolbar-text > div,
.bg-state-toolbar-text td {
  position: relative;
  z-index: 1;
}
.bg-state-toolbar-text.active, .bg-state-toolbar-text:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-toolbar-text.active::after, .bg-state-toolbar-text:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-toolbar-text.active::after, .light-theme .bg-state-toolbar-text:hover::after {
  background-color: !important;
}
.dark-theme .bg-state-toolbar-text.active::after, .dark-theme .bg-state-toolbar-text:hover::after {
  background-color: !important;
}

.bg-focus-bg {
  --bg-color: 238,
  243,
  247;
}
.bg-hover-focus-bg {
  cursor: pointer;
}
.bg-hover-focus-bg:hover {
  background-color: var(--active-focus-bg);
}
.bg-active-focus-bg.active {
  --bg-color: 238,
  243,
  247;
  background-color: #eef3f7 !important;
}

.bg-state-focus-bg {
  cursor: pointer;
}
.bg-state-focus-bg > div,
.bg-state-focus-bg td {
  position: relative;
  z-index: 1;
}
.bg-state-focus-bg.active, .bg-state-focus-bg:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-focus-bg.active::after, .bg-state-focus-bg:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-focus-bg.active::after, .light-theme .bg-state-focus-bg:hover::after {
  background-color: !important;
}
.dark-theme .bg-state-focus-bg.active::after, .dark-theme .bg-state-focus-bg:hover::after {
  background-color: !important;
}

.bg-tabbar-text {
  --bg-color: 23,
  23,
  35;
}
.bg-hover-tabbar-text {
  cursor: pointer;
}
.bg-hover-tabbar-text:hover {
  background-color: var(--active-tabbar-text);
}
.bg-active-tabbar-text.active {
  --bg-color: 23,
  23,
  35;
  background-color: #171723 !important;
}

.bg-state-tabbar-text {
  cursor: pointer;
}
.bg-state-tabbar-text > div,
.bg-state-tabbar-text td {
  position: relative;
  z-index: 1;
}
.bg-state-tabbar-text.active, .bg-state-tabbar-text:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-tabbar-text.active::after, .bg-state-tabbar-text:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-tabbar-text.active::after, .light-theme .bg-state-tabbar-text:hover::after {
  background-color: !important;
}
.dark-theme .bg-state-tabbar-text.active::after, .dark-theme .bg-state-tabbar-text:hover::after {
  background-color: !important;
}

.bg-card-bg {
  --bg-color: 255,
  255,
  255;
}
.bg-hover-card-bg {
  cursor: pointer;
}
.bg-hover-card-bg:hover {
  background-color: var(--active-card-bg);
}
.bg-active-card-bg.active {
  --bg-color: 255,
  255,
  255;
  background-color: #ffffff !important;
}

.bg-state-card-bg {
  cursor: pointer;
}
.bg-state-card-bg > div,
.bg-state-card-bg td {
  position: relative;
  z-index: 1;
}
.bg-state-card-bg.active, .bg-state-card-bg:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-card-bg.active::after, .bg-state-card-bg:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-card-bg.active::after, .light-theme .bg-state-card-bg:hover::after {
  background-color: !important;
}
.dark-theme .bg-state-card-bg.active::after, .dark-theme .bg-state-card-bg:hover::after {
  background-color: !important;
}

.bg-card-text {
  --bg-color: 30,
  30,
  45;
}
.bg-hover-card-text {
  cursor: pointer;
}
.bg-hover-card-text:hover {
  background-color: var(--active-card-text);
}
.bg-active-card-text.active {
  --bg-color: 30,
  30,
  45;
  background-color: #1e1e2d !important;
}

.bg-state-card-text {
  cursor: pointer;
}
.bg-state-card-text > div,
.bg-state-card-text td {
  position: relative;
  z-index: 1;
}
.bg-state-card-text.active, .bg-state-card-text:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-card-text.active::after, .bg-state-card-text:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-card-text.active::after, .light-theme .bg-state-card-text:hover::after {
  background-color: !important;
}
.dark-theme .bg-state-card-text.active::after, .dark-theme .bg-state-card-text:hover::after {
  background-color: !important;
}

.bg-item-bg {
  --bg-color: 228,
  230,
  239;
}
.bg-hover-item-bg {
  cursor: pointer;
}
.bg-hover-item-bg:hover {
  background-color: var(--active-item-bg);
}
.bg-active-item-bg.active {
  --bg-color: 228,
  230,
  239;
  background-color: #e4e6ef !important;
}

.bg-state-item-bg {
  cursor: pointer;
}
.bg-state-item-bg > div,
.bg-state-item-bg td {
  position: relative;
  z-index: 1;
}
.bg-state-item-bg.active, .bg-state-item-bg:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-item-bg.active::after, .bg-state-item-bg:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-item-bg.active::after, .light-theme .bg-state-item-bg:hover::after {
  background-color: !important;
}
.dark-theme .bg-state-item-bg.active::after, .dark-theme .bg-state-item-bg:hover::after {
  background-color: !important;
}

.bg-item-text {
  --bg-color: 27,
  27,
  41;
}
.bg-hover-item-text {
  cursor: pointer;
}
.bg-hover-item-text:hover {
  background-color: var(--active-item-text);
}
.bg-active-item-text.active {
  --bg-color: 27,
  27,
  41;
  background-color: #1b1b29 !important;
}

.bg-state-item-text {
  cursor: pointer;
}
.bg-state-item-text > div,
.bg-state-item-text td {
  position: relative;
  z-index: 1;
}
.bg-state-item-text.active, .bg-state-item-text:hover {
  position: relative;
  overflow: hidden;
}
.bg-state-item-text.active::after, .bg-state-item-text:hover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}
.light-theme .bg-state-item-text.active::after, .light-theme .bg-state-item-text:hover::after {
  background-color: !important;
}
.dark-theme .bg-state-item-text.active::after, .dark-theme .bg-state-item-text:hover::after {
  background-color: !important;
}

.bg-gray-100 {
  background-color: #f5f8fa !important;
}

.bg-gray-200 {
  background-color: #eff2f5 !important;
}

.bg-gray-300 {
  background-color: #e4e6ef !important;
}

.bg-gray-400 {
  background-color: #b5b5c3 !important;
}

.bg-gray-500 {
  background-color: #a1a5b7 !important;
}

.bg-gray-600 {
  background-color: #7e8299 !important;
}

.bg-gray-700 {
  background-color: #5e6278 !important;
}

.bg-gray-800 {
  background-color: #3f4254 !important;
}

.bg-gray-900 {
  background-color: #181c32 !important;
}

.bg-opacity-0 {
  background-color: rgba(var(--bg-color), 0) !important;
}

.bg-hover-opacity-0:hover {
  background-color: rgba(var(--bg-color), 0) !important;
}

.bg-active-opacity-0.active {
  background-color: rgba(var(--bg-color), 0) !important;
}

.bg-state-opacity-0 .active, .bg-state-opacity-0:hover {
  background-color: rgba(var(--bg-color), 0) !important;
  position: relative;
}
.bg-state-opacity-0 .active::after, .bg-state-opacity-0:hover::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  opacity: 0;
}

.bg-opacity-5 {
  background-color: rgba(var(--bg-color), 0.05) !important;
}

.bg-hover-opacity-5:hover {
  background-color: rgba(var(--bg-color), 0.05) !important;
}

.bg-active-opacity-5.active {
  background-color: rgba(var(--bg-color), 0.05) !important;
}

.bg-state-opacity-5 .active, .bg-state-opacity-5:hover {
  background-color: rgba(var(--bg-color), 0.05) !important;
  position: relative;
}
.bg-state-opacity-5 .active::after, .bg-state-opacity-5:hover::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  opacity: 0.05;
}

.bg-opacity-10 {
  background-color: rgba(var(--bg-color), 0.1) !important;
}

.bg-hover-opacity-10:hover {
  background-color: rgba(var(--bg-color), 0.1) !important;
}

.bg-active-opacity-10.active {
  background-color: rgba(var(--bg-color), 0.1) !important;
}

.bg-state-opacity-10 .active, .bg-state-opacity-10:hover {
  background-color: rgba(var(--bg-color), 0.1) !important;
  position: relative;
}
.bg-state-opacity-10 .active::after, .bg-state-opacity-10:hover::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  opacity: 0.1;
}

.bg-opacity-15 {
  background-color: rgba(var(--bg-color), 0.15) !important;
}

.bg-hover-opacity-15:hover {
  background-color: rgba(var(--bg-color), 0.15) !important;
}

.bg-active-opacity-15.active {
  background-color: rgba(var(--bg-color), 0.15) !important;
}

.bg-state-opacity-15 .active, .bg-state-opacity-15:hover {
  background-color: rgba(var(--bg-color), 0.15) !important;
  position: relative;
}
.bg-state-opacity-15 .active::after, .bg-state-opacity-15:hover::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  opacity: 0.15;
}

.bg-opacity-20 {
  background-color: rgba(var(--bg-color), 0.2) !important;
}

.bg-hover-opacity-20:hover {
  background-color: rgba(var(--bg-color), 0.2) !important;
}

.bg-active-opacity-20.active {
  background-color: rgba(var(--bg-color), 0.2) !important;
}

.bg-state-opacity-20 .active, .bg-state-opacity-20:hover {
  background-color: rgba(var(--bg-color), 0.2) !important;
  position: relative;
}
.bg-state-opacity-20 .active::after, .bg-state-opacity-20:hover::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  opacity: 0.2;
}

.bg-opacity-25 {
  background-color: rgba(var(--bg-color), 0.25) !important;
}

.bg-hover-opacity-25:hover {
  background-color: rgba(var(--bg-color), 0.25) !important;
}

.bg-active-opacity-25.active {
  background-color: rgba(var(--bg-color), 0.25) !important;
}

.bg-state-opacity-25 .active, .bg-state-opacity-25:hover {
  background-color: rgba(var(--bg-color), 0.25) !important;
  position: relative;
}
.bg-state-opacity-25 .active::after, .bg-state-opacity-25:hover::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  opacity: 0.25;
}

.bg-opacity-50 {
  background-color: rgba(var(--bg-color), 0.5) !important;
}

.bg-hover-opacity-50:hover {
  background-color: rgba(var(--bg-color), 0.5) !important;
}

.bg-active-opacity-50.active {
  background-color: rgba(var(--bg-color), 0.5) !important;
}

.bg-state-opacity-50 .active, .bg-state-opacity-50:hover {
  background-color: rgba(var(--bg-color), 0.5) !important;
  position: relative;
}
.bg-state-opacity-50 .active::after, .bg-state-opacity-50:hover::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  opacity: 0.5;
}

.bg-opacity-75 {
  background-color: rgba(var(--bg-color), 0.75) !important;
}

.bg-hover-opacity-75:hover {
  background-color: rgba(var(--bg-color), 0.75) !important;
}

.bg-active-opacity-75.active {
  background-color: rgba(var(--bg-color), 0.75) !important;
}

.bg-state-opacity-75 .active, .bg-state-opacity-75:hover {
  background-color: rgba(var(--bg-color), 0.75) !important;
  position: relative;
}
.bg-state-opacity-75 .active::after, .bg-state-opacity-75:hover::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  opacity: 0.75;
}

.bg-opacity-100 {
  background-color: rgba(var(--bg-color), 1) !important;
}

.bg-hover-opacity-100:hover {
  background-color: rgba(var(--bg-color), 1) !important;
}

.bg-active-opacity-100.active {
  background-color: rgba(var(--bg-color), 1) !important;
}

.bg-state-opacity-100 .active, .bg-state-opacity-100:hover {
  background-color: rgba(var(--bg-color), 1) !important;
  position: relative;
}
.bg-state-opacity-100 .active::after, .bg-state-opacity-100:hover::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  opacity: 1;
}

.bg-lighten {
  background-color: var(--light-light) !important;
}
.light-theme .bg-lighten {
  background-color: rgba(239, 242, 245, 0.5) !important;
}
.dark-theme .bg-lighten {
  background-color: rgba(63, 66, 84, 0.5) !important;
}

.bg-hover-lighten {
  cursor: pointer;
}
.bg-hover-lighten:hover {
  background-color: var(--light-light) !important;
}
.light-theme .bg-hover-lighten:hover {
  background-color: rgba(239, 242, 245, 0.5) !important;
}
.dark-theme .bg-hover-lighten:hover {
  background-color: rgba(63, 66, 84, 0.5) !important;
}

.bg-active-lighten.active {
  background-color: rgba(239, 242, 245, 0.5) !important;
}

.bg-state-lighten {
  cursor: pointer;
}
.bg-state-lighten.active, .bg-state-lighten:hover {
  background-color: rgba(239, 242, 245, 0.5) !important;
}

.hoverable {
  cursor: pointer;
  transition: all 0.2s ease;
}
.hoverable:hover {
  transition: all 0.2s ease;
}

.bgi-none {
  background-image: none !important;
}
.bgi-none.form-select {
  background-image: none !important;
}

.bgi-no-repeat {
  background-repeat: no-repeat;
}

.bgi-position-y-top {
  background-position-y: top;
}

.bgi-position-y-bottom {
  background-position-y: bottom;
}

.bgi-position-y-center {
  background-position-y: center;
}

.bgi-position-x-start {
  background-position-x: left;
}

.bgi-position-x-end {
  background-position-x: right;
}

.bgi-position-x-center {
  background-position-x: center;
}

.bgi-position-top {
  background-position: 0 top;
}

.bgi-position-bottom {
  background-position: 0 bottom;
}

.bgi-position-center {
  background-position: center;
}

.bgi-size-auto {
  background-size: auto;
}

.bgi-size-cover {
  background-size: cover;
}

.bgi-size-contain {
  background-size: contain;
}

.bgi-attachment-fixed {
  background-attachment: fixed;
}

.bgi-attachment-auto {
  background-attachment: auto;
}

@media (min-width: 576px) {
  .bgi-size-sm-auto {
    background-size: auto;
  }

  .bgi-size-sm-cover {
    background-size: cover;
  }

  .bgi-size-sm-contain {
    background-size: contain;
  }

  .bgi-attachment-sm-fixed {
    background-attachment: fixed;
  }

  .bgi-attachment-sm-auto {
    background-attachment: auto;
  }
}
@media (min-width: 768px) {
  .bgi-size-md-auto {
    background-size: auto;
  }

  .bgi-size-md-cover {
    background-size: cover;
  }

  .bgi-size-md-contain {
    background-size: contain;
  }

  .bgi-attachment-md-fixed {
    background-attachment: fixed;
  }

  .bgi-attachment-md-auto {
    background-attachment: auto;
  }
}
@media (min-width: 992px) {
  .bgi-size-lg-auto {
    background-size: auto;
  }

  .bgi-size-lg-cover {
    background-size: cover;
  }

  .bgi-size-lg-contain {
    background-size: contain;
  }

  .bgi-attachment-lg-fixed {
    background-attachment: fixed;
  }

  .bgi-attachment-lg-auto {
    background-attachment: auto;
  }
}
@media (min-width: 1200px) {
  .bgi-size-xl-auto {
    background-size: auto;
  }

  .bgi-size-xl-cover {
    background-size: cover;
  }

  .bgi-size-xl-contain {
    background-size: contain;
  }

  .bgi-attachment-xl-fixed {
    background-attachment: fixed;
  }

  .bgi-attachment-xl-auto {
    background-attachment: auto;
  }
}
@media (min-width: 1400px) {
  .bgi-size-xxl-auto {
    background-size: auto;
  }

  .bgi-size-xxl-cover {
    background-size: cover;
  }

  .bgi-size-xxl-contain {
    background-size: contain;
  }

  .bgi-attachment-xxl-fixed {
    background-attachment: fixed;
  }

  .bgi-attachment-xxl-auto {
    background-attachment: auto;
  }
}
.light-theme .border {
  border-color: #eff2f5 !important;
}
.dark-theme .border {
  border-color: #3f4254 !important;
}

.light-theme .border-top {
  border-top: 1px solid #eff2f5 !important;
}
.dark-theme .border-top {
  border-top: 1px solid #3f4254 !important;
}

.light-theme .border-bottom {
  border-bottom: 1px solid #eff2f5 !important;
}
.dark-theme .border-bottom {
  border-bottom: 1px solid #3f4254 !important;
}

.light-theme .border-end {
  border-right: 1px solid #eff2f5 !important;
}
.dark-theme .border-end {
  border-right: 1px solid #3f4254 !important;
}

.light-theme .border-start {
  border-left: 1px solid #eff2f5 !important;
}
.dark-theme .border-start {
  border-left: 1px solid #3f4254 !important;
}

.border-active:not(.active):not(:active):not(:hover):not(:focus) {
  border-color: transparent !important;
}

.border-hover:not(:hover):not(:focus):not(.active):not(:active) {
  cursor: pointer;
  border-color: transparent !important;
}

.border-white {
  border-color: #ffffff !important;
}

.light-theme .border-white {
  border-color: var(--white) !important;
}
.dark-theme .border-white {
  border-color: var(--white) !important;
}

.border-primary {
  border-color: #e10e8d !important;
}

.light-theme .border-primary {
  border-color: var(--primary) !important;
}
.dark-theme .border-primary {
  border-color: var(--primary) !important;
}

.border-secondary {
  border-color: #e4e6ef !important;
}

.light-theme .border-secondary {
  border-color: var(--secondary) !important;
}
.dark-theme .border-secondary {
  border-color: var(--secondary) !important;
}

.border-light {
  border-color: #eff2f5 !important;
}

.light-theme .border-light {
  border-color: var(--light) !important;
}
.dark-theme .border-light {
  border-color: var(--light) !important;
}

.border-success {
  border-color: #1BC5BC !important;
}

.light-theme .border-success {
  border-color: var(--success) !important;
}
.dark-theme .border-success {
  border-color: var(--success) !important;
}

.border-info {
  border-color: #5F5CF1 !important;
}

.light-theme .border-info {
  border-color: var(--info) !important;
}
.dark-theme .border-info {
  border-color: var(--info) !important;
}

.border-warning {
  border-color: #FFA800 !important;
}

.light-theme .border-warning {
  border-color: var(--warning) !important;
}
.dark-theme .border-warning {
  border-color: var(--warning) !important;
}

.border-danger {
  border-color: #F65464 !important;
}

.light-theme .border-danger {
  border-color: var(--danger) !important;
}
.dark-theme .border-danger {
  border-color: var(--danger) !important;
}

.border-dark {
  border-color: #3f4254 !important;
}

.light-theme .border-dark {
  border-color: var(--dark) !important;
}
.dark-theme .border-dark {
  border-color: var(--dark) !important;
}

.border-black {
  border-color: #000000 !important;
}

.light-theme .border-black {
  border-color: var(--black) !important;
}
.dark-theme .border-black {
  border-color: var(--black) !important;
}

.border-muted {
  border-color: #a1a5b7 !important;
}

.light-theme .border-muted {
  border-color: var(--muted) !important;
}
.dark-theme .border-muted {
  border-color: var(--muted) !important;
}

.border-gray-100 {
  border-color: #f5f8fa !important;
}

.light-theme .border-gray-100 {
  border-color: var(--gray-100) !important;
}
.dark-theme .border-gray-100 {
  border-color: var(--gray-100) !important;
}

.border-gray-200 {
  border-color: #eff2f5 !important;
}

.light-theme .border-gray-200 {
  border-color: var(--gray-200) !important;
}
.dark-theme .border-gray-200 {
  border-color: var(--gray-200) !important;
}

.border-gray-300 {
  border-color: #e4e6ef !important;
}

.light-theme .border-gray-300 {
  border-color: var(--gray-300) !important;
}
.dark-theme .border-gray-300 {
  border-color: var(--gray-300) !important;
}

.border-gray-400 {
  border-color: #b5b5c3 !important;
}

.light-theme .border-gray-400 {
  border-color: var(--gray-400) !important;
}
.dark-theme .border-gray-400 {
  border-color: var(--gray-400) !important;
}

.border-gray-500 {
  border-color: #a1a5b7 !important;
}

.light-theme .border-gray-500 {
  border-color: var(--gray-500) !important;
}
.dark-theme .border-gray-500 {
  border-color: var(--gray-500) !important;
}

.border-gray-600 {
  border-color: #7e8299 !important;
}

.light-theme .border-gray-600 {
  border-color: var(--gray-600) !important;
}
.dark-theme .border-gray-600 {
  border-color: var(--gray-600) !important;
}

.border-gray-700 {
  border-color: #5e6278 !important;
}

.light-theme .border-gray-700 {
  border-color: var(--gray-700) !important;
}
.dark-theme .border-gray-700 {
  border-color: var(--gray-700) !important;
}

.border-gray-800 {
  border-color: #3f4254 !important;
}

.light-theme .border-gray-800 {
  border-color: var(--gray-800) !important;
}
.dark-theme .border-gray-800 {
  border-color: var(--gray-800) !important;
}

.border-gray-900 {
  border-color: #181c32 !important;
}

.light-theme .border-gray-900 {
  border-color: var(--gray-900) !important;
}
.dark-theme .border-gray-900 {
  border-color: var(--gray-900) !important;
}

.border-blue {
  border-color: #3699FF !important;
}

.light-theme .border-blue {
  border-color: var(--blue) !important;
}
.dark-theme .border-blue {
  border-color: var(--blue) !important;
}

.border-indigo {
  border-color: #6610f2 !important;
}

.light-theme .border-indigo {
  border-color: var(--indigo) !important;
}
.dark-theme .border-indigo {
  border-color: var(--indigo) !important;
}

.border-purple {
  border-color: #5F5CF1 !important;
}

.light-theme .border-purple {
  border-color: var(--purple) !important;
}
.dark-theme .border-purple {
  border-color: var(--purple) !important;
}

.border-pink {
  border-color: #d63384 !important;
}

.light-theme .border-pink {
  border-color: var(--pink) !important;
}
.dark-theme .border-pink {
  border-color: var(--pink) !important;
}

.border-red {
  border-color: #F65464 !important;
}

.light-theme .border-red {
  border-color: var(--red) !important;
}
.dark-theme .border-red {
  border-color: var(--red) !important;
}

.border-orange {
  border-color: #fd7e14 !important;
}

.light-theme .border-orange {
  border-color: var(--orange) !important;
}
.dark-theme .border-orange {
  border-color: var(--orange) !important;
}

.border-yellow {
  border-color: #FFA800 !important;
}

.light-theme .border-yellow {
  border-color: var(--yellow) !important;
}
.dark-theme .border-yellow {
  border-color: var(--yellow) !important;
}

.border-green {
  border-color: #1BC5BC !important;
}

.light-theme .border-green {
  border-color: var(--green) !important;
}
.dark-theme .border-green {
  border-color: var(--green) !important;
}

.border-teal {
  border-color: #20c997 !important;
}

.light-theme .border-teal {
  border-color: var(--teal) !important;
}
.dark-theme .border-teal {
  border-color: var(--teal) !important;
}

.border-cyan {
  border-color: #0dcaf0 !important;
}

.light-theme .border-cyan {
  border-color: var(--cyan) !important;
}
.dark-theme .border-cyan {
  border-color: var(--cyan) !important;
}

.border-gray {
  border-color: #7e8299 !important;
}

.light-theme .border-gray {
  border-color: var(--gray) !important;
}
.dark-theme .border-gray {
  border-color: var(--gray) !important;
}

.border-gray-100 {
  border-color: #f5f8fa !important;
}

.border-gray-200 {
  border-color: #eff2f5 !important;
}

.border-gray-300 {
  border-color: #e4e6ef !important;
}

.border-gray-400 {
  border-color: #b5b5c3 !important;
}

.border-gray-500 {
  border-color: #a1a5b7 !important;
}

.border-gray-600 {
  border-color: #7e8299 !important;
}

.border-gray-700 {
  border-color: #5e6278 !important;
}

.border-gray-800 {
  border-color: #3f4254 !important;
}

.border-gray-900 {
  border-color: #181c32 !important;
}

.border-hover-white:hover {
  border-color: #ffffff !important;
}

.border-active-white.active {
  border-color: #ffffff !important;
}

.border-hover-primary:hover {
  border-color: #e10e8d !important;
}

.border-active-primary.active {
  border-color: #e10e8d !important;
}

.border-hover-secondary:hover {
  border-color: #e4e6ef !important;
}

.border-active-secondary.active {
  border-color: #e4e6ef !important;
}

.border-hover-light:hover {
  border-color: #eff2f5 !important;
}

.border-active-light.active {
  border-color: #eff2f5 !important;
}

.border-hover-success:hover {
  border-color: #1BC5BC !important;
}

.border-active-success.active {
  border-color: #1BC5BC !important;
}

.border-hover-info:hover {
  border-color: #5F5CF1 !important;
}

.border-active-info.active {
  border-color: #5F5CF1 !important;
}

.border-hover-warning:hover {
  border-color: #FFA800 !important;
}

.border-active-warning.active {
  border-color: #FFA800 !important;
}

.border-hover-danger:hover {
  border-color: #F65464 !important;
}

.border-active-danger.active {
  border-color: #F65464 !important;
}

.border-hover-dark:hover {
  border-color: #3f4254 !important;
}

.border-active-dark.active {
  border-color: #3f4254 !important;
}

.border-hover-black:hover {
  border-color: #000000 !important;
}

.border-active-black.active {
  border-color: #000000 !important;
}

.border-hover-muted:hover {
  border-color: #a1a5b7 !important;
}

.border-active-muted.active {
  border-color: #a1a5b7 !important;
}

.border-hover-gray-100:hover {
  border-color: #f5f8fa !important;
}

.border-active-gray-100.active {
  border-color: #f5f8fa !important;
}

.border-hover-gray-200:hover {
  border-color: #eff2f5 !important;
}

.border-active-gray-200.active {
  border-color: #eff2f5 !important;
}

.border-hover-gray-300:hover {
  border-color: #e4e6ef !important;
}

.border-active-gray-300.active {
  border-color: #e4e6ef !important;
}

.border-hover-gray-400:hover {
  border-color: #b5b5c3 !important;
}

.border-active-gray-400.active {
  border-color: #b5b5c3 !important;
}

.border-hover-gray-500:hover {
  border-color: #a1a5b7 !important;
}

.border-active-gray-500.active {
  border-color: #a1a5b7 !important;
}

.border-hover-gray-600:hover {
  border-color: #7e8299 !important;
}

.border-active-gray-600.active {
  border-color: #7e8299 !important;
}

.border-hover-gray-700:hover {
  border-color: #5e6278 !important;
}

.border-active-gray-700.active {
  border-color: #5e6278 !important;
}

.border-hover-gray-800:hover {
  border-color: #3f4254 !important;
}

.border-active-gray-800.active {
  border-color: #3f4254 !important;
}

.border-hover-gray-900:hover {
  border-color: #181c32 !important;
}

.border-active-gray-900.active {
  border-color: #181c32 !important;
}

.border-hover-blue:hover {
  border-color: #3699FF !important;
}

.border-active-blue.active {
  border-color: #3699FF !important;
}

.border-hover-indigo:hover {
  border-color: #6610f2 !important;
}

.border-active-indigo.active {
  border-color: #6610f2 !important;
}

.border-hover-purple:hover {
  border-color: #5F5CF1 !important;
}

.border-active-purple.active {
  border-color: #5F5CF1 !important;
}

.border-hover-pink:hover {
  border-color: #d63384 !important;
}

.border-active-pink.active {
  border-color: #d63384 !important;
}

.border-hover-red:hover {
  border-color: #F65464 !important;
}

.border-active-red.active {
  border-color: #F65464 !important;
}

.border-hover-orange:hover {
  border-color: #fd7e14 !important;
}

.border-active-orange.active {
  border-color: #fd7e14 !important;
}

.border-hover-yellow:hover {
  border-color: #FFA800 !important;
}

.border-active-yellow.active {
  border-color: #FFA800 !important;
}

.border-hover-green:hover {
  border-color: #1BC5BC !important;
}

.border-active-green.active {
  border-color: #1BC5BC !important;
}

.border-hover-teal:hover {
  border-color: #20c997 !important;
}

.border-active-teal.active {
  border-color: #20c997 !important;
}

.border-hover-cyan:hover {
  border-color: #0dcaf0 !important;
}

.border-active-cyan.active {
  border-color: #0dcaf0 !important;
}

.border-hover-gray:hover {
  border-color: #7e8299 !important;
}

.border-active-gray.active {
  border-color: #7e8299 !important;
}

.border-dashed {
  border-style: dashed !important;
  border-color: #e4e6ef;
}

.border-top-dashed {
  border-top-style: dashed !important;
}

.border-bottom-dashed {
  border-bottom-style: dashed !important;
}

.border-start-dashed {
  border-left-style: dashed !important;
}

.border-end-dashed {
  border-right-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-top-dotted {
  border-top-style: dotted !important;
}

.border-bottom-dotted {
  border-bottom-style: dotted !important;
}

.border-start-dotted {
  border-left-style: dotted !important;
}

.border-end-dotted {
  border-right-style: dotted !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-transparent {
  border-color: transparent !important;
}

.rounded-sm {
  border-radius: 0.275rem;
}

.rounded {
  border-radius: 0.475rem;
}

.rounded-lg {
  border-radius: 0.775rem;
}

.rounded-xl {
  border-radius: 1rem;
}

.rounded-xxl {
  border-radius: 2rem;
}

.rounded-circle {
  border-radius: 50%;
}

.flex-root {
  flex: 1;
}

.flex-column-auto {
  flex: none;
}

.flex-column-fluid {
  flex: 1 0 auto;
}

.flex-row-auto {
  flex: 0 0 auto;
}

.flex-row-fluid {
  flex: 1 auto;
  min-width: 0;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-stack {
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 576px) {
  .flex-sm-root {
    flex: 1;
  }

  .flex-sm-column-auto {
    flex: none;
  }

  .flex-sm-column-fluid {
    flex: 1 0 auto;
  }

  .flex-sm-row-auto {
    flex: 0 0 auto;
  }

  .flex-sm-row-fluid {
    flex: 1 auto;
    min-width: 0;
  }

  .flex-sm-center {
    justify-content: center;
    align-items: center;
  }

  .flex-sm-stack {
    justify-content: space-between;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .flex-md-root {
    flex: 1;
  }

  .flex-md-column-auto {
    flex: none;
  }

  .flex-md-column-fluid {
    flex: 1 0 auto;
  }

  .flex-md-row-auto {
    flex: 0 0 auto;
  }

  .flex-md-row-fluid {
    flex: 1 auto;
    min-width: 0;
  }

  .flex-md-center {
    justify-content: center;
    align-items: center;
  }

  .flex-md-stack {
    justify-content: space-between;
    align-items: center;
  }
}
@media (min-width: 992px) {
  .flex-lg-root {
    flex: 1;
  }

  .flex-lg-column-auto {
    flex: none;
  }

  .flex-lg-column-fluid {
    flex: 1 0 auto;
  }

  .flex-lg-row-auto {
    flex: 0 0 auto;
  }

  .flex-lg-row-fluid {
    flex: 1 auto;
    min-width: 0;
  }

  .flex-lg-center {
    justify-content: center;
    align-items: center;
  }

  .flex-lg-stack {
    justify-content: space-between;
    align-items: center;
  }
}
@media (min-width: 1200px) {
  .flex-xl-root {
    flex: 1;
  }

  .flex-xl-column-auto {
    flex: none;
  }

  .flex-xl-column-fluid {
    flex: 1 0 auto;
  }

  .flex-xl-row-auto {
    flex: 0 0 auto;
  }

  .flex-xl-row-fluid {
    flex: 1 auto;
    min-width: 0;
  }

  .flex-xl-center {
    justify-content: center;
    align-items: center;
  }

  .flex-xl-stack {
    justify-content: space-between;
    align-items: center;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-root {
    flex: 1;
  }

  .flex-xxl-column-auto {
    flex: none;
  }

  .flex-xxl-column-fluid {
    flex: 1 0 auto;
  }

  .flex-xxl-row-auto {
    flex: 0 0 auto;
  }

  .flex-xxl-row-fluid {
    flex: 1 auto;
    min-width: 0;
  }

  .flex-xxl-center {
    justify-content: center;
    align-items: center;
  }

  .flex-xxl-stack {
    justify-content: space-between;
    align-items: center;
  }
}
.opacity-active-0.active {
  opacity: 0 !important;
}

.opacity-state-0:hover, .opacity-state-0.active {
  opacity: 0 !important;
}

.opacity-active-5.active {
  opacity: 0.05 !important;
}

.opacity-state-5:hover, .opacity-state-5.active {
  opacity: 0.05 !important;
}

.opacity-active-10.active {
  opacity: 0.1 !important;
}

.opacity-state-10:hover, .opacity-state-10.active {
  opacity: 0.1 !important;
}

.opacity-active-15.active {
  opacity: 0.15 !important;
}

.opacity-state-15:hover, .opacity-state-15.active {
  opacity: 0.15 !important;
}

.opacity-active-20.active {
  opacity: 0.2 !important;
}

.opacity-state-20:hover, .opacity-state-20.active {
  opacity: 0.2 !important;
}

.opacity-active-25.active {
  opacity: 0.25 !important;
}

.opacity-state-25:hover, .opacity-state-25.active {
  opacity: 0.25 !important;
}

.opacity-active-50.active {
  opacity: 0.5 !important;
}

.opacity-state-50:hover, .opacity-state-50.active {
  opacity: 0.5 !important;
}

.opacity-active-75.active {
  opacity: 0.75 !important;
}

.opacity-state-75:hover, .opacity-state-75.active {
  opacity: 0.75 !important;
}

.opacity-active-100.active {
  opacity: 1 !important;
}

.opacity-state-100:hover, .opacity-state-100.active {
  opacity: 1 !important;
}

.text-white {
  color: var(--white);
}

.light-theme .text-white {
  color: #ffffff;
}
.dark-theme .text-white {
  color: #000000;
}

.text-inverse-white {
  color: #000000;
}

.text-hover-white {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-white i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-white:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-white:hover {
  color: #ffffff !important;
}
.dark-theme .text-hover-white:hover {
  color: #000000 !important;
}
.text-hover-white:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-white:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-white:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-white {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-white i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-white:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #000000 !important;
}
.text-hover-inverse-white:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #000000 !important;
}
.text-hover-inverse-white:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #000000;
}
.text-hover-inverse-white:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-white {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-white i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-white.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-white.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-white.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-white.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-white {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-white i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-white.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #000000 !important;
}
.text-active-inverse-white.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #000000 !important;
}
.text-active-inverse-white.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #000000;
}
.text-active-inverse-white.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-primary {
  color: var(--primary);
}

.light-theme .text-primary {
  color: #e10e8d;
}
.dark-theme .text-primary {
  color: #e10e8d;
}

.text-inverse-primary {
  color: #ffffff;
}

.text-light-primary {
  color: #fff1fe;
}

.text-lighten-primary {
  color: rgba(255, 241, 254, 0.5) !important;
}

.text-hover-primary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-primary i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-primary:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-primary:hover {
  color: #e10e8d !important;
}
.dark-theme .text-hover-primary:hover {
  color: #e10e8d !important;
}
.text-hover-primary:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e10e8d !important;
}
.text-hover-primary:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.text-hover-primary:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-primary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-primary i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-primary:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-primary:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-primary:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-inverse-primary:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-primary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-primary i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-primary.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e10e8d !important;
}
.text-active-primary.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e10e8d !important;
}
.text-active-primary.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.text-active-primary.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-primary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-primary i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-primary.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-primary.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-primary.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-inverse-primary.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-secondary {
  color: var(--secondary);
}

.light-theme .text-secondary {
  color: #e4e6ef;
}
.dark-theme .text-secondary {
  color: #e4e6ef;
}

.text-inverse-secondary {
  color: #3f4254;
}

.text-hover-secondary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-secondary i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-secondary:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-secondary:hover {
  color: #e4e6ef !important;
}
.dark-theme .text-hover-secondary:hover {
  color: #e4e6ef !important;
}
.text-hover-secondary:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e4e6ef !important;
}
.text-hover-secondary:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.text-hover-secondary:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-secondary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-secondary i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-secondary:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3f4254 !important;
}
.text-hover-inverse-secondary:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3f4254 !important;
}
.text-hover-inverse-secondary:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.text-hover-inverse-secondary:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-secondary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-secondary i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-secondary.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e4e6ef !important;
}
.text-active-secondary.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e4e6ef !important;
}
.text-active-secondary.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.text-active-secondary.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-secondary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-secondary i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-secondary.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3f4254 !important;
}
.text-active-inverse-secondary.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3f4254 !important;
}
.text-active-inverse-secondary.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.text-active-inverse-secondary.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-light {
  color: var(--light);
}

.light-theme .text-light {
  color: #eff2f5;
}
.dark-theme .text-light {
  color: #3f4254;
}

.text-inverse-light {
  color: #3f4254;
}

.text-hover-light {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-light i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-light:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-light:hover {
  color: #eff2f5 !important;
}
.dark-theme .text-hover-light:hover {
  color: #3f4254 !important;
}
.text-hover-light:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #eff2f5 !important;
}
.text-hover-light:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.text-hover-light:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-light {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-light i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-light:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3f4254 !important;
}
.text-hover-inverse-light:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3f4254 !important;
}
.text-hover-inverse-light:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.text-hover-inverse-light:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-light {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-light i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-light.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #eff2f5 !important;
}
.text-active-light.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #eff2f5 !important;
}
.text-active-light.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.text-active-light.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-light {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-light i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-light.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3f4254 !important;
}
.text-active-inverse-light.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3f4254 !important;
}
.text-active-inverse-light.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.text-active-inverse-light.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-success {
  color: var(--success);
}

.light-theme .text-success {
  color: #1BC5BC;
}
.dark-theme .text-success {
  color: #1BC5BC;
}

.text-inverse-success {
  color: #ffffff;
}

.text-light-success {
  color: rgba(27, 197, 188, 0.1);
}

.text-lighten-success {
  color: #b7f5f2 !important;
}

.text-hover-success {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-success i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-success:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-success:hover {
  color: #1BC5BC !important;
}
.dark-theme .text-hover-success:hover {
  color: #1BC5BC !important;
}
.text-hover-success:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #1BC5BC !important;
}
.text-hover-success:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.text-hover-success:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-success {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-success i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-success:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-success:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-success:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-inverse-success:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-success {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-success i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-success.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #1BC5BC !important;
}
.text-active-success.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #1BC5BC !important;
}
.text-active-success.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.text-active-success.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-success {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-success i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-success.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-success.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-success.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-inverse-success.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-info {
  color: var(--info);
}

.light-theme .text-info {
  color: #5F5CF1;
}
.dark-theme .text-info {
  color: #5F5CF1;
}

.text-inverse-info {
  color: #ffffff;
}

.text-light-info {
  color: rgba(95, 92, 241, 0.1);
}

.text-lighten-info {
  color: white !important;
}

.text-hover-info {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-info i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-info:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-info:hover {
  color: #5F5CF1 !important;
}
.dark-theme .text-hover-info:hover {
  color: #5F5CF1 !important;
}
.text-hover-info:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #5F5CF1 !important;
}
.text-hover-info:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.text-hover-info:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-info {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-info i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-info:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-info:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-info:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-inverse-info:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-info {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-info i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-info.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #5F5CF1 !important;
}
.text-active-info.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #5F5CF1 !important;
}
.text-active-info.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.text-active-info.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-info {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-info i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-info.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-info.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-info.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-inverse-info.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-warning {
  color: var(--warning);
}

.light-theme .text-warning {
  color: #FFA800;
}
.dark-theme .text-warning {
  color: #FFA800;
}

.text-inverse-warning {
  color: #eff2f5;
}

.text-light-warning {
  color: rgba(255, 168, 0, 0.15);
}

.text-lighten-warning {
  color: #ffeecc !important;
}

.text-hover-warning {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-warning i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-warning:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-warning:hover {
  color: #FFA800 !important;
}
.dark-theme .text-hover-warning:hover {
  color: #FFA800 !important;
}
.text-hover-warning:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFA800 !important;
}
.text-hover-warning:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.text-hover-warning:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-warning {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-warning i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-warning:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #eff2f5 !important;
}
.text-hover-inverse-warning:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #eff2f5 !important;
}
.text-hover-inverse-warning:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.text-hover-inverse-warning:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-warning {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-warning i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-warning.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFA800 !important;
}
.text-active-warning.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFA800 !important;
}
.text-active-warning.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.text-active-warning.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-warning {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-warning i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-warning.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #eff2f5 !important;
}
.text-active-inverse-warning.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #eff2f5 !important;
}
.text-active-inverse-warning.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.text-active-inverse-warning.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-danger {
  color: var(--danger);
}

.light-theme .text-danger {
  color: #F65464;
}
.dark-theme .text-danger {
  color: #F65464;
}

.text-inverse-danger {
  color: #ffffff;
}

.text-light-danger {
  color: rgba(246, 84, 100, 0.1);
}

.text-lighten-danger {
  color: white !important;
}

.text-hover-danger {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-danger i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-danger:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-danger:hover {
  color: #F65464 !important;
}
.dark-theme .text-hover-danger:hover {
  color: #F65464 !important;
}
.text-hover-danger:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F65464 !important;
}
.text-hover-danger:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.text-hover-danger:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-danger {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-danger i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-danger:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-danger:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-danger:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-inverse-danger:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-danger {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-danger i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-danger.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F65464 !important;
}
.text-active-danger.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F65464 !important;
}
.text-active-danger.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.text-active-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-danger {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-danger i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-danger.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-danger.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-danger.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-inverse-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-dark {
  color: var(--dark);
}

.light-theme .text-dark {
  color: #3f4254;
}
.dark-theme .text-dark {
  color: #eff2f5;
}

.text-inverse-dark {
  color: #f5f8fa;
}

.text-light-dark {
  color: #eff2f5;
}

.text-hover-dark {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-dark i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-dark:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-dark:hover {
  color: #3f4254 !important;
}
.dark-theme .text-hover-dark:hover {
  color: #eff2f5 !important;
}
.text-hover-dark:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3f4254 !important;
}
.text-hover-dark:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.text-hover-dark:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-dark {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-dark i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-dark:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #f5f8fa !important;
}
.text-hover-inverse-dark:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #f5f8fa !important;
}
.text-hover-inverse-dark:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #f5f8fa;
}
.text-hover-inverse-dark:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-dark {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-dark i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-dark.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3f4254 !important;
}
.text-active-dark.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3f4254 !important;
}
.text-active-dark.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.text-active-dark.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-dark {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-dark i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-dark.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #f5f8fa !important;
}
.text-active-inverse-dark.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #f5f8fa !important;
}
.text-active-inverse-dark.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #f5f8fa;
}
.text-active-inverse-dark.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-black {
  color: var(--black);
}

.light-theme .text-black {
  color: #000000;
}
.dark-theme .text-black {
  color: #ffffff;
}

.text-inverse-black {
  color: #ffffff;
}

.text-hover-black {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-black i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-black:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-black:hover {
  color: #000000 !important;
}
.dark-theme .text-hover-black:hover {
  color: #ffffff !important;
}
.text-hover-black:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #000000 !important;
}
.text-hover-black:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #000000;
}
.text-hover-black:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-black {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-black i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-black:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-black:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-black:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-inverse-black:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-black {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-black i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-black.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #000000 !important;
}
.text-active-black.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #000000 !important;
}
.text-active-black.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #000000;
}
.text-active-black.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-black {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-black i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-black.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-black.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-black.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-inverse-black.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-muted {
  color: var(--muted);
}

.light-theme .text-muted {
  color: #a1a5b7;
}
.dark-theme .text-muted {
  color: #a1a5b7;
}

.text-hover-muted {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-muted i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-muted:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-muted:hover {
  color: #a1a5b7 !important;
}
.dark-theme .text-hover-muted:hover {
  color: #a1a5b7 !important;
}
.text-hover-muted:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #a1a5b7 !important;
}
.text-hover-muted:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.text-hover-muted:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-muted {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-muted i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-muted:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-muted:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-muted:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-muted:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-muted {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-muted i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-muted.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #a1a5b7 !important;
}
.text-active-muted.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #a1a5b7 !important;
}
.text-active-muted.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.text-active-muted.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-muted {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-muted i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-muted.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-muted.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-muted.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-muted.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-100 {
  color: var(--gray-100);
}

.light-theme .text-gray-100 {
  color: #f5f8fa;
}
.dark-theme .text-gray-100 {
  color: #181c32;
}

.text-hover-gray-100 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-100 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-100:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-gray-100:hover {
  color: #f5f8fa !important;
}
.dark-theme .text-hover-gray-100:hover {
  color: #181c32 !important;
}
.text-hover-gray-100:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #f5f8fa !important;
}
.text-hover-gray-100:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #f5f8fa;
}
.text-hover-gray-100:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-100 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-100 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-100:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-100:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-100:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-100:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-100 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-100 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-100.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #f5f8fa !important;
}
.text-active-gray-100.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #f5f8fa !important;
}
.text-active-gray-100.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #f5f8fa;
}
.text-active-gray-100.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-100 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-100 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-100.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-100.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-100.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-100.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-200 {
  color: var(--gray-200);
}

.light-theme .text-gray-200 {
  color: #eff2f5;
}
.dark-theme .text-gray-200 {
  color: #3f4254;
}

.text-hover-gray-200 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-200 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-200:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-gray-200:hover {
  color: #eff2f5 !important;
}
.dark-theme .text-hover-gray-200:hover {
  color: #3f4254 !important;
}
.text-hover-gray-200:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #eff2f5 !important;
}
.text-hover-gray-200:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.text-hover-gray-200:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-200 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-200 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-200:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-200:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-200:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-200:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-200 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-200 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-200.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #eff2f5 !important;
}
.text-active-gray-200.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #eff2f5 !important;
}
.text-active-gray-200.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.text-active-gray-200.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-200 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-200 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-200.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-200.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-200.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-200.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-300 {
  color: var(--gray-300);
}

.light-theme .text-gray-300 {
  color: #e4e6ef;
}
.dark-theme .text-gray-300 {
  color: #5e6278;
}

.text-hover-gray-300 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-300 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-300:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-gray-300:hover {
  color: #e4e6ef !important;
}
.dark-theme .text-hover-gray-300:hover {
  color: #5e6278 !important;
}
.text-hover-gray-300:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e4e6ef !important;
}
.text-hover-gray-300:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.text-hover-gray-300:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-300 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-300 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-300:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-300:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-300:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-300:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-300 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-300 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-300.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e4e6ef !important;
}
.text-active-gray-300.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #e4e6ef !important;
}
.text-active-gray-300.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.text-active-gray-300.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-300 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-300 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-300.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-300.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-300.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-300.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-400 {
  color: var(--gray-400);
}

.light-theme .text-gray-400 {
  color: #b5b5c3;
}
.dark-theme .text-gray-400 {
  color: #7e8299;
}

.text-hover-gray-400 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-400 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-400:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-gray-400:hover {
  color: #b5b5c3 !important;
}
.dark-theme .text-hover-gray-400:hover {
  color: #7e8299 !important;
}
.text-hover-gray-400:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #b5b5c3 !important;
}
.text-hover-gray-400:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.text-hover-gray-400:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-400 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-400 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-400:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-400:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-400:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-400:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-400 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-400 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-400.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #b5b5c3 !important;
}
.text-active-gray-400.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #b5b5c3 !important;
}
.text-active-gray-400.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.text-active-gray-400.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-400 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-400 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-400.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-400.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-400.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-400.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-500 {
  color: var(--gray-500);
}

.light-theme .text-gray-500 {
  color: #a1a5b7;
}
.dark-theme .text-gray-500 {
  color: #a1a5b7;
}

.text-hover-gray-500 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-500 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-500:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-gray-500:hover {
  color: #a1a5b7 !important;
}
.dark-theme .text-hover-gray-500:hover {
  color: #a1a5b7 !important;
}
.text-hover-gray-500:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #a1a5b7 !important;
}
.text-hover-gray-500:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.text-hover-gray-500:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-500 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-500 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-500:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-500:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-500:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-500:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-500 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-500 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-500.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #a1a5b7 !important;
}
.text-active-gray-500.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #a1a5b7 !important;
}
.text-active-gray-500.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.text-active-gray-500.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-500 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-500 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-500.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-500.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-500.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-500.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-600 {
  color: var(--gray-600);
}

.light-theme .text-gray-600 {
  color: #7e8299;
}
.dark-theme .text-gray-600 {
  color: #b5b5c3;
}

.text-hover-gray-600 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-600 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-600:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-gray-600:hover {
  color: #7e8299 !important;
}
.dark-theme .text-hover-gray-600:hover {
  color: #b5b5c3 !important;
}
.text-hover-gray-600:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7e8299 !important;
}
.text-hover-gray-600:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.text-hover-gray-600:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-600 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-600 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-600:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-600:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-600:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-600:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-600 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-600 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-600.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7e8299 !important;
}
.text-active-gray-600.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7e8299 !important;
}
.text-active-gray-600.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.text-active-gray-600.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-600 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-600 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-600.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-600.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-600.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-600.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-700 {
  color: var(--gray-700);
}

.light-theme .text-gray-700 {
  color: #5e6278;
}
.dark-theme .text-gray-700 {
  color: #e4e6ef;
}

.text-hover-gray-700 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-700 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-700:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-gray-700:hover {
  color: #5e6278 !important;
}
.dark-theme .text-hover-gray-700:hover {
  color: #e4e6ef !important;
}
.text-hover-gray-700:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #5e6278 !important;
}
.text-hover-gray-700:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5e6278;
}
.text-hover-gray-700:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-700 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-700 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-700:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-700:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-700:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-700:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-700 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-700 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-700.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #5e6278 !important;
}
.text-active-gray-700.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #5e6278 !important;
}
.text-active-gray-700.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5e6278;
}
.text-active-gray-700.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-700 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-700 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-700.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-700.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-700.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-700.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-800 {
  color: var(--gray-800);
}

.light-theme .text-gray-800 {
  color: #3f4254;
}
.dark-theme .text-gray-800 {
  color: #eff2f5;
}

.text-hover-gray-800 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-800 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-800:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-gray-800:hover {
  color: #3f4254 !important;
}
.dark-theme .text-hover-gray-800:hover {
  color: #eff2f5 !important;
}
.text-hover-gray-800:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3f4254 !important;
}
.text-hover-gray-800:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.text-hover-gray-800:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-800 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-800 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-800:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-800:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-800:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-800:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-800 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-800 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-800.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3f4254 !important;
}
.text-active-gray-800.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3f4254 !important;
}
.text-active-gray-800.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.text-active-gray-800.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-800 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-800 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-800.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-800.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-800.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-800.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray-900 {
  color: var(--gray-900);
}

.light-theme .text-gray-900 {
  color: #181c32;
}
.dark-theme .text-gray-900 {
  color: #f5f8fa;
}

.text-hover-gray-900 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-900 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray-900:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-gray-900:hover {
  color: #181c32 !important;
}
.dark-theme .text-hover-gray-900:hover {
  color: #f5f8fa !important;
}
.text-hover-gray-900:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #181c32 !important;
}
.text-hover-gray-900:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181c32;
}
.text-hover-gray-900:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray-900 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-900 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray-900:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-900:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-hover-inverse-gray-900:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-hover-inverse-gray-900:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray-900 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-900 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray-900.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #181c32 !important;
}
.text-active-gray-900.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #181c32 !important;
}
.text-active-gray-900.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181c32;
}
.text-active-gray-900.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray-900 {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-900 i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray-900.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-900.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: !important;
}
.text-active-inverse-gray-900.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.text-active-inverse-gray-900.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-blue {
  color: var(--blue);
}

.light-theme .text-blue {
  color: #3699FF;
}
.dark-theme .text-blue {
  color: #3699FF;
}

.text-inverse-blue {
  color: #ffffff;
}

.text-light-blue {
  color: rgba(54, 153, 255, 0.1);
}

.text-lighten-blue {
  color: white !important;
}

.text-hover-blue {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-blue i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-blue:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-blue:hover {
  color: #3699FF !important;
}
.dark-theme .text-hover-blue:hover {
  color: #3699FF !important;
}
.text-hover-blue:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3699FF !important;
}
.text-hover-blue:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3699FF;
}
.text-hover-blue:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-blue {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-blue i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-blue:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-blue:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-blue:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-inverse-blue:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-blue {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-blue i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-blue.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3699FF !important;
}
.text-active-blue.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #3699FF !important;
}
.text-active-blue.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3699FF;
}
.text-active-blue.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-blue {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-blue i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-blue.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-blue.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-blue.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-inverse-blue.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-indigo {
  color: var(--indigo);
}

.light-theme .text-indigo {
  color: #6610f2;
}
.dark-theme .text-indigo {
  color: #6610f2;
}

.text-inverse-indigo {
  color: #ffffff;
}

.text-light-indigo {
  color: rgba(102, 16, 242, 0.1);
}

.text-lighten-indigo {
  color: #e2d1fd !important;
}

.text-hover-indigo {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-indigo i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-indigo:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-indigo:hover {
  color: #6610f2 !important;
}
.dark-theme .text-hover-indigo:hover {
  color: #6610f2 !important;
}
.text-hover-indigo:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #6610f2 !important;
}
.text-hover-indigo:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #6610f2;
}
.text-hover-indigo:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-indigo {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-indigo i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-indigo:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-indigo:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-indigo:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-inverse-indigo:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-indigo {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-indigo i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-indigo.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #6610f2 !important;
}
.text-active-indigo.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #6610f2 !important;
}
.text-active-indigo.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #6610f2;
}
.text-active-indigo.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-indigo {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-indigo i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-indigo.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-indigo.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-indigo.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-inverse-indigo.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-purple {
  color: var(--purple);
}

.light-theme .text-purple {
  color: #5F5CF1;
}
.dark-theme .text-purple {
  color: #5F5CF1;
}

.text-inverse-purple {
  color: #ffffff;
}

.text-light-purple {
  color: rgba(95, 92, 241, 0.1);
}

.text-lighten-purple {
  color: white !important;
}

.text-hover-purple {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-purple i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-purple:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-purple:hover {
  color: #5F5CF1 !important;
}
.dark-theme .text-hover-purple:hover {
  color: #5F5CF1 !important;
}
.text-hover-purple:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #5F5CF1 !important;
}
.text-hover-purple:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.text-hover-purple:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-purple {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-purple i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-purple:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-purple:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-purple:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-inverse-purple:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-purple {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-purple i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-purple.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #5F5CF1 !important;
}
.text-active-purple.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #5F5CF1 !important;
}
.text-active-purple.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.text-active-purple.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-purple {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-purple i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-purple.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-purple.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-purple.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-inverse-purple.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-pink {
  color: var(--pink);
}

.light-theme .text-pink {
  color: #d63384;
}
.dark-theme .text-pink {
  color: #d63384;
}

.text-inverse-pink {
  color: #ffffff;
}

.text-light-pink {
  color: rgba(214, 51, 132, 0.1);
}

.text-lighten-pink {
  color: #f8ddea !important;
}

.text-hover-pink {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-pink i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-pink:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-pink:hover {
  color: #d63384 !important;
}
.dark-theme .text-hover-pink:hover {
  color: #d63384 !important;
}
.text-hover-pink:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #d63384 !important;
}
.text-hover-pink:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #d63384;
}
.text-hover-pink:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-pink {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-pink i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-pink:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-pink:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-pink:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-inverse-pink:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-pink {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-pink i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-pink.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #d63384 !important;
}
.text-active-pink.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #d63384 !important;
}
.text-active-pink.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #d63384;
}
.text-active-pink.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-pink {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-pink i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-pink.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-pink.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-pink.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-inverse-pink.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-red {
  color: var(--red);
}

.light-theme .text-red {
  color: #F65464;
}
.dark-theme .text-red {
  color: #F65464;
}

.text-inverse-red {
  color: #ffffff;
}

.text-light-red {
  color: rgba(246, 84, 100, 0.1);
}

.text-lighten-red {
  color: white !important;
}

.text-hover-red {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-red i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-red:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-red:hover {
  color: #F65464 !important;
}
.dark-theme .text-hover-red:hover {
  color: #F65464 !important;
}
.text-hover-red:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F65464 !important;
}
.text-hover-red:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.text-hover-red:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-red {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-red i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-red:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-red:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-red:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-inverse-red:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-red {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-red i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-red.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F65464 !important;
}
.text-active-red.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #F65464 !important;
}
.text-active-red.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.text-active-red.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-red {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-red i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-red.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-red.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-red.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-inverse-red.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-orange {
  color: var(--orange);
}

.light-theme .text-orange {
  color: #fd7e14;
}
.dark-theme .text-orange {
  color: #fd7e14;
}

.text-inverse-orange {
  color: #ffffff;
}

.text-light-orange {
  color: rgba(253, 126, 20, 0.1);
}

.text-lighten-orange {
  color: #ffedde !important;
}

.text-hover-orange {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-orange i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-orange:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-orange:hover {
  color: #fd7e14 !important;
}
.dark-theme .text-hover-orange:hover {
  color: #fd7e14 !important;
}
.text-hover-orange:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #fd7e14 !important;
}
.text-hover-orange:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #fd7e14;
}
.text-hover-orange:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-orange {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-orange i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-orange:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-orange:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-orange:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-inverse-orange:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-orange {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-orange i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-orange.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #fd7e14 !important;
}
.text-active-orange.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #fd7e14 !important;
}
.text-active-orange.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #fd7e14;
}
.text-active-orange.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-orange {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-orange i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-orange.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-orange.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-orange.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-inverse-orange.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-yellow {
  color: var(--yellow);
}

.light-theme .text-yellow {
  color: #FFA800;
}
.dark-theme .text-yellow {
  color: #FFA800;
}

.text-inverse-yellow {
  color: #ffffff;
}

.text-light-yellow {
  color: rgba(255, 168, 0, 0.1);
}

.text-lighten-yellow {
  color: #ffeecc !important;
}

.text-hover-yellow {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-yellow i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-yellow:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-yellow:hover {
  color: #FFA800 !important;
}
.dark-theme .text-hover-yellow:hover {
  color: #FFA800 !important;
}
.text-hover-yellow:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFA800 !important;
}
.text-hover-yellow:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.text-hover-yellow:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-yellow {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-yellow i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-yellow:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-yellow:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-yellow:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-inverse-yellow:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-yellow {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-yellow i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-yellow.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFA800 !important;
}
.text-active-yellow.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #FFA800 !important;
}
.text-active-yellow.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.text-active-yellow.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-yellow {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-yellow i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-yellow.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-yellow.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-yellow.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-inverse-yellow.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-green {
  color: var(--green);
}

.light-theme .text-green {
  color: #1BC5BC;
}
.text-inverse-green {
  color: #ffffff;
}

.text-light-green {
  color: rgba(27, 197, 188, 0.1);
}

.text-lighten-green {
  color: #b7f5f2 !important;
}

.text-hover-green {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-green i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-green:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-green:hover {
  color: #1BC5BC !important;
}
.dark-theme .text-hover-green:hover {
  color: !important;
}
.text-hover-green:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #1BC5BC !important;
}
.text-hover-green:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.text-hover-green:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-green {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-green i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-green:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-green:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-green:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-inverse-green:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-green {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-green i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-green.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #1BC5BC !important;
}
.text-active-green.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #1BC5BC !important;
}
.text-active-green.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.text-active-green.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-green {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-green i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-green.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-green.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-green.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-inverse-green.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-teal {
  color: var(--teal);
}

.light-theme .text-teal {
  color: #20c997;
}
.dark-theme .text-teal {
  color: #20c997;
}

.text-inverse-teal {
  color: #ffffff;
}

.text-light-teal {
  color: rgba(32, 201, 151, 0.1);
}

.text-lighten-teal {
  color: #c0f5e5 !important;
}

.text-hover-teal {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-teal i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-teal:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-teal:hover {
  color: #20c997 !important;
}
.dark-theme .text-hover-teal:hover {
  color: #20c997 !important;
}
.text-hover-teal:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #20c997 !important;
}
.text-hover-teal:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #20c997;
}
.text-hover-teal:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-teal {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-teal i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-teal:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-teal:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-teal:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-inverse-teal:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-teal {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-teal i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-teal.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #20c997 !important;
}
.text-active-teal.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #20c997 !important;
}
.text-active-teal.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #20c997;
}
.text-active-teal.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-teal {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-teal i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-teal.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-teal.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-teal.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-inverse-teal.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-cyan {
  color: var(--cyan);
}

.light-theme .text-cyan {
  color: #0dcaf0;
}
.dark-theme .text-cyan {
  color: #0dcaf0;
}

.text-inverse-cyan {
  color: #ffffff;
}

.text-light-cyan {
  color: rgba(13, 202, 240, 0.1);
}

.text-lighten-cyan {
  color: #cdf4fc !important;
}

.text-hover-cyan {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-cyan i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-cyan:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-cyan:hover {
  color: #0dcaf0 !important;
}
.dark-theme .text-hover-cyan:hover {
  color: #0dcaf0 !important;
}
.text-hover-cyan:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #0dcaf0 !important;
}
.text-hover-cyan:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #0dcaf0;
}
.text-hover-cyan:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-cyan {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-cyan i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-cyan:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-cyan:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-hover-inverse-cyan:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-hover-inverse-cyan:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-cyan {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-cyan i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-cyan.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #0dcaf0 !important;
}
.text-active-cyan.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #0dcaf0 !important;
}
.text-active-cyan.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #0dcaf0;
}
.text-active-cyan.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-cyan {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-cyan i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-cyan.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-cyan.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #ffffff !important;
}
.text-active-inverse-cyan.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.text-active-inverse-cyan.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-gray {
  color: var(--gray);
}

.light-theme .text-gray {
  color: #7e8299;
}
.dark-theme .text-gray {
  color: #7e8299;
}

.text-inverse-gray {
  color: #b5b5c3;
}

.text-light-gray {
  color: rgba(126, 130, 153, 0.1);
}

.text-lighten-gray {
  color: #b5b5c3 !important;
}

.text-hover-gray {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-gray:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .text-hover-gray:hover {
  color: #7e8299 !important;
}
.dark-theme .text-hover-gray:hover {
  color: #7e8299 !important;
}
.text-hover-gray:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7e8299 !important;
}
.text-hover-gray:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.text-hover-gray:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-hover-inverse-gray {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-hover-inverse-gray:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #b5b5c3 !important;
}
.text-hover-inverse-gray:hover i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #b5b5c3 !important;
}
.text-hover-inverse-gray:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.text-hover-inverse-gray:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-gray {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-gray.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7e8299 !important;
}
.text-active-gray.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #7e8299 !important;
}
.text-active-gray.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.text-active-gray.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.text-active-inverse-gray {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray i {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.text-active-inverse-gray.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #b5b5c3 !important;
}
.text-active-inverse-gray.active i {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #b5b5c3 !important;
}
.text-active-inverse-gray.active .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.text-active-inverse-gray.active .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.fw-boldest {
  font-weight: 700 !important;
}

.text-transparent {
  color: transparent;
}

.color-white {
  color: white !important;
}

.color-black {
  color: black !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-move {
  cursor: move;
}

.text-primary-inverse {
  color: var(--inverse-primary);
}

i {
  line-height: 1;
  font-size: 1rem;
}
i.icon-color {
  color: #a1a5b7;
}

a {
  transition: color 0.2s ease, background-color 0.2s ease;
}
a:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
}

.transform-90 {
  transform: rotate(90deg);
  transform-origin: right top;
}

.transform-inherit {
  transform: inherit !important;
}

.shadow-xs {
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.05);
}

.svg-icon {
  line-height: 1;
}
.svg-icon svg {
  height: 1.15rem;
  width: 1.15rem;
}
.svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-white svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--white) !important;
}
.svg-icon.svg-icon-white svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-white svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.light-theme .svg-icon.svg-icon-white svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-white svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #000000;
}
.dark-theme .svg-icon.svg-icon-white svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-primary svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--primary) !important;
}
.svg-icon.svg-icon-primary svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-primary svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.light-theme .svg-icon.svg-icon-primary svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-primary svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.dark-theme .svg-icon.svg-icon-primary svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-secondary svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--secondary) !important;
}
.svg-icon.svg-icon-secondary svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-secondary svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.light-theme .svg-icon.svg-icon-secondary svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-secondary svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.dark-theme .svg-icon.svg-icon-secondary svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-light svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--light) !important;
}
.svg-icon.svg-icon-light svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-light svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.light-theme .svg-icon.svg-icon-light svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-light svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.dark-theme .svg-icon.svg-icon-light svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-success svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--success) !important;
}
.svg-icon.svg-icon-success svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-success svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.light-theme .svg-icon.svg-icon-success svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-success svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.dark-theme .svg-icon.svg-icon-success svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-info svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--info) !important;
}
.svg-icon.svg-icon-info svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-info svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.light-theme .svg-icon.svg-icon-info svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-info svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.dark-theme .svg-icon.svg-icon-info svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-warning svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--warning) !important;
}
.svg-icon.svg-icon-warning svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-warning svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.light-theme .svg-icon.svg-icon-warning svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-warning svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.dark-theme .svg-icon.svg-icon-warning svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-danger svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--danger) !important;
}
.svg-icon.svg-icon-danger svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-danger svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.light-theme .svg-icon.svg-icon-danger svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-danger svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.dark-theme .svg-icon.svg-icon-danger svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-dark svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--dark) !important;
}
.svg-icon.svg-icon-dark svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-dark svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.light-theme .svg-icon.svg-icon-dark svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-dark svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.dark-theme .svg-icon.svg-icon-dark svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-black svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--black) !important;
}
.svg-icon.svg-icon-black svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-black svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #000000;
}
.light-theme .svg-icon.svg-icon-black svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-black svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.dark-theme .svg-icon.svg-icon-black svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-muted svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--muted) !important;
}
.svg-icon.svg-icon-muted svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-muted svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.light-theme .svg-icon.svg-icon-muted svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-muted svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.dark-theme .svg-icon.svg-icon-muted svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-100 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--gray-100) !important;
}
.svg-icon.svg-icon-gray-100 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-gray-100 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #f5f8fa;
}
.light-theme .svg-icon.svg-icon-gray-100 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-gray-100 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181c32;
}
.dark-theme .svg-icon.svg-icon-gray-100 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-200 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--gray-200) !important;
}
.svg-icon.svg-icon-gray-200 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-gray-200 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.light-theme .svg-icon.svg-icon-gray-200 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-gray-200 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.dark-theme .svg-icon.svg-icon-gray-200 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-300 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--gray-300) !important;
}
.svg-icon.svg-icon-gray-300 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-gray-300 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.light-theme .svg-icon.svg-icon-gray-300 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-gray-300 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5e6278;
}
.dark-theme .svg-icon.svg-icon-gray-300 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-400 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--gray-400) !important;
}
.svg-icon.svg-icon-gray-400 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-gray-400 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.light-theme .svg-icon.svg-icon-gray-400 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-gray-400 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.dark-theme .svg-icon.svg-icon-gray-400 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-500 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--gray-500) !important;
}
.svg-icon.svg-icon-gray-500 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-gray-500 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.light-theme .svg-icon.svg-icon-gray-500 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-gray-500 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.dark-theme .svg-icon.svg-icon-gray-500 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-600 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--gray-600) !important;
}
.svg-icon.svg-icon-gray-600 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-gray-600 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.light-theme .svg-icon.svg-icon-gray-600 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-gray-600 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.dark-theme .svg-icon.svg-icon-gray-600 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-700 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--gray-700) !important;
}
.svg-icon.svg-icon-gray-700 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-gray-700 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5e6278;
}
.light-theme .svg-icon.svg-icon-gray-700 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-gray-700 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.dark-theme .svg-icon.svg-icon-gray-700 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-800 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--gray-800) !important;
}
.svg-icon.svg-icon-gray-800 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-gray-800 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.light-theme .svg-icon.svg-icon-gray-800 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-gray-800 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.dark-theme .svg-icon.svg-icon-gray-800 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray-900 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--gray-900) !important;
}
.svg-icon.svg-icon-gray-900 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-gray-900 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181c32;
}
.light-theme .svg-icon.svg-icon-gray-900 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-gray-900 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #f5f8fa;
}
.dark-theme .svg-icon.svg-icon-gray-900 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-blue svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--blue) !important;
}
.svg-icon.svg-icon-blue svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-blue svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3699FF;
}
.light-theme .svg-icon.svg-icon-blue svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-blue svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3699FF;
}
.dark-theme .svg-icon.svg-icon-blue svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-indigo svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--indigo) !important;
}
.svg-icon.svg-icon-indigo svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-indigo svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #6610f2;
}
.light-theme .svg-icon.svg-icon-indigo svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-indigo svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #6610f2;
}
.dark-theme .svg-icon.svg-icon-indigo svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-purple svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--purple) !important;
}
.svg-icon.svg-icon-purple svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-purple svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.light-theme .svg-icon.svg-icon-purple svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-purple svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.dark-theme .svg-icon.svg-icon-purple svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-pink svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--pink) !important;
}
.svg-icon.svg-icon-pink svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-pink svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #d63384;
}
.light-theme .svg-icon.svg-icon-pink svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-pink svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #d63384;
}
.dark-theme .svg-icon.svg-icon-pink svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-red svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--red) !important;
}
.svg-icon.svg-icon-red svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-red svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.light-theme .svg-icon.svg-icon-red svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-red svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.dark-theme .svg-icon.svg-icon-red svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-orange svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--orange) !important;
}
.svg-icon.svg-icon-orange svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-orange svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #fd7e14;
}
.light-theme .svg-icon.svg-icon-orange svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-orange svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #fd7e14;
}
.dark-theme .svg-icon.svg-icon-orange svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-yellow svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--yellow) !important;
}
.svg-icon.svg-icon-yellow svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-yellow svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.light-theme .svg-icon.svg-icon-yellow svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-yellow svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.dark-theme .svg-icon.svg-icon-yellow svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-green svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--green) !important;
}
.svg-icon.svg-icon-green svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-green svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.light-theme .svg-icon.svg-icon-green svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-green svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-green svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-teal svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--teal) !important;
}
.svg-icon.svg-icon-teal svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-teal svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #20c997;
}
.light-theme .svg-icon.svg-icon-teal svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-teal svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #20c997;
}
.dark-theme .svg-icon.svg-icon-teal svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-cyan svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--cyan) !important;
}
.svg-icon.svg-icon-cyan svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-cyan svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #0dcaf0;
}
.light-theme .svg-icon.svg-icon-cyan svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-cyan svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #0dcaf0;
}
.dark-theme .svg-icon.svg-icon-cyan svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-gray svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: var(--gray) !important;
}
.svg-icon.svg-icon-gray svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.light-theme .svg-icon.svg-icon-gray svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.light-theme .svg-icon.svg-icon-gray svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.dark-theme .svg-icon.svg-icon-gray svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.dark-theme .svg-icon.svg-icon-gray svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-icon.svg-icon-1 svg {
  height: 1.75rem !important;
  width: 1.75rem !important;
}
.svg-icon.svg-icon-2 svg {
  height: 1.5rem !important;
  width: 1.5rem !important;
}
.svg-icon.svg-icon-3 svg {
  height: 1.35rem !important;
  width: 1.35rem !important;
}
.svg-icon.svg-icon-4 svg {
  height: 1.25rem !important;
  width: 1.25rem !important;
}
.svg-icon.svg-icon-5 svg {
  height: 1.15rem !important;
  width: 1.15rem !important;
}
.svg-icon.svg-icon-6 svg {
  height: 1.075rem !important;
  width: 1.075rem !important;
}
.svg-icon.svg-icon-7 svg {
  height: 0.95rem !important;
  width: 0.95rem !important;
}
.svg-icon.svg-icon-8 svg {
  height: 0.85rem !important;
  width: 0.85rem !important;
}
.svg-icon.svg-icon-9 svg {
  height: 0.75rem !important;
  width: 0.75rem !important;
}
.svg-icon.svg-icon-10 svg {
  height: 0.5rem !important;
  width: 0.5rem !important;
}
.svg-icon.svg-icon-base svg {
  height: 1rem !important;
  width: 1rem !important;
}
.svg-icon.svg-icon-fluid svg {
  height: 100% !important;
  width: 100% !important;
}
.svg-icon.svg-icon-2x svg {
  height: 2rem !important;
  width: 2rem !important;
}
.svg-icon.svg-icon-2qx svg {
  height: 2.25rem !important;
  width: 2.25rem !important;
}
.svg-icon.svg-icon-2hx svg {
  height: 2.5rem !important;
  width: 2.5rem !important;
}
.svg-icon.svg-icon-2tx svg {
  height: 2.75rem !important;
  width: 2.75rem !important;
}
.svg-icon.svg-icon-3x svg {
  height: 3rem !important;
  width: 3rem !important;
}
.svg-icon.svg-icon-3qx svg {
  height: 3.25rem !important;
  width: 3.25rem !important;
}
.svg-icon.svg-icon-3hx svg {
  height: 3.5rem !important;
  width: 3.5rem !important;
}
.svg-icon.svg-icon-3tx svg {
  height: 3.75rem !important;
  width: 3.75rem !important;
}
.svg-icon.svg-icon-4x svg {
  height: 4rem !important;
  width: 4rem !important;
}
.svg-icon.svg-icon-4qx svg {
  height: 4.25rem !important;
  width: 4.25rem !important;
}
.svg-icon.svg-icon-4hx svg {
  height: 4.5rem !important;
  width: 4.5rem !important;
}
.svg-icon.svg-icon-4tx svg {
  height: 4.75rem !important;
  width: 4.75rem !important;
}
.svg-icon.svg-icon-5x svg {
  height: 5rem !important;
  width: 5rem !important;
}
.svg-icon.svg-icon-5qx svg {
  height: 5.25rem !important;
  width: 5.25rem !important;
}
.svg-icon.svg-icon-5hx svg {
  height: 5.5rem !important;
  width: 5.5rem !important;
}
.svg-icon.svg-icon-5tx svg {
  height: 5.75rem !important;
  width: 5.75rem !important;
}
.svg-icon.svg-icon-10x svg {
  height: 10rem !important;
  width: 10rem !important;
}
.svg-icon.svg-icon-10qx svg {
  height: 10.25rem !important;
  width: 10.25rem !important;
}
.svg-icon.svg-icon-10hx svg {
  height: 10.5rem !important;
  width: 10.5rem !important;
}
.svg-icon.svg-icon-10tx svg {
  height: 10.75rem !important;
  width: 10.75rem !important;
}
@media (min-width: 576px) {
  .svg-icon.svg-icon-sm-1 svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-icon.svg-icon-sm-2 svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-icon.svg-icon-sm-3 svg {
    height: 1.35rem !important;
    width: 1.35rem !important;
  }
  .svg-icon.svg-icon-sm-4 svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-icon.svg-icon-sm-5 svg {
    height: 1.15rem !important;
    width: 1.15rem !important;
  }
  .svg-icon.svg-icon-sm-6 svg {
    height: 1.075rem !important;
    width: 1.075rem !important;
  }
  .svg-icon.svg-icon-sm-7 svg {
    height: 0.95rem !important;
    width: 0.95rem !important;
  }
  .svg-icon.svg-icon-sm-8 svg {
    height: 0.85rem !important;
    width: 0.85rem !important;
  }
  .svg-icon.svg-icon-sm-9 svg {
    height: 0.75rem !important;
    width: 0.75rem !important;
  }
  .svg-icon.svg-icon-sm-10 svg {
    height: 0.5rem !important;
    width: 0.5rem !important;
  }
  .svg-icon.svg-icon-sm-base svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-icon.svg-icon-sm-fluid svg {
    height: 100% !important;
    width: 100% !important;
  }
  .svg-icon.svg-icon-sm-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-sm-2qx svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-icon.svg-icon-sm-2hx svg {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .svg-icon.svg-icon-sm-2tx svg {
    height: 2.75rem !important;
    width: 2.75rem !important;
  }
  .svg-icon.svg-icon-sm-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-icon.svg-icon-sm-3qx svg {
    height: 3.25rem !important;
    width: 3.25rem !important;
  }
  .svg-icon.svg-icon-sm-3hx svg {
    height: 3.5rem !important;
    width: 3.5rem !important;
  }
  .svg-icon.svg-icon-sm-3tx svg {
    height: 3.75rem !important;
    width: 3.75rem !important;
  }
  .svg-icon.svg-icon-sm-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-icon.svg-icon-sm-4qx svg {
    height: 4.25rem !important;
    width: 4.25rem !important;
  }
  .svg-icon.svg-icon-sm-4hx svg {
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  .svg-icon.svg-icon-sm-4tx svg {
    height: 4.75rem !important;
    width: 4.75rem !important;
  }
  .svg-icon.svg-icon-sm-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-icon.svg-icon-sm-5qx svg {
    height: 5.25rem !important;
    width: 5.25rem !important;
  }
  .svg-icon.svg-icon-sm-5hx svg {
    height: 5.5rem !important;
    width: 5.5rem !important;
  }
  .svg-icon.svg-icon-sm-5tx svg {
    height: 5.75rem !important;
    width: 5.75rem !important;
  }
  .svg-icon.svg-icon-sm-10x svg {
    height: 10rem !important;
    width: 10rem !important;
  }
  .svg-icon.svg-icon-sm-10qx svg {
    height: 10.25rem !important;
    width: 10.25rem !important;
  }
  .svg-icon.svg-icon-sm-10hx svg {
    height: 10.5rem !important;
    width: 10.5rem !important;
  }
  .svg-icon.svg-icon-sm-10tx svg {
    height: 10.75rem !important;
    width: 10.75rem !important;
  }
}
@media (min-width: 768px) {
  .svg-icon.svg-icon-md-1 svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-icon.svg-icon-md-2 svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-icon.svg-icon-md-3 svg {
    height: 1.35rem !important;
    width: 1.35rem !important;
  }
  .svg-icon.svg-icon-md-4 svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-icon.svg-icon-md-5 svg {
    height: 1.15rem !important;
    width: 1.15rem !important;
  }
  .svg-icon.svg-icon-md-6 svg {
    height: 1.075rem !important;
    width: 1.075rem !important;
  }
  .svg-icon.svg-icon-md-7 svg {
    height: 0.95rem !important;
    width: 0.95rem !important;
  }
  .svg-icon.svg-icon-md-8 svg {
    height: 0.85rem !important;
    width: 0.85rem !important;
  }
  .svg-icon.svg-icon-md-9 svg {
    height: 0.75rem !important;
    width: 0.75rem !important;
  }
  .svg-icon.svg-icon-md-10 svg {
    height: 0.5rem !important;
    width: 0.5rem !important;
  }
  .svg-icon.svg-icon-md-base svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-icon.svg-icon-md-fluid svg {
    height: 100% !important;
    width: 100% !important;
  }
  .svg-icon.svg-icon-md-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-md-2qx svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-icon.svg-icon-md-2hx svg {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .svg-icon.svg-icon-md-2tx svg {
    height: 2.75rem !important;
    width: 2.75rem !important;
  }
  .svg-icon.svg-icon-md-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-icon.svg-icon-md-3qx svg {
    height: 3.25rem !important;
    width: 3.25rem !important;
  }
  .svg-icon.svg-icon-md-3hx svg {
    height: 3.5rem !important;
    width: 3.5rem !important;
  }
  .svg-icon.svg-icon-md-3tx svg {
    height: 3.75rem !important;
    width: 3.75rem !important;
  }
  .svg-icon.svg-icon-md-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-icon.svg-icon-md-4qx svg {
    height: 4.25rem !important;
    width: 4.25rem !important;
  }
  .svg-icon.svg-icon-md-4hx svg {
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  .svg-icon.svg-icon-md-4tx svg {
    height: 4.75rem !important;
    width: 4.75rem !important;
  }
  .svg-icon.svg-icon-md-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-icon.svg-icon-md-5qx svg {
    height: 5.25rem !important;
    width: 5.25rem !important;
  }
  .svg-icon.svg-icon-md-5hx svg {
    height: 5.5rem !important;
    width: 5.5rem !important;
  }
  .svg-icon.svg-icon-md-5tx svg {
    height: 5.75rem !important;
    width: 5.75rem !important;
  }
  .svg-icon.svg-icon-md-10x svg {
    height: 10rem !important;
    width: 10rem !important;
  }
  .svg-icon.svg-icon-md-10qx svg {
    height: 10.25rem !important;
    width: 10.25rem !important;
  }
  .svg-icon.svg-icon-md-10hx svg {
    height: 10.5rem !important;
    width: 10.5rem !important;
  }
  .svg-icon.svg-icon-md-10tx svg {
    height: 10.75rem !important;
    width: 10.75rem !important;
  }
}
@media (min-width: 992px) {
  .svg-icon.svg-icon-lg-1 svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-icon.svg-icon-lg-2 svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-icon.svg-icon-lg-3 svg {
    height: 1.35rem !important;
    width: 1.35rem !important;
  }
  .svg-icon.svg-icon-lg-4 svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-icon.svg-icon-lg-5 svg {
    height: 1.15rem !important;
    width: 1.15rem !important;
  }
  .svg-icon.svg-icon-lg-6 svg {
    height: 1.075rem !important;
    width: 1.075rem !important;
  }
  .svg-icon.svg-icon-lg-7 svg {
    height: 0.95rem !important;
    width: 0.95rem !important;
  }
  .svg-icon.svg-icon-lg-8 svg {
    height: 0.85rem !important;
    width: 0.85rem !important;
  }
  .svg-icon.svg-icon-lg-9 svg {
    height: 0.75rem !important;
    width: 0.75rem !important;
  }
  .svg-icon.svg-icon-lg-10 svg {
    height: 0.5rem !important;
    width: 0.5rem !important;
  }
  .svg-icon.svg-icon-lg-base svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-icon.svg-icon-lg-fluid svg {
    height: 100% !important;
    width: 100% !important;
  }
  .svg-icon.svg-icon-lg-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-lg-2qx svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-icon.svg-icon-lg-2hx svg {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .svg-icon.svg-icon-lg-2tx svg {
    height: 2.75rem !important;
    width: 2.75rem !important;
  }
  .svg-icon.svg-icon-lg-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-icon.svg-icon-lg-3qx svg {
    height: 3.25rem !important;
    width: 3.25rem !important;
  }
  .svg-icon.svg-icon-lg-3hx svg {
    height: 3.5rem !important;
    width: 3.5rem !important;
  }
  .svg-icon.svg-icon-lg-3tx svg {
    height: 3.75rem !important;
    width: 3.75rem !important;
  }
  .svg-icon.svg-icon-lg-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-icon.svg-icon-lg-4qx svg {
    height: 4.25rem !important;
    width: 4.25rem !important;
  }
  .svg-icon.svg-icon-lg-4hx svg {
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  .svg-icon.svg-icon-lg-4tx svg {
    height: 4.75rem !important;
    width: 4.75rem !important;
  }
  .svg-icon.svg-icon-lg-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-icon.svg-icon-lg-5qx svg {
    height: 5.25rem !important;
    width: 5.25rem !important;
  }
  .svg-icon.svg-icon-lg-5hx svg {
    height: 5.5rem !important;
    width: 5.5rem !important;
  }
  .svg-icon.svg-icon-lg-5tx svg {
    height: 5.75rem !important;
    width: 5.75rem !important;
  }
  .svg-icon.svg-icon-lg-10x svg {
    height: 10rem !important;
    width: 10rem !important;
  }
  .svg-icon.svg-icon-lg-10qx svg {
    height: 10.25rem !important;
    width: 10.25rem !important;
  }
  .svg-icon.svg-icon-lg-10hx svg {
    height: 10.5rem !important;
    width: 10.5rem !important;
  }
  .svg-icon.svg-icon-lg-10tx svg {
    height: 10.75rem !important;
    width: 10.75rem !important;
  }
}
@media (min-width: 1200px) {
  .svg-icon.svg-icon-xl-1 svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-icon.svg-icon-xl-2 svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-icon.svg-icon-xl-3 svg {
    height: 1.35rem !important;
    width: 1.35rem !important;
  }
  .svg-icon.svg-icon-xl-4 svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-icon.svg-icon-xl-5 svg {
    height: 1.15rem !important;
    width: 1.15rem !important;
  }
  .svg-icon.svg-icon-xl-6 svg {
    height: 1.075rem !important;
    width: 1.075rem !important;
  }
  .svg-icon.svg-icon-xl-7 svg {
    height: 0.95rem !important;
    width: 0.95rem !important;
  }
  .svg-icon.svg-icon-xl-8 svg {
    height: 0.85rem !important;
    width: 0.85rem !important;
  }
  .svg-icon.svg-icon-xl-9 svg {
    height: 0.75rem !important;
    width: 0.75rem !important;
  }
  .svg-icon.svg-icon-xl-10 svg {
    height: 0.5rem !important;
    width: 0.5rem !important;
  }
  .svg-icon.svg-icon-xl-base svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-icon.svg-icon-xl-fluid svg {
    height: 100% !important;
    width: 100% !important;
  }
  .svg-icon.svg-icon-xl-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-xl-2qx svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-icon.svg-icon-xl-2hx svg {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .svg-icon.svg-icon-xl-2tx svg {
    height: 2.75rem !important;
    width: 2.75rem !important;
  }
  .svg-icon.svg-icon-xl-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-icon.svg-icon-xl-3qx svg {
    height: 3.25rem !important;
    width: 3.25rem !important;
  }
  .svg-icon.svg-icon-xl-3hx svg {
    height: 3.5rem !important;
    width: 3.5rem !important;
  }
  .svg-icon.svg-icon-xl-3tx svg {
    height: 3.75rem !important;
    width: 3.75rem !important;
  }
  .svg-icon.svg-icon-xl-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-icon.svg-icon-xl-4qx svg {
    height: 4.25rem !important;
    width: 4.25rem !important;
  }
  .svg-icon.svg-icon-xl-4hx svg {
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  .svg-icon.svg-icon-xl-4tx svg {
    height: 4.75rem !important;
    width: 4.75rem !important;
  }
  .svg-icon.svg-icon-xl-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-icon.svg-icon-xl-5qx svg {
    height: 5.25rem !important;
    width: 5.25rem !important;
  }
  .svg-icon.svg-icon-xl-5hx svg {
    height: 5.5rem !important;
    width: 5.5rem !important;
  }
  .svg-icon.svg-icon-xl-5tx svg {
    height: 5.75rem !important;
    width: 5.75rem !important;
  }
  .svg-icon.svg-icon-xl-10x svg {
    height: 10rem !important;
    width: 10rem !important;
  }
  .svg-icon.svg-icon-xl-10qx svg {
    height: 10.25rem !important;
    width: 10.25rem !important;
  }
  .svg-icon.svg-icon-xl-10hx svg {
    height: 10.5rem !important;
    width: 10.5rem !important;
  }
  .svg-icon.svg-icon-xl-10tx svg {
    height: 10.75rem !important;
    width: 10.75rem !important;
  }
}
@media (min-width: 1400px) {
  .svg-icon.svg-icon-xxl-1 svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-icon.svg-icon-xxl-2 svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-icon.svg-icon-xxl-3 svg {
    height: 1.35rem !important;
    width: 1.35rem !important;
  }
  .svg-icon.svg-icon-xxl-4 svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-icon.svg-icon-xxl-5 svg {
    height: 1.15rem !important;
    width: 1.15rem !important;
  }
  .svg-icon.svg-icon-xxl-6 svg {
    height: 1.075rem !important;
    width: 1.075rem !important;
  }
  .svg-icon.svg-icon-xxl-7 svg {
    height: 0.95rem !important;
    width: 0.95rem !important;
  }
  .svg-icon.svg-icon-xxl-8 svg {
    height: 0.85rem !important;
    width: 0.85rem !important;
  }
  .svg-icon.svg-icon-xxl-9 svg {
    height: 0.75rem !important;
    width: 0.75rem !important;
  }
  .svg-icon.svg-icon-xxl-10 svg {
    height: 0.5rem !important;
    width: 0.5rem !important;
  }
  .svg-icon.svg-icon-xxl-base svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-icon.svg-icon-xxl-fluid svg {
    height: 100% !important;
    width: 100% !important;
  }
  .svg-icon.svg-icon-xxl-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-icon.svg-icon-xxl-2qx svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-icon.svg-icon-xxl-2hx svg {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .svg-icon.svg-icon-xxl-2tx svg {
    height: 2.75rem !important;
    width: 2.75rem !important;
  }
  .svg-icon.svg-icon-xxl-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-icon.svg-icon-xxl-3qx svg {
    height: 3.25rem !important;
    width: 3.25rem !important;
  }
  .svg-icon.svg-icon-xxl-3hx svg {
    height: 3.5rem !important;
    width: 3.5rem !important;
  }
  .svg-icon.svg-icon-xxl-3tx svg {
    height: 3.75rem !important;
    width: 3.75rem !important;
  }
  .svg-icon.svg-icon-xxl-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-icon.svg-icon-xxl-4qx svg {
    height: 4.25rem !important;
    width: 4.25rem !important;
  }
  .svg-icon.svg-icon-xxl-4hx svg {
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  .svg-icon.svg-icon-xxl-4tx svg {
    height: 4.75rem !important;
    width: 4.75rem !important;
  }
  .svg-icon.svg-icon-xxl-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-icon.svg-icon-xxl-5qx svg {
    height: 5.25rem !important;
    width: 5.25rem !important;
  }
  .svg-icon.svg-icon-xxl-5hx svg {
    height: 5.5rem !important;
    width: 5.5rem !important;
  }
  .svg-icon.svg-icon-xxl-5tx svg {
    height: 5.75rem !important;
    width: 5.75rem !important;
  }
  .svg-icon.svg-icon-xxl-10x svg {
    height: 10rem !important;
    width: 10rem !important;
  }
  .svg-icon.svg-icon-xxl-10qx svg {
    height: 10.25rem !important;
    width: 10.25rem !important;
  }
  .svg-icon.svg-icon-xxl-10hx svg {
    height: 10.5rem !important;
    width: 10.5rem !important;
  }
  .svg-icon.svg-icon-xxl-10tx svg {
    height: 10.75rem !important;
    width: 10.75rem !important;
  }
}

.svg-icon-100 svg {
  height: 100% !important;
  width: 100% !important;
}

.svg-icon-transparent svg {
  height: 20rem !important;
  width: 20rem !important;
}
.svg-icon-transparent svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: transparent !important;
}

.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.475rem;
}
.symbol .symbol-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: var(--gray-800);
  background-color: var(--gray-200);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.475rem;
}
.light-theme .symbol .symbol-label {
  color: #3f4254;
  background-color: #eff2f5;
}
.dark-theme .symbol .symbol-label {
  color: #eff2f5;
  background-color: #3f4254;
}
.symbol .badge {
  position: absolute;
  border: 2px solid var(--gray-200);
  top: 0;
  left: 100%;
  transform: translateX(-50%) translateY(-50%);
}
.light-theme .symbol .badge {
  border: 2px solid #eff2f5;
}
.dark-theme .symbol .badge {
  border: 2px solid #3f4254;
}
.symbol > img {
  width: 100%;
  flex-shrink: 0;
  display: inline-block;
  border-radius: 0.475rem;
}
.symbol.symbol-square,
.symbol.symbol-square > img,
.symbol.symbol-square .symbol-label {
  border-radius: 0 !important;
}
.symbol.symbol-circle,
.symbol.symbol-circle > img,
.symbol.symbol-circle .symbol-label {
  border-radius: 50%;
}
.symbol > img {
  width: 50px;
  height: 50px;
}
.symbol .symbol-label {
  width: 50px;
  height: 50px;
}
.symbol.symbol-fixed .symbol-label {
  width: 50px;
  height: 50px;
}
.symbol.symbol-fixed > img {
  width: 50px;
  height: 50px;
  max-width: auto;
}
.symbol.symbol-2by3 .symbol-label {
  height: 50px;
  width: 75px;
}
.symbol.symbol-2by3 > img {
  height: 50px;
  width: 75px;
  max-width: auto;
}
.symbol.symbol-20px > img {
  width: 20px;
  height: 20px;
}
.symbol.symbol-20px .symbol-label {
  width: 20px;
  height: 20px;
}
.symbol.symbol-20px.symbol-fixed .symbol-label {
  width: 20px;
  height: 20px;
}
.symbol.symbol-20px.symbol-fixed > img {
  width: 20px;
  height: 20px;
  max-width: auto;
}
.symbol.symbol-20px.symbol-2by3 .symbol-label {
  height: 20px;
  width: 30px;
}
.symbol.symbol-20px.symbol-2by3 > img {
  height: 20px;
  width: 30px;
  max-width: auto;
}
.symbol.symbol-25px > img {
  width: 25px;
  height: 25px;
}
.symbol.symbol-25px .symbol-label {
  width: 25px;
  height: 25px;
}
.symbol.symbol-25px.symbol-fixed .symbol-label {
  width: 25px;
  height: 25px;
}
.symbol.symbol-25px.symbol-fixed > img {
  width: 25px;
  height: 25px;
  max-width: auto;
}
.symbol.symbol-25px.symbol-2by3 .symbol-label {
  height: 25px;
  width: 37.5px;
}
.symbol.symbol-25px.symbol-2by3 > img {
  height: 25px;
  width: 37.5px;
  max-width: auto;
}
.symbol.symbol-30px > img {
  width: 30px;
  height: 30px;
}
.symbol.symbol-30px .symbol-label {
  width: 30px;
  height: 30px;
}
.symbol.symbol-30px.symbol-fixed .symbol-label {
  width: 30px;
  height: 30px;
}
.symbol.symbol-30px.symbol-fixed > img {
  width: 30px;
  height: 30px;
  max-width: auto;
}
.symbol.symbol-30px.symbol-2by3 .symbol-label {
  height: 30px;
  width: 45px;
}
.symbol.symbol-30px.symbol-2by3 > img {
  height: 30px;
  width: 45px;
  max-width: auto;
}
.symbol.symbol-35px > img {
  width: 35px;
  height: 35px;
}
.symbol.symbol-35px .symbol-label {
  width: 35px;
  height: 35px;
}
.symbol.symbol-35px.symbol-fixed .symbol-label {
  width: 35px;
  height: 35px;
}
.symbol.symbol-35px.symbol-fixed > img {
  width: 35px;
  height: 35px;
  max-width: auto;
}
.symbol.symbol-35px.symbol-2by3 .symbol-label {
  height: 35px;
  width: 52.5px;
}
.symbol.symbol-35px.symbol-2by3 > img {
  height: 35px;
  width: 52.5px;
  max-width: auto;
}
.symbol.symbol-40px > img {
  width: 40px;
  height: 40px;
}
.symbol.symbol-40px .symbol-label {
  width: 40px;
  height: 40px;
}
.symbol.symbol-40px.symbol-fixed .symbol-label {
  width: 40px;
  height: 40px;
}
.symbol.symbol-40px.symbol-fixed > img {
  width: 40px;
  height: 40px;
  max-width: auto;
}
.symbol.symbol-40px.symbol-2by3 .symbol-label {
  height: 40px;
  width: 60px;
}
.symbol.symbol-40px.symbol-2by3 > img {
  height: 40px;
  width: 60px;
  max-width: auto;
}
.symbol.symbol-45px > img {
  width: 45px;
  height: 45px;
}
.symbol.symbol-45px .symbol-label {
  width: 45px;
  height: 45px;
}
.symbol.symbol-45px.symbol-fixed .symbol-label {
  width: 45px;
  height: 45px;
}
.symbol.symbol-45px.symbol-fixed > img {
  width: 45px;
  height: 45px;
  max-width: auto;
}
.symbol.symbol-45px.symbol-2by3 .symbol-label {
  height: 45px;
  width: 67.5px;
}
.symbol.symbol-45px.symbol-2by3 > img {
  height: 45px;
  width: 67.5px;
  max-width: auto;
}
.symbol.symbol-50px > img {
  width: 50px;
  height: 50px;
}
.symbol.symbol-50px .symbol-label {
  width: 50px;
  height: 50px;
}
.symbol.symbol-50px.symbol-fixed .symbol-label {
  width: 50px;
  height: 50px;
}
.symbol.symbol-50px.symbol-fixed > img {
  width: 50px;
  height: 50px;
  max-width: auto;
}
.symbol.symbol-50px.symbol-2by3 .symbol-label {
  height: 50px;
  width: 75px;
}
.symbol.symbol-50px.symbol-2by3 > img {
  height: 50px;
  width: 75px;
  max-width: auto;
}
.symbol.symbol-55px > img {
  width: 55px;
  height: 55px;
}
.symbol.symbol-55px .symbol-label {
  width: 55px;
  height: 55px;
}
.symbol.symbol-55px.symbol-fixed .symbol-label {
  width: 55px;
  height: 55px;
}
.symbol.symbol-55px.symbol-fixed > img {
  width: 55px;
  height: 55px;
  max-width: auto;
}
.symbol.symbol-55px.symbol-2by3 .symbol-label {
  height: 55px;
  width: 82.5px;
}
.symbol.symbol-55px.symbol-2by3 > img {
  height: 55px;
  width: 82.5px;
  max-width: auto;
}
.symbol.symbol-60px > img {
  width: 60px;
  height: 60px;
}
.symbol.symbol-60px .symbol-label {
  width: 60px;
  height: 60px;
}
.symbol.symbol-60px.symbol-fixed .symbol-label {
  width: 60px;
  height: 60px;
}
.symbol.symbol-60px.symbol-fixed > img {
  width: 60px;
  height: 60px;
  max-width: auto;
}
.symbol.symbol-60px.symbol-2by3 .symbol-label {
  height: 60px;
  width: 90px;
}
.symbol.symbol-60px.symbol-2by3 > img {
  height: 60px;
  width: 90px;
  max-width: auto;
}
.symbol.symbol-65px > img {
  width: 65px;
  height: 65px;
}
.symbol.symbol-65px .symbol-label {
  width: 65px;
  height: 65px;
}
.symbol.symbol-65px.symbol-fixed .symbol-label {
  width: 65px;
  height: 65px;
}
.symbol.symbol-65px.symbol-fixed > img {
  width: 65px;
  height: 65px;
  max-width: auto;
}
.symbol.symbol-65px.symbol-2by3 .symbol-label {
  height: 65px;
  width: 97.5px;
}
.symbol.symbol-65px.symbol-2by3 > img {
  height: 65px;
  width: 97.5px;
  max-width: auto;
}
.symbol.symbol-70px > img {
  width: 70px;
  height: 70px;
}
.symbol.symbol-70px .symbol-label {
  width: 70px;
  height: 70px;
}
.symbol.symbol-70px.symbol-fixed .symbol-label {
  width: 70px;
  height: 70px;
}
.symbol.symbol-70px.symbol-fixed > img {
  width: 70px;
  height: 70px;
  max-width: auto;
}
.symbol.symbol-70px.symbol-2by3 .symbol-label {
  height: 70px;
  width: 105px;
}
.symbol.symbol-70px.symbol-2by3 > img {
  height: 70px;
  width: 105px;
  max-width: auto;
}
.symbol.symbol-75px > img {
  width: 75px;
  height: 75px;
}
.symbol.symbol-75px .symbol-label {
  width: 75px;
  height: 75px;
}
.symbol.symbol-75px.symbol-fixed .symbol-label {
  width: 75px;
  height: 75px;
}
.symbol.symbol-75px.symbol-fixed > img {
  width: 75px;
  height: 75px;
  max-width: auto;
}
.symbol.symbol-75px.symbol-2by3 .symbol-label {
  height: 75px;
  width: 112.5px;
}
.symbol.symbol-75px.symbol-2by3 > img {
  height: 75px;
  width: 112.5px;
  max-width: auto;
}
.symbol.symbol-100px > img {
  width: 100px;
  height: 100px;
}
.symbol.symbol-100px .symbol-label {
  width: 100px;
  height: 100px;
}
.symbol.symbol-100px.symbol-fixed .symbol-label {
  width: 100px;
  height: 100px;
}
.symbol.symbol-100px.symbol-fixed > img {
  width: 100px;
  height: 100px;
  max-width: auto;
}
.symbol.symbol-100px.symbol-2by3 .symbol-label {
  height: 100px;
  width: 150px;
}
.symbol.symbol-100px.symbol-2by3 > img {
  height: 100px;
  width: 150px;
  max-width: auto;
}
.symbol.symbol-125px > img {
  width: 125px;
  height: 125px;
}
.symbol.symbol-125px .symbol-label {
  width: 125px;
  height: 125px;
}
.symbol.symbol-125px.symbol-fixed .symbol-label {
  width: 125px;
  height: 125px;
}
.symbol.symbol-125px.symbol-fixed > img {
  width: 125px;
  height: 125px;
  max-width: auto;
}
.symbol.symbol-125px.symbol-2by3 .symbol-label {
  height: 125px;
  width: 187.5px;
}
.symbol.symbol-125px.symbol-2by3 > img {
  height: 125px;
  width: 187.5px;
  max-width: auto;
}
.symbol.symbol-150px > img {
  width: 150px;
  height: 150px;
}
.symbol.symbol-150px .symbol-label {
  width: 150px;
  height: 150px;
}
.symbol.symbol-150px.symbol-fixed .symbol-label {
  width: 150px;
  height: 150px;
}
.symbol.symbol-150px.symbol-fixed > img {
  width: 150px;
  height: 150px;
  max-width: auto;
}
.symbol.symbol-150px.symbol-2by3 .symbol-label {
  height: 150px;
  width: 225px;
}
.symbol.symbol-150px.symbol-2by3 > img {
  height: 150px;
  width: 225px;
  max-width: auto;
}
.symbol.symbol-160px > img {
  width: 160px;
  height: 160px;
}
.symbol.symbol-160px .symbol-label {
  width: 160px;
  height: 160px;
}
.symbol.symbol-160px.symbol-fixed .symbol-label {
  width: 160px;
  height: 160px;
}
.symbol.symbol-160px.symbol-fixed > img {
  width: 160px;
  height: 160px;
  max-width: auto;
}
.symbol.symbol-160px.symbol-2by3 .symbol-label {
  height: 160px;
  width: 240px;
}
.symbol.symbol-160px.symbol-2by3 > img {
  height: 160px;
  width: 240px;
  max-width: auto;
}
.symbol.symbol-175px > img {
  width: 175px;
  height: 175px;
}
.symbol.symbol-175px .symbol-label {
  width: 175px;
  height: 175px;
}
.symbol.symbol-175px.symbol-fixed .symbol-label {
  width: 175px;
  height: 175px;
}
.symbol.symbol-175px.symbol-fixed > img {
  width: 175px;
  height: 175px;
  max-width: auto;
}
.symbol.symbol-175px.symbol-2by3 .symbol-label {
  height: 175px;
  width: 262.5px;
}
.symbol.symbol-175px.symbol-2by3 > img {
  height: 175px;
  width: 262.5px;
  max-width: auto;
}
.symbol.symbol-200px > img {
  width: 200px;
  height: 200px;
}
.symbol.symbol-200px .symbol-label {
  width: 200px;
  height: 200px;
}
.symbol.symbol-200px.symbol-fixed .symbol-label {
  width: 200px;
  height: 200px;
}
.symbol.symbol-200px.symbol-fixed > img {
  width: 200px;
  height: 200px;
  max-width: auto;
}
.symbol.symbol-200px.symbol-2by3 .symbol-label {
  height: 200px;
  width: 300px;
}
.symbol.symbol-200px.symbol-2by3 > img {
  height: 200px;
  width: 300px;
  max-width: auto;
}
@media (min-width: 576px) {
  .symbol.symbol-sm-20px > img {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-sm-20px .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-sm-20px.symbol-fixed .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-sm-20px.symbol-fixed > img {
    width: 20px;
    height: 20px;
    max-width: auto;
  }
  .symbol.symbol-sm-20px.symbol-2by3 .symbol-label {
    height: 20px;
    width: 30px;
  }
  .symbol.symbol-sm-20px.symbol-2by3 > img {
    height: 20px;
    width: 30px;
    max-width: auto;
  }
  .symbol.symbol-sm-25px > img {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-sm-25px .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-sm-25px.symbol-fixed .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-sm-25px.symbol-fixed > img {
    width: 25px;
    height: 25px;
    max-width: auto;
  }
  .symbol.symbol-sm-25px.symbol-2by3 .symbol-label {
    height: 25px;
    width: 37.5px;
  }
  .symbol.symbol-sm-25px.symbol-2by3 > img {
    height: 25px;
    width: 37.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-30px > img {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-sm-30px .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-sm-30px.symbol-fixed .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-sm-30px.symbol-fixed > img {
    width: 30px;
    height: 30px;
    max-width: auto;
  }
  .symbol.symbol-sm-30px.symbol-2by3 .symbol-label {
    height: 30px;
    width: 45px;
  }
  .symbol.symbol-sm-30px.symbol-2by3 > img {
    height: 30px;
    width: 45px;
    max-width: auto;
  }
  .symbol.symbol-sm-35px > img {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-sm-35px .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-sm-35px.symbol-fixed .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-sm-35px.symbol-fixed > img {
    width: 35px;
    height: 35px;
    max-width: auto;
  }
  .symbol.symbol-sm-35px.symbol-2by3 .symbol-label {
    height: 35px;
    width: 52.5px;
  }
  .symbol.symbol-sm-35px.symbol-2by3 > img {
    height: 35px;
    width: 52.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-40px > img {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-sm-40px .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-sm-40px.symbol-fixed .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-sm-40px.symbol-fixed > img {
    width: 40px;
    height: 40px;
    max-width: auto;
  }
  .symbol.symbol-sm-40px.symbol-2by3 .symbol-label {
    height: 40px;
    width: 60px;
  }
  .symbol.symbol-sm-40px.symbol-2by3 > img {
    height: 40px;
    width: 60px;
    max-width: auto;
  }
  .symbol.symbol-sm-45px > img {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-sm-45px .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-sm-45px.symbol-fixed .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-sm-45px.symbol-fixed > img {
    width: 45px;
    height: 45px;
    max-width: auto;
  }
  .symbol.symbol-sm-45px.symbol-2by3 .symbol-label {
    height: 45px;
    width: 67.5px;
  }
  .symbol.symbol-sm-45px.symbol-2by3 > img {
    height: 45px;
    width: 67.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-50px > img {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-sm-50px .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-sm-50px.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-sm-50px.symbol-fixed > img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-sm-50px.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-sm-50px.symbol-2by3 > img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-sm-55px > img {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-sm-55px .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-sm-55px.symbol-fixed .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-sm-55px.symbol-fixed > img {
    width: 55px;
    height: 55px;
    max-width: auto;
  }
  .symbol.symbol-sm-55px.symbol-2by3 .symbol-label {
    height: 55px;
    width: 82.5px;
  }
  .symbol.symbol-sm-55px.symbol-2by3 > img {
    height: 55px;
    width: 82.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-60px > img {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-sm-60px .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-sm-60px.symbol-fixed .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-sm-60px.symbol-fixed > img {
    width: 60px;
    height: 60px;
    max-width: auto;
  }
  .symbol.symbol-sm-60px.symbol-2by3 .symbol-label {
    height: 60px;
    width: 90px;
  }
  .symbol.symbol-sm-60px.symbol-2by3 > img {
    height: 60px;
    width: 90px;
    max-width: auto;
  }
  .symbol.symbol-sm-65px > img {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-sm-65px .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-sm-65px.symbol-fixed .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-sm-65px.symbol-fixed > img {
    width: 65px;
    height: 65px;
    max-width: auto;
  }
  .symbol.symbol-sm-65px.symbol-2by3 .symbol-label {
    height: 65px;
    width: 97.5px;
  }
  .symbol.symbol-sm-65px.symbol-2by3 > img {
    height: 65px;
    width: 97.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-70px > img {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-sm-70px .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-sm-70px.symbol-fixed .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-sm-70px.symbol-fixed > img {
    width: 70px;
    height: 70px;
    max-width: auto;
  }
  .symbol.symbol-sm-70px.symbol-2by3 .symbol-label {
    height: 70px;
    width: 105px;
  }
  .symbol.symbol-sm-70px.symbol-2by3 > img {
    height: 70px;
    width: 105px;
    max-width: auto;
  }
  .symbol.symbol-sm-75px > img {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-sm-75px .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-sm-75px.symbol-fixed .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-sm-75px.symbol-fixed > img {
    width: 75px;
    height: 75px;
    max-width: auto;
  }
  .symbol.symbol-sm-75px.symbol-2by3 .symbol-label {
    height: 75px;
    width: 112.5px;
  }
  .symbol.symbol-sm-75px.symbol-2by3 > img {
    height: 75px;
    width: 112.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-100px > img {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-sm-100px .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-sm-100px.symbol-fixed .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-sm-100px.symbol-fixed > img {
    width: 100px;
    height: 100px;
    max-width: auto;
  }
  .symbol.symbol-sm-100px.symbol-2by3 .symbol-label {
    height: 100px;
    width: 150px;
  }
  .symbol.symbol-sm-100px.symbol-2by3 > img {
    height: 100px;
    width: 150px;
    max-width: auto;
  }
  .symbol.symbol-sm-125px > img {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-sm-125px .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-sm-125px.symbol-fixed .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-sm-125px.symbol-fixed > img {
    width: 125px;
    height: 125px;
    max-width: auto;
  }
  .symbol.symbol-sm-125px.symbol-2by3 .symbol-label {
    height: 125px;
    width: 187.5px;
  }
  .symbol.symbol-sm-125px.symbol-2by3 > img {
    height: 125px;
    width: 187.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-150px > img {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-sm-150px .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-sm-150px.symbol-fixed .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-sm-150px.symbol-fixed > img {
    width: 150px;
    height: 150px;
    max-width: auto;
  }
  .symbol.symbol-sm-150px.symbol-2by3 .symbol-label {
    height: 150px;
    width: 225px;
  }
  .symbol.symbol-sm-150px.symbol-2by3 > img {
    height: 150px;
    width: 225px;
    max-width: auto;
  }
  .symbol.symbol-sm-160px > img {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-sm-160px .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-sm-160px.symbol-fixed .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-sm-160px.symbol-fixed > img {
    width: 160px;
    height: 160px;
    max-width: auto;
  }
  .symbol.symbol-sm-160px.symbol-2by3 .symbol-label {
    height: 160px;
    width: 240px;
  }
  .symbol.symbol-sm-160px.symbol-2by3 > img {
    height: 160px;
    width: 240px;
    max-width: auto;
  }
  .symbol.symbol-sm-175px > img {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-sm-175px .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-sm-175px.symbol-fixed .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-sm-175px.symbol-fixed > img {
    width: 175px;
    height: 175px;
    max-width: auto;
  }
  .symbol.symbol-sm-175px.symbol-2by3 .symbol-label {
    height: 175px;
    width: 262.5px;
  }
  .symbol.symbol-sm-175px.symbol-2by3 > img {
    height: 175px;
    width: 262.5px;
    max-width: auto;
  }
  .symbol.symbol-sm-200px > img {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-sm-200px .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-sm-200px.symbol-fixed .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-sm-200px.symbol-fixed > img {
    width: 200px;
    height: 200px;
    max-width: auto;
  }
  .symbol.symbol-sm-200px.symbol-2by3 .symbol-label {
    height: 200px;
    width: 300px;
  }
  .symbol.symbol-sm-200px.symbol-2by3 > img {
    height: 200px;
    width: 300px;
    max-width: auto;
  }
}
@media (min-width: 768px) {
  .symbol.symbol-md-20px > img {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-md-20px .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-md-20px.symbol-fixed .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-md-20px.symbol-fixed > img {
    width: 20px;
    height: 20px;
    max-width: auto;
  }
  .symbol.symbol-md-20px.symbol-2by3 .symbol-label {
    height: 20px;
    width: 30px;
  }
  .symbol.symbol-md-20px.symbol-2by3 > img {
    height: 20px;
    width: 30px;
    max-width: auto;
  }
  .symbol.symbol-md-25px > img {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-md-25px .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-md-25px.symbol-fixed .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-md-25px.symbol-fixed > img {
    width: 25px;
    height: 25px;
    max-width: auto;
  }
  .symbol.symbol-md-25px.symbol-2by3 .symbol-label {
    height: 25px;
    width: 37.5px;
  }
  .symbol.symbol-md-25px.symbol-2by3 > img {
    height: 25px;
    width: 37.5px;
    max-width: auto;
  }
  .symbol.symbol-md-30px > img {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-md-30px .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-md-30px.symbol-fixed .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-md-30px.symbol-fixed > img {
    width: 30px;
    height: 30px;
    max-width: auto;
  }
  .symbol.symbol-md-30px.symbol-2by3 .symbol-label {
    height: 30px;
    width: 45px;
  }
  .symbol.symbol-md-30px.symbol-2by3 > img {
    height: 30px;
    width: 45px;
    max-width: auto;
  }
  .symbol.symbol-md-35px > img {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-md-35px .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-md-35px.symbol-fixed .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-md-35px.symbol-fixed > img {
    width: 35px;
    height: 35px;
    max-width: auto;
  }
  .symbol.symbol-md-35px.symbol-2by3 .symbol-label {
    height: 35px;
    width: 52.5px;
  }
  .symbol.symbol-md-35px.symbol-2by3 > img {
    height: 35px;
    width: 52.5px;
    max-width: auto;
  }
  .symbol.symbol-md-40px > img {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-md-40px .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-md-40px.symbol-fixed .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-md-40px.symbol-fixed > img {
    width: 40px;
    height: 40px;
    max-width: auto;
  }
  .symbol.symbol-md-40px.symbol-2by3 .symbol-label {
    height: 40px;
    width: 60px;
  }
  .symbol.symbol-md-40px.symbol-2by3 > img {
    height: 40px;
    width: 60px;
    max-width: auto;
  }
  .symbol.symbol-md-45px > img {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-md-45px .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-md-45px.symbol-fixed .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-md-45px.symbol-fixed > img {
    width: 45px;
    height: 45px;
    max-width: auto;
  }
  .symbol.symbol-md-45px.symbol-2by3 .symbol-label {
    height: 45px;
    width: 67.5px;
  }
  .symbol.symbol-md-45px.symbol-2by3 > img {
    height: 45px;
    width: 67.5px;
    max-width: auto;
  }
  .symbol.symbol-md-50px > img {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-md-50px .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-md-50px.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-md-50px.symbol-fixed > img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-md-50px.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-md-50px.symbol-2by3 > img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-md-55px > img {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-md-55px .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-md-55px.symbol-fixed .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-md-55px.symbol-fixed > img {
    width: 55px;
    height: 55px;
    max-width: auto;
  }
  .symbol.symbol-md-55px.symbol-2by3 .symbol-label {
    height: 55px;
    width: 82.5px;
  }
  .symbol.symbol-md-55px.symbol-2by3 > img {
    height: 55px;
    width: 82.5px;
    max-width: auto;
  }
  .symbol.symbol-md-60px > img {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-md-60px .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-md-60px.symbol-fixed .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-md-60px.symbol-fixed > img {
    width: 60px;
    height: 60px;
    max-width: auto;
  }
  .symbol.symbol-md-60px.symbol-2by3 .symbol-label {
    height: 60px;
    width: 90px;
  }
  .symbol.symbol-md-60px.symbol-2by3 > img {
    height: 60px;
    width: 90px;
    max-width: auto;
  }
  .symbol.symbol-md-65px > img {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-md-65px .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-md-65px.symbol-fixed .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-md-65px.symbol-fixed > img {
    width: 65px;
    height: 65px;
    max-width: auto;
  }
  .symbol.symbol-md-65px.symbol-2by3 .symbol-label {
    height: 65px;
    width: 97.5px;
  }
  .symbol.symbol-md-65px.symbol-2by3 > img {
    height: 65px;
    width: 97.5px;
    max-width: auto;
  }
  .symbol.symbol-md-70px > img {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-md-70px .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-md-70px.symbol-fixed .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-md-70px.symbol-fixed > img {
    width: 70px;
    height: 70px;
    max-width: auto;
  }
  .symbol.symbol-md-70px.symbol-2by3 .symbol-label {
    height: 70px;
    width: 105px;
  }
  .symbol.symbol-md-70px.symbol-2by3 > img {
    height: 70px;
    width: 105px;
    max-width: auto;
  }
  .symbol.symbol-md-75px > img {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-md-75px .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-md-75px.symbol-fixed .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-md-75px.symbol-fixed > img {
    width: 75px;
    height: 75px;
    max-width: auto;
  }
  .symbol.symbol-md-75px.symbol-2by3 .symbol-label {
    height: 75px;
    width: 112.5px;
  }
  .symbol.symbol-md-75px.symbol-2by3 > img {
    height: 75px;
    width: 112.5px;
    max-width: auto;
  }
  .symbol.symbol-md-100px > img {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-md-100px .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-md-100px.symbol-fixed .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-md-100px.symbol-fixed > img {
    width: 100px;
    height: 100px;
    max-width: auto;
  }
  .symbol.symbol-md-100px.symbol-2by3 .symbol-label {
    height: 100px;
    width: 150px;
  }
  .symbol.symbol-md-100px.symbol-2by3 > img {
    height: 100px;
    width: 150px;
    max-width: auto;
  }
  .symbol.symbol-md-125px > img {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-md-125px .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-md-125px.symbol-fixed .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-md-125px.symbol-fixed > img {
    width: 125px;
    height: 125px;
    max-width: auto;
  }
  .symbol.symbol-md-125px.symbol-2by3 .symbol-label {
    height: 125px;
    width: 187.5px;
  }
  .symbol.symbol-md-125px.symbol-2by3 > img {
    height: 125px;
    width: 187.5px;
    max-width: auto;
  }
  .symbol.symbol-md-150px > img {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-md-150px .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-md-150px.symbol-fixed .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-md-150px.symbol-fixed > img {
    width: 150px;
    height: 150px;
    max-width: auto;
  }
  .symbol.symbol-md-150px.symbol-2by3 .symbol-label {
    height: 150px;
    width: 225px;
  }
  .symbol.symbol-md-150px.symbol-2by3 > img {
    height: 150px;
    width: 225px;
    max-width: auto;
  }
  .symbol.symbol-md-160px > img {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-md-160px .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-md-160px.symbol-fixed .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-md-160px.symbol-fixed > img {
    width: 160px;
    height: 160px;
    max-width: auto;
  }
  .symbol.symbol-md-160px.symbol-2by3 .symbol-label {
    height: 160px;
    width: 240px;
  }
  .symbol.symbol-md-160px.symbol-2by3 > img {
    height: 160px;
    width: 240px;
    max-width: auto;
  }
  .symbol.symbol-md-175px > img {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-md-175px .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-md-175px.symbol-fixed .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-md-175px.symbol-fixed > img {
    width: 175px;
    height: 175px;
    max-width: auto;
  }
  .symbol.symbol-md-175px.symbol-2by3 .symbol-label {
    height: 175px;
    width: 262.5px;
  }
  .symbol.symbol-md-175px.symbol-2by3 > img {
    height: 175px;
    width: 262.5px;
    max-width: auto;
  }
  .symbol.symbol-md-200px > img {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-md-200px .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-md-200px.symbol-fixed .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-md-200px.symbol-fixed > img {
    width: 200px;
    height: 200px;
    max-width: auto;
  }
  .symbol.symbol-md-200px.symbol-2by3 .symbol-label {
    height: 200px;
    width: 300px;
  }
  .symbol.symbol-md-200px.symbol-2by3 > img {
    height: 200px;
    width: 300px;
    max-width: auto;
  }
}
@media (min-width: 992px) {
  .symbol.symbol-lg-20px > img {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-lg-20px .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-lg-20px.symbol-fixed .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-lg-20px.symbol-fixed > img {
    width: 20px;
    height: 20px;
    max-width: auto;
  }
  .symbol.symbol-lg-20px.symbol-2by3 .symbol-label {
    height: 20px;
    width: 30px;
  }
  .symbol.symbol-lg-20px.symbol-2by3 > img {
    height: 20px;
    width: 30px;
    max-width: auto;
  }
  .symbol.symbol-lg-25px > img {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-lg-25px .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-lg-25px.symbol-fixed .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-lg-25px.symbol-fixed > img {
    width: 25px;
    height: 25px;
    max-width: auto;
  }
  .symbol.symbol-lg-25px.symbol-2by3 .symbol-label {
    height: 25px;
    width: 37.5px;
  }
  .symbol.symbol-lg-25px.symbol-2by3 > img {
    height: 25px;
    width: 37.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-30px > img {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-lg-30px .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-lg-30px.symbol-fixed .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-lg-30px.symbol-fixed > img {
    width: 30px;
    height: 30px;
    max-width: auto;
  }
  .symbol.symbol-lg-30px.symbol-2by3 .symbol-label {
    height: 30px;
    width: 45px;
  }
  .symbol.symbol-lg-30px.symbol-2by3 > img {
    height: 30px;
    width: 45px;
    max-width: auto;
  }
  .symbol.symbol-lg-35px > img {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-lg-35px .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-lg-35px.symbol-fixed .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-lg-35px.symbol-fixed > img {
    width: 35px;
    height: 35px;
    max-width: auto;
  }
  .symbol.symbol-lg-35px.symbol-2by3 .symbol-label {
    height: 35px;
    width: 52.5px;
  }
  .symbol.symbol-lg-35px.symbol-2by3 > img {
    height: 35px;
    width: 52.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-40px > img {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-lg-40px .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-lg-40px.symbol-fixed .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-lg-40px.symbol-fixed > img {
    width: 40px;
    height: 40px;
    max-width: auto;
  }
  .symbol.symbol-lg-40px.symbol-2by3 .symbol-label {
    height: 40px;
    width: 60px;
  }
  .symbol.symbol-lg-40px.symbol-2by3 > img {
    height: 40px;
    width: 60px;
    max-width: auto;
  }
  .symbol.symbol-lg-45px > img {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-lg-45px .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-lg-45px.symbol-fixed .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-lg-45px.symbol-fixed > img {
    width: 45px;
    height: 45px;
    max-width: auto;
  }
  .symbol.symbol-lg-45px.symbol-2by3 .symbol-label {
    height: 45px;
    width: 67.5px;
  }
  .symbol.symbol-lg-45px.symbol-2by3 > img {
    height: 45px;
    width: 67.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-50px > img {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-lg-50px .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-lg-50px.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-lg-50px.symbol-fixed > img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-lg-50px.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-lg-50px.symbol-2by3 > img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-lg-55px > img {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-lg-55px .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-lg-55px.symbol-fixed .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-lg-55px.symbol-fixed > img {
    width: 55px;
    height: 55px;
    max-width: auto;
  }
  .symbol.symbol-lg-55px.symbol-2by3 .symbol-label {
    height: 55px;
    width: 82.5px;
  }
  .symbol.symbol-lg-55px.symbol-2by3 > img {
    height: 55px;
    width: 82.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-60px > img {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-lg-60px .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-lg-60px.symbol-fixed .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-lg-60px.symbol-fixed > img {
    width: 60px;
    height: 60px;
    max-width: auto;
  }
  .symbol.symbol-lg-60px.symbol-2by3 .symbol-label {
    height: 60px;
    width: 90px;
  }
  .symbol.symbol-lg-60px.symbol-2by3 > img {
    height: 60px;
    width: 90px;
    max-width: auto;
  }
  .symbol.symbol-lg-65px > img {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-lg-65px .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-lg-65px.symbol-fixed .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-lg-65px.symbol-fixed > img {
    width: 65px;
    height: 65px;
    max-width: auto;
  }
  .symbol.symbol-lg-65px.symbol-2by3 .symbol-label {
    height: 65px;
    width: 97.5px;
  }
  .symbol.symbol-lg-65px.symbol-2by3 > img {
    height: 65px;
    width: 97.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-70px > img {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-lg-70px .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-lg-70px.symbol-fixed .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-lg-70px.symbol-fixed > img {
    width: 70px;
    height: 70px;
    max-width: auto;
  }
  .symbol.symbol-lg-70px.symbol-2by3 .symbol-label {
    height: 70px;
    width: 105px;
  }
  .symbol.symbol-lg-70px.symbol-2by3 > img {
    height: 70px;
    width: 105px;
    max-width: auto;
  }
  .symbol.symbol-lg-75px > img {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-lg-75px .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-lg-75px.symbol-fixed .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-lg-75px.symbol-fixed > img {
    width: 75px;
    height: 75px;
    max-width: auto;
  }
  .symbol.symbol-lg-75px.symbol-2by3 .symbol-label {
    height: 75px;
    width: 112.5px;
  }
  .symbol.symbol-lg-75px.symbol-2by3 > img {
    height: 75px;
    width: 112.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-100px > img {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-lg-100px .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-lg-100px.symbol-fixed .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-lg-100px.symbol-fixed > img {
    width: 100px;
    height: 100px;
    max-width: auto;
  }
  .symbol.symbol-lg-100px.symbol-2by3 .symbol-label {
    height: 100px;
    width: 150px;
  }
  .symbol.symbol-lg-100px.symbol-2by3 > img {
    height: 100px;
    width: 150px;
    max-width: auto;
  }
  .symbol.symbol-lg-125px > img {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-lg-125px .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-lg-125px.symbol-fixed .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-lg-125px.symbol-fixed > img {
    width: 125px;
    height: 125px;
    max-width: auto;
  }
  .symbol.symbol-lg-125px.symbol-2by3 .symbol-label {
    height: 125px;
    width: 187.5px;
  }
  .symbol.symbol-lg-125px.symbol-2by3 > img {
    height: 125px;
    width: 187.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-150px > img {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-lg-150px .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-lg-150px.symbol-fixed .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-lg-150px.symbol-fixed > img {
    width: 150px;
    height: 150px;
    max-width: auto;
  }
  .symbol.symbol-lg-150px.symbol-2by3 .symbol-label {
    height: 150px;
    width: 225px;
  }
  .symbol.symbol-lg-150px.symbol-2by3 > img {
    height: 150px;
    width: 225px;
    max-width: auto;
  }
  .symbol.symbol-lg-160px > img {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-lg-160px .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-lg-160px.symbol-fixed .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-lg-160px.symbol-fixed > img {
    width: 160px;
    height: 160px;
    max-width: auto;
  }
  .symbol.symbol-lg-160px.symbol-2by3 .symbol-label {
    height: 160px;
    width: 240px;
  }
  .symbol.symbol-lg-160px.symbol-2by3 > img {
    height: 160px;
    width: 240px;
    max-width: auto;
  }
  .symbol.symbol-lg-175px > img {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-lg-175px .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-lg-175px.symbol-fixed .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-lg-175px.symbol-fixed > img {
    width: 175px;
    height: 175px;
    max-width: auto;
  }
  .symbol.symbol-lg-175px.symbol-2by3 .symbol-label {
    height: 175px;
    width: 262.5px;
  }
  .symbol.symbol-lg-175px.symbol-2by3 > img {
    height: 175px;
    width: 262.5px;
    max-width: auto;
  }
  .symbol.symbol-lg-200px > img {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-lg-200px .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-lg-200px.symbol-fixed .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-lg-200px.symbol-fixed > img {
    width: 200px;
    height: 200px;
    max-width: auto;
  }
  .symbol.symbol-lg-200px.symbol-2by3 .symbol-label {
    height: 200px;
    width: 300px;
  }
  .symbol.symbol-lg-200px.symbol-2by3 > img {
    height: 200px;
    width: 300px;
    max-width: auto;
  }
}
@media (min-width: 1200px) {
  .symbol.symbol-xl-20px > img {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-xl-20px .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-xl-20px.symbol-fixed .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-xl-20px.symbol-fixed > img {
    width: 20px;
    height: 20px;
    max-width: auto;
  }
  .symbol.symbol-xl-20px.symbol-2by3 .symbol-label {
    height: 20px;
    width: 30px;
  }
  .symbol.symbol-xl-20px.symbol-2by3 > img {
    height: 20px;
    width: 30px;
    max-width: auto;
  }
  .symbol.symbol-xl-25px > img {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-xl-25px .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-xl-25px.symbol-fixed .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-xl-25px.symbol-fixed > img {
    width: 25px;
    height: 25px;
    max-width: auto;
  }
  .symbol.symbol-xl-25px.symbol-2by3 .symbol-label {
    height: 25px;
    width: 37.5px;
  }
  .symbol.symbol-xl-25px.symbol-2by3 > img {
    height: 25px;
    width: 37.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-30px > img {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-xl-30px .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-xl-30px.symbol-fixed .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-xl-30px.symbol-fixed > img {
    width: 30px;
    height: 30px;
    max-width: auto;
  }
  .symbol.symbol-xl-30px.symbol-2by3 .symbol-label {
    height: 30px;
    width: 45px;
  }
  .symbol.symbol-xl-30px.symbol-2by3 > img {
    height: 30px;
    width: 45px;
    max-width: auto;
  }
  .symbol.symbol-xl-35px > img {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-xl-35px .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-xl-35px.symbol-fixed .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-xl-35px.symbol-fixed > img {
    width: 35px;
    height: 35px;
    max-width: auto;
  }
  .symbol.symbol-xl-35px.symbol-2by3 .symbol-label {
    height: 35px;
    width: 52.5px;
  }
  .symbol.symbol-xl-35px.symbol-2by3 > img {
    height: 35px;
    width: 52.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-40px > img {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-xl-40px .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-xl-40px.symbol-fixed .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-xl-40px.symbol-fixed > img {
    width: 40px;
    height: 40px;
    max-width: auto;
  }
  .symbol.symbol-xl-40px.symbol-2by3 .symbol-label {
    height: 40px;
    width: 60px;
  }
  .symbol.symbol-xl-40px.symbol-2by3 > img {
    height: 40px;
    width: 60px;
    max-width: auto;
  }
  .symbol.symbol-xl-45px > img {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-xl-45px .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-xl-45px.symbol-fixed .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-xl-45px.symbol-fixed > img {
    width: 45px;
    height: 45px;
    max-width: auto;
  }
  .symbol.symbol-xl-45px.symbol-2by3 .symbol-label {
    height: 45px;
    width: 67.5px;
  }
  .symbol.symbol-xl-45px.symbol-2by3 > img {
    height: 45px;
    width: 67.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-50px > img {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-xl-50px .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-xl-50px.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-xl-50px.symbol-fixed > img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-xl-50px.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-xl-50px.symbol-2by3 > img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-xl-55px > img {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-xl-55px .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-xl-55px.symbol-fixed .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-xl-55px.symbol-fixed > img {
    width: 55px;
    height: 55px;
    max-width: auto;
  }
  .symbol.symbol-xl-55px.symbol-2by3 .symbol-label {
    height: 55px;
    width: 82.5px;
  }
  .symbol.symbol-xl-55px.symbol-2by3 > img {
    height: 55px;
    width: 82.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-60px > img {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-xl-60px .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-xl-60px.symbol-fixed .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-xl-60px.symbol-fixed > img {
    width: 60px;
    height: 60px;
    max-width: auto;
  }
  .symbol.symbol-xl-60px.symbol-2by3 .symbol-label {
    height: 60px;
    width: 90px;
  }
  .symbol.symbol-xl-60px.symbol-2by3 > img {
    height: 60px;
    width: 90px;
    max-width: auto;
  }
  .symbol.symbol-xl-65px > img {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-xl-65px .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-xl-65px.symbol-fixed .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-xl-65px.symbol-fixed > img {
    width: 65px;
    height: 65px;
    max-width: auto;
  }
  .symbol.symbol-xl-65px.symbol-2by3 .symbol-label {
    height: 65px;
    width: 97.5px;
  }
  .symbol.symbol-xl-65px.symbol-2by3 > img {
    height: 65px;
    width: 97.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-70px > img {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-xl-70px .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-xl-70px.symbol-fixed .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-xl-70px.symbol-fixed > img {
    width: 70px;
    height: 70px;
    max-width: auto;
  }
  .symbol.symbol-xl-70px.symbol-2by3 .symbol-label {
    height: 70px;
    width: 105px;
  }
  .symbol.symbol-xl-70px.symbol-2by3 > img {
    height: 70px;
    width: 105px;
    max-width: auto;
  }
  .symbol.symbol-xl-75px > img {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-xl-75px .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-xl-75px.symbol-fixed .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-xl-75px.symbol-fixed > img {
    width: 75px;
    height: 75px;
    max-width: auto;
  }
  .symbol.symbol-xl-75px.symbol-2by3 .symbol-label {
    height: 75px;
    width: 112.5px;
  }
  .symbol.symbol-xl-75px.symbol-2by3 > img {
    height: 75px;
    width: 112.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-100px > img {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-xl-100px .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-xl-100px.symbol-fixed .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-xl-100px.symbol-fixed > img {
    width: 100px;
    height: 100px;
    max-width: auto;
  }
  .symbol.symbol-xl-100px.symbol-2by3 .symbol-label {
    height: 100px;
    width: 150px;
  }
  .symbol.symbol-xl-100px.symbol-2by3 > img {
    height: 100px;
    width: 150px;
    max-width: auto;
  }
  .symbol.symbol-xl-125px > img {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-xl-125px .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-xl-125px.symbol-fixed .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-xl-125px.symbol-fixed > img {
    width: 125px;
    height: 125px;
    max-width: auto;
  }
  .symbol.symbol-xl-125px.symbol-2by3 .symbol-label {
    height: 125px;
    width: 187.5px;
  }
  .symbol.symbol-xl-125px.symbol-2by3 > img {
    height: 125px;
    width: 187.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-150px > img {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-xl-150px .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-xl-150px.symbol-fixed .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-xl-150px.symbol-fixed > img {
    width: 150px;
    height: 150px;
    max-width: auto;
  }
  .symbol.symbol-xl-150px.symbol-2by3 .symbol-label {
    height: 150px;
    width: 225px;
  }
  .symbol.symbol-xl-150px.symbol-2by3 > img {
    height: 150px;
    width: 225px;
    max-width: auto;
  }
  .symbol.symbol-xl-160px > img {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-xl-160px .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-xl-160px.symbol-fixed .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-xl-160px.symbol-fixed > img {
    width: 160px;
    height: 160px;
    max-width: auto;
  }
  .symbol.symbol-xl-160px.symbol-2by3 .symbol-label {
    height: 160px;
    width: 240px;
  }
  .symbol.symbol-xl-160px.symbol-2by3 > img {
    height: 160px;
    width: 240px;
    max-width: auto;
  }
  .symbol.symbol-xl-175px > img {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-xl-175px .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-xl-175px.symbol-fixed .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-xl-175px.symbol-fixed > img {
    width: 175px;
    height: 175px;
    max-width: auto;
  }
  .symbol.symbol-xl-175px.symbol-2by3 .symbol-label {
    height: 175px;
    width: 262.5px;
  }
  .symbol.symbol-xl-175px.symbol-2by3 > img {
    height: 175px;
    width: 262.5px;
    max-width: auto;
  }
  .symbol.symbol-xl-200px > img {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-xl-200px .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-xl-200px.symbol-fixed .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-xl-200px.symbol-fixed > img {
    width: 200px;
    height: 200px;
    max-width: auto;
  }
  .symbol.symbol-xl-200px.symbol-2by3 .symbol-label {
    height: 200px;
    width: 300px;
  }
  .symbol.symbol-xl-200px.symbol-2by3 > img {
    height: 200px;
    width: 300px;
    max-width: auto;
  }
}
@media (min-width: 1400px) {
  .symbol.symbol-xxl-20px > img {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-xxl-20px .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-xxl-20px.symbol-fixed .symbol-label {
    width: 20px;
    height: 20px;
  }
  .symbol.symbol-xxl-20px.symbol-fixed > img {
    width: 20px;
    height: 20px;
    max-width: auto;
  }
  .symbol.symbol-xxl-20px.symbol-2by3 .symbol-label {
    height: 20px;
    width: 30px;
  }
  .symbol.symbol-xxl-20px.symbol-2by3 > img {
    height: 20px;
    width: 30px;
    max-width: auto;
  }
  .symbol.symbol-xxl-25px > img {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-xxl-25px .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-xxl-25px.symbol-fixed .symbol-label {
    width: 25px;
    height: 25px;
  }
  .symbol.symbol-xxl-25px.symbol-fixed > img {
    width: 25px;
    height: 25px;
    max-width: auto;
  }
  .symbol.symbol-xxl-25px.symbol-2by3 .symbol-label {
    height: 25px;
    width: 37.5px;
  }
  .symbol.symbol-xxl-25px.symbol-2by3 > img {
    height: 25px;
    width: 37.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-30px > img {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-xxl-30px .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-xxl-30px.symbol-fixed .symbol-label {
    width: 30px;
    height: 30px;
  }
  .symbol.symbol-xxl-30px.symbol-fixed > img {
    width: 30px;
    height: 30px;
    max-width: auto;
  }
  .symbol.symbol-xxl-30px.symbol-2by3 .symbol-label {
    height: 30px;
    width: 45px;
  }
  .symbol.symbol-xxl-30px.symbol-2by3 > img {
    height: 30px;
    width: 45px;
    max-width: auto;
  }
  .symbol.symbol-xxl-35px > img {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-xxl-35px .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-xxl-35px.symbol-fixed .symbol-label {
    width: 35px;
    height: 35px;
  }
  .symbol.symbol-xxl-35px.symbol-fixed > img {
    width: 35px;
    height: 35px;
    max-width: auto;
  }
  .symbol.symbol-xxl-35px.symbol-2by3 .symbol-label {
    height: 35px;
    width: 52.5px;
  }
  .symbol.symbol-xxl-35px.symbol-2by3 > img {
    height: 35px;
    width: 52.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-40px > img {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-xxl-40px .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-xxl-40px.symbol-fixed .symbol-label {
    width: 40px;
    height: 40px;
  }
  .symbol.symbol-xxl-40px.symbol-fixed > img {
    width: 40px;
    height: 40px;
    max-width: auto;
  }
  .symbol.symbol-xxl-40px.symbol-2by3 .symbol-label {
    height: 40px;
    width: 60px;
  }
  .symbol.symbol-xxl-40px.symbol-2by3 > img {
    height: 40px;
    width: 60px;
    max-width: auto;
  }
  .symbol.symbol-xxl-45px > img {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-xxl-45px .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-xxl-45px.symbol-fixed .symbol-label {
    width: 45px;
    height: 45px;
  }
  .symbol.symbol-xxl-45px.symbol-fixed > img {
    width: 45px;
    height: 45px;
    max-width: auto;
  }
  .symbol.symbol-xxl-45px.symbol-2by3 .symbol-label {
    height: 45px;
    width: 67.5px;
  }
  .symbol.symbol-xxl-45px.symbol-2by3 > img {
    height: 45px;
    width: 67.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-50px > img {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-xxl-50px .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-xxl-50px.symbol-fixed .symbol-label {
    width: 50px;
    height: 50px;
  }
  .symbol.symbol-xxl-50px.symbol-fixed > img {
    width: 50px;
    height: 50px;
    max-width: auto;
  }
  .symbol.symbol-xxl-50px.symbol-2by3 .symbol-label {
    height: 50px;
    width: 75px;
  }
  .symbol.symbol-xxl-50px.symbol-2by3 > img {
    height: 50px;
    width: 75px;
    max-width: auto;
  }
  .symbol.symbol-xxl-55px > img {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-xxl-55px .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-xxl-55px.symbol-fixed .symbol-label {
    width: 55px;
    height: 55px;
  }
  .symbol.symbol-xxl-55px.symbol-fixed > img {
    width: 55px;
    height: 55px;
    max-width: auto;
  }
  .symbol.symbol-xxl-55px.symbol-2by3 .symbol-label {
    height: 55px;
    width: 82.5px;
  }
  .symbol.symbol-xxl-55px.symbol-2by3 > img {
    height: 55px;
    width: 82.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-60px > img {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-xxl-60px .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-xxl-60px.symbol-fixed .symbol-label {
    width: 60px;
    height: 60px;
  }
  .symbol.symbol-xxl-60px.symbol-fixed > img {
    width: 60px;
    height: 60px;
    max-width: auto;
  }
  .symbol.symbol-xxl-60px.symbol-2by3 .symbol-label {
    height: 60px;
    width: 90px;
  }
  .symbol.symbol-xxl-60px.symbol-2by3 > img {
    height: 60px;
    width: 90px;
    max-width: auto;
  }
  .symbol.symbol-xxl-65px > img {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-xxl-65px .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-xxl-65px.symbol-fixed .symbol-label {
    width: 65px;
    height: 65px;
  }
  .symbol.symbol-xxl-65px.symbol-fixed > img {
    width: 65px;
    height: 65px;
    max-width: auto;
  }
  .symbol.symbol-xxl-65px.symbol-2by3 .symbol-label {
    height: 65px;
    width: 97.5px;
  }
  .symbol.symbol-xxl-65px.symbol-2by3 > img {
    height: 65px;
    width: 97.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-70px > img {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-xxl-70px .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-xxl-70px.symbol-fixed .symbol-label {
    width: 70px;
    height: 70px;
  }
  .symbol.symbol-xxl-70px.symbol-fixed > img {
    width: 70px;
    height: 70px;
    max-width: auto;
  }
  .symbol.symbol-xxl-70px.symbol-2by3 .symbol-label {
    height: 70px;
    width: 105px;
  }
  .symbol.symbol-xxl-70px.symbol-2by3 > img {
    height: 70px;
    width: 105px;
    max-width: auto;
  }
  .symbol.symbol-xxl-75px > img {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-xxl-75px .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-xxl-75px.symbol-fixed .symbol-label {
    width: 75px;
    height: 75px;
  }
  .symbol.symbol-xxl-75px.symbol-fixed > img {
    width: 75px;
    height: 75px;
    max-width: auto;
  }
  .symbol.symbol-xxl-75px.symbol-2by3 .symbol-label {
    height: 75px;
    width: 112.5px;
  }
  .symbol.symbol-xxl-75px.symbol-2by3 > img {
    height: 75px;
    width: 112.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-100px > img {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-xxl-100px .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-xxl-100px.symbol-fixed .symbol-label {
    width: 100px;
    height: 100px;
  }
  .symbol.symbol-xxl-100px.symbol-fixed > img {
    width: 100px;
    height: 100px;
    max-width: auto;
  }
  .symbol.symbol-xxl-100px.symbol-2by3 .symbol-label {
    height: 100px;
    width: 150px;
  }
  .symbol.symbol-xxl-100px.symbol-2by3 > img {
    height: 100px;
    width: 150px;
    max-width: auto;
  }
  .symbol.symbol-xxl-125px > img {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-xxl-125px .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-xxl-125px.symbol-fixed .symbol-label {
    width: 125px;
    height: 125px;
  }
  .symbol.symbol-xxl-125px.symbol-fixed > img {
    width: 125px;
    height: 125px;
    max-width: auto;
  }
  .symbol.symbol-xxl-125px.symbol-2by3 .symbol-label {
    height: 125px;
    width: 187.5px;
  }
  .symbol.symbol-xxl-125px.symbol-2by3 > img {
    height: 125px;
    width: 187.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-150px > img {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-xxl-150px .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-xxl-150px.symbol-fixed .symbol-label {
    width: 150px;
    height: 150px;
  }
  .symbol.symbol-xxl-150px.symbol-fixed > img {
    width: 150px;
    height: 150px;
    max-width: auto;
  }
  .symbol.symbol-xxl-150px.symbol-2by3 .symbol-label {
    height: 150px;
    width: 225px;
  }
  .symbol.symbol-xxl-150px.symbol-2by3 > img {
    height: 150px;
    width: 225px;
    max-width: auto;
  }
  .symbol.symbol-xxl-160px > img {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-xxl-160px .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-xxl-160px.symbol-fixed .symbol-label {
    width: 160px;
    height: 160px;
  }
  .symbol.symbol-xxl-160px.symbol-fixed > img {
    width: 160px;
    height: 160px;
    max-width: auto;
  }
  .symbol.symbol-xxl-160px.symbol-2by3 .symbol-label {
    height: 160px;
    width: 240px;
  }
  .symbol.symbol-xxl-160px.symbol-2by3 > img {
    height: 160px;
    width: 240px;
    max-width: auto;
  }
  .symbol.symbol-xxl-175px > img {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-xxl-175px .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-xxl-175px.symbol-fixed .symbol-label {
    width: 175px;
    height: 175px;
  }
  .symbol.symbol-xxl-175px.symbol-fixed > img {
    width: 175px;
    height: 175px;
    max-width: auto;
  }
  .symbol.symbol-xxl-175px.symbol-2by3 .symbol-label {
    height: 175px;
    width: 262.5px;
  }
  .symbol.symbol-xxl-175px.symbol-2by3 > img {
    height: 175px;
    width: 262.5px;
    max-width: auto;
  }
  .symbol.symbol-xxl-200px > img {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-xxl-200px .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-xxl-200px.symbol-fixed .symbol-label {
    width: 200px;
    height: 200px;
  }
  .symbol.symbol-xxl-200px.symbol-fixed > img {
    width: 200px;
    height: 200px;
    max-width: auto;
  }
  .symbol.symbol-xxl-200px.symbol-2by3 .symbol-label {
    height: 200px;
    width: 300px;
  }
  .symbol.symbol-xxl-200px.symbol-2by3 > img {
    height: 200px;
    width: 300px;
    max-width: auto;
  }
}

.symbol-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 10px;
}
.symbol-group .symbol {
  position: relative;
  z-index: 0;
  margin-left: -20px;
  border: 2px solid var(--gray-200);
  transition: all 0.3s ease;
}
.light-theme .symbol-group .symbol {
  border: 2px solid #eff2f5;
}
.dark-theme .symbol-group .symbol {
  border: 2px solid #3f4254;
}
.symbol-group .symbol:hover {
  transition: all 0.3s ease;
  z-index: 1;
}
.symbol-group.symbol-hover .symbol {
  cursor: pointer;
}

.svg-symbol {
  line-height: 1;
}
.svg-symbol svg {
  width: 100%;
  height: 100%;
}
.light-theme .svg-symbol svg {
  fill: #000000;
}
.dark-theme .svg-symbol svg {
  fill: #ffffff;
}
.svg-symbol.svg-symbol-white svg {
  fill: var(--white);
}
.svg-symbol.svg-symbol-primary svg {
  fill: var(--primary);
}
.svg-symbol.svg-symbol-secondary svg {
  fill: var(--secondary);
}
.svg-symbol.svg-symbol-light svg {
  fill: var(--light);
}
.svg-symbol.svg-symbol-success svg {
  fill: var(--success);
}
.svg-symbol.svg-symbol-info svg {
  fill: var(--info);
}
.svg-symbol.svg-symbol-warning svg {
  fill: var(--warning);
}
.svg-symbol.svg-symbol-danger svg {
  fill: var(--danger);
}
.svg-symbol.svg-symbol-dark svg {
  fill: var(--dark);
}
.svg-symbol.svg-symbol-black svg {
  fill: var(--black);
}
.svg-symbol.svg-symbol-muted svg {
  fill: var(--muted);
}
.svg-symbol.svg-symbol-gray-100 svg {
  fill: var(--gray-100);
}
.svg-symbol.svg-symbol-gray-200 svg {
  fill: var(--gray-200);
}
.svg-symbol.svg-symbol-gray-300 svg {
  fill: var(--gray-300);
}
.svg-symbol.svg-symbol-gray-400 svg {
  fill: var(--gray-400);
}
.svg-symbol.svg-symbol-gray-500 svg {
  fill: var(--gray-500);
}
.svg-symbol.svg-symbol-gray-600 svg {
  fill: var(--gray-600);
}
.svg-symbol.svg-symbol-gray-700 svg {
  fill: var(--gray-700);
}
.svg-symbol.svg-symbol-gray-800 svg {
  fill: var(--gray-800);
}
.svg-symbol.svg-symbol-gray-900 svg {
  fill: var(--gray-900);
}
.svg-symbol.svg-symbol-blue svg {
  fill: var(--blue);
}
.svg-symbol.svg-symbol-indigo svg {
  fill: var(--indigo);
}
.svg-symbol.svg-symbol-purple svg {
  fill: var(--purple);
}
.svg-symbol.svg-symbol-pink svg {
  fill: var(--pink);
}
.svg-symbol.svg-symbol-red svg {
  fill: var(--red);
}
.svg-symbol.svg-symbol-orange svg {
  fill: var(--orange);
}
.svg-symbol.svg-symbol-yellow svg {
  fill: var(--yellow);
}
.svg-symbol.svg-symbol-green svg {
  fill: var(--green);
}
.svg-symbol.svg-symbol-teal svg {
  fill: var(--teal);
}
.svg-symbol.svg-symbol-cyan svg {
  fill: var(--cyan);
}
.svg-symbol.svg-symbol-gray svg {
  fill: var(--gray);
}

.bullet {
  display: inline-block;
  background-color: #b5b5c3;
  border-radius: 6px;
  width: 8px;
  height: 4px;
  flex-shrink: 0;
}

.bullet-dot {
  width: 4px;
  height: 4px;
  border-radius: 100% !important;
}

.bullet-vertical {
  width: 4px;
  height: 8px;
}

.bullet-line {
  width: 5px;
  height: 1px;
  border-radius: none;
}

.form-control {
  box-shadow: none !important;
}
.dropdown.show > .form-control {
  color: #151521;
  background-color: #ffffff;
  border-color: #b5b5c3;
}
.form-control[readonly] {
  background-color: #ffffff;
}
.form-control[disabled] {
  color: #7e8299 !important;
}
.form-control.form-control-solid {
  border: none !important;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .form-control.form-control-solid {
  color: #1b1b29;
  background-color: #e4e6ef;
}
.dark-theme .form-control.form-control-solid {
  color: #e4e6ef;
  background-color: #1b1b29;
}
.form-control.form-control-solid::placeholder {
  color: #a1a5b7;
}
.form-control.form-control-solid::-moz-placeholder {
  color: #a1a5b7;
  opacity: 1;
}
.dropdown.show > .form-control.form-control-solid, .form-control.form-control-solid:active, .form-control.form-control-solid.active, .form-control.form-control-solid:focus, .form-control.form-control-solid.focus {
  border-color: #e10e8d !important;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .dropdown.show > .form-control.form-control-solid, .light-theme .form-control.form-control-solid:active, .light-theme .form-control.form-control-solid.active, .light-theme .form-control.form-control-solid:focus, .light-theme .form-control.form-control-solid.focus {
  color: #171723;
  background-color: #eef3f7 !important;
}
.dark-theme .dropdown.show > .form-control.form-control-solid, .dark-theme .form-control.form-control-solid:active, .dark-theme .form-control.form-control-solid.active, .dark-theme .form-control.form-control-solid:focus, .dark-theme .form-control.form-control-solid.focus {
  color: #eef3f7;
  background-color: #171723 !important;
}
.form-control.form-control-white {
  background-color: #ffffff;
  border-color: #ffffff;
}
.dropdown.show > .form-control.form-control-white, .form-control.form-control-white:active, .form-control.form-control-white.active, .form-control.form-control-white:focus, .form-control.form-control-white.focus {
  background-color: #ffffff;
  border-color: #ffffff;
}
.form-control.form-control-flush {
  border: 0;
  background-color: transparent;
  outline: none !important;
  box-shadow: none;
  border-radius: 0;
}

.placeholder-gray-500::placeholder {
  color: #a1a5b7;
}
.placeholder-gray-500::-moz-placeholder {
  color: #a1a5b7;
  opacity: 1;
}

.placeholder-white::placeholder {
  color: #ffffff;
}
.placeholder-white::-moz-placeholder {
  color: #ffffff;
  opacity: 1;
}

.resize-none {
  resize: none;
}

.form-select {
  box-shadow: none !important;
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2211%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M8.99%2010.1a.817.817%200%2001-.566-.235l-8-8A.8.8%200%20011.555.733L8.99%208.168%2016.424.733a.8.8%200%20111.131%201.132l-8%208a.817.817%200%2001-.565.234z%22%20fill%3D%22%23383D48%22%2F%3E%3C%2Fsvg%3E) !important;
  background-position: right 1rem center !important;
  background-size: inherit !important;
  font-weight: 500;
  border: none;
}
.form-select.form-select-solid {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.form-select.form-select-solid::placeholder {
  color: #a1a5b7;
}
.form-select.form-select-solid::-moz-placeholder {
  color: #a1a5b7;
  opacity: 1;
}
.light-theme .form-select.form-select-solid {
  color: #1b1b29;
  background-color: #e4e6ef;
  border-color: #e4e6ef;
}
.dark-theme .form-select.form-select-solid {
  color: #e4e6ef;
  background-color: #1b1b29;
  border-color: #1b1b29;
}
.dropdown.show > .form-select.form-select-solid, .form-select.form-select-solid:active, .form-select.form-select-solid.active, .form-select.form-select-solid:focus, .form-select.form-select-solid.focus {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .dropdown.show > .form-select.form-select-solid, .light-theme .form-select.form-select-solid:active, .light-theme .form-select.form-select-solid.active, .light-theme .form-select.form-select-solid:focus, .light-theme .form-select.form-select-solid.focus {
  color: #171723;
  background-color: #eef3f7 !important;
  border-color: #eef3f7 !important;
}
.dark-theme .dropdown.show > .form-select.form-select-solid, .dark-theme .form-select.form-select-solid:active, .dark-theme .form-select.form-select-solid.active, .dark-theme .form-select.form-select-solid:focus, .dark-theme .form-select.form-select-solid.focus {
  color: #eef3f7;
  background-color: #171723 !important;
  border-color: #171723 !important;
}
.form-select.form-select-white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #151521;
}
.form-select.form-select-white::placeholder {
  color: #a1a5b7;
}
.form-select.form-select-white::-moz-placeholder {
  color: #a1a5b7;
  opacity: 1;
}
.dropdown.show > .form-select.form-select-white, .form-select.form-select-white:active, .form-select.form-select-white.active, .form-select.form-select-white:focus, .form-select.form-select-white.focus {
  background-color: #ffffff;
  border-color: #ffffff !important;
  color: #151521;
}

.form-check .form-check-label {
  cursor: pointer;
}
.form-check .form-check-input {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-check .form-check-input[type=checkbox]:indeterminate {
  background-color: #e10e8d !important;
}
.form-check.form-check-custom {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin: 0;
}
.form-check.form-check-custom .form-check-input {
  margin: 0;
  float: none;
  flex-shrink: 0;
}
.form-check.form-check-custom .form-check-label {
  margin-left: 0.55rem;
}
.form-check:not(.form-switch) .form-check-input[type=checkbox] {
  background-size: 60% 60%;
}
.form-check.form-check-solid .form-check-input {
  border: 0;
}
.light-theme .form-check.form-check-solid .form-check-input {
  background-color: #e4e6ef;
}
.dark-theme .form-check.form-check-solid .form-check-input {
  background-color: #1b1b29;
}
.form-check.form-check-solid .form-check-input:active, .form-check.form-check-solid .form-check-input:focus {
  filter: none;
}
.light-theme .form-check.form-check-solid .form-check-input:active, .light-theme .form-check.form-check-solid .form-check-input:focus {
  background-color: #e4e6ef;
}
.dark-theme .form-check.form-check-solid .form-check-input:active, .dark-theme .form-check.form-check-solid .form-check-input:focus {
  background-color: #1b1b29;
}
.form-check.form-check-solid .form-check-input:checked {
  background-color: #e10e8d !important;
}
.form-check.form-check-solid .form-check-input[type=radio], .form-check.form-check-solid .form-check-input[type=radio]:active, .form-check.form-check-solid .form-check-input[type=radio]:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check.form-check-success .form-check-input:checked {
  background-color: #1BC5BC;
}
.form-check.form-check-danger .form-check-input:checked {
  background-color: #F65464;
}
.form-check.form-check-warning .form-check-input:checked {
  background-color: #FFA800;
}

.form-check.form-check-custom.form-check-sm .form-check-input {
  height: 1.55rem;
  width: 1.55rem;
}
.form-check.form-check-custom.form-check-lg .form-check-input {
  height: 2.25rem;
  width: 2.25rem;
}

.form-switch.form-check-solid .form-check-input {
  height: 2.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-solid .form-check-input:active, .form-switch.form-check-solid .form-check-input:checked, .form-switch.form-check-solid .form-check-input:focus {
  filter: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-solid.form-switch-sm .form-check-input {
  height: 1.5rem;
  width: 2.5rem;
}
.form-switch.form-check-solid.form-switch-lg .form-check-input {
  height: 2.75rem;
  width: 3.75rem;
}

.input-group.input-group-solid {
  background-color: #f5f8fa;
  border-color: #f5f8fa;
  border-radius: 0.475rem;
}
.input-group.input-group-solid.input-group-sm {
  border-radius: 0.475rem;
}
.input-group.input-group-solid.input-group-lg {
  border-radius: 0.475rem;
}
.input-group.input-group-solid .input-group-text {
  background-color: transparent;
  border-color: transparent;
}
.input-group.input-group-solid .form-control {
  background-color: transparent;
  border-color: transparent;
}

.required:after {
  content: "*";
  position: relative;
  font-size: inherit;
  padding-left: 0.25rem;
  font-weight: bold;
}

.form-datepicker {
  box-shadow: none !important;
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M5.25%209.75a.75.75%200%20000%201.5h.008a.75.75%200%20000-1.5H5.25zM8.25%209.75a.75.75%200%20000%201.5h4.5a.75.75%200%20000-1.5h-4.5zM5.25%2012.75a.75.75%200%20000%201.5h.008a.75.75%200%20000-1.5H5.25zM8.25%2012.75a.75.75%200%20000%201.5h4.5a.75.75%200%20000-1.5h-4.5z%22%20fill%3D%22%23383D48%22%2F%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M6%20.75a.75.75%200%2000-1.5%200v.75H2.25A2.25%202.25%200%20000%203.75v12A2.25%202.25%200%20002.25%2018h13.5A2.25%202.25%200%200018%2015.75v-12a2.25%202.25%200%2000-2.25-2.25H13.5V.75a.75.75%200%2000-1.5%200v.75H6V.75zM2.25%203H4.5v.75a.75.75%200%20001.5%200V3h6v.75a.75.75%200%20001.5%200V3h2.25a.75.75%200%2001.75.75V6h-15V3.75A.75.75%200%20012.25%203zM1.5%207.5h15v8.25a.75.75%200%2001-.75.75H2.25a.75.75%200%2001-.75-.75V7.5z%22%20fill%3D%22%23383D48%22%2F%3E%3C%2Fsvg%3E) !important;
  background-position: right 1rem center !important;
  background-repeat: no-repeat !important;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  border-top-right-radius: 0.475rem;
  border-bottom-right-radius: 0.475rem;
}
.form-datepicker.form-datepicker-solid {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.form-datepicker.form-datepicker-solid::placeholder {
  color: #a1a5b7;
}
.form-datepicker.form-datepicker-solid::-moz-placeholder {
  color: #a1a5b7;
  opacity: 1;
}
.light-theme .form-datepicker.form-datepicker-solid {
  color: #1b1b29;
  background-color: #e4e6ef;
  border-color: #e4e6ef;
}
.dark-theme .form-datepicker.form-datepicker-solid {
  color: #e4e6ef;
  background-color: #1b1b29;
  border-color: #1b1b29;
}
.dropdown.show > .form-datepicker.form-datepicker-solid, .form-datepicker.form-datepicker-solid:active, .form-datepicker.form-datepicker-solid.active, .form-datepicker.form-datepicker-solid:focus, .form-datepicker.form-datepicker-solid.focus {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.light-theme .dropdown.show > .form-datepicker.form-datepicker-solid, .light-theme .form-datepicker.form-datepicker-solid:active, .light-theme .form-datepicker.form-datepicker-solid.active, .light-theme .form-datepicker.form-datepicker-solid:focus, .light-theme .form-datepicker.form-datepicker-solid.focus {
  color: #171723;
  background-color: #eef3f7 !important;
  border-color: #eef3f7 !important;
}
.dark-theme .dropdown.show > .form-datepicker.form-datepicker-solid, .dark-theme .form-datepicker.form-datepicker-solid:active, .dark-theme .form-datepicker.form-datepicker-solid.active, .dark-theme .form-datepicker.form-datepicker-solid:focus, .dark-theme .form-datepicker.form-datepicker-solid.focus {
  color: #eef3f7;
  background-color: #171723 !important;
  border-color: #171723 !important;
}
.form-datepicker.form-datepicker-white {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #151521;
}
.form-datepicker.form-datepicker-white::placeholder {
  color: #a1a5b7;
}
.form-datepicker.form-datepicker-white::-moz-placeholder {
  color: #a1a5b7;
  opacity: 1;
}
.dropdown.show > .form-datepicker.form-datepicker-white, .form-datepicker.form-datepicker-white:active, .form-datepicker.form-datepicker-white.active, .form-datepicker.form-datepicker-white:focus, .form-datepicker.form-datepicker-white.focus {
  background-color: #ffffff;
  border-color: #ffffff !important;
  color: #151521;
}

.rotate {
  display: inline-flex;
  align-items: center;
}

.rotate-90 {
  transition: transform 0.3s ease;
  -webkit-backface-visibility: hidden;
}
.collapsible:not(.collapsed) > .rotate-90, .hoverable:hover > .rotate-90, .active > .rotate-90 {
  transform: rotateZ(90deg) /*rtl:ignore*/;
  transition: transform 0.3s ease;
}
[direction=rtl] .collapsible:not(.collapsed) > .rotate-90, [direction=rtl] .hoverable:hover > .rotate-90, [direction=rtl] .active > .rotate-90 {
  transform: rotateZ(-90deg) /*rtl:ignore*/;
}

.rotate-n90 {
  transition: transform 0.3s ease;
  -webkit-backface-visibility: hidden;
}
.collapsible:not(.collapsed) > .rotate-n90, .hoverable:hover > .rotate-n90 .active > .rotate-n90 {
  transform: rotateZ(-90deg) /*rtl:ignore*/;
  transition: transform 0.3s ease;
}
[direction=rtl] .collapsible:not(.collapsed) > .rotate-n90, [direction=rtl] .hoverable:hover > .rotate-n90 .active > .rotate-n90 {
  transform: rotateZ(90deg) /*rtl:ignore*/;
}

.rotate-180 {
  transition: transform 0.3s ease;
  -webkit-backface-visibility: hidden;
}
.collapsible:not(.collapsed) > .rotate-180, .hoverable:hover > .rotate-180, .active > .rotate-180 {
  transform: rotateZ(180deg) /*rtl:ignore*/;
  transition: transform 0.3s ease;
}
[direction=rtl] .collapsible:not(.collapsed) > .rotate-180, [direction=rtl] .hoverable:hover > .rotate-180, [direction=rtl] .active > .rotate-180 {
  transform: rotateZ(-180deg) /*rtl:ignore*/;
}

.rotate-n180 {
  transition: transform 0.3s ease;
  -webkit-backface-visibility: hidden;
}
.collapsible:not(.collapsed) > .rotate-n180, .hoverable:hover > .rotate-n180 .active > .rotate-n180 {
  transform: rotateZ(-180deg) /*rtl:ignore*/;
  transition: transform 0.3s ease;
}
[direction=rtl] .collapsible:not(.collapsed) > .rotate-n180, [direction=rtl] .hoverable:hover > .rotate-n180 .active > .rotate-n180 {
  transform: rotateZ(180deg) /*rtl:ignore*/;
}

.rotate-270 {
  transition: transform 0.3s ease;
  -webkit-backface-visibility: hidden;
}
.collapsible:not(.collapsed) > .rotate-270, .hoverable:hover > .rotate-270, .active > .rotate-270 {
  transform: rotateZ(270deg) /*rtl:ignore*/;
  transition: transform 0.3s ease;
}
[direction=rtl] .collapsible:not(.collapsed) > .rotate-270, [direction=rtl] .hoverable:hover > .rotate-270, [direction=rtl] .active > .rotate-270 {
  transform: rotateZ(-270deg) /*rtl:ignore*/;
}

.rotate-n270 {
  transition: transform 0.3s ease;
  -webkit-backface-visibility: hidden;
}
.collapsible:not(.collapsed) > .rotate-n270, .hoverable:hover > .rotate-n270 .active > .rotate-n270 {
  transform: rotateZ(-270deg) /*rtl:ignore*/;
  transition: transform 0.3s ease;
}
[direction=rtl] .collapsible:not(.collapsed) > .rotate-n270, [direction=rtl] .hoverable:hover > .rotate-n270 .active > .rotate-n270 {
  transform: rotateZ(270deg) /*rtl:ignore*/;
}

.light-theme .accordion .accordion-item {
  border: 1px solid #eff2f5;
  background-color: #ffffff;
}
.dark-theme .accordion .accordion-item {
  border: 1px solid #3f4254;
  background-color: #1e1e2d;
}
.accordion .accordion-header {
  cursor: pointer;
}
.light-theme .accordion .accordion-header .accordion-button {
  color: #1e1e2d;
  background-color: #ffffff;
}
.dark-theme .accordion .accordion-header .accordion-button {
  color: #ffffff;
  background-color: #1e1e2d;
}
.light-theme .accordion .accordion-header .accordion-button:not(.collapsed) {
  color: #e10e8d;
  background-color: #e4e6ef;
  box-shadow: inset 0 -1px 0 #eff2f5;
}
.dark-theme .accordion .accordion-header .accordion-button:not(.collapsed) {
  color: #e10e8d;
  background-color: #1b1b29;
  box-shadow: inset 0 -1px 0 #3f4254;
}
.accordion .accordion-header .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion .accordion-header .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23a1a5b7'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion.accordion-icon-toggle .accordion-icon {
  display: flex;
  flex-shrink: 0;
  transition: all 0.2s ease;
  transform: rotate(90deg);
  align-items: center;
  justify-content: center;
}
.accordion.accordion-icon-toggle .accordion-icon i {
  color: #e10e8d;
}
.accordion.accordion-icon-toggle .accordion-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.accordion.accordion-icon-toggle .accordion-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.accordion.accordion-icon-toggle .collapsed .accordion-icon {
  transition: all 0.2s ease;
  transform: rotate(0);
}
.accordion.accordion-icon-toggle .collapsed .accordion-icon i {
  color: #a1a5b7;
}
.accordion.accordion-icon-toggle .collapsed .accordion-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.accordion.accordion-icon-toggle .collapsed .accordion-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.accordion.accordion-borderless .accordion-item {
  border: 0;
}
.accordion.accordion-flush .accordion-item {
  background-color: transparent;
  border: 0;
  border-radius: none;
  padding-left: 0;
  padding-right: 0;
}

.anchor {
  display: flex;
  align-items: center;
}
.anchor a {
  position: relative;
  display: none;
  align-items: center;
  justify-content: flex-start;
  height: 1em;
  width: 1.25em;
  margin-left: -1.25em;
  font-weight: 500;
  font-size: 0.8em;
  color: #a1a5b7;
  transition: all 0.2s ease;
}
.anchor a:before {
  content: "#";
}
.anchor:hover a {
  display: flex;
}
.anchor:hover a:hover {
  color: #e10e8d;
  transition: all 0.2s ease;
}

.animation {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes animationSlideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.animation-slide-in-down {
  animation-name: animationSlideInDown;
}

@keyframes animationSlideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.animation-slide-in-up {
  animation-name: animationSlideInUp;
}

@keyframes animationFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.animation-fade-in {
  animation-name: animationFadeIn;
}

@keyframes animationFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.animation-fade-out {
  animation-name: animationFadeOut;
}

.animation-blink {
  animation: animationBlink 1s steps(5, start) infinite;
}

@keyframes animationBlink {
  to {
    visibility: hidden;
  }
}
.animation-shake {
  position: relative;
  animation: shake 0.2s;
}

@keyframes shake {
  0%, 100% {
    left: 0px;
  }
  20%, 80% {
    left: -15px;
  }
  40%, 60% {
    left: 15px;
  }
}
.badge.badge-circle, .badge.badge-square {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  min-width: 1.75rem;
  padding: 0 0.1rem;
}
.badge.badge-circle {
  border-radius: 50%;
  padding: 0;
  min-width: unset;
  width: 1.75rem;
}

.badge-white {
  color: var(--inverse-white);
  background-color: var(--white);
}

.light-theme .badge-white {
  color: #000000 !important;
  background-color: #ffffff !important;
}
.dark-theme .badge-white {
  color: #ffffff !important;
  background-color: #000000 !important;
}

.badge-light-white {
  color: #ffffff;
}

.badge-lighten-white {
  color: #ffffff;
}

.badge-light {
  color: var(--inverse-light);
  background-color: var(--light);
}

.light-theme .badge-light {
  color: #3f4254 !important;
  background-color: #eff2f5 !important;
}
.dark-theme .badge-light {
  color: #f5f8fa !important;
  background-color: #3f4254 !important;
}

.badge-light-light {
  color: #eff2f5;
}

.badge-lighten-light {
  color: #eff2f5;
}

.badge-primary {
  color: var(--inverse-primary);
  background-color: var(--primary);
}

.light-theme .badge-primary {
  color: #ffffff !important;
  background-color: #e10e8d !important;
}
.dark-theme .badge-primary {
  color: #ffffff !important;
  background-color: #e10e8d !important;
}

.badge-light-primary {
  color: #e10e8d;
  background-color: #fff1fe;
}

.badge-lighten-primary {
  color: #e10e8d;
  background-color: rgba(255, 241, 254, 0.5);
}

.badge-secondary {
  color: var(--inverse-secondary);
  background-color: var(--secondary);
}

.light-theme .badge-secondary {
  color: #3f4254 !important;
  background-color: #e4e6ef !important;
}
.dark-theme .badge-secondary {
  color: #3f4254 !important;
  background-color: #e4e6ef !important;
}

.badge-light-secondary {
  color: #e4e6ef;
}

.badge-lighten-secondary {
  color: #e4e6ef;
}

.badge-success {
  color: var(--inverse-success);
  background-color: var(--success);
}

.light-theme .badge-success {
  color: #ffffff !important;
  background-color: #1BC5BC !important;
}
.dark-theme .badge-success {
  color: #ffffff !important;
  background-color: #1BC5BC !important;
}

.badge-light-success {
  color: #1BC5BC;
  background-color: rgba(27, 197, 188, 0.1);
}

.badge-lighten-success {
  color: #1BC5BC;
  background-color: #b7f5f2;
}

.badge-info {
  color: var(--inverse-info);
  background-color: var(--info);
}

.light-theme .badge-info {
  color: #ffffff !important;
  background-color: #5F5CF1 !important;
}
.dark-theme .badge-info {
  color: !important;
  background-color: #5F5CF1 !important;
}

.badge-light-info {
  color: #5F5CF1;
  background-color: rgba(95, 92, 241, 0.1);
}

.badge-lighten-info {
  color: #5F5CF1;
  background-color: white;
}

.badge-warning {
  color: var(--inverse-warning);
  background-color: var(--warning);
}

.light-theme .badge-warning {
  color: #eff2f5 !important;
  background-color: #FFA800 !important;
}
.dark-theme .badge-warning {
  color: !important;
  background-color: #FFA800 !important;
}

.badge-light-warning {
  color: #FFA800;
  background-color: rgba(255, 168, 0, 0.15);
}

.badge-lighten-warning {
  color: #FFA800;
  background-color: #ffeecc;
}

.badge-danger {
  color: var(--inverse-danger);
  background-color: var(--danger);
}

.light-theme .badge-danger {
  color: #ffffff !important;
  background-color: #F65464 !important;
}
.dark-theme .badge-danger {
  color: !important;
  background-color: #F65464 !important;
}

.badge-light-danger {
  color: #F65464;
  background-color: rgba(246, 84, 100, 0.1);
}

.badge-lighten-danger {
  color: #F65464;
  background-color: white;
}

.badge-dark {
  color: var(--inverse-dark);
  background-color: var(--dark);
}

.light-theme .badge-dark {
  color: #f5f8fa !important;
  background-color: #3f4254 !important;
}
.dark-theme .badge-dark {
  color: #3f4254 !important;
  background-color: #eff2f5 !important;
}

.badge-light-dark {
  color: #3f4254;
  background-color: #eff2f5;
}

.badge-lighten-dark {
  color: #3f4254;
}

.badge-black {
  color: var(--inverse-black);
  background-color: var(--black);
}

.light-theme .badge-black {
  color: #ffffff !important;
  background-color: #000000 !important;
}
.dark-theme .badge-black {
  color: #000000 !important;
  background-color: #ffffff !important;
}

.badge-light-black {
  color: #000000;
}

.badge-lighten-black {
  color: #000000;
}

.badge-muted {
  color: var(--inverse-muted);
  background-color: var(--muted);
}

.light-theme .badge-muted {
  color: !important;
  background-color: #a1a5b7 !important;
}
.dark-theme .badge-muted {
  color: !important;
  background-color: #a1a5b7 !important;
}

.badge-light-muted {
  color: #a1a5b7;
}

.badge-lighten-muted {
  color: #a1a5b7;
}

.badge-blue {
  color: var(--inverse-blue);
  background-color: var(--blue);
}

.light-theme .badge-blue {
  color: #ffffff !important;
  background-color: #3699FF !important;
}
.dark-theme .badge-blue {
  color: !important;
  background-color: #3699FF !important;
}

.badge-light-blue {
  color: #3699FF;
  background-color: rgba(54, 153, 255, 0.1);
}

.badge-lighten-blue {
  color: #3699FF;
  background-color: white;
}

.badge-indigo {
  color: var(--inverse-indigo);
  background-color: var(--indigo);
}

.light-theme .badge-indigo {
  color: #ffffff !important;
  background-color: #6610f2 !important;
}
.dark-theme .badge-indigo {
  color: !important;
  background-color: #6610f2 !important;
}

.badge-light-indigo {
  color: #6610f2;
  background-color: rgba(102, 16, 242, 0.1);
}

.badge-lighten-indigo {
  color: #6610f2;
  background-color: #e2d1fd;
}

.badge-purple {
  color: var(--inverse-purple);
  background-color: var(--purple);
}

.light-theme .badge-purple {
  color: #ffffff !important;
  background-color: #5F5CF1 !important;
}
.dark-theme .badge-purple {
  color: !important;
  background-color: #5F5CF1 !important;
}

.badge-light-purple {
  color: #5F5CF1;
  background-color: rgba(95, 92, 241, 0.1);
}

.badge-lighten-purple {
  color: #5F5CF1;
  background-color: white;
}

.badge-pink {
  color: var(--inverse-pink);
  background-color: var(--pink);
}

.light-theme .badge-pink {
  color: #ffffff !important;
  background-color: #d63384 !important;
}
.dark-theme .badge-pink {
  color: !important;
  background-color: #d63384 !important;
}

.badge-light-pink {
  color: #d63384;
  background-color: rgba(214, 51, 132, 0.1);
}

.badge-lighten-pink {
  color: #d63384;
  background-color: #f8ddea;
}

.badge-red {
  color: var(--inverse-red);
  background-color: var(--red);
}

.light-theme .badge-red {
  color: #ffffff !important;
  background-color: #F65464 !important;
}
.dark-theme .badge-red {
  color: !important;
  background-color: #F65464 !important;
}

.badge-light-red {
  color: #F65464;
  background-color: rgba(246, 84, 100, 0.1);
}

.badge-lighten-red {
  color: #F65464;
  background-color: white;
}

.badge-orange {
  color: var(--inverse-orange);
  background-color: var(--orange);
}

.light-theme .badge-orange {
  color: #ffffff !important;
  background-color: #fd7e14 !important;
}
.dark-theme .badge-orange {
  color: !important;
  background-color: #fd7e14 !important;
}

.badge-light-orange {
  color: #fd7e14;
  background-color: rgba(253, 126, 20, 0.1);
}

.badge-lighten-orange {
  color: #fd7e14;
  background-color: #ffedde;
}

.badge-yellow {
  color: var(--inverse-yellow);
  background-color: var(--yellow);
}

.light-theme .badge-yellow {
  color: #ffffff !important;
  background-color: #FFA800 !important;
}
.dark-theme .badge-yellow {
  color: !important;
  background-color: #FFA800 !important;
}

.badge-light-yellow {
  color: #FFA800;
  background-color: rgba(255, 168, 0, 0.1);
}

.badge-lighten-yellow {
  color: #FFA800;
  background-color: #ffeecc;
}

.badge-green {
  color: var(--inverse-green);
  background-color: var(--green);
}

.light-theme .badge-green {
  color: #ffffff !important;
  background-color: #1BC5BC !important;
}
.dark-theme .badge-green {
  color: !important;
  background-color: !important;
}

.badge-light-green {
  color: #1BC5BC;
  background-color: rgba(27, 197, 188, 0.1);
}

.badge-lighten-green {
  color: #1BC5BC;
  background-color: #b7f5f2;
}

.badge-teal {
  color: var(--inverse-teal);
  background-color: var(--teal);
}

.light-theme .badge-teal {
  color: #ffffff !important;
  background-color: #20c997 !important;
}
.dark-theme .badge-teal {
  color: !important;
  background-color: #20c997 !important;
}

.badge-light-teal {
  color: #20c997;
  background-color: rgba(32, 201, 151, 0.1);
}

.badge-lighten-teal {
  color: #20c997;
  background-color: #c0f5e5;
}

.badge-cyan {
  color: var(--inverse-cyan);
  background-color: var(--cyan);
}

.light-theme .badge-cyan {
  color: #ffffff !important;
  background-color: #0dcaf0 !important;
}
.dark-theme .badge-cyan {
  color: !important;
  background-color: #0dcaf0 !important;
}

.badge-light-cyan {
  color: #0dcaf0;
  background-color: rgba(13, 202, 240, 0.1);
}

.badge-lighten-cyan {
  color: #0dcaf0;
  background-color: #cdf4fc;
}

.badge-gray {
  color: var(--inverse-gray);
  background-color: var(--gray);
}

.light-theme .badge-gray {
  color: #b5b5c3 !important;
  background-color: #7e8299 !important;
}
.dark-theme .badge-gray {
  color: !important;
  background-color: #7e8299 !important;
}

.badge-light-gray {
  color: #7e8299;
  background-color: rgba(126, 130, 153, 0.1);
}

.badge-lighten-gray {
  color: #7e8299;
  background-color: #b5b5c3;
}

.badge-body-bg {
  color: var(--inverse-body-bg);
  background-color: var(--body-bg);
}

.light-theme .badge-body-bg {
  color: !important;
  background-color: #f5f8fa !important;
}
.dark-theme .badge-body-bg {
  color: !important;
  background-color: #151521 !important;
}

.badge-light-body-bg {
  color: #f5f8fa;
}

.badge-lighten-body-bg {
  color: #f5f8fa;
}

.badge-body-text {
  color: var(--inverse-body-text);
  background-color: var(--body-text);
}

.light-theme .badge-body-text {
  color: !important;
  background-color: #151521 !important;
}
.dark-theme .badge-body-text {
  color: !important;
  background-color: #f5f8fa !important;
}

.badge-light-body-text {
  color: #151521;
}

.badge-lighten-body-text {
  color: #151521;
}

.badge-toolbar-bg {
  color: var(--inverse-toolbar-bg);
  background-color: var(--toolbar-bg);
}

.light-theme .badge-toolbar-bg {
  color: !important;
  background-color: #ffffff !important;
}
.dark-theme .badge-toolbar-bg {
  color: !important;
  background-color: #1a1a27 !important;
}

.badge-light-toolbar-bg {
  color: #ffffff;
}

.badge-lighten-toolbar-bg {
  color: #ffffff;
}

.badge-toolbar-text {
  color: var(--inverse-toolbar-text);
  background-color: var(--toolbar-text);
}

.light-theme .badge-toolbar-text {
  color: !important;
  background-color: #1a1a27 !important;
}
.dark-theme .badge-toolbar-text {
  color: !important;
  background-color: #ffffff !important;
}

.badge-light-toolbar-text {
  color: #1a1a27;
}

.badge-lighten-toolbar-text {
  color: #1a1a27;
}

.badge-focus-bg {
  color: var(--inverse-focus-bg);
  background-color: var(--focus-bg);
}

.light-theme .badge-focus-bg {
  color: !important;
  background-color: #eef3f7 !important;
}
.dark-theme .badge-focus-bg {
  color: !important;
  background-color: #171723 !important;
}

.badge-light-focus-bg {
  color: #eef3f7;
}

.badge-lighten-focus-bg {
  color: #eef3f7;
}

.badge-tabbar-text {
  color: var(--inverse-tabbar-text);
  background-color: var(--tabbar-text);
}

.light-theme .badge-tabbar-text {
  color: !important;
  background-color: #171723 !important;
}
.dark-theme .badge-tabbar-text {
  color: !important;
  background-color: #eef3f7 !important;
}

.badge-light-tabbar-text {
  color: #171723;
}

.badge-lighten-tabbar-text {
  color: #171723;
}

.badge-card-bg {
  color: var(--inverse-card-bg);
  background-color: var(--card-bg);
}

.light-theme .badge-card-bg {
  color: !important;
  background-color: #ffffff !important;
}
.dark-theme .badge-card-bg {
  color: !important;
  background-color: #1e1e2d !important;
}

.badge-light-card-bg {
  color: #ffffff;
}

.badge-lighten-card-bg {
  color: #ffffff;
}

.badge-card-text {
  color: var(--inverse-card-text);
  background-color: var(--card-text);
}

.light-theme .badge-card-text {
  color: !important;
  background-color: #1e1e2d !important;
}
.dark-theme .badge-card-text {
  color: !important;
  background-color: #ffffff !important;
}

.badge-light-card-text {
  color: #1e1e2d;
}

.badge-lighten-card-text {
  color: #1e1e2d;
}

.badge-item-bg {
  color: var(--inverse-item-bg);
  background-color: var(--item-bg);
}

.light-theme .badge-item-bg {
  color: !important;
  background-color: #e4e6ef !important;
}
.dark-theme .badge-item-bg {
  color: !important;
  background-color: #1b1b29 !important;
}

.badge-light-item-bg {
  color: #e4e6ef;
}

.badge-lighten-item-bg {
  color: #e4e6ef;
}

.badge-item-text {
  color: var(--inverse-item-text);
  background-color: var(--item-text);
}

.light-theme .badge-item-text {
  color: !important;
  background-color: #1b1b29 !important;
}
.dark-theme .badge-item-text {
  color: !important;
  background-color: #e4e6ef !important;
}

.badge-light-item-text {
  color: #1b1b29;
}

.badge-lighten-item-text {
  color: #1b1b29;
}

.breadcrumb {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
  margin: 0;
}
.breadcrumb .breadcrumb-item {
  display: flex;
  align-items: center;
  padding-left: 0;
  padding-right: 0.5rem;
}
.breadcrumb .breadcrumb-item:last-child {
  padding-right: 0;
}
.breadcrumb .breadcrumb-item:after {
  content: "/";
  padding-left: 0.5rem;
}
.breadcrumb .breadcrumb-item:before {
  display: none;
}
.breadcrumb .breadcrumb-item:last-child:after {
  display: none;
}

.breadcrumb-line .breadcrumb-item:after {
  content: "-";
}

.breadcrumb-dot .breadcrumb-item:after {
  content: "•";
}

.breadcrumb-separatorless .breadcrumb-item:after {
  display: none;
}

.carousel.carousel-custom .carousel-indicators {
  align-items: center;
  position: static;
  margin: 0;
  padding: 0;
  list-style: none;
}
.carousel.carousel-custom .carousel-indicators li {
  transform: none;
  opacity: 1;
}
.carousel.carousel-custom .carousel-indicators li.active {
  transform: none;
  opacity: 1;
}
.carousel.carousel-custom .carousel-indicators.carousel-indicators-dots li {
  border-radius: 0;
  background-color: transparent;
  height: 13px;
  width: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.carousel.carousel-custom .carousel-indicators.carousel-indicators-dots li:after {
  display: inline-block;
  content: " ";
  border-radius: 50%;
  transition: all 0.3s ease;
  background-color: #eff2f5;
  height: 9px;
  width: 9px;
}
.carousel.carousel-custom .carousel-indicators.carousel-indicators-dots li.active {
  background-color: transparent;
}
.carousel.carousel-custom .carousel-indicators.carousel-indicators-dots li.active:after {
  transition: all 0.3s ease;
  height: 13px;
  width: 13px;
  background-color: #e4e6ef;
}
.carousel.carousel-custom.carousel-stretch {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.carousel.carousel-custom.carousel-stretch .carousel-inner {
  flex-grow: 1;
}
.carousel.carousel-custom.carousel-stretch .carousel-item {
  height: 100%;
}
.carousel.carousel-custom.carousel-stretch .carousel-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

code:not([class*=language-]) {
  font-weight: 400;
  color: #e10e8d;
  line-height: inherit;
  font-size: inherit;
  background-color: #f5f8fa;
  padding: 1px 0.4rem;
  margin: 0 0.5rem;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.08);
  border-radius: 0.3rem;
}

.drawer {
  display: flex !important;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  transition: transform 0.3s ease-in-out;
}
.light-theme .drawer {
  color: #1e1e2d;
  background-color: #ffffff;
}
.dark-theme .drawer {
  color: #ffffff;
  background-color: #1e1e2d;
}
.drawer.drawer-dark {
  background-color: #1e1e2d;
}
.drawer.drawer-dark mat-label {
  color: #ffffff;
}
.drawer.drawer-start {
  left: 0;
  transform: translateX(-100%);
}
.drawer.drawer-end {
  right: 0;
  transform: translateX(100%);
}
.drawer.drawer-on {
  transform: none;
  box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease-in-out;
}
.drawer.animations-enabled {
  transition-property: transform, width;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
}

.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 109;
  background-color: rgba(161, 165, 183, 0.6);
  animation: animation-drawer-fade-in 0.3s ease-in-out 1;
}

[data-drawer=true] {
  display: none;
}

body[data-drawer=on] {
  overflow: hidden;
}
body[data-drawer=on] .drawer {
  z-index: 110;
}

body[data-drawer=swipe] .drawer {
  z-index: 2;
}

@media (min-width: 992px) {
  body[data-drawer=swipe][data-aside-minimize=on].aside-enabled .drawer-start {
    left: 75px;
    transition: 0.3s ease;
  }
  body[data-drawer=swipe][data-aside-minimize=on].toolbar-fixed.aside-enabled .drawer-start {
    top: calc(var(--header-height) + var(--toolbar-height)) !important;
    transition: 0.3s ease;
  }
  body[data-drawer=swipe].aside-enabled .drawer-start {
    left: 265px;
    transition: 0.3s ease;
  }
  body[data-drawer=swipe].toolbar-fixed.aside-enabled .drawer-start {
    top: calc(var(--header-height) + var(--toolbar-height)) !important;
    transition: 0.3s ease;
  }
}
@keyframes animation-drawer-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.feedback {
  display: none;
}

.feedback-popup {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  position: fixed;
  z-index: 1000;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  background-color: #ffffff;
  border-radius: 0.475rem;
  padding: 1rem 1.25rem;
}

.feedback-top-center {
  display: flex;
  transition: top 0.6s ease;
  left: 50%;
  transform: translateX(-50%);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.feedback-top-center.feedback-shown {
  top: 0px;
  transition: top 0.6s ease;
}

.image-input {
  position: relative;
  display: inline-block;
  border-radius: 0.475rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.image-input:not(.image-input-empty) {
  background-image: none !important;
}
.image-input .image-input-wrapper {
  width: 120px;
  height: 120px;
  border-radius: 0.475rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.image-input [data-image-input-action=change] {
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -10px;
}
.image-input [data-image-input-action=change] input {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}
.image-input [data-image-input-action=cancel],
.image-input [data-image-input-action=remove] {
  position: absolute;
  right: -10px;
  bottom: -5px;
}
.image-input [data-image-input-action=cancel] {
  display: none;
}
.image-input.image-input-changed [data-image-input-action=cancel] {
  display: flex;
}
.image-input.image-input-changed [data-image-input-action=remove] {
  display: none;
}
.image-input.image-input-empty [data-image-input-action=remove],
.image-input.image-input-empty [data-image-input-action=cancel] {
  display: none;
}
.image-input.image-input-circle {
  border-radius: 50%;
}
.image-input.image-input-circle .image-input-wrapper {
  border-radius: 50%;
}
.image-input.image-input-circle [data-action=change] {
  right: 5px;
  top: 5px;
}
.image-input.image-input-circle [data-action=cancel],
.image-input.image-input-circle [data-action=remove] {
  right: 5px;
  bottom: 5px;
}
.image-input.image-input-outline .image-input-wrapper {
  border: 3px solid #ffffff;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.05);
}

.indicator-progress {
  display: none;
}
[data-indicator=on] > .indicator-progress {
  display: inline-block;
}

[data-indicator=on] > .indicator-label {
  display: none;
}

.item-border-hover {
  border: 1px solid #fff;
  border-radius: 6px;
}
.item-border-hover:hover {
  border: 1px solid #eff2f5;
  cursor: pointer;
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(245, 248, 250, 0.3);
}
.item-border-hover:hover .label {
  background-color: #00a3ff !important;
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #fff;
}
.item-border-hover .label {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.modal .modal-header {
  align-items: center;
  justify-content: space-between;
}
.modal .modal-dialog {
  outline: none !important;
}
.light-theme .modal .modal-dialog .modal-header {
  border-bottom: 1px solid #eff2f5;
}
.dark-theme .modal .modal-dialog .modal-header {
  border-bottom: 1px solid #3f4254;
}
.light-theme .modal .modal-dialog .modal-content {
  color: #1e1e2d;
  background-color: #ffffff;
}
.dark-theme .modal .modal-dialog .modal-content {
  color: #ffffff;
  background-color: #1e1e2d;
}
.light-theme .modal .modal-dialog .modal-footer {
  border-top: 1px solid #eff2f5;
}
.dark-theme .modal .modal-dialog .modal-footer {
  border-top: 1px solid #3f4254;
}

.modal.modal-sticky.modal-sticky-bottom-right {
  padding: 0 !important;
  max-width: 500px;
  height: auto;
  position: fixed;
  left: auto;
  top: auto;
  bottom: 25px;
  right: 25px;
  margin: 0;
  box-shadow: 0px 0px 60px -15px rgba(0, 0, 0, 0.2);
  border-radius: 0.475rem;
}
.modal.modal-sticky.modal-sticky-bottom-right .modal-dialog {
  position: static;
  max-width: 500px;
  width: auto;
  margin: 0;
  border-radius: 0.475rem;
}
.modal.modal-sticky.modal-sticky-bottom-right .modal-dialog .modal-content {
  border: 0;
  border-radius: 0.475rem;
}
.modal.modal-sticky.modal-sticky-bottom-right.modal-sticky-lg {
  max-width: 600px;
}
.modal.modal-sticky.modal-sticky-bottom-right.modal-sticky-lg .modal-dialog {
  max-width: 600px;
}
@media (max-width: 767.98px) {
  .modal.modal-sticky.modal-sticky-bottom-right {
    bottom: 10px;
    right: 10px;
    left: 10px;
    width: auto;
  }
}

.nav-line-tabs {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #eff2f5;
}
.nav-line-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-line-tabs .nav-item .nav-link {
  color: #a1a5b7;
  border: 0;
  border-bottom: 1px solid transparent;
  transition: color 0.2s ease, background-color 0.2s ease;
  padding: 0.5rem 0;
  margin: 0 1rem;
}
.nav-line-tabs .nav-item:first-child .nav-link {
  margin-left: 0;
}
.nav-line-tabs .nav-item:last-child .nav-link {
  margin-right: 0;
}
.nav-line-tabs .nav-item .nav-link.active,
.nav-line-tabs .nav-item.show .nav-link,
.nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #e10e8d;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.nav-line-tabs.nav-line-tabs-2x {
  border-bottom-width: 2px;
}
.nav-line-tabs.nav-line-tabs-2x .nav-item {
  margin-bottom: -2px;
}
.nav-line-tabs.nav-line-tabs-2x .nav-item .nav-link {
  border-bottom-width: 2px;
}
.nav-line-tabs.nav-line-tabs-2x .nav-item .nav-link.active,
.nav-line-tabs.nav-line-tabs-2x .nav-item.show .nav-link,
.nav-line-tabs.nav-line-tabs-2x .nav-item .nav-link:hover:not(.disabled) {
  border-bottom-width: 2px;
}

.nav-pills .nav-item {
  margin-right: 0.5rem;
}
.nav-pills .nav-item:last-child {
  margin-right: 0;
}

.nav-stretch {
  align-items: stretch;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.nav-stretch .nav-item {
  display: flex;
  align-items: stretch;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.nav-stretch .nav-link {
  display: flex;
  align-items: center;
}

.nav-group {
  padding: 0.35rem;
  border-radius: 0.475rem;
  background-color: #f5f8fa;
}
.nav-group.nav-group-outline {
  background-color: transparent;
  border: 1px solid #eff2f5;
}
.nav-group.nav-group-fluid {
  display: flex;
}
.nav-group.nav-group-fluid > label,
.nav-group.nav-group-fluid > .btn {
  position: relative;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 0;
}
.nav-group.nav-group-fluid > label {
  margin-right: 0.1rem;
}
.nav-group.nav-group-fluid > label > .btn {
  width: 100%;
}
.nav-group.nav-group-fluid > label:last-child {
  margin-right: 0;
}

.overlay {
  position: relative;
}
.overlay .overlay-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  opacity: 0;
}
.overlay.overlay-block .overlay-layer, .overlay:hover .overlay-layer {
  transition: all 0.3s ease;
  opacity: 1;
}
.overlay.overlay-block {
  cursor: wait;
}

[data-st-page-loading=true] {
  overflow: hidden;
}

.page-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1080;
  display: none;
}
.page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-loader .page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s linear;
}
.page-loader .page-loading::before, .page-loader .page-loading::after {
  content: "";
  position: fixed;
  height: 100%;
  top: 0;
  left: 50%;
  background-color: var(--bs-body-bg);
  opacity: 0.25;
  width: 200%;
  margin-left: -100%;
  transform: skewX(-30deg) translateX(-100%);
  transition: transform 0.3s linear 0.3s;
  z-index: 2;
}
.page-loader .page-loading.is-loading {
  position: absolute;
  opacity: 1;
  visibility: visible;
}
.page-loader .page-loading.is-loading::before {
  transform: skewX(-30deg) translateX(0);
  transition: transform 0.3s linear;
}
.page-loader .page-loading.is-loading::after {
  transform: skewX(-30deg) translateX(0);
  transition: transform 0.3s linear 0.15s;
}
.page-loader .page-loading > * {
  z-index: 9;
}

.svg-icon.svg-icon-20x {
  height: 10rem !important;
  width: 10rem !important;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}
.pagination.pagination-circle .page-link {
  border-radius: 50%;
}
.light-theme .pagination.pagination-outline .page-link {
  border: 1px solid #eff2f5;
}
.dark-theme .pagination.pagination-outline .page-link {
  border: 1px solid #3f4254;
}

.page-item {
  margin-right: 0.5rem;
}
.page-item:last-child {
  margin-right: 0;
}
.page-item .page-link {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.475rem;
  height: 2.5rem;
  min-width: 2.5rem;
  font-weight: 500;
  font-size: 1.075rem;
}
.page-item .page-link i {
  font-size: 0.85rem;
}
.page-item .page-link .previous,
.page-item .page-link .next {
  display: block;
  height: 0.875rem;
  width: 0.875rem;
}
.page-item.next:not(:hover):not(:focus):not(.disabled) .page-link, .page-item.previous:not(:hover):not(:focus):not(.disabled) .page-link {
  background-color: transparent;
}
.page-item.next:not(:hover):not(:focus):not(.disabled) .page-link i, .page-item.previous:not(:hover):not(:focus):not(.disabled) .page-link i {
  color: #5e6278;
}
.page-item.next:not(:hover):not(:focus):not(.disabled) .page-link .svg-icon svg [fill]:not(.permanent):not(g), .page-item.previous:not(:hover):not(:focus):not(.disabled) .page-link .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5e6278;
}
.page-item.next:not(:hover):not(:focus):not(.disabled) .page-link .svg-icon svg:hover [fill]:not(.permanent):not(g), .page-item.previous:not(:hover):not(:focus):not(.disabled) .page-link .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.page-item.next:not(:hover):not(:focus):not(.disabled) .page-link .previous, .page-item.previous:not(:hover):not(:focus):not(.disabled) .page-link .previous {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235e6278'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
}
.page-item.next:not(:hover):not(:focus):not(.disabled) .page-link .next, .page-item.previous:not(:hover):not(:focus):not(.disabled) .page-link .next {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%235e6278'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.page-item:focus:not(.disabled) .page-link i {
  color: #e10e8d;
}
.page-item:focus:not(.disabled) .page-link .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.page-item:focus:not(.disabled) .page-link .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.page-item:focus:not(.disabled) .page-link .previous {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
}
.page-item:focus:not(.disabled) .page-link .next {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.page-item:hover:not(.disabled) .page-link i {
  color: #e10e8d;
}
.page-item:hover:not(.disabled) .page-link .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.page-item:hover:not(.disabled) .page-link .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.page-item:hover:not(.disabled) .page-link .previous {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
}
.page-item:hover:not(.disabled) .page-link .next {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.page-item.active .page-link i {
  color: #ffffff;
}
.page-item.active .page-link .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.page-item.active .page-link .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.page-item.active .page-link .previous {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
}
.page-item.active .page-link .next {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}
.page-item.disabled .page-link i {
  color: #b5b5c3;
}
.page-item.disabled .page-link .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.page-item.disabled .page-link .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.page-item.disabled .page-link .previous {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23b5b5c3'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
}
.page-item.disabled .page-link .next {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23b5b5c3'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
}

.placeholder-white-opacity-75::placeholder {
  color: rgba(255, 255, 255, 0.75);
}
.placeholder-white-opacity-75::-moz-placeholder {
  color: rgba(255, 255, 255, 0.75);
  opacity: 1;
}

.popover {
  border: none;
  /*rtl:raw:
    left: 0 !important;
    right: auto !important;
    */
}
.popover .popover-header {
  font-size: 1rem;
  font-weight: 500;
}
.light-theme .popover .popover-header {
  color: #2b2b40 !important;
  background-color: #ffffff !important;
  border-bottom: 1px solid #f5f8fa !important;
}
.dark-theme .popover .popover-header {
  color: #ffffff !important;
  background-color: #2b2b40 !important;
  border-bottom: 1px solid #323248 !important;
}
.light-theme .popover .popover-body {
  color: #2b2b40 !important;
  background-color: #ffffff !important;
}
.dark-theme .popover .popover-body {
  color: #ffffff !important;
  background-color: #2b2b40 !important;
}
.popover.bs-popover-right {
  margin-right: auto/*rtl:ignore*/;
  margin-left: 0.5rem /*rtl:ignore*/;
}
.popover.bs-popover-right > .arrow {
  right: auto/*rtl:ignore*/;
  left: calc(-0.5rem - (var(--bs-border-width))) /*rtl:ignore*/;
}
.popover.bs-popover-right > .arrow::before {
  right: auto/*rtl:ignore*/;
  left: 0 /*rtl:ignore*/;
  border-width: 0.5rem 0.5rem 0.5rem 0 /*rtl:ignore*/;
  border-right-color: var(--bs-border-color-translucent) /*rtl:ignore*/;
}
.popover.bs-popover-right > .arrow::after {
  right: auto/*rtl:ignore*/;
  left: var(--bs-border-width) /*rtl:ignore*/;
  border-width: 0.5rem 0.5rem 0.5rem 0 /*rtl:ignore*/;
  border-right-color: var(--bs-body-bg) /*rtl:ignore*/;
}
.popover.bs-popover-left {
  margin-left: auto/*rtl:ignore*/;
  margin-right: 0.5rem /*rtl:ignore*/;
}
.popover.bs-popover-left > .arrow {
  left: auto/*rtl:ignore*/;
  right: calc(-0.5rem - (var(--bs-border-width))) /*rtl:ignore*/;
}
.popover.bs-popover-left > .arrow::before {
  left: auto/*rtl:ignore*/;
  right: 0 /*rtl:ignore*/;
  border-width: 0.5rem 0 0.5rem 0.5rem /*rtl:ignore*/;
  border-left-color: var(--bs-border-color-translucent) /*rtl:ignore*/;
}
.popover.bs-popover-left > .arrow::after {
  left: auto/*rtl:ignore*/;
  right: var(--bs-border-width) /*rtl:ignore*/;
  border-width: 0.5rem 0 0.5rem 0.5rem /*rtl:ignore*/;
  border-left-color: var(--bs-body-bg) /*rtl:ignore*/;
}

.popover-dark {
  background-color: #181c32;
  border: 0;
}
.popover-dark .popover-header {
  background-color: #181c32;
  color: #eff2f5;
  border-bottom-color: #3f4254;
}
.popover-dark .popover-body {
  color: #b5b5c3;
}
.popover-dark.bs-popover-top > .popover-arrow::before, .popover-dark.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  border-top-color: #f5f8fa;
}
.popover-dark.bs-popover-top > .popover-arrow::after, .popover-dark.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-top-color: #181c32;
}
.popover-dark.bs-popover-end > .popover-arrow::before, .popover-dark.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  border-right-color: #f5f8fa;
}
.popover-dark.bs-popover-end > .popover-arrow::after, .popover-dark.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-right-color: #181c32;
}
.popover-dark.bs-popover-bottom > .popover-arrow::before, .popover-dark.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  border-bottom-color: #f5f8fa;
}
.popover-dark.bs-popover-bottom > .popover-arrow::after, .popover-dark.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-bottom-color: #181c32;
}
.popover-dark.bs-popover-bottom .popover-header::before, .popover-dark.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  border-bottom-color: #181c32;
}
.popover-dark.bs-popover-start > .popover-arrow::before, .popover-dark.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  border-left-color: #f5f8fa;
}
.popover-dark.bs-popover-start > .popover-arrow::after, .popover-dark.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-left-color: #181c32;
}

.light-theme .progress {
  background-color: #eff2f5 !important;
}
.dark-theme .progress {
  background-color: #3f4254 !important;
}

.progress-vertical {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.progress-vertical .progress {
  height: 100%;
  border-radius: 0.475rem;
  display: flex;
  align-items: flex-end;
  margin-right: 1rem;
}
.light-theme .progress-vertical .progress {
  background-color: #eff2f5 !important;
}
.dark-theme .progress-vertical .progress {
  background-color: #3f4254 !important;
}
.progress-vertical .progress:last-child {
  margin-right: 0;
}
.progress-vertical .progress .progress-bar {
  width: 8px;
  border-radius: 0.475rem;
}

.multi-progress-bar {
  display: block;
  position: relative;
  width: 100%;
  height: 1rem;
  contain: strict;
  direction: ltr;
  overflow: hidden;
}
.multi-progress-bar span {
  float: left;
  width: 0%;
  height: 100%;
  overflow: hidden;
  animation: multi-progress-bar 1s ease;
}

@keyframes multi-progress-bar {
  0% {
    width: 0%;
  }
  100% {
    transition: width, 1s ease;
  }
}
.pulse {
  position: relative;
}
.pulse.pulse-white .pulse-ring {
  border-color: #ffffff;
}
.pulse.pulse-light .pulse-ring {
  border-color: #eff2f5;
}
.pulse.pulse-primary .pulse-ring {
  border-color: #e10e8d;
}
.pulse.pulse-secondary .pulse-ring {
  border-color: #e4e6ef;
}
.pulse.pulse-success .pulse-ring {
  border-color: #1BC5BC;
}
.pulse.pulse-info .pulse-ring {
  border-color: #5F5CF1;
}
.pulse.pulse-warning .pulse-ring {
  border-color: #FFA800;
}
.pulse.pulse-danger .pulse-ring {
  border-color: #F65464;
}
.pulse.pulse-dark .pulse-ring {
  border-color: #3f4254;
}
.pulse.pulse-black .pulse-ring {
  border-color: #000000;
}
.pulse.pulse-muted .pulse-ring {
  border-color: #a1a5b7;
}
.pulse.pulse-blue .pulse-ring {
  border-color: #3699FF;
}
.pulse.pulse-indigo .pulse-ring {
  border-color: #6610f2;
}
.pulse.pulse-purple .pulse-ring {
  border-color: #5F5CF1;
}
.pulse.pulse-pink .pulse-ring {
  border-color: #d63384;
}
.pulse.pulse-red .pulse-ring {
  border-color: #F65464;
}
.pulse.pulse-orange .pulse-ring {
  border-color: #fd7e14;
}
.pulse.pulse-yellow .pulse-ring {
  border-color: #FFA800;
}
.pulse.pulse-green .pulse-ring {
  border-color: #1BC5BC;
}
.pulse.pulse-teal .pulse-ring {
  border-color: #20c997;
}
.pulse.pulse-cyan .pulse-ring {
  border-color: #0dcaf0;
}
.pulse.pulse-gray .pulse-ring {
  border-color: #7e8299;
}
.pulse.pulse-body-bg .pulse-ring {
  border-color: #f5f8fa;
}
.pulse.pulse-body-text .pulse-ring {
  border-color: #151521;
}
.pulse.pulse-toolbar-bg .pulse-ring {
  border-color: #ffffff;
}
.pulse.pulse-toolbar-text .pulse-ring {
  border-color: #1a1a27;
}
.pulse.pulse-focus-bg .pulse-ring {
  border-color: #eef3f7;
}
.pulse.pulse-tabbar-text .pulse-ring {
  border-color: #171723;
}
.pulse.pulse-card-bg .pulse-ring {
  border-color: #ffffff;
}
.pulse.pulse-card-text .pulse-ring {
  border-color: #1e1e2d;
}
.pulse.pulse-item-bg .pulse-ring {
  border-color: #e4e6ef;
}
.pulse.pulse-item-text .pulse-ring {
  border-color: #1b1b29;
}

.pulse-ring {
  display: block;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  position: absolute;
  animation: animation-pulse 3.5s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  border-width: 3px;
  border-style: solid;
  border-color: #a1a5b7;
}

@keyframes animation-pulse {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
.rating {
  display: flex;
  align-items: center;
}

.rating-input {
  position: absolute !important;
  left: -9999px !important;
}
.rating-input[disabled] {
  display: none;
}

.rating-label {
  padding: 0;
  margin: 0;
}
.rating-label > i {
  line-height: 1;
  color: #b5b5c3;
}
.rating-label > .svg-icon {
  line-height: 1;
}
.rating-label > .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.rating-label > .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

label.rating-label {
  cursor: pointer;
}

div.rating-label.checked > i,
label.rating-label > i {
  color: #ffad0f;
}
div.rating-label.checked > .svg-icon svg [fill]:not(.permanent):not(g),
label.rating-label > .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffad0f;
}
div.rating-label.checked > .svg-icon svg:hover [fill]:not(.permanent):not(g),
label.rating-label > .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.rating-input:checked ~ .rating-label > i {
  color: #b5b5c3;
}

.rating-input:checked ~ .rating-label > .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.rating-input:checked ~ .rating-label > .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.rating:hover label.rating-label > i {
  color: #ffad0f;
}

.rating:hover label.rating-label > .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffad0f;
}
.rating:hover label.rating-label > .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

label.rating-label:hover ~ .rating-label > i {
  color: #b5b5c3;
}

label.rating-label:hover ~ .rating-label > .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
label.rating-label:hover ~ .rating-label > .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

@media (min-width: 992px) {
  span,
ol,
ul,
pre,
div {
    scrollbar-width: thin;
  }
  span::-webkit-scrollbar,
ol::-webkit-scrollbar,
ul::-webkit-scrollbar,
pre::-webkit-scrollbar,
div::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }
  .light-theme span,
.light-theme ol,
.light-theme ul,
.light-theme pre,
.light-theme div {
    scrollbar-color: #eff2f5 transparent;
  }
  .light-theme span::-webkit-scrollbar-thumb,
.light-theme ol::-webkit-scrollbar-thumb,
.light-theme ul::-webkit-scrollbar-thumb,
.light-theme pre::-webkit-scrollbar-thumb,
.light-theme div::-webkit-scrollbar-thumb {
    background-color: #eff2f5;
  }
  .dark-theme span,
.dark-theme ol,
.dark-theme ul,
.dark-theme pre,
.dark-theme div {
    scrollbar-color: #3f4254 transparent;
  }
  .dark-theme span::-webkit-scrollbar-thumb,
.dark-theme ol::-webkit-scrollbar-thumb,
.dark-theme ul::-webkit-scrollbar-thumb,
.dark-theme pre::-webkit-scrollbar-thumb,
.dark-theme div::-webkit-scrollbar-thumb {
    background-color: #3f4254;
  }
}
.scroll {
  overflow: scroll;
  position: relative;
}
@media (max-width: 991.98px) {
  .scroll {
    overflow: auto;
  }
}

.scroll-x {
  overflow-x: scroll;
  position: relative;
}
@media (max-width: 991.98px) {
  .scroll-x {
    overflow-x: auto;
  }
}

.scroll-y {
  overflow-y: overlay;
  position: relative;
}
@media (max-width: 991.98px) {
  .scroll-y {
    overflow-y: auto;
  }
}

.hover-scroll {
  position: relative;
}
@media (min-width: 992px) {
  .hover-scroll {
    overflow: hidden;
    border-right: 0.4rem solid transparent;
    border-bottom: 0.4rem solid transparent;
    margin-right: -0.4rem;
    margin-bottom: -0.4rem;
  }
  .hover-scroll:hover {
    overflow: scroll;
    border-right: 0;
    border-bottom: 0;
  }
  @-moz-document url-prefix() {
    .hover-scroll {
      overflow: scroll;
      position: relative;
      border-right: 0;
      border-bottom: 0;
    }
  }
}
@media (max-width: 991.98px) {
  .hover-scroll {
    overflow: auto;
  }
}

.hover-scroll-y {
  position: relative;
}
@media (min-width: 992px) {
  .hover-scroll-y {
    overflow-y: hidden;
    border-right: 0.4rem solid transparent;
    margin-right: -0.4rem;
  }
  .hover-scroll-y:hover {
    overflow-y: scroll;
    border-right: 0;
  }
  @-moz-document url-prefix() {
    .hover-scroll-y {
      overflow-y: scroll;
      position: relative;
      border-right: 0;
    }
  }
}
@media (max-width: 991.98px) {
  .hover-scroll-y {
    overflow-y: auto;
  }
}

.hover-scroll-x {
  position: relative;
}
@media (min-width: 992px) {
  .hover-scroll-x {
    overflow-x: hidden;
    border-bottom: 0.4rem solid transparent;
  }
  .hover-scroll-x:hover {
    overflow-x: scroll;
    border-bottom: 0;
  }
  @-moz-document url-prefix() {
    .hover-scroll-x {
      overflow-x: scroll;
      position: relative;
      border-bottom: 0;
    }
  }
}
@media (max-width: 991.98px) {
  .hover-scroll-x {
    overflow-x: auto;
  }
}

.hover-scroll-overlay-y {
  overflow-y: hidden;
  position: relative;
  --scrollbar-space: 0.25rem;
}
.hover-scroll-overlay-y::-webkit-scrollbar {
  width: calc(0.4rem + var(--scrollbar-space));
}
.hover-scroll-overlay-y::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-right: var(--scrollbar-space) solid transparent;
}
.hover-scroll-overlay-y:hover {
  overflow-y: overlay;
}
@-moz-document url-prefix() {
  .hover-scroll-overlay-y {
    overflow-y: scroll;
    position: relative;
  }
}

.scrolltop {
  display: none;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  position: fixed;
  bottom: 40px;
  right: 20px;
  cursor: pointer;
  z-index: 100;
  background-color: #e10e8d;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.05);
  opacity: 0;
  transition: color 0.2s ease, background-color 0.2s ease;
  border-radius: 0.475rem !important;
}
.scrolltop .svg-icon svg {
  height: 24px;
  width: 24px;
}
.scrolltop .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.scrolltop .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.scrolltop > i {
  font-size: 1.3rem;
  color: #ffffff;
}
.scrolltop:hover > i {
  color: #ffffff;
}
.scrolltop:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.scrolltop:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
[scrolltop=on] .scrolltop {
  opacity: 0.3;
  animation: animation-scrolltop 0.4s ease-out 1;
  display: flex;
}
[scrolltop=on] .scrolltop:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  opacity: 1;
}
@media (max-width: 991.98px) {
  .scrolltop {
    bottom: 30px;
    right: 15px;
    width: 30px;
    height: 30px;
  }
}

@keyframes animation-scrolltop {
  from {
    margin-bottom: -15px;
  }
  to {
    margin-bottom: 0;
  }
}
.separator {
  display: block;
  height: 0;
  border-bottom: 1px solid var(--gray-200);
}
.light-theme .separator {
  border-bottom: 1px solid #eff2f5;
}
.dark-theme .separator {
  border-bottom: 1px solid #3f4254;
}
.separator.separator-dotted {
  border-bottom-style: dotted;
  border-bottom-color: var(--gray-300);
}
.light-theme .separator.separator-dotted {
  border-bottom-color: #e4e6ef;
}
.dark-theme .separator.separator-dotted {
  border-bottom-color: #5e6278;
}
.separator.separator-dashed {
  border-bottom-style: dashed;
  border-bottom-color: var(--gray-300);
}
.light-theme .separator.separator-dashed {
  border-bottom-color: #e4e6ef;
}
.dark-theme .separator.separator-dashed {
  border-bottom-color: #5e6278;
}

.table {
  width: 100%;
}
.light-theme .table {
  color: #323248 !important;
}
.dark-theme .table {
  color: #f5f8fa !important;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: inherit;
}
.table > :not(:first-child) {
  border-top: 0px solid currentColor !important;
}
.table tr,
.table th,
.table td {
  border-color: inherit;
  border-width: inherit;
  border-style: inherit;
  text-transform: inherit;
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  height: inherit;
  min-height: inherit;
  background-color: #ffffff;
  z-index: 2 !important;
}
.table tr:first-child,
.table th:first-child,
.table td:first-child {
  padding-left: 0;
}
.table tr:last-child,
.table th:last-child,
.table td:last-child {
  padding-right: 0;
}
.table tfoot tr:last-child,
.table tbody tr:last-child {
  border-bottom: 0 !important;
}
.table tfoot tr:last-child th,
.table tfoot tr:last-child td,
.table tbody tr:last-child th,
.table tbody tr:last-child td {
  border-bottom: 0 !important;
}
.table tfoot th,
.table tfoot td {
  border-top: inherit;
}
.table.table-hover tbody tr:hover {
  --bs-table-hover-bg: none;
}
.light-theme .table.table-hover tbody tr:hover {
  background-color: #eff2f5 !important;
}
.dark-theme .table.table-hover tbody tr:hover {
  background-color: #3f4254 !important;
}
.table.table-rounded {
  border-radius: 0.475rem;
  border-spacing: 0;
  border-collapse: separate;
}
.table.table-flush tr,
.table.table-flush th,
.table.table-flush td {
  padding: inherit;
}
.table.table-row-bordered tr {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #eff2f5;
}
.table.table-row-bordered tfoot th,
.table.table-row-bordered tfoot td {
  border-top-width: 1px !important;
}
.table.table-row-dashed tr {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  border-bottom-color: #eff2f5;
}
.table.table-row-dashed tfoot th,
.table.table-row-dashed tfoot td {
  border-top-width: 1px !important;
}
.table.table-row-gray-100 tr {
  border-bottom-color: #f5f8fa;
}
.table.table-row-gray-200 tr {
  border-bottom-color: #eff2f5;
}
.table.table-row-gray-300 tr {
  border-bottom-color: #e4e6ef;
}
.table.table-row-gray-400 tr {
  border-bottom-color: #b5b5c3;
}
.table.table-row-gray-500 tr {
  border-bottom-color: #a1a5b7;
}
.table.table-row-gray-600 tr {
  border-bottom-color: #7e8299;
}
.table.table-row-gray-700 tr {
  border-bottom-color: #5e6278;
}
.table.table-row-gray-800 tr {
  border-bottom-color: #3f4254;
}
.table.table-row-gray-900 tr {
  border-bottom-color: #181c32;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: none;
}
.light-theme .table-striped > tbody > tr:nth-of-type(odd) {
  color: #323248 !important;
  background-color: #f5f8fa !important;
}
.dark-theme .table-striped > tbody > tr:nth-of-type(odd) {
  color: #f5f8fa !important;
  background-color: #323248 !important;
}

.table-fixed {
  overflow: auto;
}
@media (min-width: 992px) {
  .table-fixed {
    height: calc(100vh - (65px + (30px * 2)));
  }
}
@media (max-width: 991.98px) {
  .table-fixed {
    height: calc(100vh - (55px + (15px * 2)));
  }
}
.table-fixed table {
  border-collapse: collapse;
  width: 100%;
}
.table-fixed table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table.g-0 th,
.table.g-0 td {
  padding: 0;
}

.table.gy-0 th,
.table.gy-0 td {
  padding-top: 0;
  padding-bottom: 0;
}

.table.gx-0 th,
.table.gx-0 td {
  padding-left: 0;
  padding-right: 0;
}

.table.gs-0 th:first-child,
.table.gs-0 td:first-child {
  padding-left: 0;
}
.table.gs-0 th:last-child,
.table.gs-0 td:last-child {
  padding-right: 0;
}

.table.g-1 th,
.table.g-1 td {
  padding: 0.25rem;
}

.table.gy-1 th,
.table.gy-1 td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.table.gx-1 th,
.table.gx-1 td {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.table.gs-1 th:first-child,
.table.gs-1 td:first-child {
  padding-left: 0.25rem;
}
.table.gs-1 th:last-child,
.table.gs-1 td:last-child {
  padding-right: 0.25rem;
}

.table.g-2 th,
.table.g-2 td {
  padding: 0.5rem;
}

.table.gy-2 th,
.table.gy-2 td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.table.gx-2 th,
.table.gx-2 td {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.table.gs-2 th:first-child,
.table.gs-2 td:first-child {
  padding-left: 0.5rem;
}
.table.gs-2 th:last-child,
.table.gs-2 td:last-child {
  padding-right: 0.5rem;
}

.table.g-3 th,
.table.g-3 td {
  padding: 0.75rem;
}

.table.gy-3 th,
.table.gy-3 td {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.table.gx-3 th,
.table.gx-3 td {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.table.gs-3 th:first-child,
.table.gs-3 td:first-child {
  padding-left: 0.75rem;
}
.table.gs-3 th:last-child,
.table.gs-3 td:last-child {
  padding-right: 0.75rem;
}

.table.g-4 th,
.table.g-4 td {
  padding: 1rem;
}

.table.gy-4 th,
.table.gy-4 td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.table.gx-4 th,
.table.gx-4 td {
  padding-left: 1rem;
  padding-right: 1rem;
}

.table.gs-4 th:first-child,
.table.gs-4 td:first-child {
  padding-left: 1rem;
}
.table.gs-4 th:last-child,
.table.gs-4 td:last-child {
  padding-right: 1rem;
}

.table.g-5 th,
.table.g-5 td {
  padding: 1.25rem;
}

.table.gy-5 th,
.table.gy-5 td {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.table.gx-5 th,
.table.gx-5 td {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.table.gs-5 th:first-child,
.table.gs-5 td:first-child {
  padding-left: 1.25rem;
}
.table.gs-5 th:last-child,
.table.gs-5 td:last-child {
  padding-right: 1.25rem;
}

.table.g-6 th,
.table.g-6 td {
  padding: 1.5rem;
}

.table.gy-6 th,
.table.gy-6 td {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.table.gx-6 th,
.table.gx-6 td {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.table.gs-6 th:first-child,
.table.gs-6 td:first-child {
  padding-left: 1.5rem;
}
.table.gs-6 th:last-child,
.table.gs-6 td:last-child {
  padding-right: 1.5rem;
}

.table.g-7 th,
.table.g-7 td {
  padding: 1.75rem;
}

.table.gy-7 th,
.table.gy-7 td {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.table.gx-7 th,
.table.gx-7 td {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.table.gs-7 th:first-child,
.table.gs-7 td:first-child {
  padding-left: 1.75rem;
}
.table.gs-7 th:last-child,
.table.gs-7 td:last-child {
  padding-right: 1.75rem;
}

.table.g-8 th,
.table.g-8 td {
  padding: 2rem;
}

.table.gy-8 th,
.table.gy-8 td {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.table.gx-8 th,
.table.gx-8 td {
  padding-left: 2rem;
  padding-right: 2rem;
}

.table.gs-8 th:first-child,
.table.gs-8 td:first-child {
  padding-left: 2rem;
}
.table.gs-8 th:last-child,
.table.gs-8 td:last-child {
  padding-right: 2rem;
}

.table.g-9 th,
.table.g-9 td {
  padding: 2.25rem;
}

.table.gy-9 th,
.table.gy-9 td {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.table.gx-9 th,
.table.gx-9 td {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.table.gs-9 th:first-child,
.table.gs-9 td:first-child {
  padding-left: 2.25rem;
}
.table.gs-9 th:last-child,
.table.gs-9 td:last-child {
  padding-right: 2.25rem;
}

.table.g-10 th,
.table.g-10 td {
  padding: 2.5rem;
}

.table.gy-10 th,
.table.gy-10 td {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.table.gx-10 th,
.table.gx-10 td {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.table.gs-10 th:first-child,
.table.gs-10 td:first-child {
  padding-left: 2.5rem;
}
.table.gs-10 th:last-child,
.table.gs-10 td:last-child {
  padding-right: 2.5rem;
}

@media (min-width: 576px) {
  .table.g-sm-0 th,
.table.g-sm-0 td {
    padding: 0;
  }

  .table.gy-sm-0 th,
.table.gy-sm-0 td {
    padding-top: 0;
    padding-bottom: 0;
  }

  .table.gx-sm-0 th,
.table.gx-sm-0 td {
    padding-left: 0;
    padding-right: 0;
  }

  .table.gs-sm-0 th:first-child,
.table.gs-sm-0 td:first-child {
    padding-left: 0;
  }
  .table.gs-sm-0 th:last-child,
.table.gs-sm-0 td:last-child {
    padding-right: 0;
  }

  .table.g-sm-1 th,
.table.g-sm-1 td {
    padding: 0.25rem;
  }

  .table.gy-sm-1 th,
.table.gy-sm-1 td {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .table.gx-sm-1 th,
.table.gx-sm-1 td {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .table.gs-sm-1 th:first-child,
.table.gs-sm-1 td:first-child {
    padding-left: 0.25rem;
  }
  .table.gs-sm-1 th:last-child,
.table.gs-sm-1 td:last-child {
    padding-right: 0.25rem;
  }

  .table.g-sm-2 th,
.table.g-sm-2 td {
    padding: 0.5rem;
  }

  .table.gy-sm-2 th,
.table.gy-sm-2 td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .table.gx-sm-2 th,
.table.gx-sm-2 td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .table.gs-sm-2 th:first-child,
.table.gs-sm-2 td:first-child {
    padding-left: 0.5rem;
  }
  .table.gs-sm-2 th:last-child,
.table.gs-sm-2 td:last-child {
    padding-right: 0.5rem;
  }

  .table.g-sm-3 th,
.table.g-sm-3 td {
    padding: 0.75rem;
  }

  .table.gy-sm-3 th,
.table.gy-sm-3 td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .table.gx-sm-3 th,
.table.gx-sm-3 td {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .table.gs-sm-3 th:first-child,
.table.gs-sm-3 td:first-child {
    padding-left: 0.75rem;
  }
  .table.gs-sm-3 th:last-child,
.table.gs-sm-3 td:last-child {
    padding-right: 0.75rem;
  }

  .table.g-sm-4 th,
.table.g-sm-4 td {
    padding: 1rem;
  }

  .table.gy-sm-4 th,
.table.gy-sm-4 td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .table.gx-sm-4 th,
.table.gx-sm-4 td {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .table.gs-sm-4 th:first-child,
.table.gs-sm-4 td:first-child {
    padding-left: 1rem;
  }
  .table.gs-sm-4 th:last-child,
.table.gs-sm-4 td:last-child {
    padding-right: 1rem;
  }

  .table.g-sm-5 th,
.table.g-sm-5 td {
    padding: 1.25rem;
  }

  .table.gy-sm-5 th,
.table.gy-sm-5 td {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .table.gx-sm-5 th,
.table.gx-sm-5 td {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .table.gs-sm-5 th:first-child,
.table.gs-sm-5 td:first-child {
    padding-left: 1.25rem;
  }
  .table.gs-sm-5 th:last-child,
.table.gs-sm-5 td:last-child {
    padding-right: 1.25rem;
  }

  .table.g-sm-6 th,
.table.g-sm-6 td {
    padding: 1.5rem;
  }

  .table.gy-sm-6 th,
.table.gy-sm-6 td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .table.gx-sm-6 th,
.table.gx-sm-6 td {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .table.gs-sm-6 th:first-child,
.table.gs-sm-6 td:first-child {
    padding-left: 1.5rem;
  }
  .table.gs-sm-6 th:last-child,
.table.gs-sm-6 td:last-child {
    padding-right: 1.5rem;
  }

  .table.g-sm-7 th,
.table.g-sm-7 td {
    padding: 1.75rem;
  }

  .table.gy-sm-7 th,
.table.gy-sm-7 td {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .table.gx-sm-7 th,
.table.gx-sm-7 td {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .table.gs-sm-7 th:first-child,
.table.gs-sm-7 td:first-child {
    padding-left: 1.75rem;
  }
  .table.gs-sm-7 th:last-child,
.table.gs-sm-7 td:last-child {
    padding-right: 1.75rem;
  }

  .table.g-sm-8 th,
.table.g-sm-8 td {
    padding: 2rem;
  }

  .table.gy-sm-8 th,
.table.gy-sm-8 td {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .table.gx-sm-8 th,
.table.gx-sm-8 td {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .table.gs-sm-8 th:first-child,
.table.gs-sm-8 td:first-child {
    padding-left: 2rem;
  }
  .table.gs-sm-8 th:last-child,
.table.gs-sm-8 td:last-child {
    padding-right: 2rem;
  }

  .table.g-sm-9 th,
.table.g-sm-9 td {
    padding: 2.25rem;
  }

  .table.gy-sm-9 th,
.table.gy-sm-9 td {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .table.gx-sm-9 th,
.table.gx-sm-9 td {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .table.gs-sm-9 th:first-child,
.table.gs-sm-9 td:first-child {
    padding-left: 2.25rem;
  }
  .table.gs-sm-9 th:last-child,
.table.gs-sm-9 td:last-child {
    padding-right: 2.25rem;
  }

  .table.g-sm-10 th,
.table.g-sm-10 td {
    padding: 2.5rem;
  }

  .table.gy-sm-10 th,
.table.gy-sm-10 td {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .table.gx-sm-10 th,
.table.gx-sm-10 td {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .table.gs-sm-10 th:first-child,
.table.gs-sm-10 td:first-child {
    padding-left: 2.5rem;
  }
  .table.gs-sm-10 th:last-child,
.table.gs-sm-10 td:last-child {
    padding-right: 2.5rem;
  }
}
@media (min-width: 768px) {
  .table.g-md-0 th,
.table.g-md-0 td {
    padding: 0;
  }

  .table.gy-md-0 th,
.table.gy-md-0 td {
    padding-top: 0;
    padding-bottom: 0;
  }

  .table.gx-md-0 th,
.table.gx-md-0 td {
    padding-left: 0;
    padding-right: 0;
  }

  .table.gs-md-0 th:first-child,
.table.gs-md-0 td:first-child {
    padding-left: 0;
  }
  .table.gs-md-0 th:last-child,
.table.gs-md-0 td:last-child {
    padding-right: 0;
  }

  .table.g-md-1 th,
.table.g-md-1 td {
    padding: 0.25rem;
  }

  .table.gy-md-1 th,
.table.gy-md-1 td {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .table.gx-md-1 th,
.table.gx-md-1 td {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .table.gs-md-1 th:first-child,
.table.gs-md-1 td:first-child {
    padding-left: 0.25rem;
  }
  .table.gs-md-1 th:last-child,
.table.gs-md-1 td:last-child {
    padding-right: 0.25rem;
  }

  .table.g-md-2 th,
.table.g-md-2 td {
    padding: 0.5rem;
  }

  .table.gy-md-2 th,
.table.gy-md-2 td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .table.gx-md-2 th,
.table.gx-md-2 td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .table.gs-md-2 th:first-child,
.table.gs-md-2 td:first-child {
    padding-left: 0.5rem;
  }
  .table.gs-md-2 th:last-child,
.table.gs-md-2 td:last-child {
    padding-right: 0.5rem;
  }

  .table.g-md-3 th,
.table.g-md-3 td {
    padding: 0.75rem;
  }

  .table.gy-md-3 th,
.table.gy-md-3 td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .table.gx-md-3 th,
.table.gx-md-3 td {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .table.gs-md-3 th:first-child,
.table.gs-md-3 td:first-child {
    padding-left: 0.75rem;
  }
  .table.gs-md-3 th:last-child,
.table.gs-md-3 td:last-child {
    padding-right: 0.75rem;
  }

  .table.g-md-4 th,
.table.g-md-4 td {
    padding: 1rem;
  }

  .table.gy-md-4 th,
.table.gy-md-4 td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .table.gx-md-4 th,
.table.gx-md-4 td {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .table.gs-md-4 th:first-child,
.table.gs-md-4 td:first-child {
    padding-left: 1rem;
  }
  .table.gs-md-4 th:last-child,
.table.gs-md-4 td:last-child {
    padding-right: 1rem;
  }

  .table.g-md-5 th,
.table.g-md-5 td {
    padding: 1.25rem;
  }

  .table.gy-md-5 th,
.table.gy-md-5 td {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .table.gx-md-5 th,
.table.gx-md-5 td {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .table.gs-md-5 th:first-child,
.table.gs-md-5 td:first-child {
    padding-left: 1.25rem;
  }
  .table.gs-md-5 th:last-child,
.table.gs-md-5 td:last-child {
    padding-right: 1.25rem;
  }

  .table.g-md-6 th,
.table.g-md-6 td {
    padding: 1.5rem;
  }

  .table.gy-md-6 th,
.table.gy-md-6 td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .table.gx-md-6 th,
.table.gx-md-6 td {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .table.gs-md-6 th:first-child,
.table.gs-md-6 td:first-child {
    padding-left: 1.5rem;
  }
  .table.gs-md-6 th:last-child,
.table.gs-md-6 td:last-child {
    padding-right: 1.5rem;
  }

  .table.g-md-7 th,
.table.g-md-7 td {
    padding: 1.75rem;
  }

  .table.gy-md-7 th,
.table.gy-md-7 td {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .table.gx-md-7 th,
.table.gx-md-7 td {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .table.gs-md-7 th:first-child,
.table.gs-md-7 td:first-child {
    padding-left: 1.75rem;
  }
  .table.gs-md-7 th:last-child,
.table.gs-md-7 td:last-child {
    padding-right: 1.75rem;
  }

  .table.g-md-8 th,
.table.g-md-8 td {
    padding: 2rem;
  }

  .table.gy-md-8 th,
.table.gy-md-8 td {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .table.gx-md-8 th,
.table.gx-md-8 td {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .table.gs-md-8 th:first-child,
.table.gs-md-8 td:first-child {
    padding-left: 2rem;
  }
  .table.gs-md-8 th:last-child,
.table.gs-md-8 td:last-child {
    padding-right: 2rem;
  }

  .table.g-md-9 th,
.table.g-md-9 td {
    padding: 2.25rem;
  }

  .table.gy-md-9 th,
.table.gy-md-9 td {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .table.gx-md-9 th,
.table.gx-md-9 td {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .table.gs-md-9 th:first-child,
.table.gs-md-9 td:first-child {
    padding-left: 2.25rem;
  }
  .table.gs-md-9 th:last-child,
.table.gs-md-9 td:last-child {
    padding-right: 2.25rem;
  }

  .table.g-md-10 th,
.table.g-md-10 td {
    padding: 2.5rem;
  }

  .table.gy-md-10 th,
.table.gy-md-10 td {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .table.gx-md-10 th,
.table.gx-md-10 td {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .table.gs-md-10 th:first-child,
.table.gs-md-10 td:first-child {
    padding-left: 2.5rem;
  }
  .table.gs-md-10 th:last-child,
.table.gs-md-10 td:last-child {
    padding-right: 2.5rem;
  }
}
@media (min-width: 992px) {
  .table.g-lg-0 th,
.table.g-lg-0 td {
    padding: 0;
  }

  .table.gy-lg-0 th,
.table.gy-lg-0 td {
    padding-top: 0;
    padding-bottom: 0;
  }

  .table.gx-lg-0 th,
.table.gx-lg-0 td {
    padding-left: 0;
    padding-right: 0;
  }

  .table.gs-lg-0 th:first-child,
.table.gs-lg-0 td:first-child {
    padding-left: 0;
  }
  .table.gs-lg-0 th:last-child,
.table.gs-lg-0 td:last-child {
    padding-right: 0;
  }

  .table.g-lg-1 th,
.table.g-lg-1 td {
    padding: 0.25rem;
  }

  .table.gy-lg-1 th,
.table.gy-lg-1 td {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .table.gx-lg-1 th,
.table.gx-lg-1 td {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .table.gs-lg-1 th:first-child,
.table.gs-lg-1 td:first-child {
    padding-left: 0.25rem;
  }
  .table.gs-lg-1 th:last-child,
.table.gs-lg-1 td:last-child {
    padding-right: 0.25rem;
  }

  .table.g-lg-2 th,
.table.g-lg-2 td {
    padding: 0.5rem;
  }

  .table.gy-lg-2 th,
.table.gy-lg-2 td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .table.gx-lg-2 th,
.table.gx-lg-2 td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .table.gs-lg-2 th:first-child,
.table.gs-lg-2 td:first-child {
    padding-left: 0.5rem;
  }
  .table.gs-lg-2 th:last-child,
.table.gs-lg-2 td:last-child {
    padding-right: 0.5rem;
  }

  .table.g-lg-3 th,
.table.g-lg-3 td {
    padding: 0.75rem;
  }

  .table.gy-lg-3 th,
.table.gy-lg-3 td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .table.gx-lg-3 th,
.table.gx-lg-3 td {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .table.gs-lg-3 th:first-child,
.table.gs-lg-3 td:first-child {
    padding-left: 0.75rem;
  }
  .table.gs-lg-3 th:last-child,
.table.gs-lg-3 td:last-child {
    padding-right: 0.75rem;
  }

  .table.g-lg-4 th,
.table.g-lg-4 td {
    padding: 1rem;
  }

  .table.gy-lg-4 th,
.table.gy-lg-4 td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .table.gx-lg-4 th,
.table.gx-lg-4 td {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .table.gs-lg-4 th:first-child,
.table.gs-lg-4 td:first-child {
    padding-left: 1rem;
  }
  .table.gs-lg-4 th:last-child,
.table.gs-lg-4 td:last-child {
    padding-right: 1rem;
  }

  .table.g-lg-5 th,
.table.g-lg-5 td {
    padding: 1.25rem;
  }

  .table.gy-lg-5 th,
.table.gy-lg-5 td {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .table.gx-lg-5 th,
.table.gx-lg-5 td {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .table.gs-lg-5 th:first-child,
.table.gs-lg-5 td:first-child {
    padding-left: 1.25rem;
  }
  .table.gs-lg-5 th:last-child,
.table.gs-lg-5 td:last-child {
    padding-right: 1.25rem;
  }

  .table.g-lg-6 th,
.table.g-lg-6 td {
    padding: 1.5rem;
  }

  .table.gy-lg-6 th,
.table.gy-lg-6 td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .table.gx-lg-6 th,
.table.gx-lg-6 td {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .table.gs-lg-6 th:first-child,
.table.gs-lg-6 td:first-child {
    padding-left: 1.5rem;
  }
  .table.gs-lg-6 th:last-child,
.table.gs-lg-6 td:last-child {
    padding-right: 1.5rem;
  }

  .table.g-lg-7 th,
.table.g-lg-7 td {
    padding: 1.75rem;
  }

  .table.gy-lg-7 th,
.table.gy-lg-7 td {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .table.gx-lg-7 th,
.table.gx-lg-7 td {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .table.gs-lg-7 th:first-child,
.table.gs-lg-7 td:first-child {
    padding-left: 1.75rem;
  }
  .table.gs-lg-7 th:last-child,
.table.gs-lg-7 td:last-child {
    padding-right: 1.75rem;
  }

  .table.g-lg-8 th,
.table.g-lg-8 td {
    padding: 2rem;
  }

  .table.gy-lg-8 th,
.table.gy-lg-8 td {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .table.gx-lg-8 th,
.table.gx-lg-8 td {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .table.gs-lg-8 th:first-child,
.table.gs-lg-8 td:first-child {
    padding-left: 2rem;
  }
  .table.gs-lg-8 th:last-child,
.table.gs-lg-8 td:last-child {
    padding-right: 2rem;
  }

  .table.g-lg-9 th,
.table.g-lg-9 td {
    padding: 2.25rem;
  }

  .table.gy-lg-9 th,
.table.gy-lg-9 td {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .table.gx-lg-9 th,
.table.gx-lg-9 td {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .table.gs-lg-9 th:first-child,
.table.gs-lg-9 td:first-child {
    padding-left: 2.25rem;
  }
  .table.gs-lg-9 th:last-child,
.table.gs-lg-9 td:last-child {
    padding-right: 2.25rem;
  }

  .table.g-lg-10 th,
.table.g-lg-10 td {
    padding: 2.5rem;
  }

  .table.gy-lg-10 th,
.table.gy-lg-10 td {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .table.gx-lg-10 th,
.table.gx-lg-10 td {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .table.gs-lg-10 th:first-child,
.table.gs-lg-10 td:first-child {
    padding-left: 2.5rem;
  }
  .table.gs-lg-10 th:last-child,
.table.gs-lg-10 td:last-child {
    padding-right: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .table.g-xl-0 th,
.table.g-xl-0 td {
    padding: 0;
  }

  .table.gy-xl-0 th,
.table.gy-xl-0 td {
    padding-top: 0;
    padding-bottom: 0;
  }

  .table.gx-xl-0 th,
.table.gx-xl-0 td {
    padding-left: 0;
    padding-right: 0;
  }

  .table.gs-xl-0 th:first-child,
.table.gs-xl-0 td:first-child {
    padding-left: 0;
  }
  .table.gs-xl-0 th:last-child,
.table.gs-xl-0 td:last-child {
    padding-right: 0;
  }

  .table.g-xl-1 th,
.table.g-xl-1 td {
    padding: 0.25rem;
  }

  .table.gy-xl-1 th,
.table.gy-xl-1 td {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .table.gx-xl-1 th,
.table.gx-xl-1 td {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .table.gs-xl-1 th:first-child,
.table.gs-xl-1 td:first-child {
    padding-left: 0.25rem;
  }
  .table.gs-xl-1 th:last-child,
.table.gs-xl-1 td:last-child {
    padding-right: 0.25rem;
  }

  .table.g-xl-2 th,
.table.g-xl-2 td {
    padding: 0.5rem;
  }

  .table.gy-xl-2 th,
.table.gy-xl-2 td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .table.gx-xl-2 th,
.table.gx-xl-2 td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .table.gs-xl-2 th:first-child,
.table.gs-xl-2 td:first-child {
    padding-left: 0.5rem;
  }
  .table.gs-xl-2 th:last-child,
.table.gs-xl-2 td:last-child {
    padding-right: 0.5rem;
  }

  .table.g-xl-3 th,
.table.g-xl-3 td {
    padding: 0.75rem;
  }

  .table.gy-xl-3 th,
.table.gy-xl-3 td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .table.gx-xl-3 th,
.table.gx-xl-3 td {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .table.gs-xl-3 th:first-child,
.table.gs-xl-3 td:first-child {
    padding-left: 0.75rem;
  }
  .table.gs-xl-3 th:last-child,
.table.gs-xl-3 td:last-child {
    padding-right: 0.75rem;
  }

  .table.g-xl-4 th,
.table.g-xl-4 td {
    padding: 1rem;
  }

  .table.gy-xl-4 th,
.table.gy-xl-4 td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .table.gx-xl-4 th,
.table.gx-xl-4 td {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .table.gs-xl-4 th:first-child,
.table.gs-xl-4 td:first-child {
    padding-left: 1rem;
  }
  .table.gs-xl-4 th:last-child,
.table.gs-xl-4 td:last-child {
    padding-right: 1rem;
  }

  .table.g-xl-5 th,
.table.g-xl-5 td {
    padding: 1.25rem;
  }

  .table.gy-xl-5 th,
.table.gy-xl-5 td {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .table.gx-xl-5 th,
.table.gx-xl-5 td {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .table.gs-xl-5 th:first-child,
.table.gs-xl-5 td:first-child {
    padding-left: 1.25rem;
  }
  .table.gs-xl-5 th:last-child,
.table.gs-xl-5 td:last-child {
    padding-right: 1.25rem;
  }

  .table.g-xl-6 th,
.table.g-xl-6 td {
    padding: 1.5rem;
  }

  .table.gy-xl-6 th,
.table.gy-xl-6 td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .table.gx-xl-6 th,
.table.gx-xl-6 td {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .table.gs-xl-6 th:first-child,
.table.gs-xl-6 td:first-child {
    padding-left: 1.5rem;
  }
  .table.gs-xl-6 th:last-child,
.table.gs-xl-6 td:last-child {
    padding-right: 1.5rem;
  }

  .table.g-xl-7 th,
.table.g-xl-7 td {
    padding: 1.75rem;
  }

  .table.gy-xl-7 th,
.table.gy-xl-7 td {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .table.gx-xl-7 th,
.table.gx-xl-7 td {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .table.gs-xl-7 th:first-child,
.table.gs-xl-7 td:first-child {
    padding-left: 1.75rem;
  }
  .table.gs-xl-7 th:last-child,
.table.gs-xl-7 td:last-child {
    padding-right: 1.75rem;
  }

  .table.g-xl-8 th,
.table.g-xl-8 td {
    padding: 2rem;
  }

  .table.gy-xl-8 th,
.table.gy-xl-8 td {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .table.gx-xl-8 th,
.table.gx-xl-8 td {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .table.gs-xl-8 th:first-child,
.table.gs-xl-8 td:first-child {
    padding-left: 2rem;
  }
  .table.gs-xl-8 th:last-child,
.table.gs-xl-8 td:last-child {
    padding-right: 2rem;
  }

  .table.g-xl-9 th,
.table.g-xl-9 td {
    padding: 2.25rem;
  }

  .table.gy-xl-9 th,
.table.gy-xl-9 td {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .table.gx-xl-9 th,
.table.gx-xl-9 td {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .table.gs-xl-9 th:first-child,
.table.gs-xl-9 td:first-child {
    padding-left: 2.25rem;
  }
  .table.gs-xl-9 th:last-child,
.table.gs-xl-9 td:last-child {
    padding-right: 2.25rem;
  }

  .table.g-xl-10 th,
.table.g-xl-10 td {
    padding: 2.5rem;
  }

  .table.gy-xl-10 th,
.table.gy-xl-10 td {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .table.gx-xl-10 th,
.table.gx-xl-10 td {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .table.gs-xl-10 th:first-child,
.table.gs-xl-10 td:first-child {
    padding-left: 2.5rem;
  }
  .table.gs-xl-10 th:last-child,
.table.gs-xl-10 td:last-child {
    padding-right: 2.5rem;
  }
}
@media (min-width: 1400px) {
  .table.g-xxl-0 th,
.table.g-xxl-0 td {
    padding: 0;
  }

  .table.gy-xxl-0 th,
.table.gy-xxl-0 td {
    padding-top: 0;
    padding-bottom: 0;
  }

  .table.gx-xxl-0 th,
.table.gx-xxl-0 td {
    padding-left: 0;
    padding-right: 0;
  }

  .table.gs-xxl-0 th:first-child,
.table.gs-xxl-0 td:first-child {
    padding-left: 0;
  }
  .table.gs-xxl-0 th:last-child,
.table.gs-xxl-0 td:last-child {
    padding-right: 0;
  }

  .table.g-xxl-1 th,
.table.g-xxl-1 td {
    padding: 0.25rem;
  }

  .table.gy-xxl-1 th,
.table.gy-xxl-1 td {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .table.gx-xxl-1 th,
.table.gx-xxl-1 td {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .table.gs-xxl-1 th:first-child,
.table.gs-xxl-1 td:first-child {
    padding-left: 0.25rem;
  }
  .table.gs-xxl-1 th:last-child,
.table.gs-xxl-1 td:last-child {
    padding-right: 0.25rem;
  }

  .table.g-xxl-2 th,
.table.g-xxl-2 td {
    padding: 0.5rem;
  }

  .table.gy-xxl-2 th,
.table.gy-xxl-2 td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .table.gx-xxl-2 th,
.table.gx-xxl-2 td {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .table.gs-xxl-2 th:first-child,
.table.gs-xxl-2 td:first-child {
    padding-left: 0.5rem;
  }
  .table.gs-xxl-2 th:last-child,
.table.gs-xxl-2 td:last-child {
    padding-right: 0.5rem;
  }

  .table.g-xxl-3 th,
.table.g-xxl-3 td {
    padding: 0.75rem;
  }

  .table.gy-xxl-3 th,
.table.gy-xxl-3 td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .table.gx-xxl-3 th,
.table.gx-xxl-3 td {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .table.gs-xxl-3 th:first-child,
.table.gs-xxl-3 td:first-child {
    padding-left: 0.75rem;
  }
  .table.gs-xxl-3 th:last-child,
.table.gs-xxl-3 td:last-child {
    padding-right: 0.75rem;
  }

  .table.g-xxl-4 th,
.table.g-xxl-4 td {
    padding: 1rem;
  }

  .table.gy-xxl-4 th,
.table.gy-xxl-4 td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .table.gx-xxl-4 th,
.table.gx-xxl-4 td {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .table.gs-xxl-4 th:first-child,
.table.gs-xxl-4 td:first-child {
    padding-left: 1rem;
  }
  .table.gs-xxl-4 th:last-child,
.table.gs-xxl-4 td:last-child {
    padding-right: 1rem;
  }

  .table.g-xxl-5 th,
.table.g-xxl-5 td {
    padding: 1.25rem;
  }

  .table.gy-xxl-5 th,
.table.gy-xxl-5 td {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .table.gx-xxl-5 th,
.table.gx-xxl-5 td {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .table.gs-xxl-5 th:first-child,
.table.gs-xxl-5 td:first-child {
    padding-left: 1.25rem;
  }
  .table.gs-xxl-5 th:last-child,
.table.gs-xxl-5 td:last-child {
    padding-right: 1.25rem;
  }

  .table.g-xxl-6 th,
.table.g-xxl-6 td {
    padding: 1.5rem;
  }

  .table.gy-xxl-6 th,
.table.gy-xxl-6 td {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .table.gx-xxl-6 th,
.table.gx-xxl-6 td {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .table.gs-xxl-6 th:first-child,
.table.gs-xxl-6 td:first-child {
    padding-left: 1.5rem;
  }
  .table.gs-xxl-6 th:last-child,
.table.gs-xxl-6 td:last-child {
    padding-right: 1.5rem;
  }

  .table.g-xxl-7 th,
.table.g-xxl-7 td {
    padding: 1.75rem;
  }

  .table.gy-xxl-7 th,
.table.gy-xxl-7 td {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .table.gx-xxl-7 th,
.table.gx-xxl-7 td {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .table.gs-xxl-7 th:first-child,
.table.gs-xxl-7 td:first-child {
    padding-left: 1.75rem;
  }
  .table.gs-xxl-7 th:last-child,
.table.gs-xxl-7 td:last-child {
    padding-right: 1.75rem;
  }

  .table.g-xxl-8 th,
.table.g-xxl-8 td {
    padding: 2rem;
  }

  .table.gy-xxl-8 th,
.table.gy-xxl-8 td {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .table.gx-xxl-8 th,
.table.gx-xxl-8 td {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .table.gs-xxl-8 th:first-child,
.table.gs-xxl-8 td:first-child {
    padding-left: 2rem;
  }
  .table.gs-xxl-8 th:last-child,
.table.gs-xxl-8 td:last-child {
    padding-right: 2rem;
  }

  .table.g-xxl-9 th,
.table.g-xxl-9 td {
    padding: 2.25rem;
  }

  .table.gy-xxl-9 th,
.table.gy-xxl-9 td {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .table.gx-xxl-9 th,
.table.gx-xxl-9 td {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .table.gs-xxl-9 th:first-child,
.table.gs-xxl-9 td:first-child {
    padding-left: 2.25rem;
  }
  .table.gs-xxl-9 th:last-child,
.table.gs-xxl-9 td:last-child {
    padding-right: 2.25rem;
  }

  .table.g-xxl-10 th,
.table.g-xxl-10 td {
    padding: 2.5rem;
  }

  .table.gy-xxl-10 th,
.table.gy-xxl-10 td {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .table.gx-xxl-10 th,
.table.gx-xxl-10 td {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .table.gs-xxl-10 th:first-child,
.table.gs-xxl-10 td:first-child {
    padding-left: 2.5rem;
  }
  .table.gs-xxl-10 th:last-child,
.table.gs-xxl-10 td:last-child {
    padding-right: 2.5rem;
  }
}
.timeline-label {
  position: relative;
}
.timeline-label:before {
  content: "";
  position: absolute;
  left: 63px;
  width: 3px;
  top: 0;
  bottom: 0;
  background-color: #eff2f5;
  background-color: var(--gray-200);
}
.light-theme .timeline-label:before {
  background-color: #eff2f5;
}
.dark-theme .timeline-label:before {
  background-color: #3f4254;
}
.timeline-label .timeline-item {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 1.7rem;
}
.timeline-label .timeline-item:last-child {
  margin-bottom: 0;
}
.timeline-label .timeline-label {
  width: 50px;
  flex-shrink: 0;
  position: relative;
  color: var(--gray-800);
}
.light-theme .timeline-label .timeline-label {
  color: #3f4254;
}
.dark-theme .timeline-label .timeline-label {
  color: #eff2f5;
}
.timeline-label .timeline-badge {
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  margin-top: 1px;
  margin-left: 5px;
  padding: 3px !important;
  background: var(--white);
  border: 6px solid var(--white) !important;
}
.timeline-label .timeline-badge span {
  display: block;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  background-color: var(--gray-200);
}
.light-theme .timeline-label .timeline-badge span {
  background-color: #eff2f5;
}
.dark-theme .timeline-label .timeline-badge span {
  background-color: #3f4254;
}
.timeline-label .timeline-content {
  flex-grow: 1;
}

.timeline .timeline-item {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-start;
}
.timeline .timeline-item:last-child .timeline-line {
  bottom: 100%;
}
.timeline .timeline-line {
  display: block;
  content: " ";
  justify-content: center;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translate(50%);
  border-left-width: 1px;
  border-left-style: dashed;
  border-left-color: var(--gray-300);
}
.light-theme .timeline .timeline-line {
  border-left-color: #e4e6ef;
}
.dark-theme .timeline .timeline-line {
  border-left-color: #5e6278;
}
.timeline .timeline-icon {
  z-index: 1;
  flex-shrink: 0;
  margin-right: 1rem;
}
.timeline .timeline-content {
  width: 100%;
  overflow: auto;
  margin-bottom: 1.5rem;
}
.timeline.timeline-center .timeline-item {
  align-items: center;
}
.timeline.timeline-center .timeline-item:first-child .timeline-line {
  top: 50%;
}
.timeline.timeline-center .timeline-item:last-child .timeline-line {
  bottom: 50%;
}

.tooltip .tooltip-inner {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}
.light-theme .tooltip .tooltip-inner {
  color: #2b2b40 !important;
  background-color: #ffffff !important;
}
.dark-theme .tooltip .tooltip-inner {
  color: #ffffff !important;
  background-color: #2b2b40 !important;
}
.tooltip.tooltop-auto-width .tooltip-inner {
  white-space: nowrap;
  max-width: none;
}
.tooltip.tooltip-dark .tooltip-inner {
  color: #f5f8fa;
  background-color: #3f4254;
}
.tooltip.tooltip-dark.bs-tooltip-top .tooltip-arrow:before, .tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow:before {
  border-top-color: #3f4254;
}
.tooltip.tooltip-dark.bs-tooltip-right .tooltip-arrow:before {
  border-right-color: #3f4254;
}
.tooltip.tooltip-dark.bs-tooltip-bottom .tooltip-arrow:before, .tooltip.tooltip-dark.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow:before {
  border-bottom-color: #3f4254;
}
.tooltip.tooltip-dark.bs-tooltip-left .tooltip-arrow:before {
  border-left-color: #3f4254;
}

:root {
  --#e10e8d: var(--primary);
  --white: #ffffff;
  --light: #eff2f5;
  --primary: #e10e8d;
  --light-primary: #fff1fe;
  --active-primary: #a90768;
  --inverse-primary: #ffffff;
  --secondary: #e4e6ef;
  --success: #1BC5BC;
  --light-success: rgba(27, 197, 188, 0.1);
  --active-success: #12827c;
  --inverse-success: #ffffff;
  --info: #5F5CF1;
  --light-info: rgba(95, 92, 241, 0.1);
  --active-info: #1a16eb;
  --inverse-info: #ffffff;
  --warning: #FFA800;
  --light-warning: rgba(255, 168, 0, 0.15);
  --active-warning: #b37600;
  --inverse-warning: #eff2f5;
  --danger: #F65464;
  --light-danger: rgba(246, 84, 100, 0.1);
  --active-danger: #f10d23;
  --inverse-danger: #ffffff;
  --dark: #3f4254;
  --light-dark: #eff2f5;
  --active-dark: #383b4b;
  --inverse-dark: #f5f8fa;
  --black: #000000;
  --muted: #a1a5b7;
  --blue: #3699FF;
  --light-blue: rgba(54, 153, 255, 0.1);
  --active-blue: #0073e9;
  --inverse-blue: #ffffff;
  --indigo: #6610f2;
  --light-indigo: rgba(102, 16, 242, 0.1);
  --active-indigo: #4709ac;
  --inverse-indigo: #ffffff;
  --purple: #5F5CF1;
  --light-purple: rgba(95, 92, 241, 0.1);
  --active-purple: #1a16eb;
  --inverse-purple: #ffffff;
  --pink: #d63384;
  --light-pink: rgba(214, 51, 132, 0.1);
  --active-pink: #9d205e;
  --inverse-pink: #ffffff;
  --red: #F65464;
  --light-red: rgba(246, 84, 100, 0.1);
  --active-red: #f10d23;
  --inverse-red: #ffffff;
  --orange: #fd7e14;
  --light-orange: rgba(253, 126, 20, 0.1);
  --active-orange: #c35a02;
  --inverse-orange: #ffffff;
  --yellow: #FFA800;
  --light-yellow: rgba(255, 168, 0, 0.1);
  --active-yellow: #b37600;
  --inverse-yellow: #ffffff;
  --green: #1BC5BC;
  --light-green: rgba(27, 197, 188, 0.1);
  --active-green: #12827c;
  --inverse-green: #ffffff;
  --teal: #20c997;
  --light-teal: rgba(32, 201, 151, 0.1);
  --active-teal: #158765;
  --inverse-teal: #ffffff;
  --cyan: #0dcaf0;
  --light-cyan: rgba(13, 202, 240, 0.1);
  --active-cyan: #098da7;
  --inverse-cyan: #ffffff;
  --gray: #7e8299;
  --light-gray: rgba(126, 130, 153, 0.1);
  --active-gray: #3f4254;
  --inverse-gray: #b5b5c3;
  --body-bg: #f5f8fa;
  --body-text: #151521;
  --toolbar-bg: #ffffff;
  --toolbar-text: #1a1a27;
  --focus-bg: #eef3f7;
  --tabbar-text: #171723;
  --card-bg: #ffffff;
  --card-text: #1e1e2d;
  --item-bg: #e4e6ef;
  --item-text: #1b1b29;
  --gray-100: #f5f8fa;
  --gray-200: #eff2f5;
  --gray-300: #e4e6ef;
  --gray-400: #b5b5c3;
  --gray-500: #a1a5b7;
  --gray-600: #7e8299;
  --gray-700: #5e6278;
  --gray-800: #3f4254;
  --gray-900: #181c32;
  --xs: 0;
  --sm: 576px;
  --md: 768px;
  --lg: 992px;
  --xl: 1200px;
  --xxl: 1400px;
}

.svg-illustrator {
  line-height: 1;
}
.svg-illustrator svg {
  height: 100%;
  width: 100%;
}
.svg-illustrator.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 100%;
}
.svg-illustrator.svg-illustrator-white svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ffffff;
}
.svg-illustrator.svg-illustrator-white svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-primary svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e10e8d;
}
.svg-illustrator.svg-illustrator-primary svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-secondary svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.svg-illustrator.svg-illustrator-secondary svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-light svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.svg-illustrator.svg-illustrator-light svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-success svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.svg-illustrator.svg-illustrator-success svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-info svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.svg-illustrator.svg-illustrator-info svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-warning svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.svg-illustrator.svg-illustrator-warning svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-danger svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.svg-illustrator.svg-illustrator-danger svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-dark svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.svg-illustrator.svg-illustrator-dark svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-black svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #000000;
}
.svg-illustrator.svg-illustrator-black svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-muted svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.svg-illustrator.svg-illustrator-muted svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-gray-100 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #f5f8fa;
}
.svg-illustrator.svg-illustrator-gray-100 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-gray-200 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #eff2f5;
}
.svg-illustrator.svg-illustrator-gray-200 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-gray-300 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #e4e6ef;
}
.svg-illustrator.svg-illustrator-gray-300 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-gray-400 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.svg-illustrator.svg-illustrator-gray-400 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-gray-500 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.svg-illustrator.svg-illustrator-gray-500 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-gray-600 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.svg-illustrator.svg-illustrator-gray-600 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-gray-700 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5e6278;
}
.svg-illustrator.svg-illustrator-gray-700 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-gray-800 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3f4254;
}
.svg-illustrator.svg-illustrator-gray-800 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-gray-900 svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #181c32;
}
.svg-illustrator.svg-illustrator-gray-900 svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-blue svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #3699FF;
}
.svg-illustrator.svg-illustrator-blue svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-indigo svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #6610f2;
}
.svg-illustrator.svg-illustrator-indigo svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-purple svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.svg-illustrator.svg-illustrator-purple svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-pink svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #d63384;
}
.svg-illustrator.svg-illustrator-pink svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-red svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #F65464;
}
.svg-illustrator.svg-illustrator-red svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-orange svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #fd7e14;
}
.svg-illustrator.svg-illustrator-orange svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-yellow svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #FFA800;
}
.svg-illustrator.svg-illustrator-yellow svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-green svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #1BC5BC;
}
.svg-illustrator.svg-illustrator-green svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-teal svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #20c997;
}
.svg-illustrator.svg-illustrator-teal svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-cyan svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #0dcaf0;
}
.svg-illustrator.svg-illustrator-cyan svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-gray svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.svg-illustrator.svg-illustrator-gray svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.svg-illustrator.svg-illustrator-1 svg {
  height: 1.75rem !important;
  width: 1.75rem !important;
}
.svg-illustrator.svg-illustrator-1.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 1.75rem;
}
.svg-illustrator.svg-illustrator-2 svg {
  height: 1.5rem !important;
  width: 1.5rem !important;
}
.svg-illustrator.svg-illustrator-2.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 1.5rem;
}
.svg-illustrator.svg-illustrator-3 svg {
  height: 1.35rem !important;
  width: 1.35rem !important;
}
.svg-illustrator.svg-illustrator-3.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 1.35rem;
}
.svg-illustrator.svg-illustrator-4 svg {
  height: 1.25rem !important;
  width: 1.25rem !important;
}
.svg-illustrator.svg-illustrator-4.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 1.25rem;
}
.svg-illustrator.svg-illustrator-5 svg {
  height: 1.15rem !important;
  width: 1.15rem !important;
}
.svg-illustrator.svg-illustrator-5.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 1.15rem;
}
.svg-illustrator.svg-illustrator-6 svg {
  height: 1.075rem !important;
  width: 1.075rem !important;
}
.svg-illustrator.svg-illustrator-6.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 1.075rem;
}
.svg-illustrator.svg-illustrator-7 svg {
  height: 0.95rem !important;
  width: 0.95rem !important;
}
.svg-illustrator.svg-illustrator-7.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 0.95rem;
}
.svg-illustrator.svg-illustrator-8 svg {
  height: 0.85rem !important;
  width: 0.85rem !important;
}
.svg-illustrator.svg-illustrator-8.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 0.85rem;
}
.svg-illustrator.svg-illustrator-9 svg {
  height: 0.75rem !important;
  width: 0.75rem !important;
}
.svg-illustrator.svg-illustrator-9.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 0.75rem;
}
.svg-illustrator.svg-illustrator-10 svg {
  height: 0.5rem !important;
  width: 0.5rem !important;
}
.svg-illustrator.svg-illustrator-10.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 0.5rem;
}
.svg-illustrator.svg-illustrator-base svg {
  height: 1rem !important;
  width: 1rem !important;
}
.svg-illustrator.svg-illustrator-base.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 1rem;
}
.svg-illustrator.svg-illustrator-fluid svg {
  height: 100% !important;
  width: 100% !important;
}
.svg-illustrator.svg-illustrator-fluid.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 100%;
}
.svg-illustrator.svg-illustrator-2x svg {
  height: 2rem !important;
  width: 2rem !important;
}
.svg-illustrator.svg-illustrator-2x.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 2rem;
}
.svg-illustrator.svg-illustrator-2qx svg {
  height: 2.25rem !important;
  width: 2.25rem !important;
}
.svg-illustrator.svg-illustrator-2qx.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 2.25rem;
}
.svg-illustrator.svg-illustrator-2hx svg {
  height: 2.5rem !important;
  width: 2.5rem !important;
}
.svg-illustrator.svg-illustrator-2hx.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 2.5rem;
}
.svg-illustrator.svg-illustrator-2tx svg {
  height: 2.75rem !important;
  width: 2.75rem !important;
}
.svg-illustrator.svg-illustrator-2tx.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 2.75rem;
}
.svg-illustrator.svg-illustrator-3x svg {
  height: 3rem !important;
  width: 3rem !important;
}
.svg-illustrator.svg-illustrator-3x.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 3rem;
}
.svg-illustrator.svg-illustrator-3qx svg {
  height: 3.25rem !important;
  width: 3.25rem !important;
}
.svg-illustrator.svg-illustrator-3qx.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 3.25rem;
}
.svg-illustrator.svg-illustrator-3hx svg {
  height: 3.5rem !important;
  width: 3.5rem !important;
}
.svg-illustrator.svg-illustrator-3hx.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 3.5rem;
}
.svg-illustrator.svg-illustrator-3tx svg {
  height: 3.75rem !important;
  width: 3.75rem !important;
}
.svg-illustrator.svg-illustrator-3tx.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 3.75rem;
}
.svg-illustrator.svg-illustrator-4x svg {
  height: 4rem !important;
  width: 4rem !important;
}
.svg-illustrator.svg-illustrator-4x.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 4rem;
}
.svg-illustrator.svg-illustrator-4qx svg {
  height: 4.25rem !important;
  width: 4.25rem !important;
}
.svg-illustrator.svg-illustrator-4qx.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 4.25rem;
}
.svg-illustrator.svg-illustrator-4hx svg {
  height: 4.5rem !important;
  width: 4.5rem !important;
}
.svg-illustrator.svg-illustrator-4hx.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 4.5rem;
}
.svg-illustrator.svg-illustrator-4tx svg {
  height: 4.75rem !important;
  width: 4.75rem !important;
}
.svg-illustrator.svg-illustrator-4tx.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 4.75rem;
}
.svg-illustrator.svg-illustrator-5x svg {
  height: 5rem !important;
  width: 5rem !important;
}
.svg-illustrator.svg-illustrator-5x.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 5rem;
}
.svg-illustrator.svg-illustrator-5qx svg {
  height: 5.25rem !important;
  width: 5.25rem !important;
}
.svg-illustrator.svg-illustrator-5qx.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 5.25rem;
}
.svg-illustrator.svg-illustrator-5hx svg {
  height: 5.5rem !important;
  width: 5.5rem !important;
}
.svg-illustrator.svg-illustrator-5hx.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 5.5rem;
}
.svg-illustrator.svg-illustrator-5tx svg {
  height: 5.75rem !important;
  width: 5.75rem !important;
}
.svg-illustrator.svg-illustrator-5tx.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 5.75rem;
}
.svg-illustrator.svg-illustrator-10x svg {
  height: 10rem !important;
  width: 10rem !important;
}
.svg-illustrator.svg-illustrator-10x.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 10rem;
}
.svg-illustrator.svg-illustrator-10qx svg {
  height: 10.25rem !important;
  width: 10.25rem !important;
}
.svg-illustrator.svg-illustrator-10qx.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 10.25rem;
}
.svg-illustrator.svg-illustrator-10hx svg {
  height: 10.5rem !important;
  width: 10.5rem !important;
}
.svg-illustrator.svg-illustrator-10hx.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 10.5rem;
}
.svg-illustrator.svg-illustrator-10tx svg {
  height: 10.75rem !important;
  width: 10.75rem !important;
}
.svg-illustrator.svg-illustrator-10tx.svg-4by3 svg {
  height: calc($size / divide(4, 3));
  width: 10.75rem;
}
@media (min-width: 576px) {
  .svg-illustrator.svg-illustrator-sm-1 svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-1.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.75rem;
  }
  .svg-illustrator.svg-illustrator-sm-2 svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-2.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.5rem;
  }
  .svg-illustrator.svg-illustrator-sm-3 svg {
    height: 1.35rem !important;
    width: 1.35rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-3.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.35rem;
  }
  .svg-illustrator.svg-illustrator-sm-4 svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-4.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.25rem;
  }
  .svg-illustrator.svg-illustrator-sm-5 svg {
    height: 1.15rem !important;
    width: 1.15rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-5.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.15rem;
  }
  .svg-illustrator.svg-illustrator-sm-6 svg {
    height: 1.075rem !important;
    width: 1.075rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-6.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.075rem;
  }
  .svg-illustrator.svg-illustrator-sm-7 svg {
    height: 0.95rem !important;
    width: 0.95rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-7.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.95rem;
  }
  .svg-illustrator.svg-illustrator-sm-8 svg {
    height: 0.85rem !important;
    width: 0.85rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-8.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.85rem;
  }
  .svg-illustrator.svg-illustrator-sm-9 svg {
    height: 0.75rem !important;
    width: 0.75rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-9.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.75rem;
  }
  .svg-illustrator.svg-illustrator-sm-10 svg {
    height: 0.5rem !important;
    width: 0.5rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-10.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.5rem;
  }
  .svg-illustrator.svg-illustrator-sm-base svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-base.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1rem;
  }
  .svg-illustrator.svg-illustrator-sm-fluid svg {
    height: 100% !important;
    width: 100% !important;
  }
  .svg-illustrator.svg-illustrator-sm-fluid.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 100%;
  }
  .svg-illustrator.svg-illustrator-sm-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-2x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2rem;
  }
  .svg-illustrator.svg-illustrator-sm-2qx svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-2qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2.25rem;
  }
  .svg-illustrator.svg-illustrator-sm-2hx svg {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-2hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2.5rem;
  }
  .svg-illustrator.svg-illustrator-sm-2tx svg {
    height: 2.75rem !important;
    width: 2.75rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-2tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2.75rem;
  }
  .svg-illustrator.svg-illustrator-sm-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-3x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3rem;
  }
  .svg-illustrator.svg-illustrator-sm-3qx svg {
    height: 3.25rem !important;
    width: 3.25rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-3qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3.25rem;
  }
  .svg-illustrator.svg-illustrator-sm-3hx svg {
    height: 3.5rem !important;
    width: 3.5rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-3hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3.5rem;
  }
  .svg-illustrator.svg-illustrator-sm-3tx svg {
    height: 3.75rem !important;
    width: 3.75rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-3tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3.75rem;
  }
  .svg-illustrator.svg-illustrator-sm-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-4x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4rem;
  }
  .svg-illustrator.svg-illustrator-sm-4qx svg {
    height: 4.25rem !important;
    width: 4.25rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-4qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4.25rem;
  }
  .svg-illustrator.svg-illustrator-sm-4hx svg {
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-4hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4.5rem;
  }
  .svg-illustrator.svg-illustrator-sm-4tx svg {
    height: 4.75rem !important;
    width: 4.75rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-4tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4.75rem;
  }
  .svg-illustrator.svg-illustrator-sm-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-5x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5rem;
  }
  .svg-illustrator.svg-illustrator-sm-5qx svg {
    height: 5.25rem !important;
    width: 5.25rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-5qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5.25rem;
  }
  .svg-illustrator.svg-illustrator-sm-5hx svg {
    height: 5.5rem !important;
    width: 5.5rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-5hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5.5rem;
  }
  .svg-illustrator.svg-illustrator-sm-5tx svg {
    height: 5.75rem !important;
    width: 5.75rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-5tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5.75rem;
  }
  .svg-illustrator.svg-illustrator-sm-10x svg {
    height: 10rem !important;
    width: 10rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-10x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10rem;
  }
  .svg-illustrator.svg-illustrator-sm-10qx svg {
    height: 10.25rem !important;
    width: 10.25rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-10qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10.25rem;
  }
  .svg-illustrator.svg-illustrator-sm-10hx svg {
    height: 10.5rem !important;
    width: 10.5rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-10hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10.5rem;
  }
  .svg-illustrator.svg-illustrator-sm-10tx svg {
    height: 10.75rem !important;
    width: 10.75rem !important;
  }
  .svg-illustrator.svg-illustrator-sm-10tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10.75rem;
  }
}
@media (min-width: 768px) {
  .svg-illustrator.svg-illustrator-md-1 svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-illustrator.svg-illustrator-md-1.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.75rem;
  }
  .svg-illustrator.svg-illustrator-md-2 svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-illustrator.svg-illustrator-md-2.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.5rem;
  }
  .svg-illustrator.svg-illustrator-md-3 svg {
    height: 1.35rem !important;
    width: 1.35rem !important;
  }
  .svg-illustrator.svg-illustrator-md-3.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.35rem;
  }
  .svg-illustrator.svg-illustrator-md-4 svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-illustrator.svg-illustrator-md-4.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.25rem;
  }
  .svg-illustrator.svg-illustrator-md-5 svg {
    height: 1.15rem !important;
    width: 1.15rem !important;
  }
  .svg-illustrator.svg-illustrator-md-5.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.15rem;
  }
  .svg-illustrator.svg-illustrator-md-6 svg {
    height: 1.075rem !important;
    width: 1.075rem !important;
  }
  .svg-illustrator.svg-illustrator-md-6.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.075rem;
  }
  .svg-illustrator.svg-illustrator-md-7 svg {
    height: 0.95rem !important;
    width: 0.95rem !important;
  }
  .svg-illustrator.svg-illustrator-md-7.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.95rem;
  }
  .svg-illustrator.svg-illustrator-md-8 svg {
    height: 0.85rem !important;
    width: 0.85rem !important;
  }
  .svg-illustrator.svg-illustrator-md-8.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.85rem;
  }
  .svg-illustrator.svg-illustrator-md-9 svg {
    height: 0.75rem !important;
    width: 0.75rem !important;
  }
  .svg-illustrator.svg-illustrator-md-9.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.75rem;
  }
  .svg-illustrator.svg-illustrator-md-10 svg {
    height: 0.5rem !important;
    width: 0.5rem !important;
  }
  .svg-illustrator.svg-illustrator-md-10.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.5rem;
  }
  .svg-illustrator.svg-illustrator-md-base svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-illustrator.svg-illustrator-md-base.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1rem;
  }
  .svg-illustrator.svg-illustrator-md-fluid svg {
    height: 100% !important;
    width: 100% !important;
  }
  .svg-illustrator.svg-illustrator-md-fluid.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 100%;
  }
  .svg-illustrator.svg-illustrator-md-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-illustrator.svg-illustrator-md-2x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2rem;
  }
  .svg-illustrator.svg-illustrator-md-2qx svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-illustrator.svg-illustrator-md-2qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2.25rem;
  }
  .svg-illustrator.svg-illustrator-md-2hx svg {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .svg-illustrator.svg-illustrator-md-2hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2.5rem;
  }
  .svg-illustrator.svg-illustrator-md-2tx svg {
    height: 2.75rem !important;
    width: 2.75rem !important;
  }
  .svg-illustrator.svg-illustrator-md-2tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2.75rem;
  }
  .svg-illustrator.svg-illustrator-md-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-illustrator.svg-illustrator-md-3x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3rem;
  }
  .svg-illustrator.svg-illustrator-md-3qx svg {
    height: 3.25rem !important;
    width: 3.25rem !important;
  }
  .svg-illustrator.svg-illustrator-md-3qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3.25rem;
  }
  .svg-illustrator.svg-illustrator-md-3hx svg {
    height: 3.5rem !important;
    width: 3.5rem !important;
  }
  .svg-illustrator.svg-illustrator-md-3hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3.5rem;
  }
  .svg-illustrator.svg-illustrator-md-3tx svg {
    height: 3.75rem !important;
    width: 3.75rem !important;
  }
  .svg-illustrator.svg-illustrator-md-3tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3.75rem;
  }
  .svg-illustrator.svg-illustrator-md-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-illustrator.svg-illustrator-md-4x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4rem;
  }
  .svg-illustrator.svg-illustrator-md-4qx svg {
    height: 4.25rem !important;
    width: 4.25rem !important;
  }
  .svg-illustrator.svg-illustrator-md-4qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4.25rem;
  }
  .svg-illustrator.svg-illustrator-md-4hx svg {
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  .svg-illustrator.svg-illustrator-md-4hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4.5rem;
  }
  .svg-illustrator.svg-illustrator-md-4tx svg {
    height: 4.75rem !important;
    width: 4.75rem !important;
  }
  .svg-illustrator.svg-illustrator-md-4tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4.75rem;
  }
  .svg-illustrator.svg-illustrator-md-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-illustrator.svg-illustrator-md-5x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5rem;
  }
  .svg-illustrator.svg-illustrator-md-5qx svg {
    height: 5.25rem !important;
    width: 5.25rem !important;
  }
  .svg-illustrator.svg-illustrator-md-5qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5.25rem;
  }
  .svg-illustrator.svg-illustrator-md-5hx svg {
    height: 5.5rem !important;
    width: 5.5rem !important;
  }
  .svg-illustrator.svg-illustrator-md-5hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5.5rem;
  }
  .svg-illustrator.svg-illustrator-md-5tx svg {
    height: 5.75rem !important;
    width: 5.75rem !important;
  }
  .svg-illustrator.svg-illustrator-md-5tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5.75rem;
  }
  .svg-illustrator.svg-illustrator-md-10x svg {
    height: 10rem !important;
    width: 10rem !important;
  }
  .svg-illustrator.svg-illustrator-md-10x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10rem;
  }
  .svg-illustrator.svg-illustrator-md-10qx svg {
    height: 10.25rem !important;
    width: 10.25rem !important;
  }
  .svg-illustrator.svg-illustrator-md-10qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10.25rem;
  }
  .svg-illustrator.svg-illustrator-md-10hx svg {
    height: 10.5rem !important;
    width: 10.5rem !important;
  }
  .svg-illustrator.svg-illustrator-md-10hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10.5rem;
  }
  .svg-illustrator.svg-illustrator-md-10tx svg {
    height: 10.75rem !important;
    width: 10.75rem !important;
  }
  .svg-illustrator.svg-illustrator-md-10tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10.75rem;
  }
}
@media (min-width: 992px) {
  .svg-illustrator.svg-illustrator-lg-1 svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-1.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.75rem;
  }
  .svg-illustrator.svg-illustrator-lg-2 svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-2.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.5rem;
  }
  .svg-illustrator.svg-illustrator-lg-3 svg {
    height: 1.35rem !important;
    width: 1.35rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-3.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.35rem;
  }
  .svg-illustrator.svg-illustrator-lg-4 svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-4.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.25rem;
  }
  .svg-illustrator.svg-illustrator-lg-5 svg {
    height: 1.15rem !important;
    width: 1.15rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-5.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.15rem;
  }
  .svg-illustrator.svg-illustrator-lg-6 svg {
    height: 1.075rem !important;
    width: 1.075rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-6.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.075rem;
  }
  .svg-illustrator.svg-illustrator-lg-7 svg {
    height: 0.95rem !important;
    width: 0.95rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-7.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.95rem;
  }
  .svg-illustrator.svg-illustrator-lg-8 svg {
    height: 0.85rem !important;
    width: 0.85rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-8.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.85rem;
  }
  .svg-illustrator.svg-illustrator-lg-9 svg {
    height: 0.75rem !important;
    width: 0.75rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-9.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.75rem;
  }
  .svg-illustrator.svg-illustrator-lg-10 svg {
    height: 0.5rem !important;
    width: 0.5rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-10.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.5rem;
  }
  .svg-illustrator.svg-illustrator-lg-base svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-base.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1rem;
  }
  .svg-illustrator.svg-illustrator-lg-fluid svg {
    height: 100% !important;
    width: 100% !important;
  }
  .svg-illustrator.svg-illustrator-lg-fluid.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 100%;
  }
  .svg-illustrator.svg-illustrator-lg-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-2x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2rem;
  }
  .svg-illustrator.svg-illustrator-lg-2qx svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-2qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2.25rem;
  }
  .svg-illustrator.svg-illustrator-lg-2hx svg {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-2hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2.5rem;
  }
  .svg-illustrator.svg-illustrator-lg-2tx svg {
    height: 2.75rem !important;
    width: 2.75rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-2tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2.75rem;
  }
  .svg-illustrator.svg-illustrator-lg-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-3x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3rem;
  }
  .svg-illustrator.svg-illustrator-lg-3qx svg {
    height: 3.25rem !important;
    width: 3.25rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-3qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3.25rem;
  }
  .svg-illustrator.svg-illustrator-lg-3hx svg {
    height: 3.5rem !important;
    width: 3.5rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-3hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3.5rem;
  }
  .svg-illustrator.svg-illustrator-lg-3tx svg {
    height: 3.75rem !important;
    width: 3.75rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-3tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3.75rem;
  }
  .svg-illustrator.svg-illustrator-lg-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-4x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4rem;
  }
  .svg-illustrator.svg-illustrator-lg-4qx svg {
    height: 4.25rem !important;
    width: 4.25rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-4qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4.25rem;
  }
  .svg-illustrator.svg-illustrator-lg-4hx svg {
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-4hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4.5rem;
  }
  .svg-illustrator.svg-illustrator-lg-4tx svg {
    height: 4.75rem !important;
    width: 4.75rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-4tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4.75rem;
  }
  .svg-illustrator.svg-illustrator-lg-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-5x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5rem;
  }
  .svg-illustrator.svg-illustrator-lg-5qx svg {
    height: 5.25rem !important;
    width: 5.25rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-5qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5.25rem;
  }
  .svg-illustrator.svg-illustrator-lg-5hx svg {
    height: 5.5rem !important;
    width: 5.5rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-5hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5.5rem;
  }
  .svg-illustrator.svg-illustrator-lg-5tx svg {
    height: 5.75rem !important;
    width: 5.75rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-5tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5.75rem;
  }
  .svg-illustrator.svg-illustrator-lg-10x svg {
    height: 10rem !important;
    width: 10rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-10x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10rem;
  }
  .svg-illustrator.svg-illustrator-lg-10qx svg {
    height: 10.25rem !important;
    width: 10.25rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-10qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10.25rem;
  }
  .svg-illustrator.svg-illustrator-lg-10hx svg {
    height: 10.5rem !important;
    width: 10.5rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-10hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10.5rem;
  }
  .svg-illustrator.svg-illustrator-lg-10tx svg {
    height: 10.75rem !important;
    width: 10.75rem !important;
  }
  .svg-illustrator.svg-illustrator-lg-10tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10.75rem;
  }
}
@media (min-width: 1200px) {
  .svg-illustrator.svg-illustrator-xl-1 svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-1.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.75rem;
  }
  .svg-illustrator.svg-illustrator-xl-2 svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-2.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.5rem;
  }
  .svg-illustrator.svg-illustrator-xl-3 svg {
    height: 1.35rem !important;
    width: 1.35rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-3.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.35rem;
  }
  .svg-illustrator.svg-illustrator-xl-4 svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-4.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.25rem;
  }
  .svg-illustrator.svg-illustrator-xl-5 svg {
    height: 1.15rem !important;
    width: 1.15rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-5.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.15rem;
  }
  .svg-illustrator.svg-illustrator-xl-6 svg {
    height: 1.075rem !important;
    width: 1.075rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-6.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.075rem;
  }
  .svg-illustrator.svg-illustrator-xl-7 svg {
    height: 0.95rem !important;
    width: 0.95rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-7.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.95rem;
  }
  .svg-illustrator.svg-illustrator-xl-8 svg {
    height: 0.85rem !important;
    width: 0.85rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-8.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.85rem;
  }
  .svg-illustrator.svg-illustrator-xl-9 svg {
    height: 0.75rem !important;
    width: 0.75rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-9.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.75rem;
  }
  .svg-illustrator.svg-illustrator-xl-10 svg {
    height: 0.5rem !important;
    width: 0.5rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-10.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.5rem;
  }
  .svg-illustrator.svg-illustrator-xl-base svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-base.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1rem;
  }
  .svg-illustrator.svg-illustrator-xl-fluid svg {
    height: 100% !important;
    width: 100% !important;
  }
  .svg-illustrator.svg-illustrator-xl-fluid.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 100%;
  }
  .svg-illustrator.svg-illustrator-xl-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-2x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2rem;
  }
  .svg-illustrator.svg-illustrator-xl-2qx svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-2qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2.25rem;
  }
  .svg-illustrator.svg-illustrator-xl-2hx svg {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-2hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2.5rem;
  }
  .svg-illustrator.svg-illustrator-xl-2tx svg {
    height: 2.75rem !important;
    width: 2.75rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-2tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2.75rem;
  }
  .svg-illustrator.svg-illustrator-xl-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-3x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3rem;
  }
  .svg-illustrator.svg-illustrator-xl-3qx svg {
    height: 3.25rem !important;
    width: 3.25rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-3qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3.25rem;
  }
  .svg-illustrator.svg-illustrator-xl-3hx svg {
    height: 3.5rem !important;
    width: 3.5rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-3hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3.5rem;
  }
  .svg-illustrator.svg-illustrator-xl-3tx svg {
    height: 3.75rem !important;
    width: 3.75rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-3tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3.75rem;
  }
  .svg-illustrator.svg-illustrator-xl-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-4x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4rem;
  }
  .svg-illustrator.svg-illustrator-xl-4qx svg {
    height: 4.25rem !important;
    width: 4.25rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-4qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4.25rem;
  }
  .svg-illustrator.svg-illustrator-xl-4hx svg {
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-4hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4.5rem;
  }
  .svg-illustrator.svg-illustrator-xl-4tx svg {
    height: 4.75rem !important;
    width: 4.75rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-4tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4.75rem;
  }
  .svg-illustrator.svg-illustrator-xl-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-5x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5rem;
  }
  .svg-illustrator.svg-illustrator-xl-5qx svg {
    height: 5.25rem !important;
    width: 5.25rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-5qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5.25rem;
  }
  .svg-illustrator.svg-illustrator-xl-5hx svg {
    height: 5.5rem !important;
    width: 5.5rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-5hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5.5rem;
  }
  .svg-illustrator.svg-illustrator-xl-5tx svg {
    height: 5.75rem !important;
    width: 5.75rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-5tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5.75rem;
  }
  .svg-illustrator.svg-illustrator-xl-10x svg {
    height: 10rem !important;
    width: 10rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-10x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10rem;
  }
  .svg-illustrator.svg-illustrator-xl-10qx svg {
    height: 10.25rem !important;
    width: 10.25rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-10qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10.25rem;
  }
  .svg-illustrator.svg-illustrator-xl-10hx svg {
    height: 10.5rem !important;
    width: 10.5rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-10hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10.5rem;
  }
  .svg-illustrator.svg-illustrator-xl-10tx svg {
    height: 10.75rem !important;
    width: 10.75rem !important;
  }
  .svg-illustrator.svg-illustrator-xl-10tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10.75rem;
  }
}
@media (min-width: 1400px) {
  .svg-illustrator.svg-illustrator-xxl-1 svg {
    height: 1.75rem !important;
    width: 1.75rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-1.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.75rem;
  }
  .svg-illustrator.svg-illustrator-xxl-2 svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-2.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.5rem;
  }
  .svg-illustrator.svg-illustrator-xxl-3 svg {
    height: 1.35rem !important;
    width: 1.35rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-3.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.35rem;
  }
  .svg-illustrator.svg-illustrator-xxl-4 svg {
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-4.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.25rem;
  }
  .svg-illustrator.svg-illustrator-xxl-5 svg {
    height: 1.15rem !important;
    width: 1.15rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-5.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.15rem;
  }
  .svg-illustrator.svg-illustrator-xxl-6 svg {
    height: 1.075rem !important;
    width: 1.075rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-6.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1.075rem;
  }
  .svg-illustrator.svg-illustrator-xxl-7 svg {
    height: 0.95rem !important;
    width: 0.95rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-7.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.95rem;
  }
  .svg-illustrator.svg-illustrator-xxl-8 svg {
    height: 0.85rem !important;
    width: 0.85rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-8.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.85rem;
  }
  .svg-illustrator.svg-illustrator-xxl-9 svg {
    height: 0.75rem !important;
    width: 0.75rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-9.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.75rem;
  }
  .svg-illustrator.svg-illustrator-xxl-10 svg {
    height: 0.5rem !important;
    width: 0.5rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-10.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 0.5rem;
  }
  .svg-illustrator.svg-illustrator-xxl-base svg {
    height: 1rem !important;
    width: 1rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-base.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 1rem;
  }
  .svg-illustrator.svg-illustrator-xxl-fluid svg {
    height: 100% !important;
    width: 100% !important;
  }
  .svg-illustrator.svg-illustrator-xxl-fluid.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 100%;
  }
  .svg-illustrator.svg-illustrator-xxl-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-2x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2rem;
  }
  .svg-illustrator.svg-illustrator-xxl-2qx svg {
    height: 2.25rem !important;
    width: 2.25rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-2qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2.25rem;
  }
  .svg-illustrator.svg-illustrator-xxl-2hx svg {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-2hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2.5rem;
  }
  .svg-illustrator.svg-illustrator-xxl-2tx svg {
    height: 2.75rem !important;
    width: 2.75rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-2tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 2.75rem;
  }
  .svg-illustrator.svg-illustrator-xxl-3x svg {
    height: 3rem !important;
    width: 3rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-3x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3rem;
  }
  .svg-illustrator.svg-illustrator-xxl-3qx svg {
    height: 3.25rem !important;
    width: 3.25rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-3qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3.25rem;
  }
  .svg-illustrator.svg-illustrator-xxl-3hx svg {
    height: 3.5rem !important;
    width: 3.5rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-3hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3.5rem;
  }
  .svg-illustrator.svg-illustrator-xxl-3tx svg {
    height: 3.75rem !important;
    width: 3.75rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-3tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 3.75rem;
  }
  .svg-illustrator.svg-illustrator-xxl-4x svg {
    height: 4rem !important;
    width: 4rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-4x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4rem;
  }
  .svg-illustrator.svg-illustrator-xxl-4qx svg {
    height: 4.25rem !important;
    width: 4.25rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-4qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4.25rem;
  }
  .svg-illustrator.svg-illustrator-xxl-4hx svg {
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-4hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4.5rem;
  }
  .svg-illustrator.svg-illustrator-xxl-4tx svg {
    height: 4.75rem !important;
    width: 4.75rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-4tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 4.75rem;
  }
  .svg-illustrator.svg-illustrator-xxl-5x svg {
    height: 5rem !important;
    width: 5rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-5x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5rem;
  }
  .svg-illustrator.svg-illustrator-xxl-5qx svg {
    height: 5.25rem !important;
    width: 5.25rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-5qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5.25rem;
  }
  .svg-illustrator.svg-illustrator-xxl-5hx svg {
    height: 5.5rem !important;
    width: 5.5rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-5hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5.5rem;
  }
  .svg-illustrator.svg-illustrator-xxl-5tx svg {
    height: 5.75rem !important;
    width: 5.75rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-5tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 5.75rem;
  }
  .svg-illustrator.svg-illustrator-xxl-10x svg {
    height: 10rem !important;
    width: 10rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-10x.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10rem;
  }
  .svg-illustrator.svg-illustrator-xxl-10qx svg {
    height: 10.25rem !important;
    width: 10.25rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-10qx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10.25rem;
  }
  .svg-illustrator.svg-illustrator-xxl-10hx svg {
    height: 10.5rem !important;
    width: 10.5rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-10hx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10.5rem;
  }
  .svg-illustrator.svg-illustrator-xxl-10tx svg {
    height: 10.75rem !important;
    width: 10.75rem !important;
  }
  .svg-illustrator.svg-illustrator-xxl-10tx.svg-4by3 svg {
    height: calc($size / divide(4, 3));
    width: 10.75rem;
  }
}

mat-toolbar,
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar .h1,
.mat-toolbar h2,
.mat-toolbar .h2,
.mat-toolbar h3,
.mat-toolbar .h3,
.mat-toolbar h4,
.mat-toolbar .h4,
.mat-toolbar h5,
.mat-toolbar .h5,
.mat-toolbar h6,
.mat-toolbar .h6 {
  font: inherit;
}

@media (max-width: 991.98px) {
  .mat-toolbar {
    height: 55px;
  }
}
.mat-menu-panel {
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.05) !important;
}
.mat-menu-panel .mat-menu-content .mat-menu-item {
  white-space: revert;
  line-height: normal;
}

.form-control {
  border-width: 1px !important;
  border-style: solid !important;
}
.light-theme .form-control {
  border-color: #e4e6ef;
}
.dark-theme .form-control {
  border-color: #5e6278;
}
.form-control:focus {
  border-color: #e10e8d !important;
}
.light-theme .form-control:focus {
  color: #171723;
  background-color: #eef3f7 !important;
}
.dark-theme .form-control:focus {
  color: #eef3f7;
  background-color: #171723 !important;
}
.form-control.form-control-solid {
  border: none !important;
}
.light-theme .form-control.form-control-solid {
  color: #1b1b29;
  background-color: #e4e6ef;
}
.dark-theme .form-control.form-control-solid {
  color: #e4e6ef;
  background-color: #1b1b29;
}
.form-control.mat-input-element {
  padding: 0.75rem 1rem;
  margin-top: 0.825rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  box-sizing: inherit;
}
.form-control.mat-input-element.form-control-sm {
  border-radius: 0.475rem;
  font-size: 0.925rem;
  padding: 0.55rem 0.75rem;
}
.form-control.mat-input-element.form-control-lg {
  border-radius: 0.475rem;
  font-size: 1.15rem;
  padding: 0.825rem 1.5rem;
}

.mat-form-field {
  font-family: Poppins, Helvetica, "sans-serif" !important;
  display: block !important;
}

.mat-form-field[floatlabel=always].mat-form-field-invalid .form-control {
  border-color: #F65464 !important;
}
.mat-form-field[floatlabel=always] .mat-form-field-infix {
  padding: 0 !important;
}
.mat-form-field[floatlabel=always] .mat-form-field-label {
  font-size: 1.05rem;
  font-weight: 500;
  transform: translateY(-1.28125em) scale(1) perspective(0) translateZ(0.001px) !important;
}
.mat-form-field[floatlabel=always] .mat-select {
  margin-top: 0.825rem;
}
.mat-form-field[floatlabel=always] .mat-select .mat-select-arrow {
  opacity: 0;
}
.mat-form-field[floatlabel=always] .mat-datepicker-toggle {
  cursor: pointer;
}
.mat-form-field[floatlabel=always] .mat-datepicker-toggle button {
  opacity: 0;
}
.mat-form-field[floatlabel=always] .mat-form-field-underline {
  display: none !important;
}

.mat-form-field[floatlabel=always].datepicker .mat-form-field-infix {
  padding-top: 0.75rem !important;
  width: 220px !important;
}

.datepicker .mat-form-field-suffix {
  margin-left: -3.5rem;
}
.datepicker .form-control.mat-input-element {
  margin-top: 0;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--primary) !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(var(--primary-rgb), 0.54) !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary) !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--primary) !important;
}

.btn-primary {
  color: var(--inverse-primary) !important;
  background-color: var(--primary) !important;
}

.btn-link {
  color: var(--primary) !important;
}

mat-drawer-content {
  position: initial !important;
}
mat-drawer-content.sidenav-collapsed {
  margin-left: 100px !important;
}

mat-drawer,
mat-drawer-content {
  transition-property: transform, margin-left, margin-right;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}

mat-drawer-container {
  height: 100%;
}

mat-drawer {
  width: 250px;
  transition-property: width;
  border-right: none !important;
}
.light-theme mat-drawer {
  color: #181c32 !important;
  background-color: #f5f8fa !important;
}
.dark-theme mat-drawer {
  color: #f5f8fa !important;
  background-color: #151521 !important;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(161, 165, 183, 0.6) !important;
}

@media (min-width: 992px) {
  mat-drawer {
    padding: 30px 0 0 30px;
    z-index: 1 !important;
  }
}
@media (max-width: 991.98px) {
  mat-drawer {
    padding: 15px;
    z-index: 3 !important;
    padding: 0;
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  mat-drawer {
    padding: 0;
    border-radius: 0;
  }
  mat-drawer .card {
    border-radius: 0;
  }
}
.mat-tooltip {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}
.light-theme .mat-tooltip {
  color: #2b2b40 !important;
  background-color: #ffffff !important;
}
.dark-theme .mat-tooltip {
  color: #ffffff !important;
  background-color: #2b2b40 !important;
}

.mat-dialog-container {
  background-color: #ffffff !important;
}
.mat-dialog-container .mat-dialog-content {
  max-height: 80vh;
}

.mat-table .mat-cell,
.mat-table .mat-footer-cell,
.mat-table .mat-header-cell {
  color: inherit;
  border: none;
}
.light-theme .mat-table .mat-header-cell {
  color: #a1a5b7 !important;
}
.dark-theme .mat-table .mat-header-cell {
  color: #a1a5b7 !important;
}

.mat-paginator .mat-paginator-container {
  justify-content: space-between;
}

.mat-select-panel .mat-option {
  height: auto !important;
  min-height: 3em !important;
  white-space: normal !important;
  line-height: 2em !important;
}

.highlight {
  position: relative;
  background: #1e1e3f;
  border-radius: 0.475rem;
  padding: 1.75rem 1.5rem 1.75rem 1.5rem;
}
.highlight .nav {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  margin-top: -0.25rem;
}
.highlight .nav .nav-item {
  margin-right: 0.75rem;
}
.highlight .nav .nav-link {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.35rem 1rem;
  border-radius: 0.475rem;
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.2s ease;
  background-color: transparent;
}
.highlight .nav .nav-link:focus, .highlight .nav .nav-link.active {
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}
.highlight .highlight-copy {
  display: none;
  position: absolute;
  right: 1.75rem;
  top: 1.5rem;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0.35rem 1rem !important;
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
}
.highlight .highlight-copy:focus, .highlight .highlight-copy:hover {
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}
.highlight:hover .highlight-copy {
  display: flex;
}
.highlight .highlight-code {
  display: grid;
}
.highlight .highlight-code pre {
  overflow: auto;
  padding: 0;
  margin: 0;
}
.highlight .highlight-code pre::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 0.25rem;
}
.highlight .highlight-code pre::-webkit-scrollbar-thumb {
  background-color: #f5f8fa;
}
.highlight .highlight-code pre code[class*=language-] {
  padding: 0;
  margin: 0;
  font-size: 1rem !important;
  display: initial;
}

.apexcharts-text,
.apexcharts-title-text,
.apexcharts-legend-text {
  font-family: var(--bs-font-sans-serif) !important;
}

.apexcharts-title-text {
  font-weight: 400;
}

.apexcharts-pie-label {
  font-weight: 400;
  font-size: 0.925rem;
}

.apexcharts-toolbar {
  text-align: left !important;
}

.apexcharts-menu {
  background: var(--white);
  border: 0 !important;
  padding: 0.5rem 0 !important;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  border-radius: 0.475rem !important;
  overflow: hidden;
  min-width: 10rem !important;
}
.light-theme .apexcharts-menu {
  background: #ffffff;
}
.dark-theme .apexcharts-menu {
  background: #000000;
}
.apexcharts-menu .apexcharts-menu-item {
  padding: 0.25rem 1rem;
}
.apexcharts-menu .apexcharts-menu-item:hover {
  background-color: rgba(245, 248, 250, 0.8) !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
  border-radius: 0.475rem;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  border: 0 !important;
}
.light-theme .apexcharts-tooltip.apexcharts-theme-light {
  color: #2b2b40 !important;
  background: #ffffff !important;
}
.dark-theme .apexcharts-tooltip.apexcharts-theme-light {
  color: #ffffff !important;
  background: #2b2b40 !important;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  border: 0 !important;
}
.light-theme .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background-color: #f5f8fa !important;
}
.dark-theme .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background-color: #323248 !important;
}
.apexcharts-tooltip .apexcharts-tooltip-title {
  padding: 0.5rem 1rem;
}

.apexcharts-xaxistooltip.apexcharts-theme-light {
  border-radius: 0.475rem !important;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !important;
  border: 0 !important;
  color: var(--white-inverse);
  background: var(--white);
}
.light-theme .apexcharts-xaxistooltip.apexcharts-theme-light {
  color: #000000;
  background: #ffffff;
}
.dark-theme .apexcharts-xaxistooltip.apexcharts-theme-light {
  color: #ffffff;
  background: #000000;
}
.apexcharts-xaxistooltip.apexcharts-theme-light:before {
  border-bottom: 0;
}
.apexcharts-xaxistooltip.apexcharts-theme-light:after {
  border-bottom-color: var(--white);
}
.light-theme .apexcharts-xaxistooltip.apexcharts-theme-light:after {
  border-bottom-color: #ffffff;
}
.dark-theme .apexcharts-xaxistooltip.apexcharts-theme-light:after {
  border-bottom-color: #000000;
}

.card-rounded-bottom .apexcharts-canvas svg {
  border-bottom-left-radius: 0.475rem;
  border-bottom-right-radius: 0.475rem;
}

.rounded .apexcharts-canvas svg {
  border-radius: 0.475rem !important;
}

.rounded-sm .apexcharts-canvas svg {
  border-radius: 0.275rem !important;
}

.rounded-lg .apexcharts-canvas svg {
  border-radius: 0.775rem !important;
}

.rounded-xl .apexcharts-canvas svg {
  border-radius: 1rem !important;
}

.rounded-bottom-xl .apexcharts-canvas svg {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

@media (max-width: 575.98px) {
  .toast-container {
    display: contents !important;
  }
}
.ngx-toastr {
  transition: all 0.2s ease;
  background-position: 1.5rem center /*rtl:calc(100% - 1.5rem) center*/ !important;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !important;
  border-radius: 0.475rem !important;
  border: 0 !important;
  background-color: #f5f8fa;
  color: #5e6278;
  padding: 1.25rem 1.25rem 1.25rem 4.5rem !important;
}
@media (min-width: 992px) {
  .ngx-toastr {
    opacity: 0.9 !important;
    margin: 30px !important;
  }
}
@media (max-width: 991.98px) {
  .ngx-toastr {
    margin: 15px !important;
  }
}
@media (max-width: 575.98px) {
  .ngx-toastr {
    width: calc(100% - (15px * 2)) !important;
  }
}
.ngx-toastr:hover {
  transition: all 0.2s ease;
  opacity: 1 !important;
}
.ngx-toastr .toast-close-button {
  outline: none !important;
  font-size: 0;
  width: 0.85rem;
  height: 0.85rem;
}
.ngx-toastr .toast-title {
  font-size: 1.15rem;
  font-weight: 500;
}
.ngx-toastr .toast-title + .toast-message {
  margin-top: 0.25rem;
}
.ngx-toastr .toast-message {
  font-size: 1rem;
  font-weight: 400;
}
.ngx-toastr.toast-success {
  background-color: #1BC5BC;
  color: #ffffff;
}
.ngx-toastr.toast-success .toast-close-button {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
.ngx-toastr.toast-info {
  background-color: #5F5CF1;
  color: #ffffff;
}
.ngx-toastr.toast-info .toast-close-button {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
.ngx-toastr.toast-warning {
  background-color: #FFA800;
  color: #eff2f5;
}
.ngx-toastr.toast-warning .toast-close-button {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23eff2f5'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
.ngx-toastr.toast-error {
  background-color: #F65464;
  color: #ffffff;
}
.ngx-toastr.toast-error .toast-close-button {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}

.toast-top-center {
  top: 12px;
}

.toast-bottom-center {
  bottom: 12px;
}

.fslightbox-slide-btn {
  border-radius: 0.475rem;
}

.fslightbox-toolbar {
  border-bottom-left-radius: 0.475rem;
}

ngx-material-timepicker-container {
  z-index: 1001;
}

html {
  font-family: sans-serif;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 13px !important;
  font-weight: 400;
  font-family: Poppins, Helvetica, "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 991.98px) {
  html,
body {
    font-size: 13px !important;
  }
}
@media (max-width: 767.98px) {
  html,
body {
    font-size: 12px !important;
  }
}
html a:hover,
html a:active,
html a:focus,
body a:hover,
body a:active,
body a:focus {
  text-decoration: none !important;
}

body {
  display: flex;
  flex-direction: column;
  color: inherit;
  background: inherit;
}

.light-theme .wrapper {
  color: #181c32;
  background: #f5f8fa;
}
.dark-theme .wrapper {
  color: #f5f8fa;
  background: #151521;
}

router-outlet {
  display: none;
}

.qrcode canvas {
  border-radius: 1rem !important;
}

canvas {
  user-select: none;
}

@media print {
  .print-content-only {
    padding: 0 !important;
    background: none !important;
  }
  .print-content-only .wrapper,
.print-content-only .page,
.print-content-only .content,
.print-content-only .container,
.print-content-only .container-fluid,
.print-content-only .container-sm,
.print-content-only .container-md,
.print-content-only .container-lg,
.print-content-only .container-xl,
.print-content-only .container-xxl {
    background: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .print-content-only .aside,
.print-content-only .sidebar,
.print-content-only .sticky-toolbar,
.print-content-only .scrolltop,
.print-content-only .header,
.print-content-only .footer,
.print-content-only .toolbar,
.print-content-only .offcanvas,
.print-content-only .btn {
    display: none !important;
  }
}
.content-bg-for-font-color {
  color: #f5f8fa;
}

@media (min-width: 992px) {
  .container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
    padding: 0 30px;
  }

  .wrapper {
    transition: padding-left 0.3s ease, margin-right 0.3s ease;
  }
  .aside-enabled.aside-fixed .wrapper {
    transition: padding-left 0.3s ease;
    padding-left: 265px;
  }
  .aside-enabled.aside-fixed[data-aside-minimize=on] .wrapper {
    transition: padding-left 0.3s ease;
    padding-left: 75px;
  }
  .header-fixed .wrapper {
    padding-top: 65px;
  }
  .header-fixed.toolbar-fixed .wrapper {
    padding-top: calc( 65px + 65px );
  }
}
@media (max-width: 991.98px) {
  .container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
    max-width: none;
    padding: 0 15px;
  }

  .header-tablet-and-mobile-fixed .wrapper {
    padding-top: 55px;
  }
  .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .wrapper {
    padding-top: calc( 55px + 55px );
  }
}
.brand-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.light-theme .brand-logo .dark {
  display: none;
}
.light-theme .brand-logo .light {
  display: block;
}

.dark-theme .brand-logo .dark {
  display: block;
}
.dark-theme .brand-logo .light {
  display: none;
}

.light-theme .header {
  background-color: rgba(255, 241, 254, 0.5) !important;
}
.dark-theme .header {
  background-color: #361a2b !important;
}

.header-h-desktop {
  height: 65px;
}

.header-h-tablet-and-mobile {
  height: 55px;
}

@media (min-width: 992px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    height: 65px;
    transition: left 0.3s ease;
  }
  .header-fixed .header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    height: 65px;
    padding: 0;
  }
  .header-fixed.toolbar-fixed .header {
    box-shadow: none;
  }
  .aside-enabled.aside-fixed.header-fixed .header {
    left: 265px;
    transition: left 0.3s ease;
  }
  .aside-enabled.aside-fixed.header-fixed[data-aside-minimize=on] .header {
    left: 75px;
    transition: left 0.3s ease;
  }
  .header .header-menu {
    display: flex;
  }
}
@media (max-width: 991.98px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    position: relative;
    z-index: 2;
  }
  .header-tablet-and-mobile-fixed .header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    height: 55px;
    min-height: 55px;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  }
  .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .header {
    box-shadow: none;
  }
  .header .header-menu {
    display: none;
    z-index: 99;
  }
  .header .page-title {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .toolbar {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    transition: left 0.3s ease, right 0.3s ease;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  }
  .light-theme .toolbar {
    color: #1a1a27;
    background: #ffffff;
  }
  .dark-theme .toolbar {
    color: #ffffff;
    background: #1a1a27;
  }
  .toolbar-fixed .toolbar {
    height: 65px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  }
  .toolbar-enabled:not(.toolbar-fixed) .toolbar {
    margin-bottom: 30px;
  }
  .header-fixed.toolbar-fixed .toolbar {
    top: 65px;
  }
  .light-theme .header-fixed.toolbar-fixed .toolbar {
    border-top: 1px solid #f5f8fa;
  }
  .dark-theme .header-fixed.toolbar-fixed .toolbar {
    border-top: 1px solid #181c32;
  }
  .aside-enabled.aside-fixed.toolbar-fixed .toolbar {
    left: 265px !important;
    transition: left 0.3s ease;
  }
  .aside-enabled.aside-fixed.toolbar-fixed[data-aside-minimize=on] .toolbar {
    left: 75px !important;
    transition: left 0.3s ease;
  }
}
@media (max-width: 991.98px) {
  .toolbar {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    transition: left 0.3s ease, right 0.3s ease;
  }
  .light-theme .toolbar {
    color: #1a1a27;
    background: #ffffff;
  }
  .dark-theme .toolbar {
    color: #ffffff;
    background: #1a1a27;
  }
  .toolbar-tablet-and-mobile-fixed .toolbar {
    height: 55px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  }
  .toolbar-enabled:not(.toolbar-tablet-and-mobile-fixed) .toolbar {
    margin: 0 0 15px;
  }
  .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .toolbar {
    top: 55px;
  }

  .page-title {
    margin: 15px !important;
  }
}
.aside {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.05);
  padding: 0;
}
.aside .btn-custom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}
.aside .btn-custom .btn-label {
  transition: opacity 0.3s ease;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  opacity: 1;
}
.aside .btn-custom .btn-icon {
  display: inline-block;
  transition: opacity 0.3s ease;
  overflow: hidden;
  opacity: 0;
  margin: 0;
  width: 0;
}
.aside.aside-dark {
  background-color: #1e1e2d;
}
.aside.aside-dark .aside-toggle svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.aside.aside-dark .aside-toggle svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.aside.aside-dark .aside-toggle .logo-minimize {
  display: none;
}
.aside.aside-dark .separator {
  border-bottom-color: #282a3d;
}
.aside.aside-dark .btn-custom {
  color: #b5b5c3;
  background-color: rgba(63, 66, 84, 0.35);
}
.btn-check:checked + .aside.aside-dark .btn-custom, .btn-check:active + .aside.aside-dark .btn-custom, .aside.aside-dark .btn-custom:focus:not(.btn-active), .aside.aside-dark .btn-custom:hover:not(.btn-active), .aside.aside-dark .btn-custom:active:not(.btn-active), .aside.aside-dark .btn-custom.active, .aside.aside-dark .btn-custom.show,
.aside.aside-dark .btn-custom .show, .aside.aside-dark .btn-custom[aria-expanded=true], [aria-expanded=true] > .aside.aside-dark .btn-custom {
  color: #b5b5c3;
  background-color: rgba(63, 66, 84, 0.35);
}
.aside.aside-dark .btn-custom .btn-icon {
  color: #b5b5c3;
}
.aside.aside-dark .btn-custom .btn-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #b5b5c3;
}
.aside.aside-dark .btn-custom .btn-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.aside.aside-light {
  background-color: #ffffff;
}
.aside .aside-menu {
  height: 100vh;
}
.aside .aside-menu {
  height: calc( 100vh - ( 65px + 58px ) );
}
.aside .aside-toggle {
  margin-left: 0rem;
}
[data-aside-minimize=on] .aside .aside-toggle {
  margin-left: 0;
}

@media (min-width: 992px) {
  .aside {
    width: 265px;
    transition: width 0.3s ease;
  }
  .aside .aside-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    padding: 0 25px;
  }
  .aside .aside-menu {
    width: 265px;
  }
  .aside-fixed .aside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 101 !important;
    overflow: hidden;
  }
  .aside-static .aside {
    position: relative;
    z-index: 1;
  }
  [data-aside-minimize=on] .aside {
    width: 75px;
    transition: width 0.3s ease;
  }
  [data-aside-minimize=on] .aside .aside-logo .logo-default {
    display: inline-block;
  }
  [data-aside-minimize=on] .aside .aside-logo .logo-minimize {
    display: none;
  }
  [data-aside-minimize=on] .aside.aside-hoverable:hover:not(.animating) {
    transition: width 0.3s ease;
    width: 265px;
    box-shadow: 5px 0px 10px rgba(70, 78, 95, 0.075);
  }
  [data-aside-minimize=on] .aside.aside-hoverable:hover:not(.animating) .aside-logo {
    flex-direction: row-reverse;
    justify-content: start;
  }
  [data-aside-minimize=on] .aside.aside-hoverable:hover:not(.animating) .aside-logo > h1, [data-aside-minimize=on] .aside.aside-hoverable:hover:not(.animating) .aside-logo > .h1 {
    display: block;
    margin-left: 1.25rem;
  }
  [data-aside-minimize=on] .aside.aside-hoverable:hover:not(.animating) .menu .menu-item {
    width: 265px;
  }
  [data-aside-minimize=on] .aside.aside-hoverable:hover:not(.animating) .menu .menu-item .menu-title {
    display: block;
    transition: opacity 0.3s ease;
  }
  [data-aside-minimize=on] .aside:not(.aside-hoverable) .aside-logo, [data-aside-minimize=on] .aside:not(:hover) .aside-logo {
    justify-content: center;
  }
  [data-aside-minimize=on] .aside:not(.aside-hoverable) .aside-logo > h1, [data-aside-minimize=on] .aside:not(.aside-hoverable) .aside-logo > .h1,
[data-aside-minimize=on] .aside:not(.aside-hoverable) .aside-logo .logo, [data-aside-minimize=on] .aside:not(:hover) .aside-logo > h1, [data-aside-minimize=on] .aside:not(:hover) .aside-logo > .h1,
[data-aside-minimize=on] .aside:not(:hover) .aside-logo .logo {
    display: none;
  }
  [data-aside-minimize=on] .aside:not(.aside-hoverable) .aside-logo .logo-default, [data-aside-minimize=on] .aside:not(:hover) .aside-logo .logo-default {
    display: none;
  }
  [data-aside-minimize=on] .aside:not(.aside-hoverable) .aside-logo .logo-minimize, [data-aside-minimize=on] .aside:not(:hover) .aside-logo .logo-minimize {
    display: inline-block;
  }
  [data-aside-minimize=on] .aside:not(.aside-hoverable) .aside-menu .menu-content,
[data-aside-minimize=on] .aside:not(.aside-hoverable) .aside-menu .menu-title, [data-aside-minimize=on] .aside:not(:hover) .aside-menu .menu-content,
[data-aside-minimize=on] .aside:not(:hover) .aside-menu .menu-title {
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  [data-aside-minimize=on] .aside:not(.aside-hoverable) .aside-menu .menu-item.show > .menu-sub, [data-aside-minimize=on] .aside:not(:hover) .aside-menu .menu-item.show > .menu-sub {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
  }
  [data-aside-minimize=on] .aside:not(.aside-hoverable) .btn-custom .btn-label, [data-aside-minimize=on] .aside:not(:hover) .btn-custom .btn-label {
    width: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  [data-aside-minimize=on] .aside:not(.aside-hoverable) .btn-custom .btn-icon, [data-aside-minimize=on] .aside:not(:hover) .btn-custom .btn-icon {
    width: auto;
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  [data-aside-minimize=on] .aside:not(.aside-hoverable) .menu .menu-item, [data-aside-minimize=on] .aside:not(:hover) .menu .menu-item {
    width: 75px;
    transition: width 0.3s ease;
  }
  .aside .aside-footer {
    height: 58px;
  }
}
@media (max-width: 991.98px) {
  .aside {
    display: none;
  }
  .aside .aside-logo {
    display: none;
  }
  .aside .aside-menu {
    height: calc(100vh - (58px));
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .aside-enabled {
    overflow: hidden;
  }
}
@media (max-width: 767.98px) {
  .aside-enabled {
    overflow: hidden;
  }
}
.aside-menu .menu .menu-item .menu-link,
.aside-menu .menu .menu-item .menu-content {
  padding-left: 25px;
  padding-right: 25px;
}
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(0.75rem + 25px);
}
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(1.5rem + 25px);
}
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(2.25rem + 25px);
}
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(3rem + 25px);
}
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(3.75rem + 25px);
}
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(4.5rem + 25px);
}
.menu-fit .aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-fit .aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: 0.75rem;
  padding-right: 0;
}
.menu-fit .aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-fit .aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(1.5rem);
  padding-right: 0;
}
.menu-fit .aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-fit .aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(2.25rem);
  padding-right: 0;
}
.menu-fit .aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-content,
.menu-fit .aside-menu .menu .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-sub:not([data-popper-placement]) > .menu-item > .menu-link {
  padding-left: calc(3rem);
  padding-right: 0;
}
.aside-menu .menu-item {
  padding: 0;
}
.aside-menu .menu-item .menu-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.aside-menu .menu-item .menu-icon {
  justify-content: flex-start;
}

.aside-dark .hover-scroll-overlay-y {
  --scrollbar-space: 0.4rem;
  scrollbar-color: #3b3b64 transparent;
}
.aside-dark .hover-scroll-overlay-y::-webkit-scrollbar-thumb {
  background-color: #3b3b64;
}
.aside-dark .hover-scroll-overlay-y:hover {
  scrollbar-color: #41416e transparent;
}
.aside-dark .hover-scroll-overlay-y:hover::-webkit-scrollbar-thumb {
  background-color: #41416e;
}
.aside-dark .menu .menu-item .menu-category {
  color: #4c4e6f !important;
}
.aside-dark .menu .menu-item .menu-link {
  border-radius: 0.475rem;
  padding: 10px;
  margin: 15px;
}
.aside-dark .menu .menu-item .menu-link {
  color: #ffffff;
}
.aside-dark .menu .menu-item .menu-link .menu-title {
  color: #ffffff;
}
.aside-dark .menu .menu-item .menu-link .menu-icon i {
  color: #a1a5b7;
}
.aside-dark .menu .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #a1a5b7;
}
.aside-dark .menu .menu-item .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.aside-dark .menu .menu-item .menu-link .menu-bullet .bullet {
  background-color: #ffffff;
}
.aside-dark .menu .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}
.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active),
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #ffffff;
  color: #5F5CF1;
}
.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-title,
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #5F5CF1;
}
.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon i,
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon i {
  color: #5F5CF1;
}
.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g),
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g),
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-bullet .bullet,
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-bullet .bullet {
  background-color: #5F5CF1;
}
.aside-dark .menu .menu-item.hover > .menu-link:not(.disabled):not(.active) .menu-arrow:after,
.aside-dark .menu .menu-item .menu-link:hover:not(.disabled):not(.active) .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}
.aside-dark .menu .menu-item.here > .menu-link, .aside-dark .menu .menu-item.show > .menu-link {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #ffffff;
  color: #5F5CF1;
}
.aside-dark .menu .menu-item.here > .menu-link .menu-title, .aside-dark .menu .menu-item.show > .menu-link .menu-title {
  color: #5F5CF1;
}
.aside-dark .menu .menu-item.here > .menu-link .menu-icon i, .aside-dark .menu .menu-item.show > .menu-link .menu-icon i {
  color: #5F5CF1;
}
.aside-dark .menu .menu-item.here > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .aside-dark .menu .menu-item.show > .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.aside-dark .menu .menu-item.here > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g), .aside-dark .menu .menu-item.show > .menu-link .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.aside-dark .menu .menu-item.here > .menu-link .menu-bullet .bullet, .aside-dark .menu .menu-item.show > .menu-link .menu-bullet .bullet {
  background-color: #5F5CF1;
}
.aside-dark .menu .menu-item.here > .menu-link .menu-arrow:after, .aside-dark .menu .menu-item.show > .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}
.aside-dark .menu .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #ffffff;
  color: #5F5CF1;
}
.aside-dark .menu .menu-item .menu-link.active .menu-title {
  color: #5F5CF1;
}
.aside-dark .menu .menu-item .menu-link.active .menu-icon i {
  color: #5F5CF1;
}
.aside-dark .menu .menu-item .menu-link.active .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #5F5CF1;
}
.aside-dark .menu .menu-item .menu-link.active .menu-icon .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.aside-dark .menu .menu-item .menu-link.active .menu-bullet .bullet {
  background-color: #5F5CF1;
}
.aside-dark .menu .menu-item .menu-link.active .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.06463 4.42111C1.96161 4.22088 1.9809 3.9637 2.12863 3.78597L5.12847 0.177181C5.31402 -0.046034 5.63049 -0.060261 5.83532 0.145404C6.04015 0.351069 6.05578 0.698744 5.87023 0.921959L3.19406 4.14137L5.84414 7.06417C6.03896 7.27904 6.03835 7.62686 5.84278 7.84105C5.64721 8.05524 5.33073 8.05469 5.13591 7.83982L2.14806 4.54449C2.1141 4.50704 2.08629 4.46541 2.06463 4.42111Z'/%3e%3c/svg%3e");
  /*rtl:begin:remove*/
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z'/%3e%3c/svg%3e");
  /*rtl:end:remove*/
}

.aside-light .menu .menu-title {
  font-weight: 500;
}

.light-theme .content {
  background: #f5f8fa;
}
.dark-theme .content {
  background: #151521;
}

@media (min-width: 992px) {
  .content {
    padding: 30px 0;
  }
  .toolbar-enabled:not(.toolbar-fixed) .content {
    padding-top: 0;
  }
}
@media (max-width: 991.98px) {
  .content {
    padding: 15px 0;
  }
  .toolbar-enabled:not(.toolbar-tablet-and-mobile-fixed) .content {
    padding-top: 0;
  }
}
[data-st-page-loading=true] {
  overflow: hidden;
}

.page-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1080;
  display: none;
}
.page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-loader .page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s linear;
}
.page-loader .page-loading::before, .page-loader .page-loading::after {
  content: "";
  position: fixed;
  height: 100%;
  top: 0;
  left: 50%;
  background-color: var(--bs-body-bg);
  opacity: 0.25;
  width: 200%;
  margin-left: -100%;
  transform: skewX(-30deg) translateX(-100%);
  transition: transform 0.3s linear 0.3s;
  z-index: 2;
}
.page-loader .page-loading.is-loading {
  position: absolute;
  opacity: 1;
  visibility: visible;
}
.page-loader .page-loading.is-loading::before {
  transform: skewX(-30deg) translateX(0);
  transition: transform 0.3s linear;
}
.page-loader .page-loading.is-loading::after {
  transform: skewX(-30deg) translateX(0);
  transition: transform 0.3s linear 0.15s;
}
.page-loader .page-loading > * {
  z-index: 9;
}

.svg-icon.svg-icon-20x {
  height: 10rem !important;
  width: 10rem !important;
}

/* Importing Bootstrap SCSS file. */
/*!
 * Bootstrap  v5.3.3 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #3699FF;
  --bs-indigo: #6610f2;
  --bs-purple: #5F5CF1;
  --bs-pink: #d63384;
  --bs-red: #F65464;
  --bs-orange: #fd7e14;
  --bs-yellow: #FFA800;
  --bs-green: #1BC5BC;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000000;
  --bs-white: #ffffff;
  --bs-gray: #7e8299;
  --bs-gray-dark: #3f4254;
  --bs-gray-100: #f5f8fa;
  --bs-gray-200: #eff2f5;
  --bs-gray-300: #e4e6ef;
  --bs-gray-400: #b5b5c3;
  --bs-gray-500: #a1a5b7;
  --bs-gray-600: #7e8299;
  --bs-gray-700: #5e6278;
  --bs-gray-800: #3f4254;
  --bs-gray-900: #181c32;
  --bs-white: #ffffff;
  --bs-light: #eff2f5;
  --bs-primary: #e10e8d;
  --bs-secondary: #e4e6ef;
  --bs-success: #1BC5BC;
  --bs-info: #5F5CF1;
  --bs-warning: #FFA800;
  --bs-danger: #F65464;
  --bs-dark: #3f4254;
  --bs-black: #000000;
  --bs-muted: #a1a5b7;
  --bs-blue: #3699FF;
  --bs-indigo: #6610f2;
  --bs-purple: #5F5CF1;
  --bs-pink: #d63384;
  --bs-red: #F65464;
  --bs-orange: #fd7e14;
  --bs-yellow: #FFA800;
  --bs-green: #1BC5BC;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-gray: #7e8299;
  --bs-body-bg: #f5f8fa;
  --bs-body-text: #151521;
  --bs-toolbar-bg: #ffffff;
  --bs-toolbar-text: #1a1a27;
  --bs-focus-bg: #eef3f7;
  --bs-tabbar-text: #171723;
  --bs-card-bg: #ffffff;
  --bs-card-text: #1e1e2d;
  --bs-item-bg: #e4e6ef;
  --bs-item-text: #1b1b29;
  --bs-white-rgb: 255, 255, 255;
  --bs-light-rgb: 239, 242, 245;
  --bs-primary-rgb: 225, 14, 141;
  --bs-secondary-rgb: 228, 230, 239;
  --bs-success-rgb: 27, 197, 188;
  --bs-info-rgb: 95, 92, 241;
  --bs-warning-rgb: 255, 168, 0;
  --bs-danger-rgb: 246, 84, 100;
  --bs-dark-rgb: 63, 66, 84;
  --bs-black-rgb: 0, 0, 0;
  --bs-muted-rgb: 161, 165, 183;
  --bs-blue-rgb: 54, 153, 255;
  --bs-indigo-rgb: 102, 16, 242;
  --bs-purple-rgb: 95, 92, 241;
  --bs-pink-rgb: 214, 51, 132;
  --bs-red-rgb: 246, 84, 100;
  --bs-orange-rgb: 253, 126, 20;
  --bs-yellow-rgb: 255, 168, 0;
  --bs-green-rgb: 27, 197, 188;
  --bs-teal-rgb: 32, 201, 151;
  --bs-cyan-rgb: 13, 202, 240;
  --bs-gray-rgb: 126, 130, 153;
  --bs-body-bg-rgb: 245, 248, 250;
  --bs-body-text-rgb: 21, 21, 33;
  --bs-toolbar-bg-rgb: 255, 255, 255;
  --bs-toolbar-text-rgb: 26, 26, 39;
  --bs-focus-bg-rgb: 238, 243, 247;
  --bs-tabbar-text-rgb: 23, 23, 35;
  --bs-card-bg-rgb: 255, 255, 255;
  --bs-card-text-rgb: 30, 30, 45;
  --bs-item-bg-rgb: 228, 230, 239;
  --bs-item-text-rgb: 27, 27, 41;
  --bs-primary-text-emphasis: #5a0638;
  --bs-secondary-text-emphasis: #5b5c60;
  --bs-success-text-emphasis: #0b4f4b;
  --bs-info-text-emphasis: #262560;
  --bs-warning-text-emphasis: #664300;
  --bs-danger-text-emphasis: #622228;
  --bs-light-text-emphasis: #5e6278;
  --bs-dark-text-emphasis: #5e6278;
  --bs-primary-bg-subtle: #f9cfe8;
  --bs-secondary-bg-subtle: #fafafc;
  --bs-success-bg-subtle: #d1f3f2;
  --bs-info-bg-subtle: #dfdefc;
  --bs-warning-bg-subtle: #ffeecc;
  --bs-danger-bg-subtle: #fddde0;
  --bs-light-bg-subtle: #fafcfd;
  --bs-dark-bg-subtle: #b5b5c3;
  --bs-primary-border-subtle: #f39fd1;
  --bs-secondary-border-subtle: #f4f5f9;
  --bs-success-border-subtle: #a4e8e4;
  --bs-info-border-subtle: #bfbef9;
  --bs-warning-border-subtle: #ffdc99;
  --bs-danger-border-subtle: #fbbbc1;
  --bs-light-border-subtle: #eff2f5;
  --bs-dark-border-subtle: #a1a5b7;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: Poppins, Helvetica, "sans-serif";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size:1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #151521;
  --bs-body-color-rgb: 21, 21, 33;
  --bs-body-bg: #f5f8fa;
  --bs-body-bg-rgb: 245, 248, 250;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(21, 21, 33, 0.75);
  --bs-secondary-color-rgb: 21, 21, 33;
  --bs-secondary-bg: #eff2f5;
  --bs-secondary-bg-rgb: 239, 242, 245;
  --bs-tertiary-color: rgba(21, 21, 33, 0.5);
  --bs-tertiary-color-rgb: 21, 21, 33;
  --bs-tertiary-bg: #f5f8fa;
  --bs-tertiary-bg-rgb: 245, 248, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #e10e8d;
  --bs-link-color-rgb: 225, 14, 141;
  --bs-link-decoration: none;
  --bs-link-hover-color: #a90768;
  --bs-link-hover-color-rgb: 169, 7, 104;
  --bs-link-hover-decoration: underline;
  --bs-code-color: #e10e8d;
  --bs-highlight-color: #151521;
  --bs-highlight-bg: #ffeecc;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #eff2f5;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.475rem;
  --bs-border-radius-sm: 0.275rem;
  --bs-border-radius-lg: 0.775rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.05);
  --bs-box-shadow-sm: 0 0.4rem 1.25rem 0.4rem rgba(0, 0, 0, 0.035);
  --bs-box-shadow-lg: 0 1rem 2rem 1rem rgba(0, 0, 0, 0.1);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(225, 14, 141, 0.25);
  --bs-form-valid-color: #1BC5BC;
  --bs-form-valid-border-color: #1BC5BC;
  --bs-form-invalid-color: #F65464;
  --bs-form-invalid-border-color: #F65464;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #e4e6ef;
  --bs-body-color-rgb: 228, 230, 239;
  --bs-body-bg: #181c32;
  --bs-body-bg-rgb: 24, 28, 50;
  --bs-emphasis-color: #ffffff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(228, 230, 239, 0.75);
  --bs-secondary-color-rgb: 228, 230, 239;
  --bs-secondary-bg: #3f4254;
  --bs-secondary-bg-rgb: 63, 66, 84;
  --bs-tertiary-color: rgba(228, 230, 239, 0.5);
  --bs-tertiary-color-rgb: 228, 230, 239;
  --bs-tertiary-bg: #2c2f43;
  --bs-tertiary-bg-rgb: 44, 47, 67;
  --bs-primary-text-emphasis: #ed6ebb;
  --bs-secondary-text-emphasis: #eff0f5;
  --bs-success-text-emphasis: #76dcd7;
  --bs-info-text-emphasis: #9f9df7;
  --bs-warning-text-emphasis: #ffcb66;
  --bs-danger-text-emphasis: #fa98a2;
  --bs-light-text-emphasis: #f5f8fa;
  --bs-dark-text-emphasis: #e4e6ef;
  --bs-primary-bg-subtle: #2d031c;
  --bs-secondary-bg-subtle: #2e2e30;
  --bs-success-bg-subtle: #052726;
  --bs-info-bg-subtle: #131230;
  --bs-warning-bg-subtle: #332200;
  --bs-danger-bg-subtle: #311114;
  --bs-light-bg-subtle: #3f4254;
  --bs-dark-bg-subtle: #20212a;
  --bs-primary-border-subtle: #870855;
  --bs-secondary-border-subtle: #898a8f;
  --bs-success-border-subtle: #107671;
  --bs-info-border-subtle: #393791;
  --bs-warning-border-subtle: #996500;
  --bs-danger-border-subtle: #94323c;
  --bs-light-border-subtle: #5e6278;
  --bs-dark-border-subtle: #3f4254;
  --bs-heading-color: inherit;
  --bs-link-color: #ed6ebb;
  --bs-link-hover-color: #f18bc9;
  --bs-link-color-rgb: 237, 110, 187;
  --bs-link-hover-color-rgb: 241, 139, 201;
  --bs-code-color: #ed6ebb;
  --bs-highlight-color: #e4e6ef;
  --bs-highlight-bg: #664300;
  --bs-border-color: #5e6278;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #76dcd7;
  --bs-form-valid-border-color: #76dcd7;
  --bs-form-invalid-color: #fa98a2;
  --bs-form-invalid-border-color: #fa98a2;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 1.75rem;
  }
}

h2, .h2 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.5rem;
  }
}

h3, .h3 {
  font-size: calc(1.26rem + 0.12vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.35rem;
  }
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1.15rem;
}

h6, .h6 {
  font-size: 1.075rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 600;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.275rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #7e8299;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: var(--bs-box-shadow-sm);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.75rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.75rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.25rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.25rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.5rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.5rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 1.75rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 1.75rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 2rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 2rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 2.25rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 2.25rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 2.5rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 2.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-7,
.gx-sm-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-sm-7,
.gy-sm-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-sm-8,
.gx-sm-8 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-8,
.gy-sm-8 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-9,
.gx-sm-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-sm-9,
.gy-sm-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-sm-10,
.gx-sm-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-10,
.gy-sm-10 {
    --bs-gutter-y: 2.5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-7,
.gx-md-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-md-7,
.gy-md-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-md-8,
.gx-md-8 {
    --bs-gutter-x: 2rem;
  }

  .g-md-8,
.gy-md-8 {
    --bs-gutter-y: 2rem;
  }

  .g-md-9,
.gx-md-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-md-9,
.gy-md-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-md-10,
.gx-md-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-10,
.gy-md-10 {
    --bs-gutter-y: 2.5rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-7,
.gx-lg-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-lg-7,
.gy-lg-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-lg-8,
.gx-lg-8 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-8,
.gy-lg-8 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-9,
.gx-lg-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-lg-9,
.gy-lg-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-lg-10,
.gx-lg-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-10,
.gy-lg-10 {
    --bs-gutter-y: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-7,
.gx-xl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xl-7,
.gy-xl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xl-8,
.gx-xl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-8,
.gy-xl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-9,
.gx-xl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xl-9,
.gy-xl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xl-10,
.gx-xl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-10,
.gy-xl-10 {
    --bs-gutter-y: 2.5rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 1.25rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 1.25rem;
  }

  .g-xxl-6,
.gx-xxl-6 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-6,
.gy-xxl-6 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-7,
.gx-xxl-7 {
    --bs-gutter-x: 1.75rem;
  }

  .g-xxl-7,
.gy-xxl-7 {
    --bs-gutter-y: 1.75rem;
  }

  .g-xxl-8,
.gx-xxl-8 {
    --bs-gutter-x: 2rem;
  }

  .g-xxl-8,
.gy-xxl-8 {
    --bs-gutter-y: 2rem;
  }

  .g-xxl-9,
.gx-xxl-9 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xxl-9,
.gy-xxl-9 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xxl-10,
.gx-xxl-10 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xxl-10,
.gy-xxl-10 {
    --bs-gutter-y: 2.5rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: #151521;
  --bs-table-bg: transparent;
  --bs-table-border-color: #eff2f5;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #151521;
  --bs-table-striped-bg: rgba(245, 248, 250, 0.75);
  --bs-table-active-color: #151521;
  --bs-table-active-bg: #f5f8fa;
  --bs-table-hover-color: #151521;
  --bs-table-hover-bg: #f5f8fa;
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(1px * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000000;
  --bs-table-bg: #f9cfe8;
  --bs-table-border-color: #e0bad1;
  --bs-table-striped-bg: #edc5dc;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e0bad1;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e6bfd7;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000000;
  --bs-table-bg: #fafafc;
  --bs-table-border-color: #e1e1e3;
  --bs-table-striped-bg: #eeeeef;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e1e1e3;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #e7e7e9;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000000;
  --bs-table-bg: #d1f3f2;
  --bs-table-border-color: #bcdbda;
  --bs-table-striped-bg: #c7e7e6;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #bcdbda;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #c1e1e0;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000000;
  --bs-table-bg: #dfdefc;
  --bs-table-border-color: #c9c8e3;
  --bs-table-striped-bg: #d4d3ef;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #c9c8e3;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #cecde9;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000000;
  --bs-table-bg: #ffeecc;
  --bs-table-border-color: #e6d6b8;
  --bs-table-striped-bg: #f2e2c2;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e6d6b8;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #ecdcbd;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000000;
  --bs-table-bg: #fddde0;
  --bs-table-border-color: #e4c7ca;
  --bs-table-striped-bg: #f0d2d5;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #e4c7ca;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #eacccf;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000000;
  --bs-table-bg: #eff2f5;
  --bs-table-border-color: #d7dadd;
  --bs-table-striped-bg: #e3e6e9;
  --bs-table-striped-color: #000000;
  --bs-table-active-bg: #d7dadd;
  --bs-table-active-color: #000000;
  --bs-table-hover-bg: #dde0e3;
  --bs-table-hover-color: #000000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #ffffff;
  --bs-table-bg: #3f4254;
  --bs-table-border-color: #525565;
  --bs-table-striped-bg: #494b5d;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: #525565;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #4d5061;
  --bs-table-hover-color: #ffffff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  font-size: 1.05rem;
  font-weight: 500;
}

.col-form-label {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.825rem + 1px);
  padding-bottom: calc(0.825rem + 1px);
  font-size: 1.15rem;
}

.col-form-label-sm {
  padding-top: calc(0.55rem + 1px);
  padding-bottom: calc(0.55rem + 1px);
  font-size: 0.925rem;
}

.form-text {
  margin-top: 0.5rem;
  font-size: 0.925rem;
  color: #a1a5b7;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #151521;
  appearance: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  border-radius: 0.475rem;
  box-shadow: var(--bs-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #151521;
  background-color: #ffffff;
  border-color: #b5b5c3;
  outline: 0;
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(225, 14, 141, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: #a1a5b7;
  opacity: 1;
}
.form-control:disabled {
  background-color: #eff2f5;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.75rem 1rem;
  margin: -0.75rem -1rem;
  margin-inline-end: 1rem;
  color: #151521;
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #151521;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 1.1rem + calc(1px * 2));
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  border-radius: 0.475rem;
}
.form-control-sm::file-selector-button {
  padding: 0.55rem 0.75rem;
  margin: -0.55rem -0.75rem;
  margin-inline-end: 0.75rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.65rem + calc(1px * 2));
  padding: 0.825rem 1.5rem;
  font-size: 1.15rem;
  border-radius: 0.475rem;
}
.form-control-lg::file-selector-button {
  padding: 0.825rem 1.5rem;
  margin: -0.825rem -1.5rem;
  margin-inline-end: 1.5rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.5rem + calc(1px * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 1.1rem + calc(1px * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.65rem + calc(1px * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 1.5rem + calc(1px * 2));
  padding: 0.75rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.475rem;
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 0.475rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 1.1rem + calc(1px * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1.65rem + calc(1px * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237e8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.75rem 3rem 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #151521;
  appearance: none;
  background-color: #ffffff;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid #e4e6ef;
  border-radius: 0.475rem;
  box-shadow: var(--bs-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #b5b5c3;
  outline: 0;
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(225, 14, 141, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #eff2f5;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #151521;
}

.form-select-sm {
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
  padding-left: 0.75rem;
  font-size: 0.925rem;
  border-radius: 0.475rem;
}

.form-select-lg {
  padding-top: 0.825rem;
  padding-bottom: 0.825rem;
  padding-left: 1.5rem;
  font-size: 1.15rem;
  border-radius: 0.475rem;
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23e4e6ef' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 2.35rem;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -2.35rem;
}

.form-check-reverse {
  padding-right: 2.35rem;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -2.35rem;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: #f5f8fa;
  flex-shrink: 0;
  width: 1.85rem;
  height: 1.85rem;
  margin-top: -0.175rem;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  print-color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}
.form-check-input[type=checkbox] {
  border-radius: 0.45em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #b5b5c3;
  outline: 0;
  box-shadow: none;
}
.form-check-input:checked {
  background-color: #e10e8d;
  border-color: #e10e8d;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 11' width='13' height='11' fill='none'%3e%3cpath d='M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #e10e8d;
  border-color: #e10e8d;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 3.75rem;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 3.25rem;
  margin-left: -3.75rem;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 3.25rem;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23b5b5c3'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 3.75rem;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -3.75rem;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.6;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f5f8fa, 0 0 0 0.25rem rgba(225, 14, 141, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f5f8fa, 0 0 0 0.25rem rgba(225, 14, 141, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #e10e8d;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #f6b7dd;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--bs-box-shadow-inset);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #e10e8d;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #f6b7dd;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--bs-box-shadow-inset);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(1px * 2));
  min-height: calc(3.5rem + calc(1px * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 1rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.5rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #ffffff;
  border-radius: 0.475rem;
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #7e8299;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: #eff2f5;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #151521;
  text-align: center;
  white-space: nowrap;
  background-color: #f5f8fa;
  border: 1px solid #e4e6ef;
  border-radius: 0.475rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.825rem 1.5rem;
  font-size: 1.15rem;
  border-radius: 0.475rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  border-radius: 0.475rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(1px * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.925rem;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.925rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: 3px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231BC5BC' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231BC5BC' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 5.5rem;
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 1.5rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.925rem;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.75rem 1rem;
  margin-top: 0.1rem;
  font-size: 0.925rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: 3px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F65464'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F65464' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.5rem);
  background-position: top calc(0.375em + 0.375rem) right calc(0.375em + 0.375rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F65464'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F65464' stroke='none'/%3e%3c/svg%3e");
  padding-right: 5.5rem;
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: var(--bs-box-shadow-inset), 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 1.5rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.75rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size:1.1rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.475rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.6;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  text-decoration: none;
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
  box-shadow: var(--bs-btn-active-shadow);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
  box-shadow: none;
}

.btn-white {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #ffffff;
}

.btn-light {
  --bs-btn-color: #000000;
  --bs-btn-bg: #eff2f5;
  --bs-btn-border-color: #eff2f5;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #cbced0;
  --bs-btn-hover-border-color: #bfc2c4;
  --bs-btn-focus-shadow-rgb: 203, 206, 208;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #bfc2c4;
  --bs-btn-active-border-color: #b3b6b8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #eff2f5;
  --bs-btn-disabled-border-color: #eff2f5;
}

.btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #e10e8d;
  --bs-btn-border-color: #e10e8d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #bf0c78;
  --bs-btn-hover-border-color: #b40b71;
  --bs-btn-focus-shadow-rgb: 230, 50, 158;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #b40b71;
  --bs-btn-active-border-color: #a90b6a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #e10e8d;
  --bs-btn-disabled-border-color: #e10e8d;
}

.btn-secondary {
  --bs-btn-color: #000000;
  --bs-btn-bg: #e4e6ef;
  --bs-btn-border-color: #e4e6ef;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e8eaf1;
  --bs-btn-hover-border-color: #e7e9f1;
  --bs-btn-focus-shadow-rgb: 194, 196, 203;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e9ebf2;
  --bs-btn-active-border-color: #e7e9f1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #e4e6ef;
  --bs-btn-disabled-border-color: #e4e6ef;
}

.btn-success {
  --bs-btn-color: #000000;
  --bs-btn-bg: #1BC5BC;
  --bs-btn-border-color: #1BC5BC;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #3dcec6;
  --bs-btn-hover-border-color: #32cbc3;
  --bs-btn-focus-shadow-rgb: 23, 167, 160;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #49d1c9;
  --bs-btn-active-border-color: #32cbc3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #1BC5BC;
  --bs-btn-disabled-border-color: #1BC5BC;
}

.btn-info {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #5F5CF1;
  --bs-btn-border-color: #5F5CF1;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #514ecd;
  --bs-btn-hover-border-color: #4c4ac1;
  --bs-btn-focus-shadow-rgb: 119, 116, 243;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4c4ac1;
  --bs-btn-active-border-color: #4745b5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #5F5CF1;
  --bs-btn-disabled-border-color: #5F5CF1;
}

.btn-warning {
  --bs-btn-color: #000000;
  --bs-btn-bg: #FFA800;
  --bs-btn-border-color: #FFA800;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffb526;
  --bs-btn-hover-border-color: #ffb11a;
  --bs-btn-focus-shadow-rgb: 217, 143, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffb933;
  --bs-btn-active-border-color: #ffb11a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #FFA800;
  --bs-btn-disabled-border-color: #FFA800;
}

.btn-danger {
  --bs-btn-color: #000000;
  --bs-btn-bg: #F65464;
  --bs-btn-border-color: #F65464;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f76e7b;
  --bs-btn-hover-border-color: #f76574;
  --bs-btn-focus-shadow-rgb: 209, 71, 85;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f87683;
  --bs-btn-active-border-color: #f76574;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #F65464;
  --bs-btn-disabled-border-color: #F65464;
}

.btn-dark {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #3f4254;
  --bs-btn-border-color: #3f4254;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #5c5e6e;
  --bs-btn-hover-border-color: #525565;
  --bs-btn-focus-shadow-rgb: 92, 94, 110;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #656876;
  --bs-btn-active-border-color: #525565;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #3f4254;
  --bs-btn-disabled-border-color: #3f4254;
}

.btn-black {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #000000;
  --bs-btn-disabled-border-color: #000000;
}

.btn-muted {
  --bs-btn-color: #000000;
  --bs-btn-bg: #a1a5b7;
  --bs-btn-border-color: #a1a5b7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #afb3c2;
  --bs-btn-hover-border-color: #aaaebe;
  --bs-btn-focus-shadow-rgb: 137, 140, 156;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #b4b7c5;
  --bs-btn-active-border-color: #aaaebe;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #a1a5b7;
  --bs-btn-disabled-border-color: #a1a5b7;
}

.btn-blue {
  --bs-btn-color: #000000;
  --bs-btn-bg: #3699FF;
  --bs-btn-border-color: #3699FF;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #54a8ff;
  --bs-btn-hover-border-color: #4aa3ff;
  --bs-btn-focus-shadow-rgb: 46, 130, 217;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #5eadff;
  --bs-btn-active-border-color: #4aa3ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #3699FF;
  --bs-btn-disabled-border-color: #3699FF;
}

.btn-indigo {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #6610f2;
  --bs-btn-border-color: #6610f2;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #570ece;
  --bs-btn-hover-border-color: #520dc2;
  --bs-btn-focus-shadow-rgb: 125, 52, 244;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #520dc2;
  --bs-btn-active-border-color: #4d0cb6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #6610f2;
  --bs-btn-disabled-border-color: #6610f2;
}

.btn-purple {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #5F5CF1;
  --bs-btn-border-color: #5F5CF1;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #514ecd;
  --bs-btn-hover-border-color: #4c4ac1;
  --bs-btn-focus-shadow-rgb: 119, 116, 243;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #4c4ac1;
  --bs-btn-active-border-color: #4745b5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #5F5CF1;
  --bs-btn-disabled-border-color: #5F5CF1;
}

.btn-pink {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #d63384;
  --bs-btn-border-color: #d63384;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #b62b70;
  --bs-btn-hover-border-color: #ab296a;
  --bs-btn-focus-shadow-rgb: 220, 82, 150;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ab296a;
  --bs-btn-active-border-color: #a12663;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #d63384;
  --bs-btn-disabled-border-color: #d63384;
}

.btn-red {
  --bs-btn-color: #000000;
  --bs-btn-bg: #F65464;
  --bs-btn-border-color: #F65464;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f76e7b;
  --bs-btn-hover-border-color: #f76574;
  --bs-btn-focus-shadow-rgb: 209, 71, 85;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f87683;
  --bs-btn-active-border-color: #f76574;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #F65464;
  --bs-btn-disabled-border-color: #F65464;
}

.btn-orange {
  --bs-btn-color: #000000;
  --bs-btn-bg: #fd7e14;
  --bs-btn-border-color: #fd7e14;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #fd9137;
  --bs-btn-hover-border-color: #fd8b2c;
  --bs-btn-focus-shadow-rgb: 215, 107, 17;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #fd9843;
  --bs-btn-active-border-color: #fd8b2c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #fd7e14;
  --bs-btn-disabled-border-color: #fd7e14;
}

.btn-yellow {
  --bs-btn-color: #000000;
  --bs-btn-bg: #FFA800;
  --bs-btn-border-color: #FFA800;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffb526;
  --bs-btn-hover-border-color: #ffb11a;
  --bs-btn-focus-shadow-rgb: 217, 143, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffb933;
  --bs-btn-active-border-color: #ffb11a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #FFA800;
  --bs-btn-disabled-border-color: #FFA800;
}

.btn-green {
  --bs-btn-color: #000000;
  --bs-btn-bg: #1BC5BC;
  --bs-btn-border-color: #1BC5BC;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #3dcec6;
  --bs-btn-hover-border-color: #32cbc3;
  --bs-btn-focus-shadow-rgb: 23, 167, 160;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #49d1c9;
  --bs-btn-active-border-color: #32cbc3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #1BC5BC;
  --bs-btn-disabled-border-color: #1BC5BC;
}

.btn-teal {
  --bs-btn-color: #000000;
  --bs-btn-bg: #20c997;
  --bs-btn-border-color: #20c997;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #41d1a7;
  --bs-btn-hover-border-color: #36cea1;
  --bs-btn-focus-shadow-rgb: 27, 171, 128;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #4dd4ac;
  --bs-btn-active-border-color: #36cea1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #20c997;
  --bs-btn-disabled-border-color: #20c997;
}

.btn-cyan {
  --bs-btn-color: #000000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-gray {
  --bs-btn-color: #000000;
  --bs-btn-bg: #7e8299;
  --bs-btn-border-color: #7e8299;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #9195a8;
  --bs-btn-hover-border-color: #8b8fa3;
  --bs-btn-focus-shadow-rgb: 107, 111, 130;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #989bad;
  --bs-btn-active-border-color: #8b8fa3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #7e8299;
  --bs-btn-disabled-border-color: #7e8299;
}

.btn-body-bg {
  --bs-btn-color: #000000;
  --bs-btn-bg: #f5f8fa;
  --bs-btn-border-color: #f5f8fa;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f7f9fb;
  --bs-btn-hover-border-color: #f6f9fb;
  --bs-btn-focus-shadow-rgb: 208, 211, 213;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f7f9fb;
  --bs-btn-active-border-color: #f6f9fb;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #f5f8fa;
  --bs-btn-disabled-border-color: #f5f8fa;
}

.btn-body-text {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #151521;
  --bs-btn-border-color: #151521;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #12121c;
  --bs-btn-hover-border-color: #11111a;
  --bs-btn-focus-shadow-rgb: 56, 56, 66;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #11111a;
  --bs-btn-active-border-color: #101019;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #151521;
  --bs-btn-disabled-border-color: #151521;
}

.btn-toolbar-bg {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #ffffff;
}

.btn-toolbar-text {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #1a1a27;
  --bs-btn-border-color: #1a1a27;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #161621;
  --bs-btn-hover-border-color: #15151f;
  --bs-btn-focus-shadow-rgb: 60, 60, 71;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #15151f;
  --bs-btn-active-border-color: #14141d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #1a1a27;
  --bs-btn-disabled-border-color: #1a1a27;
}

.btn-focus-bg {
  --bs-btn-color: #000000;
  --bs-btn-bg: #eef3f7;
  --bs-btn-border-color: #eef3f7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f1f5f8;
  --bs-btn-hover-border-color: #f0f4f8;
  --bs-btn-focus-shadow-rgb: 202, 207, 210;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f1f5f9;
  --bs-btn-active-border-color: #f0f4f8;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #eef3f7;
  --bs-btn-disabled-border-color: #eef3f7;
}

.btn-tabbar-text {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #171723;
  --bs-btn-border-color: #171723;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #14141e;
  --bs-btn-hover-border-color: #12121c;
  --bs-btn-focus-shadow-rgb: 58, 58, 68;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #12121c;
  --bs-btn-active-border-color: #11111a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #171723;
  --bs-btn-disabled-border-color: #171723;
}

.btn-card-bg {
  --bs-btn-color: #000000;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #ffffff;
}

.btn-card-text {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #1e1e2d;
  --bs-btn-border-color: #1e1e2d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1a1a26;
  --bs-btn-hover-border-color: #181824;
  --bs-btn-focus-shadow-rgb: 64, 64, 77;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #181824;
  --bs-btn-active-border-color: #171722;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #1e1e2d;
  --bs-btn-disabled-border-color: #1e1e2d;
}

.btn-item-bg {
  --bs-btn-color: #000000;
  --bs-btn-bg: #e4e6ef;
  --bs-btn-border-color: #e4e6ef;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e8eaf1;
  --bs-btn-hover-border-color: #e7e9f1;
  --bs-btn-focus-shadow-rgb: 194, 196, 203;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e9ebf2;
  --bs-btn-active-border-color: #e7e9f1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: #e4e6ef;
  --bs-btn-disabled-border-color: #e4e6ef;
}

.btn-item-text {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #1b1b29;
  --bs-btn-border-color: #1b1b29;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #171723;
  --bs-btn-hover-border-color: #161621;
  --bs-btn-focus-shadow-rgb: 61, 61, 73;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #161621;
  --bs-btn-active-border-color: #14141f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #1b1b29;
  --bs-btn-disabled-border-color: #1b1b29;
}

.btn-outline-white {
  --bs-btn-color: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffffff;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #eff2f5;
  --bs-btn-border-color: #eff2f5;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #eff2f5;
  --bs-btn-hover-border-color: #eff2f5;
  --bs-btn-focus-shadow-rgb: 239, 242, 245;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #eff2f5;
  --bs-btn-active-border-color: #eff2f5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #eff2f5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #eff2f5;
  --bs-gradient: none;
}

.btn-outline-primary {
  --bs-btn-color: #e10e8d;
  --bs-btn-border-color: #e10e8d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #e10e8d;
  --bs-btn-hover-border-color: #e10e8d;
  --bs-btn-focus-shadow-rgb: 225, 14, 141;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #e10e8d;
  --bs-btn-active-border-color: #e10e8d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e10e8d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e10e8d;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #e4e6ef;
  --bs-btn-border-color: #e4e6ef;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e4e6ef;
  --bs-btn-hover-border-color: #e4e6ef;
  --bs-btn-focus-shadow-rgb: 228, 230, 239;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e4e6ef;
  --bs-btn-active-border-color: #e4e6ef;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e4e6ef;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e4e6ef;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #1BC5BC;
  --bs-btn-border-color: #1BC5BC;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #1BC5BC;
  --bs-btn-hover-border-color: #1BC5BC;
  --bs-btn-focus-shadow-rgb: 27, 197, 188;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #1BC5BC;
  --bs-btn-active-border-color: #1BC5BC;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1BC5BC;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1BC5BC;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #5F5CF1;
  --bs-btn-border-color: #5F5CF1;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #5F5CF1;
  --bs-btn-hover-border-color: #5F5CF1;
  --bs-btn-focus-shadow-rgb: 95, 92, 241;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #5F5CF1;
  --bs-btn-active-border-color: #5F5CF1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5F5CF1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5F5CF1;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #FFA800;
  --bs-btn-border-color: #FFA800;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #FFA800;
  --bs-btn-hover-border-color: #FFA800;
  --bs-btn-focus-shadow-rgb: 255, 168, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #FFA800;
  --bs-btn-active-border-color: #FFA800;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFA800;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFA800;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #F65464;
  --bs-btn-border-color: #F65464;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #F65464;
  --bs-btn-hover-border-color: #F65464;
  --bs-btn-focus-shadow-rgb: 246, 84, 100;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #F65464;
  --bs-btn-active-border-color: #F65464;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F65464;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F65464;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #3f4254;
  --bs-btn-border-color: #3f4254;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #3f4254;
  --bs-btn-hover-border-color: #3f4254;
  --bs-btn-focus-shadow-rgb: 63, 66, 84;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3f4254;
  --bs-btn-active-border-color: #3f4254;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3f4254;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3f4254;
  --bs-gradient: none;
}

.btn-outline-black {
  --bs-btn-color: #000000;
  --bs-btn-border-color: #000000;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #000000;
  --bs-btn-hover-border-color: #000000;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #000000;
  --bs-btn-active-border-color: #000000;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #000000;
  --bs-gradient: none;
}

.btn-outline-muted {
  --bs-btn-color: #a1a5b7;
  --bs-btn-border-color: #a1a5b7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #a1a5b7;
  --bs-btn-hover-border-color: #a1a5b7;
  --bs-btn-focus-shadow-rgb: 161, 165, 183;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #a1a5b7;
  --bs-btn-active-border-color: #a1a5b7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #a1a5b7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #a1a5b7;
  --bs-gradient: none;
}

.btn-outline-blue {
  --bs-btn-color: #3699FF;
  --bs-btn-border-color: #3699FF;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #3699FF;
  --bs-btn-hover-border-color: #3699FF;
  --bs-btn-focus-shadow-rgb: 54, 153, 255;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #3699FF;
  --bs-btn-active-border-color: #3699FF;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3699FF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3699FF;
  --bs-gradient: none;
}

.btn-outline-indigo {
  --bs-btn-color: #6610f2;
  --bs-btn-border-color: #6610f2;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #6610f2;
  --bs-btn-hover-border-color: #6610f2;
  --bs-btn-focus-shadow-rgb: 102, 16, 242;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #6610f2;
  --bs-btn-active-border-color: #6610f2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6610f2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6610f2;
  --bs-gradient: none;
}

.btn-outline-purple {
  --bs-btn-color: #5F5CF1;
  --bs-btn-border-color: #5F5CF1;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #5F5CF1;
  --bs-btn-hover-border-color: #5F5CF1;
  --bs-btn-focus-shadow-rgb: 95, 92, 241;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #5F5CF1;
  --bs-btn-active-border-color: #5F5CF1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5F5CF1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5F5CF1;
  --bs-gradient: none;
}

.btn-outline-pink {
  --bs-btn-color: #d63384;
  --bs-btn-border-color: #d63384;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d63384;
  --bs-btn-hover-border-color: #d63384;
  --bs-btn-focus-shadow-rgb: 214, 51, 132;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #d63384;
  --bs-btn-active-border-color: #d63384;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #d63384;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #d63384;
  --bs-gradient: none;
}

.btn-outline-red {
  --bs-btn-color: #F65464;
  --bs-btn-border-color: #F65464;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #F65464;
  --bs-btn-hover-border-color: #F65464;
  --bs-btn-focus-shadow-rgb: 246, 84, 100;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #F65464;
  --bs-btn-active-border-color: #F65464;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F65464;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F65464;
  --bs-gradient: none;
}

.btn-outline-orange {
  --bs-btn-color: #fd7e14;
  --bs-btn-border-color: #fd7e14;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #fd7e14;
  --bs-btn-hover-border-color: #fd7e14;
  --bs-btn-focus-shadow-rgb: 253, 126, 20;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #fd7e14;
  --bs-btn-active-border-color: #fd7e14;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fd7e14;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fd7e14;
  --bs-gradient: none;
}

.btn-outline-yellow {
  --bs-btn-color: #FFA800;
  --bs-btn-border-color: #FFA800;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #FFA800;
  --bs-btn-hover-border-color: #FFA800;
  --bs-btn-focus-shadow-rgb: 255, 168, 0;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #FFA800;
  --bs-btn-active-border-color: #FFA800;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFA800;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FFA800;
  --bs-gradient: none;
}

.btn-outline-green {
  --bs-btn-color: #1BC5BC;
  --bs-btn-border-color: #1BC5BC;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #1BC5BC;
  --bs-btn-hover-border-color: #1BC5BC;
  --bs-btn-focus-shadow-rgb: 27, 197, 188;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #1BC5BC;
  --bs-btn-active-border-color: #1BC5BC;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1BC5BC;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1BC5BC;
  --bs-gradient: none;
}

.btn-outline-teal {
  --bs-btn-color: #20c997;
  --bs-btn-border-color: #20c997;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #20c997;
  --bs-btn-hover-border-color: #20c997;
  --bs-btn-focus-shadow-rgb: 32, 201, 151;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #20c997;
  --bs-btn-active-border-color: #20c997;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #20c997;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #20c997;
  --bs-gradient: none;
}

.btn-outline-cyan {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-gray {
  --bs-btn-color: #7e8299;
  --bs-btn-border-color: #7e8299;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #7e8299;
  --bs-btn-hover-border-color: #7e8299;
  --bs-btn-focus-shadow-rgb: 126, 130, 153;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #7e8299;
  --bs-btn-active-border-color: #7e8299;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #7e8299;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #7e8299;
  --bs-gradient: none;
}

.btn-outline-body-bg {
  --bs-btn-color: #f5f8fa;
  --bs-btn-border-color: #f5f8fa;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #f5f8fa;
  --bs-btn-hover-border-color: #f5f8fa;
  --bs-btn-focus-shadow-rgb: 245, 248, 250;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #f5f8fa;
  --bs-btn-active-border-color: #f5f8fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f5f8fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f5f8fa;
  --bs-gradient: none;
}

.btn-outline-body-text {
  --bs-btn-color: #151521;
  --bs-btn-border-color: #151521;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #151521;
  --bs-btn-hover-border-color: #151521;
  --bs-btn-focus-shadow-rgb: 21, 21, 33;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #151521;
  --bs-btn-active-border-color: #151521;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #151521;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #151521;
  --bs-gradient: none;
}

.btn-outline-toolbar-bg {
  --bs-btn-color: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffffff;
  --bs-gradient: none;
}

.btn-outline-toolbar-text {
  --bs-btn-color: #1a1a27;
  --bs-btn-border-color: #1a1a27;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1a1a27;
  --bs-btn-hover-border-color: #1a1a27;
  --bs-btn-focus-shadow-rgb: 26, 26, 39;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1a1a27;
  --bs-btn-active-border-color: #1a1a27;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1a1a27;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1a1a27;
  --bs-gradient: none;
}

.btn-outline-focus-bg {
  --bs-btn-color: #eef3f7;
  --bs-btn-border-color: #eef3f7;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #eef3f7;
  --bs-btn-hover-border-color: #eef3f7;
  --bs-btn-focus-shadow-rgb: 238, 243, 247;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #eef3f7;
  --bs-btn-active-border-color: #eef3f7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #eef3f7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #eef3f7;
  --bs-gradient: none;
}

.btn-outline-tabbar-text {
  --bs-btn-color: #171723;
  --bs-btn-border-color: #171723;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #171723;
  --bs-btn-hover-border-color: #171723;
  --bs-btn-focus-shadow-rgb: 23, 23, 35;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #171723;
  --bs-btn-active-border-color: #171723;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #171723;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #171723;
  --bs-gradient: none;
}

.btn-outline-card-bg {
  --bs-btn-color: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffffff;
  --bs-gradient: none;
}

.btn-outline-card-text {
  --bs-btn-color: #1e1e2d;
  --bs-btn-border-color: #1e1e2d;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1e1e2d;
  --bs-btn-hover-border-color: #1e1e2d;
  --bs-btn-focus-shadow-rgb: 30, 30, 45;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1e1e2d;
  --bs-btn-active-border-color: #1e1e2d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1e1e2d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1e1e2d;
  --bs-gradient: none;
}

.btn-outline-item-bg {
  --bs-btn-color: #e4e6ef;
  --bs-btn-border-color: #e4e6ef;
  --bs-btn-hover-color: #000000;
  --bs-btn-hover-bg: #e4e6ef;
  --bs-btn-hover-border-color: #e4e6ef;
  --bs-btn-focus-shadow-rgb: 228, 230, 239;
  --bs-btn-active-color: #000000;
  --bs-btn-active-bg: #e4e6ef;
  --bs-btn-active-border-color: #e4e6ef;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e4e6ef;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e4e6ef;
  --bs-gradient: none;
}

.btn-outline-item-text {
  --bs-btn-color: #1b1b29;
  --bs-btn-border-color: #1b1b29;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #1b1b29;
  --bs-btn-hover-border-color: #1b1b29;
  --bs-btn-focus-shadow-rgb: 27, 27, 41;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1b1b29;
  --bs-btn-active-border-color: #1b1b29;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1b1b29;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1b1b29;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #e10e8d;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #a90768;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #a90768;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #7e8299;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 230, 50, 158;
  text-decoration: none;
}
.btn-link:hover, .btn-link:focus-visible {
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.825rem;
  --bs-btn-padding-x: 1.75rem;
  --bs-btn-font-size:1.15rem;
  --bs-btn-border-radius: 0.475rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.55rem;
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-font-size:0.925rem;
  --bs-btn-border-radius: 0.475rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size:1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.475rem;
  --bs-dropdown-border-width: 0;
  --bs-dropdown-inner-border-radius: calc(0.475rem - 0);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #e10e8d;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #7e8299;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  box-shadow: var(--bs-dropdown-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.925rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #e4e6ef;
  --bs-dropdown-bg: #3f4254;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #e4e6ef;
  --bs-dropdown-link-hover-color: #ffffff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #ffffff;
  --bs-dropdown-link-active-bg: #e10e8d;
  --bs-dropdown-link-disabled-color: #a1a5b7;
  --bs-dropdown-header-color: #a1a5b7;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.475rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(1px * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(1px * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
  text-decoration: none;
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(225, 14, 141, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #eff2f5;
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) #eff2f5;
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #ffffff;
  --bs-nav-pills-link-active-bg: #e10e8d;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 500;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.44375rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.075rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.075rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2821, 21, 33, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: 0.475rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
  text-decoration: none;
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  box-shadow: none;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: #eff2f5;
  --bs-card-border-radius: 0.475rem;
  --bs-card-box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  --bs-card-inner-border-radius: calc(0.475rem - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #ffffff;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: #1e1e2d;
  --bs-card-bg: #ffffff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  box-shadow: var(--bs-card-box-shadow);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: #eff2f5;
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.5rem;
  --bs-accordion-btn-padding-y: 1.5rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23a1a5b7'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.15rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e10e8d'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 1.5rem;
  --bs-accordion-body-padding-y: 1.5rem;
  --bs-accordion-active-color: #e10e8d;
  --bs-accordion-active-bg: #f5f8fa;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ed6ebb'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ed6ebb'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #7e8299;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #e10e8d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size:1.075rem;
  --bs-pagination-color: #5e6278;
  --bs-pagination-bg: transparent;
  --bs-pagination-border-width: 0;
  --bs-pagination-border-color: transparent;
  --bs-pagination-border-radius: 0.475rem;
  --bs-pagination-hover-color: #e10e8d;
  --bs-pagination-hover-bg: #fff1fe;
  --bs-pagination-hover-border-color: transparent;
  --bs-pagination-focus-color: #e10e8d;
  --bs-pagination-focus-bg: #fff1fe;
  --bs-pagination-focus-box-shadow: none;
  --bs-pagination-active-color: #ffffff;
  --bs-pagination-active-bg: #e10e8d;
  --bs-pagination-active-border-color: transparent;
  --bs-pagination-disabled-color: #b5b5c3;
  --bs-pagination-disabled-bg: transparent;
  --bs-pagination-disabled-border-color: transparent;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  text-decoration: none;
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(0 * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size:1.075rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size:0.925rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.85em;
  --bs-badge-padding-y: 0.5em;
  --bs-badge-font-size:0.85em;
  --bs-badge-font-weight: 500;
  --bs-badge-color: #ffffff;
  --bs-badge-border-radius: 0.475rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 500;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-white {
  --bs-alert-color: var(--bs-white-text-emphasis);
  --bs-alert-bg: var(--bs-white-bg-subtle);
  --bs-alert-border-color: var(--bs-white-border-subtle);
  --bs-alert-link-color: var(--bs-white-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

.alert-black {
  --bs-alert-color: var(--bs-black-text-emphasis);
  --bs-alert-bg: var(--bs-black-bg-subtle);
  --bs-alert-border-color: var(--bs-black-border-subtle);
  --bs-alert-link-color: var(--bs-black-text-emphasis);
}

.alert-muted {
  --bs-alert-color: var(--bs-muted-text-emphasis);
  --bs-alert-bg: var(--bs-muted-bg-subtle);
  --bs-alert-border-color: var(--bs-muted-border-subtle);
  --bs-alert-link-color: var(--bs-muted-text-emphasis);
}

.alert-blue {
  --bs-alert-color: var(--bs-blue-text-emphasis);
  --bs-alert-bg: var(--bs-blue-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-border-subtle);
  --bs-alert-link-color: var(--bs-blue-text-emphasis);
}

.alert-indigo {
  --bs-alert-color: var(--bs-indigo-text-emphasis);
  --bs-alert-bg: var(--bs-indigo-bg-subtle);
  --bs-alert-border-color: var(--bs-indigo-border-subtle);
  --bs-alert-link-color: var(--bs-indigo-text-emphasis);
}

.alert-purple {
  --bs-alert-color: var(--bs-purple-text-emphasis);
  --bs-alert-bg: var(--bs-purple-bg-subtle);
  --bs-alert-border-color: var(--bs-purple-border-subtle);
  --bs-alert-link-color: var(--bs-purple-text-emphasis);
}

.alert-pink {
  --bs-alert-color: var(--bs-pink-text-emphasis);
  --bs-alert-bg: var(--bs-pink-bg-subtle);
  --bs-alert-border-color: var(--bs-pink-border-subtle);
  --bs-alert-link-color: var(--bs-pink-text-emphasis);
}

.alert-red {
  --bs-alert-color: var(--bs-red-text-emphasis);
  --bs-alert-bg: var(--bs-red-bg-subtle);
  --bs-alert-border-color: var(--bs-red-border-subtle);
  --bs-alert-link-color: var(--bs-red-text-emphasis);
}

.alert-orange {
  --bs-alert-color: var(--bs-orange-text-emphasis);
  --bs-alert-bg: var(--bs-orange-bg-subtle);
  --bs-alert-border-color: var(--bs-orange-border-subtle);
  --bs-alert-link-color: var(--bs-orange-text-emphasis);
}

.alert-yellow {
  --bs-alert-color: var(--bs-yellow-text-emphasis);
  --bs-alert-bg: var(--bs-yellow-bg-subtle);
  --bs-alert-border-color: var(--bs-yellow-border-subtle);
  --bs-alert-link-color: var(--bs-yellow-text-emphasis);
}

.alert-green {
  --bs-alert-color: var(--bs-green-text-emphasis);
  --bs-alert-bg: var(--bs-green-bg-subtle);
  --bs-alert-border-color: var(--bs-green-border-subtle);
  --bs-alert-link-color: var(--bs-green-text-emphasis);
}

.alert-teal {
  --bs-alert-color: var(--bs-teal-text-emphasis);
  --bs-alert-bg: var(--bs-teal-bg-subtle);
  --bs-alert-border-color: var(--bs-teal-border-subtle);
  --bs-alert-link-color: var(--bs-teal-text-emphasis);
}

.alert-cyan {
  --bs-alert-color: var(--bs-cyan-text-emphasis);
  --bs-alert-bg: var(--bs-cyan-bg-subtle);
  --bs-alert-border-color: var(--bs-cyan-border-subtle);
  --bs-alert-link-color: var(--bs-cyan-text-emphasis);
}

.alert-gray {
  --bs-alert-color: var(--bs-gray-text-emphasis);
  --bs-alert-bg: var(--bs-gray-bg-subtle);
  --bs-alert-border-color: var(--bs-gray-border-subtle);
  --bs-alert-link-color: var(--bs-gray-text-emphasis);
}

.alert-body-bg {
  --bs-alert-color: var(--bs-body-bg-text-emphasis);
  --bs-alert-bg: var(--bs-body-bg-bg-subtle);
  --bs-alert-border-color: var(--bs-body-bg-border-subtle);
  --bs-alert-link-color: var(--bs-body-bg-text-emphasis);
}

.alert-body-text {
  --bs-alert-color: var(--bs-body-text-text-emphasis);
  --bs-alert-bg: var(--bs-body-text-bg-subtle);
  --bs-alert-border-color: var(--bs-body-text-border-subtle);
  --bs-alert-link-color: var(--bs-body-text-text-emphasis);
}

.alert-toolbar-bg {
  --bs-alert-color: var(--bs-toolbar-bg-text-emphasis);
  --bs-alert-bg: var(--bs-toolbar-bg-bg-subtle);
  --bs-alert-border-color: var(--bs-toolbar-bg-border-subtle);
  --bs-alert-link-color: var(--bs-toolbar-bg-text-emphasis);
}

.alert-toolbar-text {
  --bs-alert-color: var(--bs-toolbar-text-text-emphasis);
  --bs-alert-bg: var(--bs-toolbar-text-bg-subtle);
  --bs-alert-border-color: var(--bs-toolbar-text-border-subtle);
  --bs-alert-link-color: var(--bs-toolbar-text-text-emphasis);
}

.alert-focus-bg {
  --bs-alert-color: var(--bs-focus-bg-text-emphasis);
  --bs-alert-bg: var(--bs-focus-bg-bg-subtle);
  --bs-alert-border-color: var(--bs-focus-bg-border-subtle);
  --bs-alert-link-color: var(--bs-focus-bg-text-emphasis);
}

.alert-tabbar-text {
  --bs-alert-color: var(--bs-tabbar-text-text-emphasis);
  --bs-alert-bg: var(--bs-tabbar-text-bg-subtle);
  --bs-alert-border-color: var(--bs-tabbar-text-border-subtle);
  --bs-alert-link-color: var(--bs-tabbar-text-text-emphasis);
}

.alert-card-bg {
  --bs-alert-color: var(--bs-card-bg-text-emphasis);
  --bs-alert-bg: var(--bs-card-bg-bg-subtle);
  --bs-alert-border-color: var(--bs-card-bg-border-subtle);
  --bs-alert-link-color: var(--bs-card-bg-text-emphasis);
}

.alert-card-text {
  --bs-alert-color: var(--bs-card-text-text-emphasis);
  --bs-alert-bg: var(--bs-card-text-bg-subtle);
  --bs-alert-border-color: var(--bs-card-text-border-subtle);
  --bs-alert-link-color: var(--bs-card-text-text-emphasis);
}

.alert-item-bg {
  --bs-alert-color: var(--bs-item-bg-text-emphasis);
  --bs-alert-bg: var(--bs-item-bg-bg-subtle);
  --bs-alert-border-color: var(--bs-item-bg-border-subtle);
  --bs-alert-link-color: var(--bs-item-bg-text-emphasis);
}

.alert-item-text {
  --bs-alert-color: var(--bs-item-text-text-emphasis);
  --bs-alert-bg: var(--bs-item-text-bg-subtle);
  --bs-alert-border-color: var(--bs-item-text-border-subtle);
  --bs-alert-link-color: var(--bs-item-text-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size:0.75rem;
  --bs-progress-bg: #f5f8fa;
  --bs-progress-border-radius: 6px;
  --bs-progress-box-shadow: none;
  --bs-progress-bar-color: #ffffff;
  --bs-progress-bar-bg: #e10e8d;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  box-shadow: var(--bs-progress-box-shadow);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #ffffff;
  --bs-list-group-active-bg: #e10e8d;
  --bs-list-group-active-border-color: #e10e8d;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-white {
  --bs-list-group-color: var(--bs-white-text-emphasis);
  --bs-list-group-bg: var(--bs-white-bg-subtle);
  --bs-list-group-border-color: var(--bs-white-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-white-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-white-border-subtle);
  --bs-list-group-active-color: var(--bs-white-bg-subtle);
  --bs-list-group-active-bg: var(--bs-white-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-white-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.list-group-item-black {
  --bs-list-group-color: var(--bs-black-text-emphasis);
  --bs-list-group-bg: var(--bs-black-bg-subtle);
  --bs-list-group-border-color: var(--bs-black-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-black-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-black-border-subtle);
  --bs-list-group-active-color: var(--bs-black-bg-subtle);
  --bs-list-group-active-bg: var(--bs-black-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-black-text-emphasis);
}

.list-group-item-muted {
  --bs-list-group-color: var(--bs-muted-text-emphasis);
  --bs-list-group-bg: var(--bs-muted-bg-subtle);
  --bs-list-group-border-color: var(--bs-muted-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-muted-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-muted-border-subtle);
  --bs-list-group-active-color: var(--bs-muted-bg-subtle);
  --bs-list-group-active-bg: var(--bs-muted-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-muted-text-emphasis);
}

.list-group-item-blue {
  --bs-list-group-color: var(--bs-blue-text-emphasis);
  --bs-list-group-bg: var(--bs-blue-bg-subtle);
  --bs-list-group-border-color: var(--bs-blue-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-blue-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-blue-border-subtle);
  --bs-list-group-active-color: var(--bs-blue-bg-subtle);
  --bs-list-group-active-bg: var(--bs-blue-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-blue-text-emphasis);
}

.list-group-item-indigo {
  --bs-list-group-color: var(--bs-indigo-text-emphasis);
  --bs-list-group-bg: var(--bs-indigo-bg-subtle);
  --bs-list-group-border-color: var(--bs-indigo-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-indigo-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-indigo-border-subtle);
  --bs-list-group-active-color: var(--bs-indigo-bg-subtle);
  --bs-list-group-active-bg: var(--bs-indigo-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-indigo-text-emphasis);
}

.list-group-item-purple {
  --bs-list-group-color: var(--bs-purple-text-emphasis);
  --bs-list-group-bg: var(--bs-purple-bg-subtle);
  --bs-list-group-border-color: var(--bs-purple-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-purple-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-purple-border-subtle);
  --bs-list-group-active-color: var(--bs-purple-bg-subtle);
  --bs-list-group-active-bg: var(--bs-purple-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-purple-text-emphasis);
}

.list-group-item-pink {
  --bs-list-group-color: var(--bs-pink-text-emphasis);
  --bs-list-group-bg: var(--bs-pink-bg-subtle);
  --bs-list-group-border-color: var(--bs-pink-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-pink-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-pink-border-subtle);
  --bs-list-group-active-color: var(--bs-pink-bg-subtle);
  --bs-list-group-active-bg: var(--bs-pink-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-pink-text-emphasis);
}

.list-group-item-red {
  --bs-list-group-color: var(--bs-red-text-emphasis);
  --bs-list-group-bg: var(--bs-red-bg-subtle);
  --bs-list-group-border-color: var(--bs-red-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-red-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-red-border-subtle);
  --bs-list-group-active-color: var(--bs-red-bg-subtle);
  --bs-list-group-active-bg: var(--bs-red-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-red-text-emphasis);
}

.list-group-item-orange {
  --bs-list-group-color: var(--bs-orange-text-emphasis);
  --bs-list-group-bg: var(--bs-orange-bg-subtle);
  --bs-list-group-border-color: var(--bs-orange-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-orange-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-orange-border-subtle);
  --bs-list-group-active-color: var(--bs-orange-bg-subtle);
  --bs-list-group-active-bg: var(--bs-orange-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-orange-text-emphasis);
}

.list-group-item-yellow {
  --bs-list-group-color: var(--bs-yellow-text-emphasis);
  --bs-list-group-bg: var(--bs-yellow-bg-subtle);
  --bs-list-group-border-color: var(--bs-yellow-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-yellow-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-yellow-border-subtle);
  --bs-list-group-active-color: var(--bs-yellow-bg-subtle);
  --bs-list-group-active-bg: var(--bs-yellow-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-yellow-text-emphasis);
}

.list-group-item-green {
  --bs-list-group-color: var(--bs-green-text-emphasis);
  --bs-list-group-bg: var(--bs-green-bg-subtle);
  --bs-list-group-border-color: var(--bs-green-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-green-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-green-border-subtle);
  --bs-list-group-active-color: var(--bs-green-bg-subtle);
  --bs-list-group-active-bg: var(--bs-green-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-green-text-emphasis);
}

.list-group-item-teal {
  --bs-list-group-color: var(--bs-teal-text-emphasis);
  --bs-list-group-bg: var(--bs-teal-bg-subtle);
  --bs-list-group-border-color: var(--bs-teal-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-teal-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-teal-border-subtle);
  --bs-list-group-active-color: var(--bs-teal-bg-subtle);
  --bs-list-group-active-bg: var(--bs-teal-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-teal-text-emphasis);
}

.list-group-item-cyan {
  --bs-list-group-color: var(--bs-cyan-text-emphasis);
  --bs-list-group-bg: var(--bs-cyan-bg-subtle);
  --bs-list-group-border-color: var(--bs-cyan-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-cyan-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-cyan-border-subtle);
  --bs-list-group-active-color: var(--bs-cyan-bg-subtle);
  --bs-list-group-active-bg: var(--bs-cyan-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-cyan-text-emphasis);
}

.list-group-item-gray {
  --bs-list-group-color: var(--bs-gray-text-emphasis);
  --bs-list-group-bg: var(--bs-gray-bg-subtle);
  --bs-list-group-border-color: var(--bs-gray-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-gray-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-gray-border-subtle);
  --bs-list-group-active-color: var(--bs-gray-bg-subtle);
  --bs-list-group-active-bg: var(--bs-gray-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-gray-text-emphasis);
}

.list-group-item-body-bg {
  --bs-list-group-color: var(--bs-body-bg-text-emphasis);
  --bs-list-group-bg: var(--bs-body-bg-bg-subtle);
  --bs-list-group-border-color: var(--bs-body-bg-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-body-bg-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-body-bg-border-subtle);
  --bs-list-group-active-color: var(--bs-body-bg-bg-subtle);
  --bs-list-group-active-bg: var(--bs-body-bg-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-body-bg-text-emphasis);
}

.list-group-item-body-text {
  --bs-list-group-color: var(--bs-body-text-text-emphasis);
  --bs-list-group-bg: var(--bs-body-text-bg-subtle);
  --bs-list-group-border-color: var(--bs-body-text-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-body-text-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-body-text-border-subtle);
  --bs-list-group-active-color: var(--bs-body-text-bg-subtle);
  --bs-list-group-active-bg: var(--bs-body-text-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-body-text-text-emphasis);
}

.list-group-item-toolbar-bg {
  --bs-list-group-color: var(--bs-toolbar-bg-text-emphasis);
  --bs-list-group-bg: var(--bs-toolbar-bg-bg-subtle);
  --bs-list-group-border-color: var(--bs-toolbar-bg-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-toolbar-bg-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-toolbar-bg-border-subtle);
  --bs-list-group-active-color: var(--bs-toolbar-bg-bg-subtle);
  --bs-list-group-active-bg: var(--bs-toolbar-bg-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-toolbar-bg-text-emphasis);
}

.list-group-item-toolbar-text {
  --bs-list-group-color: var(--bs-toolbar-text-text-emphasis);
  --bs-list-group-bg: var(--bs-toolbar-text-bg-subtle);
  --bs-list-group-border-color: var(--bs-toolbar-text-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-toolbar-text-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-toolbar-text-border-subtle);
  --bs-list-group-active-color: var(--bs-toolbar-text-bg-subtle);
  --bs-list-group-active-bg: var(--bs-toolbar-text-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-toolbar-text-text-emphasis);
}

.list-group-item-focus-bg {
  --bs-list-group-color: var(--bs-focus-bg-text-emphasis);
  --bs-list-group-bg: var(--bs-focus-bg-bg-subtle);
  --bs-list-group-border-color: var(--bs-focus-bg-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-focus-bg-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-focus-bg-border-subtle);
  --bs-list-group-active-color: var(--bs-focus-bg-bg-subtle);
  --bs-list-group-active-bg: var(--bs-focus-bg-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-focus-bg-text-emphasis);
}

.list-group-item-tabbar-text {
  --bs-list-group-color: var(--bs-tabbar-text-text-emphasis);
  --bs-list-group-bg: var(--bs-tabbar-text-bg-subtle);
  --bs-list-group-border-color: var(--bs-tabbar-text-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tabbar-text-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tabbar-text-border-subtle);
  --bs-list-group-active-color: var(--bs-tabbar-text-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tabbar-text-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tabbar-text-text-emphasis);
}

.list-group-item-card-bg {
  --bs-list-group-color: var(--bs-card-bg-text-emphasis);
  --bs-list-group-bg: var(--bs-card-bg-bg-subtle);
  --bs-list-group-border-color: var(--bs-card-bg-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-card-bg-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-card-bg-border-subtle);
  --bs-list-group-active-color: var(--bs-card-bg-bg-subtle);
  --bs-list-group-active-bg: var(--bs-card-bg-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-card-bg-text-emphasis);
}

.list-group-item-card-text {
  --bs-list-group-color: var(--bs-card-text-text-emphasis);
  --bs-list-group-bg: var(--bs-card-text-bg-subtle);
  --bs-list-group-border-color: var(--bs-card-text-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-card-text-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-card-text-border-subtle);
  --bs-list-group-active-color: var(--bs-card-text-bg-subtle);
  --bs-list-group-active-bg: var(--bs-card-text-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-card-text-text-emphasis);
}

.list-group-item-item-bg {
  --bs-list-group-color: var(--bs-item-bg-text-emphasis);
  --bs-list-group-bg: var(--bs-item-bg-bg-subtle);
  --bs-list-group-border-color: var(--bs-item-bg-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-item-bg-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-item-bg-border-subtle);
  --bs-list-group-active-color: var(--bs-item-bg-bg-subtle);
  --bs-list-group-active-bg: var(--bs-item-bg-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-item-bg-text-emphasis);
}

.list-group-item-item-text {
  --bs-list-group-color: var(--bs-item-text-text-emphasis);
  --bs-list-group-bg: var(--bs-item-text-bg-subtle);
  --bs-list-group-border-color: var(--bs-item-text-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-item-text-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-item-text-border-subtle);
  --bs-list-group-active-color: var(--bs-item-text-bg-subtle);
  --bs-list-group-active-bg: var(--bs-item-text-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-item-text-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(225, 14, 141, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.475rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size:0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1.75rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #ffffff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 0;
  --bs-modal-border-radius: 0.475rem;
  --bs-modal-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  --bs-modal-inner-border-radius: 0.475rem;
  --bs-modal-header-padding-x: 1.75rem;
  --bs-modal-header-padding-y: 1.75rem;
  --bs-modal-header-padding: 1.75rem 1.75rem;
  --bs-modal-header-border-color: #eff2f5;
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: #eff2f5;
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  box-shadow: var(--bs-modal-box-shadow);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000000;
  --bs-backdrop-opacity: 0.2;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 1rem;
  --bs-tooltip-padding-y: 0.75rem;
  --bs-tooltip-margin: 0;
  --bs-tooltip-font-size:0.925rem;
  --bs-tooltip-color: #3f4254;
  --bs-tooltip-bg: #ffffff;
  --bs-tooltip-border-radius: 3px;
  --bs-tooltip-opacity: 1;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size:0.925rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: #ffffff;
  --bs-popover-border-radius: 0.475rem;
  --bs-popover-inner-border-radius: calc(0.475rem - var(--bs-border-width));
  --bs-popover-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1.25rem;
  --bs-popover-header-padding-y: 1rem;
  --bs-popover-header-font-size:1rem;
  --bs-popover-header-color: #3f4254;
  --bs-popover-header-bg: #ffffff;
  --bs-popover-body-padding-x: 1.25rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #151521;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  box-shadow: var(--bs-popover-box-shadow);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #ffffff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #ffffff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
.carousel-dark .carousel-caption {
  color: #000000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.185em;
  --bs-spinner-animation-speed: 0.65s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.145em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.65s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.3s;
  }
}
.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1.75rem;
  --bs-offcanvas-padding-y: 1.75rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: 0;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--bs-offcanvas-box-shadow);
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.2;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin: calc(-.5 * var(--bs-offcanvas-padding-y)) calc(-.5 * var(--bs-offcanvas-padding-x)) calc(-.5 * var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000000 55%, rgba(0, 0, 0, 0.8) 75%, #000000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-white {
  color: #000000 !important;
  background-color: RGBA(var(--bs-white-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000000 !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000000 !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000000 !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-black {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-black-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-muted {
  color: #000000 !important;
  background-color: RGBA(var(--bs-muted-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue {
  color: #000000 !important;
  background-color: RGBA(var(--bs-blue-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-indigo {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-indigo-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-purple {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-purple-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-pink-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red {
  color: #000000 !important;
  background-color: RGBA(var(--bs-red-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-orange {
  color: #000000 !important;
  background-color: RGBA(var(--bs-orange-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-yellow {
  color: #000000 !important;
  background-color: RGBA(var(--bs-yellow-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green {
  color: #000000 !important;
  background-color: RGBA(var(--bs-green-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-teal {
  color: #000000 !important;
  background-color: RGBA(var(--bs-teal-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-cyan {
  color: #000000 !important;
  background-color: RGBA(var(--bs-cyan-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-gray {
  color: #000000 !important;
  background-color: RGBA(var(--bs-gray-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-body-bg {
  color: #000000 !important;
  background-color: RGBA(var(--bs-body-bg-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-body-text {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-body-text-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-toolbar-bg {
  color: #000000 !important;
  background-color: RGBA(var(--bs-toolbar-bg-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-toolbar-text {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-toolbar-text-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-focus-bg {
  color: #000000 !important;
  background-color: RGBA(var(--bs-focus-bg-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-tabbar-text {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-tabbar-text-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-card-bg {
  color: #000000 !important;
  background-color: RGBA(var(--bs-card-bg-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-card-text {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-card-text-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-item-bg {
  color: #000000 !important;
  background-color: RGBA(var(--bs-item-bg-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-item-text {
  color: #ffffff !important;
  background-color: RGBA(var(--bs-item-text-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-white {
  color: RGBA(var(--bs-white-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-white-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-white:hover, .link-white:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(242, 245, 247, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(242, 245, 247, var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(180, 11, 113, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(180, 11, 113, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(233, 235, 242, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(233, 235, 242, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(73, 209, 201, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(73, 209, 201, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(76, 74, 193, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(76, 74, 193, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 185, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 185, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(248, 118, 131, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(248, 118, 131, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(50, 53, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(50, 53, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-black {
  color: RGBA(var(--bs-black-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-black-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-black:hover, .link-black:focus {
  color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-muted {
  color: RGBA(var(--bs-muted-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-muted-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-muted:hover, .link-muted:focus {
  color: RGBA(180, 183, 197, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(180, 183, 197, var(--bs-link-underline-opacity, 1)) !important;
}

.link-blue {
  color: RGBA(var(--bs-blue-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-blue-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-blue:hover, .link-blue:focus {
  color: RGBA(94, 173, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(94, 173, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-indigo {
  color: RGBA(var(--bs-indigo-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-indigo-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-indigo:hover, .link-indigo:focus {
  color: RGBA(82, 13, 194, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(82, 13, 194, var(--bs-link-underline-opacity, 1)) !important;
}

.link-purple {
  color: RGBA(var(--bs-purple-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-purple-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-purple:hover, .link-purple:focus {
  color: RGBA(76, 74, 193, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(76, 74, 193, var(--bs-link-underline-opacity, 1)) !important;
}

.link-pink {
  color: RGBA(var(--bs-pink-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-pink-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-pink:hover, .link-pink:focus {
  color: RGBA(171, 41, 106, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(171, 41, 106, var(--bs-link-underline-opacity, 1)) !important;
}

.link-red {
  color: RGBA(var(--bs-red-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-red-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-red:hover, .link-red:focus {
  color: RGBA(248, 118, 131, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(248, 118, 131, var(--bs-link-underline-opacity, 1)) !important;
}

.link-orange {
  color: RGBA(var(--bs-orange-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-orange-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-orange:hover, .link-orange:focus {
  color: RGBA(253, 152, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(253, 152, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-yellow {
  color: RGBA(var(--bs-yellow-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-yellow-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-yellow:hover, .link-yellow:focus {
  color: RGBA(255, 185, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 185, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-green {
  color: RGBA(var(--bs-green-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-green-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-green:hover, .link-green:focus {
  color: RGBA(73, 209, 201, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(73, 209, 201, var(--bs-link-underline-opacity, 1)) !important;
}

.link-teal {
  color: RGBA(var(--bs-teal-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-teal-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-teal:hover, .link-teal:focus {
  color: RGBA(77, 212, 172, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(77, 212, 172, var(--bs-link-underline-opacity, 1)) !important;
}

.link-cyan {
  color: RGBA(var(--bs-cyan-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-cyan-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-cyan:hover, .link-cyan:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-gray {
  color: RGBA(var(--bs-gray-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-gray-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-gray:hover, .link-gray:focus {
  color: RGBA(152, 155, 173, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(152, 155, 173, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-bg {
  color: RGBA(var(--bs-body-bg-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-body-bg-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-bg:hover, .link-body-bg:focus {
  color: RGBA(247, 249, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(247, 249, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-text {
  color: RGBA(var(--bs-body-text-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-body-text-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-text:hover, .link-body-text:focus {
  color: RGBA(17, 17, 26, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(17, 17, 26, var(--bs-link-underline-opacity, 1)) !important;
}

.link-toolbar-bg {
  color: RGBA(var(--bs-toolbar-bg-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-toolbar-bg-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-toolbar-bg:hover, .link-toolbar-bg:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-toolbar-text {
  color: RGBA(var(--bs-toolbar-text-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-toolbar-text-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-toolbar-text:hover, .link-toolbar-text:focus {
  color: RGBA(21, 21, 31, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(21, 21, 31, var(--bs-link-underline-opacity, 1)) !important;
}

.link-focus-bg {
  color: RGBA(var(--bs-focus-bg-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-focus-bg-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-focus-bg:hover, .link-focus-bg:focus {
  color: RGBA(241, 245, 249, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(241, 245, 249, var(--bs-link-underline-opacity, 1)) !important;
}

.link-tabbar-text {
  color: RGBA(var(--bs-tabbar-text-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tabbar-text-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-tabbar-text:hover, .link-tabbar-text:focus {
  color: RGBA(18, 18, 28, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(18, 18, 28, var(--bs-link-underline-opacity, 1)) !important;
}

.link-card-bg {
  color: RGBA(var(--bs-card-bg-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-card-bg-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-card-bg:hover, .link-card-bg:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-card-text {
  color: RGBA(var(--bs-card-text-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-card-text-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-card-text:hover, .link-card-text:focus {
  color: RGBA(24, 24, 36, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(24, 24, 36, var(--bs-link-underline-opacity, 1)) !important;
}

.link-item-bg {
  color: RGBA(var(--bs-item-bg-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-item-bg-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-item-bg:hover, .link-item-bg:focus {
  color: RGBA(233, 235, 242, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(233, 235, 242, var(--bs-link-underline-opacity, 1)) !important;
}

.link-item-text {
  color: RGBA(var(--bs-item-text-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-item-text-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-item-text:hover, .link-item-text:focus {
  color: RGBA(22, 22, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(22, 22, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-5 {
  opacity: 0.05 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-15 {
  opacity: 0.15 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-white {
  --bs-focus-ring-color: rgba(var(--bs-white-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-black {
  --bs-focus-ring-color: rgba(var(--bs-black-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-muted {
  --bs-focus-ring-color: rgba(var(--bs-muted-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-blue {
  --bs-focus-ring-color: rgba(var(--bs-blue-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-indigo {
  --bs-focus-ring-color: rgba(var(--bs-indigo-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-purple {
  --bs-focus-ring-color: rgba(var(--bs-purple-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-pink {
  --bs-focus-ring-color: rgba(var(--bs-pink-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-red {
  --bs-focus-ring-color: rgba(var(--bs-red-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-orange {
  --bs-focus-ring-color: rgba(var(--bs-orange-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-yellow {
  --bs-focus-ring-color: rgba(var(--bs-yellow-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-green {
  --bs-focus-ring-color: rgba(var(--bs-green-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-teal {
  --bs-focus-ring-color: rgba(var(--bs-teal-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-cyan {
  --bs-focus-ring-color: rgba(var(--bs-cyan-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-gray {
  --bs-focus-ring-color: rgba(var(--bs-gray-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-body-bg {
  --bs-focus-ring-color: rgba(var(--bs-body-bg-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-body-text {
  --bs-focus-ring-color: rgba(var(--bs-body-text-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-toolbar-bg {
  --bs-focus-ring-color: rgba(var(--bs-toolbar-bg-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-toolbar-text {
  --bs-focus-ring-color: rgba(var(--bs-toolbar-text-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-focus-bg {
  --bs-focus-ring-color: rgba(var(--bs-focus-bg-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-tabbar-text {
  --bs-focus-ring-color: rgba(var(--bs-tabbar-text-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-card-bg {
  --bs-focus-ring-color: rgba(var(--bs-card-bg-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-card-text {
  --bs-focus-ring-color: rgba(var(--bs-card-text-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-item-bg {
  --bs-focus-ring-color: rgba(var(--bs-item-bg-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-item-text {
  --bs-focus-ring-color: rgba(var(--bs-item-text-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-unset {
  top: unset !important;
}

.top-25 {
  top: 25% !important;
}

.top-50 {
  top: 50% !important;
}

.top-75 {
  top: 75% !important;
}

.top-100 {
  top: 100% !important;
}

.top-auto {
  top: auto !important;
}

.top-1px {
  top: 1px !important;
}

.top-2px {
  top: 2px !important;
}

.top-3px {
  top: 3px !important;
}

.top-4px {
  top: 4px !important;
}

.top-5px {
  top: 5px !important;
}

.top-6px {
  top: 6px !important;
}

.top-7px {
  top: 7px !important;
}

.top-8px {
  top: 8px !important;
}

.top-9px {
  top: 9px !important;
}

.top-10px {
  top: 10px !important;
}

.top-15px {
  top: 15px !important;
}

.top-20px {
  top: 20px !important;
}

.top-25px {
  top: 25px !important;
}

.top-30px {
  top: 30px !important;
}

.top-35px {
  top: 35px !important;
}

.top-40px {
  top: 40px !important;
}

.top-45px {
  top: 45px !important;
}

.top-50px {
  top: 50px !important;
}

.top-55px {
  top: 55px !important;
}

.top-60px {
  top: 60px !important;
}

.top-65px {
  top: 65px !important;
}

.top-70px {
  top: 70px !important;
}

.top-75px {
  top: 75px !important;
}

.top-80px {
  top: 80px !important;
}

.top-85px {
  top: 85px !important;
}

.top-90px {
  top: 90px !important;
}

.top-95px {
  top: 95px !important;
}

.top-100px {
  top: 100px !important;
}

.top-125px {
  top: 125px !important;
}

.top-150px {
  top: 150px !important;
}

.top-175px {
  top: 175px !important;
}

.top-200px {
  top: 200px !important;
}

.top-225px {
  top: 225px !important;
}

.top-250px {
  top: 250px !important;
}

.top-275px {
  top: 275px !important;
}

.top-300px {
  top: 300px !important;
}

.top-325px {
  top: 325px !important;
}

.top-350px {
  top: 350px !important;
}

.top-375px {
  top: 375px !important;
}

.top-400px {
  top: 400px !important;
}

.top-425px {
  top: 425px !important;
}

.top-450px {
  top: 450px !important;
}

.top-475px {
  top: 475px !important;
}

.top-500px {
  top: 500px !important;
}

.top-550px {
  top: 550px !important;
}

.top-600px {
  top: 600px !important;
}

.top-650px {
  top: 650px !important;
}

.top-700px {
  top: 700px !important;
}

.top-750px {
  top: 750px !important;
}

.top-800px {
  top: 800px !important;
}

.top-850px {
  top: 850px !important;
}

.top-900px {
  top: 900px !important;
}

.top-950px {
  top: 950px !important;
}

.top-1000px {
  top: 1000px !important;
}

.bottom-unset {
  bottom: unset !important;
}

.bottom-25 {
  bottom: 25% !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-75 {
  bottom: 75% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.bottom-auto {
  bottom: auto !important;
}

.bottom-1px {
  bottom: 1px !important;
}

.bottom-2px {
  bottom: 2px !important;
}

.bottom-3px {
  bottom: 3px !important;
}

.bottom-4px {
  bottom: 4px !important;
}

.bottom-5px {
  bottom: 5px !important;
}

.bottom-6px {
  bottom: 6px !important;
}

.bottom-7px {
  bottom: 7px !important;
}

.bottom-8px {
  bottom: 8px !important;
}

.bottom-9px {
  bottom: 9px !important;
}

.bottom-10px {
  bottom: 10px !important;
}

.bottom-15px {
  bottom: 15px !important;
}

.bottom-20px {
  bottom: 20px !important;
}

.bottom-25px {
  bottom: 25px !important;
}

.bottom-30px {
  bottom: 30px !important;
}

.bottom-35px {
  bottom: 35px !important;
}

.bottom-40px {
  bottom: 40px !important;
}

.bottom-45px {
  bottom: 45px !important;
}

.bottom-50px {
  bottom: 50px !important;
}

.bottom-55px {
  bottom: 55px !important;
}

.bottom-60px {
  bottom: 60px !important;
}

.bottom-65px {
  bottom: 65px !important;
}

.bottom-70px {
  bottom: 70px !important;
}

.bottom-75px {
  bottom: 75px !important;
}

.bottom-80px {
  bottom: 80px !important;
}

.bottom-85px {
  bottom: 85px !important;
}

.bottom-90px {
  bottom: 90px !important;
}

.bottom-95px {
  bottom: 95px !important;
}

.bottom-100px {
  bottom: 100px !important;
}

.bottom-125px {
  bottom: 125px !important;
}

.bottom-150px {
  bottom: 150px !important;
}

.bottom-175px {
  bottom: 175px !important;
}

.bottom-200px {
  bottom: 200px !important;
}

.bottom-225px {
  bottom: 225px !important;
}

.bottom-250px {
  bottom: 250px !important;
}

.bottom-275px {
  bottom: 275px !important;
}

.bottom-300px {
  bottom: 300px !important;
}

.bottom-325px {
  bottom: 325px !important;
}

.bottom-350px {
  bottom: 350px !important;
}

.bottom-375px {
  bottom: 375px !important;
}

.bottom-400px {
  bottom: 400px !important;
}

.bottom-425px {
  bottom: 425px !important;
}

.bottom-450px {
  bottom: 450px !important;
}

.bottom-475px {
  bottom: 475px !important;
}

.bottom-500px {
  bottom: 500px !important;
}

.bottom-550px {
  bottom: 550px !important;
}

.bottom-600px {
  bottom: 600px !important;
}

.bottom-650px {
  bottom: 650px !important;
}

.bottom-700px {
  bottom: 700px !important;
}

.bottom-750px {
  bottom: 750px !important;
}

.bottom-800px {
  bottom: 800px !important;
}

.bottom-850px {
  bottom: 850px !important;
}

.bottom-900px {
  bottom: 900px !important;
}

.bottom-950px {
  bottom: 950px !important;
}

.bottom-1000px {
  bottom: 1000px !important;
}

.start-unset {
  left: unset !important;
}

.start-25 {
  left: 25% !important;
}

.start-50 {
  left: 50% !important;
}

.start-75 {
  left: 75% !important;
}

.start-100 {
  left: 100% !important;
}

.start-auto {
  left: auto !important;
}

.start-1px {
  left: 1px !important;
}

.start-2px {
  left: 2px !important;
}

.start-3px {
  left: 3px !important;
}

.start-4px {
  left: 4px !important;
}

.start-5px {
  left: 5px !important;
}

.start-6px {
  left: 6px !important;
}

.start-7px {
  left: 7px !important;
}

.start-8px {
  left: 8px !important;
}

.start-9px {
  left: 9px !important;
}

.start-10px {
  left: 10px !important;
}

.start-15px {
  left: 15px !important;
}

.start-20px {
  left: 20px !important;
}

.start-25px {
  left: 25px !important;
}

.start-30px {
  left: 30px !important;
}

.start-35px {
  left: 35px !important;
}

.start-40px {
  left: 40px !important;
}

.start-45px {
  left: 45px !important;
}

.start-50px {
  left: 50px !important;
}

.start-55px {
  left: 55px !important;
}

.start-60px {
  left: 60px !important;
}

.start-65px {
  left: 65px !important;
}

.start-70px {
  left: 70px !important;
}

.start-75px {
  left: 75px !important;
}

.start-80px {
  left: 80px !important;
}

.start-85px {
  left: 85px !important;
}

.start-90px {
  left: 90px !important;
}

.start-95px {
  left: 95px !important;
}

.start-100px {
  left: 100px !important;
}

.start-125px {
  left: 125px !important;
}

.start-150px {
  left: 150px !important;
}

.start-175px {
  left: 175px !important;
}

.start-200px {
  left: 200px !important;
}

.start-225px {
  left: 225px !important;
}

.start-250px {
  left: 250px !important;
}

.start-275px {
  left: 275px !important;
}

.start-300px {
  left: 300px !important;
}

.start-325px {
  left: 325px !important;
}

.start-350px {
  left: 350px !important;
}

.start-375px {
  left: 375px !important;
}

.start-400px {
  left: 400px !important;
}

.start-425px {
  left: 425px !important;
}

.start-450px {
  left: 450px !important;
}

.start-475px {
  left: 475px !important;
}

.start-500px {
  left: 500px !important;
}

.start-550px {
  left: 550px !important;
}

.start-600px {
  left: 600px !important;
}

.start-650px {
  left: 650px !important;
}

.start-700px {
  left: 700px !important;
}

.start-750px {
  left: 750px !important;
}

.start-800px {
  left: 800px !important;
}

.start-850px {
  left: 850px !important;
}

.start-900px {
  left: 900px !important;
}

.start-950px {
  left: 950px !important;
}

.start-1000px {
  left: 1000px !important;
}

.end-unset {
  right: unset !important;
}

.end-25 {
  right: 25% !important;
}

.end-50 {
  right: 50% !important;
}

.end-75 {
  right: 75% !important;
}

.end-100 {
  right: 100% !important;
}

.end-auto {
  right: auto !important;
}

.end-1px {
  right: 1px !important;
}

.end-2px {
  right: 2px !important;
}

.end-3px {
  right: 3px !important;
}

.end-4px {
  right: 4px !important;
}

.end-5px {
  right: 5px !important;
}

.end-6px {
  right: 6px !important;
}

.end-7px {
  right: 7px !important;
}

.end-8px {
  right: 8px !important;
}

.end-9px {
  right: 9px !important;
}

.end-10px {
  right: 10px !important;
}

.end-15px {
  right: 15px !important;
}

.end-20px {
  right: 20px !important;
}

.end-25px {
  right: 25px !important;
}

.end-30px {
  right: 30px !important;
}

.end-35px {
  right: 35px !important;
}

.end-40px {
  right: 40px !important;
}

.end-45px {
  right: 45px !important;
}

.end-50px {
  right: 50px !important;
}

.end-55px {
  right: 55px !important;
}

.end-60px {
  right: 60px !important;
}

.end-65px {
  right: 65px !important;
}

.end-70px {
  right: 70px !important;
}

.end-75px {
  right: 75px !important;
}

.end-80px {
  right: 80px !important;
}

.end-85px {
  right: 85px !important;
}

.end-90px {
  right: 90px !important;
}

.end-95px {
  right: 95px !important;
}

.end-100px {
  right: 100px !important;
}

.end-125px {
  right: 125px !important;
}

.end-150px {
  right: 150px !important;
}

.end-175px {
  right: 175px !important;
}

.end-200px {
  right: 200px !important;
}

.end-225px {
  right: 225px !important;
}

.end-250px {
  right: 250px !important;
}

.end-275px {
  right: 275px !important;
}

.end-300px {
  right: 300px !important;
}

.end-325px {
  right: 325px !important;
}

.end-350px {
  right: 350px !important;
}

.end-375px {
  right: 375px !important;
}

.end-400px {
  right: 400px !important;
}

.end-425px {
  right: 425px !important;
}

.end-450px {
  right: 450px !important;
}

.end-475px {
  right: 475px !important;
}

.end-500px {
  right: 500px !important;
}

.end-550px {
  right: 550px !important;
}

.end-600px {
  right: 600px !important;
}

.end-650px {
  right: 650px !important;
}

.end-700px {
  right: 700px !important;
}

.end-750px {
  right: 750px !important;
}

.end-800px {
  right: 800px !important;
}

.end-850px {
  right: 850px !important;
}

.end-900px {
  right: 900px !important;
}

.end-950px {
  right: 950px !important;
}

.end-1000px {
  right: 1000px !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-muted {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-muted-rgb), var(--bs-border-opacity)) !important;
}

.border-blue {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-rgb), var(--bs-border-opacity)) !important;
}

.border-indigo {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-indigo-rgb), var(--bs-border-opacity)) !important;
}

.border-purple {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-purple-rgb), var(--bs-border-opacity)) !important;
}

.border-pink {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-rgb), var(--bs-border-opacity)) !important;
}

.border-red {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-rgb), var(--bs-border-opacity)) !important;
}

.border-orange {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-rgb), var(--bs-border-opacity)) !important;
}

.border-green {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-rgb), var(--bs-border-opacity)) !important;
}

.border-teal {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-teal-rgb), var(--bs-border-opacity)) !important;
}

.border-cyan {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-cyan-rgb), var(--bs-border-opacity)) !important;
}

.border-gray {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-gray-rgb), var(--bs-border-opacity)) !important;
}

.border-body-bg {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-body-bg-rgb), var(--bs-border-opacity)) !important;
}

.border-body-text {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-body-text-rgb), var(--bs-border-opacity)) !important;
}

.border-toolbar-bg {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-toolbar-bg-rgb), var(--bs-border-opacity)) !important;
}

.border-toolbar-text {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-toolbar-text-rgb), var(--bs-border-opacity)) !important;
}

.border-focus-bg {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-focus-bg-rgb), var(--bs-border-opacity)) !important;
}

.border-tabbar-text {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tabbar-text-rgb), var(--bs-border-opacity)) !important;
}

.border-card-bg {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-card-bg-rgb), var(--bs-border-opacity)) !important;
}

.border-card-text {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-card-text-rgb), var(--bs-border-opacity)) !important;
}

.border-item-bg {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-item-bg-rgb), var(--bs-border-opacity)) !important;
}

.border-item-text {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-item-text-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-unset {
  width: unset !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-1px {
  width: 1px !important;
}

.w-2px {
  width: 2px !important;
}

.w-3px {
  width: 3px !important;
}

.w-4px {
  width: 4px !important;
}

.w-5px {
  width: 5px !important;
}

.w-6px {
  width: 6px !important;
}

.w-7px {
  width: 7px !important;
}

.w-8px {
  width: 8px !important;
}

.w-9px {
  width: 9px !important;
}

.w-10px {
  width: 10px !important;
}

.w-15px {
  width: 15px !important;
}

.w-20px {
  width: 20px !important;
}

.w-25px {
  width: 25px !important;
}

.w-30px {
  width: 30px !important;
}

.w-35px {
  width: 35px !important;
}

.w-40px {
  width: 40px !important;
}

.w-45px {
  width: 45px !important;
}

.w-50px {
  width: 50px !important;
}

.w-55px {
  width: 55px !important;
}

.w-60px {
  width: 60px !important;
}

.w-65px {
  width: 65px !important;
}

.w-70px {
  width: 70px !important;
}

.w-75px {
  width: 75px !important;
}

.w-80px {
  width: 80px !important;
}

.w-85px {
  width: 85px !important;
}

.w-90px {
  width: 90px !important;
}

.w-95px {
  width: 95px !important;
}

.w-100px {
  width: 100px !important;
}

.w-125px {
  width: 125px !important;
}

.w-150px {
  width: 150px !important;
}

.w-175px {
  width: 175px !important;
}

.w-200px {
  width: 200px !important;
}

.w-225px {
  width: 225px !important;
}

.w-250px {
  width: 250px !important;
}

.w-275px {
  width: 275px !important;
}

.w-300px {
  width: 300px !important;
}

.w-325px {
  width: 325px !important;
}

.w-350px {
  width: 350px !important;
}

.w-375px {
  width: 375px !important;
}

.w-400px {
  width: 400px !important;
}

.w-425px {
  width: 425px !important;
}

.w-450px {
  width: 450px !important;
}

.w-475px {
  width: 475px !important;
}

.w-500px {
  width: 500px !important;
}

.w-550px {
  width: 550px !important;
}

.w-600px {
  width: 600px !important;
}

.w-650px {
  width: 650px !important;
}

.w-700px {
  width: 700px !important;
}

.w-750px {
  width: 750px !important;
}

.w-800px {
  width: 800px !important;
}

.w-850px {
  width: 850px !important;
}

.w-900px {
  width: 900px !important;
}

.w-950px {
  width: 950px !important;
}

.w-1000px {
  width: 1000px !important;
}

.mw-unset {
  max-width: unset !important;
}

.mw-25 {
  max-width: 25% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mw-auto {
  max-width: auto !important;
}

.mw-1px {
  max-width: 1px !important;
}

.mw-2px {
  max-width: 2px !important;
}

.mw-3px {
  max-width: 3px !important;
}

.mw-4px {
  max-width: 4px !important;
}

.mw-5px {
  max-width: 5px !important;
}

.mw-6px {
  max-width: 6px !important;
}

.mw-7px {
  max-width: 7px !important;
}

.mw-8px {
  max-width: 8px !important;
}

.mw-9px {
  max-width: 9px !important;
}

.mw-10px {
  max-width: 10px !important;
}

.mw-15px {
  max-width: 15px !important;
}

.mw-20px {
  max-width: 20px !important;
}

.mw-25px {
  max-width: 25px !important;
}

.mw-30px {
  max-width: 30px !important;
}

.mw-35px {
  max-width: 35px !important;
}

.mw-40px {
  max-width: 40px !important;
}

.mw-45px {
  max-width: 45px !important;
}

.mw-50px {
  max-width: 50px !important;
}

.mw-55px {
  max-width: 55px !important;
}

.mw-60px {
  max-width: 60px !important;
}

.mw-65px {
  max-width: 65px !important;
}

.mw-70px {
  max-width: 70px !important;
}

.mw-75px {
  max-width: 75px !important;
}

.mw-80px {
  max-width: 80px !important;
}

.mw-85px {
  max-width: 85px !important;
}

.mw-90px {
  max-width: 90px !important;
}

.mw-95px {
  max-width: 95px !important;
}

.mw-100px {
  max-width: 100px !important;
}

.mw-125px {
  max-width: 125px !important;
}

.mw-150px {
  max-width: 150px !important;
}

.mw-175px {
  max-width: 175px !important;
}

.mw-200px {
  max-width: 200px !important;
}

.mw-225px {
  max-width: 225px !important;
}

.mw-250px {
  max-width: 250px !important;
}

.mw-275px {
  max-width: 275px !important;
}

.mw-300px {
  max-width: 300px !important;
}

.mw-325px {
  max-width: 325px !important;
}

.mw-350px {
  max-width: 350px !important;
}

.mw-375px {
  max-width: 375px !important;
}

.mw-400px {
  max-width: 400px !important;
}

.mw-425px {
  max-width: 425px !important;
}

.mw-450px {
  max-width: 450px !important;
}

.mw-475px {
  max-width: 475px !important;
}

.mw-500px {
  max-width: 500px !important;
}

.mw-550px {
  max-width: 550px !important;
}

.mw-600px {
  max-width: 600px !important;
}

.mw-650px {
  max-width: 650px !important;
}

.mw-700px {
  max-width: 700px !important;
}

.mw-750px {
  max-width: 750px !important;
}

.mw-800px {
  max-width: 800px !important;
}

.mw-850px {
  max-width: 850px !important;
}

.mw-900px {
  max-width: 900px !important;
}

.mw-950px {
  max-width: 950px !important;
}

.mw-1000px {
  max-width: 1000px !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-unset {
  height: unset !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-1px {
  height: 1px !important;
}

.h-2px {
  height: 2px !important;
}

.h-3px {
  height: 3px !important;
}

.h-4px {
  height: 4px !important;
}

.h-5px {
  height: 5px !important;
}

.h-6px {
  height: 6px !important;
}

.h-7px {
  height: 7px !important;
}

.h-8px {
  height: 8px !important;
}

.h-9px {
  height: 9px !important;
}

.h-10px {
  height: 10px !important;
}

.h-15px {
  height: 15px !important;
}

.h-20px {
  height: 20px !important;
}

.h-25px {
  height: 25px !important;
}

.h-30px {
  height: 30px !important;
}

.h-35px {
  height: 35px !important;
}

.h-40px {
  height: 40px !important;
}

.h-45px {
  height: 45px !important;
}

.h-50px {
  height: 50px !important;
}

.h-55px {
  height: 55px !important;
}

.h-60px {
  height: 60px !important;
}

.h-65px {
  height: 65px !important;
}

.h-70px {
  height: 70px !important;
}

.h-75px {
  height: 75px !important;
}

.h-80px {
  height: 80px !important;
}

.h-85px {
  height: 85px !important;
}

.h-90px {
  height: 90px !important;
}

.h-95px {
  height: 95px !important;
}

.h-100px {
  height: 100px !important;
}

.h-125px {
  height: 125px !important;
}

.h-150px {
  height: 150px !important;
}

.h-175px {
  height: 175px !important;
}

.h-200px {
  height: 200px !important;
}

.h-225px {
  height: 225px !important;
}

.h-250px {
  height: 250px !important;
}

.h-275px {
  height: 275px !important;
}

.h-300px {
  height: 300px !important;
}

.h-325px {
  height: 325px !important;
}

.h-350px {
  height: 350px !important;
}

.h-375px {
  height: 375px !important;
}

.h-400px {
  height: 400px !important;
}

.h-425px {
  height: 425px !important;
}

.h-450px {
  height: 450px !important;
}

.h-475px {
  height: 475px !important;
}

.h-500px {
  height: 500px !important;
}

.h-550px {
  height: 550px !important;
}

.h-600px {
  height: 600px !important;
}

.h-650px {
  height: 650px !important;
}

.h-700px {
  height: 700px !important;
}

.h-750px {
  height: 750px !important;
}

.h-800px {
  height: 800px !important;
}

.h-850px {
  height: 850px !important;
}

.h-900px {
  height: 900px !important;
}

.h-950px {
  height: 950px !important;
}

.h-1000px {
  height: 1000px !important;
}

.mh-unset {
  max-height: unset !important;
}

.mh-25 {
  max-height: 25% !important;
}

.mh-50 {
  max-height: 50% !important;
}

.mh-75 {
  max-height: 75% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.mh-auto {
  max-height: auto !important;
}

.mh-1px {
  max-height: 1px !important;
}

.mh-2px {
  max-height: 2px !important;
}

.mh-3px {
  max-height: 3px !important;
}

.mh-4px {
  max-height: 4px !important;
}

.mh-5px {
  max-height: 5px !important;
}

.mh-6px {
  max-height: 6px !important;
}

.mh-7px {
  max-height: 7px !important;
}

.mh-8px {
  max-height: 8px !important;
}

.mh-9px {
  max-height: 9px !important;
}

.mh-10px {
  max-height: 10px !important;
}

.mh-15px {
  max-height: 15px !important;
}

.mh-20px {
  max-height: 20px !important;
}

.mh-25px {
  max-height: 25px !important;
}

.mh-30px {
  max-height: 30px !important;
}

.mh-35px {
  max-height: 35px !important;
}

.mh-40px {
  max-height: 40px !important;
}

.mh-45px {
  max-height: 45px !important;
}

.mh-50px {
  max-height: 50px !important;
}

.mh-55px {
  max-height: 55px !important;
}

.mh-60px {
  max-height: 60px !important;
}

.mh-65px {
  max-height: 65px !important;
}

.mh-70px {
  max-height: 70px !important;
}

.mh-75px {
  max-height: 75px !important;
}

.mh-80px {
  max-height: 80px !important;
}

.mh-85px {
  max-height: 85px !important;
}

.mh-90px {
  max-height: 90px !important;
}

.mh-95px {
  max-height: 95px !important;
}

.mh-100px {
  max-height: 100px !important;
}

.mh-125px {
  max-height: 125px !important;
}

.mh-150px {
  max-height: 150px !important;
}

.mh-175px {
  max-height: 175px !important;
}

.mh-200px {
  max-height: 200px !important;
}

.mh-225px {
  max-height: 225px !important;
}

.mh-250px {
  max-height: 250px !important;
}

.mh-275px {
  max-height: 275px !important;
}

.mh-300px {
  max-height: 300px !important;
}

.mh-325px {
  max-height: 325px !important;
}

.mh-350px {
  max-height: 350px !important;
}

.mh-375px {
  max-height: 375px !important;
}

.mh-400px {
  max-height: 400px !important;
}

.mh-425px {
  max-height: 425px !important;
}

.mh-450px {
  max-height: 450px !important;
}

.mh-475px {
  max-height: 475px !important;
}

.mh-500px {
  max-height: 500px !important;
}

.mh-550px {
  max-height: 550px !important;
}

.mh-600px {
  max-height: 600px !important;
}

.mh-650px {
  max-height: 650px !important;
}

.mh-700px {
  max-height: 700px !important;
}

.mh-750px {
  max-height: 750px !important;
}

.mh-800px {
  max-height: 800px !important;
}

.mh-850px {
  max-height: 850px !important;
}

.mh-900px {
  max-height: 900px !important;
}

.mh-950px {
  max-height: 950px !important;
}

.mh-1000px {
  max-height: 1000px !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-7 {
  margin: 1.75rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-9 {
  margin: 2.25rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-11 {
  margin: 2.75rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.m-13 {
  margin: 3.25rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.m-15 {
  margin: 3.75rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.m-17 {
  margin: 4.25rem !important;
}

.m-18 {
  margin: 4.5rem !important;
}

.m-19 {
  margin: 4.75rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-4 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-5 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-6 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-7 {
  margin-right: 1.75rem !important;
  margin-left: 1.75rem !important;
}

.mx-8 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-9 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important;
}

.mx-10 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-11 {
  margin-right: 2.75rem !important;
  margin-left: 2.75rem !important;
}

.mx-12 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-13 {
  margin-right: 3.25rem !important;
  margin-left: 3.25rem !important;
}

.mx-14 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-15 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important;
}

.mx-16 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-17 {
  margin-right: 4.25rem !important;
  margin-left: 4.25rem !important;
}

.mx-18 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-19 {
  margin-right: 4.75rem !important;
  margin-left: 4.75rem !important;
}

.mx-20 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-9 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-11 {
  margin-top: 2.75rem !important;
  margin-bottom: 2.75rem !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-13 {
  margin-top: 3.25rem !important;
  margin-bottom: 3.25rem !important;
}

.my-14 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-15 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-17 {
  margin-top: 4.25rem !important;
  margin-bottom: 4.25rem !important;
}

.my-18 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-19 {
  margin-top: 4.75rem !important;
  margin-bottom: 4.75rem !important;
}

.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-7 {
  margin-top: 1.75rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-9 {
  margin-top: 2.25rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-11 {
  margin-top: 2.75rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mt-13 {
  margin-top: 3.25rem !important;
}

.mt-14 {
  margin-top: 3.5rem !important;
}

.mt-15 {
  margin-top: 3.75rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mt-17 {
  margin-top: 4.25rem !important;
}

.mt-18 {
  margin-top: 4.5rem !important;
}

.mt-19 {
  margin-top: 4.75rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 0.75rem !important;
}

.me-4 {
  margin-right: 1rem !important;
}

.me-5 {
  margin-right: 1.25rem !important;
}

.me-6 {
  margin-right: 1.5rem !important;
}

.me-7 {
  margin-right: 1.75rem !important;
}

.me-8 {
  margin-right: 2rem !important;
}

.me-9 {
  margin-right: 2.25rem !important;
}

.me-10 {
  margin-right: 2.5rem !important;
}

.me-11 {
  margin-right: 2.75rem !important;
}

.me-12 {
  margin-right: 3rem !important;
}

.me-13 {
  margin-right: 3.25rem !important;
}

.me-14 {
  margin-right: 3.5rem !important;
}

.me-15 {
  margin-right: 3.75rem !important;
}

.me-16 {
  margin-right: 4rem !important;
}

.me-17 {
  margin-right: 4.25rem !important;
}

.me-18 {
  margin-right: 4.5rem !important;
}

.me-19 {
  margin-right: 4.75rem !important;
}

.me-20 {
  margin-right: 5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-7 {
  margin-bottom: 1.75rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mb-9 {
  margin-bottom: 2.25rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-11 {
  margin-bottom: 2.75rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.mb-13 {
  margin-bottom: 3.25rem !important;
}

.mb-14 {
  margin-bottom: 3.5rem !important;
}

.mb-15 {
  margin-bottom: 3.75rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.mb-17 {
  margin-bottom: 4.25rem !important;
}

.mb-18 {
  margin-bottom: 4.5rem !important;
}

.mb-19 {
  margin-bottom: 4.75rem !important;
}

.mb-20 {
  margin-bottom: 5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 0.75rem !important;
}

.ms-4 {
  margin-left: 1rem !important;
}

.ms-5 {
  margin-left: 1.25rem !important;
}

.ms-6 {
  margin-left: 1.5rem !important;
}

.ms-7 {
  margin-left: 1.75rem !important;
}

.ms-8 {
  margin-left: 2rem !important;
}

.ms-9 {
  margin-left: 2.25rem !important;
}

.ms-10 {
  margin-left: 2.5rem !important;
}

.ms-11 {
  margin-left: 2.75rem !important;
}

.ms-12 {
  margin-left: 3rem !important;
}

.ms-13 {
  margin-left: 3.25rem !important;
}

.ms-14 {
  margin-left: 3.5rem !important;
}

.ms-15 {
  margin-left: 3.75rem !important;
}

.ms-16 {
  margin-left: 4rem !important;
}

.ms-17 {
  margin-left: 4.25rem !important;
}

.ms-18 {
  margin-left: 4.5rem !important;
}

.ms-19 {
  margin-left: 4.75rem !important;
}

.ms-20 {
  margin-left: 5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.m-n5 {
  margin: -1.25rem !important;
}

.m-n6 {
  margin: -1.5rem !important;
}

.m-n7 {
  margin: -1.75rem !important;
}

.m-n8 {
  margin: -2rem !important;
}

.m-n9 {
  margin: -2.25rem !important;
}

.m-n10 {
  margin: -2.5rem !important;
}

.m-n11 {
  margin: -2.75rem !important;
}

.m-n12 {
  margin: -3rem !important;
}

.m-n13 {
  margin: -3.25rem !important;
}

.m-n14 {
  margin: -3.5rem !important;
}

.m-n15 {
  margin: -3.75rem !important;
}

.m-n16 {
  margin: -4rem !important;
}

.m-n17 {
  margin: -4.25rem !important;
}

.m-n18 {
  margin: -4.5rem !important;
}

.m-n19 {
  margin: -4.75rem !important;
}

.m-n20 {
  margin: -5rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}

.mx-n4 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n5 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n6 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n7 {
  margin-right: -1.75rem !important;
  margin-left: -1.75rem !important;
}

.mx-n8 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-n9 {
  margin-right: -2.25rem !important;
  margin-left: -2.25rem !important;
}

.mx-n10 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n11 {
  margin-right: -2.75rem !important;
  margin-left: -2.75rem !important;
}

.mx-n12 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n13 {
  margin-right: -3.25rem !important;
  margin-left: -3.25rem !important;
}

.mx-n14 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important;
}

.mx-n15 {
  margin-right: -3.75rem !important;
  margin-left: -3.75rem !important;
}

.mx-n16 {
  margin-right: -4rem !important;
  margin-left: -4rem !important;
}

.mx-n17 {
  margin-right: -4.25rem !important;
  margin-left: -4.25rem !important;
}

.mx-n18 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.mx-n19 {
  margin-right: -4.75rem !important;
  margin-left: -4.75rem !important;
}

.mx-n20 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.my-n4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n7 {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.my-n8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-n9 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.my-n10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n11 {
  margin-top: -2.75rem !important;
  margin-bottom: -2.75rem !important;
}

.my-n12 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n13 {
  margin-top: -3.25rem !important;
  margin-bottom: -3.25rem !important;
}

.my-n14 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.my-n15 {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important;
}

.my-n16 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.my-n17 {
  margin-top: -4.25rem !important;
  margin-bottom: -4.25rem !important;
}

.my-n18 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.my-n19 {
  margin-top: -4.75rem !important;
  margin-bottom: -4.75rem !important;
}

.my-n20 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -0.75rem !important;
}

.mt-n4 {
  margin-top: -1rem !important;
}

.mt-n5 {
  margin-top: -1.25rem !important;
}

.mt-n6 {
  margin-top: -1.5rem !important;
}

.mt-n7 {
  margin-top: -1.75rem !important;
}

.mt-n8 {
  margin-top: -2rem !important;
}

.mt-n9 {
  margin-top: -2.25rem !important;
}

.mt-n10 {
  margin-top: -2.5rem !important;
}

.mt-n11 {
  margin-top: -2.75rem !important;
}

.mt-n12 {
  margin-top: -3rem !important;
}

.mt-n13 {
  margin-top: -3.25rem !important;
}

.mt-n14 {
  margin-top: -3.5rem !important;
}

.mt-n15 {
  margin-top: -3.75rem !important;
}

.mt-n16 {
  margin-top: -4rem !important;
}

.mt-n17 {
  margin-top: -4.25rem !important;
}

.mt-n18 {
  margin-top: -4.5rem !important;
}

.mt-n19 {
  margin-top: -4.75rem !important;
}

.mt-n20 {
  margin-top: -5rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -0.75rem !important;
}

.me-n4 {
  margin-right: -1rem !important;
}

.me-n5 {
  margin-right: -1.25rem !important;
}

.me-n6 {
  margin-right: -1.5rem !important;
}

.me-n7 {
  margin-right: -1.75rem !important;
}

.me-n8 {
  margin-right: -2rem !important;
}

.me-n9 {
  margin-right: -2.25rem !important;
}

.me-n10 {
  margin-right: -2.5rem !important;
}

.me-n11 {
  margin-right: -2.75rem !important;
}

.me-n12 {
  margin-right: -3rem !important;
}

.me-n13 {
  margin-right: -3.25rem !important;
}

.me-n14 {
  margin-right: -3.5rem !important;
}

.me-n15 {
  margin-right: -3.75rem !important;
}

.me-n16 {
  margin-right: -4rem !important;
}

.me-n17 {
  margin-right: -4.25rem !important;
}

.me-n18 {
  margin-right: -4.5rem !important;
}

.me-n19 {
  margin-right: -4.75rem !important;
}

.me-n20 {
  margin-right: -5rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -0.75rem !important;
}

.mb-n4 {
  margin-bottom: -1rem !important;
}

.mb-n5 {
  margin-bottom: -1.25rem !important;
}

.mb-n6 {
  margin-bottom: -1.5rem !important;
}

.mb-n7 {
  margin-bottom: -1.75rem !important;
}

.mb-n8 {
  margin-bottom: -2rem !important;
}

.mb-n9 {
  margin-bottom: -2.25rem !important;
}

.mb-n10 {
  margin-bottom: -2.5rem !important;
}

.mb-n11 {
  margin-bottom: -2.75rem !important;
}

.mb-n12 {
  margin-bottom: -3rem !important;
}

.mb-n13 {
  margin-bottom: -3.25rem !important;
}

.mb-n14 {
  margin-bottom: -3.5rem !important;
}

.mb-n15 {
  margin-bottom: -3.75rem !important;
}

.mb-n16 {
  margin-bottom: -4rem !important;
}

.mb-n17 {
  margin-bottom: -4.25rem !important;
}

.mb-n18 {
  margin-bottom: -4.5rem !important;
}

.mb-n19 {
  margin-bottom: -4.75rem !important;
}

.mb-n20 {
  margin-bottom: -5rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -0.75rem !important;
}

.ms-n4 {
  margin-left: -1rem !important;
}

.ms-n5 {
  margin-left: -1.25rem !important;
}

.ms-n6 {
  margin-left: -1.5rem !important;
}

.ms-n7 {
  margin-left: -1.75rem !important;
}

.ms-n8 {
  margin-left: -2rem !important;
}

.ms-n9 {
  margin-left: -2.25rem !important;
}

.ms-n10 {
  margin-left: -2.5rem !important;
}

.ms-n11 {
  margin-left: -2.75rem !important;
}

.ms-n12 {
  margin-left: -3rem !important;
}

.ms-n13 {
  margin-left: -3.25rem !important;
}

.ms-n14 {
  margin-left: -3.5rem !important;
}

.ms-n15 {
  margin-left: -3.75rem !important;
}

.ms-n16 {
  margin-left: -4rem !important;
}

.ms-n17 {
  margin-left: -4.25rem !important;
}

.ms-n18 {
  margin-left: -4.5rem !important;
}

.ms-n19 {
  margin-left: -4.75rem !important;
}

.ms-n20 {
  margin-left: -5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-7 {
  padding: 1.75rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-9 {
  padding: 2.25rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-11 {
  padding: 2.75rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.p-13 {
  padding: 3.25rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.p-15 {
  padding: 3.75rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.p-17 {
  padding: 4.25rem !important;
}

.p-18 {
  padding: 4.5rem !important;
}

.p-19 {
  padding: 4.75rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-5 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-6 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-7 {
  padding-right: 1.75rem !important;
  padding-left: 1.75rem !important;
}

.px-8 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-9 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}

.px-10 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-11 {
  padding-right: 2.75rem !important;
  padding-left: 2.75rem !important;
}

.px-12 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-13 {
  padding-right: 3.25rem !important;
  padding-left: 3.25rem !important;
}

.px-14 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-15 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important;
}

.px-16 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.px-17 {
  padding-right: 4.25rem !important;
  padding-left: 4.25rem !important;
}

.px-18 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-19 {
  padding-right: 4.75rem !important;
  padding-left: 4.75rem !important;
}

.px-20 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-9 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-11 {
  padding-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-13 {
  padding-top: 3.25rem !important;
  padding-bottom: 3.25rem !important;
}

.py-14 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-15 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-17 {
  padding-top: 4.25rem !important;
  padding-bottom: 4.25rem !important;
}

.py-18 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-19 {
  padding-top: 4.75rem !important;
  padding-bottom: 4.75rem !important;
}

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-7 {
  padding-top: 1.75rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-9 {
  padding-top: 2.25rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pt-11 {
  padding-top: 2.75rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pt-13 {
  padding-top: 3.25rem !important;
}

.pt-14 {
  padding-top: 3.5rem !important;
}

.pt-15 {
  padding-top: 3.75rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pt-17 {
  padding-top: 4.25rem !important;
}

.pt-18 {
  padding-top: 4.5rem !important;
}

.pt-19 {
  padding-top: 4.75rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 0.75rem !important;
}

.pe-4 {
  padding-right: 1rem !important;
}

.pe-5 {
  padding-right: 1.25rem !important;
}

.pe-6 {
  padding-right: 1.5rem !important;
}

.pe-7 {
  padding-right: 1.75rem !important;
}

.pe-8 {
  padding-right: 2rem !important;
}

.pe-9 {
  padding-right: 2.25rem !important;
}

.pe-10 {
  padding-right: 2.5rem !important;
}

.pe-11 {
  padding-right: 2.75rem !important;
}

.pe-12 {
  padding-right: 3rem !important;
}

.pe-13 {
  padding-right: 3.25rem !important;
}

.pe-14 {
  padding-right: 3.5rem !important;
}

.pe-15 {
  padding-right: 3.75rem !important;
}

.pe-16 {
  padding-right: 4rem !important;
}

.pe-17 {
  padding-right: 4.25rem !important;
}

.pe-18 {
  padding-right: 4.5rem !important;
}

.pe-19 {
  padding-right: 4.75rem !important;
}

.pe-20 {
  padding-right: 5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pb-7 {
  padding-bottom: 1.75rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pb-9 {
  padding-bottom: 2.25rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pb-11 {
  padding-bottom: 2.75rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pb-13 {
  padding-bottom: 3.25rem !important;
}

.pb-14 {
  padding-bottom: 3.5rem !important;
}

.pb-15 {
  padding-bottom: 3.75rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pb-17 {
  padding-bottom: 4.25rem !important;
}

.pb-18 {
  padding-bottom: 4.5rem !important;
}

.pb-19 {
  padding-bottom: 4.75rem !important;
}

.pb-20 {
  padding-bottom: 5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 0.75rem !important;
}

.ps-4 {
  padding-left: 1rem !important;
}

.ps-5 {
  padding-left: 1.25rem !important;
}

.ps-6 {
  padding-left: 1.5rem !important;
}

.ps-7 {
  padding-left: 1.75rem !important;
}

.ps-8 {
  padding-left: 2rem !important;
}

.ps-9 {
  padding-left: 2.25rem !important;
}

.ps-10 {
  padding-left: 2.5rem !important;
}

.ps-11 {
  padding-left: 2.75rem !important;
}

.ps-12 {
  padding-left: 3rem !important;
}

.ps-13 {
  padding-left: 3.25rem !important;
}

.ps-14 {
  padding-left: 3.5rem !important;
}

.ps-15 {
  padding-left: 3.75rem !important;
}

.ps-16 {
  padding-left: 4rem !important;
}

.ps-17 {
  padding-left: 4.25rem !important;
}

.ps-18 {
  padding-left: 4.5rem !important;
}

.ps-19 {
  padding-left: 4.75rem !important;
}

.ps-20 {
  padding-left: 5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 0.75rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-5 {
  gap: 1.25rem !important;
}

.gap-6 {
  gap: 1.5rem !important;
}

.gap-7 {
  gap: 1.75rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.gap-9 {
  gap: 2.25rem !important;
}

.gap-10 {
  gap: 2.5rem !important;
}

.gap-11 {
  gap: 2.75rem !important;
}

.gap-12 {
  gap: 3rem !important;
}

.gap-13 {
  gap: 3.25rem !important;
}

.gap-14 {
  gap: 3.5rem !important;
}

.gap-15 {
  gap: 3.75rem !important;
}

.gap-16 {
  gap: 4rem !important;
}

.gap-17 {
  gap: 4.25rem !important;
}

.gap-18 {
  gap: 4.5rem !important;
}

.gap-19 {
  gap: 4.75rem !important;
}

.gap-20 {
  gap: 5rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 0.75rem !important;
}

.row-gap-4 {
  row-gap: 1rem !important;
}

.row-gap-5 {
  row-gap: 1.25rem !important;
}

.row-gap-6 {
  row-gap: 1.5rem !important;
}

.row-gap-7 {
  row-gap: 1.75rem !important;
}

.row-gap-8 {
  row-gap: 2rem !important;
}

.row-gap-9 {
  row-gap: 2.25rem !important;
}

.row-gap-10 {
  row-gap: 2.5rem !important;
}

.row-gap-11 {
  row-gap: 2.75rem !important;
}

.row-gap-12 {
  row-gap: 3rem !important;
}

.row-gap-13 {
  row-gap: 3.25rem !important;
}

.row-gap-14 {
  row-gap: 3.5rem !important;
}

.row-gap-15 {
  row-gap: 3.75rem !important;
}

.row-gap-16 {
  row-gap: 4rem !important;
}

.row-gap-17 {
  row-gap: 4.25rem !important;
}

.row-gap-18 {
  row-gap: 4.5rem !important;
}

.row-gap-19 {
  row-gap: 4.75rem !important;
}

.row-gap-20 {
  row-gap: 5rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 0.75rem !important;
}

.column-gap-4 {
  column-gap: 1rem !important;
}

.column-gap-5 {
  column-gap: 1.25rem !important;
}

.column-gap-6 {
  column-gap: 1.5rem !important;
}

.column-gap-7 {
  column-gap: 1.75rem !important;
}

.column-gap-8 {
  column-gap: 2rem !important;
}

.column-gap-9 {
  column-gap: 2.25rem !important;
}

.column-gap-10 {
  column-gap: 2.5rem !important;
}

.column-gap-11 {
  column-gap: 2.75rem !important;
}

.column-gap-12 {
  column-gap: 3rem !important;
}

.column-gap-13 {
  column-gap: 3.25rem !important;
}

.column-gap-14 {
  column-gap: 3.5rem !important;
}

.column-gap-15 {
  column-gap: 3.75rem !important;
}

.column-gap-16 {
  column-gap: 4rem !important;
}

.column-gap-17 {
  column-gap: 4.25rem !important;
}

.column-gap-18 {
  column-gap: 4.5rem !important;
}

.column-gap-19 {
  column-gap: 4.75rem !important;
}

.column-gap-20 {
  column-gap: 5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-2 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-3 {
  font-size: calc(1.26rem + 0.12vw) !important;
}

.fs-4 {
  font-size: 1.25rem !important;
}

.fs-5 {
  font-size: 1.15rem !important;
}

.fs-6 {
  font-size: 1.075rem !important;
}

.fs-7 {
  font-size: 0.95rem !important;
}

.fs-8 {
  font-size: 0.85rem !important;
}

.fs-9 {
  font-size: 0.75rem !important;
}

.fs-10 {
  font-size: 0.5rem !important;
}

.fs-base {
  font-size: 1rem !important;
}

.fs-fluid {
  font-size: 100% !important;
}

.fs-2x {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-2qx {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2hx {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2tx {
  font-size: calc(1.4rem + 1.8vw) !important;
}

.fs-3x {
  font-size: calc(1.425rem + 2.1vw) !important;
}

.fs-3qx {
  font-size: calc(1.45rem + 2.4vw) !important;
}

.fs-3hx {
  font-size: calc(1.475rem + 2.7vw) !important;
}

.fs-3tx {
  font-size: calc(1.5rem + 3vw) !important;
}

.fs-4x {
  font-size: calc(1.525rem + 3.3vw) !important;
}

.fs-4qx {
  font-size: calc(1.55rem + 3.6vw) !important;
}

.fs-4hx {
  font-size: calc(1.575rem + 3.9vw) !important;
}

.fs-4tx {
  font-size: calc(1.6rem + 4.2vw) !important;
}

.fs-5x {
  font-size: calc(1.625rem + 4.5vw) !important;
}

.fs-5qx {
  font-size: calc(1.65rem + 4.8vw) !important;
}

.fs-5hx {
  font-size: calc(1.675rem + 5.1vw) !important;
}

.fs-5tx {
  font-size: calc(1.7rem + 5.4vw) !important;
}

.fs-10x {
  font-size: calc(2.125rem + 10.5vw) !important;
}

.fs-10qx {
  font-size: calc(2.15rem + 10.8vw) !important;
}

.fs-10hx {
  font-size: calc(2.175rem + 11.1vw) !important;
}

.fs-10tx {
  font-size: calc(2.2rem + 11.4vw) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 500 !important;
}

.fw-bolder {
  font-weight: 600 !important;
}

.lh-0 {
  line-height: 0 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 1.75 !important;
}

.lh-xl {
  line-height: 2 !important;
}

.lh-xxl {
  line-height: 2.25 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-blue {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-rgb), var(--bs-text-opacity)) !important;
}

.text-indigo {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-indigo-rgb), var(--bs-text-opacity)) !important;
}

.text-purple {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-purple-rgb), var(--bs-text-opacity)) !important;
}

.text-pink {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;
}

.text-red {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-rgb), var(--bs-text-opacity)) !important;
}

.text-orange {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-rgb), var(--bs-text-opacity)) !important;
}

.text-green {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-rgb), var(--bs-text-opacity)) !important;
}

.text-teal {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-teal-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-rgb), var(--bs-text-opacity)) !important;
}

.text-gray {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-gray-rgb), var(--bs-text-opacity)) !important;
}

.text-body-bg {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-bg-rgb), var(--bs-text-opacity)) !important;
}

.text-body-text {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-text-rgb), var(--bs-text-opacity)) !important;
}

.text-toolbar-bg {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-toolbar-bg-rgb), var(--bs-text-opacity)) !important;
}

.text-toolbar-text {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-toolbar-text-rgb), var(--bs-text-opacity)) !important;
}

.text-focus-bg {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-focus-bg-rgb), var(--bs-text-opacity)) !important;
}

.text-tabbar-text {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tabbar-text-rgb), var(--bs-text-opacity)) !important;
}

.text-card-bg {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-card-bg-rgb), var(--bs-text-opacity)) !important;
}

.text-card-text {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-card-text-rgb), var(--bs-text-opacity)) !important;
}

.text-item-bg {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-item-bg-rgb), var(--bs-text-opacity)) !important;
}

.text-item-text {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-item-text-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-white {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-white-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-black {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-black-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-muted {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-muted-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-blue {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-blue-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-indigo {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-indigo-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-purple {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-purple-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-pink {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-pink-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-red {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-red-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-orange {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-orange-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-yellow {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-yellow-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-green {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-green-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-teal {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-teal-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-cyan {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-cyan-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-gray {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-gray-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-body-bg {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-body-bg-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-body-text {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-body-text-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-toolbar-bg {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-toolbar-bg-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-toolbar-text {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-toolbar-text-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-focus-bg {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-focus-bg-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-tabbar-text {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tabbar-text-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-card-bg {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-card-bg-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-card-text {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-card-text-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-item-bg {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-item-bg-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-item-text {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-item-text-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-muted {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-muted-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-rgb), var(--bs-bg-opacity)) !important;
}

.bg-indigo {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-indigo-rgb), var(--bs-bg-opacity)) !important;
}

.bg-purple {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-purple-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-rgb), var(--bs-bg-opacity)) !important;
}

.bg-teal {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-teal-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-rgb), var(--bs-bg-opacity)) !important;
}

.bg-gray {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-gray-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-bg {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-text {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-text-rgb), var(--bs-bg-opacity)) !important;
}

.bg-toolbar-bg {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-toolbar-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-toolbar-text {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-toolbar-text-rgb), var(--bs-bg-opacity)) !important;
}

.bg-focus-bg {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-focus-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tabbar-text {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tabbar-text-rgb), var(--bs-bg-opacity)) !important;
}

.bg-card-bg {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-card-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-card-text {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-card-text-rgb), var(--bs-bg-opacity)) !important;
}

.bg-item-bg {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-item-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-item-text {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-item-text-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-index-n1 {
  z-index: -1 !important;
}

.z-index-n2 {
  z-index: -2 !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-0-hover:hover {
  opacity: 0 !important;
}

.opacity-5 {
  opacity: 0.05 !important;
}

.opacity-5-hover:hover {
  opacity: 0.05 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-10-hover:hover {
  opacity: 0.1 !important;
}

.opacity-15 {
  opacity: 0.15 !important;
}

.opacity-15-hover:hover {
  opacity: 0.15 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-20-hover:hover {
  opacity: 0.2 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-25-hover:hover {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-50-hover:hover {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-75-hover:hover {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.opacity-100-hover:hover {
  opacity: 1 !important;
}

.min-w-unset {
  min-width: unset !important;
}

.min-w-25 {
  min-width: 25% !important;
}

.min-w-50 {
  min-width: 50% !important;
}

.min-w-75 {
  min-width: 75% !important;
}

.min-w-100 {
  min-width: 100% !important;
}

.min-w-auto {
  min-width: auto !important;
}

.min-w-1px {
  min-width: 1px !important;
}

.min-w-2px {
  min-width: 2px !important;
}

.min-w-3px {
  min-width: 3px !important;
}

.min-w-4px {
  min-width: 4px !important;
}

.min-w-5px {
  min-width: 5px !important;
}

.min-w-6px {
  min-width: 6px !important;
}

.min-w-7px {
  min-width: 7px !important;
}

.min-w-8px {
  min-width: 8px !important;
}

.min-w-9px {
  min-width: 9px !important;
}

.min-w-10px {
  min-width: 10px !important;
}

.min-w-15px {
  min-width: 15px !important;
}

.min-w-20px {
  min-width: 20px !important;
}

.min-w-25px {
  min-width: 25px !important;
}

.min-w-30px {
  min-width: 30px !important;
}

.min-w-35px {
  min-width: 35px !important;
}

.min-w-40px {
  min-width: 40px !important;
}

.min-w-45px {
  min-width: 45px !important;
}

.min-w-50px {
  min-width: 50px !important;
}

.min-w-55px {
  min-width: 55px !important;
}

.min-w-60px {
  min-width: 60px !important;
}

.min-w-65px {
  min-width: 65px !important;
}

.min-w-70px {
  min-width: 70px !important;
}

.min-w-75px {
  min-width: 75px !important;
}

.min-w-80px {
  min-width: 80px !important;
}

.min-w-85px {
  min-width: 85px !important;
}

.min-w-90px {
  min-width: 90px !important;
}

.min-w-95px {
  min-width: 95px !important;
}

.min-w-100px {
  min-width: 100px !important;
}

.min-w-125px {
  min-width: 125px !important;
}

.min-w-150px {
  min-width: 150px !important;
}

.min-w-175px {
  min-width: 175px !important;
}

.min-w-200px {
  min-width: 200px !important;
}

.min-w-225px {
  min-width: 225px !important;
}

.min-w-250px {
  min-width: 250px !important;
}

.min-w-275px {
  min-width: 275px !important;
}

.min-w-300px {
  min-width: 300px !important;
}

.min-w-325px {
  min-width: 325px !important;
}

.min-w-350px {
  min-width: 350px !important;
}

.min-w-375px {
  min-width: 375px !important;
}

.min-w-400px {
  min-width: 400px !important;
}

.min-w-425px {
  min-width: 425px !important;
}

.min-w-450px {
  min-width: 450px !important;
}

.min-w-475px {
  min-width: 475px !important;
}

.min-w-500px {
  min-width: 500px !important;
}

.min-w-550px {
  min-width: 550px !important;
}

.min-w-600px {
  min-width: 600px !important;
}

.min-w-650px {
  min-width: 650px !important;
}

.min-w-700px {
  min-width: 700px !important;
}

.min-w-750px {
  min-width: 750px !important;
}

.min-w-800px {
  min-width: 800px !important;
}

.min-w-850px {
  min-width: 850px !important;
}

.min-w-900px {
  min-width: 900px !important;
}

.min-w-950px {
  min-width: 950px !important;
}

.min-w-1000px {
  min-width: 1000px !important;
}

.min-h-unset {
  min-height: unset !important;
}

.min-h-25 {
  min-height: 25% !important;
}

.min-h-50 {
  min-height: 50% !important;
}

.min-h-75 {
  min-height: 75% !important;
}

.min-h-100 {
  min-height: 100% !important;
}

.min-h-auto {
  min-height: auto !important;
}

.min-h-1px {
  min-height: 1px !important;
}

.min-h-2px {
  min-height: 2px !important;
}

.min-h-3px {
  min-height: 3px !important;
}

.min-h-4px {
  min-height: 4px !important;
}

.min-h-5px {
  min-height: 5px !important;
}

.min-h-6px {
  min-height: 6px !important;
}

.min-h-7px {
  min-height: 7px !important;
}

.min-h-8px {
  min-height: 8px !important;
}

.min-h-9px {
  min-height: 9px !important;
}

.min-h-10px {
  min-height: 10px !important;
}

.min-h-15px {
  min-height: 15px !important;
}

.min-h-20px {
  min-height: 20px !important;
}

.min-h-25px {
  min-height: 25px !important;
}

.min-h-30px {
  min-height: 30px !important;
}

.min-h-35px {
  min-height: 35px !important;
}

.min-h-40px {
  min-height: 40px !important;
}

.min-h-45px {
  min-height: 45px !important;
}

.min-h-50px {
  min-height: 50px !important;
}

.min-h-55px {
  min-height: 55px !important;
}

.min-h-60px {
  min-height: 60px !important;
}

.min-h-65px {
  min-height: 65px !important;
}

.min-h-70px {
  min-height: 70px !important;
}

.min-h-75px {
  min-height: 75px !important;
}

.min-h-80px {
  min-height: 80px !important;
}

.min-h-85px {
  min-height: 85px !important;
}

.min-h-90px {
  min-height: 90px !important;
}

.min-h-95px {
  min-height: 95px !important;
}

.min-h-100px {
  min-height: 100px !important;
}

.min-h-125px {
  min-height: 125px !important;
}

.min-h-150px {
  min-height: 150px !important;
}

.min-h-175px {
  min-height: 175px !important;
}

.min-h-200px {
  min-height: 200px !important;
}

.min-h-225px {
  min-height: 225px !important;
}

.min-h-250px {
  min-height: 250px !important;
}

.min-h-275px {
  min-height: 275px !important;
}

.min-h-300px {
  min-height: 300px !important;
}

.min-h-325px {
  min-height: 325px !important;
}

.min-h-350px {
  min-height: 350px !important;
}

.min-h-375px {
  min-height: 375px !important;
}

.min-h-400px {
  min-height: 400px !important;
}

.min-h-425px {
  min-height: 425px !important;
}

.min-h-450px {
  min-height: 450px !important;
}

.min-h-475px {
  min-height: 475px !important;
}

.min-h-500px {
  min-height: 500px !important;
}

.min-h-550px {
  min-height: 550px !important;
}

.min-h-600px {
  min-height: 600px !important;
}

.min-h-650px {
  min-height: 650px !important;
}

.min-h-700px {
  min-height: 700px !important;
}

.min-h-750px {
  min-height: 750px !important;
}

.min-h-800px {
  min-height: 800px !important;
}

.min-h-850px {
  min-height: 850px !important;
}

.min-h-900px {
  min-height: 900px !important;
}

.min-h-950px {
  min-height: 950px !important;
}

.min-h-1000px {
  min-height: 1000px !important;
}

.border-top-0 {
  border-top-width: 0 !important;
}

.border-top-1 {
  border-top-width: 1px !important;
}

.border-top-2 {
  border-top-width: 2px !important;
}

.border-top-3 {
  border-top-width: 3px !important;
}

.border-top-4 {
  border-top-width: 4px !important;
}

.border-top-5 {
  border-top-width: 5px !important;
}

.border-bottom-0 {
  border-bottom-width: 0 !important;
}

.border-bottom-1 {
  border-bottom-width: 1px !important;
}

.border-bottom-2 {
  border-bottom-width: 2px !important;
}

.border-bottom-3 {
  border-bottom-width: 3px !important;
}

.border-bottom-4 {
  border-bottom-width: 4px !important;
}

.border-bottom-5 {
  border-bottom-width: 5px !important;
}

.border-right-0 {
  border-right-width: 0 !important;
}

.border-right-1 {
  border-right-width: 1px !important;
}

.border-right-2 {
  border-right-width: 2px !important;
}

.border-right-3 {
  border-right-width: 3px !important;
}

.border-right-4 {
  border-right-width: 4px !important;
}

.border-right-5 {
  border-right-width: 5px !important;
}

.border-left-0 {
  border-left-width: 0 !important;
}

.border-left-1 {
  border-left-width: 1px !important;
}

.border-left-2 {
  border-left-width: 2px !important;
}

.border-left-3 {
  border-left-width: 3px !important;
}

.border-left-4 {
  border-left-width: 4px !important;
}

.border-left-5 {
  border-left-width: 5px !important;
}

.ls-1 {
  letter-spacing: 0.1rem !important;
}

.ls-2 {
  letter-spacing: 0.115rem !important;
}

.ls-3 {
  letter-spacing: 0.125rem !important;
}

.ls-4 {
  letter-spacing: 0.25rem !important;
}

.ls-5 {
  letter-spacing: 0.5rem !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .w-sm-unset {
    width: unset !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-auto {
    width: auto !important;
  }

  .w-sm-1px {
    width: 1px !important;
  }

  .w-sm-2px {
    width: 2px !important;
  }

  .w-sm-3px {
    width: 3px !important;
  }

  .w-sm-4px {
    width: 4px !important;
  }

  .w-sm-5px {
    width: 5px !important;
  }

  .w-sm-6px {
    width: 6px !important;
  }

  .w-sm-7px {
    width: 7px !important;
  }

  .w-sm-8px {
    width: 8px !important;
  }

  .w-sm-9px {
    width: 9px !important;
  }

  .w-sm-10px {
    width: 10px !important;
  }

  .w-sm-15px {
    width: 15px !important;
  }

  .w-sm-20px {
    width: 20px !important;
  }

  .w-sm-25px {
    width: 25px !important;
  }

  .w-sm-30px {
    width: 30px !important;
  }

  .w-sm-35px {
    width: 35px !important;
  }

  .w-sm-40px {
    width: 40px !important;
  }

  .w-sm-45px {
    width: 45px !important;
  }

  .w-sm-50px {
    width: 50px !important;
  }

  .w-sm-55px {
    width: 55px !important;
  }

  .w-sm-60px {
    width: 60px !important;
  }

  .w-sm-65px {
    width: 65px !important;
  }

  .w-sm-70px {
    width: 70px !important;
  }

  .w-sm-75px {
    width: 75px !important;
  }

  .w-sm-80px {
    width: 80px !important;
  }

  .w-sm-85px {
    width: 85px !important;
  }

  .w-sm-90px {
    width: 90px !important;
  }

  .w-sm-95px {
    width: 95px !important;
  }

  .w-sm-100px {
    width: 100px !important;
  }

  .w-sm-125px {
    width: 125px !important;
  }

  .w-sm-150px {
    width: 150px !important;
  }

  .w-sm-175px {
    width: 175px !important;
  }

  .w-sm-200px {
    width: 200px !important;
  }

  .w-sm-225px {
    width: 225px !important;
  }

  .w-sm-250px {
    width: 250px !important;
  }

  .w-sm-275px {
    width: 275px !important;
  }

  .w-sm-300px {
    width: 300px !important;
  }

  .w-sm-325px {
    width: 325px !important;
  }

  .w-sm-350px {
    width: 350px !important;
  }

  .w-sm-375px {
    width: 375px !important;
  }

  .w-sm-400px {
    width: 400px !important;
  }

  .w-sm-425px {
    width: 425px !important;
  }

  .w-sm-450px {
    width: 450px !important;
  }

  .w-sm-475px {
    width: 475px !important;
  }

  .w-sm-500px {
    width: 500px !important;
  }

  .w-sm-550px {
    width: 550px !important;
  }

  .w-sm-600px {
    width: 600px !important;
  }

  .w-sm-650px {
    width: 650px !important;
  }

  .w-sm-700px {
    width: 700px !important;
  }

  .w-sm-750px {
    width: 750px !important;
  }

  .w-sm-800px {
    width: 800px !important;
  }

  .w-sm-850px {
    width: 850px !important;
  }

  .w-sm-900px {
    width: 900px !important;
  }

  .w-sm-950px {
    width: 950px !important;
  }

  .w-sm-1000px {
    width: 1000px !important;
  }

  .mw-sm-unset {
    max-width: unset !important;
  }

  .mw-sm-25 {
    max-width: 25% !important;
  }

  .mw-sm-50 {
    max-width: 50% !important;
  }

  .mw-sm-75 {
    max-width: 75% !important;
  }

  .mw-sm-100 {
    max-width: 100% !important;
  }

  .mw-sm-auto {
    max-width: auto !important;
  }

  .mw-sm-1px {
    max-width: 1px !important;
  }

  .mw-sm-2px {
    max-width: 2px !important;
  }

  .mw-sm-3px {
    max-width: 3px !important;
  }

  .mw-sm-4px {
    max-width: 4px !important;
  }

  .mw-sm-5px {
    max-width: 5px !important;
  }

  .mw-sm-6px {
    max-width: 6px !important;
  }

  .mw-sm-7px {
    max-width: 7px !important;
  }

  .mw-sm-8px {
    max-width: 8px !important;
  }

  .mw-sm-9px {
    max-width: 9px !important;
  }

  .mw-sm-10px {
    max-width: 10px !important;
  }

  .mw-sm-15px {
    max-width: 15px !important;
  }

  .mw-sm-20px {
    max-width: 20px !important;
  }

  .mw-sm-25px {
    max-width: 25px !important;
  }

  .mw-sm-30px {
    max-width: 30px !important;
  }

  .mw-sm-35px {
    max-width: 35px !important;
  }

  .mw-sm-40px {
    max-width: 40px !important;
  }

  .mw-sm-45px {
    max-width: 45px !important;
  }

  .mw-sm-50px {
    max-width: 50px !important;
  }

  .mw-sm-55px {
    max-width: 55px !important;
  }

  .mw-sm-60px {
    max-width: 60px !important;
  }

  .mw-sm-65px {
    max-width: 65px !important;
  }

  .mw-sm-70px {
    max-width: 70px !important;
  }

  .mw-sm-75px {
    max-width: 75px !important;
  }

  .mw-sm-80px {
    max-width: 80px !important;
  }

  .mw-sm-85px {
    max-width: 85px !important;
  }

  .mw-sm-90px {
    max-width: 90px !important;
  }

  .mw-sm-95px {
    max-width: 95px !important;
  }

  .mw-sm-100px {
    max-width: 100px !important;
  }

  .mw-sm-125px {
    max-width: 125px !important;
  }

  .mw-sm-150px {
    max-width: 150px !important;
  }

  .mw-sm-175px {
    max-width: 175px !important;
  }

  .mw-sm-200px {
    max-width: 200px !important;
  }

  .mw-sm-225px {
    max-width: 225px !important;
  }

  .mw-sm-250px {
    max-width: 250px !important;
  }

  .mw-sm-275px {
    max-width: 275px !important;
  }

  .mw-sm-300px {
    max-width: 300px !important;
  }

  .mw-sm-325px {
    max-width: 325px !important;
  }

  .mw-sm-350px {
    max-width: 350px !important;
  }

  .mw-sm-375px {
    max-width: 375px !important;
  }

  .mw-sm-400px {
    max-width: 400px !important;
  }

  .mw-sm-425px {
    max-width: 425px !important;
  }

  .mw-sm-450px {
    max-width: 450px !important;
  }

  .mw-sm-475px {
    max-width: 475px !important;
  }

  .mw-sm-500px {
    max-width: 500px !important;
  }

  .mw-sm-550px {
    max-width: 550px !important;
  }

  .mw-sm-600px {
    max-width: 600px !important;
  }

  .mw-sm-650px {
    max-width: 650px !important;
  }

  .mw-sm-700px {
    max-width: 700px !important;
  }

  .mw-sm-750px {
    max-width: 750px !important;
  }

  .mw-sm-800px {
    max-width: 800px !important;
  }

  .mw-sm-850px {
    max-width: 850px !important;
  }

  .mw-sm-900px {
    max-width: 900px !important;
  }

  .mw-sm-950px {
    max-width: 950px !important;
  }

  .mw-sm-1000px {
    max-width: 1000px !important;
  }

  .h-sm-unset {
    height: unset !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .h-sm-auto {
    height: auto !important;
  }

  .h-sm-1px {
    height: 1px !important;
  }

  .h-sm-2px {
    height: 2px !important;
  }

  .h-sm-3px {
    height: 3px !important;
  }

  .h-sm-4px {
    height: 4px !important;
  }

  .h-sm-5px {
    height: 5px !important;
  }

  .h-sm-6px {
    height: 6px !important;
  }

  .h-sm-7px {
    height: 7px !important;
  }

  .h-sm-8px {
    height: 8px !important;
  }

  .h-sm-9px {
    height: 9px !important;
  }

  .h-sm-10px {
    height: 10px !important;
  }

  .h-sm-15px {
    height: 15px !important;
  }

  .h-sm-20px {
    height: 20px !important;
  }

  .h-sm-25px {
    height: 25px !important;
  }

  .h-sm-30px {
    height: 30px !important;
  }

  .h-sm-35px {
    height: 35px !important;
  }

  .h-sm-40px {
    height: 40px !important;
  }

  .h-sm-45px {
    height: 45px !important;
  }

  .h-sm-50px {
    height: 50px !important;
  }

  .h-sm-55px {
    height: 55px !important;
  }

  .h-sm-60px {
    height: 60px !important;
  }

  .h-sm-65px {
    height: 65px !important;
  }

  .h-sm-70px {
    height: 70px !important;
  }

  .h-sm-75px {
    height: 75px !important;
  }

  .h-sm-80px {
    height: 80px !important;
  }

  .h-sm-85px {
    height: 85px !important;
  }

  .h-sm-90px {
    height: 90px !important;
  }

  .h-sm-95px {
    height: 95px !important;
  }

  .h-sm-100px {
    height: 100px !important;
  }

  .h-sm-125px {
    height: 125px !important;
  }

  .h-sm-150px {
    height: 150px !important;
  }

  .h-sm-175px {
    height: 175px !important;
  }

  .h-sm-200px {
    height: 200px !important;
  }

  .h-sm-225px {
    height: 225px !important;
  }

  .h-sm-250px {
    height: 250px !important;
  }

  .h-sm-275px {
    height: 275px !important;
  }

  .h-sm-300px {
    height: 300px !important;
  }

  .h-sm-325px {
    height: 325px !important;
  }

  .h-sm-350px {
    height: 350px !important;
  }

  .h-sm-375px {
    height: 375px !important;
  }

  .h-sm-400px {
    height: 400px !important;
  }

  .h-sm-425px {
    height: 425px !important;
  }

  .h-sm-450px {
    height: 450px !important;
  }

  .h-sm-475px {
    height: 475px !important;
  }

  .h-sm-500px {
    height: 500px !important;
  }

  .h-sm-550px {
    height: 550px !important;
  }

  .h-sm-600px {
    height: 600px !important;
  }

  .h-sm-650px {
    height: 650px !important;
  }

  .h-sm-700px {
    height: 700px !important;
  }

  .h-sm-750px {
    height: 750px !important;
  }

  .h-sm-800px {
    height: 800px !important;
  }

  .h-sm-850px {
    height: 850px !important;
  }

  .h-sm-900px {
    height: 900px !important;
  }

  .h-sm-950px {
    height: 950px !important;
  }

  .h-sm-1000px {
    height: 1000px !important;
  }

  .mh-sm-unset {
    max-height: unset !important;
  }

  .mh-sm-25 {
    max-height: 25% !important;
  }

  .mh-sm-50 {
    max-height: 50% !important;
  }

  .mh-sm-75 {
    max-height: 75% !important;
  }

  .mh-sm-100 {
    max-height: 100% !important;
  }

  .mh-sm-auto {
    max-height: auto !important;
  }

  .mh-sm-1px {
    max-height: 1px !important;
  }

  .mh-sm-2px {
    max-height: 2px !important;
  }

  .mh-sm-3px {
    max-height: 3px !important;
  }

  .mh-sm-4px {
    max-height: 4px !important;
  }

  .mh-sm-5px {
    max-height: 5px !important;
  }

  .mh-sm-6px {
    max-height: 6px !important;
  }

  .mh-sm-7px {
    max-height: 7px !important;
  }

  .mh-sm-8px {
    max-height: 8px !important;
  }

  .mh-sm-9px {
    max-height: 9px !important;
  }

  .mh-sm-10px {
    max-height: 10px !important;
  }

  .mh-sm-15px {
    max-height: 15px !important;
  }

  .mh-sm-20px {
    max-height: 20px !important;
  }

  .mh-sm-25px {
    max-height: 25px !important;
  }

  .mh-sm-30px {
    max-height: 30px !important;
  }

  .mh-sm-35px {
    max-height: 35px !important;
  }

  .mh-sm-40px {
    max-height: 40px !important;
  }

  .mh-sm-45px {
    max-height: 45px !important;
  }

  .mh-sm-50px {
    max-height: 50px !important;
  }

  .mh-sm-55px {
    max-height: 55px !important;
  }

  .mh-sm-60px {
    max-height: 60px !important;
  }

  .mh-sm-65px {
    max-height: 65px !important;
  }

  .mh-sm-70px {
    max-height: 70px !important;
  }

  .mh-sm-75px {
    max-height: 75px !important;
  }

  .mh-sm-80px {
    max-height: 80px !important;
  }

  .mh-sm-85px {
    max-height: 85px !important;
  }

  .mh-sm-90px {
    max-height: 90px !important;
  }

  .mh-sm-95px {
    max-height: 95px !important;
  }

  .mh-sm-100px {
    max-height: 100px !important;
  }

  .mh-sm-125px {
    max-height: 125px !important;
  }

  .mh-sm-150px {
    max-height: 150px !important;
  }

  .mh-sm-175px {
    max-height: 175px !important;
  }

  .mh-sm-200px {
    max-height: 200px !important;
  }

  .mh-sm-225px {
    max-height: 225px !important;
  }

  .mh-sm-250px {
    max-height: 250px !important;
  }

  .mh-sm-275px {
    max-height: 275px !important;
  }

  .mh-sm-300px {
    max-height: 300px !important;
  }

  .mh-sm-325px {
    max-height: 325px !important;
  }

  .mh-sm-350px {
    max-height: 350px !important;
  }

  .mh-sm-375px {
    max-height: 375px !important;
  }

  .mh-sm-400px {
    max-height: 400px !important;
  }

  .mh-sm-425px {
    max-height: 425px !important;
  }

  .mh-sm-450px {
    max-height: 450px !important;
  }

  .mh-sm-475px {
    max-height: 475px !important;
  }

  .mh-sm-500px {
    max-height: 500px !important;
  }

  .mh-sm-550px {
    max-height: 550px !important;
  }

  .mh-sm-600px {
    max-height: 600px !important;
  }

  .mh-sm-650px {
    max-height: 650px !important;
  }

  .mh-sm-700px {
    max-height: 700px !important;
  }

  .mh-sm-750px {
    max-height: 750px !important;
  }

  .mh-sm-800px {
    max-height: 800px !important;
  }

  .mh-sm-850px {
    max-height: 850px !important;
  }

  .mh-sm-900px {
    max-height: 900px !important;
  }

  .mh-sm-950px {
    max-height: 950px !important;
  }

  .mh-sm-1000px {
    max-height: 1000px !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 0.75rem !important;
  }

  .m-sm-4 {
    margin: 1rem !important;
  }

  .m-sm-5 {
    margin: 1.25rem !important;
  }

  .m-sm-6 {
    margin: 1.5rem !important;
  }

  .m-sm-7 {
    margin: 1.75rem !important;
  }

  .m-sm-8 {
    margin: 2rem !important;
  }

  .m-sm-9 {
    margin: 2.25rem !important;
  }

  .m-sm-10 {
    margin: 2.5rem !important;
  }

  .m-sm-11 {
    margin: 2.75rem !important;
  }

  .m-sm-12 {
    margin: 3rem !important;
  }

  .m-sm-13 {
    margin: 3.25rem !important;
  }

  .m-sm-14 {
    margin: 3.5rem !important;
  }

  .m-sm-15 {
    margin: 3.75rem !important;
  }

  .m-sm-16 {
    margin: 4rem !important;
  }

  .m-sm-17 {
    margin: 4.25rem !important;
  }

  .m-sm-18 {
    margin: 4.5rem !important;
  }

  .m-sm-19 {
    margin: 4.75rem !important;
  }

  .m-sm-20 {
    margin: 5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-sm-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-sm-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-sm-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-sm-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-sm-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-sm-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-sm-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-sm-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-sm-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-sm-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-sm-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-sm-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-sm-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-sm-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-sm-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-sm-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-sm-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-sm-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-sm-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-sm-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-sm-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }

  .mt-sm-4 {
    margin-top: 1rem !important;
  }

  .mt-sm-5 {
    margin-top: 1.25rem !important;
  }

  .mt-sm-6 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-7 {
    margin-top: 1.75rem !important;
  }

  .mt-sm-8 {
    margin-top: 2rem !important;
  }

  .mt-sm-9 {
    margin-top: 2.25rem !important;
  }

  .mt-sm-10 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-11 {
    margin-top: 2.75rem !important;
  }

  .mt-sm-12 {
    margin-top: 3rem !important;
  }

  .mt-sm-13 {
    margin-top: 3.25rem !important;
  }

  .mt-sm-14 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-15 {
    margin-top: 3.75rem !important;
  }

  .mt-sm-16 {
    margin-top: 4rem !important;
  }

  .mt-sm-17 {
    margin-top: 4.25rem !important;
  }

  .mt-sm-18 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-19 {
    margin-top: 4.75rem !important;
  }

  .mt-sm-20 {
    margin-top: 5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 0.75rem !important;
  }

  .me-sm-4 {
    margin-right: 1rem !important;
  }

  .me-sm-5 {
    margin-right: 1.25rem !important;
  }

  .me-sm-6 {
    margin-right: 1.5rem !important;
  }

  .me-sm-7 {
    margin-right: 1.75rem !important;
  }

  .me-sm-8 {
    margin-right: 2rem !important;
  }

  .me-sm-9 {
    margin-right: 2.25rem !important;
  }

  .me-sm-10 {
    margin-right: 2.5rem !important;
  }

  .me-sm-11 {
    margin-right: 2.75rem !important;
  }

  .me-sm-12 {
    margin-right: 3rem !important;
  }

  .me-sm-13 {
    margin-right: 3.25rem !important;
  }

  .me-sm-14 {
    margin-right: 3.5rem !important;
  }

  .me-sm-15 {
    margin-right: 3.75rem !important;
  }

  .me-sm-16 {
    margin-right: 4rem !important;
  }

  .me-sm-17 {
    margin-right: 4.25rem !important;
  }

  .me-sm-18 {
    margin-right: 4.5rem !important;
  }

  .me-sm-19 {
    margin-right: 4.75rem !important;
  }

  .me-sm-20 {
    margin-right: 5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-sm-8 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-sm-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-sm-12 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-sm-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-sm-16 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-sm-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-sm-20 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 0.75rem !important;
  }

  .ms-sm-4 {
    margin-left: 1rem !important;
  }

  .ms-sm-5 {
    margin-left: 1.25rem !important;
  }

  .ms-sm-6 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-7 {
    margin-left: 1.75rem !important;
  }

  .ms-sm-8 {
    margin-left: 2rem !important;
  }

  .ms-sm-9 {
    margin-left: 2.25rem !important;
  }

  .ms-sm-10 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-11 {
    margin-left: 2.75rem !important;
  }

  .ms-sm-12 {
    margin-left: 3rem !important;
  }

  .ms-sm-13 {
    margin-left: 3.25rem !important;
  }

  .ms-sm-14 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-15 {
    margin-left: 3.75rem !important;
  }

  .ms-sm-16 {
    margin-left: 4rem !important;
  }

  .ms-sm-17 {
    margin-left: 4.25rem !important;
  }

  .ms-sm-18 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-19 {
    margin-left: 4.75rem !important;
  }

  .ms-sm-20 {
    margin-left: 5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -0.75rem !important;
  }

  .m-sm-n4 {
    margin: -1rem !important;
  }

  .m-sm-n5 {
    margin: -1.25rem !important;
  }

  .m-sm-n6 {
    margin: -1.5rem !important;
  }

  .m-sm-n7 {
    margin: -1.75rem !important;
  }

  .m-sm-n8 {
    margin: -2rem !important;
  }

  .m-sm-n9 {
    margin: -2.25rem !important;
  }

  .m-sm-n10 {
    margin: -2.5rem !important;
  }

  .m-sm-n11 {
    margin: -2.75rem !important;
  }

  .m-sm-n12 {
    margin: -3rem !important;
  }

  .m-sm-n13 {
    margin: -3.25rem !important;
  }

  .m-sm-n14 {
    margin: -3.5rem !important;
  }

  .m-sm-n15 {
    margin: -3.75rem !important;
  }

  .m-sm-n16 {
    margin: -4rem !important;
  }

  .m-sm-n17 {
    margin: -4.25rem !important;
  }

  .m-sm-n18 {
    margin: -4.5rem !important;
  }

  .m-sm-n19 {
    margin: -4.75rem !important;
  }

  .m-sm-n20 {
    margin: -5rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-sm-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-sm-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-sm-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-sm-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-sm-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-sm-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-sm-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-sm-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-sm-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-sm-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-sm-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-sm-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-sm-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-sm-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-sm-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-sm-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-sm-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-sm-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-sm-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-sm-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-sm-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-sm-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-sm-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-sm-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-sm-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-sm-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-sm-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-sm-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-sm-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1rem !important;
  }

  .mt-sm-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-sm-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-sm-n8 {
    margin-top: -2rem !important;
  }

  .mt-sm-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-sm-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-sm-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-sm-n12 {
    margin-top: -3rem !important;
  }

  .mt-sm-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-sm-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-sm-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-sm-n16 {
    margin-top: -4rem !important;
  }

  .mt-sm-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-sm-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-sm-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-sm-n20 {
    margin-top: -5rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -0.75rem !important;
  }

  .me-sm-n4 {
    margin-right: -1rem !important;
  }

  .me-sm-n5 {
    margin-right: -1.25rem !important;
  }

  .me-sm-n6 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n7 {
    margin-right: -1.75rem !important;
  }

  .me-sm-n8 {
    margin-right: -2rem !important;
  }

  .me-sm-n9 {
    margin-right: -2.25rem !important;
  }

  .me-sm-n10 {
    margin-right: -2.5rem !important;
  }

  .me-sm-n11 {
    margin-right: -2.75rem !important;
  }

  .me-sm-n12 {
    margin-right: -3rem !important;
  }

  .me-sm-n13 {
    margin-right: -3.25rem !important;
  }

  .me-sm-n14 {
    margin-right: -3.5rem !important;
  }

  .me-sm-n15 {
    margin-right: -3.75rem !important;
  }

  .me-sm-n16 {
    margin-right: -4rem !important;
  }

  .me-sm-n17 {
    margin-right: -4.25rem !important;
  }

  .me-sm-n18 {
    margin-right: -4.5rem !important;
  }

  .me-sm-n19 {
    margin-right: -4.75rem !important;
  }

  .me-sm-n20 {
    margin-right: -5rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-sm-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-sm-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-sm-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-sm-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-sm-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-sm-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-sm-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-sm-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-sm-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-sm-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-sm-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-sm-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-sm-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-sm-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1rem !important;
  }

  .ms-sm-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-sm-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-sm-n8 {
    margin-left: -2rem !important;
  }

  .ms-sm-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-sm-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-sm-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-sm-n12 {
    margin-left: -3rem !important;
  }

  .ms-sm-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-sm-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-sm-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-sm-n16 {
    margin-left: -4rem !important;
  }

  .ms-sm-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-sm-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-sm-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-sm-n20 {
    margin-left: -5rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 0.75rem !important;
  }

  .p-sm-4 {
    padding: 1rem !important;
  }

  .p-sm-5 {
    padding: 1.25rem !important;
  }

  .p-sm-6 {
    padding: 1.5rem !important;
  }

  .p-sm-7 {
    padding: 1.75rem !important;
  }

  .p-sm-8 {
    padding: 2rem !important;
  }

  .p-sm-9 {
    padding: 2.25rem !important;
  }

  .p-sm-10 {
    padding: 2.5rem !important;
  }

  .p-sm-11 {
    padding: 2.75rem !important;
  }

  .p-sm-12 {
    padding: 3rem !important;
  }

  .p-sm-13 {
    padding: 3.25rem !important;
  }

  .p-sm-14 {
    padding: 3.5rem !important;
  }

  .p-sm-15 {
    padding: 3.75rem !important;
  }

  .p-sm-16 {
    padding: 4rem !important;
  }

  .p-sm-17 {
    padding: 4.25rem !important;
  }

  .p-sm-18 {
    padding: 4.5rem !important;
  }

  .p-sm-19 {
    padding: 4.75rem !important;
  }

  .p-sm-20 {
    padding: 5rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-sm-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-sm-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-sm-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-sm-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-sm-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-sm-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-sm-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-sm-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-sm-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-sm-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-sm-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-sm-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-sm-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-sm-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-sm-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-sm-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-sm-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-sm-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-sm-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-sm-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-sm-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-sm-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-sm-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }

  .pt-sm-4 {
    padding-top: 1rem !important;
  }

  .pt-sm-5 {
    padding-top: 1.25rem !important;
  }

  .pt-sm-6 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-7 {
    padding-top: 1.75rem !important;
  }

  .pt-sm-8 {
    padding-top: 2rem !important;
  }

  .pt-sm-9 {
    padding-top: 2.25rem !important;
  }

  .pt-sm-10 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-11 {
    padding-top: 2.75rem !important;
  }

  .pt-sm-12 {
    padding-top: 3rem !important;
  }

  .pt-sm-13 {
    padding-top: 3.25rem !important;
  }

  .pt-sm-14 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-15 {
    padding-top: 3.75rem !important;
  }

  .pt-sm-16 {
    padding-top: 4rem !important;
  }

  .pt-sm-17 {
    padding-top: 4.25rem !important;
  }

  .pt-sm-18 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-19 {
    padding-top: 4.75rem !important;
  }

  .pt-sm-20 {
    padding-top: 5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 0.75rem !important;
  }

  .pe-sm-4 {
    padding-right: 1rem !important;
  }

  .pe-sm-5 {
    padding-right: 1.25rem !important;
  }

  .pe-sm-6 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-7 {
    padding-right: 1.75rem !important;
  }

  .pe-sm-8 {
    padding-right: 2rem !important;
  }

  .pe-sm-9 {
    padding-right: 2.25rem !important;
  }

  .pe-sm-10 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-11 {
    padding-right: 2.75rem !important;
  }

  .pe-sm-12 {
    padding-right: 3rem !important;
  }

  .pe-sm-13 {
    padding-right: 3.25rem !important;
  }

  .pe-sm-14 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-15 {
    padding-right: 3.75rem !important;
  }

  .pe-sm-16 {
    padding-right: 4rem !important;
  }

  .pe-sm-17 {
    padding-right: 4.25rem !important;
  }

  .pe-sm-18 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-19 {
    padding-right: 4.75rem !important;
  }

  .pe-sm-20 {
    padding-right: 5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-sm-8 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-sm-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-sm-12 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-sm-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-sm-16 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-sm-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-sm-20 {
    padding-bottom: 5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 0.75rem !important;
  }

  .ps-sm-4 {
    padding-left: 1rem !important;
  }

  .ps-sm-5 {
    padding-left: 1.25rem !important;
  }

  .ps-sm-6 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-7 {
    padding-left: 1.75rem !important;
  }

  .ps-sm-8 {
    padding-left: 2rem !important;
  }

  .ps-sm-9 {
    padding-left: 2.25rem !important;
  }

  .ps-sm-10 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-11 {
    padding-left: 2.75rem !important;
  }

  .ps-sm-12 {
    padding-left: 3rem !important;
  }

  .ps-sm-13 {
    padding-left: 3.25rem !important;
  }

  .ps-sm-14 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-15 {
    padding-left: 3.75rem !important;
  }

  .ps-sm-16 {
    padding-left: 4rem !important;
  }

  .ps-sm-17 {
    padding-left: 4.25rem !important;
  }

  .ps-sm-18 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-19 {
    padding-left: 4.75rem !important;
  }

  .ps-sm-20 {
    padding-left: 5rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 0.75rem !important;
  }

  .gap-sm-4 {
    gap: 1rem !important;
  }

  .gap-sm-5 {
    gap: 1.25rem !important;
  }

  .gap-sm-6 {
    gap: 1.5rem !important;
  }

  .gap-sm-7 {
    gap: 1.75rem !important;
  }

  .gap-sm-8 {
    gap: 2rem !important;
  }

  .gap-sm-9 {
    gap: 2.25rem !important;
  }

  .gap-sm-10 {
    gap: 2.5rem !important;
  }

  .gap-sm-11 {
    gap: 2.75rem !important;
  }

  .gap-sm-12 {
    gap: 3rem !important;
  }

  .gap-sm-13 {
    gap: 3.25rem !important;
  }

  .gap-sm-14 {
    gap: 3.5rem !important;
  }

  .gap-sm-15 {
    gap: 3.75rem !important;
  }

  .gap-sm-16 {
    gap: 4rem !important;
  }

  .gap-sm-17 {
    gap: 4.25rem !important;
  }

  .gap-sm-18 {
    gap: 4.5rem !important;
  }

  .gap-sm-19 {
    gap: 4.75rem !important;
  }

  .gap-sm-20 {
    gap: 5rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-sm-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-sm-8 {
    row-gap: 2rem !important;
  }

  .row-gap-sm-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-sm-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-sm-11 {
    row-gap: 2.75rem !important;
  }

  .row-gap-sm-12 {
    row-gap: 3rem !important;
  }

  .row-gap-sm-13 {
    row-gap: 3.25rem !important;
  }

  .row-gap-sm-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-sm-15 {
    row-gap: 3.75rem !important;
  }

  .row-gap-sm-16 {
    row-gap: 4rem !important;
  }

  .row-gap-sm-17 {
    row-gap: 4.25rem !important;
  }

  .row-gap-sm-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-sm-19 {
    row-gap: 4.75rem !important;
  }

  .row-gap-sm-20 {
    row-gap: 5rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-sm-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-sm-8 {
    column-gap: 2rem !important;
  }

  .column-gap-sm-9 {
    column-gap: 2.25rem !important;
  }

  .column-gap-sm-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-sm-11 {
    column-gap: 2.75rem !important;
  }

  .column-gap-sm-12 {
    column-gap: 3rem !important;
  }

  .column-gap-sm-13 {
    column-gap: 3.25rem !important;
  }

  .column-gap-sm-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-sm-15 {
    column-gap: 3.75rem !important;
  }

  .column-gap-sm-16 {
    column-gap: 4rem !important;
  }

  .column-gap-sm-17 {
    column-gap: 4.25rem !important;
  }

  .column-gap-sm-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-sm-19 {
    column-gap: 4.75rem !important;
  }

  .column-gap-sm-20 {
    column-gap: 5rem !important;
  }

  .fs-sm-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-sm-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-sm-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-sm-4 {
    font-size: 1.25rem !important;
  }

  .fs-sm-5 {
    font-size: 1.15rem !important;
  }

  .fs-sm-6 {
    font-size: 1.075rem !important;
  }

  .fs-sm-7 {
    font-size: 0.95rem !important;
  }

  .fs-sm-8 {
    font-size: 0.85rem !important;
  }

  .fs-sm-9 {
    font-size: 0.75rem !important;
  }

  .fs-sm-10 {
    font-size: 0.5rem !important;
  }

  .fs-sm-base {
    font-size: 1rem !important;
  }

  .fs-sm-fluid {
    font-size: 100% !important;
  }

  .fs-sm-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-sm-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-sm-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-sm-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-sm-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-sm-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-sm-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-sm-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-sm-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-sm-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-sm-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-sm-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-sm-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-sm-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-sm-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-sm-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }

  .fs-sm-10x {
    font-size: calc(2.125rem + 10.5vw) !important;
  }

  .fs-sm-10qx {
    font-size: calc(2.15rem + 10.8vw) !important;
  }

  .fs-sm-10hx {
    font-size: calc(2.175rem + 11.1vw) !important;
  }

  .fs-sm-10tx {
    font-size: calc(2.2rem + 11.4vw) !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .min-w-sm-unset {
    min-width: unset !important;
  }

  .min-w-sm-25 {
    min-width: 25% !important;
  }

  .min-w-sm-50 {
    min-width: 50% !important;
  }

  .min-w-sm-75 {
    min-width: 75% !important;
  }

  .min-w-sm-100 {
    min-width: 100% !important;
  }

  .min-w-sm-auto {
    min-width: auto !important;
  }

  .min-w-sm-1px {
    min-width: 1px !important;
  }

  .min-w-sm-2px {
    min-width: 2px !important;
  }

  .min-w-sm-3px {
    min-width: 3px !important;
  }

  .min-w-sm-4px {
    min-width: 4px !important;
  }

  .min-w-sm-5px {
    min-width: 5px !important;
  }

  .min-w-sm-6px {
    min-width: 6px !important;
  }

  .min-w-sm-7px {
    min-width: 7px !important;
  }

  .min-w-sm-8px {
    min-width: 8px !important;
  }

  .min-w-sm-9px {
    min-width: 9px !important;
  }

  .min-w-sm-10px {
    min-width: 10px !important;
  }

  .min-w-sm-15px {
    min-width: 15px !important;
  }

  .min-w-sm-20px {
    min-width: 20px !important;
  }

  .min-w-sm-25px {
    min-width: 25px !important;
  }

  .min-w-sm-30px {
    min-width: 30px !important;
  }

  .min-w-sm-35px {
    min-width: 35px !important;
  }

  .min-w-sm-40px {
    min-width: 40px !important;
  }

  .min-w-sm-45px {
    min-width: 45px !important;
  }

  .min-w-sm-50px {
    min-width: 50px !important;
  }

  .min-w-sm-55px {
    min-width: 55px !important;
  }

  .min-w-sm-60px {
    min-width: 60px !important;
  }

  .min-w-sm-65px {
    min-width: 65px !important;
  }

  .min-w-sm-70px {
    min-width: 70px !important;
  }

  .min-w-sm-75px {
    min-width: 75px !important;
  }

  .min-w-sm-80px {
    min-width: 80px !important;
  }

  .min-w-sm-85px {
    min-width: 85px !important;
  }

  .min-w-sm-90px {
    min-width: 90px !important;
  }

  .min-w-sm-95px {
    min-width: 95px !important;
  }

  .min-w-sm-100px {
    min-width: 100px !important;
  }

  .min-w-sm-125px {
    min-width: 125px !important;
  }

  .min-w-sm-150px {
    min-width: 150px !important;
  }

  .min-w-sm-175px {
    min-width: 175px !important;
  }

  .min-w-sm-200px {
    min-width: 200px !important;
  }

  .min-w-sm-225px {
    min-width: 225px !important;
  }

  .min-w-sm-250px {
    min-width: 250px !important;
  }

  .min-w-sm-275px {
    min-width: 275px !important;
  }

  .min-w-sm-300px {
    min-width: 300px !important;
  }

  .min-w-sm-325px {
    min-width: 325px !important;
  }

  .min-w-sm-350px {
    min-width: 350px !important;
  }

  .min-w-sm-375px {
    min-width: 375px !important;
  }

  .min-w-sm-400px {
    min-width: 400px !important;
  }

  .min-w-sm-425px {
    min-width: 425px !important;
  }

  .min-w-sm-450px {
    min-width: 450px !important;
  }

  .min-w-sm-475px {
    min-width: 475px !important;
  }

  .min-w-sm-500px {
    min-width: 500px !important;
  }

  .min-w-sm-550px {
    min-width: 550px !important;
  }

  .min-w-sm-600px {
    min-width: 600px !important;
  }

  .min-w-sm-650px {
    min-width: 650px !important;
  }

  .min-w-sm-700px {
    min-width: 700px !important;
  }

  .min-w-sm-750px {
    min-width: 750px !important;
  }

  .min-w-sm-800px {
    min-width: 800px !important;
  }

  .min-w-sm-850px {
    min-width: 850px !important;
  }

  .min-w-sm-900px {
    min-width: 900px !important;
  }

  .min-w-sm-950px {
    min-width: 950px !important;
  }

  .min-w-sm-1000px {
    min-width: 1000px !important;
  }

  .min-h-sm-unset {
    min-height: unset !important;
  }

  .min-h-sm-25 {
    min-height: 25% !important;
  }

  .min-h-sm-50 {
    min-height: 50% !important;
  }

  .min-h-sm-75 {
    min-height: 75% !important;
  }

  .min-h-sm-100 {
    min-height: 100% !important;
  }

  .min-h-sm-auto {
    min-height: auto !important;
  }

  .min-h-sm-1px {
    min-height: 1px !important;
  }

  .min-h-sm-2px {
    min-height: 2px !important;
  }

  .min-h-sm-3px {
    min-height: 3px !important;
  }

  .min-h-sm-4px {
    min-height: 4px !important;
  }

  .min-h-sm-5px {
    min-height: 5px !important;
  }

  .min-h-sm-6px {
    min-height: 6px !important;
  }

  .min-h-sm-7px {
    min-height: 7px !important;
  }

  .min-h-sm-8px {
    min-height: 8px !important;
  }

  .min-h-sm-9px {
    min-height: 9px !important;
  }

  .min-h-sm-10px {
    min-height: 10px !important;
  }

  .min-h-sm-15px {
    min-height: 15px !important;
  }

  .min-h-sm-20px {
    min-height: 20px !important;
  }

  .min-h-sm-25px {
    min-height: 25px !important;
  }

  .min-h-sm-30px {
    min-height: 30px !important;
  }

  .min-h-sm-35px {
    min-height: 35px !important;
  }

  .min-h-sm-40px {
    min-height: 40px !important;
  }

  .min-h-sm-45px {
    min-height: 45px !important;
  }

  .min-h-sm-50px {
    min-height: 50px !important;
  }

  .min-h-sm-55px {
    min-height: 55px !important;
  }

  .min-h-sm-60px {
    min-height: 60px !important;
  }

  .min-h-sm-65px {
    min-height: 65px !important;
  }

  .min-h-sm-70px {
    min-height: 70px !important;
  }

  .min-h-sm-75px {
    min-height: 75px !important;
  }

  .min-h-sm-80px {
    min-height: 80px !important;
  }

  .min-h-sm-85px {
    min-height: 85px !important;
  }

  .min-h-sm-90px {
    min-height: 90px !important;
  }

  .min-h-sm-95px {
    min-height: 95px !important;
  }

  .min-h-sm-100px {
    min-height: 100px !important;
  }

  .min-h-sm-125px {
    min-height: 125px !important;
  }

  .min-h-sm-150px {
    min-height: 150px !important;
  }

  .min-h-sm-175px {
    min-height: 175px !important;
  }

  .min-h-sm-200px {
    min-height: 200px !important;
  }

  .min-h-sm-225px {
    min-height: 225px !important;
  }

  .min-h-sm-250px {
    min-height: 250px !important;
  }

  .min-h-sm-275px {
    min-height: 275px !important;
  }

  .min-h-sm-300px {
    min-height: 300px !important;
  }

  .min-h-sm-325px {
    min-height: 325px !important;
  }

  .min-h-sm-350px {
    min-height: 350px !important;
  }

  .min-h-sm-375px {
    min-height: 375px !important;
  }

  .min-h-sm-400px {
    min-height: 400px !important;
  }

  .min-h-sm-425px {
    min-height: 425px !important;
  }

  .min-h-sm-450px {
    min-height: 450px !important;
  }

  .min-h-sm-475px {
    min-height: 475px !important;
  }

  .min-h-sm-500px {
    min-height: 500px !important;
  }

  .min-h-sm-550px {
    min-height: 550px !important;
  }

  .min-h-sm-600px {
    min-height: 600px !important;
  }

  .min-h-sm-650px {
    min-height: 650px !important;
  }

  .min-h-sm-700px {
    min-height: 700px !important;
  }

  .min-h-sm-750px {
    min-height: 750px !important;
  }

  .min-h-sm-800px {
    min-height: 800px !important;
  }

  .min-h-sm-850px {
    min-height: 850px !important;
  }

  .min-h-sm-900px {
    min-height: 900px !important;
  }

  .min-h-sm-950px {
    min-height: 950px !important;
  }

  .min-h-sm-1000px {
    min-height: 1000px !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .w-md-unset {
    width: unset !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-md-auto {
    width: auto !important;
  }

  .w-md-1px {
    width: 1px !important;
  }

  .w-md-2px {
    width: 2px !important;
  }

  .w-md-3px {
    width: 3px !important;
  }

  .w-md-4px {
    width: 4px !important;
  }

  .w-md-5px {
    width: 5px !important;
  }

  .w-md-6px {
    width: 6px !important;
  }

  .w-md-7px {
    width: 7px !important;
  }

  .w-md-8px {
    width: 8px !important;
  }

  .w-md-9px {
    width: 9px !important;
  }

  .w-md-10px {
    width: 10px !important;
  }

  .w-md-15px {
    width: 15px !important;
  }

  .w-md-20px {
    width: 20px !important;
  }

  .w-md-25px {
    width: 25px !important;
  }

  .w-md-30px {
    width: 30px !important;
  }

  .w-md-35px {
    width: 35px !important;
  }

  .w-md-40px {
    width: 40px !important;
  }

  .w-md-45px {
    width: 45px !important;
  }

  .w-md-50px {
    width: 50px !important;
  }

  .w-md-55px {
    width: 55px !important;
  }

  .w-md-60px {
    width: 60px !important;
  }

  .w-md-65px {
    width: 65px !important;
  }

  .w-md-70px {
    width: 70px !important;
  }

  .w-md-75px {
    width: 75px !important;
  }

  .w-md-80px {
    width: 80px !important;
  }

  .w-md-85px {
    width: 85px !important;
  }

  .w-md-90px {
    width: 90px !important;
  }

  .w-md-95px {
    width: 95px !important;
  }

  .w-md-100px {
    width: 100px !important;
  }

  .w-md-125px {
    width: 125px !important;
  }

  .w-md-150px {
    width: 150px !important;
  }

  .w-md-175px {
    width: 175px !important;
  }

  .w-md-200px {
    width: 200px !important;
  }

  .w-md-225px {
    width: 225px !important;
  }

  .w-md-250px {
    width: 250px !important;
  }

  .w-md-275px {
    width: 275px !important;
  }

  .w-md-300px {
    width: 300px !important;
  }

  .w-md-325px {
    width: 325px !important;
  }

  .w-md-350px {
    width: 350px !important;
  }

  .w-md-375px {
    width: 375px !important;
  }

  .w-md-400px {
    width: 400px !important;
  }

  .w-md-425px {
    width: 425px !important;
  }

  .w-md-450px {
    width: 450px !important;
  }

  .w-md-475px {
    width: 475px !important;
  }

  .w-md-500px {
    width: 500px !important;
  }

  .w-md-550px {
    width: 550px !important;
  }

  .w-md-600px {
    width: 600px !important;
  }

  .w-md-650px {
    width: 650px !important;
  }

  .w-md-700px {
    width: 700px !important;
  }

  .w-md-750px {
    width: 750px !important;
  }

  .w-md-800px {
    width: 800px !important;
  }

  .w-md-850px {
    width: 850px !important;
  }

  .w-md-900px {
    width: 900px !important;
  }

  .w-md-950px {
    width: 950px !important;
  }

  .w-md-1000px {
    width: 1000px !important;
  }

  .mw-md-unset {
    max-width: unset !important;
  }

  .mw-md-25 {
    max-width: 25% !important;
  }

  .mw-md-50 {
    max-width: 50% !important;
  }

  .mw-md-75 {
    max-width: 75% !important;
  }

  .mw-md-100 {
    max-width: 100% !important;
  }

  .mw-md-auto {
    max-width: auto !important;
  }

  .mw-md-1px {
    max-width: 1px !important;
  }

  .mw-md-2px {
    max-width: 2px !important;
  }

  .mw-md-3px {
    max-width: 3px !important;
  }

  .mw-md-4px {
    max-width: 4px !important;
  }

  .mw-md-5px {
    max-width: 5px !important;
  }

  .mw-md-6px {
    max-width: 6px !important;
  }

  .mw-md-7px {
    max-width: 7px !important;
  }

  .mw-md-8px {
    max-width: 8px !important;
  }

  .mw-md-9px {
    max-width: 9px !important;
  }

  .mw-md-10px {
    max-width: 10px !important;
  }

  .mw-md-15px {
    max-width: 15px !important;
  }

  .mw-md-20px {
    max-width: 20px !important;
  }

  .mw-md-25px {
    max-width: 25px !important;
  }

  .mw-md-30px {
    max-width: 30px !important;
  }

  .mw-md-35px {
    max-width: 35px !important;
  }

  .mw-md-40px {
    max-width: 40px !important;
  }

  .mw-md-45px {
    max-width: 45px !important;
  }

  .mw-md-50px {
    max-width: 50px !important;
  }

  .mw-md-55px {
    max-width: 55px !important;
  }

  .mw-md-60px {
    max-width: 60px !important;
  }

  .mw-md-65px {
    max-width: 65px !important;
  }

  .mw-md-70px {
    max-width: 70px !important;
  }

  .mw-md-75px {
    max-width: 75px !important;
  }

  .mw-md-80px {
    max-width: 80px !important;
  }

  .mw-md-85px {
    max-width: 85px !important;
  }

  .mw-md-90px {
    max-width: 90px !important;
  }

  .mw-md-95px {
    max-width: 95px !important;
  }

  .mw-md-100px {
    max-width: 100px !important;
  }

  .mw-md-125px {
    max-width: 125px !important;
  }

  .mw-md-150px {
    max-width: 150px !important;
  }

  .mw-md-175px {
    max-width: 175px !important;
  }

  .mw-md-200px {
    max-width: 200px !important;
  }

  .mw-md-225px {
    max-width: 225px !important;
  }

  .mw-md-250px {
    max-width: 250px !important;
  }

  .mw-md-275px {
    max-width: 275px !important;
  }

  .mw-md-300px {
    max-width: 300px !important;
  }

  .mw-md-325px {
    max-width: 325px !important;
  }

  .mw-md-350px {
    max-width: 350px !important;
  }

  .mw-md-375px {
    max-width: 375px !important;
  }

  .mw-md-400px {
    max-width: 400px !important;
  }

  .mw-md-425px {
    max-width: 425px !important;
  }

  .mw-md-450px {
    max-width: 450px !important;
  }

  .mw-md-475px {
    max-width: 475px !important;
  }

  .mw-md-500px {
    max-width: 500px !important;
  }

  .mw-md-550px {
    max-width: 550px !important;
  }

  .mw-md-600px {
    max-width: 600px !important;
  }

  .mw-md-650px {
    max-width: 650px !important;
  }

  .mw-md-700px {
    max-width: 700px !important;
  }

  .mw-md-750px {
    max-width: 750px !important;
  }

  .mw-md-800px {
    max-width: 800px !important;
  }

  .mw-md-850px {
    max-width: 850px !important;
  }

  .mw-md-900px {
    max-width: 900px !important;
  }

  .mw-md-950px {
    max-width: 950px !important;
  }

  .mw-md-1000px {
    max-width: 1000px !important;
  }

  .h-md-unset {
    height: unset !important;
  }

  .h-md-25 {
    height: 25% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .h-md-auto {
    height: auto !important;
  }

  .h-md-1px {
    height: 1px !important;
  }

  .h-md-2px {
    height: 2px !important;
  }

  .h-md-3px {
    height: 3px !important;
  }

  .h-md-4px {
    height: 4px !important;
  }

  .h-md-5px {
    height: 5px !important;
  }

  .h-md-6px {
    height: 6px !important;
  }

  .h-md-7px {
    height: 7px !important;
  }

  .h-md-8px {
    height: 8px !important;
  }

  .h-md-9px {
    height: 9px !important;
  }

  .h-md-10px {
    height: 10px !important;
  }

  .h-md-15px {
    height: 15px !important;
  }

  .h-md-20px {
    height: 20px !important;
  }

  .h-md-25px {
    height: 25px !important;
  }

  .h-md-30px {
    height: 30px !important;
  }

  .h-md-35px {
    height: 35px !important;
  }

  .h-md-40px {
    height: 40px !important;
  }

  .h-md-45px {
    height: 45px !important;
  }

  .h-md-50px {
    height: 50px !important;
  }

  .h-md-55px {
    height: 55px !important;
  }

  .h-md-60px {
    height: 60px !important;
  }

  .h-md-65px {
    height: 65px !important;
  }

  .h-md-70px {
    height: 70px !important;
  }

  .h-md-75px {
    height: 75px !important;
  }

  .h-md-80px {
    height: 80px !important;
  }

  .h-md-85px {
    height: 85px !important;
  }

  .h-md-90px {
    height: 90px !important;
  }

  .h-md-95px {
    height: 95px !important;
  }

  .h-md-100px {
    height: 100px !important;
  }

  .h-md-125px {
    height: 125px !important;
  }

  .h-md-150px {
    height: 150px !important;
  }

  .h-md-175px {
    height: 175px !important;
  }

  .h-md-200px {
    height: 200px !important;
  }

  .h-md-225px {
    height: 225px !important;
  }

  .h-md-250px {
    height: 250px !important;
  }

  .h-md-275px {
    height: 275px !important;
  }

  .h-md-300px {
    height: 300px !important;
  }

  .h-md-325px {
    height: 325px !important;
  }

  .h-md-350px {
    height: 350px !important;
  }

  .h-md-375px {
    height: 375px !important;
  }

  .h-md-400px {
    height: 400px !important;
  }

  .h-md-425px {
    height: 425px !important;
  }

  .h-md-450px {
    height: 450px !important;
  }

  .h-md-475px {
    height: 475px !important;
  }

  .h-md-500px {
    height: 500px !important;
  }

  .h-md-550px {
    height: 550px !important;
  }

  .h-md-600px {
    height: 600px !important;
  }

  .h-md-650px {
    height: 650px !important;
  }

  .h-md-700px {
    height: 700px !important;
  }

  .h-md-750px {
    height: 750px !important;
  }

  .h-md-800px {
    height: 800px !important;
  }

  .h-md-850px {
    height: 850px !important;
  }

  .h-md-900px {
    height: 900px !important;
  }

  .h-md-950px {
    height: 950px !important;
  }

  .h-md-1000px {
    height: 1000px !important;
  }

  .mh-md-unset {
    max-height: unset !important;
  }

  .mh-md-25 {
    max-height: 25% !important;
  }

  .mh-md-50 {
    max-height: 50% !important;
  }

  .mh-md-75 {
    max-height: 75% !important;
  }

  .mh-md-100 {
    max-height: 100% !important;
  }

  .mh-md-auto {
    max-height: auto !important;
  }

  .mh-md-1px {
    max-height: 1px !important;
  }

  .mh-md-2px {
    max-height: 2px !important;
  }

  .mh-md-3px {
    max-height: 3px !important;
  }

  .mh-md-4px {
    max-height: 4px !important;
  }

  .mh-md-5px {
    max-height: 5px !important;
  }

  .mh-md-6px {
    max-height: 6px !important;
  }

  .mh-md-7px {
    max-height: 7px !important;
  }

  .mh-md-8px {
    max-height: 8px !important;
  }

  .mh-md-9px {
    max-height: 9px !important;
  }

  .mh-md-10px {
    max-height: 10px !important;
  }

  .mh-md-15px {
    max-height: 15px !important;
  }

  .mh-md-20px {
    max-height: 20px !important;
  }

  .mh-md-25px {
    max-height: 25px !important;
  }

  .mh-md-30px {
    max-height: 30px !important;
  }

  .mh-md-35px {
    max-height: 35px !important;
  }

  .mh-md-40px {
    max-height: 40px !important;
  }

  .mh-md-45px {
    max-height: 45px !important;
  }

  .mh-md-50px {
    max-height: 50px !important;
  }

  .mh-md-55px {
    max-height: 55px !important;
  }

  .mh-md-60px {
    max-height: 60px !important;
  }

  .mh-md-65px {
    max-height: 65px !important;
  }

  .mh-md-70px {
    max-height: 70px !important;
  }

  .mh-md-75px {
    max-height: 75px !important;
  }

  .mh-md-80px {
    max-height: 80px !important;
  }

  .mh-md-85px {
    max-height: 85px !important;
  }

  .mh-md-90px {
    max-height: 90px !important;
  }

  .mh-md-95px {
    max-height: 95px !important;
  }

  .mh-md-100px {
    max-height: 100px !important;
  }

  .mh-md-125px {
    max-height: 125px !important;
  }

  .mh-md-150px {
    max-height: 150px !important;
  }

  .mh-md-175px {
    max-height: 175px !important;
  }

  .mh-md-200px {
    max-height: 200px !important;
  }

  .mh-md-225px {
    max-height: 225px !important;
  }

  .mh-md-250px {
    max-height: 250px !important;
  }

  .mh-md-275px {
    max-height: 275px !important;
  }

  .mh-md-300px {
    max-height: 300px !important;
  }

  .mh-md-325px {
    max-height: 325px !important;
  }

  .mh-md-350px {
    max-height: 350px !important;
  }

  .mh-md-375px {
    max-height: 375px !important;
  }

  .mh-md-400px {
    max-height: 400px !important;
  }

  .mh-md-425px {
    max-height: 425px !important;
  }

  .mh-md-450px {
    max-height: 450px !important;
  }

  .mh-md-475px {
    max-height: 475px !important;
  }

  .mh-md-500px {
    max-height: 500px !important;
  }

  .mh-md-550px {
    max-height: 550px !important;
  }

  .mh-md-600px {
    max-height: 600px !important;
  }

  .mh-md-650px {
    max-height: 650px !important;
  }

  .mh-md-700px {
    max-height: 700px !important;
  }

  .mh-md-750px {
    max-height: 750px !important;
  }

  .mh-md-800px {
    max-height: 800px !important;
  }

  .mh-md-850px {
    max-height: 850px !important;
  }

  .mh-md-900px {
    max-height: 900px !important;
  }

  .mh-md-950px {
    max-height: 950px !important;
  }

  .mh-md-1000px {
    max-height: 1000px !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 0.75rem !important;
  }

  .m-md-4 {
    margin: 1rem !important;
  }

  .m-md-5 {
    margin: 1.25rem !important;
  }

  .m-md-6 {
    margin: 1.5rem !important;
  }

  .m-md-7 {
    margin: 1.75rem !important;
  }

  .m-md-8 {
    margin: 2rem !important;
  }

  .m-md-9 {
    margin: 2.25rem !important;
  }

  .m-md-10 {
    margin: 2.5rem !important;
  }

  .m-md-11 {
    margin: 2.75rem !important;
  }

  .m-md-12 {
    margin: 3rem !important;
  }

  .m-md-13 {
    margin: 3.25rem !important;
  }

  .m-md-14 {
    margin: 3.5rem !important;
  }

  .m-md-15 {
    margin: 3.75rem !important;
  }

  .m-md-16 {
    margin: 4rem !important;
  }

  .m-md-17 {
    margin: 4.25rem !important;
  }

  .m-md-18 {
    margin: 4.5rem !important;
  }

  .m-md-19 {
    margin: 4.75rem !important;
  }

  .m-md-20 {
    margin: 5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-md-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-md-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-md-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-md-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-md-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-md-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-md-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-md-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-md-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-md-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-md-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-md-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-md-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-md-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-md-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-md-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-md-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-md-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-md-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-md-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-md-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 0.75rem !important;
  }

  .mt-md-4 {
    margin-top: 1rem !important;
  }

  .mt-md-5 {
    margin-top: 1.25rem !important;
  }

  .mt-md-6 {
    margin-top: 1.5rem !important;
  }

  .mt-md-7 {
    margin-top: 1.75rem !important;
  }

  .mt-md-8 {
    margin-top: 2rem !important;
  }

  .mt-md-9 {
    margin-top: 2.25rem !important;
  }

  .mt-md-10 {
    margin-top: 2.5rem !important;
  }

  .mt-md-11 {
    margin-top: 2.75rem !important;
  }

  .mt-md-12 {
    margin-top: 3rem !important;
  }

  .mt-md-13 {
    margin-top: 3.25rem !important;
  }

  .mt-md-14 {
    margin-top: 3.5rem !important;
  }

  .mt-md-15 {
    margin-top: 3.75rem !important;
  }

  .mt-md-16 {
    margin-top: 4rem !important;
  }

  .mt-md-17 {
    margin-top: 4.25rem !important;
  }

  .mt-md-18 {
    margin-top: 4.5rem !important;
  }

  .mt-md-19 {
    margin-top: 4.75rem !important;
  }

  .mt-md-20 {
    margin-top: 5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 0.75rem !important;
  }

  .me-md-4 {
    margin-right: 1rem !important;
  }

  .me-md-5 {
    margin-right: 1.25rem !important;
  }

  .me-md-6 {
    margin-right: 1.5rem !important;
  }

  .me-md-7 {
    margin-right: 1.75rem !important;
  }

  .me-md-8 {
    margin-right: 2rem !important;
  }

  .me-md-9 {
    margin-right: 2.25rem !important;
  }

  .me-md-10 {
    margin-right: 2.5rem !important;
  }

  .me-md-11 {
    margin-right: 2.75rem !important;
  }

  .me-md-12 {
    margin-right: 3rem !important;
  }

  .me-md-13 {
    margin-right: 3.25rem !important;
  }

  .me-md-14 {
    margin-right: 3.5rem !important;
  }

  .me-md-15 {
    margin-right: 3.75rem !important;
  }

  .me-md-16 {
    margin-right: 4rem !important;
  }

  .me-md-17 {
    margin-right: 4.25rem !important;
  }

  .me-md-18 {
    margin-right: 4.5rem !important;
  }

  .me-md-19 {
    margin-right: 4.75rem !important;
  }

  .me-md-20 {
    margin-right: 5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1rem !important;
  }

  .mb-md-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-md-8 {
    margin-bottom: 2rem !important;
  }

  .mb-md-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-md-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-md-12 {
    margin-bottom: 3rem !important;
  }

  .mb-md-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-md-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-md-16 {
    margin-bottom: 4rem !important;
  }

  .mb-md-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-md-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-md-20 {
    margin-bottom: 5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 0.75rem !important;
  }

  .ms-md-4 {
    margin-left: 1rem !important;
  }

  .ms-md-5 {
    margin-left: 1.25rem !important;
  }

  .ms-md-6 {
    margin-left: 1.5rem !important;
  }

  .ms-md-7 {
    margin-left: 1.75rem !important;
  }

  .ms-md-8 {
    margin-left: 2rem !important;
  }

  .ms-md-9 {
    margin-left: 2.25rem !important;
  }

  .ms-md-10 {
    margin-left: 2.5rem !important;
  }

  .ms-md-11 {
    margin-left: 2.75rem !important;
  }

  .ms-md-12 {
    margin-left: 3rem !important;
  }

  .ms-md-13 {
    margin-left: 3.25rem !important;
  }

  .ms-md-14 {
    margin-left: 3.5rem !important;
  }

  .ms-md-15 {
    margin-left: 3.75rem !important;
  }

  .ms-md-16 {
    margin-left: 4rem !important;
  }

  .ms-md-17 {
    margin-left: 4.25rem !important;
  }

  .ms-md-18 {
    margin-left: 4.5rem !important;
  }

  .ms-md-19 {
    margin-left: 4.75rem !important;
  }

  .ms-md-20 {
    margin-left: 5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -0.75rem !important;
  }

  .m-md-n4 {
    margin: -1rem !important;
  }

  .m-md-n5 {
    margin: -1.25rem !important;
  }

  .m-md-n6 {
    margin: -1.5rem !important;
  }

  .m-md-n7 {
    margin: -1.75rem !important;
  }

  .m-md-n8 {
    margin: -2rem !important;
  }

  .m-md-n9 {
    margin: -2.25rem !important;
  }

  .m-md-n10 {
    margin: -2.5rem !important;
  }

  .m-md-n11 {
    margin: -2.75rem !important;
  }

  .m-md-n12 {
    margin: -3rem !important;
  }

  .m-md-n13 {
    margin: -3.25rem !important;
  }

  .m-md-n14 {
    margin: -3.5rem !important;
  }

  .m-md-n15 {
    margin: -3.75rem !important;
  }

  .m-md-n16 {
    margin: -4rem !important;
  }

  .m-md-n17 {
    margin: -4.25rem !important;
  }

  .m-md-n18 {
    margin: -4.5rem !important;
  }

  .m-md-n19 {
    margin: -4.75rem !important;
  }

  .m-md-n20 {
    margin: -5rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-md-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-md-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-md-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-md-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-md-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-md-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-md-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-md-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-md-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-md-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-md-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-md-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-md-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-md-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-md-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-md-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-md-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-md-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-md-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-md-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-md-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-md-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-md-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-md-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-md-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-md-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-md-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-md-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-md-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-md-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-md-n4 {
    margin-top: -1rem !important;
  }

  .mt-md-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-md-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-md-n8 {
    margin-top: -2rem !important;
  }

  .mt-md-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-md-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-md-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-md-n12 {
    margin-top: -3rem !important;
  }

  .mt-md-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-md-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-md-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-md-n16 {
    margin-top: -4rem !important;
  }

  .mt-md-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-md-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-md-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-md-n20 {
    margin-top: -5rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -0.75rem !important;
  }

  .me-md-n4 {
    margin-right: -1rem !important;
  }

  .me-md-n5 {
    margin-right: -1.25rem !important;
  }

  .me-md-n6 {
    margin-right: -1.5rem !important;
  }

  .me-md-n7 {
    margin-right: -1.75rem !important;
  }

  .me-md-n8 {
    margin-right: -2rem !important;
  }

  .me-md-n9 {
    margin-right: -2.25rem !important;
  }

  .me-md-n10 {
    margin-right: -2.5rem !important;
  }

  .me-md-n11 {
    margin-right: -2.75rem !important;
  }

  .me-md-n12 {
    margin-right: -3rem !important;
  }

  .me-md-n13 {
    margin-right: -3.25rem !important;
  }

  .me-md-n14 {
    margin-right: -3.5rem !important;
  }

  .me-md-n15 {
    margin-right: -3.75rem !important;
  }

  .me-md-n16 {
    margin-right: -4rem !important;
  }

  .me-md-n17 {
    margin-right: -4.25rem !important;
  }

  .me-md-n18 {
    margin-right: -4.5rem !important;
  }

  .me-md-n19 {
    margin-right: -4.75rem !important;
  }

  .me-md-n20 {
    margin-right: -5rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-md-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-md-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-md-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-md-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-md-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-md-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-md-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-md-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-md-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-md-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-md-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-md-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-md-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-md-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-md-n4 {
    margin-left: -1rem !important;
  }

  .ms-md-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-md-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-md-n8 {
    margin-left: -2rem !important;
  }

  .ms-md-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-md-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-md-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-md-n12 {
    margin-left: -3rem !important;
  }

  .ms-md-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-md-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-md-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-md-n16 {
    margin-left: -4rem !important;
  }

  .ms-md-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-md-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-md-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-md-n20 {
    margin-left: -5rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 0.75rem !important;
  }

  .p-md-4 {
    padding: 1rem !important;
  }

  .p-md-5 {
    padding: 1.25rem !important;
  }

  .p-md-6 {
    padding: 1.5rem !important;
  }

  .p-md-7 {
    padding: 1.75rem !important;
  }

  .p-md-8 {
    padding: 2rem !important;
  }

  .p-md-9 {
    padding: 2.25rem !important;
  }

  .p-md-10 {
    padding: 2.5rem !important;
  }

  .p-md-11 {
    padding: 2.75rem !important;
  }

  .p-md-12 {
    padding: 3rem !important;
  }

  .p-md-13 {
    padding: 3.25rem !important;
  }

  .p-md-14 {
    padding: 3.5rem !important;
  }

  .p-md-15 {
    padding: 3.75rem !important;
  }

  .p-md-16 {
    padding: 4rem !important;
  }

  .p-md-17 {
    padding: 4.25rem !important;
  }

  .p-md-18 {
    padding: 4.5rem !important;
  }

  .p-md-19 {
    padding: 4.75rem !important;
  }

  .p-md-20 {
    padding: 5rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-md-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-md-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-md-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-md-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-md-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-md-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-md-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-md-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-md-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-md-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-md-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-md-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-md-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-md-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-md-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-md-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-md-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-md-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-md-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-md-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-md-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-md-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-md-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 0.75rem !important;
  }

  .pt-md-4 {
    padding-top: 1rem !important;
  }

  .pt-md-5 {
    padding-top: 1.25rem !important;
  }

  .pt-md-6 {
    padding-top: 1.5rem !important;
  }

  .pt-md-7 {
    padding-top: 1.75rem !important;
  }

  .pt-md-8 {
    padding-top: 2rem !important;
  }

  .pt-md-9 {
    padding-top: 2.25rem !important;
  }

  .pt-md-10 {
    padding-top: 2.5rem !important;
  }

  .pt-md-11 {
    padding-top: 2.75rem !important;
  }

  .pt-md-12 {
    padding-top: 3rem !important;
  }

  .pt-md-13 {
    padding-top: 3.25rem !important;
  }

  .pt-md-14 {
    padding-top: 3.5rem !important;
  }

  .pt-md-15 {
    padding-top: 3.75rem !important;
  }

  .pt-md-16 {
    padding-top: 4rem !important;
  }

  .pt-md-17 {
    padding-top: 4.25rem !important;
  }

  .pt-md-18 {
    padding-top: 4.5rem !important;
  }

  .pt-md-19 {
    padding-top: 4.75rem !important;
  }

  .pt-md-20 {
    padding-top: 5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 0.75rem !important;
  }

  .pe-md-4 {
    padding-right: 1rem !important;
  }

  .pe-md-5 {
    padding-right: 1.25rem !important;
  }

  .pe-md-6 {
    padding-right: 1.5rem !important;
  }

  .pe-md-7 {
    padding-right: 1.75rem !important;
  }

  .pe-md-8 {
    padding-right: 2rem !important;
  }

  .pe-md-9 {
    padding-right: 2.25rem !important;
  }

  .pe-md-10 {
    padding-right: 2.5rem !important;
  }

  .pe-md-11 {
    padding-right: 2.75rem !important;
  }

  .pe-md-12 {
    padding-right: 3rem !important;
  }

  .pe-md-13 {
    padding-right: 3.25rem !important;
  }

  .pe-md-14 {
    padding-right: 3.5rem !important;
  }

  .pe-md-15 {
    padding-right: 3.75rem !important;
  }

  .pe-md-16 {
    padding-right: 4rem !important;
  }

  .pe-md-17 {
    padding-right: 4.25rem !important;
  }

  .pe-md-18 {
    padding-right: 4.5rem !important;
  }

  .pe-md-19 {
    padding-right: 4.75rem !important;
  }

  .pe-md-20 {
    padding-right: 5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1rem !important;
  }

  .pb-md-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-md-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-md-8 {
    padding-bottom: 2rem !important;
  }

  .pb-md-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-md-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-md-12 {
    padding-bottom: 3rem !important;
  }

  .pb-md-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-md-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-md-16 {
    padding-bottom: 4rem !important;
  }

  .pb-md-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-md-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-md-20 {
    padding-bottom: 5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 0.75rem !important;
  }

  .ps-md-4 {
    padding-left: 1rem !important;
  }

  .ps-md-5 {
    padding-left: 1.25rem !important;
  }

  .ps-md-6 {
    padding-left: 1.5rem !important;
  }

  .ps-md-7 {
    padding-left: 1.75rem !important;
  }

  .ps-md-8 {
    padding-left: 2rem !important;
  }

  .ps-md-9 {
    padding-left: 2.25rem !important;
  }

  .ps-md-10 {
    padding-left: 2.5rem !important;
  }

  .ps-md-11 {
    padding-left: 2.75rem !important;
  }

  .ps-md-12 {
    padding-left: 3rem !important;
  }

  .ps-md-13 {
    padding-left: 3.25rem !important;
  }

  .ps-md-14 {
    padding-left: 3.5rem !important;
  }

  .ps-md-15 {
    padding-left: 3.75rem !important;
  }

  .ps-md-16 {
    padding-left: 4rem !important;
  }

  .ps-md-17 {
    padding-left: 4.25rem !important;
  }

  .ps-md-18 {
    padding-left: 4.5rem !important;
  }

  .ps-md-19 {
    padding-left: 4.75rem !important;
  }

  .ps-md-20 {
    padding-left: 5rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 0.75rem !important;
  }

  .gap-md-4 {
    gap: 1rem !important;
  }

  .gap-md-5 {
    gap: 1.25rem !important;
  }

  .gap-md-6 {
    gap: 1.5rem !important;
  }

  .gap-md-7 {
    gap: 1.75rem !important;
  }

  .gap-md-8 {
    gap: 2rem !important;
  }

  .gap-md-9 {
    gap: 2.25rem !important;
  }

  .gap-md-10 {
    gap: 2.5rem !important;
  }

  .gap-md-11 {
    gap: 2.75rem !important;
  }

  .gap-md-12 {
    gap: 3rem !important;
  }

  .gap-md-13 {
    gap: 3.25rem !important;
  }

  .gap-md-14 {
    gap: 3.5rem !important;
  }

  .gap-md-15 {
    gap: 3.75rem !important;
  }

  .gap-md-16 {
    gap: 4rem !important;
  }

  .gap-md-17 {
    gap: 4.25rem !important;
  }

  .gap-md-18 {
    gap: 4.5rem !important;
  }

  .gap-md-19 {
    gap: 4.75rem !important;
  }

  .gap-md-20 {
    gap: 5rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1rem !important;
  }

  .row-gap-md-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-md-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-md-8 {
    row-gap: 2rem !important;
  }

  .row-gap-md-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-md-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-md-11 {
    row-gap: 2.75rem !important;
  }

  .row-gap-md-12 {
    row-gap: 3rem !important;
  }

  .row-gap-md-13 {
    row-gap: 3.25rem !important;
  }

  .row-gap-md-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-md-15 {
    row-gap: 3.75rem !important;
  }

  .row-gap-md-16 {
    row-gap: 4rem !important;
  }

  .row-gap-md-17 {
    row-gap: 4.25rem !important;
  }

  .row-gap-md-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-md-19 {
    row-gap: 4.75rem !important;
  }

  .row-gap-md-20 {
    row-gap: 5rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1rem !important;
  }

  .column-gap-md-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-md-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-md-8 {
    column-gap: 2rem !important;
  }

  .column-gap-md-9 {
    column-gap: 2.25rem !important;
  }

  .column-gap-md-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-md-11 {
    column-gap: 2.75rem !important;
  }

  .column-gap-md-12 {
    column-gap: 3rem !important;
  }

  .column-gap-md-13 {
    column-gap: 3.25rem !important;
  }

  .column-gap-md-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-md-15 {
    column-gap: 3.75rem !important;
  }

  .column-gap-md-16 {
    column-gap: 4rem !important;
  }

  .column-gap-md-17 {
    column-gap: 4.25rem !important;
  }

  .column-gap-md-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-md-19 {
    column-gap: 4.75rem !important;
  }

  .column-gap-md-20 {
    column-gap: 5rem !important;
  }

  .fs-md-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-md-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-md-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-md-4 {
    font-size: 1.25rem !important;
  }

  .fs-md-5 {
    font-size: 1.15rem !important;
  }

  .fs-md-6 {
    font-size: 1.075rem !important;
  }

  .fs-md-7 {
    font-size: 0.95rem !important;
  }

  .fs-md-8 {
    font-size: 0.85rem !important;
  }

  .fs-md-9 {
    font-size: 0.75rem !important;
  }

  .fs-md-10 {
    font-size: 0.5rem !important;
  }

  .fs-md-base {
    font-size: 1rem !important;
  }

  .fs-md-fluid {
    font-size: 100% !important;
  }

  .fs-md-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-md-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-md-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-md-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-md-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-md-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-md-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-md-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-md-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-md-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-md-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-md-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-md-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-md-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-md-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-md-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }

  .fs-md-10x {
    font-size: calc(2.125rem + 10.5vw) !important;
  }

  .fs-md-10qx {
    font-size: calc(2.15rem + 10.8vw) !important;
  }

  .fs-md-10hx {
    font-size: calc(2.175rem + 11.1vw) !important;
  }

  .fs-md-10tx {
    font-size: calc(2.2rem + 11.4vw) !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .min-w-md-unset {
    min-width: unset !important;
  }

  .min-w-md-25 {
    min-width: 25% !important;
  }

  .min-w-md-50 {
    min-width: 50% !important;
  }

  .min-w-md-75 {
    min-width: 75% !important;
  }

  .min-w-md-100 {
    min-width: 100% !important;
  }

  .min-w-md-auto {
    min-width: auto !important;
  }

  .min-w-md-1px {
    min-width: 1px !important;
  }

  .min-w-md-2px {
    min-width: 2px !important;
  }

  .min-w-md-3px {
    min-width: 3px !important;
  }

  .min-w-md-4px {
    min-width: 4px !important;
  }

  .min-w-md-5px {
    min-width: 5px !important;
  }

  .min-w-md-6px {
    min-width: 6px !important;
  }

  .min-w-md-7px {
    min-width: 7px !important;
  }

  .min-w-md-8px {
    min-width: 8px !important;
  }

  .min-w-md-9px {
    min-width: 9px !important;
  }

  .min-w-md-10px {
    min-width: 10px !important;
  }

  .min-w-md-15px {
    min-width: 15px !important;
  }

  .min-w-md-20px {
    min-width: 20px !important;
  }

  .min-w-md-25px {
    min-width: 25px !important;
  }

  .min-w-md-30px {
    min-width: 30px !important;
  }

  .min-w-md-35px {
    min-width: 35px !important;
  }

  .min-w-md-40px {
    min-width: 40px !important;
  }

  .min-w-md-45px {
    min-width: 45px !important;
  }

  .min-w-md-50px {
    min-width: 50px !important;
  }

  .min-w-md-55px {
    min-width: 55px !important;
  }

  .min-w-md-60px {
    min-width: 60px !important;
  }

  .min-w-md-65px {
    min-width: 65px !important;
  }

  .min-w-md-70px {
    min-width: 70px !important;
  }

  .min-w-md-75px {
    min-width: 75px !important;
  }

  .min-w-md-80px {
    min-width: 80px !important;
  }

  .min-w-md-85px {
    min-width: 85px !important;
  }

  .min-w-md-90px {
    min-width: 90px !important;
  }

  .min-w-md-95px {
    min-width: 95px !important;
  }

  .min-w-md-100px {
    min-width: 100px !important;
  }

  .min-w-md-125px {
    min-width: 125px !important;
  }

  .min-w-md-150px {
    min-width: 150px !important;
  }

  .min-w-md-175px {
    min-width: 175px !important;
  }

  .min-w-md-200px {
    min-width: 200px !important;
  }

  .min-w-md-225px {
    min-width: 225px !important;
  }

  .min-w-md-250px {
    min-width: 250px !important;
  }

  .min-w-md-275px {
    min-width: 275px !important;
  }

  .min-w-md-300px {
    min-width: 300px !important;
  }

  .min-w-md-325px {
    min-width: 325px !important;
  }

  .min-w-md-350px {
    min-width: 350px !important;
  }

  .min-w-md-375px {
    min-width: 375px !important;
  }

  .min-w-md-400px {
    min-width: 400px !important;
  }

  .min-w-md-425px {
    min-width: 425px !important;
  }

  .min-w-md-450px {
    min-width: 450px !important;
  }

  .min-w-md-475px {
    min-width: 475px !important;
  }

  .min-w-md-500px {
    min-width: 500px !important;
  }

  .min-w-md-550px {
    min-width: 550px !important;
  }

  .min-w-md-600px {
    min-width: 600px !important;
  }

  .min-w-md-650px {
    min-width: 650px !important;
  }

  .min-w-md-700px {
    min-width: 700px !important;
  }

  .min-w-md-750px {
    min-width: 750px !important;
  }

  .min-w-md-800px {
    min-width: 800px !important;
  }

  .min-w-md-850px {
    min-width: 850px !important;
  }

  .min-w-md-900px {
    min-width: 900px !important;
  }

  .min-w-md-950px {
    min-width: 950px !important;
  }

  .min-w-md-1000px {
    min-width: 1000px !important;
  }

  .min-h-md-unset {
    min-height: unset !important;
  }

  .min-h-md-25 {
    min-height: 25% !important;
  }

  .min-h-md-50 {
    min-height: 50% !important;
  }

  .min-h-md-75 {
    min-height: 75% !important;
  }

  .min-h-md-100 {
    min-height: 100% !important;
  }

  .min-h-md-auto {
    min-height: auto !important;
  }

  .min-h-md-1px {
    min-height: 1px !important;
  }

  .min-h-md-2px {
    min-height: 2px !important;
  }

  .min-h-md-3px {
    min-height: 3px !important;
  }

  .min-h-md-4px {
    min-height: 4px !important;
  }

  .min-h-md-5px {
    min-height: 5px !important;
  }

  .min-h-md-6px {
    min-height: 6px !important;
  }

  .min-h-md-7px {
    min-height: 7px !important;
  }

  .min-h-md-8px {
    min-height: 8px !important;
  }

  .min-h-md-9px {
    min-height: 9px !important;
  }

  .min-h-md-10px {
    min-height: 10px !important;
  }

  .min-h-md-15px {
    min-height: 15px !important;
  }

  .min-h-md-20px {
    min-height: 20px !important;
  }

  .min-h-md-25px {
    min-height: 25px !important;
  }

  .min-h-md-30px {
    min-height: 30px !important;
  }

  .min-h-md-35px {
    min-height: 35px !important;
  }

  .min-h-md-40px {
    min-height: 40px !important;
  }

  .min-h-md-45px {
    min-height: 45px !important;
  }

  .min-h-md-50px {
    min-height: 50px !important;
  }

  .min-h-md-55px {
    min-height: 55px !important;
  }

  .min-h-md-60px {
    min-height: 60px !important;
  }

  .min-h-md-65px {
    min-height: 65px !important;
  }

  .min-h-md-70px {
    min-height: 70px !important;
  }

  .min-h-md-75px {
    min-height: 75px !important;
  }

  .min-h-md-80px {
    min-height: 80px !important;
  }

  .min-h-md-85px {
    min-height: 85px !important;
  }

  .min-h-md-90px {
    min-height: 90px !important;
  }

  .min-h-md-95px {
    min-height: 95px !important;
  }

  .min-h-md-100px {
    min-height: 100px !important;
  }

  .min-h-md-125px {
    min-height: 125px !important;
  }

  .min-h-md-150px {
    min-height: 150px !important;
  }

  .min-h-md-175px {
    min-height: 175px !important;
  }

  .min-h-md-200px {
    min-height: 200px !important;
  }

  .min-h-md-225px {
    min-height: 225px !important;
  }

  .min-h-md-250px {
    min-height: 250px !important;
  }

  .min-h-md-275px {
    min-height: 275px !important;
  }

  .min-h-md-300px {
    min-height: 300px !important;
  }

  .min-h-md-325px {
    min-height: 325px !important;
  }

  .min-h-md-350px {
    min-height: 350px !important;
  }

  .min-h-md-375px {
    min-height: 375px !important;
  }

  .min-h-md-400px {
    min-height: 400px !important;
  }

  .min-h-md-425px {
    min-height: 425px !important;
  }

  .min-h-md-450px {
    min-height: 450px !important;
  }

  .min-h-md-475px {
    min-height: 475px !important;
  }

  .min-h-md-500px {
    min-height: 500px !important;
  }

  .min-h-md-550px {
    min-height: 550px !important;
  }

  .min-h-md-600px {
    min-height: 600px !important;
  }

  .min-h-md-650px {
    min-height: 650px !important;
  }

  .min-h-md-700px {
    min-height: 700px !important;
  }

  .min-h-md-750px {
    min-height: 750px !important;
  }

  .min-h-md-800px {
    min-height: 800px !important;
  }

  .min-h-md-850px {
    min-height: 850px !important;
  }

  .min-h-md-900px {
    min-height: 900px !important;
  }

  .min-h-md-950px {
    min-height: 950px !important;
  }

  .min-h-md-1000px {
    min-height: 1000px !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .w-lg-unset {
    width: unset !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-auto {
    width: auto !important;
  }

  .w-lg-1px {
    width: 1px !important;
  }

  .w-lg-2px {
    width: 2px !important;
  }

  .w-lg-3px {
    width: 3px !important;
  }

  .w-lg-4px {
    width: 4px !important;
  }

  .w-lg-5px {
    width: 5px !important;
  }

  .w-lg-6px {
    width: 6px !important;
  }

  .w-lg-7px {
    width: 7px !important;
  }

  .w-lg-8px {
    width: 8px !important;
  }

  .w-lg-9px {
    width: 9px !important;
  }

  .w-lg-10px {
    width: 10px !important;
  }

  .w-lg-15px {
    width: 15px !important;
  }

  .w-lg-20px {
    width: 20px !important;
  }

  .w-lg-25px {
    width: 25px !important;
  }

  .w-lg-30px {
    width: 30px !important;
  }

  .w-lg-35px {
    width: 35px !important;
  }

  .w-lg-40px {
    width: 40px !important;
  }

  .w-lg-45px {
    width: 45px !important;
  }

  .w-lg-50px {
    width: 50px !important;
  }

  .w-lg-55px {
    width: 55px !important;
  }

  .w-lg-60px {
    width: 60px !important;
  }

  .w-lg-65px {
    width: 65px !important;
  }

  .w-lg-70px {
    width: 70px !important;
  }

  .w-lg-75px {
    width: 75px !important;
  }

  .w-lg-80px {
    width: 80px !important;
  }

  .w-lg-85px {
    width: 85px !important;
  }

  .w-lg-90px {
    width: 90px !important;
  }

  .w-lg-95px {
    width: 95px !important;
  }

  .w-lg-100px {
    width: 100px !important;
  }

  .w-lg-125px {
    width: 125px !important;
  }

  .w-lg-150px {
    width: 150px !important;
  }

  .w-lg-175px {
    width: 175px !important;
  }

  .w-lg-200px {
    width: 200px !important;
  }

  .w-lg-225px {
    width: 225px !important;
  }

  .w-lg-250px {
    width: 250px !important;
  }

  .w-lg-275px {
    width: 275px !important;
  }

  .w-lg-300px {
    width: 300px !important;
  }

  .w-lg-325px {
    width: 325px !important;
  }

  .w-lg-350px {
    width: 350px !important;
  }

  .w-lg-375px {
    width: 375px !important;
  }

  .w-lg-400px {
    width: 400px !important;
  }

  .w-lg-425px {
    width: 425px !important;
  }

  .w-lg-450px {
    width: 450px !important;
  }

  .w-lg-475px {
    width: 475px !important;
  }

  .w-lg-500px {
    width: 500px !important;
  }

  .w-lg-550px {
    width: 550px !important;
  }

  .w-lg-600px {
    width: 600px !important;
  }

  .w-lg-650px {
    width: 650px !important;
  }

  .w-lg-700px {
    width: 700px !important;
  }

  .w-lg-750px {
    width: 750px !important;
  }

  .w-lg-800px {
    width: 800px !important;
  }

  .w-lg-850px {
    width: 850px !important;
  }

  .w-lg-900px {
    width: 900px !important;
  }

  .w-lg-950px {
    width: 950px !important;
  }

  .w-lg-1000px {
    width: 1000px !important;
  }

  .mw-lg-unset {
    max-width: unset !important;
  }

  .mw-lg-25 {
    max-width: 25% !important;
  }

  .mw-lg-50 {
    max-width: 50% !important;
  }

  .mw-lg-75 {
    max-width: 75% !important;
  }

  .mw-lg-100 {
    max-width: 100% !important;
  }

  .mw-lg-auto {
    max-width: auto !important;
  }

  .mw-lg-1px {
    max-width: 1px !important;
  }

  .mw-lg-2px {
    max-width: 2px !important;
  }

  .mw-lg-3px {
    max-width: 3px !important;
  }

  .mw-lg-4px {
    max-width: 4px !important;
  }

  .mw-lg-5px {
    max-width: 5px !important;
  }

  .mw-lg-6px {
    max-width: 6px !important;
  }

  .mw-lg-7px {
    max-width: 7px !important;
  }

  .mw-lg-8px {
    max-width: 8px !important;
  }

  .mw-lg-9px {
    max-width: 9px !important;
  }

  .mw-lg-10px {
    max-width: 10px !important;
  }

  .mw-lg-15px {
    max-width: 15px !important;
  }

  .mw-lg-20px {
    max-width: 20px !important;
  }

  .mw-lg-25px {
    max-width: 25px !important;
  }

  .mw-lg-30px {
    max-width: 30px !important;
  }

  .mw-lg-35px {
    max-width: 35px !important;
  }

  .mw-lg-40px {
    max-width: 40px !important;
  }

  .mw-lg-45px {
    max-width: 45px !important;
  }

  .mw-lg-50px {
    max-width: 50px !important;
  }

  .mw-lg-55px {
    max-width: 55px !important;
  }

  .mw-lg-60px {
    max-width: 60px !important;
  }

  .mw-lg-65px {
    max-width: 65px !important;
  }

  .mw-lg-70px {
    max-width: 70px !important;
  }

  .mw-lg-75px {
    max-width: 75px !important;
  }

  .mw-lg-80px {
    max-width: 80px !important;
  }

  .mw-lg-85px {
    max-width: 85px !important;
  }

  .mw-lg-90px {
    max-width: 90px !important;
  }

  .mw-lg-95px {
    max-width: 95px !important;
  }

  .mw-lg-100px {
    max-width: 100px !important;
  }

  .mw-lg-125px {
    max-width: 125px !important;
  }

  .mw-lg-150px {
    max-width: 150px !important;
  }

  .mw-lg-175px {
    max-width: 175px !important;
  }

  .mw-lg-200px {
    max-width: 200px !important;
  }

  .mw-lg-225px {
    max-width: 225px !important;
  }

  .mw-lg-250px {
    max-width: 250px !important;
  }

  .mw-lg-275px {
    max-width: 275px !important;
  }

  .mw-lg-300px {
    max-width: 300px !important;
  }

  .mw-lg-325px {
    max-width: 325px !important;
  }

  .mw-lg-350px {
    max-width: 350px !important;
  }

  .mw-lg-375px {
    max-width: 375px !important;
  }

  .mw-lg-400px {
    max-width: 400px !important;
  }

  .mw-lg-425px {
    max-width: 425px !important;
  }

  .mw-lg-450px {
    max-width: 450px !important;
  }

  .mw-lg-475px {
    max-width: 475px !important;
  }

  .mw-lg-500px {
    max-width: 500px !important;
  }

  .mw-lg-550px {
    max-width: 550px !important;
  }

  .mw-lg-600px {
    max-width: 600px !important;
  }

  .mw-lg-650px {
    max-width: 650px !important;
  }

  .mw-lg-700px {
    max-width: 700px !important;
  }

  .mw-lg-750px {
    max-width: 750px !important;
  }

  .mw-lg-800px {
    max-width: 800px !important;
  }

  .mw-lg-850px {
    max-width: 850px !important;
  }

  .mw-lg-900px {
    max-width: 900px !important;
  }

  .mw-lg-950px {
    max-width: 950px !important;
  }

  .mw-lg-1000px {
    max-width: 1000px !important;
  }

  .h-lg-unset {
    height: unset !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .h-lg-auto {
    height: auto !important;
  }

  .h-lg-1px {
    height: 1px !important;
  }

  .h-lg-2px {
    height: 2px !important;
  }

  .h-lg-3px {
    height: 3px !important;
  }

  .h-lg-4px {
    height: 4px !important;
  }

  .h-lg-5px {
    height: 5px !important;
  }

  .h-lg-6px {
    height: 6px !important;
  }

  .h-lg-7px {
    height: 7px !important;
  }

  .h-lg-8px {
    height: 8px !important;
  }

  .h-lg-9px {
    height: 9px !important;
  }

  .h-lg-10px {
    height: 10px !important;
  }

  .h-lg-15px {
    height: 15px !important;
  }

  .h-lg-20px {
    height: 20px !important;
  }

  .h-lg-25px {
    height: 25px !important;
  }

  .h-lg-30px {
    height: 30px !important;
  }

  .h-lg-35px {
    height: 35px !important;
  }

  .h-lg-40px {
    height: 40px !important;
  }

  .h-lg-45px {
    height: 45px !important;
  }

  .h-lg-50px {
    height: 50px !important;
  }

  .h-lg-55px {
    height: 55px !important;
  }

  .h-lg-60px {
    height: 60px !important;
  }

  .h-lg-65px {
    height: 65px !important;
  }

  .h-lg-70px {
    height: 70px !important;
  }

  .h-lg-75px {
    height: 75px !important;
  }

  .h-lg-80px {
    height: 80px !important;
  }

  .h-lg-85px {
    height: 85px !important;
  }

  .h-lg-90px {
    height: 90px !important;
  }

  .h-lg-95px {
    height: 95px !important;
  }

  .h-lg-100px {
    height: 100px !important;
  }

  .h-lg-125px {
    height: 125px !important;
  }

  .h-lg-150px {
    height: 150px !important;
  }

  .h-lg-175px {
    height: 175px !important;
  }

  .h-lg-200px {
    height: 200px !important;
  }

  .h-lg-225px {
    height: 225px !important;
  }

  .h-lg-250px {
    height: 250px !important;
  }

  .h-lg-275px {
    height: 275px !important;
  }

  .h-lg-300px {
    height: 300px !important;
  }

  .h-lg-325px {
    height: 325px !important;
  }

  .h-lg-350px {
    height: 350px !important;
  }

  .h-lg-375px {
    height: 375px !important;
  }

  .h-lg-400px {
    height: 400px !important;
  }

  .h-lg-425px {
    height: 425px !important;
  }

  .h-lg-450px {
    height: 450px !important;
  }

  .h-lg-475px {
    height: 475px !important;
  }

  .h-lg-500px {
    height: 500px !important;
  }

  .h-lg-550px {
    height: 550px !important;
  }

  .h-lg-600px {
    height: 600px !important;
  }

  .h-lg-650px {
    height: 650px !important;
  }

  .h-lg-700px {
    height: 700px !important;
  }

  .h-lg-750px {
    height: 750px !important;
  }

  .h-lg-800px {
    height: 800px !important;
  }

  .h-lg-850px {
    height: 850px !important;
  }

  .h-lg-900px {
    height: 900px !important;
  }

  .h-lg-950px {
    height: 950px !important;
  }

  .h-lg-1000px {
    height: 1000px !important;
  }

  .mh-lg-unset {
    max-height: unset !important;
  }

  .mh-lg-25 {
    max-height: 25% !important;
  }

  .mh-lg-50 {
    max-height: 50% !important;
  }

  .mh-lg-75 {
    max-height: 75% !important;
  }

  .mh-lg-100 {
    max-height: 100% !important;
  }

  .mh-lg-auto {
    max-height: auto !important;
  }

  .mh-lg-1px {
    max-height: 1px !important;
  }

  .mh-lg-2px {
    max-height: 2px !important;
  }

  .mh-lg-3px {
    max-height: 3px !important;
  }

  .mh-lg-4px {
    max-height: 4px !important;
  }

  .mh-lg-5px {
    max-height: 5px !important;
  }

  .mh-lg-6px {
    max-height: 6px !important;
  }

  .mh-lg-7px {
    max-height: 7px !important;
  }

  .mh-lg-8px {
    max-height: 8px !important;
  }

  .mh-lg-9px {
    max-height: 9px !important;
  }

  .mh-lg-10px {
    max-height: 10px !important;
  }

  .mh-lg-15px {
    max-height: 15px !important;
  }

  .mh-lg-20px {
    max-height: 20px !important;
  }

  .mh-lg-25px {
    max-height: 25px !important;
  }

  .mh-lg-30px {
    max-height: 30px !important;
  }

  .mh-lg-35px {
    max-height: 35px !important;
  }

  .mh-lg-40px {
    max-height: 40px !important;
  }

  .mh-lg-45px {
    max-height: 45px !important;
  }

  .mh-lg-50px {
    max-height: 50px !important;
  }

  .mh-lg-55px {
    max-height: 55px !important;
  }

  .mh-lg-60px {
    max-height: 60px !important;
  }

  .mh-lg-65px {
    max-height: 65px !important;
  }

  .mh-lg-70px {
    max-height: 70px !important;
  }

  .mh-lg-75px {
    max-height: 75px !important;
  }

  .mh-lg-80px {
    max-height: 80px !important;
  }

  .mh-lg-85px {
    max-height: 85px !important;
  }

  .mh-lg-90px {
    max-height: 90px !important;
  }

  .mh-lg-95px {
    max-height: 95px !important;
  }

  .mh-lg-100px {
    max-height: 100px !important;
  }

  .mh-lg-125px {
    max-height: 125px !important;
  }

  .mh-lg-150px {
    max-height: 150px !important;
  }

  .mh-lg-175px {
    max-height: 175px !important;
  }

  .mh-lg-200px {
    max-height: 200px !important;
  }

  .mh-lg-225px {
    max-height: 225px !important;
  }

  .mh-lg-250px {
    max-height: 250px !important;
  }

  .mh-lg-275px {
    max-height: 275px !important;
  }

  .mh-lg-300px {
    max-height: 300px !important;
  }

  .mh-lg-325px {
    max-height: 325px !important;
  }

  .mh-lg-350px {
    max-height: 350px !important;
  }

  .mh-lg-375px {
    max-height: 375px !important;
  }

  .mh-lg-400px {
    max-height: 400px !important;
  }

  .mh-lg-425px {
    max-height: 425px !important;
  }

  .mh-lg-450px {
    max-height: 450px !important;
  }

  .mh-lg-475px {
    max-height: 475px !important;
  }

  .mh-lg-500px {
    max-height: 500px !important;
  }

  .mh-lg-550px {
    max-height: 550px !important;
  }

  .mh-lg-600px {
    max-height: 600px !important;
  }

  .mh-lg-650px {
    max-height: 650px !important;
  }

  .mh-lg-700px {
    max-height: 700px !important;
  }

  .mh-lg-750px {
    max-height: 750px !important;
  }

  .mh-lg-800px {
    max-height: 800px !important;
  }

  .mh-lg-850px {
    max-height: 850px !important;
  }

  .mh-lg-900px {
    max-height: 900px !important;
  }

  .mh-lg-950px {
    max-height: 950px !important;
  }

  .mh-lg-1000px {
    max-height: 1000px !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 0.75rem !important;
  }

  .m-lg-4 {
    margin: 1rem !important;
  }

  .m-lg-5 {
    margin: 1.25rem !important;
  }

  .m-lg-6 {
    margin: 1.5rem !important;
  }

  .m-lg-7 {
    margin: 1.75rem !important;
  }

  .m-lg-8 {
    margin: 2rem !important;
  }

  .m-lg-9 {
    margin: 2.25rem !important;
  }

  .m-lg-10 {
    margin: 2.5rem !important;
  }

  .m-lg-11 {
    margin: 2.75rem !important;
  }

  .m-lg-12 {
    margin: 3rem !important;
  }

  .m-lg-13 {
    margin: 3.25rem !important;
  }

  .m-lg-14 {
    margin: 3.5rem !important;
  }

  .m-lg-15 {
    margin: 3.75rem !important;
  }

  .m-lg-16 {
    margin: 4rem !important;
  }

  .m-lg-17 {
    margin: 4.25rem !important;
  }

  .m-lg-18 {
    margin: 4.5rem !important;
  }

  .m-lg-19 {
    margin: 4.75rem !important;
  }

  .m-lg-20 {
    margin: 5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-lg-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-lg-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-lg-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-lg-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-lg-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-lg-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-lg-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-lg-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-lg-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-lg-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-lg-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-lg-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-lg-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-lg-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-lg-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-lg-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-lg-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-lg-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-lg-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-lg-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-lg-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }

  .mt-lg-4 {
    margin-top: 1rem !important;
  }

  .mt-lg-5 {
    margin-top: 1.25rem !important;
  }

  .mt-lg-6 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-7 {
    margin-top: 1.75rem !important;
  }

  .mt-lg-8 {
    margin-top: 2rem !important;
  }

  .mt-lg-9 {
    margin-top: 2.25rem !important;
  }

  .mt-lg-10 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-11 {
    margin-top: 2.75rem !important;
  }

  .mt-lg-12 {
    margin-top: 3rem !important;
  }

  .mt-lg-13 {
    margin-top: 3.25rem !important;
  }

  .mt-lg-14 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-15 {
    margin-top: 3.75rem !important;
  }

  .mt-lg-16 {
    margin-top: 4rem !important;
  }

  .mt-lg-17 {
    margin-top: 4.25rem !important;
  }

  .mt-lg-18 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-19 {
    margin-top: 4.75rem !important;
  }

  .mt-lg-20 {
    margin-top: 5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 0.75rem !important;
  }

  .me-lg-4 {
    margin-right: 1rem !important;
  }

  .me-lg-5 {
    margin-right: 1.25rem !important;
  }

  .me-lg-6 {
    margin-right: 1.5rem !important;
  }

  .me-lg-7 {
    margin-right: 1.75rem !important;
  }

  .me-lg-8 {
    margin-right: 2rem !important;
  }

  .me-lg-9 {
    margin-right: 2.25rem !important;
  }

  .me-lg-10 {
    margin-right: 2.5rem !important;
  }

  .me-lg-11 {
    margin-right: 2.75rem !important;
  }

  .me-lg-12 {
    margin-right: 3rem !important;
  }

  .me-lg-13 {
    margin-right: 3.25rem !important;
  }

  .me-lg-14 {
    margin-right: 3.5rem !important;
  }

  .me-lg-15 {
    margin-right: 3.75rem !important;
  }

  .me-lg-16 {
    margin-right: 4rem !important;
  }

  .me-lg-17 {
    margin-right: 4.25rem !important;
  }

  .me-lg-18 {
    margin-right: 4.5rem !important;
  }

  .me-lg-19 {
    margin-right: 4.75rem !important;
  }

  .me-lg-20 {
    margin-right: 5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-lg-8 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-lg-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-lg-12 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-lg-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-lg-16 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-lg-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-lg-20 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }

  .ms-lg-4 {
    margin-left: 1rem !important;
  }

  .ms-lg-5 {
    margin-left: 1.25rem !important;
  }

  .ms-lg-6 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-7 {
    margin-left: 1.75rem !important;
  }

  .ms-lg-8 {
    margin-left: 2rem !important;
  }

  .ms-lg-9 {
    margin-left: 2.25rem !important;
  }

  .ms-lg-10 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-11 {
    margin-left: 2.75rem !important;
  }

  .ms-lg-12 {
    margin-left: 3rem !important;
  }

  .ms-lg-13 {
    margin-left: 3.25rem !important;
  }

  .ms-lg-14 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-15 {
    margin-left: 3.75rem !important;
  }

  .ms-lg-16 {
    margin-left: 4rem !important;
  }

  .ms-lg-17 {
    margin-left: 4.25rem !important;
  }

  .ms-lg-18 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-19 {
    margin-left: 4.75rem !important;
  }

  .ms-lg-20 {
    margin-left: 5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -0.75rem !important;
  }

  .m-lg-n4 {
    margin: -1rem !important;
  }

  .m-lg-n5 {
    margin: -1.25rem !important;
  }

  .m-lg-n6 {
    margin: -1.5rem !important;
  }

  .m-lg-n7 {
    margin: -1.75rem !important;
  }

  .m-lg-n8 {
    margin: -2rem !important;
  }

  .m-lg-n9 {
    margin: -2.25rem !important;
  }

  .m-lg-n10 {
    margin: -2.5rem !important;
  }

  .m-lg-n11 {
    margin: -2.75rem !important;
  }

  .m-lg-n12 {
    margin: -3rem !important;
  }

  .m-lg-n13 {
    margin: -3.25rem !important;
  }

  .m-lg-n14 {
    margin: -3.5rem !important;
  }

  .m-lg-n15 {
    margin: -3.75rem !important;
  }

  .m-lg-n16 {
    margin: -4rem !important;
  }

  .m-lg-n17 {
    margin: -4.25rem !important;
  }

  .m-lg-n18 {
    margin: -4.5rem !important;
  }

  .m-lg-n19 {
    margin: -4.75rem !important;
  }

  .m-lg-n20 {
    margin: -5rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-lg-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-lg-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-lg-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-lg-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-lg-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-lg-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-lg-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-lg-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-lg-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-lg-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-lg-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-lg-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-lg-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-lg-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-lg-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-lg-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-lg-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-lg-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-lg-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-lg-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-lg-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-lg-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-lg-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-lg-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-lg-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-lg-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-lg-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-lg-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-lg-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1rem !important;
  }

  .mt-lg-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-lg-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-lg-n8 {
    margin-top: -2rem !important;
  }

  .mt-lg-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-lg-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-lg-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-lg-n12 {
    margin-top: -3rem !important;
  }

  .mt-lg-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-lg-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-lg-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-lg-n16 {
    margin-top: -4rem !important;
  }

  .mt-lg-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-lg-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-lg-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-lg-n20 {
    margin-top: -5rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -0.75rem !important;
  }

  .me-lg-n4 {
    margin-right: -1rem !important;
  }

  .me-lg-n5 {
    margin-right: -1.25rem !important;
  }

  .me-lg-n6 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n7 {
    margin-right: -1.75rem !important;
  }

  .me-lg-n8 {
    margin-right: -2rem !important;
  }

  .me-lg-n9 {
    margin-right: -2.25rem !important;
  }

  .me-lg-n10 {
    margin-right: -2.5rem !important;
  }

  .me-lg-n11 {
    margin-right: -2.75rem !important;
  }

  .me-lg-n12 {
    margin-right: -3rem !important;
  }

  .me-lg-n13 {
    margin-right: -3.25rem !important;
  }

  .me-lg-n14 {
    margin-right: -3.5rem !important;
  }

  .me-lg-n15 {
    margin-right: -3.75rem !important;
  }

  .me-lg-n16 {
    margin-right: -4rem !important;
  }

  .me-lg-n17 {
    margin-right: -4.25rem !important;
  }

  .me-lg-n18 {
    margin-right: -4.5rem !important;
  }

  .me-lg-n19 {
    margin-right: -4.75rem !important;
  }

  .me-lg-n20 {
    margin-right: -5rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-lg-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-lg-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-lg-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-lg-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-lg-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-lg-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-lg-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-lg-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-lg-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-lg-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-lg-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-lg-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-lg-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-lg-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1rem !important;
  }

  .ms-lg-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-lg-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-lg-n8 {
    margin-left: -2rem !important;
  }

  .ms-lg-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-lg-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-lg-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-lg-n12 {
    margin-left: -3rem !important;
  }

  .ms-lg-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-lg-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-lg-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-lg-n16 {
    margin-left: -4rem !important;
  }

  .ms-lg-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-lg-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-lg-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-lg-n20 {
    margin-left: -5rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 0.75rem !important;
  }

  .p-lg-4 {
    padding: 1rem !important;
  }

  .p-lg-5 {
    padding: 1.25rem !important;
  }

  .p-lg-6 {
    padding: 1.5rem !important;
  }

  .p-lg-7 {
    padding: 1.75rem !important;
  }

  .p-lg-8 {
    padding: 2rem !important;
  }

  .p-lg-9 {
    padding: 2.25rem !important;
  }

  .p-lg-10 {
    padding: 2.5rem !important;
  }

  .p-lg-11 {
    padding: 2.75rem !important;
  }

  .p-lg-12 {
    padding: 3rem !important;
  }

  .p-lg-13 {
    padding: 3.25rem !important;
  }

  .p-lg-14 {
    padding: 3.5rem !important;
  }

  .p-lg-15 {
    padding: 3.75rem !important;
  }

  .p-lg-16 {
    padding: 4rem !important;
  }

  .p-lg-17 {
    padding: 4.25rem !important;
  }

  .p-lg-18 {
    padding: 4.5rem !important;
  }

  .p-lg-19 {
    padding: 4.75rem !important;
  }

  .p-lg-20 {
    padding: 5rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-lg-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-lg-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-lg-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-lg-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-lg-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-lg-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-lg-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-lg-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-lg-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-lg-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-lg-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-lg-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-lg-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-lg-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-lg-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-lg-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-lg-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-lg-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-lg-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-lg-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-lg-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-lg-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-lg-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }

  .pt-lg-4 {
    padding-top: 1rem !important;
  }

  .pt-lg-5 {
    padding-top: 1.25rem !important;
  }

  .pt-lg-6 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-7 {
    padding-top: 1.75rem !important;
  }

  .pt-lg-8 {
    padding-top: 2rem !important;
  }

  .pt-lg-9 {
    padding-top: 2.25rem !important;
  }

  .pt-lg-10 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-11 {
    padding-top: 2.75rem !important;
  }

  .pt-lg-12 {
    padding-top: 3rem !important;
  }

  .pt-lg-13 {
    padding-top: 3.25rem !important;
  }

  .pt-lg-14 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-15 {
    padding-top: 3.75rem !important;
  }

  .pt-lg-16 {
    padding-top: 4rem !important;
  }

  .pt-lg-17 {
    padding-top: 4.25rem !important;
  }

  .pt-lg-18 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-19 {
    padding-top: 4.75rem !important;
  }

  .pt-lg-20 {
    padding-top: 5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 0.75rem !important;
  }

  .pe-lg-4 {
    padding-right: 1rem !important;
  }

  .pe-lg-5 {
    padding-right: 1.25rem !important;
  }

  .pe-lg-6 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-7 {
    padding-right: 1.75rem !important;
  }

  .pe-lg-8 {
    padding-right: 2rem !important;
  }

  .pe-lg-9 {
    padding-right: 2.25rem !important;
  }

  .pe-lg-10 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-11 {
    padding-right: 2.75rem !important;
  }

  .pe-lg-12 {
    padding-right: 3rem !important;
  }

  .pe-lg-13 {
    padding-right: 3.25rem !important;
  }

  .pe-lg-14 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-15 {
    padding-right: 3.75rem !important;
  }

  .pe-lg-16 {
    padding-right: 4rem !important;
  }

  .pe-lg-17 {
    padding-right: 4.25rem !important;
  }

  .pe-lg-18 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-19 {
    padding-right: 4.75rem !important;
  }

  .pe-lg-20 {
    padding-right: 5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-lg-8 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-lg-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-lg-12 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-lg-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-lg-16 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-lg-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-lg-20 {
    padding-bottom: 5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 0.75rem !important;
  }

  .ps-lg-4 {
    padding-left: 1rem !important;
  }

  .ps-lg-5 {
    padding-left: 1.25rem !important;
  }

  .ps-lg-6 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-7 {
    padding-left: 1.75rem !important;
  }

  .ps-lg-8 {
    padding-left: 2rem !important;
  }

  .ps-lg-9 {
    padding-left: 2.25rem !important;
  }

  .ps-lg-10 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-11 {
    padding-left: 2.75rem !important;
  }

  .ps-lg-12 {
    padding-left: 3rem !important;
  }

  .ps-lg-13 {
    padding-left: 3.25rem !important;
  }

  .ps-lg-14 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-15 {
    padding-left: 3.75rem !important;
  }

  .ps-lg-16 {
    padding-left: 4rem !important;
  }

  .ps-lg-17 {
    padding-left: 4.25rem !important;
  }

  .ps-lg-18 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-19 {
    padding-left: 4.75rem !important;
  }

  .ps-lg-20 {
    padding-left: 5rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 0.75rem !important;
  }

  .gap-lg-4 {
    gap: 1rem !important;
  }

  .gap-lg-5 {
    gap: 1.25rem !important;
  }

  .gap-lg-6 {
    gap: 1.5rem !important;
  }

  .gap-lg-7 {
    gap: 1.75rem !important;
  }

  .gap-lg-8 {
    gap: 2rem !important;
  }

  .gap-lg-9 {
    gap: 2.25rem !important;
  }

  .gap-lg-10 {
    gap: 2.5rem !important;
  }

  .gap-lg-11 {
    gap: 2.75rem !important;
  }

  .gap-lg-12 {
    gap: 3rem !important;
  }

  .gap-lg-13 {
    gap: 3.25rem !important;
  }

  .gap-lg-14 {
    gap: 3.5rem !important;
  }

  .gap-lg-15 {
    gap: 3.75rem !important;
  }

  .gap-lg-16 {
    gap: 4rem !important;
  }

  .gap-lg-17 {
    gap: 4.25rem !important;
  }

  .gap-lg-18 {
    gap: 4.5rem !important;
  }

  .gap-lg-19 {
    gap: 4.75rem !important;
  }

  .gap-lg-20 {
    gap: 5rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-lg-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-lg-8 {
    row-gap: 2rem !important;
  }

  .row-gap-lg-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-lg-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-lg-11 {
    row-gap: 2.75rem !important;
  }

  .row-gap-lg-12 {
    row-gap: 3rem !important;
  }

  .row-gap-lg-13 {
    row-gap: 3.25rem !important;
  }

  .row-gap-lg-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-lg-15 {
    row-gap: 3.75rem !important;
  }

  .row-gap-lg-16 {
    row-gap: 4rem !important;
  }

  .row-gap-lg-17 {
    row-gap: 4.25rem !important;
  }

  .row-gap-lg-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-lg-19 {
    row-gap: 4.75rem !important;
  }

  .row-gap-lg-20 {
    row-gap: 5rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-lg-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-lg-8 {
    column-gap: 2rem !important;
  }

  .column-gap-lg-9 {
    column-gap: 2.25rem !important;
  }

  .column-gap-lg-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-lg-11 {
    column-gap: 2.75rem !important;
  }

  .column-gap-lg-12 {
    column-gap: 3rem !important;
  }

  .column-gap-lg-13 {
    column-gap: 3.25rem !important;
  }

  .column-gap-lg-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-lg-15 {
    column-gap: 3.75rem !important;
  }

  .column-gap-lg-16 {
    column-gap: 4rem !important;
  }

  .column-gap-lg-17 {
    column-gap: 4.25rem !important;
  }

  .column-gap-lg-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-lg-19 {
    column-gap: 4.75rem !important;
  }

  .column-gap-lg-20 {
    column-gap: 5rem !important;
  }

  .fs-lg-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-lg-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-lg-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-lg-4 {
    font-size: 1.25rem !important;
  }

  .fs-lg-5 {
    font-size: 1.15rem !important;
  }

  .fs-lg-6 {
    font-size: 1.075rem !important;
  }

  .fs-lg-7 {
    font-size: 0.95rem !important;
  }

  .fs-lg-8 {
    font-size: 0.85rem !important;
  }

  .fs-lg-9 {
    font-size: 0.75rem !important;
  }

  .fs-lg-10 {
    font-size: 0.5rem !important;
  }

  .fs-lg-base {
    font-size: 1rem !important;
  }

  .fs-lg-fluid {
    font-size: 100% !important;
  }

  .fs-lg-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-lg-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-lg-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-lg-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-lg-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-lg-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-lg-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-lg-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-lg-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-lg-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-lg-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-lg-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-lg-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-lg-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-lg-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-lg-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }

  .fs-lg-10x {
    font-size: calc(2.125rem + 10.5vw) !important;
  }

  .fs-lg-10qx {
    font-size: calc(2.15rem + 10.8vw) !important;
  }

  .fs-lg-10hx {
    font-size: calc(2.175rem + 11.1vw) !important;
  }

  .fs-lg-10tx {
    font-size: calc(2.2rem + 11.4vw) !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .min-w-lg-unset {
    min-width: unset !important;
  }

  .min-w-lg-25 {
    min-width: 25% !important;
  }

  .min-w-lg-50 {
    min-width: 50% !important;
  }

  .min-w-lg-75 {
    min-width: 75% !important;
  }

  .min-w-lg-100 {
    min-width: 100% !important;
  }

  .min-w-lg-auto {
    min-width: auto !important;
  }

  .min-w-lg-1px {
    min-width: 1px !important;
  }

  .min-w-lg-2px {
    min-width: 2px !important;
  }

  .min-w-lg-3px {
    min-width: 3px !important;
  }

  .min-w-lg-4px {
    min-width: 4px !important;
  }

  .min-w-lg-5px {
    min-width: 5px !important;
  }

  .min-w-lg-6px {
    min-width: 6px !important;
  }

  .min-w-lg-7px {
    min-width: 7px !important;
  }

  .min-w-lg-8px {
    min-width: 8px !important;
  }

  .min-w-lg-9px {
    min-width: 9px !important;
  }

  .min-w-lg-10px {
    min-width: 10px !important;
  }

  .min-w-lg-15px {
    min-width: 15px !important;
  }

  .min-w-lg-20px {
    min-width: 20px !important;
  }

  .min-w-lg-25px {
    min-width: 25px !important;
  }

  .min-w-lg-30px {
    min-width: 30px !important;
  }

  .min-w-lg-35px {
    min-width: 35px !important;
  }

  .min-w-lg-40px {
    min-width: 40px !important;
  }

  .min-w-lg-45px {
    min-width: 45px !important;
  }

  .min-w-lg-50px {
    min-width: 50px !important;
  }

  .min-w-lg-55px {
    min-width: 55px !important;
  }

  .min-w-lg-60px {
    min-width: 60px !important;
  }

  .min-w-lg-65px {
    min-width: 65px !important;
  }

  .min-w-lg-70px {
    min-width: 70px !important;
  }

  .min-w-lg-75px {
    min-width: 75px !important;
  }

  .min-w-lg-80px {
    min-width: 80px !important;
  }

  .min-w-lg-85px {
    min-width: 85px !important;
  }

  .min-w-lg-90px {
    min-width: 90px !important;
  }

  .min-w-lg-95px {
    min-width: 95px !important;
  }

  .min-w-lg-100px {
    min-width: 100px !important;
  }

  .min-w-lg-125px {
    min-width: 125px !important;
  }

  .min-w-lg-150px {
    min-width: 150px !important;
  }

  .min-w-lg-175px {
    min-width: 175px !important;
  }

  .min-w-lg-200px {
    min-width: 200px !important;
  }

  .min-w-lg-225px {
    min-width: 225px !important;
  }

  .min-w-lg-250px {
    min-width: 250px !important;
  }

  .min-w-lg-275px {
    min-width: 275px !important;
  }

  .min-w-lg-300px {
    min-width: 300px !important;
  }

  .min-w-lg-325px {
    min-width: 325px !important;
  }

  .min-w-lg-350px {
    min-width: 350px !important;
  }

  .min-w-lg-375px {
    min-width: 375px !important;
  }

  .min-w-lg-400px {
    min-width: 400px !important;
  }

  .min-w-lg-425px {
    min-width: 425px !important;
  }

  .min-w-lg-450px {
    min-width: 450px !important;
  }

  .min-w-lg-475px {
    min-width: 475px !important;
  }

  .min-w-lg-500px {
    min-width: 500px !important;
  }

  .min-w-lg-550px {
    min-width: 550px !important;
  }

  .min-w-lg-600px {
    min-width: 600px !important;
  }

  .min-w-lg-650px {
    min-width: 650px !important;
  }

  .min-w-lg-700px {
    min-width: 700px !important;
  }

  .min-w-lg-750px {
    min-width: 750px !important;
  }

  .min-w-lg-800px {
    min-width: 800px !important;
  }

  .min-w-lg-850px {
    min-width: 850px !important;
  }

  .min-w-lg-900px {
    min-width: 900px !important;
  }

  .min-w-lg-950px {
    min-width: 950px !important;
  }

  .min-w-lg-1000px {
    min-width: 1000px !important;
  }

  .min-h-lg-unset {
    min-height: unset !important;
  }

  .min-h-lg-25 {
    min-height: 25% !important;
  }

  .min-h-lg-50 {
    min-height: 50% !important;
  }

  .min-h-lg-75 {
    min-height: 75% !important;
  }

  .min-h-lg-100 {
    min-height: 100% !important;
  }

  .min-h-lg-auto {
    min-height: auto !important;
  }

  .min-h-lg-1px {
    min-height: 1px !important;
  }

  .min-h-lg-2px {
    min-height: 2px !important;
  }

  .min-h-lg-3px {
    min-height: 3px !important;
  }

  .min-h-lg-4px {
    min-height: 4px !important;
  }

  .min-h-lg-5px {
    min-height: 5px !important;
  }

  .min-h-lg-6px {
    min-height: 6px !important;
  }

  .min-h-lg-7px {
    min-height: 7px !important;
  }

  .min-h-lg-8px {
    min-height: 8px !important;
  }

  .min-h-lg-9px {
    min-height: 9px !important;
  }

  .min-h-lg-10px {
    min-height: 10px !important;
  }

  .min-h-lg-15px {
    min-height: 15px !important;
  }

  .min-h-lg-20px {
    min-height: 20px !important;
  }

  .min-h-lg-25px {
    min-height: 25px !important;
  }

  .min-h-lg-30px {
    min-height: 30px !important;
  }

  .min-h-lg-35px {
    min-height: 35px !important;
  }

  .min-h-lg-40px {
    min-height: 40px !important;
  }

  .min-h-lg-45px {
    min-height: 45px !important;
  }

  .min-h-lg-50px {
    min-height: 50px !important;
  }

  .min-h-lg-55px {
    min-height: 55px !important;
  }

  .min-h-lg-60px {
    min-height: 60px !important;
  }

  .min-h-lg-65px {
    min-height: 65px !important;
  }

  .min-h-lg-70px {
    min-height: 70px !important;
  }

  .min-h-lg-75px {
    min-height: 75px !important;
  }

  .min-h-lg-80px {
    min-height: 80px !important;
  }

  .min-h-lg-85px {
    min-height: 85px !important;
  }

  .min-h-lg-90px {
    min-height: 90px !important;
  }

  .min-h-lg-95px {
    min-height: 95px !important;
  }

  .min-h-lg-100px {
    min-height: 100px !important;
  }

  .min-h-lg-125px {
    min-height: 125px !important;
  }

  .min-h-lg-150px {
    min-height: 150px !important;
  }

  .min-h-lg-175px {
    min-height: 175px !important;
  }

  .min-h-lg-200px {
    min-height: 200px !important;
  }

  .min-h-lg-225px {
    min-height: 225px !important;
  }

  .min-h-lg-250px {
    min-height: 250px !important;
  }

  .min-h-lg-275px {
    min-height: 275px !important;
  }

  .min-h-lg-300px {
    min-height: 300px !important;
  }

  .min-h-lg-325px {
    min-height: 325px !important;
  }

  .min-h-lg-350px {
    min-height: 350px !important;
  }

  .min-h-lg-375px {
    min-height: 375px !important;
  }

  .min-h-lg-400px {
    min-height: 400px !important;
  }

  .min-h-lg-425px {
    min-height: 425px !important;
  }

  .min-h-lg-450px {
    min-height: 450px !important;
  }

  .min-h-lg-475px {
    min-height: 475px !important;
  }

  .min-h-lg-500px {
    min-height: 500px !important;
  }

  .min-h-lg-550px {
    min-height: 550px !important;
  }

  .min-h-lg-600px {
    min-height: 600px !important;
  }

  .min-h-lg-650px {
    min-height: 650px !important;
  }

  .min-h-lg-700px {
    min-height: 700px !important;
  }

  .min-h-lg-750px {
    min-height: 750px !important;
  }

  .min-h-lg-800px {
    min-height: 800px !important;
  }

  .min-h-lg-850px {
    min-height: 850px !important;
  }

  .min-h-lg-900px {
    min-height: 900px !important;
  }

  .min-h-lg-950px {
    min-height: 950px !important;
  }

  .min-h-lg-1000px {
    min-height: 1000px !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .w-xl-unset {
    width: unset !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-auto {
    width: auto !important;
  }

  .w-xl-1px {
    width: 1px !important;
  }

  .w-xl-2px {
    width: 2px !important;
  }

  .w-xl-3px {
    width: 3px !important;
  }

  .w-xl-4px {
    width: 4px !important;
  }

  .w-xl-5px {
    width: 5px !important;
  }

  .w-xl-6px {
    width: 6px !important;
  }

  .w-xl-7px {
    width: 7px !important;
  }

  .w-xl-8px {
    width: 8px !important;
  }

  .w-xl-9px {
    width: 9px !important;
  }

  .w-xl-10px {
    width: 10px !important;
  }

  .w-xl-15px {
    width: 15px !important;
  }

  .w-xl-20px {
    width: 20px !important;
  }

  .w-xl-25px {
    width: 25px !important;
  }

  .w-xl-30px {
    width: 30px !important;
  }

  .w-xl-35px {
    width: 35px !important;
  }

  .w-xl-40px {
    width: 40px !important;
  }

  .w-xl-45px {
    width: 45px !important;
  }

  .w-xl-50px {
    width: 50px !important;
  }

  .w-xl-55px {
    width: 55px !important;
  }

  .w-xl-60px {
    width: 60px !important;
  }

  .w-xl-65px {
    width: 65px !important;
  }

  .w-xl-70px {
    width: 70px !important;
  }

  .w-xl-75px {
    width: 75px !important;
  }

  .w-xl-80px {
    width: 80px !important;
  }

  .w-xl-85px {
    width: 85px !important;
  }

  .w-xl-90px {
    width: 90px !important;
  }

  .w-xl-95px {
    width: 95px !important;
  }

  .w-xl-100px {
    width: 100px !important;
  }

  .w-xl-125px {
    width: 125px !important;
  }

  .w-xl-150px {
    width: 150px !important;
  }

  .w-xl-175px {
    width: 175px !important;
  }

  .w-xl-200px {
    width: 200px !important;
  }

  .w-xl-225px {
    width: 225px !important;
  }

  .w-xl-250px {
    width: 250px !important;
  }

  .w-xl-275px {
    width: 275px !important;
  }

  .w-xl-300px {
    width: 300px !important;
  }

  .w-xl-325px {
    width: 325px !important;
  }

  .w-xl-350px {
    width: 350px !important;
  }

  .w-xl-375px {
    width: 375px !important;
  }

  .w-xl-400px {
    width: 400px !important;
  }

  .w-xl-425px {
    width: 425px !important;
  }

  .w-xl-450px {
    width: 450px !important;
  }

  .w-xl-475px {
    width: 475px !important;
  }

  .w-xl-500px {
    width: 500px !important;
  }

  .w-xl-550px {
    width: 550px !important;
  }

  .w-xl-600px {
    width: 600px !important;
  }

  .w-xl-650px {
    width: 650px !important;
  }

  .w-xl-700px {
    width: 700px !important;
  }

  .w-xl-750px {
    width: 750px !important;
  }

  .w-xl-800px {
    width: 800px !important;
  }

  .w-xl-850px {
    width: 850px !important;
  }

  .w-xl-900px {
    width: 900px !important;
  }

  .w-xl-950px {
    width: 950px !important;
  }

  .w-xl-1000px {
    width: 1000px !important;
  }

  .mw-xl-unset {
    max-width: unset !important;
  }

  .mw-xl-25 {
    max-width: 25% !important;
  }

  .mw-xl-50 {
    max-width: 50% !important;
  }

  .mw-xl-75 {
    max-width: 75% !important;
  }

  .mw-xl-100 {
    max-width: 100% !important;
  }

  .mw-xl-auto {
    max-width: auto !important;
  }

  .mw-xl-1px {
    max-width: 1px !important;
  }

  .mw-xl-2px {
    max-width: 2px !important;
  }

  .mw-xl-3px {
    max-width: 3px !important;
  }

  .mw-xl-4px {
    max-width: 4px !important;
  }

  .mw-xl-5px {
    max-width: 5px !important;
  }

  .mw-xl-6px {
    max-width: 6px !important;
  }

  .mw-xl-7px {
    max-width: 7px !important;
  }

  .mw-xl-8px {
    max-width: 8px !important;
  }

  .mw-xl-9px {
    max-width: 9px !important;
  }

  .mw-xl-10px {
    max-width: 10px !important;
  }

  .mw-xl-15px {
    max-width: 15px !important;
  }

  .mw-xl-20px {
    max-width: 20px !important;
  }

  .mw-xl-25px {
    max-width: 25px !important;
  }

  .mw-xl-30px {
    max-width: 30px !important;
  }

  .mw-xl-35px {
    max-width: 35px !important;
  }

  .mw-xl-40px {
    max-width: 40px !important;
  }

  .mw-xl-45px {
    max-width: 45px !important;
  }

  .mw-xl-50px {
    max-width: 50px !important;
  }

  .mw-xl-55px {
    max-width: 55px !important;
  }

  .mw-xl-60px {
    max-width: 60px !important;
  }

  .mw-xl-65px {
    max-width: 65px !important;
  }

  .mw-xl-70px {
    max-width: 70px !important;
  }

  .mw-xl-75px {
    max-width: 75px !important;
  }

  .mw-xl-80px {
    max-width: 80px !important;
  }

  .mw-xl-85px {
    max-width: 85px !important;
  }

  .mw-xl-90px {
    max-width: 90px !important;
  }

  .mw-xl-95px {
    max-width: 95px !important;
  }

  .mw-xl-100px {
    max-width: 100px !important;
  }

  .mw-xl-125px {
    max-width: 125px !important;
  }

  .mw-xl-150px {
    max-width: 150px !important;
  }

  .mw-xl-175px {
    max-width: 175px !important;
  }

  .mw-xl-200px {
    max-width: 200px !important;
  }

  .mw-xl-225px {
    max-width: 225px !important;
  }

  .mw-xl-250px {
    max-width: 250px !important;
  }

  .mw-xl-275px {
    max-width: 275px !important;
  }

  .mw-xl-300px {
    max-width: 300px !important;
  }

  .mw-xl-325px {
    max-width: 325px !important;
  }

  .mw-xl-350px {
    max-width: 350px !important;
  }

  .mw-xl-375px {
    max-width: 375px !important;
  }

  .mw-xl-400px {
    max-width: 400px !important;
  }

  .mw-xl-425px {
    max-width: 425px !important;
  }

  .mw-xl-450px {
    max-width: 450px !important;
  }

  .mw-xl-475px {
    max-width: 475px !important;
  }

  .mw-xl-500px {
    max-width: 500px !important;
  }

  .mw-xl-550px {
    max-width: 550px !important;
  }

  .mw-xl-600px {
    max-width: 600px !important;
  }

  .mw-xl-650px {
    max-width: 650px !important;
  }

  .mw-xl-700px {
    max-width: 700px !important;
  }

  .mw-xl-750px {
    max-width: 750px !important;
  }

  .mw-xl-800px {
    max-width: 800px !important;
  }

  .mw-xl-850px {
    max-width: 850px !important;
  }

  .mw-xl-900px {
    max-width: 900px !important;
  }

  .mw-xl-950px {
    max-width: 950px !important;
  }

  .mw-xl-1000px {
    max-width: 1000px !important;
  }

  .h-xl-unset {
    height: unset !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .h-xl-auto {
    height: auto !important;
  }

  .h-xl-1px {
    height: 1px !important;
  }

  .h-xl-2px {
    height: 2px !important;
  }

  .h-xl-3px {
    height: 3px !important;
  }

  .h-xl-4px {
    height: 4px !important;
  }

  .h-xl-5px {
    height: 5px !important;
  }

  .h-xl-6px {
    height: 6px !important;
  }

  .h-xl-7px {
    height: 7px !important;
  }

  .h-xl-8px {
    height: 8px !important;
  }

  .h-xl-9px {
    height: 9px !important;
  }

  .h-xl-10px {
    height: 10px !important;
  }

  .h-xl-15px {
    height: 15px !important;
  }

  .h-xl-20px {
    height: 20px !important;
  }

  .h-xl-25px {
    height: 25px !important;
  }

  .h-xl-30px {
    height: 30px !important;
  }

  .h-xl-35px {
    height: 35px !important;
  }

  .h-xl-40px {
    height: 40px !important;
  }

  .h-xl-45px {
    height: 45px !important;
  }

  .h-xl-50px {
    height: 50px !important;
  }

  .h-xl-55px {
    height: 55px !important;
  }

  .h-xl-60px {
    height: 60px !important;
  }

  .h-xl-65px {
    height: 65px !important;
  }

  .h-xl-70px {
    height: 70px !important;
  }

  .h-xl-75px {
    height: 75px !important;
  }

  .h-xl-80px {
    height: 80px !important;
  }

  .h-xl-85px {
    height: 85px !important;
  }

  .h-xl-90px {
    height: 90px !important;
  }

  .h-xl-95px {
    height: 95px !important;
  }

  .h-xl-100px {
    height: 100px !important;
  }

  .h-xl-125px {
    height: 125px !important;
  }

  .h-xl-150px {
    height: 150px !important;
  }

  .h-xl-175px {
    height: 175px !important;
  }

  .h-xl-200px {
    height: 200px !important;
  }

  .h-xl-225px {
    height: 225px !important;
  }

  .h-xl-250px {
    height: 250px !important;
  }

  .h-xl-275px {
    height: 275px !important;
  }

  .h-xl-300px {
    height: 300px !important;
  }

  .h-xl-325px {
    height: 325px !important;
  }

  .h-xl-350px {
    height: 350px !important;
  }

  .h-xl-375px {
    height: 375px !important;
  }

  .h-xl-400px {
    height: 400px !important;
  }

  .h-xl-425px {
    height: 425px !important;
  }

  .h-xl-450px {
    height: 450px !important;
  }

  .h-xl-475px {
    height: 475px !important;
  }

  .h-xl-500px {
    height: 500px !important;
  }

  .h-xl-550px {
    height: 550px !important;
  }

  .h-xl-600px {
    height: 600px !important;
  }

  .h-xl-650px {
    height: 650px !important;
  }

  .h-xl-700px {
    height: 700px !important;
  }

  .h-xl-750px {
    height: 750px !important;
  }

  .h-xl-800px {
    height: 800px !important;
  }

  .h-xl-850px {
    height: 850px !important;
  }

  .h-xl-900px {
    height: 900px !important;
  }

  .h-xl-950px {
    height: 950px !important;
  }

  .h-xl-1000px {
    height: 1000px !important;
  }

  .mh-xl-unset {
    max-height: unset !important;
  }

  .mh-xl-25 {
    max-height: 25% !important;
  }

  .mh-xl-50 {
    max-height: 50% !important;
  }

  .mh-xl-75 {
    max-height: 75% !important;
  }

  .mh-xl-100 {
    max-height: 100% !important;
  }

  .mh-xl-auto {
    max-height: auto !important;
  }

  .mh-xl-1px {
    max-height: 1px !important;
  }

  .mh-xl-2px {
    max-height: 2px !important;
  }

  .mh-xl-3px {
    max-height: 3px !important;
  }

  .mh-xl-4px {
    max-height: 4px !important;
  }

  .mh-xl-5px {
    max-height: 5px !important;
  }

  .mh-xl-6px {
    max-height: 6px !important;
  }

  .mh-xl-7px {
    max-height: 7px !important;
  }

  .mh-xl-8px {
    max-height: 8px !important;
  }

  .mh-xl-9px {
    max-height: 9px !important;
  }

  .mh-xl-10px {
    max-height: 10px !important;
  }

  .mh-xl-15px {
    max-height: 15px !important;
  }

  .mh-xl-20px {
    max-height: 20px !important;
  }

  .mh-xl-25px {
    max-height: 25px !important;
  }

  .mh-xl-30px {
    max-height: 30px !important;
  }

  .mh-xl-35px {
    max-height: 35px !important;
  }

  .mh-xl-40px {
    max-height: 40px !important;
  }

  .mh-xl-45px {
    max-height: 45px !important;
  }

  .mh-xl-50px {
    max-height: 50px !important;
  }

  .mh-xl-55px {
    max-height: 55px !important;
  }

  .mh-xl-60px {
    max-height: 60px !important;
  }

  .mh-xl-65px {
    max-height: 65px !important;
  }

  .mh-xl-70px {
    max-height: 70px !important;
  }

  .mh-xl-75px {
    max-height: 75px !important;
  }

  .mh-xl-80px {
    max-height: 80px !important;
  }

  .mh-xl-85px {
    max-height: 85px !important;
  }

  .mh-xl-90px {
    max-height: 90px !important;
  }

  .mh-xl-95px {
    max-height: 95px !important;
  }

  .mh-xl-100px {
    max-height: 100px !important;
  }

  .mh-xl-125px {
    max-height: 125px !important;
  }

  .mh-xl-150px {
    max-height: 150px !important;
  }

  .mh-xl-175px {
    max-height: 175px !important;
  }

  .mh-xl-200px {
    max-height: 200px !important;
  }

  .mh-xl-225px {
    max-height: 225px !important;
  }

  .mh-xl-250px {
    max-height: 250px !important;
  }

  .mh-xl-275px {
    max-height: 275px !important;
  }

  .mh-xl-300px {
    max-height: 300px !important;
  }

  .mh-xl-325px {
    max-height: 325px !important;
  }

  .mh-xl-350px {
    max-height: 350px !important;
  }

  .mh-xl-375px {
    max-height: 375px !important;
  }

  .mh-xl-400px {
    max-height: 400px !important;
  }

  .mh-xl-425px {
    max-height: 425px !important;
  }

  .mh-xl-450px {
    max-height: 450px !important;
  }

  .mh-xl-475px {
    max-height: 475px !important;
  }

  .mh-xl-500px {
    max-height: 500px !important;
  }

  .mh-xl-550px {
    max-height: 550px !important;
  }

  .mh-xl-600px {
    max-height: 600px !important;
  }

  .mh-xl-650px {
    max-height: 650px !important;
  }

  .mh-xl-700px {
    max-height: 700px !important;
  }

  .mh-xl-750px {
    max-height: 750px !important;
  }

  .mh-xl-800px {
    max-height: 800px !important;
  }

  .mh-xl-850px {
    max-height: 850px !important;
  }

  .mh-xl-900px {
    max-height: 900px !important;
  }

  .mh-xl-950px {
    max-height: 950px !important;
  }

  .mh-xl-1000px {
    max-height: 1000px !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 0.75rem !important;
  }

  .m-xl-4 {
    margin: 1rem !important;
  }

  .m-xl-5 {
    margin: 1.25rem !important;
  }

  .m-xl-6 {
    margin: 1.5rem !important;
  }

  .m-xl-7 {
    margin: 1.75rem !important;
  }

  .m-xl-8 {
    margin: 2rem !important;
  }

  .m-xl-9 {
    margin: 2.25rem !important;
  }

  .m-xl-10 {
    margin: 2.5rem !important;
  }

  .m-xl-11 {
    margin: 2.75rem !important;
  }

  .m-xl-12 {
    margin: 3rem !important;
  }

  .m-xl-13 {
    margin: 3.25rem !important;
  }

  .m-xl-14 {
    margin: 3.5rem !important;
  }

  .m-xl-15 {
    margin: 3.75rem !important;
  }

  .m-xl-16 {
    margin: 4rem !important;
  }

  .m-xl-17 {
    margin: 4.25rem !important;
  }

  .m-xl-18 {
    margin: 4.5rem !important;
  }

  .m-xl-19 {
    margin: 4.75rem !important;
  }

  .m-xl-20 {
    margin: 5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xl-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-xl-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-xl-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-xl-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-xl-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xl-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-xl-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-xl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-xl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-xl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-xl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-xl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xl-4 {
    margin-top: 1rem !important;
  }

  .mt-xl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xl-8 {
    margin-top: 2rem !important;
  }

  .mt-xl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-11 {
    margin-top: 2.75rem !important;
  }

  .mt-xl-12 {
    margin-top: 3rem !important;
  }

  .mt-xl-13 {
    margin-top: 3.25rem !important;
  }

  .mt-xl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-15 {
    margin-top: 3.75rem !important;
  }

  .mt-xl-16 {
    margin-top: 4rem !important;
  }

  .mt-xl-17 {
    margin-top: 4.25rem !important;
  }

  .mt-xl-18 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-19 {
    margin-top: 4.75rem !important;
  }

  .mt-xl-20 {
    margin-top: 5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xl-4 {
    margin-right: 1rem !important;
  }

  .me-xl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xl-8 {
    margin-right: 2rem !important;
  }

  .me-xl-9 {
    margin-right: 2.25rem !important;
  }

  .me-xl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xl-11 {
    margin-right: 2.75rem !important;
  }

  .me-xl-12 {
    margin-right: 3rem !important;
  }

  .me-xl-13 {
    margin-right: 3.25rem !important;
  }

  .me-xl-14 {
    margin-right: 3.5rem !important;
  }

  .me-xl-15 {
    margin-right: 3.75rem !important;
  }

  .me-xl-16 {
    margin-right: 4rem !important;
  }

  .me-xl-17 {
    margin-right: 4.25rem !important;
  }

  .me-xl-18 {
    margin-right: 4.5rem !important;
  }

  .me-xl-19 {
    margin-right: 4.75rem !important;
  }

  .me-xl-20 {
    margin-right: 5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-xl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-xl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-xl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-xl-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-xl-20 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xl-4 {
    margin-left: 1rem !important;
  }

  .ms-xl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xl-8 {
    margin-left: 2rem !important;
  }

  .ms-xl-9 {
    margin-left: 2.25rem !important;
  }

  .ms-xl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-11 {
    margin-left: 2.75rem !important;
  }

  .ms-xl-12 {
    margin-left: 3rem !important;
  }

  .ms-xl-13 {
    margin-left: 3.25rem !important;
  }

  .ms-xl-14 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-15 {
    margin-left: 3.75rem !important;
  }

  .ms-xl-16 {
    margin-left: 4rem !important;
  }

  .ms-xl-17 {
    margin-left: 4.25rem !important;
  }

  .ms-xl-18 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-19 {
    margin-left: 4.75rem !important;
  }

  .ms-xl-20 {
    margin-left: 5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -0.75rem !important;
  }

  .m-xl-n4 {
    margin: -1rem !important;
  }

  .m-xl-n5 {
    margin: -1.25rem !important;
  }

  .m-xl-n6 {
    margin: -1.5rem !important;
  }

  .m-xl-n7 {
    margin: -1.75rem !important;
  }

  .m-xl-n8 {
    margin: -2rem !important;
  }

  .m-xl-n9 {
    margin: -2.25rem !important;
  }

  .m-xl-n10 {
    margin: -2.5rem !important;
  }

  .m-xl-n11 {
    margin: -2.75rem !important;
  }

  .m-xl-n12 {
    margin: -3rem !important;
  }

  .m-xl-n13 {
    margin: -3.25rem !important;
  }

  .m-xl-n14 {
    margin: -3.5rem !important;
  }

  .m-xl-n15 {
    margin: -3.75rem !important;
  }

  .m-xl-n16 {
    margin: -4rem !important;
  }

  .m-xl-n17 {
    margin: -4.25rem !important;
  }

  .m-xl-n18 {
    margin: -4.5rem !important;
  }

  .m-xl-n19 {
    margin: -4.75rem !important;
  }

  .m-xl-n20 {
    margin: -5rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-xl-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xl-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-xl-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xl-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-xl-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xl-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-xl-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xl-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-xl-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xl-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-xl-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-xl-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-xl-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xl-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-xl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xl-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-xl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xl-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-xl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xl-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-xl-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xl-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-xl-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xl-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-xl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xl-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-xl-n12 {
    margin-top: -3rem !important;
  }

  .mt-xl-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-xl-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-xl-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-xl-n16 {
    margin-top: -4rem !important;
  }

  .mt-xl-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-xl-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-xl-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-xl-n20 {
    margin-top: -5rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -0.75rem !important;
  }

  .me-xl-n4 {
    margin-right: -1rem !important;
  }

  .me-xl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xl-n8 {
    margin-right: -2rem !important;
  }

  .me-xl-n9 {
    margin-right: -2.25rem !important;
  }

  .me-xl-n10 {
    margin-right: -2.5rem !important;
  }

  .me-xl-n11 {
    margin-right: -2.75rem !important;
  }

  .me-xl-n12 {
    margin-right: -3rem !important;
  }

  .me-xl-n13 {
    margin-right: -3.25rem !important;
  }

  .me-xl-n14 {
    margin-right: -3.5rem !important;
  }

  .me-xl-n15 {
    margin-right: -3.75rem !important;
  }

  .me-xl-n16 {
    margin-right: -4rem !important;
  }

  .me-xl-n17 {
    margin-right: -4.25rem !important;
  }

  .me-xl-n18 {
    margin-right: -4.5rem !important;
  }

  .me-xl-n19 {
    margin-right: -4.75rem !important;
  }

  .me-xl-n20 {
    margin-right: -5rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xl-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xl-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-xl-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-xl-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-xl-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xl-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-xl-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-xl-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-xl-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xl-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-xl-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xl-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-xl-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-xl-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-xl-n12 {
    margin-left: -3rem !important;
  }

  .ms-xl-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-xl-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-xl-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-xl-n16 {
    margin-left: -4rem !important;
  }

  .ms-xl-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-xl-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-xl-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-xl-n20 {
    margin-left: -5rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 0.75rem !important;
  }

  .p-xl-4 {
    padding: 1rem !important;
  }

  .p-xl-5 {
    padding: 1.25rem !important;
  }

  .p-xl-6 {
    padding: 1.5rem !important;
  }

  .p-xl-7 {
    padding: 1.75rem !important;
  }

  .p-xl-8 {
    padding: 2rem !important;
  }

  .p-xl-9 {
    padding: 2.25rem !important;
  }

  .p-xl-10 {
    padding: 2.5rem !important;
  }

  .p-xl-11 {
    padding: 2.75rem !important;
  }

  .p-xl-12 {
    padding: 3rem !important;
  }

  .p-xl-13 {
    padding: 3.25rem !important;
  }

  .p-xl-14 {
    padding: 3.5rem !important;
  }

  .p-xl-15 {
    padding: 3.75rem !important;
  }

  .p-xl-16 {
    padding: 4rem !important;
  }

  .p-xl-17 {
    padding: 4.25rem !important;
  }

  .p-xl-18 {
    padding: 4.5rem !important;
  }

  .p-xl-19 {
    padding: 4.75rem !important;
  }

  .p-xl-20 {
    padding: 5rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xl-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-xl-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-xl-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-xl-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xl-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-xl-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xl-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-xl-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-xl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-xl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-xl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-xl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xl-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-xl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xl-4 {
    padding-top: 1rem !important;
  }

  .pt-xl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xl-8 {
    padding-top: 2rem !important;
  }

  .pt-xl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-11 {
    padding-top: 2.75rem !important;
  }

  .pt-xl-12 {
    padding-top: 3rem !important;
  }

  .pt-xl-13 {
    padding-top: 3.25rem !important;
  }

  .pt-xl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-15 {
    padding-top: 3.75rem !important;
  }

  .pt-xl-16 {
    padding-top: 4rem !important;
  }

  .pt-xl-17 {
    padding-top: 4.25rem !important;
  }

  .pt-xl-18 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-19 {
    padding-top: 4.75rem !important;
  }

  .pt-xl-20 {
    padding-top: 5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xl-4 {
    padding-right: 1rem !important;
  }

  .pe-xl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xl-8 {
    padding-right: 2rem !important;
  }

  .pe-xl-9 {
    padding-right: 2.25rem !important;
  }

  .pe-xl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-11 {
    padding-right: 2.75rem !important;
  }

  .pe-xl-12 {
    padding-right: 3rem !important;
  }

  .pe-xl-13 {
    padding-right: 3.25rem !important;
  }

  .pe-xl-14 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-15 {
    padding-right: 3.75rem !important;
  }

  .pe-xl-16 {
    padding-right: 4rem !important;
  }

  .pe-xl-17 {
    padding-right: 4.25rem !important;
  }

  .pe-xl-18 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-19 {
    padding-right: 4.75rem !important;
  }

  .pe-xl-20 {
    padding-right: 5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-xl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-xl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-xl-16 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-xl-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-xl-20 {
    padding-bottom: 5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xl-4 {
    padding-left: 1rem !important;
  }

  .ps-xl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xl-8 {
    padding-left: 2rem !important;
  }

  .ps-xl-9 {
    padding-left: 2.25rem !important;
  }

  .ps-xl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-11 {
    padding-left: 2.75rem !important;
  }

  .ps-xl-12 {
    padding-left: 3rem !important;
  }

  .ps-xl-13 {
    padding-left: 3.25rem !important;
  }

  .ps-xl-14 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-15 {
    padding-left: 3.75rem !important;
  }

  .ps-xl-16 {
    padding-left: 4rem !important;
  }

  .ps-xl-17 {
    padding-left: 4.25rem !important;
  }

  .ps-xl-18 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-19 {
    padding-left: 4.75rem !important;
  }

  .ps-xl-20 {
    padding-left: 5rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 0.75rem !important;
  }

  .gap-xl-4 {
    gap: 1rem !important;
  }

  .gap-xl-5 {
    gap: 1.25rem !important;
  }

  .gap-xl-6 {
    gap: 1.5rem !important;
  }

  .gap-xl-7 {
    gap: 1.75rem !important;
  }

  .gap-xl-8 {
    gap: 2rem !important;
  }

  .gap-xl-9 {
    gap: 2.25rem !important;
  }

  .gap-xl-10 {
    gap: 2.5rem !important;
  }

  .gap-xl-11 {
    gap: 2.75rem !important;
  }

  .gap-xl-12 {
    gap: 3rem !important;
  }

  .gap-xl-13 {
    gap: 3.25rem !important;
  }

  .gap-xl-14 {
    gap: 3.5rem !important;
  }

  .gap-xl-15 {
    gap: 3.75rem !important;
  }

  .gap-xl-16 {
    gap: 4rem !important;
  }

  .gap-xl-17 {
    gap: 4.25rem !important;
  }

  .gap-xl-18 {
    gap: 4.5rem !important;
  }

  .gap-xl-19 {
    gap: 4.75rem !important;
  }

  .gap-xl-20 {
    gap: 5rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-xl-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-xl-8 {
    row-gap: 2rem !important;
  }

  .row-gap-xl-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-xl-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-xl-11 {
    row-gap: 2.75rem !important;
  }

  .row-gap-xl-12 {
    row-gap: 3rem !important;
  }

  .row-gap-xl-13 {
    row-gap: 3.25rem !important;
  }

  .row-gap-xl-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-xl-15 {
    row-gap: 3.75rem !important;
  }

  .row-gap-xl-16 {
    row-gap: 4rem !important;
  }

  .row-gap-xl-17 {
    row-gap: 4.25rem !important;
  }

  .row-gap-xl-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-xl-19 {
    row-gap: 4.75rem !important;
  }

  .row-gap-xl-20 {
    row-gap: 5rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-xl-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-xl-8 {
    column-gap: 2rem !important;
  }

  .column-gap-xl-9 {
    column-gap: 2.25rem !important;
  }

  .column-gap-xl-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-xl-11 {
    column-gap: 2.75rem !important;
  }

  .column-gap-xl-12 {
    column-gap: 3rem !important;
  }

  .column-gap-xl-13 {
    column-gap: 3.25rem !important;
  }

  .column-gap-xl-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-xl-15 {
    column-gap: 3.75rem !important;
  }

  .column-gap-xl-16 {
    column-gap: 4rem !important;
  }

  .column-gap-xl-17 {
    column-gap: 4.25rem !important;
  }

  .column-gap-xl-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-xl-19 {
    column-gap: 4.75rem !important;
  }

  .column-gap-xl-20 {
    column-gap: 5rem !important;
  }

  .fs-xl-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-xl-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-xl-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-xl-4 {
    font-size: 1.25rem !important;
  }

  .fs-xl-5 {
    font-size: 1.15rem !important;
  }

  .fs-xl-6 {
    font-size: 1.075rem !important;
  }

  .fs-xl-7 {
    font-size: 0.95rem !important;
  }

  .fs-xl-8 {
    font-size: 0.85rem !important;
  }

  .fs-xl-9 {
    font-size: 0.75rem !important;
  }

  .fs-xl-10 {
    font-size: 0.5rem !important;
  }

  .fs-xl-base {
    font-size: 1rem !important;
  }

  .fs-xl-fluid {
    font-size: 100% !important;
  }

  .fs-xl-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-xl-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-xl-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-xl-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-xl-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-xl-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-xl-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-xl-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-xl-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-xl-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-xl-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-xl-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-xl-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-xl-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-xl-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-xl-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }

  .fs-xl-10x {
    font-size: calc(2.125rem + 10.5vw) !important;
  }

  .fs-xl-10qx {
    font-size: calc(2.15rem + 10.8vw) !important;
  }

  .fs-xl-10hx {
    font-size: calc(2.175rem + 11.1vw) !important;
  }

  .fs-xl-10tx {
    font-size: calc(2.2rem + 11.4vw) !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .min-w-xl-unset {
    min-width: unset !important;
  }

  .min-w-xl-25 {
    min-width: 25% !important;
  }

  .min-w-xl-50 {
    min-width: 50% !important;
  }

  .min-w-xl-75 {
    min-width: 75% !important;
  }

  .min-w-xl-100 {
    min-width: 100% !important;
  }

  .min-w-xl-auto {
    min-width: auto !important;
  }

  .min-w-xl-1px {
    min-width: 1px !important;
  }

  .min-w-xl-2px {
    min-width: 2px !important;
  }

  .min-w-xl-3px {
    min-width: 3px !important;
  }

  .min-w-xl-4px {
    min-width: 4px !important;
  }

  .min-w-xl-5px {
    min-width: 5px !important;
  }

  .min-w-xl-6px {
    min-width: 6px !important;
  }

  .min-w-xl-7px {
    min-width: 7px !important;
  }

  .min-w-xl-8px {
    min-width: 8px !important;
  }

  .min-w-xl-9px {
    min-width: 9px !important;
  }

  .min-w-xl-10px {
    min-width: 10px !important;
  }

  .min-w-xl-15px {
    min-width: 15px !important;
  }

  .min-w-xl-20px {
    min-width: 20px !important;
  }

  .min-w-xl-25px {
    min-width: 25px !important;
  }

  .min-w-xl-30px {
    min-width: 30px !important;
  }

  .min-w-xl-35px {
    min-width: 35px !important;
  }

  .min-w-xl-40px {
    min-width: 40px !important;
  }

  .min-w-xl-45px {
    min-width: 45px !important;
  }

  .min-w-xl-50px {
    min-width: 50px !important;
  }

  .min-w-xl-55px {
    min-width: 55px !important;
  }

  .min-w-xl-60px {
    min-width: 60px !important;
  }

  .min-w-xl-65px {
    min-width: 65px !important;
  }

  .min-w-xl-70px {
    min-width: 70px !important;
  }

  .min-w-xl-75px {
    min-width: 75px !important;
  }

  .min-w-xl-80px {
    min-width: 80px !important;
  }

  .min-w-xl-85px {
    min-width: 85px !important;
  }

  .min-w-xl-90px {
    min-width: 90px !important;
  }

  .min-w-xl-95px {
    min-width: 95px !important;
  }

  .min-w-xl-100px {
    min-width: 100px !important;
  }

  .min-w-xl-125px {
    min-width: 125px !important;
  }

  .min-w-xl-150px {
    min-width: 150px !important;
  }

  .min-w-xl-175px {
    min-width: 175px !important;
  }

  .min-w-xl-200px {
    min-width: 200px !important;
  }

  .min-w-xl-225px {
    min-width: 225px !important;
  }

  .min-w-xl-250px {
    min-width: 250px !important;
  }

  .min-w-xl-275px {
    min-width: 275px !important;
  }

  .min-w-xl-300px {
    min-width: 300px !important;
  }

  .min-w-xl-325px {
    min-width: 325px !important;
  }

  .min-w-xl-350px {
    min-width: 350px !important;
  }

  .min-w-xl-375px {
    min-width: 375px !important;
  }

  .min-w-xl-400px {
    min-width: 400px !important;
  }

  .min-w-xl-425px {
    min-width: 425px !important;
  }

  .min-w-xl-450px {
    min-width: 450px !important;
  }

  .min-w-xl-475px {
    min-width: 475px !important;
  }

  .min-w-xl-500px {
    min-width: 500px !important;
  }

  .min-w-xl-550px {
    min-width: 550px !important;
  }

  .min-w-xl-600px {
    min-width: 600px !important;
  }

  .min-w-xl-650px {
    min-width: 650px !important;
  }

  .min-w-xl-700px {
    min-width: 700px !important;
  }

  .min-w-xl-750px {
    min-width: 750px !important;
  }

  .min-w-xl-800px {
    min-width: 800px !important;
  }

  .min-w-xl-850px {
    min-width: 850px !important;
  }

  .min-w-xl-900px {
    min-width: 900px !important;
  }

  .min-w-xl-950px {
    min-width: 950px !important;
  }

  .min-w-xl-1000px {
    min-width: 1000px !important;
  }

  .min-h-xl-unset {
    min-height: unset !important;
  }

  .min-h-xl-25 {
    min-height: 25% !important;
  }

  .min-h-xl-50 {
    min-height: 50% !important;
  }

  .min-h-xl-75 {
    min-height: 75% !important;
  }

  .min-h-xl-100 {
    min-height: 100% !important;
  }

  .min-h-xl-auto {
    min-height: auto !important;
  }

  .min-h-xl-1px {
    min-height: 1px !important;
  }

  .min-h-xl-2px {
    min-height: 2px !important;
  }

  .min-h-xl-3px {
    min-height: 3px !important;
  }

  .min-h-xl-4px {
    min-height: 4px !important;
  }

  .min-h-xl-5px {
    min-height: 5px !important;
  }

  .min-h-xl-6px {
    min-height: 6px !important;
  }

  .min-h-xl-7px {
    min-height: 7px !important;
  }

  .min-h-xl-8px {
    min-height: 8px !important;
  }

  .min-h-xl-9px {
    min-height: 9px !important;
  }

  .min-h-xl-10px {
    min-height: 10px !important;
  }

  .min-h-xl-15px {
    min-height: 15px !important;
  }

  .min-h-xl-20px {
    min-height: 20px !important;
  }

  .min-h-xl-25px {
    min-height: 25px !important;
  }

  .min-h-xl-30px {
    min-height: 30px !important;
  }

  .min-h-xl-35px {
    min-height: 35px !important;
  }

  .min-h-xl-40px {
    min-height: 40px !important;
  }

  .min-h-xl-45px {
    min-height: 45px !important;
  }

  .min-h-xl-50px {
    min-height: 50px !important;
  }

  .min-h-xl-55px {
    min-height: 55px !important;
  }

  .min-h-xl-60px {
    min-height: 60px !important;
  }

  .min-h-xl-65px {
    min-height: 65px !important;
  }

  .min-h-xl-70px {
    min-height: 70px !important;
  }

  .min-h-xl-75px {
    min-height: 75px !important;
  }

  .min-h-xl-80px {
    min-height: 80px !important;
  }

  .min-h-xl-85px {
    min-height: 85px !important;
  }

  .min-h-xl-90px {
    min-height: 90px !important;
  }

  .min-h-xl-95px {
    min-height: 95px !important;
  }

  .min-h-xl-100px {
    min-height: 100px !important;
  }

  .min-h-xl-125px {
    min-height: 125px !important;
  }

  .min-h-xl-150px {
    min-height: 150px !important;
  }

  .min-h-xl-175px {
    min-height: 175px !important;
  }

  .min-h-xl-200px {
    min-height: 200px !important;
  }

  .min-h-xl-225px {
    min-height: 225px !important;
  }

  .min-h-xl-250px {
    min-height: 250px !important;
  }

  .min-h-xl-275px {
    min-height: 275px !important;
  }

  .min-h-xl-300px {
    min-height: 300px !important;
  }

  .min-h-xl-325px {
    min-height: 325px !important;
  }

  .min-h-xl-350px {
    min-height: 350px !important;
  }

  .min-h-xl-375px {
    min-height: 375px !important;
  }

  .min-h-xl-400px {
    min-height: 400px !important;
  }

  .min-h-xl-425px {
    min-height: 425px !important;
  }

  .min-h-xl-450px {
    min-height: 450px !important;
  }

  .min-h-xl-475px {
    min-height: 475px !important;
  }

  .min-h-xl-500px {
    min-height: 500px !important;
  }

  .min-h-xl-550px {
    min-height: 550px !important;
  }

  .min-h-xl-600px {
    min-height: 600px !important;
  }

  .min-h-xl-650px {
    min-height: 650px !important;
  }

  .min-h-xl-700px {
    min-height: 700px !important;
  }

  .min-h-xl-750px {
    min-height: 750px !important;
  }

  .min-h-xl-800px {
    min-height: 800px !important;
  }

  .min-h-xl-850px {
    min-height: 850px !important;
  }

  .min-h-xl-900px {
    min-height: 900px !important;
  }

  .min-h-xl-950px {
    min-height: 950px !important;
  }

  .min-h-xl-1000px {
    min-height: 1000px !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .w-xxl-unset {
    width: unset !important;
  }

  .w-xxl-25 {
    width: 25% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-100 {
    width: 100% !important;
  }

  .w-xxl-auto {
    width: auto !important;
  }

  .w-xxl-1px {
    width: 1px !important;
  }

  .w-xxl-2px {
    width: 2px !important;
  }

  .w-xxl-3px {
    width: 3px !important;
  }

  .w-xxl-4px {
    width: 4px !important;
  }

  .w-xxl-5px {
    width: 5px !important;
  }

  .w-xxl-6px {
    width: 6px !important;
  }

  .w-xxl-7px {
    width: 7px !important;
  }

  .w-xxl-8px {
    width: 8px !important;
  }

  .w-xxl-9px {
    width: 9px !important;
  }

  .w-xxl-10px {
    width: 10px !important;
  }

  .w-xxl-15px {
    width: 15px !important;
  }

  .w-xxl-20px {
    width: 20px !important;
  }

  .w-xxl-25px {
    width: 25px !important;
  }

  .w-xxl-30px {
    width: 30px !important;
  }

  .w-xxl-35px {
    width: 35px !important;
  }

  .w-xxl-40px {
    width: 40px !important;
  }

  .w-xxl-45px {
    width: 45px !important;
  }

  .w-xxl-50px {
    width: 50px !important;
  }

  .w-xxl-55px {
    width: 55px !important;
  }

  .w-xxl-60px {
    width: 60px !important;
  }

  .w-xxl-65px {
    width: 65px !important;
  }

  .w-xxl-70px {
    width: 70px !important;
  }

  .w-xxl-75px {
    width: 75px !important;
  }

  .w-xxl-80px {
    width: 80px !important;
  }

  .w-xxl-85px {
    width: 85px !important;
  }

  .w-xxl-90px {
    width: 90px !important;
  }

  .w-xxl-95px {
    width: 95px !important;
  }

  .w-xxl-100px {
    width: 100px !important;
  }

  .w-xxl-125px {
    width: 125px !important;
  }

  .w-xxl-150px {
    width: 150px !important;
  }

  .w-xxl-175px {
    width: 175px !important;
  }

  .w-xxl-200px {
    width: 200px !important;
  }

  .w-xxl-225px {
    width: 225px !important;
  }

  .w-xxl-250px {
    width: 250px !important;
  }

  .w-xxl-275px {
    width: 275px !important;
  }

  .w-xxl-300px {
    width: 300px !important;
  }

  .w-xxl-325px {
    width: 325px !important;
  }

  .w-xxl-350px {
    width: 350px !important;
  }

  .w-xxl-375px {
    width: 375px !important;
  }

  .w-xxl-400px {
    width: 400px !important;
  }

  .w-xxl-425px {
    width: 425px !important;
  }

  .w-xxl-450px {
    width: 450px !important;
  }

  .w-xxl-475px {
    width: 475px !important;
  }

  .w-xxl-500px {
    width: 500px !important;
  }

  .w-xxl-550px {
    width: 550px !important;
  }

  .w-xxl-600px {
    width: 600px !important;
  }

  .w-xxl-650px {
    width: 650px !important;
  }

  .w-xxl-700px {
    width: 700px !important;
  }

  .w-xxl-750px {
    width: 750px !important;
  }

  .w-xxl-800px {
    width: 800px !important;
  }

  .w-xxl-850px {
    width: 850px !important;
  }

  .w-xxl-900px {
    width: 900px !important;
  }

  .w-xxl-950px {
    width: 950px !important;
  }

  .w-xxl-1000px {
    width: 1000px !important;
  }

  .mw-xxl-unset {
    max-width: unset !important;
  }

  .mw-xxl-25 {
    max-width: 25% !important;
  }

  .mw-xxl-50 {
    max-width: 50% !important;
  }

  .mw-xxl-75 {
    max-width: 75% !important;
  }

  .mw-xxl-100 {
    max-width: 100% !important;
  }

  .mw-xxl-auto {
    max-width: auto !important;
  }

  .mw-xxl-1px {
    max-width: 1px !important;
  }

  .mw-xxl-2px {
    max-width: 2px !important;
  }

  .mw-xxl-3px {
    max-width: 3px !important;
  }

  .mw-xxl-4px {
    max-width: 4px !important;
  }

  .mw-xxl-5px {
    max-width: 5px !important;
  }

  .mw-xxl-6px {
    max-width: 6px !important;
  }

  .mw-xxl-7px {
    max-width: 7px !important;
  }

  .mw-xxl-8px {
    max-width: 8px !important;
  }

  .mw-xxl-9px {
    max-width: 9px !important;
  }

  .mw-xxl-10px {
    max-width: 10px !important;
  }

  .mw-xxl-15px {
    max-width: 15px !important;
  }

  .mw-xxl-20px {
    max-width: 20px !important;
  }

  .mw-xxl-25px {
    max-width: 25px !important;
  }

  .mw-xxl-30px {
    max-width: 30px !important;
  }

  .mw-xxl-35px {
    max-width: 35px !important;
  }

  .mw-xxl-40px {
    max-width: 40px !important;
  }

  .mw-xxl-45px {
    max-width: 45px !important;
  }

  .mw-xxl-50px {
    max-width: 50px !important;
  }

  .mw-xxl-55px {
    max-width: 55px !important;
  }

  .mw-xxl-60px {
    max-width: 60px !important;
  }

  .mw-xxl-65px {
    max-width: 65px !important;
  }

  .mw-xxl-70px {
    max-width: 70px !important;
  }

  .mw-xxl-75px {
    max-width: 75px !important;
  }

  .mw-xxl-80px {
    max-width: 80px !important;
  }

  .mw-xxl-85px {
    max-width: 85px !important;
  }

  .mw-xxl-90px {
    max-width: 90px !important;
  }

  .mw-xxl-95px {
    max-width: 95px !important;
  }

  .mw-xxl-100px {
    max-width: 100px !important;
  }

  .mw-xxl-125px {
    max-width: 125px !important;
  }

  .mw-xxl-150px {
    max-width: 150px !important;
  }

  .mw-xxl-175px {
    max-width: 175px !important;
  }

  .mw-xxl-200px {
    max-width: 200px !important;
  }

  .mw-xxl-225px {
    max-width: 225px !important;
  }

  .mw-xxl-250px {
    max-width: 250px !important;
  }

  .mw-xxl-275px {
    max-width: 275px !important;
  }

  .mw-xxl-300px {
    max-width: 300px !important;
  }

  .mw-xxl-325px {
    max-width: 325px !important;
  }

  .mw-xxl-350px {
    max-width: 350px !important;
  }

  .mw-xxl-375px {
    max-width: 375px !important;
  }

  .mw-xxl-400px {
    max-width: 400px !important;
  }

  .mw-xxl-425px {
    max-width: 425px !important;
  }

  .mw-xxl-450px {
    max-width: 450px !important;
  }

  .mw-xxl-475px {
    max-width: 475px !important;
  }

  .mw-xxl-500px {
    max-width: 500px !important;
  }

  .mw-xxl-550px {
    max-width: 550px !important;
  }

  .mw-xxl-600px {
    max-width: 600px !important;
  }

  .mw-xxl-650px {
    max-width: 650px !important;
  }

  .mw-xxl-700px {
    max-width: 700px !important;
  }

  .mw-xxl-750px {
    max-width: 750px !important;
  }

  .mw-xxl-800px {
    max-width: 800px !important;
  }

  .mw-xxl-850px {
    max-width: 850px !important;
  }

  .mw-xxl-900px {
    max-width: 900px !important;
  }

  .mw-xxl-950px {
    max-width: 950px !important;
  }

  .mw-xxl-1000px {
    max-width: 1000px !important;
  }

  .h-xxl-unset {
    height: unset !important;
  }

  .h-xxl-25 {
    height: 25% !important;
  }

  .h-xxl-50 {
    height: 50% !important;
  }

  .h-xxl-75 {
    height: 75% !important;
  }

  .h-xxl-100 {
    height: 100% !important;
  }

  .h-xxl-auto {
    height: auto !important;
  }

  .h-xxl-1px {
    height: 1px !important;
  }

  .h-xxl-2px {
    height: 2px !important;
  }

  .h-xxl-3px {
    height: 3px !important;
  }

  .h-xxl-4px {
    height: 4px !important;
  }

  .h-xxl-5px {
    height: 5px !important;
  }

  .h-xxl-6px {
    height: 6px !important;
  }

  .h-xxl-7px {
    height: 7px !important;
  }

  .h-xxl-8px {
    height: 8px !important;
  }

  .h-xxl-9px {
    height: 9px !important;
  }

  .h-xxl-10px {
    height: 10px !important;
  }

  .h-xxl-15px {
    height: 15px !important;
  }

  .h-xxl-20px {
    height: 20px !important;
  }

  .h-xxl-25px {
    height: 25px !important;
  }

  .h-xxl-30px {
    height: 30px !important;
  }

  .h-xxl-35px {
    height: 35px !important;
  }

  .h-xxl-40px {
    height: 40px !important;
  }

  .h-xxl-45px {
    height: 45px !important;
  }

  .h-xxl-50px {
    height: 50px !important;
  }

  .h-xxl-55px {
    height: 55px !important;
  }

  .h-xxl-60px {
    height: 60px !important;
  }

  .h-xxl-65px {
    height: 65px !important;
  }

  .h-xxl-70px {
    height: 70px !important;
  }

  .h-xxl-75px {
    height: 75px !important;
  }

  .h-xxl-80px {
    height: 80px !important;
  }

  .h-xxl-85px {
    height: 85px !important;
  }

  .h-xxl-90px {
    height: 90px !important;
  }

  .h-xxl-95px {
    height: 95px !important;
  }

  .h-xxl-100px {
    height: 100px !important;
  }

  .h-xxl-125px {
    height: 125px !important;
  }

  .h-xxl-150px {
    height: 150px !important;
  }

  .h-xxl-175px {
    height: 175px !important;
  }

  .h-xxl-200px {
    height: 200px !important;
  }

  .h-xxl-225px {
    height: 225px !important;
  }

  .h-xxl-250px {
    height: 250px !important;
  }

  .h-xxl-275px {
    height: 275px !important;
  }

  .h-xxl-300px {
    height: 300px !important;
  }

  .h-xxl-325px {
    height: 325px !important;
  }

  .h-xxl-350px {
    height: 350px !important;
  }

  .h-xxl-375px {
    height: 375px !important;
  }

  .h-xxl-400px {
    height: 400px !important;
  }

  .h-xxl-425px {
    height: 425px !important;
  }

  .h-xxl-450px {
    height: 450px !important;
  }

  .h-xxl-475px {
    height: 475px !important;
  }

  .h-xxl-500px {
    height: 500px !important;
  }

  .h-xxl-550px {
    height: 550px !important;
  }

  .h-xxl-600px {
    height: 600px !important;
  }

  .h-xxl-650px {
    height: 650px !important;
  }

  .h-xxl-700px {
    height: 700px !important;
  }

  .h-xxl-750px {
    height: 750px !important;
  }

  .h-xxl-800px {
    height: 800px !important;
  }

  .h-xxl-850px {
    height: 850px !important;
  }

  .h-xxl-900px {
    height: 900px !important;
  }

  .h-xxl-950px {
    height: 950px !important;
  }

  .h-xxl-1000px {
    height: 1000px !important;
  }

  .mh-xxl-unset {
    max-height: unset !important;
  }

  .mh-xxl-25 {
    max-height: 25% !important;
  }

  .mh-xxl-50 {
    max-height: 50% !important;
  }

  .mh-xxl-75 {
    max-height: 75% !important;
  }

  .mh-xxl-100 {
    max-height: 100% !important;
  }

  .mh-xxl-auto {
    max-height: auto !important;
  }

  .mh-xxl-1px {
    max-height: 1px !important;
  }

  .mh-xxl-2px {
    max-height: 2px !important;
  }

  .mh-xxl-3px {
    max-height: 3px !important;
  }

  .mh-xxl-4px {
    max-height: 4px !important;
  }

  .mh-xxl-5px {
    max-height: 5px !important;
  }

  .mh-xxl-6px {
    max-height: 6px !important;
  }

  .mh-xxl-7px {
    max-height: 7px !important;
  }

  .mh-xxl-8px {
    max-height: 8px !important;
  }

  .mh-xxl-9px {
    max-height: 9px !important;
  }

  .mh-xxl-10px {
    max-height: 10px !important;
  }

  .mh-xxl-15px {
    max-height: 15px !important;
  }

  .mh-xxl-20px {
    max-height: 20px !important;
  }

  .mh-xxl-25px {
    max-height: 25px !important;
  }

  .mh-xxl-30px {
    max-height: 30px !important;
  }

  .mh-xxl-35px {
    max-height: 35px !important;
  }

  .mh-xxl-40px {
    max-height: 40px !important;
  }

  .mh-xxl-45px {
    max-height: 45px !important;
  }

  .mh-xxl-50px {
    max-height: 50px !important;
  }

  .mh-xxl-55px {
    max-height: 55px !important;
  }

  .mh-xxl-60px {
    max-height: 60px !important;
  }

  .mh-xxl-65px {
    max-height: 65px !important;
  }

  .mh-xxl-70px {
    max-height: 70px !important;
  }

  .mh-xxl-75px {
    max-height: 75px !important;
  }

  .mh-xxl-80px {
    max-height: 80px !important;
  }

  .mh-xxl-85px {
    max-height: 85px !important;
  }

  .mh-xxl-90px {
    max-height: 90px !important;
  }

  .mh-xxl-95px {
    max-height: 95px !important;
  }

  .mh-xxl-100px {
    max-height: 100px !important;
  }

  .mh-xxl-125px {
    max-height: 125px !important;
  }

  .mh-xxl-150px {
    max-height: 150px !important;
  }

  .mh-xxl-175px {
    max-height: 175px !important;
  }

  .mh-xxl-200px {
    max-height: 200px !important;
  }

  .mh-xxl-225px {
    max-height: 225px !important;
  }

  .mh-xxl-250px {
    max-height: 250px !important;
  }

  .mh-xxl-275px {
    max-height: 275px !important;
  }

  .mh-xxl-300px {
    max-height: 300px !important;
  }

  .mh-xxl-325px {
    max-height: 325px !important;
  }

  .mh-xxl-350px {
    max-height: 350px !important;
  }

  .mh-xxl-375px {
    max-height: 375px !important;
  }

  .mh-xxl-400px {
    max-height: 400px !important;
  }

  .mh-xxl-425px {
    max-height: 425px !important;
  }

  .mh-xxl-450px {
    max-height: 450px !important;
  }

  .mh-xxl-475px {
    max-height: 475px !important;
  }

  .mh-xxl-500px {
    max-height: 500px !important;
  }

  .mh-xxl-550px {
    max-height: 550px !important;
  }

  .mh-xxl-600px {
    max-height: 600px !important;
  }

  .mh-xxl-650px {
    max-height: 650px !important;
  }

  .mh-xxl-700px {
    max-height: 700px !important;
  }

  .mh-xxl-750px {
    max-height: 750px !important;
  }

  .mh-xxl-800px {
    max-height: 800px !important;
  }

  .mh-xxl-850px {
    max-height: 850px !important;
  }

  .mh-xxl-900px {
    max-height: 900px !important;
  }

  .mh-xxl-950px {
    max-height: 950px !important;
  }

  .mh-xxl-1000px {
    max-height: 1000px !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 0.75rem !important;
  }

  .m-xxl-4 {
    margin: 1rem !important;
  }

  .m-xxl-5 {
    margin: 1.25rem !important;
  }

  .m-xxl-6 {
    margin: 1.5rem !important;
  }

  .m-xxl-7 {
    margin: 1.75rem !important;
  }

  .m-xxl-8 {
    margin: 2rem !important;
  }

  .m-xxl-9 {
    margin: 2.25rem !important;
  }

  .m-xxl-10 {
    margin: 2.5rem !important;
  }

  .m-xxl-11 {
    margin: 2.75rem !important;
  }

  .m-xxl-12 {
    margin: 3rem !important;
  }

  .m-xxl-13 {
    margin: 3.25rem !important;
  }

  .m-xxl-14 {
    margin: 3.5rem !important;
  }

  .m-xxl-15 {
    margin: 3.75rem !important;
  }

  .m-xxl-16 {
    margin: 4rem !important;
  }

  .m-xxl-17 {
    margin: 4.25rem !important;
  }

  .m-xxl-18 {
    margin: 4.5rem !important;
  }

  .m-xxl-19 {
    margin: 4.75rem !important;
  }

  .m-xxl-20 {
    margin: 5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-5 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .mx-xxl-6 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-7 {
    margin-right: 1.75rem !important;
    margin-left: 1.75rem !important;
  }

  .mx-xxl-8 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  .mx-xxl-9 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }

  .mx-xxl-10 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }

  .mx-xxl-11 {
    margin-right: 2.75rem !important;
    margin-left: 2.75rem !important;
  }

  .mx-xxl-12 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-13 {
    margin-right: 3.25rem !important;
    margin-left: 3.25rem !important;
  }

  .mx-xxl-14 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xxl-15 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }

  .mx-xxl-16 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-17 {
    margin-right: 4.25rem !important;
    margin-left: 4.25rem !important;
  }

  .mx-xxl-18 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }

  .mx-xxl-19 {
    margin-right: 4.75rem !important;
    margin-left: 4.75rem !important;
  }

  .mx-xxl-20 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xxl-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .my-xxl-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-7 {
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
  }

  .my-xxl-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xxl-9 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xxl-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xxl-11 {
    margin-top: 2.75rem !important;
    margin-bottom: 2.75rem !important;
  }

  .my-xxl-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-13 {
    margin-top: 3.25rem !important;
    margin-bottom: 3.25rem !important;
  }

  .my-xxl-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-15 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }

  .my-xxl-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-17 {
    margin-top: 4.25rem !important;
    margin-bottom: 4.25rem !important;
  }

  .my-xxl-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-19 {
    margin-top: 4.75rem !important;
    margin-bottom: 4.75rem !important;
  }

  .my-xxl-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1rem !important;
  }

  .mt-xxl-5 {
    margin-top: 1.25rem !important;
  }

  .mt-xxl-6 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-7 {
    margin-top: 1.75rem !important;
  }

  .mt-xxl-8 {
    margin-top: 2rem !important;
  }

  .mt-xxl-9 {
    margin-top: 2.25rem !important;
  }

  .mt-xxl-10 {
    margin-top: 2.5rem !important;
  }

  .mt-xxl-11 {
    margin-top: 2.75rem !important;
  }

  .mt-xxl-12 {
    margin-top: 3rem !important;
  }

  .mt-xxl-13 {
    margin-top: 3.25rem !important;
  }

  .mt-xxl-14 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-15 {
    margin-top: 3.75rem !important;
  }

  .mt-xxl-16 {
    margin-top: 4rem !important;
  }

  .mt-xxl-17 {
    margin-top: 4.25rem !important;
  }

  .mt-xxl-18 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-19 {
    margin-top: 4.75rem !important;
  }

  .mt-xxl-20 {
    margin-top: 5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 0.75rem !important;
  }

  .me-xxl-4 {
    margin-right: 1rem !important;
  }

  .me-xxl-5 {
    margin-right: 1.25rem !important;
  }

  .me-xxl-6 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-7 {
    margin-right: 1.75rem !important;
  }

  .me-xxl-8 {
    margin-right: 2rem !important;
  }

  .me-xxl-9 {
    margin-right: 2.25rem !important;
  }

  .me-xxl-10 {
    margin-right: 2.5rem !important;
  }

  .me-xxl-11 {
    margin-right: 2.75rem !important;
  }

  .me-xxl-12 {
    margin-right: 3rem !important;
  }

  .me-xxl-13 {
    margin-right: 3.25rem !important;
  }

  .me-xxl-14 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-15 {
    margin-right: 3.75rem !important;
  }

  .me-xxl-16 {
    margin-right: 4rem !important;
  }

  .me-xxl-17 {
    margin-right: 4.25rem !important;
  }

  .me-xxl-18 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-19 {
    margin-right: 4.75rem !important;
  }

  .me-xxl-20 {
    margin-right: 5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 1.25rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-7 {
    margin-bottom: 1.75rem !important;
  }

  .mb-xxl-8 {
    margin-bottom: 2rem !important;
  }

  .mb-xxl-9 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xxl-10 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xxl-11 {
    margin-bottom: 2.75rem !important;
  }

  .mb-xxl-12 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-13 {
    margin-bottom: 3.25rem !important;
  }

  .mb-xxl-14 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-15 {
    margin-bottom: 3.75rem !important;
  }

  .mb-xxl-16 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-17 {
    margin-bottom: 4.25rem !important;
  }

  .mb-xxl-18 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-19 {
    margin-bottom: 4.75rem !important;
  }

  .mb-xxl-20 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 0.75rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1rem !important;
  }

  .ms-xxl-5 {
    margin-left: 1.25rem !important;
  }

  .ms-xxl-6 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-7 {
    margin-left: 1.75rem !important;
  }

  .ms-xxl-8 {
    margin-left: 2rem !important;
  }

  .ms-xxl-9 {
    margin-left: 2.25rem !important;
  }

  .ms-xxl-10 {
    margin-left: 2.5rem !important;
  }

  .ms-xxl-11 {
    margin-left: 2.75rem !important;
  }

  .ms-xxl-12 {
    margin-left: 3rem !important;
  }

  .ms-xxl-13 {
    margin-left: 3.25rem !important;
  }

  .ms-xxl-14 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-15 {
    margin-left: 3.75rem !important;
  }

  .ms-xxl-16 {
    margin-left: 4rem !important;
  }

  .ms-xxl-17 {
    margin-left: 4.25rem !important;
  }

  .ms-xxl-18 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-19 {
    margin-left: 4.75rem !important;
  }

  .ms-xxl-20 {
    margin-left: 5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -0.75rem !important;
  }

  .m-xxl-n4 {
    margin: -1rem !important;
  }

  .m-xxl-n5 {
    margin: -1.25rem !important;
  }

  .m-xxl-n6 {
    margin: -1.5rem !important;
  }

  .m-xxl-n7 {
    margin: -1.75rem !important;
  }

  .m-xxl-n8 {
    margin: -2rem !important;
  }

  .m-xxl-n9 {
    margin: -2.25rem !important;
  }

  .m-xxl-n10 {
    margin: -2.5rem !important;
  }

  .m-xxl-n11 {
    margin: -2.75rem !important;
  }

  .m-xxl-n12 {
    margin: -3rem !important;
  }

  .m-xxl-n13 {
    margin: -3.25rem !important;
  }

  .m-xxl-n14 {
    margin: -3.5rem !important;
  }

  .m-xxl-n15 {
    margin: -3.75rem !important;
  }

  .m-xxl-n16 {
    margin: -4rem !important;
  }

  .m-xxl-n17 {
    margin: -4.25rem !important;
  }

  .m-xxl-n18 {
    margin: -4.5rem !important;
  }

  .m-xxl-n19 {
    margin: -4.75rem !important;
  }

  .m-xxl-n20 {
    margin: -5rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }

  .mx-xxl-n6 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n7 {
    margin-right: -1.75rem !important;
    margin-left: -1.75rem !important;
  }

  .mx-xxl-n8 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }

  .mx-xxl-n9 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }

  .mx-xxl-n10 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }

  .mx-xxl-n11 {
    margin-right: -2.75rem !important;
    margin-left: -2.75rem !important;
  }

  .mx-xxl-n12 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .mx-xxl-n13 {
    margin-right: -3.25rem !important;
    margin-left: -3.25rem !important;
  }

  .mx-xxl-n14 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important;
  }

  .mx-xxl-n15 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }

  .mx-xxl-n16 {
    margin-right: -4rem !important;
    margin-left: -4rem !important;
  }

  .mx-xxl-n17 {
    margin-right: -4.25rem !important;
    margin-left: -4.25rem !important;
  }

  .mx-xxl-n18 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }

  .mx-xxl-n19 {
    margin-right: -4.75rem !important;
    margin-left: -4.75rem !important;
  }

  .mx-xxl-n20 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .my-xxl-n6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n7 {
    margin-top: -1.75rem !important;
    margin-bottom: -1.75rem !important;
  }

  .my-xxl-n8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .my-xxl-n9 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xxl-n10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .my-xxl-n11 {
    margin-top: -2.75rem !important;
    margin-bottom: -2.75rem !important;
  }

  .my-xxl-n12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .my-xxl-n13 {
    margin-top: -3.25rem !important;
    margin-bottom: -3.25rem !important;
  }

  .my-xxl-n14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .my-xxl-n15 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }

  .my-xxl-n16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .my-xxl-n17 {
    margin-top: -4.25rem !important;
    margin-bottom: -4.25rem !important;
  }

  .my-xxl-n18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .my-xxl-n19 {
    margin-top: -4.75rem !important;
    margin-bottom: -4.75rem !important;
  }

  .my-xxl-n20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -0.75rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -1.25rem !important;
  }

  .mt-xxl-n6 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n7 {
    margin-top: -1.75rem !important;
  }

  .mt-xxl-n8 {
    margin-top: -2rem !important;
  }

  .mt-xxl-n9 {
    margin-top: -2.25rem !important;
  }

  .mt-xxl-n10 {
    margin-top: -2.5rem !important;
  }

  .mt-xxl-n11 {
    margin-top: -2.75rem !important;
  }

  .mt-xxl-n12 {
    margin-top: -3rem !important;
  }

  .mt-xxl-n13 {
    margin-top: -3.25rem !important;
  }

  .mt-xxl-n14 {
    margin-top: -3.5rem !important;
  }

  .mt-xxl-n15 {
    margin-top: -3.75rem !important;
  }

  .mt-xxl-n16 {
    margin-top: -4rem !important;
  }

  .mt-xxl-n17 {
    margin-top: -4.25rem !important;
  }

  .mt-xxl-n18 {
    margin-top: -4.5rem !important;
  }

  .mt-xxl-n19 {
    margin-top: -4.75rem !important;
  }

  .mt-xxl-n20 {
    margin-top: -5rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -0.75rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1rem !important;
  }

  .me-xxl-n5 {
    margin-right: -1.25rem !important;
  }

  .me-xxl-n6 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n7 {
    margin-right: -1.75rem !important;
  }

  .me-xxl-n8 {
    margin-right: -2rem !important;
  }

  .me-xxl-n9 {
    margin-right: -2.25rem !important;
  }

  .me-xxl-n10 {
    margin-right: -2.5rem !important;
  }

  .me-xxl-n11 {
    margin-right: -2.75rem !important;
  }

  .me-xxl-n12 {
    margin-right: -3rem !important;
  }

  .me-xxl-n13 {
    margin-right: -3.25rem !important;
  }

  .me-xxl-n14 {
    margin-right: -3.5rem !important;
  }

  .me-xxl-n15 {
    margin-right: -3.75rem !important;
  }

  .me-xxl-n16 {
    margin-right: -4rem !important;
  }

  .me-xxl-n17 {
    margin-right: -4.25rem !important;
  }

  .me-xxl-n18 {
    margin-right: -4.5rem !important;
  }

  .me-xxl-n19 {
    margin-right: -4.75rem !important;
  }

  .me-xxl-n20 {
    margin-right: -5rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -1.25rem !important;
  }

  .mb-xxl-n6 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n7 {
    margin-bottom: -1.75rem !important;
  }

  .mb-xxl-n8 {
    margin-bottom: -2rem !important;
  }

  .mb-xxl-n9 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xxl-n10 {
    margin-bottom: -2.5rem !important;
  }

  .mb-xxl-n11 {
    margin-bottom: -2.75rem !important;
  }

  .mb-xxl-n12 {
    margin-bottom: -3rem !important;
  }

  .mb-xxl-n13 {
    margin-bottom: -3.25rem !important;
  }

  .mb-xxl-n14 {
    margin-bottom: -3.5rem !important;
  }

  .mb-xxl-n15 {
    margin-bottom: -3.75rem !important;
  }

  .mb-xxl-n16 {
    margin-bottom: -4rem !important;
  }

  .mb-xxl-n17 {
    margin-bottom: -4.25rem !important;
  }

  .mb-xxl-n18 {
    margin-bottom: -4.5rem !important;
  }

  .mb-xxl-n19 {
    margin-bottom: -4.75rem !important;
  }

  .mb-xxl-n20 {
    margin-bottom: -5rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -0.75rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -1.25rem !important;
  }

  .ms-xxl-n6 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n7 {
    margin-left: -1.75rem !important;
  }

  .ms-xxl-n8 {
    margin-left: -2rem !important;
  }

  .ms-xxl-n9 {
    margin-left: -2.25rem !important;
  }

  .ms-xxl-n10 {
    margin-left: -2.5rem !important;
  }

  .ms-xxl-n11 {
    margin-left: -2.75rem !important;
  }

  .ms-xxl-n12 {
    margin-left: -3rem !important;
  }

  .ms-xxl-n13 {
    margin-left: -3.25rem !important;
  }

  .ms-xxl-n14 {
    margin-left: -3.5rem !important;
  }

  .ms-xxl-n15 {
    margin-left: -3.75rem !important;
  }

  .ms-xxl-n16 {
    margin-left: -4rem !important;
  }

  .ms-xxl-n17 {
    margin-left: -4.25rem !important;
  }

  .ms-xxl-n18 {
    margin-left: -4.5rem !important;
  }

  .ms-xxl-n19 {
    margin-left: -4.75rem !important;
  }

  .ms-xxl-n20 {
    margin-left: -5rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 0.75rem !important;
  }

  .p-xxl-4 {
    padding: 1rem !important;
  }

  .p-xxl-5 {
    padding: 1.25rem !important;
  }

  .p-xxl-6 {
    padding: 1.5rem !important;
  }

  .p-xxl-7 {
    padding: 1.75rem !important;
  }

  .p-xxl-8 {
    padding: 2rem !important;
  }

  .p-xxl-9 {
    padding: 2.25rem !important;
  }

  .p-xxl-10 {
    padding: 2.5rem !important;
  }

  .p-xxl-11 {
    padding: 2.75rem !important;
  }

  .p-xxl-12 {
    padding: 3rem !important;
  }

  .p-xxl-13 {
    padding: 3.25rem !important;
  }

  .p-xxl-14 {
    padding: 3.5rem !important;
  }

  .p-xxl-15 {
    padding: 3.75rem !important;
  }

  .p-xxl-16 {
    padding: 4rem !important;
  }

  .p-xxl-17 {
    padding: 4.25rem !important;
  }

  .p-xxl-18 {
    padding: 4.5rem !important;
  }

  .p-xxl-19 {
    padding: 4.75rem !important;
  }

  .p-xxl-20 {
    padding: 5rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xxl-4 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-5 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }

  .px-xxl-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-7 {
    padding-right: 1.75rem !important;
    padding-left: 1.75rem !important;
  }

  .px-xxl-8 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .px-xxl-9 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }

  .px-xxl-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }

  .px-xxl-11 {
    padding-right: 2.75rem !important;
    padding-left: 2.75rem !important;
  }

  .px-xxl-12 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-13 {
    padding-right: 3.25rem !important;
    padding-left: 3.25rem !important;
  }

  .px-xxl-14 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xxl-15 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }

  .px-xxl-16 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .px-xxl-17 {
    padding-right: 4.25rem !important;
    padding-left: 4.25rem !important;
  }

  .px-xxl-18 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }

  .px-xxl-19 {
    padding-right: 4.75rem !important;
    padding-left: 4.75rem !important;
  }

  .px-xxl-20 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xxl-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .py-xxl-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-7 {
    padding-top: 1.75rem !important;
    padding-bottom: 1.75rem !important;
  }

  .py-xxl-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xxl-9 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xxl-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xxl-11 {
    padding-top: 2.75rem !important;
    padding-bottom: 2.75rem !important;
  }

  .py-xxl-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-13 {
    padding-top: 3.25rem !important;
    padding-bottom: 3.25rem !important;
  }

  .py-xxl-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }

  .py-xxl-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xxl-17 {
    padding-top: 4.25rem !important;
    padding-bottom: 4.25rem !important;
  }

  .py-xxl-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xxl-19 {
    padding-top: 4.75rem !important;
    padding-bottom: 4.75rem !important;
  }

  .py-xxl-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1rem !important;
  }

  .pt-xxl-5 {
    padding-top: 1.25rem !important;
  }

  .pt-xxl-6 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-7 {
    padding-top: 1.75rem !important;
  }

  .pt-xxl-8 {
    padding-top: 2rem !important;
  }

  .pt-xxl-9 {
    padding-top: 2.25rem !important;
  }

  .pt-xxl-10 {
    padding-top: 2.5rem !important;
  }

  .pt-xxl-11 {
    padding-top: 2.75rem !important;
  }

  .pt-xxl-12 {
    padding-top: 3rem !important;
  }

  .pt-xxl-13 {
    padding-top: 3.25rem !important;
  }

  .pt-xxl-14 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-15 {
    padding-top: 3.75rem !important;
  }

  .pt-xxl-16 {
    padding-top: 4rem !important;
  }

  .pt-xxl-17 {
    padding-top: 4.25rem !important;
  }

  .pt-xxl-18 {
    padding-top: 4.5rem !important;
  }

  .pt-xxl-19 {
    padding-top: 4.75rem !important;
  }

  .pt-xxl-20 {
    padding-top: 5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 0.75rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1rem !important;
  }

  .pe-xxl-5 {
    padding-right: 1.25rem !important;
  }

  .pe-xxl-6 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-7 {
    padding-right: 1.75rem !important;
  }

  .pe-xxl-8 {
    padding-right: 2rem !important;
  }

  .pe-xxl-9 {
    padding-right: 2.25rem !important;
  }

  .pe-xxl-10 {
    padding-right: 2.5rem !important;
  }

  .pe-xxl-11 {
    padding-right: 2.75rem !important;
  }

  .pe-xxl-12 {
    padding-right: 3rem !important;
  }

  .pe-xxl-13 {
    padding-right: 3.25rem !important;
  }

  .pe-xxl-14 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-15 {
    padding-right: 3.75rem !important;
  }

  .pe-xxl-16 {
    padding-right: 4rem !important;
  }

  .pe-xxl-17 {
    padding-right: 4.25rem !important;
  }

  .pe-xxl-18 {
    padding-right: 4.5rem !important;
  }

  .pe-xxl-19 {
    padding-right: 4.75rem !important;
  }

  .pe-xxl-20 {
    padding-right: 5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 1.25rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-7 {
    padding-bottom: 1.75rem !important;
  }

  .pb-xxl-8 {
    padding-bottom: 2rem !important;
  }

  .pb-xxl-9 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xxl-10 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xxl-11 {
    padding-bottom: 2.75rem !important;
  }

  .pb-xxl-12 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-13 {
    padding-bottom: 3.25rem !important;
  }

  .pb-xxl-14 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-15 {
    padding-bottom: 3.75rem !important;
  }

  .pb-xxl-16 {
    padding-bottom: 4rem !important;
  }

  .pb-xxl-17 {
    padding-bottom: 4.25rem !important;
  }

  .pb-xxl-18 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xxl-19 {
    padding-bottom: 4.75rem !important;
  }

  .pb-xxl-20 {
    padding-bottom: 5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 0.75rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1rem !important;
  }

  .ps-xxl-5 {
    padding-left: 1.25rem !important;
  }

  .ps-xxl-6 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-7 {
    padding-left: 1.75rem !important;
  }

  .ps-xxl-8 {
    padding-left: 2rem !important;
  }

  .ps-xxl-9 {
    padding-left: 2.25rem !important;
  }

  .ps-xxl-10 {
    padding-left: 2.5rem !important;
  }

  .ps-xxl-11 {
    padding-left: 2.75rem !important;
  }

  .ps-xxl-12 {
    padding-left: 3rem !important;
  }

  .ps-xxl-13 {
    padding-left: 3.25rem !important;
  }

  .ps-xxl-14 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-15 {
    padding-left: 3.75rem !important;
  }

  .ps-xxl-16 {
    padding-left: 4rem !important;
  }

  .ps-xxl-17 {
    padding-left: 4.25rem !important;
  }

  .ps-xxl-18 {
    padding-left: 4.5rem !important;
  }

  .ps-xxl-19 {
    padding-left: 4.75rem !important;
  }

  .ps-xxl-20 {
    padding-left: 5rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 0.75rem !important;
  }

  .gap-xxl-4 {
    gap: 1rem !important;
  }

  .gap-xxl-5 {
    gap: 1.25rem !important;
  }

  .gap-xxl-6 {
    gap: 1.5rem !important;
  }

  .gap-xxl-7 {
    gap: 1.75rem !important;
  }

  .gap-xxl-8 {
    gap: 2rem !important;
  }

  .gap-xxl-9 {
    gap: 2.25rem !important;
  }

  .gap-xxl-10 {
    gap: 2.5rem !important;
  }

  .gap-xxl-11 {
    gap: 2.75rem !important;
  }

  .gap-xxl-12 {
    gap: 3rem !important;
  }

  .gap-xxl-13 {
    gap: 3.25rem !important;
  }

  .gap-xxl-14 {
    gap: 3.5rem !important;
  }

  .gap-xxl-15 {
    gap: 3.75rem !important;
  }

  .gap-xxl-16 {
    gap: 4rem !important;
  }

  .gap-xxl-17 {
    gap: 4.25rem !important;
  }

  .gap-xxl-18 {
    gap: 4.5rem !important;
  }

  .gap-xxl-19 {
    gap: 4.75rem !important;
  }

  .gap-xxl-20 {
    gap: 5rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 0.75rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 1.25rem !important;
  }

  .row-gap-xxl-6 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-7 {
    row-gap: 1.75rem !important;
  }

  .row-gap-xxl-8 {
    row-gap: 2rem !important;
  }

  .row-gap-xxl-9 {
    row-gap: 2.25rem !important;
  }

  .row-gap-xxl-10 {
    row-gap: 2.5rem !important;
  }

  .row-gap-xxl-11 {
    row-gap: 2.75rem !important;
  }

  .row-gap-xxl-12 {
    row-gap: 3rem !important;
  }

  .row-gap-xxl-13 {
    row-gap: 3.25rem !important;
  }

  .row-gap-xxl-14 {
    row-gap: 3.5rem !important;
  }

  .row-gap-xxl-15 {
    row-gap: 3.75rem !important;
  }

  .row-gap-xxl-16 {
    row-gap: 4rem !important;
  }

  .row-gap-xxl-17 {
    row-gap: 4.25rem !important;
  }

  .row-gap-xxl-18 {
    row-gap: 4.5rem !important;
  }

  .row-gap-xxl-19 {
    row-gap: 4.75rem !important;
  }

  .row-gap-xxl-20 {
    row-gap: 5rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 0.75rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 1.25rem !important;
  }

  .column-gap-xxl-6 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-7 {
    column-gap: 1.75rem !important;
  }

  .column-gap-xxl-8 {
    column-gap: 2rem !important;
  }

  .column-gap-xxl-9 {
    column-gap: 2.25rem !important;
  }

  .column-gap-xxl-10 {
    column-gap: 2.5rem !important;
  }

  .column-gap-xxl-11 {
    column-gap: 2.75rem !important;
  }

  .column-gap-xxl-12 {
    column-gap: 3rem !important;
  }

  .column-gap-xxl-13 {
    column-gap: 3.25rem !important;
  }

  .column-gap-xxl-14 {
    column-gap: 3.5rem !important;
  }

  .column-gap-xxl-15 {
    column-gap: 3.75rem !important;
  }

  .column-gap-xxl-16 {
    column-gap: 4rem !important;
  }

  .column-gap-xxl-17 {
    column-gap: 4.25rem !important;
  }

  .column-gap-xxl-18 {
    column-gap: 4.5rem !important;
  }

  .column-gap-xxl-19 {
    column-gap: 4.75rem !important;
  }

  .column-gap-xxl-20 {
    column-gap: 5rem !important;
  }

  .fs-xxl-1 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-xxl-2 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-xxl-3 {
    font-size: calc(1.26rem + 0.12vw) !important;
  }

  .fs-xxl-4 {
    font-size: 1.25rem !important;
  }

  .fs-xxl-5 {
    font-size: 1.15rem !important;
  }

  .fs-xxl-6 {
    font-size: 1.075rem !important;
  }

  .fs-xxl-7 {
    font-size: 0.95rem !important;
  }

  .fs-xxl-8 {
    font-size: 0.85rem !important;
  }

  .fs-xxl-9 {
    font-size: 0.75rem !important;
  }

  .fs-xxl-10 {
    font-size: 0.5rem !important;
  }

  .fs-xxl-base {
    font-size: 1rem !important;
  }

  .fs-xxl-fluid {
    font-size: 100% !important;
  }

  .fs-xxl-2x {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-xxl-2qx {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  .fs-xxl-2hx {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-xxl-2tx {
    font-size: calc(1.4rem + 1.8vw) !important;
  }

  .fs-xxl-3x {
    font-size: calc(1.425rem + 2.1vw) !important;
  }

  .fs-xxl-3qx {
    font-size: calc(1.45rem + 2.4vw) !important;
  }

  .fs-xxl-3hx {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  .fs-xxl-3tx {
    font-size: calc(1.5rem + 3vw) !important;
  }

  .fs-xxl-4x {
    font-size: calc(1.525rem + 3.3vw) !important;
  }

  .fs-xxl-4qx {
    font-size: calc(1.55rem + 3.6vw) !important;
  }

  .fs-xxl-4hx {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .fs-xxl-4tx {
    font-size: calc(1.6rem + 4.2vw) !important;
  }

  .fs-xxl-5x {
    font-size: calc(1.625rem + 4.5vw) !important;
  }

  .fs-xxl-5qx {
    font-size: calc(1.65rem + 4.8vw) !important;
  }

  .fs-xxl-5hx {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .fs-xxl-5tx {
    font-size: calc(1.7rem + 5.4vw) !important;
  }

  .fs-xxl-10x {
    font-size: calc(2.125rem + 10.5vw) !important;
  }

  .fs-xxl-10qx {
    font-size: calc(2.15rem + 10.8vw) !important;
  }

  .fs-xxl-10hx {
    font-size: calc(2.175rem + 11.1vw) !important;
  }

  .fs-xxl-10tx {
    font-size: calc(2.2rem + 11.4vw) !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .min-w-xxl-unset {
    min-width: unset !important;
  }

  .min-w-xxl-25 {
    min-width: 25% !important;
  }

  .min-w-xxl-50 {
    min-width: 50% !important;
  }

  .min-w-xxl-75 {
    min-width: 75% !important;
  }

  .min-w-xxl-100 {
    min-width: 100% !important;
  }

  .min-w-xxl-auto {
    min-width: auto !important;
  }

  .min-w-xxl-1px {
    min-width: 1px !important;
  }

  .min-w-xxl-2px {
    min-width: 2px !important;
  }

  .min-w-xxl-3px {
    min-width: 3px !important;
  }

  .min-w-xxl-4px {
    min-width: 4px !important;
  }

  .min-w-xxl-5px {
    min-width: 5px !important;
  }

  .min-w-xxl-6px {
    min-width: 6px !important;
  }

  .min-w-xxl-7px {
    min-width: 7px !important;
  }

  .min-w-xxl-8px {
    min-width: 8px !important;
  }

  .min-w-xxl-9px {
    min-width: 9px !important;
  }

  .min-w-xxl-10px {
    min-width: 10px !important;
  }

  .min-w-xxl-15px {
    min-width: 15px !important;
  }

  .min-w-xxl-20px {
    min-width: 20px !important;
  }

  .min-w-xxl-25px {
    min-width: 25px !important;
  }

  .min-w-xxl-30px {
    min-width: 30px !important;
  }

  .min-w-xxl-35px {
    min-width: 35px !important;
  }

  .min-w-xxl-40px {
    min-width: 40px !important;
  }

  .min-w-xxl-45px {
    min-width: 45px !important;
  }

  .min-w-xxl-50px {
    min-width: 50px !important;
  }

  .min-w-xxl-55px {
    min-width: 55px !important;
  }

  .min-w-xxl-60px {
    min-width: 60px !important;
  }

  .min-w-xxl-65px {
    min-width: 65px !important;
  }

  .min-w-xxl-70px {
    min-width: 70px !important;
  }

  .min-w-xxl-75px {
    min-width: 75px !important;
  }

  .min-w-xxl-80px {
    min-width: 80px !important;
  }

  .min-w-xxl-85px {
    min-width: 85px !important;
  }

  .min-w-xxl-90px {
    min-width: 90px !important;
  }

  .min-w-xxl-95px {
    min-width: 95px !important;
  }

  .min-w-xxl-100px {
    min-width: 100px !important;
  }

  .min-w-xxl-125px {
    min-width: 125px !important;
  }

  .min-w-xxl-150px {
    min-width: 150px !important;
  }

  .min-w-xxl-175px {
    min-width: 175px !important;
  }

  .min-w-xxl-200px {
    min-width: 200px !important;
  }

  .min-w-xxl-225px {
    min-width: 225px !important;
  }

  .min-w-xxl-250px {
    min-width: 250px !important;
  }

  .min-w-xxl-275px {
    min-width: 275px !important;
  }

  .min-w-xxl-300px {
    min-width: 300px !important;
  }

  .min-w-xxl-325px {
    min-width: 325px !important;
  }

  .min-w-xxl-350px {
    min-width: 350px !important;
  }

  .min-w-xxl-375px {
    min-width: 375px !important;
  }

  .min-w-xxl-400px {
    min-width: 400px !important;
  }

  .min-w-xxl-425px {
    min-width: 425px !important;
  }

  .min-w-xxl-450px {
    min-width: 450px !important;
  }

  .min-w-xxl-475px {
    min-width: 475px !important;
  }

  .min-w-xxl-500px {
    min-width: 500px !important;
  }

  .min-w-xxl-550px {
    min-width: 550px !important;
  }

  .min-w-xxl-600px {
    min-width: 600px !important;
  }

  .min-w-xxl-650px {
    min-width: 650px !important;
  }

  .min-w-xxl-700px {
    min-width: 700px !important;
  }

  .min-w-xxl-750px {
    min-width: 750px !important;
  }

  .min-w-xxl-800px {
    min-width: 800px !important;
  }

  .min-w-xxl-850px {
    min-width: 850px !important;
  }

  .min-w-xxl-900px {
    min-width: 900px !important;
  }

  .min-w-xxl-950px {
    min-width: 950px !important;
  }

  .min-w-xxl-1000px {
    min-width: 1000px !important;
  }

  .min-h-xxl-unset {
    min-height: unset !important;
  }

  .min-h-xxl-25 {
    min-height: 25% !important;
  }

  .min-h-xxl-50 {
    min-height: 50% !important;
  }

  .min-h-xxl-75 {
    min-height: 75% !important;
  }

  .min-h-xxl-100 {
    min-height: 100% !important;
  }

  .min-h-xxl-auto {
    min-height: auto !important;
  }

  .min-h-xxl-1px {
    min-height: 1px !important;
  }

  .min-h-xxl-2px {
    min-height: 2px !important;
  }

  .min-h-xxl-3px {
    min-height: 3px !important;
  }

  .min-h-xxl-4px {
    min-height: 4px !important;
  }

  .min-h-xxl-5px {
    min-height: 5px !important;
  }

  .min-h-xxl-6px {
    min-height: 6px !important;
  }

  .min-h-xxl-7px {
    min-height: 7px !important;
  }

  .min-h-xxl-8px {
    min-height: 8px !important;
  }

  .min-h-xxl-9px {
    min-height: 9px !important;
  }

  .min-h-xxl-10px {
    min-height: 10px !important;
  }

  .min-h-xxl-15px {
    min-height: 15px !important;
  }

  .min-h-xxl-20px {
    min-height: 20px !important;
  }

  .min-h-xxl-25px {
    min-height: 25px !important;
  }

  .min-h-xxl-30px {
    min-height: 30px !important;
  }

  .min-h-xxl-35px {
    min-height: 35px !important;
  }

  .min-h-xxl-40px {
    min-height: 40px !important;
  }

  .min-h-xxl-45px {
    min-height: 45px !important;
  }

  .min-h-xxl-50px {
    min-height: 50px !important;
  }

  .min-h-xxl-55px {
    min-height: 55px !important;
  }

  .min-h-xxl-60px {
    min-height: 60px !important;
  }

  .min-h-xxl-65px {
    min-height: 65px !important;
  }

  .min-h-xxl-70px {
    min-height: 70px !important;
  }

  .min-h-xxl-75px {
    min-height: 75px !important;
  }

  .min-h-xxl-80px {
    min-height: 80px !important;
  }

  .min-h-xxl-85px {
    min-height: 85px !important;
  }

  .min-h-xxl-90px {
    min-height: 90px !important;
  }

  .min-h-xxl-95px {
    min-height: 95px !important;
  }

  .min-h-xxl-100px {
    min-height: 100px !important;
  }

  .min-h-xxl-125px {
    min-height: 125px !important;
  }

  .min-h-xxl-150px {
    min-height: 150px !important;
  }

  .min-h-xxl-175px {
    min-height: 175px !important;
  }

  .min-h-xxl-200px {
    min-height: 200px !important;
  }

  .min-h-xxl-225px {
    min-height: 225px !important;
  }

  .min-h-xxl-250px {
    min-height: 250px !important;
  }

  .min-h-xxl-275px {
    min-height: 275px !important;
  }

  .min-h-xxl-300px {
    min-height: 300px !important;
  }

  .min-h-xxl-325px {
    min-height: 325px !important;
  }

  .min-h-xxl-350px {
    min-height: 350px !important;
  }

  .min-h-xxl-375px {
    min-height: 375px !important;
  }

  .min-h-xxl-400px {
    min-height: 400px !important;
  }

  .min-h-xxl-425px {
    min-height: 425px !important;
  }

  .min-h-xxl-450px {
    min-height: 450px !important;
  }

  .min-h-xxl-475px {
    min-height: 475px !important;
  }

  .min-h-xxl-500px {
    min-height: 500px !important;
  }

  .min-h-xxl-550px {
    min-height: 550px !important;
  }

  .min-h-xxl-600px {
    min-height: 600px !important;
  }

  .min-h-xxl-650px {
    min-height: 650px !important;
  }

  .min-h-xxl-700px {
    min-height: 700px !important;
  }

  .min-h-xxl-750px {
    min-height: 750px !important;
  }

  .min-h-xxl-800px {
    min-height: 800px !important;
  }

  .min-h-xxl-850px {
    min-height: 850px !important;
  }

  .min-h-xxl-900px {
    min-height: 900px !important;
  }

  .min-h-xxl-950px {
    min-height: 950px !important;
  }

  .min-h-xxl-1000px {
    min-height: 1000px !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 1.75rem !important;
  }

  .fs-2 {
    font-size: 1.5rem !important;
  }

  .fs-3 {
    font-size: 1.35rem !important;
  }

  .fs-2x {
    font-size: 2rem !important;
  }

  .fs-2qx {
    font-size: 2.25rem !important;
  }

  .fs-2hx {
    font-size: 2.5rem !important;
  }

  .fs-2tx {
    font-size: 2.75rem !important;
  }

  .fs-3x {
    font-size: 3rem !important;
  }

  .fs-3qx {
    font-size: 3.25rem !important;
  }

  .fs-3hx {
    font-size: 3.5rem !important;
  }

  .fs-3tx {
    font-size: 3.75rem !important;
  }

  .fs-4x {
    font-size: 4rem !important;
  }

  .fs-4qx {
    font-size: 4.25rem !important;
  }

  .fs-4hx {
    font-size: 4.5rem !important;
  }

  .fs-4tx {
    font-size: 4.75rem !important;
  }

  .fs-5x {
    font-size: 5rem !important;
  }

  .fs-5qx {
    font-size: 5.25rem !important;
  }

  .fs-5hx {
    font-size: 5.5rem !important;
  }

  .fs-5tx {
    font-size: 5.75rem !important;
  }

  .fs-10x {
    font-size: 10rem !important;
  }

  .fs-10qx {
    font-size: 10.25rem !important;
  }

  .fs-10hx {
    font-size: 10.5rem !important;
  }

  .fs-10tx {
    font-size: 10.75rem !important;
  }

  .fs-sm-1 {
    font-size: 1.75rem !important;
  }

  .fs-sm-2 {
    font-size: 1.5rem !important;
  }

  .fs-sm-3 {
    font-size: 1.35rem !important;
  }

  .fs-sm-2x {
    font-size: 2rem !important;
  }

  .fs-sm-2qx {
    font-size: 2.25rem !important;
  }

  .fs-sm-2hx {
    font-size: 2.5rem !important;
  }

  .fs-sm-2tx {
    font-size: 2.75rem !important;
  }

  .fs-sm-3x {
    font-size: 3rem !important;
  }

  .fs-sm-3qx {
    font-size: 3.25rem !important;
  }

  .fs-sm-3hx {
    font-size: 3.5rem !important;
  }

  .fs-sm-3tx {
    font-size: 3.75rem !important;
  }

  .fs-sm-4x {
    font-size: 4rem !important;
  }

  .fs-sm-4qx {
    font-size: 4.25rem !important;
  }

  .fs-sm-4hx {
    font-size: 4.5rem !important;
  }

  .fs-sm-4tx {
    font-size: 4.75rem !important;
  }

  .fs-sm-5x {
    font-size: 5rem !important;
  }

  .fs-sm-5qx {
    font-size: 5.25rem !important;
  }

  .fs-sm-5hx {
    font-size: 5.5rem !important;
  }

  .fs-sm-5tx {
    font-size: 5.75rem !important;
  }

  .fs-sm-10x {
    font-size: 10rem !important;
  }

  .fs-sm-10qx {
    font-size: 10.25rem !important;
  }

  .fs-sm-10hx {
    font-size: 10.5rem !important;
  }

  .fs-sm-10tx {
    font-size: 10.75rem !important;
  }

  .fs-md-1 {
    font-size: 1.75rem !important;
  }

  .fs-md-2 {
    font-size: 1.5rem !important;
  }

  .fs-md-3 {
    font-size: 1.35rem !important;
  }

  .fs-md-2x {
    font-size: 2rem !important;
  }

  .fs-md-2qx {
    font-size: 2.25rem !important;
  }

  .fs-md-2hx {
    font-size: 2.5rem !important;
  }

  .fs-md-2tx {
    font-size: 2.75rem !important;
  }

  .fs-md-3x {
    font-size: 3rem !important;
  }

  .fs-md-3qx {
    font-size: 3.25rem !important;
  }

  .fs-md-3hx {
    font-size: 3.5rem !important;
  }

  .fs-md-3tx {
    font-size: 3.75rem !important;
  }

  .fs-md-4x {
    font-size: 4rem !important;
  }

  .fs-md-4qx {
    font-size: 4.25rem !important;
  }

  .fs-md-4hx {
    font-size: 4.5rem !important;
  }

  .fs-md-4tx {
    font-size: 4.75rem !important;
  }

  .fs-md-5x {
    font-size: 5rem !important;
  }

  .fs-md-5qx {
    font-size: 5.25rem !important;
  }

  .fs-md-5hx {
    font-size: 5.5rem !important;
  }

  .fs-md-5tx {
    font-size: 5.75rem !important;
  }

  .fs-md-10x {
    font-size: 10rem !important;
  }

  .fs-md-10qx {
    font-size: 10.25rem !important;
  }

  .fs-md-10hx {
    font-size: 10.5rem !important;
  }

  .fs-md-10tx {
    font-size: 10.75rem !important;
  }

  .fs-lg-1 {
    font-size: 1.75rem !important;
  }

  .fs-lg-2 {
    font-size: 1.5rem !important;
  }

  .fs-lg-3 {
    font-size: 1.35rem !important;
  }

  .fs-lg-2x {
    font-size: 2rem !important;
  }

  .fs-lg-2qx {
    font-size: 2.25rem !important;
  }

  .fs-lg-2hx {
    font-size: 2.5rem !important;
  }

  .fs-lg-2tx {
    font-size: 2.75rem !important;
  }

  .fs-lg-3x {
    font-size: 3rem !important;
  }

  .fs-lg-3qx {
    font-size: 3.25rem !important;
  }

  .fs-lg-3hx {
    font-size: 3.5rem !important;
  }

  .fs-lg-3tx {
    font-size: 3.75rem !important;
  }

  .fs-lg-4x {
    font-size: 4rem !important;
  }

  .fs-lg-4qx {
    font-size: 4.25rem !important;
  }

  .fs-lg-4hx {
    font-size: 4.5rem !important;
  }

  .fs-lg-4tx {
    font-size: 4.75rem !important;
  }

  .fs-lg-5x {
    font-size: 5rem !important;
  }

  .fs-lg-5qx {
    font-size: 5.25rem !important;
  }

  .fs-lg-5hx {
    font-size: 5.5rem !important;
  }

  .fs-lg-5tx {
    font-size: 5.75rem !important;
  }

  .fs-lg-10x {
    font-size: 10rem !important;
  }

  .fs-lg-10qx {
    font-size: 10.25rem !important;
  }

  .fs-lg-10hx {
    font-size: 10.5rem !important;
  }

  .fs-lg-10tx {
    font-size: 10.75rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}