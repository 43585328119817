// Tables
.mat-table {

  .mat-cell,
  .mat-footer-cell,
  .mat-header-cell {
    color: inherit;
    border: none;
  }

  .mat-header-cell {
    @include themed() {
      color: theme-color(color-muted) !important;
    }
  }
}
