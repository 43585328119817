//
// Drawer
//

mat-drawer-content {
  position: initial !important;

  &.sidenav-collapsed {
    margin-left: 100px !important;
  }
}

mat-drawer,
mat-drawer-content {
  transition-property: transform, margin-left, margin-right;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}

mat-drawer-container {
  height: 100%;
}

mat-drawer {
  width: 250px;
  transition-property: width;

  @include themed() {
    color: theme-color($color-gray-900) !important;
    background-color: theme-color($color-body-bg) !important;
  }

  border-right: none !important;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: get($drawer, overlay-bg-color) !important;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  mat-drawer {
    padding: get($content-spacing, desktop) 0 0 get($content-spacing, desktop);
    z-index: 1 !important;
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  mat-drawer {
    padding: get($content-spacing, tablet-and-mobile);
    z-index: 3 !important;
    padding: 0;
    border-radius: 0;
  }
}

// Mobile modes
@include media-breakpoint-down(md) {
  mat-drawer {
    padding: 0;
    border-radius: 0;

    .card {
      border-radius: 0;
    }
  }
}
