//
//  Tooltip
//

// Base
.tooltip {
  .tooltip-inner {
    box-shadow: $tooltip-box-shadow;

    @include themed() {
      color: theme-color(color-canvas-body-text) !important;
      background-color: theme-color(color-canvas-body-bg) !important;
    }
  }

  &.tooltop-auto-width {
    .tooltip-inner {
      white-space: nowrap;
      max-width: none;
    }
  }

  // Dark Theme
  &.tooltip-dark {
    @include tooltip-theme($dark, $dark-inverse);
  }
}
