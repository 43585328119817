//
// Symbol
//
@use 'sass:math';

@mixin symbol-size($size) {
  >img {
    //width: 100%;
    //max-width: $size;
    width: $size;
    height: $size;
  }

  .symbol-label {
    width: $size;
    height: $size;
  }

  &.symbol-fixed {
    .symbol-label {
      width: $size;
      height: $size;
    }

    >img {
      width: $size;
      height: $size;
      max-width: auto;
    }
  }

  // Ratios
  &.symbol-2by3 {
    .symbol-label {
      height: $size;
      width: $size * divide(3, 2);
    }

    >img {
      height: $size;
      width: $size * divide(3, 2);
      max-width: auto;
    }
  }
}
