//
// Page loader(used by the previous layout system)
//

// // CSS3 Transitions only after page load(.page-loading class added to body tag and remove with JS on page load)
// [data-st-page-loading="true"] *,
// .page-loading * {
//   transition: none;
// }

// CSS3 Transitions only after page load(data-st-page-loading="true" attribute added to body tag and removed with JS on page loaded)
[data-st-page-loading="true"] {
  overflow: hidden;
}

// [data-st-page-loading="true"] * {
//   transition: none;
// }

// Base
.page-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1080;
  display: none;

  & {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .page-loading {
    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;
    visibility: hidden;
    transition: 0.3s linear;

    &::before,
    &::after {
      content: "";
      position: fixed;
      height: 100%;
      top: 0;
      left: 50%;
      background-color: var(--bs-body-bg);
      opacity: 0.25;
      width: 200%;
      margin-left: -100%;
      transform: skewX(-30deg) translateX(-100%);
      transition: transform 0.3s linear 0.3s;
      z-index: 2;
    }

    &.is-loading {
      position: absolute;
      opacity: 1;
      visibility: visible;

      &::before {
        transform: skewX(-30deg) translateX(0);
        transition: transform 0.3s linear;
      }

      &::after {
        transform: skewX(-30deg) translateX(0);
        transition: transform 0.3s linear 0.15s;
      }
    }

    > * {
      z-index: 9;
    }
  }
}
.svg-icon.svg-icon-20x {
  height: 10rem !important;
  width: 10rem !important;
}
