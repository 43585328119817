//
// Dropdown
//

.mat-menu-panel {
  box-shadow: $box-shadow !important;

  .mat-menu-content {
    .mat-menu-item {
      white-space: revert;
      line-height: normal;
    }
  }
}
