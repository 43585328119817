//
// Custom background helpers
//

// Background theme light colors
@each $color,
$value in $theme-colors {
  @if (theme-light-color($color)) {
    .bg-light-#{$color} {
      background-color: theme-light-color($color);

      &.hoverable:hover {
        background-color: darken(theme-light-color($color), 6%);
      }
    }
  }

  @if (theme-lighten-color($color)) {
    .bg-lighten-#{$color} {
      @include themed() {
        background-color: theme-color(color-#{$color}-lighten);
      }

      &.hoverable:hover {
        @include themed() {
          background-color: theme-color(color-#{$color}-active);
        }
      }
    }
  }

  .bg-#{$color} {
    --bg-color: #{red($value)},
    #{green($value)},
    #{blue($value)};

    &.hoverable:hover {
      background-color: theme-active-color($color);
    }
  }

  @if (theme-light-color($color)) {
    .bg-hover-light-#{$color} {
      cursor: pointer;

      &:hover {
        background-color: theme-light-color($color);
      }
    }

    .bg-state-light-#{$color} {
      cursor: pointer;

      &.active,
      &:hover {
        background-color: theme-light-color($color) !important;
      }
    }
  }

  @if (theme-lighten-color($color)) {
    .bg-hover-lighten-#{$color} {
      cursor: pointer;

      &:hover {
        background-color: theme-lighten-color($color) !important;
      }
    }

    .bg-state-light-#{$color} {
      cursor: pointer;

      &.active,
      &:hover {
        background-color: theme-lighten-color($color) !important;
      }
    }
  }

  .bg-hover-#{$color} {
    cursor: pointer;

    &:hover {
      background-color: var(--active-#{$color});

      @include themed() {
        background-color: theme-color(color-#{$color}-active);
      }
    }
  }

  .bg-active-#{$color} {
    &.active {
      --bg-color: #{red($value)},
      #{green($value)},
      #{blue($value)};
      background-color: $value !important;
    }
  }

  .bg-state-#{$color} {
    cursor: pointer;

    >div,
    td {
      position: relative;
      z-index: 1;
    }

    &.active,
    &:hover {
      position: relative;
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;

        @include themed() {
          background-color: theme-color(color-#{$color}-active) !important;
        }
      }
    }
  }
}

// Background gray colors
@each $color,
$value in $grays {
  .bg-gray-#{$color} {
    background-color: $value !important;
  }
}

// Opacity
@each $name,
$value in $opacity-values {
  .bg-opacity-#{$name} {
    background-color: rgba(var(--bg-color), #{$value}) !important;
  }

  .bg-hover-opacity-#{$name}:hover {
    background-color: rgba(var(--bg-color), #{$value}) !important;
  }

  .bg-active-opacity-#{$name}.active {
    background-color: rgba(var(--bg-color), #{$value}) !important;
  }

  .bg-state-opacity-#{$name} {

    .active,
    &:hover {
      background-color: rgba(var(--bg-color), #{$value}) !important;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        z-index: 0;
        opacity: #{$value};
      }
    }
  }
}

// Lighten bg states
.bg-lighten {
  // don't have themed
  background-color: var(--light-light) !important;

  // if has themed
  @include themed() {
    background-color: theme-color(color-lighten) !important;
  }
}

.bg-hover-lighten {
  cursor: pointer;

  &:hover {
    // don't have themed
    background-color: var(--light-light) !important;

    // if has themed
    @include themed() {
      background-color: theme-color(color-lighten) !important;
    }
  }
}

.bg-active-lighten {
  &.active {
    background-color: $lighten !important;
  }
}

.bg-state-lighten {
  cursor: pointer;

  &.active,
  &:hover {
    background-color: $lighten !important;
  }
}

// Hoverable
.hoverable {
  cursor: pointer;
  transition: $transition-base;

  &:hover {
    transition: $transition-base;
  }
}

// Background image helpers
// Background image none
.bgi-none {
  background-image: none !important;

  &.form-select {
    background-image: none !important;
  }
}

// Background repeat
.bgi-no-repeat {
  background-repeat: no-repeat;
}

// Background positions
.bgi-position-y-top {
  background-position-y: top;
}

.bgi-position-y-bottom {
  background-position-y: bottom;
}

.bgi-position-y-center {
  background-position-y: center;
}

.bgi-position-x-start {
  background-position-x: left;
}

.bgi-position-x-end {
  background-position-x: right;
}

.bgi-position-x-center {
  background-position-x: center;
}

.bgi-position-top {
  background-position: 0 top;
}

.bgi-position-bottom {
  background-position: 0 bottom;
}

.bgi-position-center {
  background-position: center;
}

// Responsive helpers
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Background sizes
    .bgi-size#{$infix}-auto {
      background-size: auto;
    }

    .bgi-size#{$infix}-cover {
      background-size: cover;
    }

    .bgi-size#{$infix}-contain {
      background-size: contain;
    }

    // Background attachment
    .bgi-attachment#{$infix}-fixed {
      background-attachment: fixed;
    }

    .bgi-attachment#{$infix}-auto {
      background-attachment: auto;
    }
  }
}
